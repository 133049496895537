import React, { useState, useEffect } from 'react';
import LoginForm from '../../../components/forms/login-form';
import ResetPasswordForm from '../../../components/forms/reset-password-form';
import logo from '../../../assets/img/header-login-logo.svg';
import ConfirmModal from '../../../components/modals/confirm-modal';
import { useModal } from '../../../services/hooks';
import { RouterConfig } from '../../../routerConfig';
import notificationSettings from '../../../services/notificationSettings';
import { store } from 'react-notifications-component';
import './style.scss';

export function LoginPage({ match: { params }, history, logInSubmit, resetPassSubmit, forgotPassSubmit, loading }) {
    const [formView, setFormView] = useState('login');
    const [confirmModal, toggleConfirmModal] = useModal(false);
    const [resetPassModal, toggleResetPassModal] = useModal(false);

    const { token } = params;
    const {
        location: { search }
    } = history;

    useEffect(() => {
        const formViewParams = new URLSearchParams(search).get('formView');
        const errorParams = new URLSearchParams(search).get('error');
        setFormView(formViewParams || 'login');

        if (formViewParams) {
            history.replace({ search: '' });
        }
        if (errorParams) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Error',
                message: 'Declined GPhC verification',
                type: 'danger'
            });
        }
        // eslint-disable-next-line
    }, []);

    const onForgotPassSuccess = async (email) =>
        forgotPassSubmit({
            email,
            toggleModal: () => toggleConfirmModal()
        });

    const onLoginSuccess = (recievedData) =>
        logInSubmit({
            email: recievedData.email,
            password: recievedData.password
        });

    const onResetPassSuccess = async (password) =>
        resetPassSubmit({
            token,
            password,
            toggleModal: () => toggleResetPassModal()
        });

    const onProceedToSignup = () => history.push(RouterConfig.signup);

    const confirmModalButton = {
        value: 'Ok',
        onClick: () => history.push(RouterConfig.root),
        size: 'big',
        width: 'big'
    };

    let displayForm = token ? (
        <ResetPasswordForm
            onSuccess={onResetPassSuccess}
            loading={loading}
            onGoBack={() => history.push(RouterConfig.root)}
        />
    ) : (
        <LoginForm
            onSuccess={onLoginSuccess}
            loading={loading}
            onProceedToSignup={onProceedToSignup}
            formView={formView}
            onForgotPass={() => setFormView('forgotPass')}
            onBackToLogin={() => setFormView('login')}
            onResetPassSuccess={onForgotPassSuccess}
        />
    );

    return (
        <div className="login-page">
            <img className="logo" src={logo} alt="logo" />
            <div className="inner">{displayForm}</div>
            {confirmModal && (
                <ConfirmModal
                    open={confirmModal}
                    onClose={() => history.push(RouterConfig.root)}
                    title="Success"
                    text="We sent a link to set your new password. Please check your email."
                    button={confirmModalButton}
                />
            )}
            {resetPassModal && (
                <ConfirmModal
                    open={resetPassModal}
                    onClose={() => history.push(RouterConfig.root)}
                    title="Success"
                    text="You have changed your password. Please proceed to login."
                    button={confirmModalButton}
                />
            )}
        </div>
    );
}
