import { call, put, fork, takeEvery, takeLatest, delay, select } from 'redux-saga/effects';
import { getUsersListRequest, getUsersListSuccess, getUsersListFailed, onUsersListFilterFalse } from '../../../actions';
import { selectItemsPerPage, selectFilters } from './selectors';
import * as usersListActionTypes from '../../../actions/super-admin-view/users-page/action-types';
import api from '../../../../services/api';

function* onUsersListRequest({ payload, filters }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page } = payload;
    const searchFilters = filters ? filters : {};

    try {
        const { data } = yield call(api.request, {
            url: '/admin/users/list',
            method: 'post',
            payload: {
                filters: searchFilters,
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getUsersListSuccess({
                data,
                totalData: data.count
            })
        );
    } catch (err) {
        yield put(getUsersListFailed());
    }
}

function* onUsersListFilterChange({ payload: { value }, page }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field && field.length > 0);

    if (!value || (value.length === 0 && !isFilled && page === 1)) {
        yield put(onUsersListFilterFalse());
        yield put(getUsersListRequest({ page }, filters));
    } else if (value.length === 0 && isFilled) {
        yield put(getUsersListRequest({ page }, filters));
    } else if (value.length === 0 && !isFilled && page !== 1) {
        yield put(onUsersListFilterFalse());
    } else {
        yield delay(1000);
        yield put(getUsersListRequest({ page }, filters));
    }
}

// watchers
function* watchUsersListRequest() {
    yield takeEvery(usersListActionTypes.GET_USERS_LIST_REQUEST, onUsersListRequest);
}

function* watchUsersListFilterChange() {
    yield takeLatest(usersListActionTypes.ON_USERS_LIST_FILTER_CHANGE, onUsersListFilterChange);
}

const adminUsersListSaga = [fork(watchUsersListRequest), fork(watchUsersListFilterChange)];

export default adminUsersListSaga;
