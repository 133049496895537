import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getInvitesDataSuccess,
    getInvitesDataFailed,
    onInviteStaffSuccess,
    onInviteStaffFailed
} from '../../../actions';
import * as inviteStaffActionTypes from '../../../actions/admin-view/invite-staff/action-types';
import api from '../../../../services/api';

function* getInvitesDataRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/invites/get-data',
            method: 'get'
        });
        yield put(getInvitesDataSuccess(data));
    } catch (err) {
        yield put(getInvitesDataFailed());
    }
}

function* onInviteStaffRequest({ payload: { recievedData, toggleModal } }) {
    try {
        yield call(api.request, {
            url: '/invites',
            method: 'post',
            payload: recievedData
        });
        yield put(onInviteStaffSuccess());
        toggleModal();
    } catch (err) {
        yield put(onInviteStaffFailed());
    }
}

// watchers
function* watchInvitesDataRequest() {
    yield takeEvery(inviteStaffActionTypes.GET_INVITES_DATA_REQUEST, getInvitesDataRequest);
}

function* watchInviteStaffRequest() {
    yield takeEvery(inviteStaffActionTypes.ON_INVITE_STAFF_REQUEST, onInviteStaffRequest);
}

const inviteStaffSaga = [fork(watchInvitesDataRequest), fork(watchInviteStaffRequest)];

export default inviteStaffSaga;
