import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'semantic-ui-react';

import './style.scss';

// sizes options: mini, tiny, small, large, fullscreen

const AbsenceRequestModal = ({
    title,
    subtitle,
    hiddenHeader,
    autoSizeHeader = false,
    size = 'tiny',
    open,
    onClose,
    closeIcon = true,
    ...props
}) => {
    return (
        <Modal closeIcon={closeIcon} size={size} open={open} onClose={onClose} className="absence-modal">
            <Modal.Header className="absence-modal__header">
                <h2>{title}</h2>
                <p className="absence-modal__subtitle small">{subtitle}</p>
            </Modal.Header>

            <Modal.Content>{props.children}</Modal.Content>
        </Modal>
    );
};

AbsenceRequestModal.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    hiddenHeader: PropTypes.bool,
    autoSizeHeader: PropTypes.bool,
    size: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    closeIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.node])
};

export default AbsenceRequestModal;
