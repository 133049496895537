import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactComponent as ErrorImage } from '../../assets/img/error-img.svg';

import './style.scss';

class ErrorBoundary extends Component {
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        };
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const { hasError } = this.state;
        const { history } = this.props;

        if (hasError) {
            return (
                <div className="error-container">
                    <div className="error-container__inner">
                        <ErrorImage />
                        <h1 className="error-title">Oooops....Something went wrong</h1>
                        <button className="error-container__btn" onClick={() => history.goBack()}>
                            Return Back
                        </button>
                    </div>
                </div>
            );
        } else return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
