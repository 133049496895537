import React, { Component } from 'react';
import { RouterConfig } from '../../../../../routerConfig';
import { generatePath } from 'react-router';
import { Redirect } from 'react-router-dom';
import query from 'query-string';
import Moment from 'moment';

import PageNav from '../../../../../components/page-nav';
import TableComponent from '../../../../../components/table';
import BasicModal from '../../../../../components/modals/basic-modal';
import ConfirmModal from '../../../../../components/modals/confirm-modal';
import InviteStaffForm from '../../../../../components/forms/invite-staff-form';

import { onFilter } from '../../../../../services/fetchDataHelper';
import { capitalizeFirstLetter } from '../../../../../services/helpers';
import { userStatusesList } from '../../../../../userConfig';

class StaffList extends Component {
    state = {
        modals: {
            inviteStaff: false,
            inviteStaffSuccess: false
        },
        paginationSorting: {},
        paginationKey: null,
        paginationValue: null,
        currentItemId: null,
        error: null,
        info: null
    };

    componentDidMount() {
        this._isMounted = true;
        const {
            location: { search },
            getStaffListRequest,
            getInvitesDataRequest
        } = this.props;

        getInvitesDataRequest();
        getStaffListRequest({ page: query.parse(search).page });
    }

    componentWillUnmount() {
        this._isMounted = false;
        const { clearStaffFilters } = this.props;
        clearStaffFilters();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        const {
            location: { search },
            staffPage: { isFiltering, filters },
            getStaffListRequest
        } = this.props;
        const { isSorting } = this.state;
        const prevPage = query.parse(prevProps.location.search).page;
        const { page } = query.parse(search);

        if (page !== prevPage && prevPage === undefined) {
            return;
        } else if (page !== prevPage && !isSorting && !isFiltering) {
            getStaffListRequest({ page: query.parse(search).page });
        } else if (page !== prevPage && !isSorting && isFiltering) {
            getStaffListRequest({ page: query.parse(search).page }, filters);
        }
    };

    toggleModal = (name, itemId) => {
        const { modals } = this.state;
        const currentState = modals[name];

        return this.setState({
            modals: { ...modals, [name]: !currentState },
            currentItemId: itemId === undefined ? null : itemId
        });
    };

    onInviteStaff = (recievedData) => {
        const { modals } = this.state;
        const { onInviteStaffRequest } = this.props;
        onInviteStaffRequest({
            recievedData,
            toggleModal: () =>
                this.setState({
                    loading: false,
                    modals: {
                        ...modals,
                        inviteStaff: false,
                        inviteStaffSuccess: true
                    }
                })
        });
    };

    getUserStatusOptions = () => {
        let result = [];
        for (let key in userStatusesList) {
            result.push({
                value: userStatusesList[key],
                text: capitalizeFirstLetter(userStatusesList[key])
            });
        }

        return result;
    };

    render() {
        const { modals } = this.state;
        const {
            location: { search },
            history,
            branchId,
            staffPage: {
                filters: { name, role, phone, dob, branchName, status },
                data,
                totalData,
                itemsPerPage
            },
            inviteStaff: {
                invitesData: { roles, regions, branches }
            },
            onStaffFilterChange
        } = this.props;
        const { page } = query.parse(search);

        const tableCols = [
            {
                name: 'name',
                title: 'Name',
                value: name,
                render: (item) => item.fullName,
                onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onStaffFilterChange, page),
                type: 'search'
            },
            {
                name: 'role',
                title: 'Role',
                value: role,
                render: (item) => item.roleTitle,
                onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onStaffFilterChange, page),
                type: 'search'
            },
            {
                name: 'phone',
                title: 'phone',
                value: phone,
                render: (item) => item.phone || 'None',
                onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onStaffFilterChange, page),
                type: 'search'
            },
            {
                name: 'dob',
                title: 'Date Of Birth',
                value: dob ? new Date(dob) : '',
                render: (item) => (item.dob ? Moment(item.dob).format('DD/MM/YY') : 'None'),
                onChange: (event, eventObj) => onFilter(event, eventObj, onStaffFilterChange, page),
                type: 'date',
                maxDate: new Date(),
                format: 'DD/MM/YY'
            },
            {
                name: 'branchName',
                title: 'assign To',
                value: branchName,
                render: (item) => item.branchName,
                onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onStaffFilterChange, page),
                type: 'search'
            },
            {
                name: 'status',
                title: 'status',
                value: status,
                render: (item) => capitalizeFirstLetter(item.status),
                onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onStaffFilterChange, page),
                type: 'select',
                options: this.getUserStatusOptions(),
                search: true,
                icon: 'search'
            }
        ];

        const headerButtons = [
            {
                componentType: 'button',
                value: 'Add staff',
                onClick: () => this.toggleModal('inviteStaff'),
                size: 'medium',
                width: 'medium'
            }
        ];

        const staffTable =
            data.rows === undefined ? null : (
                <TableComponent
                    cols={tableCols}
                    data={data.rows}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                    openInfo={(staffId) =>
                        history.push(
                            generatePath(RouterConfig.branchAdminStaffRoot, {
                                id: branchId,
                                staffId
                            })
                        )
                    }
                />
            );

        const inviteMoreButton = {
            value: 'Invite More',
            onClick: () =>
                this.setState({
                    modals: {
                        ...modals,
                        inviteStaff: true,
                        inviteStaffSuccess: false
                    }
                }),
            size: 'big',
            width: 'big'
        };

        return (
            <div className="staff-admin-page">
                {!page && <Redirect from="/staff" to="/staff?page=1" exact />}
                <div className="main-container">
                    <PageNav title="Staff" inputs={headerButtons} />
                    {staffTable}
                </div>
                {modals.inviteStaff && (
                    <BasicModal
                        open={modals.inviteStaff}
                        onClose={() => this.toggleModal('inviteStaff')}
                        title="Add Staff"
                    >
                        <InviteStaffForm
                            roles={roles}
                            regions={regions}
                            branches={branches}
                            loading={this.props.inviteStaff.loading}
                            onSuccess={this.onInviteStaff}
                            onCancelInvite={() => this.toggleModal('inviteStaff')}
                        />
                    </BasicModal>
                )}
                {modals.inviteStaffSuccess && (
                    <ConfirmModal
                        open={modals.inviteStaffSuccess}
                        onClose={() => this.toggleModal('inviteStaffSuccess')}
                        title="Invite sent"
                        text="Your invite has been sent"
                        button={inviteMoreButton}
                    />
                )}
            </div>
        );
    }
}

export default StaffList;
