import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import './style.scss';

const BackButton = ({ onClick, visible }) => {
    return (
        <div className={`global-back-button ${visible ? 'visible' : ''}`} onClick={onClick}>
            <Icon name="chevron left" /> <span className="text">Back</span>
        </div>
    );
};

BackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.bool
};

export default BackButton;
