import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonComponent from '../../../../../components/UI/buttons/button';
import InfoTabs from '../../../../../components/info-tabs';
import UploadedDoc from '../UploadedDoc';

import { AbilityContext } from '../../../../../services/ability';
import { roleAbilityTypes, roleFeatureTypes } from '../../../../../userConfig';

function RightToWorkBoard({
    activeView,
    onTabClick,
    accessStatus,
    isPersonalProfile,
    onUserAction,
    loading,
    onChangeView,
    onSaveFile,
    data: {
        DBSAnnualUpdate,
        DBSAnnualUpdateDoc,
        DBSCertificate,
        DBSCertificateDate,
        identification,
        identificationExpDate,
        insuranceNumber,
        insuranceNumberDoc,
        professionalInsurance,
        professionalInsuranceExpDate,
        qualifications,
        qualificationsYear,
        visa,
        visaExpDate
    }
}) {
    const ability = useContext(AbilityContext);

    const tabs = [
        {
            title: 'Personal',
            onClick: () => onTabClick('Personal'),
            isActive: false
        },
        {
            title: 'Right to Work',
            onClick: () => onTabClick('RightToWork'),
            isActive: activeView === 'RightToWork'
        },
        {
            title: 'Experience',
            onClick: () => onTabClick('Experience'),
            isActive: false
        },
        {
            title: 'Qualifications',
            onClick: () => onTabClick('Qualifications'),
            isActive: false
        }
    ];

    let userControlButtons = null;

    switch (accessStatus) {
        case 'Pending user':
            userControlButtons = (
                <div className="admin-controls">
                    <ButtonComponent
                        value="Reject"
                        type="bordered"
                        size="small"
                        colorType="bordered-danger"
                        loading={loading.reject}
                        onClick={() => onUserAction('reject')}
                    />
                    <ButtonComponent
                        value="Approve"
                        size="medium"
                        colorType="green"
                        loading={loading.approve}
                        onClick={() => onUserAction('approve')}
                    />
                </div>
            );
            break;
        case 'Active user':
            userControlButtons = (
                <ButtonComponent
                    value="Deactivate"
                    type="bordered"
                    size="small"
                    colorType="bordered-danger"
                    loading={loading.deactivate}
                    onClick={() => onUserAction('deactivate')}
                />
            );
            break;
        case 'Deavtivated user':
            userControlButtons = (
                <ButtonComponent
                    value="Activate"
                    type="bordered"
                    size="small"
                    colorType="green"
                    loading={loading.activate}
                    onClick={() => onUserAction('activate')}
                />
            );
            break;
        case 'Staff personal info':
        case 'Locum personal info':
            userControlButtons = (
                <ButtonComponent value="Edit" size="small" onClick={() => onChangeView('EditRightToWork')} />
            );
            break;
        default:
            userControlButtons = null;
    }

    const heading = isPersonalProfile ? 'Personal Information' : 'Information';

    const isAdminPermission = ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE);

    return (
        <>
            <div className="staff-info-page__header">
                <h3 className="staff-info-page__title">{heading}</h3>
                <div className="staff-info-page__row buttons-row with-tabs">
                    <InfoTabs tabs={tabs} />
                    {userControlButtons}
                </div>
            </div>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col fluid">
                        <h4 className="row-title">Right to work</h4>
                        <div className="grid-row">
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">Identification</p>
                                    <div className="description">
                                        {identificationExpDate
                                            ? moment(identificationExpDate).format('DD/MM/YYYY')
                                            : 'None'}
                                    </div>
                                </div>
                                {identification && !isAdminPermission ? (
                                    <UploadedDoc title={identification} />
                                ) : identification && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(identification, 'Identification')}
                                    />
                                ) : null}
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">Professional Indemnity Insurance</p>
                                    <div className="description">
                                        {professionalInsuranceExpDate
                                            ? moment(professionalInsuranceExpDate).format('DD/MM/YYYY')
                                            : 'None'}
                                    </div>
                                </div>
                                {professionalInsurance && !isAdminPermission ? (
                                    <UploadedDoc title={professionalInsurance} />
                                ) : professionalInsurance && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(professionalInsurance, 'professionalInsurance')}
                                    />
                                ) : null}
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">Visa (For Non UK members)</p>
                                    <div className="description">
                                        {visaExpDate ? moment(visaExpDate).format('DD/MM/YYYY') : 'None'}
                                    </div>
                                </div>
                                {visa && !isAdminPermission ? (
                                    <UploadedDoc title={visa} />
                                ) : visa && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(visa, 'visa')}
                                    />
                                ) : null}
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">Enchanced DBS Certificate</p>
                                    <div className="description">
                                        {DBSCertificateDate ? moment(DBSCertificateDate).format('DD/MM/YYYY') : 'None'}
                                    </div>
                                </div>
                                {DBSCertificate && !isAdminPermission ? (
                                    <UploadedDoc title={DBSCertificate} />
                                ) : DBSCertificate && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(DBSCertificate, 'DBSCertificate')}
                                    />
                                ) : null}
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">National Insurance Number</p>
                                    <div className="description">{insuranceNumber || 'None'}</div>
                                </div>
                                {insuranceNumberDoc && !isAdminPermission ? (
                                    <UploadedDoc title={insuranceNumberDoc} />
                                ) : insuranceNumberDoc && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(insuranceNumberDoc, 'insuranceNumberDoc')}
                                    />
                                ) : null}
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">DBS Annual Update</p>
                                    <div className="description">{DBSAnnualUpdate || 'None'}</div>
                                </div>
                                {DBSAnnualUpdateDoc && !isAdminPermission ? (
                                    <UploadedDoc title={DBSAnnualUpdateDoc} />
                                ) : DBSAnnualUpdateDoc && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(DBSAnnualUpdateDoc, 'DBSAnnualUpdateDoc')}
                                    />
                                ) : null}
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">Degree Qualification</p>
                                    <div className="description">{qualifications || 'None'}</div>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="info-box__item fluid inner-item">
                                    <p className="name">Year Qualified</p>
                                    <div className="description">{qualificationsYear || ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

RightToWorkBoard.propTypes = {
    data: PropTypes.object,
    activeView: PropTypes.string.isRequired,
    onTabClick: PropTypes.func.isRequired,
    onSaveFile: PropTypes.func.isRequired,
    accessStatus: PropTypes.string,
    onUserAction: PropTypes.func,
    loading: PropTypes.object,
    onChangeView: PropTypes.func,
    isPersonalProfile: PropTypes.bool
};

export default RightToWorkBoard;
