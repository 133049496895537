import {
    AUTH_USER_TRUE,
    AUTH_USER_FALSE,
    LOGOUT,
    REGISTER_PROCESS_TRUE,
    REGISTER_PROCESS_FALSE,
    UPDATE_USER_PROFILE
} from '../../actions/auth/action-types';
import {
    FORGOT_PASS_SUBMIT,
    FORGOT_PASS_SUCCESS,
    FORGOT_PASS_FAILED,
    PROFILE_RESET_PASS_SUBMIT,
    PROFILE_RESET_PASS_SUCCESS,
    PROFILE_RESET_PASS_FAILED,
    LOG_IN_SUCCESS
} from '../../actions/login-page/action-types';
import {
    RESEND_ACTIVATION_LINK_REQUEST,
    RESEND_ACTIVATION_LINK_SUCCESS,
    RESEND_ACTIVATION_LINK_FAILED
} from '../../actions/verification-page/action-types';
import { userRoles } from '../../../userConfig';

const initialState = {
    isAuth: undefined, // user is authenticated
    registerProcess: false, // user proceeded to additional registration forms
    authError: null, // authenticate error status
    user: null, // user object
    loading: false,
    roles: [
        userRoles.ADMIN,
        userRoles.MODERATOR,
        userRoles.BRANCH_ADMIN,
        userRoles.PHARMACY_MANAGER,
        userRoles.RELIEF,
        userRoles.BRANCH_PHARMACIST,
        userRoles.TECHNICIAN,
        userRoles.DISPENSER,
        userRoles.MEDICINE_COUNTER,
        userRoles.LOCUM
    ]
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_USER_TRUE:
            return {
                ...state,
                isAuth: true,
                user: action.payload
            };
        case AUTH_USER_FALSE:
            return {
                ...state,
                isAuth: false,
                authError: action.payload
            };
        case LOG_IN_SUCCESS:
            return {
                ...state,
                isAuth: true,
                user: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                isAuth: false,
                user: null
            };
        case REGISTER_PROCESS_TRUE:
            return {
                ...state,
                registerProcess: true
            };
        case REGISTER_PROCESS_FALSE:
            return {
                ...state,
                registerProcess: false
            };
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.payload.name]: action.payload.value
                }
            };
        case FORGOT_PASS_SUBMIT:
        case PROFILE_RESET_PASS_SUBMIT:
        case RESEND_ACTIVATION_LINK_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FORGOT_PASS_SUCCESS:
        case FORGOT_PASS_FAILED:
        case RESEND_ACTIVATION_LINK_SUCCESS:
        case RESEND_ACTIVATION_LINK_FAILED:
        case PROFILE_RESET_PASS_FAILED:
        case PROFILE_RESET_PASS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default auth;
