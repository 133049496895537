import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'semantic-ui-react';

import './style.scss';

const ArrowButton = ({ direction, color = 'blue', onClick }) => {
    return (
        <button className={`arrow-button ${color}`} onClick={onClick}>
            <Icon name={`chevron ${direction}`} />
        </button>
    );
};

ArrowButton.propTypes = {
    direction: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string
};

export default ArrowButton;
