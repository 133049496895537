import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form } from 'semantic-ui-react';

import ButtonComponent from '../../UI/buttons/button';
import ButtonModal from '../../UI/buttons/button-modal';
import CustomPagination from '../../custom-pagination';

import { connect } from 'react-redux';
import { getSelectedBranches } from '../../../store/actions';
import { paginate } from '../../../services/helpers';

import './style.scss';

function ActivatedBranchesModal({
    open,
    onClose,
    onSubmit,
    branches: {
        data: { rows },
        totalData,
        loading
    },
    selectedPlan: { branchesAllowed },
    submitLoading,
    getSelectedBranches
}) {
    // eslint-disable-next-line no-unused-vars
    const [activePage, setActivePage] = useState(1);
    const [activatedBranhces, setActivatedBranhces] = useState([]);

    const branches = useMemo(() => paginate(rows, 10, activePage), [rows, activePage]);

    const onGetActivatedBranches = useCallback((array) => {
        const activeBranches = array.reduce((result, item) => {
            const { id, active } = item;
            if (active) {
                result.push({
                    id,
                    active
                });
            }
            return result;
        }, []);
        setActivatedBranhces(activeBranches);
    }, []);

    useEffect(() => {
        onGetActivatedBranches(rows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPaginate = useCallback((event, { activePage }) => setActivePage(activePage), []);

    const onToggleBranchStatus = useCallback(
        (id) => {
            const updatedState = rows.map((item) => {
                if (item.id === id) {
                    item.active = !item.active;
                }
                return item;
            });
            onGetActivatedBranches(updatedState);
        },
        [onGetActivatedBranches, rows]
    );

    const onSubmitPlan = useCallback(() => {
        getSelectedBranches(activatedBranhces);
        onSubmit();
    }, [activatedBranhces, getSelectedBranches, onSubmit]);

    const pagination =
        totalData > 10 ? (
            <CustomPagination
                items={branches}
                onPageChange={onPaginate}
                totalPages={Math.ceil(totalData / 10)}
                modalView
            />
        ) : null;

    const displayBranches = branches.map((branch) => {
        const { id, title, regionName, active } = branch;

        return (
            <div className="branches-list__item" key={id}>
                <span className="text">{title}</span>
                <span className="text region">{regionName}</span>
                <ButtonModal
                    value={active ? 'deactivate' : 'activate'}
                    colorType={active ? 'red' : 'blue'}
                    size="medium"
                    onClick={(evt) => {
                        evt.preventDefault();
                        onToggleBranchStatus(id);
                    }}
                    disabled={loading || submitLoading}
                />
            </div>
        );
    });

    const counter = branchesAllowed ? (
        <p className="counter">{`${activatedBranhces.length}/${branchesAllowed} Activated`}</p>
    ) : (
        <p className="counter">{`${activatedBranhces.length} Activated`}</p>
    );

    return (
        <Modal closeIcon size="tiny" open={open} onClose={onClose} className="activated-branches-modal">
            <Modal.Header>
                <h2 className="activated-branches-modal__title">Activate Branches</h2>
            </Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <div className="titles-header">
                        <p className="col-title">Branch</p>
                        <p className="col-title">Region</p>
                        <p className="col-title">Action</p>
                    </div>
                    <div className="branches-list">{displayBranches}</div>
                    {pagination}
                    <div className="activated-branches-modal__buttons">
                        {counter}
                        <ButtonComponent
                            value="Submit"
                            size="medium"
                            onClick={(evt) => {
                                evt.preventDefault();
                                onSubmitPlan();
                            }}
                            loading={submitLoading}
                            disabled={
                                activatedBranhces.length === 0 ||
                                (branchesAllowed && activatedBranhces.length > branchesAllowed)
                            }
                        />
                    </div>
                </Form>
            </Modal.Content>
        </Modal>
    );
}

ActivatedBranchesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    branches: state.billingPage.branches,
    selectedPlan: state.billingPage.subscriptionPlan.selectedPlan,
    submitLoading: state.billingPage.subscriptionPlan.loading
});

const mapDispatchToProps = {
    getSelectedBranches
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivatedBranchesModal);
