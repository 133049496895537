import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';

import './style.scss';
import ButtonModal from '../../../../../../components/UI/buttons/button-modal';
import TextInput from '../../../../../../components/UI/inputs/text-input';
import SelectComponent from '../../../../../../components/UI/select';

const initialState = {
    name: '',
    foodCoverage: true,
    payOver: null,
    upTo: null,
    errors: {
        name: null,
        foodCoverage: null,
        payOver: null,
        upTo: null
    }
};

const CreateAccommodationAndFoodRuleForm = ({ onSubmit, onRemove, editableRule }) => {
    const [formData, setFormData] = useState(initialState);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    useEffect(() => {
        if (!editableRule) return;
        setFormData({
            ...formData,
            name: editableRule.name.toString(),
            foodCoverage: editableRule.foodCoverage,
            payOver: editableRule.payOver.toString(),
            upTo: editableRule.upTo.toString()
        });
        // eslint-disable-next-line
    }, [editableRule]);

    const onChangeHandler = (_, data) => {
        setFormData({
            ...formData,
            [data.name]: data.value
        });
    };

    const formIsValid = () => {
        const { name, upTo, payOver } = formData;
        return !(name && upTo && payOver);
    };

    const onFormSubmit = () => {
        onSubmit({
            ...formData,
            id: editableRule && editableRule.id,
            errors: undefined
        });
    };

    const confirmButton = {
        value: 'Yes',
        onClick: () => onRemove(),
        size: 'big',
        width: 'big'
    };

    return (
        <>
            {confirmPopupOpen && (
                <ConfirmModal
                    open={true}
                    onClose={() => setConfirmPopupOpen(!confirmPopupOpen)}
                    title="Delete rule"
                    text="Are you sure want to delete this Rule"
                    button={confirmButton}
                />
            )}
            <Form className="pharmacy-settings-create-rule-form" onSubmit={onFormSubmit}>
                <div className="content content-row">
                    <div className="content-row__item">
                        <div className="content-row__item reasons">
                            <div className="">
                                <TextInput
                                    name="name"
                                    label="RULE NAME"
                                    placeholder="Rule Name"
                                    type="text"
                                    value={formData.name}
                                    error={!!formData.errors.name}
                                    helperText={formData.errors.name}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-row__item">
                        <div className="content-row__item reasons">
                            <SelectComponent
                                name="foodCoverage"
                                icon="caret down"
                                floating
                                onChange={onChangeHandler}
                                options={[
                                    {
                                        key: 'true',
                                        value: true,
                                        text: 'Yes'
                                    },
                                    {
                                        key: 'false',
                                        value: false,
                                        text: 'No'
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <div className="content-row__item reasons">
                            <div className="">
                                <TextInput
                                    name="payOver"
                                    label="Pay over £X"
                                    type="number"
                                    min={1}
                                    max={99}
                                    placeholder="Pay over £X"
                                    value={formData.payOver || ''}
                                    error={!!formData.errors.payOver}
                                    helperText={formData.errors.payOver}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-row__item content">
                        <div className="">
                            <TextInput
                                name="upTo"
                                label="Up to £X"
                                type="number"
                                min={1}
                                max={99}
                                placeholder="Up to £X"
                                value={formData.upTo || ''}
                                error={!!formData.errors.upTo}
                                helperText={formData.errors.upTo}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>

                    <div className="buttons-row">
                        {editableRule && (
                            <ButtonModal
                                className="mr-2"
                                value={'Delete'}
                                colorType="red"
                                type="button"
                                onClick={() => setConfirmPopupOpen(true)}
                            />
                        )}
                        <ButtonModal
                            type="submit"
                            value={'Save'}
                            colorType="blue"
                            disabled={formIsValid()}
                            loading={false}
                        />
                    </div>
                </div>
            </Form>
        </>
    );
};

CreateAccommodationAndFoodRuleForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    editableRule: PropTypes.object
};

export default CreateAccommodationAndFoodRuleForm;
