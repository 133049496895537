import {
    GET_BRANCH_STAFF_SUCCESS,
    GET_BRANCH_STAFF_FAILED,
    GET_ALL_STAFF_SUCCESS,
    GET_ALL_STAFF_FAILED,
    POST_STAFF_ABSENCE_FAILED,
    POST_STAFF_ABSENCE_SUCCESS
} from '../../../actions/admin-view/branch-staff/action-types';

const initialState = {
    data: null,
    loading: false,
    absenceData: null
};

const branchStaffPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANCH_STAFF_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case GET_ALL_STAFF_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case POST_STAFF_ABSENCE_SUCCESS:
            return {
                ...state,
                absenceData: action.payload,
                loading: false
            };
        case GET_BRANCH_STAFF_FAILED:
        case GET_ALL_STAFF_FAILED:
        case POST_STAFF_ABSENCE_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default branchStaffPage;
