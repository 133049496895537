import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PersonalInfoBoard from './boards/PersonalInfoBoard';
import RightToWorkBoard from './boards/RightToWorkBoard';
import ExperienceBoard from './boards/ExperienceBoard';
import QualificationsBoard from './boards/QualificationsBoard';
import PersonalInfoForm from '../../../../components/forms/onboarding-forms/personal-info-form';
import RightToWork from '../../../../components/forms/onboarding-forms/right-to-work-form';
import SkillsForm from '../../../../components/forms/onboarding-forms/skills-form';
import AccreditationsForm from '../../../../components/forms/onboarding-forms/accreditations-form';

import { useStaffInfoAccessCheck } from '../../../../services/hooks';

import './style.scss';

export function StaffProfilePage({
    saveFileRequest,
    removeUploadedFileRequest,
    postPersonalInfoRequest,
    getRightsToWorkInfoRequest,
    postRightsToWorkInfoRequest,
    getSkillsInfoRequest,
    postSkillsInfoRequest,
    getAccreditationsInfoRequest,
    postAccreditationsInfoRequest,
    getProfileDataRequest,
    getInitLoading,
    personalInfo,
    rigthsToWork,
    experience,
    qualifications,
    userStatus,
    onUserAction,
    toggleAddReleaseLocumBankRequest,
    loading,
    removingLoading,
    updateInfoAction,
    profileAdditionalInfoData,
    isPersonalProfile = false
}) {
    const accessStatus = useStaffInfoAccessCheck(userStatus, personalInfo.role);
    const user = useSelector((state) => state.auth.user);
    const [activeView, setActiveView] = useState('Personal');
    const { staffId } = useParams();

    useEffect(() => {
        if (activeView === 'RightToWork') getRightsToWorkInfoRequest({ staffId });
        if (activeView === 'Experience') getSkillsInfoRequest({ staffId });
        if (activeView === 'Qualifications') getAccreditationsInfoRequest({ staffId });
        if (!Object.values(profileAdditionalInfoData).length) getProfileDataRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeView]);

    const onSaveFile = useCallback((fileUrl, fileName) => saveFileRequest({ fileUrl, fileName }), [saveFileRequest]);

    const onRemoveFile = useCallback(
        (payload, updateInfoAction, userInfoAction, onboardingForm) =>
            removeUploadedFileRequest(payload, updateInfoAction, userInfoAction, onboardingForm),
        [removeUploadedFileRequest]
    );

    const onPersonalInfoFormSubmit = useCallback(
        (payload) => {
            postPersonalInfoRequest(
                {
                    data: payload,
                    callback: () => setActiveView('Personal')
                },
                updateInfoAction,
                'auth'
            );
        },
        [postPersonalInfoRequest, updateInfoAction]
    );

    const onRightToWorkSubmit = useCallback(
        (payload) => {
            postRightsToWorkInfoRequest({
                data: payload,
                callback: () => setActiveView('RightToWork')
            });
        },
        [postRightsToWorkInfoRequest]
    );

    const onSkillsSubmit = useCallback(
        (payload) => {
            postSkillsInfoRequest({
                data: payload,
                callback: () => setActiveView('Experience')
            });
        },
        [postSkillsInfoRequest]
    );

    const onAccreditationsSubmit = useCallback(
        (payload) => {
            postAccreditationsInfoRequest(
                {
                    data: payload,
                    callback: () => setActiveView('Qualifications')
                },
                null,
                null
            );
        },
        [postAccreditationsInfoRequest]
    );
    const toggleAddToBank = useCallback(() => {
        toggleAddReleaseLocumBankRequest(personalInfo.id);
        // eslint-disable-next-line
    }, [toggleAddReleaseLocumBankRequest]);

    let displayView = null;

    const isAdminEdit = useCallback(
        (action, payload, updateInfoAction = null, userInfoAction = null, onboardingForm = null) => {
            if (user.role === 'branch_admin' && user.id !== personalInfo.id) {
                payload.id = personalInfo.id;
            }
            action(payload, updateInfoAction, userInfoAction, onboardingForm);
        },
        [user.id, user.role, personalInfo.id]
    );

    switch (activeView) {
        case 'Personal':
            displayView = (
                <PersonalInfoBoard
                    personal={personalInfo}
                    gphcInfo={personalInfo.gphcInfo}
                    provisionallyRegisterNumberData={personalInfo.provisionallyRegisterNumberData}
                    onChangeView={(view) => setActiveView(view)}
                    accessStatus={accessStatus}
                    onUserAction={onUserAction}
                    toggleAddToBank={toggleAddToBank}
                    loading={loading}
                    isPersonalProfile={isPersonalProfile}
                    activeView={activeView}
                    onTabClick={(view) => setActiveView(view)}
                />
            );
            break;
        case 'EditPersonal':
            displayView = (
                <PersonalInfoForm
                    userEmail={personalInfo.email}
                    filledForm={personalInfo}
                    loading={loading === undefined ? getInitLoading : loading}
                    onGoBack={() => setActiveView('Personal')}
                    isPersonalProfile
                    onSuccess={(payload) => isAdminEdit(onPersonalInfoFormSubmit, payload)}
                    onRemoveFile={(payload) => onRemoveFile(payload, null, 'auth')}
                    isEditProfile
                />
            );
            break;
        case 'RightToWork':
            displayView = rigthsToWork.data && (
                <RightToWorkBoard
                    data={rigthsToWork.data}
                    onSaveFile={onSaveFile}
                    onChangeView={(view) => setActiveView(view)}
                    accessStatus={accessStatus}
                    onUserAction={onUserAction}
                    loading={loading}
                    activeView={activeView}
                    onTabClick={(view) => setActiveView(view)}
                    isPersonalProfile={isPersonalProfile}
                />
            );
            break;
        case 'EditRightToWork':
            displayView = (
                <RightToWork
                    onSuccess={(payload) => isAdminEdit(onRightToWorkSubmit, payload)}
                    onRemoveRigthToWorkFile={(payload) => isAdminEdit(onRemoveFile, payload, null, null, null)}
                    onGoBack={() => setActiveView('RightToWork')}
                    filledForm={rigthsToWork.data}
                    loading={rigthsToWork.loading}
                    removingLoading={removingLoading}
                    isEditProfile
                />
            );
            break;
        case 'Experience':
            displayView = (
                <ExperienceBoard
                    data={experience.data}
                    onSaveFile={onSaveFile}
                    onChangeView={(view) => setActiveView(view)}
                    accessStatus={accessStatus}
                    onUserAction={onUserAction}
                    loading={loading}
                    activeView={activeView}
                    onTabClick={(view) => setActiveView(view)}
                    isPersonalProfile={isPersonalProfile}
                />
            );
            break;
        case 'EditExperience':
            displayView = (
                <SkillsForm
                    onSuccess={() => isAdminEdit(onSkillsSubmit, {})}
                    onGoBack={() => setActiveView('Experience')}
                    onRemoveSkillsFile={(payload) => isAdminEdit(onRemoveFile, payload, null, null, 'skills')}
                    isEditProfile
                />
            );
            break;
        case 'Qualifications':
            displayView = (
                <QualificationsBoard
                    data={qualifications.data}
                    onSaveFile={onSaveFile}
                    onChangeView={(view) => setActiveView(view)}
                    accessStatus={accessStatus}
                    onUserAction={onUserAction}
                    loading={loading}
                    activeView={activeView}
                    onTabClick={(view) => setActiveView(view)}
                    isPersonalProfile={isPersonalProfile}
                />
            );
            break;
        case 'EditQualifications':
            displayView = (
                <AccreditationsForm
                    onSuccess={() => isAdminEdit(onAccreditationsSubmit, {})}
                    onGoBack={() => setActiveView('Qualifications')}
                    onRemoveAccreditationsFile={(payload) => isAdminEdit(onRemoveFile, payload, null)}
                    isEditProfile
                />
            );
            break;
        default:
            break;
    }

    return <div className="staff-info-page">{displayView}</div>;
}

StaffProfilePage.propTypes = {
    personalInfo: PropTypes.object,
    userStatus: PropTypes.string,
    onUserAction: PropTypes.func,
    loader: PropTypes.objectOf(PropTypes.bool),
    updateInfoAction: PropTypes.func,
    isPersonalProfile: PropTypes.bool
};
