import {
    GET_STAFF_INFO_SUCCESS,
    GET_STAFF_INFO_FAILED,
    TOGGLE_ADD_RELSEASE_LOCUM_BANK_SUCCESS,
    TOGGLE_ADD_RELSEASE_LOCUM_BANK_FAILED
} from '../../../actions/admin-view/staff-info/action-types';

const initialState = {
    data: null,
    loading: false
};

const staffInfoPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_STAFF_INFO_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        case TOGGLE_ADD_RELSEASE_LOCUM_BANK_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    locumBank: action.payload
                }
            };
        case GET_STAFF_INFO_FAILED:
        case TOGGLE_ADD_RELSEASE_LOCUM_BANK_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default staffInfoPage;
