import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as StarLight } from '../../assets/icons/star-light.svg';
import { ReactComponent as StarFilled } from '../../assets/icons/star-filled.svg';
import { ReactComponent as StarPartial } from '../../assets/icons/star-partial.svg';

import './style.scss';

const StarsRating = ({ value, onRate, disabled, starsCount, starSize }) => {
    const [highestStarHovered, setHighestStarHovered] = useState(-Infinity);
    const [ratingValue, setRatingValue] = useState(value);

    useEffect(() => {
        setRatingValue(value);
    }, [value]);

    const hoverMode = highestStarHovered > 0;

    const renderStars = () => {
        const numberOfStarsArray = Array.apply(null, Array(starsCount));

        return numberOfStarsArray.map((_, i) => {
            const starRating = i + 1;

            const hoverMode = highestStarHovered > 0;
            const isStarred = starRating <= ratingValue;
            const isHovered = starRating <= highestStarHovered;
            const isPartiallyFullStar = starRating > ratingValue && starRating - 1 < ratingValue;

            let Component = StarLight;
            if (hoverMode) {
                if (isHovered) {
                    Component = StarFilled;
                }
            } else {
                if (isPartiallyFullStar) {
                    Component = StarPartial;
                } else if (isStarred) {
                    Component = StarFilled;
                }
            }

            return (
                <span
                    key={starRating}
                    onMouseEnter={!disabled ? () => setHighestStarHovered(starRating) : null}
                    onMouseLeave={!disabled ? () => setHighestStarHovered(-Infinity) : null}
                    className={classnames('star', hoverMode && isHovered && 'hovered')}
                    style={{
                        width: `${starSize}px`,
                        height: `${starSize}px`
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (onRate && !disabled) {
                            onRate(starRating);
                            setRatingValue(starRating);
                        }
                    }}
                >
                    <Component />
                </span>
            );
        });
    };

    return (
        <div className={classnames('stars', { disabled })} style={{ '--rating': hoverMode ? 0 : ratingValue }}>
            {renderStars()}
        </div>
    );
};

StarsRating.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    starSize: PropTypes.number,
    starsCount: PropTypes.number
};

StarsRating.defaultProps = {
    disabled: true,
    starsCount: 5,
    starSize: 25
};

export default StarsRating;
