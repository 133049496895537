import { ScheduleCalendarDayView } from './ScheduleCalendarDayView';
import { connect } from 'react-redux';
import {
    showBackButton,
    hideBackButton,
    getPostJobDraft,
    separateJob,
    longTermJob,
    getCurrentShift,
    getCurrentShiftId,
    getDayShiftsRequest,
    getBranchAvailableStaffRequest,
    onDayViewPostJobRequest,
    onDayViewSubmitDraftRequest,
    onDayViewSubmitShiftRequest,
    onRemoveShiftRequest,
    getLocumByGphcRequest,
    getLocumScheduleAvailabilityRequest,
    clearLocumData,
    getAllStaffRequest,
    postStaffAbsenceRequest,
    getJobLocumsByFilterRequest,
    clearGetJobLocumsByFilter,
    getAllMileageRulesRequest,
    getAllTransportRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    onSubmitBulkShiftRequest,
    onSubmitShiftDraftBulkRequest,
    onPostJobBulkRequest
} from '../../../../../../store/actions';

import { getCalculatedEmployeeShiftHoursClear } from '../../../../../../store/actions';

export default connect(
    (state) => ({
        currentDay: state.branchSchedule.currentDay,
        currentShift: state.branchScheduleDayView.currentShift,
        currentShiftId: state.branchScheduleDayView.currentShiftId,
        dayEvents: state.branchScheduleDayView.data,
        staffList: state.branchSchedule.staffList.data,
        loading: state.branchScheduleDayView.loading,
        removingLoading: state.branchScheduleDayView.removingLoading,
        postJobDraft: state.branchSchedule.postJob.jobData,
        operatingTime: state.branchInfoPage.branchInfo.data.operatingTime,
        shiftsList: state.branchSchedule.shiftsList,
        locumData: state.locumData,
        calculatedActualStaffShiftTime: state.absenceRequestForm.calculatedActualStaffShiftTime,
        locums: state.postJobForm.data,
        mileageRules: state.pharmacySettingsPage.mileageRules,
        transportRules: state.pharmacySettingsPage.transportRules,
        parkingRules: state.pharmacySettingsPage.parkingRules,
        accommodationRules: state.pharmacySettingsPage.accommodationRules,
        allStaff: state.branchStaffPage.data
    }),
    {
        showBackButton,
        hideBackButton,
        getPostJobDraft,
        separateJob,
        longTermJob,
        getCurrentShift,
        getCurrentShiftId,
        getDayShiftsRequest,
        getBranchAvailableStaffRequest,
        onDayViewPostJobRequest,
        onDayViewSubmitDraftRequest,
        onDayViewSubmitShiftRequest,
        onRemoveShiftRequest,
        getLocumByGphcRequest,
        getLocumScheduleAvailabilityRequest,
        clearLocumData,
        getAllStaffRequest,
        postStaffAbsenceRequest,
        getCalculatedEmployeeShiftHoursClear,
        getJobLocumsByFilterRequest,
        clearGetJobLocumsByFilter,
        getAllMileageRulesRequest,
        getAllTransportRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest,
        onSubmitBulkShiftRequest,
        onSubmitShiftDraftBulkRequest,
        onPostJobBulkRequest
    }
)(ScheduleCalendarDayView);
