import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';
import ButtonModal from '../../../UI/buttons/button-modal';
import SelectComponent from '../../../UI/select';

const RuleType = {
    Mileage: 'MILEAGE',
    Parking: 'PARKING',
    Transport: 'TRANSPORT',
    Accommodation: 'ACCOMMODATIONANDFOODS'
};

const rules = [
    {
        type: RuleType.Mileage,
        title: 'Mileage',
        name: 'mileageRuleId',
        items: []
    },
    {
        type: RuleType.Transport,
        title: 'Transport',
        name: 'transportRuleId',
        items: []
    },
    {
        type: RuleType.Accommodation,
        title: 'Accommodation&Food',
        name: 'accommodationAndFoodRuleId',
        items: []
    },
    {
        type: RuleType.Parking,
        title: 'Parking',
        name: 'parkingRuleId',
        items: []
    }
];

const initialState = {
    mileageRuleId: null,
    transportRuleId: null,
    parkingRuleId: null,
    accommodationAndFoodRuleId: null
};

function ExpenceCoverageForm({
    mileageRules,
    transportRules,
    parkingRules,
    accommodationRules,
    onSubmit,
    onCancel,
    submitLoading,
    savedExpences
}) {
    const [expences, setExpences] = useState(initialState);

    useEffect(() => {
        if (savedExpences)
            setExpences({
                ...savedExpences
            });
    }, [savedExpences]);

    const rulesWithData = useMemo(
        () =>
            rules.map((rule) => {
                switch (rule.type) {
                    case RuleType.Mileage:
                        rule.items = mileageRules.map((item) => {
                            return {
                                value: item.id,
                                key: item.id,
                                text: item.name
                            };
                        });
                        break;
                    case RuleType.Parking:
                        rule.items = parkingRules.map((item) => {
                            return {
                                value: item.id,
                                key: item.id,
                                text: item.name
                            };
                        });
                        break;
                    case RuleType.Transport:
                        rule.items = transportRules.map((item) => {
                            return {
                                value: item.id,
                                key: item.id,
                                text: item.name
                            };
                        });
                        break;
                    case RuleType.Accommodation:
                        rule.items = accommodationRules.map((item) => {
                            return {
                                value: item.id,
                                key: item.id,
                                text: item.name
                            };
                        });
                        break;
                    default:
                        return rule;
                }
                return rule;
            }),
        [mileageRules, transportRules, parkingRules, accommodationRules]
    );

    const onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;
        const newExpences = {
            ...expences,
            [name]: value
        };

        setExpences(newExpences);
    };

    const onFormSubmit = () => {
        onSubmit(expences);
    };

    const submitDisabled = () => {
        const { mileageRuleId, transportRuleId, parkingRuleId, accommodationAndFoodRuleId } = expences;
        return !mileageRuleId && !transportRuleId && !parkingRuleId && !accommodationAndFoodRuleId;
    };

    return (
        <Form className="post-job-form" onSubmit={onFormSubmit}>
            {rulesWithData.map((rule) => {
                return (
                    <div key={rule.type} className="content-row__item">
                        <div className="inner space-between">
                            <div>
                                <p className="title">{rule.title}</p>
                            </div>
                            <div>
                                <SelectComponent
                                    name={rule.name}
                                    icon="caret down"
                                    floating
                                    value={expences[rule.name] || ''}
                                    onChange={onChangeHandler}
                                    options={rule.items}
                                    // disabled={loading || isDisabled || canBeAdjusted}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="buttons-row double">
                <ButtonModal value={'Cancel'} colorType="red" type="button" onClick={() => onCancel()} />
                <ButtonModal
                    value={'Post'}
                    colorType="blue"
                    type="submit"
                    disabled={submitDisabled()}
                    loading={submitLoading}
                />
            </div>
        </Form>
    );
}

export default ExpenceCoverageForm;
