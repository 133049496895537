import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextInput from '../text-input';
import Datepicker from '../../datepicker';
import DateRangesPicker from '../../date-ranges-picker';
import SelectComponent from '../../select';
import { Form } from 'semantic-ui-react';

import './style.scss';

const TableInput = ({ type = 'search', title, format, ...props }) => {
    const input =
        type === 'disabled' ? null : (
            <>
                {type === 'search' && <TextInput type={type} {...props} />}
                {type === 'select' && <SelectComponent {...props} />}
                {type === 'date' && (
                    <Form autoComplete="off">
                        <Datepicker format={format} selected type="text" {...props} />
                    </Form>
                )}
                {type === 'range' && (
                    <Form autoComplete="off">
                        <DateRangesPicker format={format} selected {...props} />
                    </Form>
                )}
                {type === 'none' && <div className="noneView noneView-Center">&nbsp;</div>}
            </>
        );
    return (
        <div className="table__input">
            <h4
                className={classNames('table__input__header', {
                    'noneView-Center': type === 'none'
                })}
            >
                {title}
            </h4>
            {input}
        </div>
    );
};

TableInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    title: PropTypes.string.isRequired,
    format: PropTypes.string,
    type: PropTypes.oneOf(['search', 'date', 'select', 'autocomplete', 'disabled', 'none', 'range']),
    options: PropTypes.instanceOf(Array),
    displayProp: PropTypes.string
};

export default TableInput;
