import { LocumSettings } from './LocumSettings';
import { connect } from 'react-redux';

import { getLocumSettingsRequest, onLocumSettingsUpdateRequest } from '../../../../store/actions';

export default connect(
    (state) => ({
        settings: state.locumSettings
    }),
    { getLocumSettingsRequest, onLocumSettingsUpdateRequest }
)(LocumSettings);

/*

import {
  getStaffUserEventsRequest,
  onStaffUserEventsDateRangeChange,
} from "../../../../store/actions";

export default connect(
  (state) => ({
    schedule: state.staffUserSchedule,
  }),
  { getStaffUserEventsRequest, onStaffUserEventsDateRangeChange }
)(LocumSchedule);*/
