import React from 'react';
import PropTypes from 'prop-types';

import StaffItemCard from '../../../../../../components/UI/staff-item-card';
import JobItemCard from '../../../../../../components/UI/job-item-card';
import DayViewShiftCell from './DayViewShiftCell';

import { checkFirstShiftGap } from '../../../../../../services/helpers';
import { getShiftCellDurationSize } from '../../../../../../services/datesHelper';

import moment from 'moment';

const DayViewItem = ({
    data: { shifts, staff },
    startWorkingTime,
    endWorkingTime,
    onJobCellClick,
    onShiftCellClick,
    ...props
}) => {
    const displayCards = shifts.map((item, index) => {
        const { id, startTime, endTime, lunchStartTime, lunchEndTime } = item;
        const getWorkingTimeGap = checkFirstShiftGap(item, index, startWorkingTime);
        const getShiftCellSize = getShiftCellDurationSize(moment(endWorkingTime, 'HH:mm'), moment(endTime, 'HH:mm'));
        return (
            <DayViewShiftCell
                key={id}
                isDraft={item.isDraft}
                paidLunch={item.paidLunch}
                isJob={item.isJob}
                startTime={startTime}
                endTime={endTime}
                lunchStartTime={lunchStartTime}
                lunchEndTime={lunchEndTime}
                className={`${getWorkingTimeGap} ${getShiftCellSize}`}
                onClick={item.isJob ? () => onJobCellClick(id) : () => onShiftCellClick(id)}
            />
        );
    });

    // check if there is a gap at least for two hours between shifts
    let workingGap = false;

    shifts.reduce(function (previousValue, currentValue, currentIndex, array) {
        if (moment(currentValue.startTime, 'HH:mm').diff(moment(previousValue.endTime, 'HH:mm'), 'minutes') >= 120) {
            return (workingGap = true);
        }
        return (workingGap = false);
    });

    const resourceCard = staff.isJob ? (
        <JobItemCard id={staff.id} status={staff.status} hourlyRate={String(staff.hourlyRate)} type="small" />
    ) : (
        <StaffItemCard
            id={staff.id}
            name={staff.name}
            surname={staff.surname}
            image={staff.image}
            roleTitle={staff.roleTitle}
            type="small"
        />
    );

    return (
        <div className="schedule-day-view__day-view-item">
            <div className={`cell-holder main-cells ${workingGap ? 'has-working-gap' : ''}`}>
                {resourceCard}
                <div className="shifts">{displayCards}</div>
            </div>
        </div>
    );
};

DayViewItem.propTypes = {
    data: PropTypes.object,
    startWorkingTime: PropTypes.string,
    endWorkingTime: PropTypes.string,
    onJobCellClick: PropTypes.func.isRequired,
    onShiftCellClick: PropTypes.func.isRequired
};

export default DayViewItem;
