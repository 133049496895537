import { call, put, fork, select, takeEvery, takeLeading } from 'redux-saga/effects';
import {
    getPersonalInfoSuccess,
    getPersonalInfoFailed,
    postPersonalInfoSuccess,
    postPersonalInfoFailed,
    getRightsToWorkInfoSuccess,
    getRightsToWorkInfoFailed,
    postRightsToWorkInfoSuccess,
    postRightsToWorkInfoFailed,
    getSkillsInfoSuccess,
    getSkillsInfoFailed,
    postSkillsInfoSuccess,
    postSkillsInfoFailed,
    getAccreditationsInfoSuccess,
    getAccreditationsInfoFailed,
    postAccreditationsInfoSuccess,
    postAccreditationsInfoFailed,
    removeUploadedFileSuccess,
    removeUploadedFileFailed,
    authUserRequest,
    clearAccreditationsDocs,
    removeStoreDocument
} from '../../actions';
import {
    selectPersonalInfoLoading,
    selectRightsToWorkInfoLoading,
    selectSkillsData,
    selectSkillsInfoLoading,
    selectSkillsUploadedFiles,
    selectAdditionalAccreditations,
    selectPharmacySystems,
    selectAccreditationsInfoLoading,
    selectUploadedFiles
} from './selectors';
import * as userProfileActionTypes from '../../actions/profile/action-types';
import api from '../../../services/api';

function* onGetPersonalInfoRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: '/profile/personal-info',
            method: 'GET'
        });
        yield put(getPersonalInfoSuccess(data));
        if (payload) payload.callback();
    } catch (err) {
        yield put(getPersonalInfoFailed());
    }
}

function* onPostPersonalInfoRequest({ payload: { data, callback }, action, userInfoAction }) {
    const formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    const loading = yield select(selectPersonalInfoLoading);

    try {
        yield call(api.request, {
            url: '/profile/personal-info',
            method: 'POST',
            payload: formData,
            loading
        });

        yield put(postPersonalInfoSuccess());
        callback();
        if (action) yield put(action());
        if (userInfoAction && userInfoAction === 'auth') {
            yield put(authUserRequest());
        }
    } catch (err) {
        yield put(postPersonalInfoFailed());
    }
}

function* onGetRightsToWorkInfoRequest({ payload }) {
    const url = payload && payload.staffId ? `/staff/${payload.staffId}/right-to-work` : '/profile/right-to-work';
    try {
        const { data } = yield call(api.request, {
            url,
            method: 'GET'
        });
        yield put(getRightsToWorkInfoSuccess(data));
        if (payload.callback) payload.callback();
    } catch (err) {
        yield put(getRightsToWorkInfoFailed());
    }
}

function* onPostRightsToWorkInfoRequest({ payload: { data, callback } }) {
    const formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    const loading = yield select(selectRightsToWorkInfoLoading);

    try {
        yield call(api.request, {
            url: '/profile/right-to-work',
            method: 'POST',
            payload: formData,
            loading
        });
        yield put(postRightsToWorkInfoSuccess());
        callback();
    } catch (err) {
        yield put(postRightsToWorkInfoFailed());
    }
}

function* onGetSkillsInfoRequest({ payload }) {
    const url = payload && payload.staffId ? `/staff/${payload.staffId}/skills` : '/profile/skills';

    try {
        const { data } = yield call(api.request, {
            url,
            method: 'GET'
        });
        yield put(getSkillsInfoSuccess(data));
        if (payload.callback) payload.callback();
    } catch (err) {
        yield put(getSkillsInfoFailed());
    }
}

function* onPostSkillsInfoRequest({ payload: { data: additionalData, callback }, action }) {
    const { cv } = yield select(selectSkillsUploadedFiles);
    const data = { ...(yield select(selectSkillsData)), ...additionalData };
    const loading = yield select(selectSkillsInfoLoading);
    const pharmacySystems = yield select(selectPharmacySystems);

    const ignoredFieldsArray = [
        'cv',
        'uploadedFiles',
        'pharmacySystems',
        'selectedSystemsIds',
        'userId',
        'createdAt',
        'updatedAt'
    ];

    const formData = new FormData();
    for (let key in data) {
        if (!ignoredFieldsArray.some((field) => field === key)) formData.append(key, data[key]);
    }

    if (cv) formData.append('cv', cv);

    formData.append('pharmacySystems', pharmacySystems);

    try {
        yield call(api.request, {
            url: '/profile/skills',
            method: 'POST',
            payload: formData,
            loading
        });
        yield put(postSkillsInfoSuccess());
        callback();
        if (action) yield put(action());
    } catch (err) {
        yield put(postSkillsInfoFailed());
    }
}

function* onGetAccreditationsInfoRequest({ payload }) {
    const url = payload && payload.staffId ? `/staff/${payload.staffId}/accreditations` : '/profile/accreditations';

    try {
        const { data } = yield call(api.request, {
            url,
            method: 'GET'
        });
        yield put(getAccreditationsInfoSuccess(data));
        if (payload.callback) payload.callback();
    } catch (err) {
        yield put(getAccreditationsInfoFailed());
    }
}

function* onPostAccreditationsInfoRequest({ payload: { data: additionalData, callback }, action, userInfoAction }) {
    const data = { ...(yield select(selectUploadedFiles)), ...additionalData };
    const additionalAccreditations = yield select(selectAdditionalAccreditations);
    const loading = yield select(selectAccreditationsInfoLoading);

    const formData = new FormData();
    for (let key in data) {
        if (data[key]) formData.append(key, data[key]);
    }

    formData.append('additionalAccreditations', additionalAccreditations);

    try {
        yield call(api.request, {
            url: '/profile/accreditations',
            method: 'POST',
            payload: formData,
            loading
        });
        yield put(postAccreditationsInfoSuccess());
        callback();
        if (action) yield put(action());
        if (userInfoAction && userInfoAction === 'auth') {
            yield put(authUserRequest());
            yield put(clearAccreditationsDocs());
        }
    } catch (err) {
        yield put(postAccreditationsInfoFailed());
    }
}

function* onRemoveFileRequest({ payload, action, userInfoAction, onboardingForm }) {
    try {
        yield call(api.request, {
            url: '/profile/delete-file',
            method: 'DELETE',
            payload
        });
        yield put(removeUploadedFileSuccess());
        if (action) yield put(action());
        if (onboardingForm === 'accreditations') yield put(removeStoreDocument(payload.fileName));
        if (userInfoAction && userInfoAction === 'auth') {
            yield put(authUserRequest());
        }
    } catch (err) {
        yield put(removeUploadedFileFailed());
    }
}

// watchers
function* watchGetPersonalInfoRequest() {
    yield takeLeading(userProfileActionTypes.GET_PERSONAL_INFO_REQUEST, onGetPersonalInfoRequest);
}

function* watchPostPersonalInfoRequest() {
    yield takeEvery(userProfileActionTypes.POST_PERSONAL_INFO_REQUEST, onPostPersonalInfoRequest);
}

function* watchGetRightsToWorkInfoRequest() {
    yield takeLeading(userProfileActionTypes.GET_RIGHTS_TO_WORK_INFO_REQUEST, onGetRightsToWorkInfoRequest);
}

function* watchPostRightsToWorkInfoRequest() {
    yield takeEvery(userProfileActionTypes.POST_RIGHTS_TO_WORK_INFO_REQUEST, onPostRightsToWorkInfoRequest);
}

function* watchGetSkillsInfoRequest() {
    yield takeEvery(userProfileActionTypes.GET_SKILLS_INFO_REQUEST, onGetSkillsInfoRequest);
}

function* watchPostSkillsInfoRequest() {
    yield takeEvery(userProfileActionTypes.POST_SKILLS_INFO_REQUEST, onPostSkillsInfoRequest);
}

function* watchGetAccreditationsInfoRequest() {
    yield takeEvery(userProfileActionTypes.GET_ACCREDITATIONS_INFO_REQUEST, onGetAccreditationsInfoRequest);
}

function* watchPostAccreditationsInfoRequest() {
    yield takeEvery(userProfileActionTypes.POST_ACCREDITATIONS_INFO_REQUEST, onPostAccreditationsInfoRequest);
}

function* watchRemoveFileRequest() {
    yield takeEvery(userProfileActionTypes.REMOVE_UPLOADED_FILE_REQUEST, onRemoveFileRequest);
}

const userProfileInfoSaga = [
    fork(watchGetPersonalInfoRequest),
    fork(watchPostPersonalInfoRequest),
    fork(watchGetRightsToWorkInfoRequest),
    fork(watchPostRightsToWorkInfoRequest),
    fork(watchGetSkillsInfoRequest),
    fork(watchPostSkillsInfoRequest),
    fork(watchGetAccreditationsInfoRequest),
    fork(watchPostAccreditationsInfoRequest),
    fork(watchRemoveFileRequest)
];

export default userProfileInfoSaga;
