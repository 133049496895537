import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import PageNav from '../../../../../components/page-nav';
import TableComponent from '../../../../../components/table';
import { calculatePages } from '../../../../../services/helpers';
import { usePage, usePrevious } from '../../../../../services/hooks';

function StaffAbsencePage({ getStaffAbsenceRequest, clearStaffAbsenceFilters, onStaffAbsenceFilterChange }) {
    const { data, totalData, itemsPerPage, filters, isFiltering, redirectPageTo } = useSelector(
        (state) => state.staffAbsencePage
    );

    const user = useSelector((state) => state.auth.user);
    const store = useDispatch();

    const history = useHistory();
    const { pathname } = useLocation();
    const {
        params: { id, staffId }
    } = useRouteMatch();

    const page = usePage();
    const prevPage = usePrevious(page);
    const prevRoute = usePrevious(pathname);

    const removeUnderscore = (text) => {
        return text.split('_').join(' ');
    };

    const updateFilter = (syntheticEvent, eventObj) => {
        const pageNumber = page || 1;
        const { name, value } = !eventObj ? syntheticEvent.target : eventObj;
        let inputValue = value;
        if (value && eventObj.isDatepicker) {
            if (Array.isArray(value)) {
                inputValue = value.map((d) => moment(d).format('YYYY-MM-DD'));
            } else {
                inputValue = moment(value).format('YYYY-MM-DD');
            }
        } else if (!value && eventObj.isDatepicker) {
            inputValue = '';
        }
        store(
            onStaffAbsenceFilterChange(
                { name, value: inputValue, staffId: parseInt(staffId) || user.id, branchId: parseInt(id) },
                Number(pageNumber)
            )
        );
    };

    const tableCols = [
        {
            name: 'from',
            title: 'From',
            value: filters.from ? new Date(filters.from) : '',
            render: (item) => moment(item.from).format('MMM Do, YYYY'),
            className: 'class-postedJob-jobId',
            type: 'date',
            onChange: (syntheticEvent, eventObj) => updateFilter(syntheticEvent, eventObj)
        },
        {
            name: 'to',
            title: 'To',
            value: filters.to ? new Date(filters.to) : '',
            render: (item) => moment(item.to).format('MMM Do, YYYY'),
            className: 'class-postedJob-dates',
            type: 'date',
            onChange: (syntheticEvent, eventObj) => updateFilter(syntheticEvent, eventObj)
        },
        {
            name: 'calculatedTimeMS',
            title: 'Total Hours',
            value: filters.calculatedTimeMS,
            render: (item) => item.calculatedTimeMS,
            className: 'class-postedJob-dates',
            type: 'search',
            onChange: (syntheticEvent, eventObj) => updateFilter(syntheticEvent, eventObj)
        },
        {
            name: 'type',
            title: 'Type',
            value: filters.type,
            render: (item) => removeUnderscore(item.type),
            onChange: (syntheticEvent, eventObj) => updateFilter(syntheticEvent, eventObj),
            type: 'select',
            options: [
                {
                    value: 'none',
                    text: 'None'
                },
                {
                    value: 'annual_leave',
                    text: 'Annual Leave'
                },
                {
                    value: 'sick_leave',
                    text: 'Sick Leave'
                },
                {
                    value: 'bank_holiday',
                    text: 'Bank Holiday'
                },
                {
                    value: 'branch_swap',
                    text: 'Branch Swap'
                },
                {
                    value: 'maternity_leave',
                    text: 'Maternity Leave'
                },
                {
                    value: 'paternity_leave',
                    text: 'Paternity Leave'
                }
            ],
            search: true,
            icon: 'search'
        },
        {
            name: 'notes',
            title: 'Notes',
            value: filters.notes,
            render: (item) => item.notes,
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: (syntheticEvent, eventObj) => updateFilter(syntheticEvent, eventObj)
        }
    ];

    useEffect(() => {
        const unlisten = history.listen(({ pathname }) => {
            if (pathname !== prevRoute) clearStaffAbsenceFilters();
        });
        return () => {
            unlisten();
        };
    }, [history, prevRoute, clearStaffAbsenceFilters]);

    const buildFilter = useCallback(() => {
        return {
            ...filters,
            branchId: parseInt(id)
        };
    }, [filters, id]);

    useEffect(() => {
        if (!user) return;
        if (!page && !staffId && !id) return;
        const userId = parseInt(staffId) || user.id;
        if (page && page !== prevPage && prevPage === undefined) {
            getStaffAbsenceRequest({ page, staffId: userId, branchId: parseInt(id) }, buildFilter());
        } else if (page && page !== prevPage && !isFiltering) {
            getStaffAbsenceRequest({ page, staffId: userId, branchId: parseInt(id) }, buildFilter());
        } else if (page && page !== prevPage && isFiltering) {
            if (page <= calculatePages(totalData, itemsPerPage)) {
                getStaffAbsenceRequest({ page, staffId: userId, branchId: parseInt(id) }, buildFilter());
            }
        }
    }, [
        getStaffAbsenceRequest,
        filters,
        isFiltering,
        page,
        prevPage,
        redirectPageTo,
        totalData,
        itemsPerPage,
        user,
        staffId,
        id,
        buildFilter
    ]);

    return (
        <div className="posted-jobs-admin-page">
            <div className="main-container">
                <PageNav title="Absence" />
                <TableComponent
                    cols={tableCols}
                    data={data.rows || []}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                    getItem
                />
            </div>
        </div>
    );
}

export default StaffAbsencePage;
