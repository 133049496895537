import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from 'semantic-ui-react';

import './style.scss';

const JobItemCard = ({
    id,
    status,
    hourlyRate,
    duration = '',
    totalAmount = '',
    startTime,
    endTime,
    type,
    staff,
    onDetailsButtonClick
}) => {
    const getLocumName = (locum) => {
        let name = '';

        if (locum.name) {
            name = `${locum.name} ${locum.surname || ''}`;
        } else {
            name = `${locum.forenames || ''} ${locum.surname || ''}`;
        }

        return name;
    };

    let getJobStatus = 'search';
    let getJobTitle = staff ? getLocumName(staff) : 'Job Posted';

    return (
        <div className="job-item-card">
            <div className="job-item-card__container">
                <div className="col col-left">
                    <div className={classnames(`status-holder ${getJobStatus}`, status)}>
                        <Icon name={getJobStatus} />
                    </div>
                    <div className="text-wrapper">
                        <p
                            className={classnames('title', onDetailsButtonClick && 'link')}
                            onClick={() => onDetailsButtonClick && onDetailsButtonClick({ jobId: id })}
                        >
                            {getJobTitle}
                        </p>
                        <p className="rate">{`Hourly Rate: £${hourlyRate}`}</p>
                    </div>
                </div>
                <div className={`col col-right ${type === 'small' ? 'small' : ''}`}>
                    <div className="info-item">
                        <p className="time">{`${startTime} - ${endTime}`}</p>
                        <div className="duration">{`${duration} (£${totalAmount})`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

JobItemCard.propTypes = {
    id: PropTypes.number.isRequired,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    hourlyRate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    duration: PropTypes.string,
    totalAmount: PropTypes.string,
    onEditButtonClick: PropTypes.func,
    onDetailsButtonClick: PropTypes.func
};

export default JobItemCard;
