import React from 'react';
import './style.scss';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const DataTableSectionItem = ({ description, value, classes = {} }) => {
    return (
        <div className="data-table-section-item">
            <div className="data-table-section-item__row">
                <div
                    className={classNames(
                        'data-table-section-item__row-item data-table-section-item__row-item--description',
                        { [classes.description]: classes?.description }
                    )}
                >
                    {typeof description === 'string' ? (
                        <p className={classNames('data-table-section-item__row-item-title')}>{description}</p>
                    ) : (
                        description
                    )}
                </div>
                <div className="data-table-section-item__row-item">
                    {typeof value === 'string' ? (
                        <p className="data-table-section-item__row-item-title">{value}</p>
                    ) : (
                        value
                    )}
                </div>
            </div>
        </div>
    );
};

DataTableSectionItem.propTypes = {
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    classes: PropTypes.shape({
        description: PropTypes.string
    })
};

export default DataTableSectionItem;
