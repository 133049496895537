import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Icon } from 'semantic-ui-react';

import './style.scss';

const EditTemplateModal = ({
    size = 'small',
    open,
    onClose,
    closeIcon = true,
    title,
    subtitle,
    type,
    color = 'blue',
    ...props
}) => {
    let iconStatus;
    if (type === 'add') {
        iconStatus = 'plus';
    } else if (type === 'edit') {
        iconStatus = 'pencil';
    } else if (type === 'search') {
        iconStatus = 'search';
    } else if (type === 'calendar') {
        iconStatus = 'calendar';
    } else if (type === 'none') {
        iconStatus = 'remove';
    } else {
        iconStatus = 'remove';
    }

    return (
        <Modal closeIcon={closeIcon} size={size} open={open} onClose={onClose} className="edit-template-modal">
            <Modal.Header className={`edit-template-modal__header ${color}`}>
                <Icon name={iconStatus} className={`header-icon ${iconStatus}`} />
                <div className="title-holder">
                    <p className="header-title">{title}</p>
                    <small className="header-subtitle">{subtitle}</small>
                </div>
            </Modal.Header>
            <Modal.Content>{props.children}</Modal.Content>
        </Modal>
    );
};

EditTemplateModal.propTypes = {
    size: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string.isRequired,
    color: PropTypes.string
};

export default EditTemplateModal;
