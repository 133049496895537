import React from 'react';
import PropTypes from 'prop-types';

import ButtonModal from '../../UI/buttons/button-modal';

import { Icon } from 'semantic-ui-react';

import moment from 'moment';

function EditSubscriptionNotification({ subscriptionEndDate, onClick, isDowngrade, isImmediately }) {
    const buttonHolder = !isImmediately ? (
        <>
            <p className="date">{moment(subscriptionEndDate, 'YYYY-MM-DD').format('Do MMM YYYY')}</p>
            <ButtonModal value={`${isDowngrade ? 'Downgrade' : 'Cancel'} now`} onClick={onClick} colorType="red" />
        </>
    ) : (
        <p className="date">Immediately</p>
    );

    return (
        <div className="edit-subscription-modal__notification">
            <div className="row">
                <div className="col icon">
                    <Icon name="warning sign" size="large" />
                </div>
                <div className="col content">
                    <p className="text">{`Note: Subscription will be ${isDowngrade ? 'downgraded' : 'cancelled'}`}</p>
                    <div className="button-holder">{buttonHolder}</div>
                </div>
            </div>
        </div>
    );
}

EditSubscriptionNotification.propTypes = {
    subscriptionEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onClick: PropTypes.func.isRequired,
    isDowngrade: PropTypes.bool,
    isImmediately: PropTypes.bool
};

export default EditSubscriptionNotification;
