import {
    ADD_LOCUM_BANK_SUCCESS,
    ADD_LOCUM_BANK_FAILED,
    GET_LOCUM_BANK_BY_FILTER_FAILED,
    CLEAR_LOCUM_BANK_FILTERS,
    GET_LOCUM_BANK_BY_FILTER_SUCCESS,
    ON_LOCUM_BANK_FILTER_CHANGE
} from '../../../actions/admin-view/locum-bank-page/action-types';

const initialState = {
    filters: {
        locumName: '',
        phone: '',
        gphcNumber: '',
        dob: null,
        city: ''
    },
    isFiltering: false,
    redirectPageTo: null,
    loading: false,
    data: {},
    totalData: 0,
    itemsPerPage: 9
};

const locumBankPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCUM_BANK_BY_FILTER_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case ADD_LOCUM_BANK_SUCCESS:
            return {
                ...state,
                totalData: state.totalData + 1,
                data: {
                    ...state.data,
                    count: state.data.count + 1,
                    rows: [{ locum: action.payload.data }, ...state.data.rows]
                },
                redirectPageTo: null
            };
        case GET_LOCUM_BANK_BY_FILTER_FAILED:
        case ADD_LOCUM_BANK_FAILED:
            return {
                ...state,
                loading: false
            };
        case ON_LOCUM_BANK_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                },
                isFiltering: true
            };
        case CLEAR_LOCUM_BANK_FILTERS:
            return {
                ...state,
                filters: {
                    ...initialState.filters
                },
                isFiltering: false
            };
        default:
            return state;
    }
};

export default locumBankPage;
