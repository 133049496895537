import {
    GET_CURRENT_SHIFT,
    GET_CURRENT_SHIFT_ID,
    GET_DAY_SHIFTS_REQUEST,
    GET_DAY_SHIFTS_SUCCESS,
    GET_DAY_SHIFTS_FAILED,
    ON_DAY_VIEW_POST_JOB_REQUEST,
    ON_DAY_VIEW_POST_JOB_SUCCESS,
    ON_DAY_VIEW_POST_JOB_FAILED,
    ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST,
    ON_DAY_VIEW_SUBMIT_DRAFT_SUCCESS,
    ON_DAY_VIEW_SUBMIT_DRAFT_FAILED,
    ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST,
    ON_DAY_VIEW_SUBMIT_SHIFT_SUCCESS,
    ON_DAY_VIEW_SUBMIT_SHIFT_FAILED,
    ON_REMOVE_SHIFT_REQUEST,
    ON_REMOVE_SHIFT_SUCCESS,
    ON_REMOVE_SHIFT_FAILED
} from './action-types';

export const getCurrentShift = (data) => ({
    type: GET_CURRENT_SHIFT,
    payload: data
});

export const getCurrentShiftId = (id) => ({
    type: GET_CURRENT_SHIFT_ID,
    payload: id
});

export const getDayShiftsRequest = (payload) => ({
    type: GET_DAY_SHIFTS_REQUEST,
    payload
});

export const getDayShiftsSuccess = (payload) => ({
    type: GET_DAY_SHIFTS_SUCCESS,
    payload
});

export const getDayShiftsFailed = () => ({
    type: GET_DAY_SHIFTS_FAILED
});

export const onDayViewSubmitShiftRequest = (payload) => ({
    type: ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST,
    payload
});

export const onDayViewSubmitShiftSuccess = () => ({
    type: ON_DAY_VIEW_SUBMIT_SHIFT_SUCCESS
});

export const onDayViewSubmitShiftFailed = () => ({
    type: ON_DAY_VIEW_SUBMIT_SHIFT_FAILED
});

export const onDayViewSubmitDraftRequest = (payload) => ({
    type: ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST,
    payload
});

export const onDayViewSubmitDraftSuccess = () => ({
    type: ON_DAY_VIEW_SUBMIT_DRAFT_SUCCESS
});

export const onDayViewSubmitDraftFailed = () => ({
    type: ON_DAY_VIEW_SUBMIT_DRAFT_FAILED
});

export const onDayViewPostJobRequest = (payload) => ({
    type: ON_DAY_VIEW_POST_JOB_REQUEST,
    payload
});

export const onDayViewPostJobSuccess = () => ({
    type: ON_DAY_VIEW_POST_JOB_SUCCESS
});

export const onDayViewPostJobFailed = () => ({
    type: ON_DAY_VIEW_POST_JOB_FAILED
});

export const onRemoveShiftRequest = (payload) => ({
    type: ON_REMOVE_SHIFT_REQUEST,
    payload
});

export const onRemoveShiftSuccess = () => ({
    type: ON_REMOVE_SHIFT_SUCCESS
});

export const onRemoveShiftFailed = () => ({
    type: ON_REMOVE_SHIFT_FAILED
});
