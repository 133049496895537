import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'semantic-ui-react';

import ButtonComponent from '../../UI/buttons/button';

import './style.scss';

function PurchaseSuccessModal({ open, onClose }) {
    const [closeCounter, setCloseCounter] = useState(5);

    useEffect(() => {
        const triggerCounter = setInterval(() => setCloseCounter(closeCounter - 1), 1000);
        setTimeout(() => {
            onClose();
        }, 5500);
        return () => {
            clearInterval(triggerCounter);
        };
    }, [closeCounter, onClose]);

    return (
        <Modal closeIcon size="tiny" open={open} onClose={onClose} className="purchase-success-modal">
            <Modal.Header className="purchase-success-modal__header">Success!</Modal.Header>
            <Modal.Content>
                <p className="content-text">Congratulations! Your purchase was successful!</p>
                <small>This window will close automatically</small>
                <ButtonComponent size="medium" value={String(closeCounter)} onClick={onClose} />
            </Modal.Content>
        </Modal>
    );
}

PurchaseSuccessModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default PurchaseSuccessModal;
