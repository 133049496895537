import {
    GET_BRANCHES_LIST_SUCCESS,
    GET_BRANCHES_LIST_FAILED,
    ON_BRANCHES_FILTER_CHANGE,
    ON_BRANCHES_FILTER_FALSE,
    CLEAR_BRANCHES_FILTERS
} from '../../../actions/admin-view/branches-page/action-types';

const initialState = {
    filters: {
        number: '',
        title: '',
        phone: '',
        region: '',
        address: '',
        active: ''
    },
    isFiltering: false,
    redirectPageTo: null,
    loading: false,
    data: {},
    totalData: 0,
    itemsPerPage: 9
};

const branchesListPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANCHES_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case ON_BRANCHES_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                },
                isFiltering: true
            };
        case ON_BRANCHES_FILTER_FALSE:
            return {
                ...state,
                isFiltering: false,
                redirectPageTo: 1
            };
        case GET_BRANCHES_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        case CLEAR_BRANCHES_FILTERS:
            return {
                ...state,
                filters: {
                    number: '',
                    title: '',
                    phone: '',
                    region: '',
                    address: ''
                },
                isFiltering: false
            };
        default:
            return state;
    }
};

export default branchesListPage;
