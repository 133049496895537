import React from 'react';
import PropTypes from 'prop-types';
import ButtonModal from '../buttons/button-modal';

import './style.scss';

const AttachedStaffCard = ({ name, surname, id, role, image, onDangerButtonClick }) => {
    const onDangerButton = (e) => {
        e.preventDefault();
        onDangerButtonClick(id);
    };

    return (
        <div className="attached-staff-card">
            <div className="attached-staff-card__container">
                <div className="col col-left">
                    <img
                        src={image}
                        alt={image === undefined || image === null ? '' : 'staff'}
                        className={image === undefined || image === null ? 'error' : ''}
                    />
                    <div className="text-wrapper">
                        <p className="name">{`${name} ${surname.charAt(0)}.`}</p>
                        <p className="role">{role ? role.replace(/_/g, ' ') : null}</p>
                    </div>
                </div>
                <div className="col col-right">
                    <div className="info-item">
                        <ButtonModal colorType="red" value="remove" onClick={onDangerButton} />
                    </div>
                </div>
            </div>
        </div>
    );
};

AttachedStaffCard.propTypes = {
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    image: PropTypes.string,
    onDangerButtonClick: PropTypes.func
};

export default AttachedStaffCard;
