import { GET_PHARMACY_STAFF_REQUEST, GET_PHARMACY_STAFF_SUCCESS, GET_PHARMACY_STAFF_FAILED } from './action-types';

export const getPharmacyStaffRequest = (payload) => ({
    type: GET_PHARMACY_STAFF_REQUEST,
    payload
});

export const getPharmacyStaffSuccess = (payload) => ({
    type: GET_PHARMACY_STAFF_SUCCESS,
    payload
});

export const getPharmacyStaffFailed = () => ({
    type: GET_PHARMACY_STAFF_FAILED
});
