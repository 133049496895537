import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { JOB } from '../../services/constants';
import { userRoles } from '../../userConfig';

const WeekViewShiftCell = ({ shifts, status, className, onClick }) => {
    const getRoleSuffix = (role) => {
        switch (role) {
            case userRoles.PHARMACY_MANAGER:
                return 'PM';
            case userRoles.ADMIN:
                return 'Admin';
            case userRoles.BRANCH_ADMIN:
                return 'BA';
            case 'branch_manager':
                return 'BM';
            case userRoles.RELIEF:
                return 'Relief';
            case userRoles.TECHNICIAN:
                return 'Technician';
            case userRoles.BRANCH_PHARMACIST:
                return 'P';
            case userRoles.LOCUM:
            default:
                return 'Locum';
        }
    };

    return (
        <div onClick={onClick} className={classnames('schedule-page__shift-cell', className, status)}>
            <div className="inner">
                {status === 'closed' ? (
                    <>
                        <LockIcon className="lock-icon" />
                        <span className="inner__title">Branch Closed</span>
                    </>
                ) : (
                    <>
                        <span className="inner__lunch">
                            {shifts.map((shift) => {
                                let shiftTitle = '';
                                if (shift.isJob) {
                                    shiftTitle = shift.status === 'pending' ? 'Pending' : 'Looking for a locum...';
                                } else {
                                    shiftTitle = 'Empty Shift';
                                }

                                const activeStaff = shift.isJob
                                    ? (shift.staff || []).find((staff) => staff.status === 'accepted')
                                    : shift.staff;
                                if (shift.status === JOB.PENDING) {
                                    //   shiftTitle = `Pending – ${shift.staff.filter(s => s.status === 'applied').length}`;
                                } else if (shift.isJob && activeStaff) {
                                    shiftTitle = `${activeStaff.name || activeStaff.gphcNumber} • ${getRoleSuffix(
                                        activeStaff.role
                                    )}`;
                                } else if (!shift.status && activeStaff) {
                                    const { name, surname, role } = activeStaff;
                                    const shiftName = name
                                        ? surname
                                            ? `${name.charAt(0)}. ${surname}`
                                            : name
                                        : surname || 'Shift';
                                    shiftTitle = `${shiftName} • ${getRoleSuffix(role)}`;
                                }

                                return (
                                    <div
                                        key={shift.id}
                                        className={classnames('shift', {
                                            unfilled: shift.isJob ? !shift.status : false,
                                            [shift.status]: shift.isJob
                                        })}
                                    >
                                        <div className="shift-inner">
                                            <div className="shift__time">{`${shift.startTime} - ${shift.endTime}`}</div>
                                            <div className="shift__title">{shiftTitle}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

WeekViewShiftCell.propTypes = {
    shifts: PropTypes.array,
    status: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default WeekViewShiftCell;
