import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { getLocumsByGphcSuccess, getLocumsByGphcLoading } from '../../../actions';

import * as locumBankActionTypes from '../../../actions/admin-view/locum-bank-add-locum-form/action-types';
import api from '../../../../services/api';

// function* onPostPaymentMethodRequest({ payload: { id, toggleModal } }) {
//   try {
//     yield call(api.request, {
//       url: "/payments/payment-method",
//       method: "POST",
//       payload: {
//         paymentMethodId: id,
//       },
//     });
//     yield put(postPaymentMethodSuccess());
//     toggleModal();
//     yield put(getPaymentMethodRequest());
//   } catch (err) {
//     yield put(postPaymentMethodFailed());
//   }
// }

function* onGetLocumBankLocumsRequest({ payload }) {
    try {
        yield put(getLocumsByGphcLoading());
        const { data } = yield call(api.request, {
            url: `/locum-banks/gphc/${payload}`,
            method: 'GET'
        });
        yield put(getLocumsByGphcSuccess(data));
    } catch (err) {
        // yield put(getLocumsByGphcFailed());
    }
}

function* watchGetLocumBankLocumsRequest() {
    yield takeEvery(locumBankActionTypes.GET_LOCUMS_BY_GPHC_REQUEST, onGetLocumBankLocumsRequest);
}

const locumBankAddLocumFormSaga = [fork(watchGetLocumBankLocumsRequest)];

export default locumBankAddLocumFormSaga;
