import {
    GET_JOB_DETAILS_REQUEST,
    GET_JOB_DETAILS_SUCCESS,
    GET_JOB_DETAILS_FAILED,
    GET_WORKED_LOCUMS_REQUEST,
    GET_WORKED_LOCUMS_SUCCESS,
    GET_WORKED_LOCUMS_FAILED,
    ON_EDIT_JOB_REQUEST,
    ON_EDIT_JOB_SUCCESS,
    ON_EDIT_JOB_FAILED,
    ON_CANCEL_JOB_REQUEST,
    ON_CANCEL_JOB_SUCCESS,
    ON_CANCEL_JOB_FAILED,
    ON_LOCUM_ACTION_REQUEST,
    ON_LOCUM_ACTION_SUCCESS,
    ON_LOCUM_ACTION_FAILED,
    GET_JOB_CANCEL_REASONS_FAILED,
    GET_JOB_CANCEL_REASONS_REQUEST,
    GET_JOB_CANCEL_REASONS_SUCCESS
} from './action-types';

export const getJobDetailsRequest = (jobId) => ({
    type: GET_JOB_DETAILS_REQUEST,
    jobId
});

export const getJobDetailsSuccess = (payload) => ({
    type: GET_JOB_DETAILS_SUCCESS,
    payload
});

export const getJobDetailsFailed = () => ({
    type: GET_JOB_DETAILS_FAILED
});

export const getWorkedLocumsRequest = () => ({
    type: GET_WORKED_LOCUMS_REQUEST
});

export const getWorkedLocumsSuccess = (payload) => ({
    type: GET_WORKED_LOCUMS_SUCCESS,
    payload
});

export const getWorkedLocumsFailed = () => ({
    type: GET_WORKED_LOCUMS_FAILED
});

export const onEditJobRequest = (payload) => ({
    type: ON_EDIT_JOB_REQUEST,
    payload
});

export const onEditJobSuccess = () => ({
    type: ON_EDIT_JOB_SUCCESS
});

export const onEditJobFailed = () => ({
    type: ON_EDIT_JOB_FAILED
});

export const onCancelJobRequest = (payload) => ({
    type: ON_CANCEL_JOB_REQUEST,
    payload
});

export const onCancelJobSuccess = () => ({
    type: ON_CANCEL_JOB_SUCCESS
});

export const onCancelJobFailed = () => ({
    type: ON_CANCEL_JOB_FAILED
});

export const onLocumActionRequest = (payload) => ({
    type: ON_LOCUM_ACTION_REQUEST,
    payload
});

export const onLocumActionSuccess = (payload) => ({
    type: ON_LOCUM_ACTION_SUCCESS,
    payload
});

export const onLocumActionFailed = (payload) => ({
    type: ON_LOCUM_ACTION_FAILED,
    payload
});

export const getJobCancelReasonsRequest = () => ({
    type: GET_JOB_CANCEL_REASONS_REQUEST
});

export const getJobCancelReasonsFailed = () => ({
    type: GET_JOB_CANCEL_REASONS_FAILED
});

export const getJobCancelReasonsSuccess = (payload) => ({
    type: GET_JOB_CANCEL_REASONS_SUCCESS,
    payload
});
