import {
    GET_STAFF_SHIFTS_FOR_ADMIN_SUCCESS,
    GET_STAFF_SHIFTS_FOR_ADMIN_FAILED
} from '../../../actions/admin-view/staff-schedule/action-types';

const initialState = {
    data: null,
    loading: false
};

const staffSchedulePage = (state = initialState, action) => {
    switch (action.type) {
        case GET_STAFF_SHIFTS_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case GET_STAFF_SHIFTS_FOR_ADMIN_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default staffSchedulePage;
