import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getBranchesDataSuccess,
    getBranchesDataFailed,
    onCreateBranchSuccess,
    onCreateBranchFailed,
    onUpdateBranchRateSuccess,
    onUpdateBranchRateFailed
} from '../../../actions';
import * as createBrancheActionTypes from '../../../actions/admin-view/create-branch/action-types';
import api from '../../../../services/api';

function* getBranchesDataRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/branches/get-data',
            method: 'get'
        });
        yield put(getBranchesDataSuccess(data));
    } catch (err) {
        yield put(getBranchesDataFailed());
    }
}

function* onCreateBranchRequest({ payload: { formData, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/branches',
            method: 'post',
            payload: formData
        });
        yield put(onCreateBranchSuccess(data));
        callback();
    } catch (err) {
        yield put(onCreateBranchFailed());
    }
}

function* onBranchWeekDayRateUpdateRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${payload.branchId}/${payload.dayOfWeek}/update-rate`,
            method: 'post',
            payload: {
                value: payload.value
            }
        });
        yield put(onUpdateBranchRateSuccess(data));
    } catch (err) {
        yield put(onUpdateBranchRateFailed());
    }
}

function* watchBranchesDataRequest() {
    yield takeEvery(createBrancheActionTypes.GET_BRANCHES_DATA_REQUEST, getBranchesDataRequest);
}

function* watchCreateBranchRequest() {
    yield takeEvery(createBrancheActionTypes.ON_CREATE_BRANCH_REQUEST, onCreateBranchRequest);
}

function* watchBranchRateUpdateRequest() {
    yield takeEvery(createBrancheActionTypes.UPDATE_BRANCH_RATE_REQUEST, onBranchWeekDayRateUpdateRequest);
}

const createBranchSaga = [
    fork(watchBranchesDataRequest),
    fork(watchCreateBranchRequest),
    fork(watchBranchRateUpdateRequest)
];

export default createBranchSaga;
