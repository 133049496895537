// get current event
export const GET_CURRENT_DAY = 'GET_CURRENT_DAY';
export const CLEAR_CURRENT_DAY = 'CLEAR_CURRENT_DAY';
export const UPDATE_DAY_SHIFTS = 'UPDATE_DAY_SHIFTS';

// posting job draft
export const GET_POST_JOB_DRAFT = 'GET_POST_JOB_DRAFT';
export const ON_POST_JOB_REQUEST = 'ON_POST_JOB_REQUEST';
export const ON_POST_JOB_SUCCESS = 'ON_POST_JOB_SUCCESS';
export const ON_POST_JOB_FAILED = 'ON_POST_JOB_FAILED';

// posting job term
export const SEPARATE_JOB = 'SEPARATE_JOB';
export const LONG_TERM_JOB = 'LONG_TERM_JOB';

export const GET_BRANCH_SHIFTS_REQUEST = 'GET_BRANCH_SHIFTS_REQUEST';
export const GET_BRANCH_SHIFTS_SUCCESS = 'GET_BRANCH_SHIFTS_SUCCESS';
export const GET_BRANCH_SHIFTS_FAILED = 'GET_BRANCH_SHIFTS_FAILED';

export const GET_BRANCH_AVAILABLE_STAFF_REQUEST = 'GET_BRANCH_AVAILABLE_STAFF_REQUEST';
export const GET_BRANCH_AVAILABLE_STAFF_SUCCESS = 'GET_BRANCH_AVAILABLE_STAFF_SUCCESS';
export const GET_BRANCH_AVAILABLE_STAFF_FAILED = 'GET_BRANCH_AVAILABLE_STAFF_FAILED';

export const ON_SUBMIT_SHIFT_REQUEST = 'ON_SUBMIT_SHIFT_REQUEST';
export const ON_SUBMIT_SHIFT_SUCCESS = 'ON_SUBMIT_SHIFT_SUCCESS';
export const ON_SUBMIT_SHIFT_FAILED = 'ON_SUBMIT_SHIFT_FAILED';

export const ON_SUBMIT_BULK_SHIFT_REQUEST = 'ON_SUBMIT_BULK_SHIFT_REQUEST';
export const ON_SUBMIT_BULK_SHIFT_SUCCESS = 'ON_SUBMIT_BULK_SHIFT_SUCCESS';
export const ON_SUBMIT_BULK_SHIFT_FAILED = 'ON_SUBMIT_BULK_SHIFT_FAILED';

export const ON_SUBMIT_SHIFT_DRAFT_BULK_REQUEST = 'ON_SUBMIT_SHIFT_DRAFT_BULK_REQUEST';
export const ON_SUBMIT_SHIFT_DRAFT_BULK_SUCCESS = 'ON_SUBMIT_SHIFT_DRAFT_BULK_SUCCESS';
export const ON_SUBMIT_SHIFT_DRAFT_BULK_FAILED = 'ON_SUBMIT_SHIFT_DRAFT_BULK_FAILED';

export const ON_SUBMIT_DRAFT_REQUEST = 'ON_SUBMIT_DRAFT_REQUEST';
export const ON_SUBMIT_DRAFT_SUCCESS = 'ON_SUBMIT_DRAFT_SUCCESS';
export const ON_SUBMIT_DRAFT_FAILED = 'ON_SUBMIT_DRAFT_FAILED';

export const POST_JOB_BULK_REQUEST = 'POST_JOB_BULK_REQUEST';
export const POST_JOB_BULK_SUCCESS = 'POST_JOB_BULK_SUCCESS';
export const POST_JOB_BULK_FAILED = 'POST_JOB_BULK_FAILED';

export const ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST = 'ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST';
export const ON_GET_SCHEDULE_SHIFTS_BY_FILTER_SUCCESS = 'ON_GET_SCHEDULE_SHIFTS_BY_FILTER_SUCCESS';
export const ON_GET_SCHEDULE_SHIFTS_BY_FILTER_FAILED = 'ON_GET_SCHEDULE_SHIFTS_BY_FILTER_FAILED';
