import {
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_REQUEST,
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_SUCCESS,
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_FAILED,
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_CLEAR
} from './action-types';

export const getCalculatedEmployeeShiftHoursRequest = (payload) => ({
    type: GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_REQUEST,
    payload
});

export const getCalculatedEmployeeShiftHoursSuccess = (payload) => ({
    type: GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_SUCCESS,
    payload
});

export const getCalculatedEmployeeShiftHoursFailed = () => ({
    type: GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_FAILED
});

export const getCalculatedEmployeeShiftHoursClear = () => ({
    type: GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_CLEAR
});
