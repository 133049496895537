import { call, put, fork, select, takeEvery } from 'redux-saga/effects';
import {
    getUserInfoRequest,
    getUserInfoFailed,
    getStaffInfoSuccess,
    onChangeUserStatusSuccess,
    onChangeUserStatusFailed
} from '../../../actions';
import { selectLoading } from './selectors';
import * as userInfoActionTypes from '../../../actions/super-admin-view/user-info/action-types';
import api from '../../../../services/api';

function* onUserInfoRequest({ userId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${userId}/profile`,
            method: 'get'
        });
        yield put(getStaffInfoSuccess(data));
    } catch (err) {
        yield put(getUserInfoFailed());
    }
}

function* onChangeUserStatusRequest({ payload: { userId }, actionName }) {
    const loadingObject = yield select(selectLoading);
    let actionLoading = false;
    for (let key in loadingObject) {
        if (key === actionName) {
            actionLoading = loadingObject[key];
        }
    }
    try {
        yield call(api.request, {
            url: `/admin/users/${userId}/${actionName}`,
            method: 'post',
            loading: actionLoading
        });
        yield put(onChangeUserStatusSuccess());
        yield put(getUserInfoRequest(userId));
    } catch (err) {
        yield put(onChangeUserStatusFailed());
    }
}

// watchers
function* watchUserInfoRequest() {
    yield takeEvery(userInfoActionTypes.GET_USER_INFO_REQUEST, onUserInfoRequest);
}

function* watchChangeUserStatusRequest() {
    yield takeEvery(userInfoActionTypes.ON_CHANGE_USER_STATUS_REQUEST, onChangeUserStatusRequest);
}

const adminUserInfoPageSaga = [fork(watchUserInfoRequest), fork(watchChangeUserStatusRequest)];

export default adminUserInfoPageSaga;
