import { call, put, fork, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';
import {
    getScheduleShiftsRequest,
    getScheduleShiftsSuccess,
    getScheduleShiftsFailed,
    onScheduleFilterFalse,
    onSchedulePostJobSuccess,
    onSchedulePostJobFailed,
    onScheduleSubmitShiftSuccess,
    onScheduleSubmitShiftFailed
} from '../../../actions';
import { selectPostJobDraft } from '../branch-schedule/selectors';
import { selectFilters, selectDateRange, selectLoading } from './selectors';
import * as scheduleActionTypes from '../../../actions/admin-view/schedule-page/action-types';
import api from '../../../../services/api';

function* onScheduleShiftsRequest() {
    const filters = yield select(selectFilters);
    const { startDateRange, endDateRange } = yield select(selectDateRange);

    try {
        const { data } = yield call(api.request, {
            url: '/branches/schedule/list',
            method: 'post',
            payload: {
                startDateRange,
                endDateRange,
                filters
            }
        });
        yield put(getScheduleShiftsSuccess(data));
    } catch (err) {
        yield put(getScheduleShiftsFailed());
    }
}

function* onScheduleFilterChange({ payload: { value } }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field.length > 0);

    if (value.length > 2) {
        yield delay(1000);
        yield put(getScheduleShiftsRequest());
    } else if (value.length === 0 && isFilled) {
        yield put(getScheduleShiftsRequest());
    } else if (value.length === 0 && !isFilled) {
        yield put(onScheduleFilterFalse());
        yield put(getScheduleShiftsRequest());
    }
}

function* onScheduleSubmitShiftRequest({ payload: { id, data, isDraft, callback } }) {
    try {
        const loading = yield select(selectLoading);
        let apiUrl = isDraft ? `/branches/${id}/draft/` : `/branches/${id}/schedule/`;

        yield call(api.request, {
            url: apiUrl,
            method: 'post',
            payload: data,
            loading
        });
        yield put(onScheduleSubmitShiftSuccess());
        callback();
        yield put(getScheduleShiftsRequest());
    } catch (err) {
        yield put(onScheduleSubmitShiftFailed());
    }
}

function* onSchedulePostJobRequest({ payload: { id, data, toggleModal } }) {
    try {
        const postJobDraft = yield select(selectPostJobDraft);
        const loading = yield select(selectLoading);

        if (data) data.branchId = id;
        postJobDraft.branchId = id;

        yield call(api.request, {
            url: '/jobs',
            method: 'post',
            payload: !data ? postJobDraft : data,
            loading
        });
        yield put(onSchedulePostJobSuccess());
        yield put(getScheduleShiftsRequest());
        toggleModal();
    } catch (err) {
        yield put(onSchedulePostJobFailed());
    }
}

// watchers
function* watchScheduleShiftsRequest() {
    yield takeEvery(scheduleActionTypes.GET_SCHEDULE_SHIFTS_REQUEST, onScheduleShiftsRequest);
}

function* watchScheduleFilterChange() {
    yield takeLatest(scheduleActionTypes.ON_SCHEDULE_FILTER_CHANGE, onScheduleFilterChange);
}

function* watchSchedulePostJobRequest() {
    yield takeEvery(scheduleActionTypes.ON_SCHEDULE_POST_JOB_REQUEST, onSchedulePostJobRequest);
}

function* watchScheduleSubmitShiftRequest() {
    yield takeEvery(scheduleActionTypes.ON_SCHEDULE_SUBMIT_SHIFT_REQUEST, onScheduleSubmitShiftRequest);
}

const schedulePageSaga = [
    fork(watchScheduleShiftsRequest),
    fork(watchScheduleFilterChange),
    fork(watchSchedulePostJobRequest),
    fork(watchScheduleSubmitShiftRequest)
];

export default schedulePageSaga;
