import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import StaffItemCard from '../UI/staff-item-card';
import JobItemCard from '../UI/job-item-card';
import ButtonModal from '../UI/buttons/button-modal';
import CustomPagination from '../custom-pagination';

import { getDuration, getDurationFormat } from '../../services/datesHelper';
import { getTotalRate, paginate } from '../../services/helpers';

import moment from 'moment';
import { RouterConfig } from '../../routerConfig';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router';

import './style.scss';

const ScheduleDayPreview = ({ shifts = [], onSubmit, onEditShift, onJobDetailsClick }) => {
    const [activePage, setActivePage] = useState(1);
    const shiftCards = useMemo(() => paginate(shifts, 5, activePage), [shifts, activePage]);
    const history = useHistory();

    const setPageNum = (event, { activePage }) => setActivePage(activePage);

    const onEditShiftButton = (shift) => {
        const getDate = moment(shift.startDate).format('YYYY-MM-DD');

        history.push({
            pathname: generatePath(RouterConfig.branchAdminBranchScheduleDayView, {
                id: shift.branchId,
                date: getDate
            }),
            search: `openShiftId=${shift.id}`
        });
    };

    const onSubmitButton = (e) => {
        e.preventDefault();
        onSubmit();
    };

    const shiftListPagination =
        shifts.length > 5 ? (
            <CustomPagination items={shiftCards} onPageChange={setPageNum} totalPages={Math.ceil(shifts.length / 5)} />
        ) : null;

    const employeesList = shiftCards.map((shift, index) => {
        if (!shift.isJob && !shift.staff)
            return (
                <StaffItemCard
                    key={index}
                    id={shift.id}
                    startTime={shift.startTime}
                    endTime={shift.endTime}
                    duration={getDurationFormat(
                        getDuration(moment(shift.startTime, 'HH:mm'), moment(shift.endTime, 'HH:mm'))
                    )}
                />
            );
        if (shift.isJob) {
            const { id, status, hourlyRate, startTime, endTime, paidLunch, staff } = shift;

            const totalAmount = getTotalRate(
                null,
                null,
                hourlyRate,
                getDuration(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')),
                paidLunch
            );

            const acceptedStaff = staff && staff.find((result) => result.status === 'accepted');

            return (
                <JobItemCard
                    key={index}
                    id={id}
                    status={status}
                    staff={acceptedStaff}
                    hourlyRate={String(hourlyRate)}
                    duration={getDurationFormat(getDuration(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')))}
                    totalAmount={totalAmount.toString()}
                    startTime={startTime}
                    endTime={endTime}
                    onDetailsButtonClick={onJobDetailsClick}
                />
            );
        }

        const {
            staff: { name, surname, role, roleTitle, image },
            startTime,
            endTime,
            startDate,
            branchId
        } = shift;

        return (
            <StaffItemCard
                key={index}
                id={shift.id}
                startTime={startTime}
                endTime={endTime}
                duration={getDurationFormat(getDuration(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')))}
                name={name}
                surname={surname}
                image={image}
                roleTitle={roleTitle}
                role={role}
                startDate={startDate}
                branchId={branchId}
                onEditButtonClick={onEditShiftButton}
            />
        );
    });

    return (
        <div className="schedule-day-preview">
            <div className="schedule-day-preview__row">{employeesList}</div>
            <div className="schedule-day-preview__row pagination-row">{shiftListPagination}</div>
            <div className="schedule-day-preview__row job-button-row">
                <ButtonModal colorType="blue" value="Go to day view" onClick={onSubmitButton} />
            </div>
        </div>
    );
};

ScheduleDayPreview.propTypes = {
    shifts: PropTypes.array,
    onAddShift: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onEditShift: PropTypes.func,
    onEditJobClick: PropTypes.func,
    onJobDetailsClick: PropTypes.func
};

export default ScheduleDayPreview;
