import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'semantic-ui-react';

import EditSubscriptionNotification from './EditSubscriptionNotification';
import ButtonComponent from '../../UI/buttons/button';
import ButtonModal from '../../UI/buttons/button-modal';

import './style.scss';

function EditSubscriptionModal({ open, onClose, loading, isDowngrade, subscriptionEndDate, onSubmit }) {
    const [immediately, setImmediately] = useState(false);

    const modalText = isDowngrade ? (
        <p className="edit-subscription-modal__text">
            We don't want you to leave and we want to help you make the most of The Locum App. Please email us on{' '}
            <a href="mailto:support@thelocumapp.co.uk">support@thelocumapp.co.uk</a> before you downgrade.
        </p>
    ) : (
        <p className="edit-subscription-modal__text">
            We’re sorry to see you go and we want to help you make the most of The Locum App. Please email our support
            team on <a href="mailto:support@thelocumapp.co.uk">support@thelocumapp.co.uk</a>
            and we’ll help you make the most of The Locum App.
        </p>
    );

    return (
        <Modal closeIcon size="tiny" open={open} onClose={onClose} className="edit-subscription-modal">
            <Modal.Header>
                <h2 className="edit-subscription-modal__title">{`Do you want to ${
                    isDowngrade ? 'Downgrade' : 'Cancel'
                } Subscription?`}</h2>
            </Modal.Header>
            <Modal.Content>
                {modalText}
                <EditSubscriptionNotification
                    subscriptionEndDate={subscriptionEndDate}
                    isDowngrade={isDowngrade}
                    isImmediately={immediately}
                    onClick={() => setImmediately(true)}
                />
                <div className="edit-subscription-modal__buttons">
                    <ButtonModal value="Back" size="small" colorType="blue" onClick={onClose} />
                    <ButtonComponent
                        value={`${isDowngrade ? 'Downgrade' : 'Cancel'} Subscription`}
                        size="medium"
                        type="bordered"
                        colorType="bordered-danger"
                        onClick={(evt) => {
                            evt.preventDefault();
                            onSubmit(isDowngrade ? { immediately } : immediately);
                        }}
                        loading={loading}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
}

EditSubscriptionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isDowngrade: PropTypes.bool,
    subscriptionEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onSubmit: PropTypes.func.isRequired
};

export default EditSubscriptionModal;
