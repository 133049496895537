// Job status enum - backend based 'active','cancelled','unfilled','pending','paid','payment_approved','completed'
export const JOB = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
    PENDING: 'pending',
    UNFILLED: 'unfilled',
    PAID: 'paid',
    PAYMENT_APPROVED: 'payment_approved',
    COMPLETED: 'completed'
};

export const JOB_STATUS_TO_ACTION = {
    [JOB.ACTIVE]: 'Complete',
    [JOB.COMPLETED]: 'Approve',
    [JOB.PAYMENT_APPROVED]: 'Paid',
    [JOB.PAID]: 'Paid'
};

export const JOB_STATUS_TO_NEXT_STATE = {
    [JOB.ACTIVE]: JOB.COMPLETED,
    [JOB.COMPLETED]: JOB.PAYMENT_APPROVED,
    [JOB.PAYMENT_APPROVED]: JOB.PAID,
    [JOB.PAID]: JOB.PAID
};

export const CONTRACT_TYPE = {
    FULL_TIME: 'full_time',
    PART_TIME: 'part_time',
    ZERO_HOUR: 'zero_hour',
    OTHER: 'other'
};

export const PERIOD_TYPE = {
    YEAR: 'year',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
};

export const CONTRACT_TYPE_TEXT = {
    [CONTRACT_TYPE.FULL_TIME]: 'Full time',
    [CONTRACT_TYPE.PART_TIME]: 'Part time',
    [CONTRACT_TYPE.ZERO_HOUR]: 'Zero-hour',
    [CONTRACT_TYPE.OTHER]: 'Other'
};
