import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Can } from '../../../../../services/ability';

import PopupComponent from '../../../../../components/popup';
import ButtonComponent from '../../../../../components/UI/buttons/button';
import GPHCInfoCard from '../GPHCInfoCard';
import InfoTabs from '../../../../../components/info-tabs';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info-icon.svg';

import { roleAbilityTypes, roleFeatureTypes, userRoles } from '../../../../../userConfig';
import ConfirmModal from '../../../../../components/modals/confirm-modal';
import { useSelector } from 'react-redux';

function PersonalInfoBoard({
    personal: {
        name,
        surname,
        gender,
        gphcNumber,
        provisionallyRegisterNumber,
        phone,
        dob,
        city,
        zipCode,
        address1,
        address2,
        email,
        pharmacy,
        role,
        locumBank
    },
    gphcInfo,
    provisionallyRegisterNumberData,
    accessStatus,
    onUserAction,
    toggleAddToBank,
    loading,
    onChangeView,
    onTabClick,
    isPersonalProfile = false,
    activeView
}) {
    let userControlButtons = null;

    const [confirmReleaseLocum, setConfirmReleaseLocum] = useState(false);
    const user = useSelector((state) => state.auth.user);

    const handleToggleAddRelease = () => {
        if (locumBank) {
            setConfirmReleaseLocum(true);
        } else {
            toggleAddToBank();
        }
    };

    switch (accessStatus) {
        case 'Pending user':
            userControlButtons = (
                <div className="admin-controls">
                    <ButtonComponent
                        value="Reject"
                        type="bordered"
                        size="small"
                        colorType="bordered-danger"
                        loading={loading.reject}
                        onClick={() => onUserAction('reject')}
                    />
                    <ButtonComponent
                        value="Approve"
                        size="medium"
                        colorType="green"
                        loading={loading.approve}
                        onClick={() => onUserAction('approve')}
                    />
                </div>
            );
            break;
        case 'Active user':
            userControlButtons = (
                <ButtonComponent
                    value="Deactivate"
                    type="bordered"
                    size="small"
                    colorType="bordered-danger"
                    loading={loading.deactivate}
                    onClick={() => onUserAction('deactivate')}
                />
            );
            break;
        case 'Deavtivated user':
            userControlButtons = (
                <ButtonComponent
                    value="Activate"
                    type="bordered"
                    size="small"
                    colorType="green"
                    loading={loading.activate}
                    onClick={() => onUserAction('activate')}
                />
            );
            break;
        case 'Staff personal info':
        case 'Locum personal info':
            userControlButtons = (
                <ButtonComponent value="Edit" size="small" onClick={() => onChangeView('EditPersonal')} />
            );
            break;
        default:
            userControlButtons = null;
    }

    const heading = isPersonalProfile ? 'Personal Information' : 'Information';

    const tabs = [
        {
            title: 'Personal',
            onClick: () => onTabClick('Personal'),
            isActive: activeView === 'Personal'
        },
        {
            title: 'Right to Work',
            onClick: () => onTabClick('RightToWork'),
            isActive: false
        },
        {
            title: 'Experience',
            onClick: () => onTabClick('Experience'),
            isActive: false
        },
        {
            title: 'Qualifications',
            onClick: () => onTabClick('Qualifications'),
            isActive: false
        }
    ];

    const confirmModalButton = {
        value: 'Confirm',
        onClick: () => toggleAddToBank(),
        size: 'big',
        width: 'big'
    };

    return (
        <>
            {confirmReleaseLocum && (
                <ConfirmModal
                    open={true}
                    onClose={() => setConfirmReleaseLocum(false)}
                    title="Release Locum?"
                    text="Are you sure you want to release the locum from the Locum bank?"
                    button={confirmModalButton}
                />
            )}
            <div className="staff-info-page__header">
                <h3 className="staff-info-page__title">{heading}</h3>
                <div className="staff-info-page__row buttons-row with-tabs">
                    <InfoTabs tabs={tabs} />
                    {userControlButtons}
                    {user.role === 'branch_admin' && role === 'locum' && (
                        <ButtonComponent
                            value={locumBank ? 'Release' : 'Add to bank'}
                            type="bordered"
                            size="small"
                            colorType={locumBank ? 'bordered-danger' : null}
                            // loading={loading.deactivate}
                            onClick={handleToggleAddRelease}
                        />
                    )}
                </div>
            </div>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">Bio</h4>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">First Name</p>
                                    <div className="description">{name || ''}</div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">GPhC Number</p>
                                    <div className="description">
                                        {gphcNumber || provisionallyRegisterNumber}
                                        <Can
                                            do={roleAbilityTypes.READ_ABILITY}
                                            on={roleFeatureTypes.STAFF_PERSONAL_INFO_GPHC_VIEW}
                                        >
                                            <PopupComponent triggerComponent={<InfoIcon />} smallPaddings>
                                                <GPHCInfoCard
                                                    gphcInfo={gphcInfo}
                                                    provisionallyRegisterNumberData={provisionallyRegisterNumberData}
                                                    registered={!!gphcInfo || !!provisionallyRegisterNumberData}
                                                />
                                            </PopupComponent>
                                        </Can>
                                    </div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Last Name</p>
                                    <div className="description">{surname || ''}</div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">Date of Birth</p>
                                    <div className="description">
                                        {dob ? moment(dob).format('MMMM Do, YYYY') : 'None'}
                                    </div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Gender</p>
                                    <div className="description">{!gender ? 'None' : gender}</div>
                                </div>
                            </div>
                            {role !== userRoles.LOCUM && (
                                <Can do={roleAbilityTypes.MANAGE_ABILITY} on={roleFeatureTypes.STAFF_EDIT_FEATURE}>
                                    <div className="item-row">
                                        <div className="info-box__item fluid">
                                            <p className="name">Pharmacy</p>
                                            <div className="description">{!pharmacy ? 'None' : pharmacy}</div>
                                        </div>
                                    </div>
                                </Can>
                            )}
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">contacts</h4>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Address Line 1</p>
                                <div className="description">{address1 || 'None'}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Address Line 2</p>
                                <div className="description">{address2 || 'None'}</div>
                            </div>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Town/City</p>
                                    <div className="description">{city || 'None'}</div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">PostCode</p>
                                    <div className="description">{zipCode || 'None'}</div>
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Email</p>
                                <div className="description email">{!email ? 'None' : email}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Phone</p>
                                <div className="description">{!phone ? 'None' : phone}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

PersonalInfoBoard.propTypes = {
    personal: PropTypes.object.isRequired,
    activeView: PropTypes.string.isRequired,
    gphcInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    compliance: PropTypes.object,
    accessStatus: PropTypes.string,
    onUserAction: PropTypes.func,
    loading: PropTypes.object,
    onChangeView: PropTypes.func,
    isPersonalProfile: PropTypes.bool
};

export default PersonalInfoBoard;
