import {
    GET_INVITES_DATA_REQUEST,
    GET_INVITES_DATA_SUCCESS,
    GET_INVITES_DATA_FAILED,
    ON_INVITE_STAFF_REQUEST,
    ON_INVITE_STAFF_SUCCESS,
    ON_INVITE_STAFF_FAILED
} from './action-types';

export const getInvitesDataRequest = () => ({
    type: GET_INVITES_DATA_REQUEST
});

export const getInvitesDataSuccess = (payload) => ({
    type: GET_INVITES_DATA_SUCCESS,
    payload
});

export const getInvitesDataFailed = () => ({
    type: GET_INVITES_DATA_FAILED
});

export const onInviteStaffRequest = (payload) => ({
    type: ON_INVITE_STAFF_REQUEST,
    payload
});

export const onInviteStaffSuccess = () => ({
    type: ON_INVITE_STAFF_SUCCESS
});

export const onInviteStaffFailed = () => ({
    type: ON_INVITE_STAFF_FAILED
});
