import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

import './style.scss';

const GlobalLoader = ({ color, size = 'huge', loaderState }) => {
    return (
        <div className={`global-loader overlay ${loaderState ? 'isActive' : ''}`}>
            <Dimmer active className={`global-loader__loader ${color}`}>
                <Loader size={size} indeterminate />
            </Dimmer>
        </div>
    );
};

GlobalLoader.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string
};

export default GlobalLoader;
