import {
    GET_LOCUM_SETTINGS_REQUEST,
    GET_LOCUM_SETTINGS_FAILED,
    GET_LOCUM_SETTINGS_SUCCESS,
    LOCUM_SETTINGS_UPDATE_FAILED,
    LOCUM_SETTINGS_UPDATE_REQUEST,
    LOCUM_SETTINGS_UPDATE_SUCCESS
} from './action-types';

export const getLocumSettingsRequest = (payload) => ({
    type: GET_LOCUM_SETTINGS_REQUEST,
    payload
});

export const getLocumSettingsSuccess = (payload) => ({
    type: GET_LOCUM_SETTINGS_SUCCESS,
    payload
});

export const getLocumSettingsFailed = () => ({
    type: GET_LOCUM_SETTINGS_FAILED
});

export const onLocumSettingsUpdateRequest = (payload) => ({
    type: LOCUM_SETTINGS_UPDATE_REQUEST,
    payload
});

export const onLocumSettingsUpdateSuccess = () => ({
    type: LOCUM_SETTINGS_UPDATE_SUCCESS
});

export const onLocumSettingsUpdateFailed = () => ({
    type: LOCUM_SETTINGS_UPDATE_FAILED
});
