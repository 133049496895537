import React from 'react';
import StaffList from './staff-list';
import StaffDetails from './staff-details';

import { Switch, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../routerConfig';

const StaffPage = (props) => {
    return (
        <Switch>
            <Route path={RouterConfig.branchAdminStaff} component={StaffList} exact />
            <Route path={RouterConfig.branchAdminStaffRoot} component={StaffDetails} />
        </Switch>
    );
};

export default StaffPage;
