import {
    GET_STAFF_SHIFTS_FOR_ADMIN_REQUEST,
    GET_STAFF_SHIFTS_FOR_ADMIN_SUCCESS,
    GET_STAFF_SHIFTS_FOR_ADMIN_FAILED
} from './action-types';

export const getStaffShiftsForAdminRequest = (payload) => ({
    type: GET_STAFF_SHIFTS_FOR_ADMIN_REQUEST,
    payload
});

export const getStaffShiftsForAdminSuccess = (payload) => ({
    type: GET_STAFF_SHIFTS_FOR_ADMIN_SUCCESS,
    payload
});

export const getStaffShiftsForAdminFailed = () => ({
    type: GET_STAFF_SHIFTS_FOR_ADMIN_FAILED
});
