import React, { useEffect } from 'react';

import PharmacyProfile from './profile';
import PharmacyBillingPage from './billing';

import { Switch, Redirect, Route } from 'react-router-dom';
import { RouterConfig } from '../../../../routerConfig';

import Sidebar from '../../../sidebar';
import ContentContainer from '../../../../components/content-container';
import ProfileCard from '../../../../components/profile-card';

import { connect } from 'react-redux';
import { getPersonalInfoRequest, clearPersonalInfo } from './../../../../store/actions';
import PharmacySettingsPage from './settings';

function PharmacyInfoPage({ getPersonalInfoRequest, clearPersonalInfo, personalInfo: { data, loading } }) {
    useEffect(() => {
        getPersonalInfoRequest();
        return () => {
            clearPersonalInfo();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sidebarLinks = [
        {
            text: 'Personal Information',
            to: RouterConfig.branchAdminProfileInfo,
            disabled: false,
            hidden: false
        },
        {
            text: 'Subscription & Billing',
            to: RouterConfig.branchAdminBilling,
            disabled: false,
            hidden: false
        },
        {
            text: 'Settings',
            to: RouterConfig.branchAdminSettings,
            disabled: false,
            hidden: false
        }
    ];

    if (!data) {
        return null;
    } else {
        const { name, surname, role, image } = data;
        return (
            <div className="content-view-page details-page">
                <div className="main-wrapper">
                    <div className="content-holder">
                        <Sidebar links={sidebarLinks} visible>
                            <ProfileCard name={name} surname={surname} role={role} avatar={image} />
                        </Sidebar>
                        <ContentContainer smallPaddings fluid={false}>
                            <Switch>
                                <Route
                                    path={RouterConfig.branchAdminProfileInfo}
                                    render={() => <PharmacyProfile data={data} loading={loading} />}
                                />
                                <Route path={RouterConfig.branchAdminBilling} component={PharmacyBillingPage} />
                                <Route path={RouterConfig.branchAdminSettings} component={PharmacySettingsPage} />
                                <Redirect
                                    from={RouterConfig.branchAdminPharmacyInfo}
                                    to={RouterConfig.branchAdminProfileInfo}
                                />
                            </Switch>
                        </ContentContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        personalInfo: state.userProfileInfo.personalInfo
    }),
    {
        getPersonalInfoRequest,
        clearPersonalInfo
    }
)(PharmacyInfoPage);
