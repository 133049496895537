import { connect } from 'react-redux';
import StaffAbsencePage from './StaffAbsencePage';

import {
    getStaffAbsenceRequest,
    clearStaffAbsenceFilters,
    onStaffAbsenceFilterChange
} from '../../../../../store/actions';

export default connect(
    (state) => ({
        staffAbsencePage: state.staffAbsencePage
    }),
    {
        getStaffAbsenceRequest,
        clearStaffAbsenceFilters,
        onStaffAbsenceFilterChange
    }
)(StaffAbsencePage);
