import {
    GET_PHARMACIES_LIST_REQUEST,
    GET_PHARMACIES_LIST_SUCCESS,
    GET_PHARMACIES_LIST_FAILED,
    ON_PHARMACIES_FILTER_CHANGE,
    ON_PHARMACIES_FILTER_FALSE,
    CLEAR_PHARMACIES_FILTERS
} from './action-types';

export const getPharmaciesListRequest = (payload) => ({
    type: GET_PHARMACIES_LIST_REQUEST,
    payload
});

export const getPharmaciesListSuccess = (payload) => ({
    type: GET_PHARMACIES_LIST_SUCCESS,
    payload
});

export const getPharmaciesListFailed = (payload) => ({
    type: GET_PHARMACIES_LIST_FAILED
});

export const onPharmaciesFilterChange = (payload, page) => ({
    type: ON_PHARMACIES_FILTER_CHANGE,
    payload,
    page
});

export const onPharmaciesFilterFalse = () => ({
    type: ON_PHARMACIES_FILTER_FALSE
});

export const clearPharmaciesFilters = () => ({
    type: CLEAR_PHARMACIES_FILTERS
});
