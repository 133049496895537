import { call, put, fork, select, takeEvery } from 'redux-saga/effects';
import {
    getBranchShiftsRequest,
    getScheduleShiftsRequest,
    getBranchShiftsSuccess,
    getBranchShiftsFailed,
    getBranchAvailableStaffSuccess,
    getBranchAvailableStaffFailed,
    onSubmitShiftSuccess,
    onSubmitShiftFailed,
    onSubmitDraftSuccess,
    onSubmitDraftFailed,
    onPostJobSuccess,
    onPostJobFailed,
    onPostJobBulkSuccess,
    onPostJobBulkFailed,
    onGetScheduleShiftsByFilterSuccess,
    onGetScheduleShiftsByFilterFailed
    //   updateDayShifts
} from '../../../actions';
import {
    selectPostJobDraft
    //   selectShiftsList,
    //   selectCurrentDay
} from './selectors';
import * as branchScheduleActionTypes from '../../../actions/admin-view/branch-schedule/action-types';
import api from '../../../../services/api';
import moment from 'moment';

function* onBranchShiftsRequest({ payload: { id, startDateRange, endDateRange } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${id}/schedule/list`,
            method: 'post',
            payload: {
                startDateRange,
                endDateRange
            }
        });
        const formatedShifts = data.map((shift) => {
            const { startDate, endDate } = shift;
            return {
                ...shift,
                start: moment(startDate),
                end: moment(endDate)
            };
        });
        yield put(getBranchShiftsSuccess(formatedShifts));
    } catch (err) {
        yield put(getBranchShiftsFailed());
    }
}

function* onBranchAvailableStaffRequest({ payload: { recievedData, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/staff/available',
            method: 'post',
            payload: recievedData
        });
        yield put(getBranchAvailableStaffSuccess(data));
        callback();
    } catch (err) {
        yield put(getBranchAvailableStaffFailed());
    }
}

function* onSubmitShiftRequest({
    payload: { id, isEdit, editShiftId, data, toggleModal, startDateRange, endDateRange }
}) {
    try {
        const apiUrl = isEdit ? `/branches/${id}/schedule/${editShiftId}` : `/branches/${id}/schedule/`;

        yield call(api.request, {
            url: apiUrl,
            method: 'post',
            payload: data
        });
        yield put(onSubmitShiftSuccess());
        toggleModal();
        yield put(getBranchShiftsRequest({ id, startDateRange, endDateRange }));
    } catch (err) {
        yield put(onSubmitShiftFailed());
    }
}

function* onSubmitBulkShiftRequest({ payload: { id, data, toggleModal, startDateRange, endDateRange } }) {
    try {
        yield call(api.request, {
            url: `/branches/${id}/schedule-save-bulk/`,
            method: 'post',
            payload: data
        });
        yield put(onSubmitShiftSuccess());
        toggleModal();
        yield put(getBranchShiftsRequest({ id, startDateRange, endDateRange }));
    } catch (err) {
        yield put(onSubmitShiftFailed());
    }
}

function* onShiftsByFilterRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: '/branches/shifts/list',
            method: 'post',
            payload: {
                filters: payload,
                pagination: {
                    page: payload.page,
                    perPage: payload.perPage
                }
            }
        });
        yield put(
            onGetScheduleShiftsByFilterSuccess({
                data,
                totalData: data.count
            })
        );
    } catch (err) {
        yield put(onGetScheduleShiftsByFilterFailed());
    }
}

function* onSubmitDraftRequest({ payload: { id, recievedData, callback, startDateRange, endDateRange } }) {
    try {
        yield call(api.request, {
            url: `/branches/${id}/draft`,
            method: 'post',
            payload: recievedData
        });
        yield put(onSubmitDraftSuccess());
        callback();
        yield put(getBranchShiftsRequest({ id, startDateRange, endDateRange }));
    } catch (err) {
        yield put(onSubmitDraftFailed());
    }
}

function* onSubmitDraftBulkRequest({ payload: { id, data, callback, startDateRange, endDateRange } }) {
    try {
        yield call(api.request, {
            url: `/branches/${id}/draft-bulk`,
            method: 'post',
            payload: data
        });
        yield put(onSubmitDraftSuccess());
        callback();
        yield put(getBranchShiftsRequest({ id, startDateRange, endDateRange }));
    } catch (err) {
        yield put(onSubmitDraftFailed());
    }
}

function* onPostJobBulkRequest({ payload: { id, data, callback, startDateRange, endDateRange } }) {
    try {
        yield call(api.request, {
            url: `/branches/${id}/job-create-bulk`,
            method: 'post',
            payload: data
        });
        yield put(onPostJobBulkSuccess());
        yield put(getBranchShiftsRequest({ id, startDateRange, endDateRange }));
        yield put(getScheduleShiftsRequest({ id, startDateRange, endDateRange }));
        callback();
    } catch (err) {
        yield put(onPostJobBulkFailed());
    }
}

function* onPostJobRequest({ payload: { id, data, toggleModal, startDateRange, endDateRange } }) {
    try {
        const postJobDraft = yield select(selectPostJobDraft);

        if (data) data.branchId = id;
        postJobDraft.branchId = id;

        yield call(api.request, {
            url: '/jobs',
            method: 'post',
            payload: !data ? postJobDraft : data
        });
        yield put(onPostJobSuccess());
        yield put(getBranchShiftsRequest({ id, startDateRange, endDateRange }));
        toggleModal();
    } catch (err) {
        yield put(onPostJobFailed());
    }
}

// watchers
function* watchBranchShiftsRequest() {
    yield takeEvery(branchScheduleActionTypes.GET_BRANCH_SHIFTS_REQUEST, onBranchShiftsRequest);
}
function* watchBranchBulkShiftsRequest() {
    yield takeEvery(branchScheduleActionTypes.ON_SUBMIT_BULK_SHIFT_REQUEST, onSubmitBulkShiftRequest);
}

function* watchBranchAvailableStaffRequest() {
    yield takeEvery(branchScheduleActionTypes.GET_BRANCH_AVAILABLE_STAFF_REQUEST, onBranchAvailableStaffRequest);
}

function* watchSubmitShiftRequest() {
    yield takeEvery(branchScheduleActionTypes.ON_SUBMIT_SHIFT_REQUEST, onSubmitShiftRequest);
}

function* watchSubmitDraftRequest() {
    yield takeEvery(branchScheduleActionTypes.ON_SUBMIT_DRAFT_REQUEST, onSubmitDraftRequest);
}

function* watchPostJobRequest() {
    yield takeEvery(branchScheduleActionTypes.ON_POST_JOB_REQUEST, onPostJobRequest);
}

function* watchSubmitDraftBulkRequest() {
    yield takeEvery(branchScheduleActionTypes.ON_SUBMIT_SHIFT_DRAFT_BULK_REQUEST, onSubmitDraftBulkRequest);
}
function* watchPostJobBulkRequest() {
    yield takeEvery(branchScheduleActionTypes.POST_JOB_BULK_REQUEST, onPostJobBulkRequest);
}
function* watchOnGetScheduleShiftsByFilterRequest() {
    yield takeEvery(branchScheduleActionTypes.ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST, onShiftsByFilterRequest);
}

const branchScheduleSaga = [
    fork(watchBranchShiftsRequest),
    fork(watchBranchAvailableStaffRequest),
    fork(watchSubmitShiftRequest),
    fork(watchSubmitDraftRequest),
    fork(watchPostJobRequest),
    fork(watchBranchBulkShiftsRequest),
    fork(watchSubmitDraftBulkRequest),
    fork(watchPostJobBulkRequest),
    fork(watchOnGetScheduleShiftsByFilterRequest)
];

export default branchScheduleSaga;
