import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonComponent from '../../../../../components/UI/buttons/button';
import InfoTabs from '../../../../../components/info-tabs';
import { AbilityContext } from '../../../../../services/ability';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download-icon.svg';

import { getStaffEducationList } from '../../../../../services/helpers';

import { roleAbilityTypes, roleFeatureTypes } from '../../../../../userConfig';

function QualificationsBoard({
    accessStatus,
    onTabClick,
    activeView,
    loading,
    onUserAction,
    onChangeView,
    isPersonalProfile,
    data,
    onSaveFile
}) {
    const ability = useContext(AbilityContext);

    const tabs = [
        {
            title: 'Personal',
            onClick: () => onTabClick('Personal'),
            isActive: false
        },
        {
            title: 'Right to Work',
            onClick: () => onTabClick('RightToWork'),
            isActive: false
        },
        {
            title: 'Experience',
            onClick: () => onTabClick('Experience'),
            isActive: false
        },
        {
            title: 'Qualifications',
            onClick: () => onTabClick('Qualifications'),
            isActive: activeView === 'Qualifications'
        }
    ];

    let userControlButtons = null;

    switch (accessStatus) {
        case 'Pending user':
            userControlButtons = (
                <div className="admin-controls">
                    <ButtonComponent
                        value="Reject"
                        type="bordered"
                        size="small"
                        colorType="bordered-danger"
                        loading={loading.reject}
                        onClick={() => onUserAction('reject')}
                    />
                    <ButtonComponent
                        value="Approve"
                        size="medium"
                        colorType="green"
                        loading={loading.approve}
                        onClick={() => onUserAction('approve')}
                    />
                </div>
            );
            break;
        case 'Active user':
            userControlButtons = (
                <ButtonComponent
                    value="Deactivate"
                    type="bordered"
                    size="small"
                    colorType="bordered-danger"
                    loading={loading.deactivate}
                    onClick={() => onUserAction('deactivate')}
                />
            );
            break;
        case 'Deavtivated user':
            userControlButtons = (
                <ButtonComponent
                    value="Activate"
                    type="bordered"
                    size="small"
                    colorType="green"
                    loading={loading.activate}
                    onClick={() => onUserAction('activate')}
                />
            );
            break;
        case 'Staff personal info':
        case 'Locum personal info':
            userControlButtons = (
                <ButtonComponent value="Edit" size="small" onClick={() => onChangeView('EditQualifications')} />
            );
            break;
        default:
            userControlButtons = null;
    }

    const heading = isPersonalProfile ? 'Personal Information' : 'Information';

    const displayCertificates = getStaffEducationList(data).map((el) => {
        if (el.value && el.value !== 'null')
            return (
                <div
                    className={classnames('certif-wrapper', {
                        linked: ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE)
                    })}
                    key={el.title}
                    onClick={() =>
                        ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE)
                            ? onSaveFile(el.value, el.title)
                            : false
                    }
                >
                    <span className="text">
                        {el.title}{' '}
                        {ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE) && (
                            <DownloadIcon />
                        )}
                    </span>
                </div>
            );
        return null;
    });

    const displayAccreditations = data.additionalAccreditations
        ? data.additionalAccreditations.map((el) => {
              return (
                  <div className="certif-wrapper" key={el.title}>
                      <span className="text">{el.title}</span>
                  </div>
              );
          })
        : 'None';

    return (
        <>
            <div className="staff-info-page__header">
                <h3 className="staff-info-page__title">{heading}</h3>
                <div className="staff-info-page__row buttons-row with-tabs">
                    <InfoTabs tabs={tabs} />
                    {userControlButtons}
                </div>
            </div>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">Qualification</h4>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Certificates</p>
                                <div className="description list">
                                    {!displayCertificates.find((el) => el !== null) ? 'None' : displayCertificates}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title hidden" aria-hidden="true"></h4>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Additional Accreditations:</p>
                                <div className="description list">{displayAccreditations}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

QualificationsBoard.propTypes = {
    data: PropTypes.object.isRequired,
    activeView: PropTypes.string.isRequired,
    onSaveFile: PropTypes.func.isRequired,
    accessStatus: PropTypes.string,
    onUserAction: PropTypes.func,
    loading: PropTypes.object,
    onChangeView: PropTypes.func,
    isPersonalProfile: PropTypes.bool
};

export default QualificationsBoard;
