import {
    PHARMACY_SIGN_UP_REQUEST,
    LOCUM_SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILED
} from '../../actions/signup-page/action-types';

const initialState = {
    loading: false
};

const signupPage = (state = initialState, action) => {
    switch (action.type) {
        case PHARMACY_SIGN_UP_REQUEST:
        case LOCUM_SIGN_UP_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SIGN_UP_SUCCESS:
        case SIGN_UP_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default signupPage;
