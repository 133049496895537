import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { getLocumsByGphcClear, getLocumsByGphcRequest } from '../../../store/actions';
import ButtonModal from '../../UI/buttons/button-modal';
import TextInput from '../../UI/inputs/text-input';
import SelectComponent from '../../UI/select';
import './style.scss';

const initialState = {
    gphcNumber: '',
    email: '',
    phone: '',
    locum: null,
    requesting: null,
    errors: {
        gphcNumber: null,
        email: null,
        phone: null
    }
};

const LocumBankAddLocumForm = ({ onClose, onSubmit }) => {
    const { data } = useSelector((state) => state.locumBankLocumsData);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (formData.gphcNumber.length < 6) return;
        setFormData({ ...formData, requesting: true });
        const timeout = setTimeout(() => {
            dispatch(getLocumsByGphcRequest(formData.gphcNumber));
        }, [1000]);
        return () => clearTimeout(timeout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.gphcNumber]);

    useEffect(() => {
        if (data && !formData.gphcNumber) {
            dispatch(getLocumsByGphcClear());
            setFormData(initialState);
            return;
        }
    }, [data, dispatch, formData.gphcNumber]);

    useEffect(() => {
        if (!data) return;
        setFormData({
            ...formData,
            email: data.email,
            phone: data.phone
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onChangeHandler = (ev, data) => {
        setFormData({
            ...formData,
            [data.name]: data.value
        });
    };

    const formIsValid = () => {
        const { phone, email, gphcNumber } = formData;
        return !(phone && email && gphcNumber);
    };

    const handleClose = () => {
        setFormData(initialState);
        onClose();
    };

    const onFormSubmit = () => {
        onSubmit({
            gphcNumber: formData.gphcNumber,
            email: formData.email,
            phone: formData.phone,
            userId: data && data.userId,
            gphcId: data && data.gphcId
        });
        dispatch(getLocumsByGphcClear());
        setFormData(initialState);
    };

    const getFormattedUserName = (user) => {
        let name = '';

        if (user.name) {
            name = `${user.name} ${user.surname || ''}`;
        } else {
            name = `${user.forenames || ''} ${user.surname || ''}`;
        }

        return name;
    };

    const typeOptions = data
        ? [
              {
                  value: data.userId || data.gphcId,
                  key: 'locum',
                  text: getFormattedUserName(data)
              }
          ]
        : [];

    return (
        <Form className="locum-bank-add-locum-form" onSubmit={onFormSubmit}>
            <div className="content content-row">
                <div className="content-row__item">
                    <div className="content-row__item reasons">
                        <div className="">
                            <TextInput
                                name="gphcNumber"
                                label="GPhC Number"
                                placeholder="GPhC Number"
                                type="number"
                                value={formData.gphcNumber}
                                error={!!formData.errors.gphcNumber}
                                helperText={formData.errors.gphcNumber}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="content-row__item">
                    <div className="content-row__item reasons">
                        <div className="">
                            <TextInput
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Email"
                                value={formData.email}
                                error={!!formData.errors.email}
                                helperText={formData.errors.email}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="content-row__item">
                    <div className="content-row__item reasons">
                        <div className="">
                            <TextInput
                                name="phone"
                                label="Phone"
                                type="tel"
                                placeholder="Phone"
                                value={formData.phone}
                                error={!!formData.errors.phone}
                                helperText={formData.errors.phone}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="content-row__item content">
                    <div className="">
                        <SelectComponent
                            name="locum"
                            label="Locum"
                            placeholder="Locum Name"
                            icon="caret down"
                            floating
                            value={data ? data.userId || data.gphcId : ''}
                            onChange={onChangeHandler}
                            options={typeOptions}
                        />
                    </div>
                </div>
                <div className="buttons-row">
                    <ButtonModal
                        className="mr-2"
                        value={'Cancel'}
                        colorType="red"
                        type="button"
                        // loading={removingLoading}
                        onClick={handleClose}
                    />
                    <ButtonModal
                        type="submit"
                        value={'Add'}
                        colorType="blue"
                        disabled={formIsValid()}
                        loading={false}
                    />
                </div>
            </div>
        </Form>
    );
};

LocumBankAddLocumForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default LocumBankAddLocumForm;
