import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Pagination } from 'semantic-ui-react';

import './style.scss';

const CustomPagination = ({
    items,
    boundaryRange = 0,
    defaultActivePage = 1,
    ellipsisItem = null,
    firstItem = null,
    lastItem = null,
    siblingRange = 1,
    totalPages,
    activePage,
    onPageChange,
    disabled,
    nextItemDisabled = false,
    prevItemDisabled = false,
    modalView = false,
    pageItem
}) => {
    return (
        <Pagination
            className={`custom-pagination ${modalView ? 'modal-view' : ''}`}
            activePage={activePage}
            disabled={disabled}
            boundaryRange={boundaryRange}
            defaultActivePage={defaultActivePage}
            ellipsisItem={ellipsisItem}
            firstItem={firstItem}
            prevItem={{
                'aria-label': 'Last item',
                content: <Icon name="chevron left" />,
                disabled: prevItemDisabled
            }}
            nextItem={{
                'aria-label': 'Last item',
                content: <Icon name="chevron right" />,
                disabled: nextItemDisabled
            }}
            pageItem={pageItem}
            lastItem={lastItem}
            siblingRange={siblingRange}
            totalPages={totalPages}
            onPageChange={onPageChange}
        />
    );
};

CustomPagination.propTypes = {
    items: PropTypes.array,
    boundaryRange: PropTypes.number,
    defaultActivePage: PropTypes.number,
    siblingRange: PropTypes.number,
    totalPages: PropTypes.number,
    activePage: PropTypes.number,
    onPageChange: PropTypes.func,
    modalView: PropTypes.bool,
    disabled: PropTypes.bool,
    nextItemDisabled: PropTypes.bool,
    prevItemDisabled: PropTypes.bool,
    pageItem: PropTypes.object
};

export default CustomPagination;
