import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';

import './style.scss';

const ButtonComponent = ({
    value,
    type = 'confirm',
    onClick,
    colorType,
    loading,
    size = 'huge',
    width = 'big',
    disabled,
    role = 'button',
    icon,
    circular,
    className
}) => {
    return (
        <Button
            size={size}
            loading={loading}
            className={classnames('button-component', {
                [type]: type,
                [width]: width,
                [colorType]: colorType,
                [className]: className
            })}
            icon={icon}
            disabled={disabled}
            onClick={onClick}
            role={role}
            circular={circular}
        >
            {value}
        </Button>
    );
};

ButtonComponent.propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
    loading: PropTypes.bool,
    size: PropTypes.string,
    colorType: PropTypes.string,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    role: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    circular: PropTypes.bool
};

export default ButtonComponent;
