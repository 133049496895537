import * as actionTypes from './action-types';

export const getStaffContractRequest = (payload) => ({
    type: actionTypes.GET_STAFF_CONTRACT_REQUEST,
    payload
});

export const getStaffContractSuccess = (payload) => ({
    type: actionTypes.GET_STAFF_CONTRACT_SUCCESS,
    payload
});

export const getStaffContractFailed = () => ({
    type: actionTypes.GET_STAFF_CONTRACT_FAILED
});

export const postContractFormRequest = (payload) => ({
    type: actionTypes.POST_STAFF_CONTRACT_REQUEST,
    payload: { ...payload }
});

export const postContractFormSuccess = (payload) => ({
    type: actionTypes.POST_STAFF_CONTRACT_SUCCESS,
    payload
});

export const postContractFormFailed = () => ({
    type: actionTypes.POST_STAFF_CONTRACT_FAILED
});

export const getContractListRequest = (payload) => ({
    type: actionTypes.GET_STAFF_CONTRACT_LIST_REQUEST,
    payload
});
export const getContractListSuccess = (payload) => ({
    type: actionTypes.GET_STAFF_CONTRACT_LIST_SUCCESS,
    payload
});
export const getContractListFailed = () => ({
    type: actionTypes.GET_STAFF_CONTRACT_LIST_FAILED
});

export const getContractByIdRequest = (payload) => ({
    type: actionTypes.GET_STAFF_CONTRACT_BY_ID_REQUEST,
    payload
});

export const getContractByIdSuccess = (payload) => ({
    type: actionTypes.GET_STAFF_CONTRACT_BY_ID_SUCCESS,
    payload
});

export const getContractByIdFailed = (error) => ({
    type: actionTypes.GET_STAFF_CONTRACT_BY_ID_FAILED,
    payload: error
});

/**
 * @param {object} payload
 * @param {object} payload.contractData
 * @param {string} payload.contractId
 * @param {string} payload.staffId
 * @param {function} payload.callback
 */
export const updateContractRequest = (payload) => ({
    type: actionTypes.UPDATE_STAFF_CONTRACT_REQUEST,
    payload
});

export const updateContractSuccess = (payload) => ({
    type: actionTypes.UPDATE_STAFF_CONTRACT_SUCCESS,
    payload
});

export const updateContractFailed = (error) => ({
    type: actionTypes.UPDATE_STAFF_CONTRACT_FAILED,
    payload: error
});

/**
 * @param {object} payload
 * @param {string} payload.contractId
 * @param {string} payload.staffId
 * @param {function} payload.callback
 */
export const deleteContractRequest = (payload) => ({
    type: actionTypes.DELETE_STAFF_CONTRACT_REQUEST,
    payload
});

export const deleteContractSuccess = (payload) => ({
    type: actionTypes.DELETE_STAFF_CONTRACT_SUCCESS,
    payload
});

export const deleteContractFailed = (error) => ({
    type: actionTypes.DELETE_STAFF_CONTRACT_FAILED,
    payload: error
});

/**
 * @param {object} payload
 * @param {function} payload.callback
 * @param {number} payload.staffId
 * @param {number} payload.contractId
 */
export const startContractRequest = (payload) => ({
    type: actionTypes.START_STAFF_CONTRACT_REQUEST,
    payload
});

export const startContractSuccess = (payload) => ({
    type: actionTypes.START_STAFF_CONTRACT_SUCCESS,
    payload
});

export const startContractFailed = (error) => ({
    type: actionTypes.START_STAFF_CONTRACT_FAILED,
    payload: error
});

/**
 * @param {object} payload
 * @param {string} payload.staffId
 * @param {function} payload.callback
 */
export const getContractMinDateRequest = (payload) => ({
    type: actionTypes.GET_CONTRACT_MIN_DATE_REQUEST,
    payload
});

export const getContractMinDateSuccess = (payload) => ({
    type: actionTypes.GET_CONTRACT_MIN_DATE_SUCCESS,
    payload
});

export const getContractMinDateFailed = (error) => ({
    type: actionTypes.GET_CONTRACT_MIN_DATE_FAILED,
    payload: error
});

/**
 * @param {object} payload
 * @param {function} payload.callback
 * @param {number} payload.contractId
 * @param {number} payload.staffId
 * @param {object} payload.formData
 */
export const duplicateContractRequest = (payload) => ({
    type: actionTypes.DUPLICATE_CONTRACT_REQUEST,
    payload
});

export const duplicateContractSuccess = (payload) => ({
    type: actionTypes.DUPLICATE_CONTRACT_SUCCESS,
    payload
});

export const duplicateContractFailed = (error) => ({
    type: actionTypes.DUPLICATE_CONTRACT_FAILED,
    payload: error
});
