import React from 'react';
import PharmaciesListPage from './pharmacies-list';
import PharmacyDetails from './pharmacies-details';

import { Switch, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../routerConfig';

const AdminPharmaciesPage = (props) => {
    return (
        <Switch>
            <Route path={RouterConfig.superAdminPharmacies} component={PharmaciesListPage} exact />
            <Route path={RouterConfig.superAdminPharmacyRoot} component={PharmacyDetails} />
        </Switch>
    );
};

export default AdminPharmaciesPage;
