import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import { getPharmacyBranchesSuccess, getPharmacyBranchesFailed } from '../../../actions';
import { selectItemsPerPage } from './selectors';
import * as pharmacyBranchesActionTypes from '../../../actions/super-admin-view/pharmacy-branches-page/action-types';
import api from '../../../../services/api';

function* onPharmacyBranchesListRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page, id } = payload;
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${id}/branches/list`,
            method: 'post',
            payload: {
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getPharmacyBranchesSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getPharmacyBranchesFailed());
    }
}

function* watchPharmacyBranchesListRequest() {
    yield takeEvery(pharmacyBranchesActionTypes.GET_PHARMACY_BRANCHES_REQUEST, onPharmacyBranchesListRequest);
}

const adminPharmacyBranchesSaga = [fork(watchPharmacyBranchesListRequest)];

export default adminPharmacyBranchesSaga;
