import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../buttons/button';

import './style.scss';

function AnswerTabs({ label, name, initialState, onClick }) {
    const [activeState, setActive] = useState(null);

    useEffect(() => {
        setActive(initialState);
    }, [initialState]);

    const handleClick = (state) => {
        setActive(state === activeState ? null : state);
        onClick({ name, value: state });
    };

    return (
        <div className="answer-tabs">
            <label>{label}</label>
            <div className="answer-tabs__buttons">
                <ButtonComponent
                    value="yes"
                    type={activeState ? 'confirm' : 'bordered'}
                    size="medium"
                    width="medium"
                    onClick={() => handleClick(true)}
                />
                <ButtonComponent
                    value="no"
                    type={activeState === false ? 'confirm' : 'bordered'}
                    size="medium"
                    width="medium"
                    onClick={() => handleClick(false)}
                />
            </div>
        </div>
    );
}

AnswerTabs.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    initialState: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

export default AnswerTabs;
