import {
    SHOW_BACK_BUTTON,
    HIDE_BACK_BUTTON,
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    SET_CONTAINER_WIDE,
    SET_CONTAINER_DEFAULT,
    TOGGLE_PAGE_VIEW
} from '../../../actions/admin-view/branch-details-page-view/action-types';

const initialState = {
    sidebarVisibility: true,
    backbuttonVisibility: false,
    containerFluid: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_BACK_BUTTON:
            return {
                ...state,
                backbuttonVisibility: true
            };
        case HIDE_BACK_BUTTON:
            return {
                ...state,
                backbuttonVisibility: false
            };
        case SHOW_SIDEBAR:
            return {
                ...state,
                sidebarVisibility: true
            };
        case HIDE_SIDEBAR:
            return {
                ...state,
                sidebarVisibility: false
            };
        case SET_CONTAINER_WIDE:
            return {
                ...state,
                containerFluid: true
            };
        case SET_CONTAINER_DEFAULT:
            return {
                ...state,
                containerFluid: false
            };
        case TOGGLE_PAGE_VIEW:
            return (state = action.payload);
        default:
            return state;
    }
};
