import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Switcher = ({ onLeftClick, onRightClick, leftValue, rightValue, activeView }) => {
    return (
        <div className="button-switcher">
            <button
                className={`button-switcher__button left ${activeView === leftValue ? 'active' : ''}`}
                onClick={onLeftClick}
            >
                {leftValue}
            </button>
            <button
                className={`button-switcher__button right ${activeView === rightValue ? 'active' : ''}`}
                onClick={onRightClick}
            >
                {rightValue}
            </button>
        </div>
    );
};

Switcher.propTypes = {
    onLeftClick: PropTypes.func.isRequired,
    onRightClick: PropTypes.func.isRequired,
    leftValue: PropTypes.string.isRequired,
    rightValue: PropTypes.string.isRequired,
    activeView: PropTypes.string.isRequired
};

export default Switcher;
