import {
    GET_PERSONAL_INFO_REQUEST,
    GET_PERSONAL_INFO_SUCCESS,
    GET_PERSONAL_INFO_FAILED,
    POST_PERSONAL_INFO_REQUEST,
    POST_PERSONAL_INFO_SUCCESS,
    POST_PERSONAL_INFO_FAILED,
    CLEAR_PERSONAL_INFO,
    GET_RIGHTS_TO_WORK_INFO_REQUEST,
    GET_RIGHTS_TO_WORK_INFO_SUCCESS,
    GET_RIGHTS_TO_WORK_INFO_FAILED,
    POST_RIGHTS_TO_WORK_INFO_REQUEST,
    POST_RIGHTS_TO_WORK_INFO_SUCCESS,
    POST_RIGHTS_TO_WORK_INFO_FAILED,
    GET_SKILLS_INFO_REQUEST,
    GET_SKILLS_INFO_SUCCESS,
    GET_SKILLS_INFO_FAILED,
    POST_SKILLS_INFO_REQUEST,
    POST_SKILLS_INFO_SUCCESS,
    POST_SKILLS_INFO_FAILED,
    GET_ACCREDITATIONS_INFO_REQUEST,
    GET_ACCREDITATIONS_INFO_SUCCESS,
    GET_ACCREDITATIONS_INFO_FAILED,
    POST_ACCREDITATIONS_INFO_REQUEST,
    POST_ACCREDITATIONS_INFO_SUCCESS,
    POST_ACCREDITATIONS_INFO_FAILED,
    REMOVE_UPLOADED_FILE_REQUEST,
    REMOVE_UPLOADED_FILE_SUCCESS,
    REMOVE_UPLOADED_FILE_FAILED,
    SET_STORE_DOCUMENT,
    REMOVE_STORE_DOCUMENT,
    CLEAR_ACCREDITATIONS_DOCS,
    SET_SKILLS_DATA,
    SET_ACCREDITATIONS_CHECKBOX,
    SET_SKILLS_CHECKBOX,
    SET_SKILLS_STORE_DOCUMENT,
    REMOVE_SKILLS_DOCUMENT
} from './action-types';

export const getPersonalInfoRequest = (payload) => ({
    type: GET_PERSONAL_INFO_REQUEST,
    payload
});

export const getPersonalInfoSuccess = (payload) => ({
    type: GET_PERSONAL_INFO_SUCCESS,
    payload
});

export const getPersonalInfoFailed = () => ({
    type: GET_PERSONAL_INFO_FAILED
});

export const postPersonalInfoRequest = (payload, action, userInfoAction) => ({
    type: POST_PERSONAL_INFO_REQUEST,
    payload,
    action,
    userInfoAction
});

export const postPersonalInfoSuccess = () => ({
    type: POST_PERSONAL_INFO_SUCCESS
});

export const postPersonalInfoFailed = () => ({
    type: POST_PERSONAL_INFO_FAILED
});

export const clearPersonalInfo = () => ({
    type: CLEAR_PERSONAL_INFO
});

export const removeUploadedFileRequest = (payload, action, userInfoAction, onboardingForm) => ({
    type: REMOVE_UPLOADED_FILE_REQUEST,
    payload,
    action,
    userInfoAction,
    onboardingForm
});

export const removeUploadedFileSuccess = () => ({
    type: REMOVE_UPLOADED_FILE_SUCCESS
});

export const removeUploadedFileFailed = () => ({
    type: REMOVE_UPLOADED_FILE_FAILED
});

export const postRightsToWorkInfoRequest = (payload) => ({
    type: POST_RIGHTS_TO_WORK_INFO_REQUEST,
    payload
});

export const postRightsToWorkInfoSuccess = () => ({
    type: POST_RIGHTS_TO_WORK_INFO_SUCCESS
});

export const postRightsToWorkInfoFailed = () => ({
    type: POST_RIGHTS_TO_WORK_INFO_FAILED
});

export const getRightsToWorkInfoRequest = (payload) => ({
    type: GET_RIGHTS_TO_WORK_INFO_REQUEST,
    payload
});

export const getRightsToWorkInfoSuccess = (payload) => ({
    type: GET_RIGHTS_TO_WORK_INFO_SUCCESS,
    payload
});

export const getRightsToWorkInfoFailed = () => ({
    type: GET_RIGHTS_TO_WORK_INFO_FAILED
});

export const postSkillsInfoRequest = (payload) => ({
    type: POST_SKILLS_INFO_REQUEST,
    payload
});

export const postSkillsInfoSuccess = () => ({
    type: POST_SKILLS_INFO_SUCCESS
});

export const postSkillsInfoFailed = () => ({
    type: POST_SKILLS_INFO_FAILED
});

export const getSkillsInfoRequest = (payload) => ({
    type: GET_SKILLS_INFO_REQUEST,
    payload
});

export const getSkillsInfoSuccess = (payload) => ({
    type: GET_SKILLS_INFO_SUCCESS,
    payload
});

export const getSkillsInfoFailed = () => ({
    type: GET_SKILLS_INFO_FAILED
});

export const postAccreditationsInfoRequest = (payload, action, userInfoAction) => ({
    type: POST_ACCREDITATIONS_INFO_REQUEST,
    payload,
    action,
    userInfoAction
});

export const postAccreditationsInfoSuccess = () => ({
    type: POST_ACCREDITATIONS_INFO_SUCCESS
});

export const postAccreditationsInfoFailed = () => ({
    type: POST_ACCREDITATIONS_INFO_FAILED
});

export const getAccreditationsInfoRequest = (payload) => ({
    type: GET_ACCREDITATIONS_INFO_REQUEST,
    payload
});

export const getAccreditationsInfoSuccess = (payload) => ({
    type: GET_ACCREDITATIONS_INFO_SUCCESS,
    payload
});

export const getAccreditationsInfoFailed = () => ({
    type: GET_ACCREDITATIONS_INFO_FAILED
});

export const setStoreDocument = (payload) => ({
    type: SET_STORE_DOCUMENT,
    payload
});

export const removeStoreDocument = (name) => ({
    type: REMOVE_STORE_DOCUMENT,
    name
});

export const setSkillsStoreDocument = (payload) => ({
    type: SET_SKILLS_STORE_DOCUMENT,
    payload
});

export const removeSkillsDocument = (name) => ({
    type: REMOVE_SKILLS_DOCUMENT,
    name
});

export const clearAccreditationsDocs = () => ({
    type: CLEAR_ACCREDITATIONS_DOCS
});

export const setSkillsData = (payload) => ({
    type: SET_SKILLS_DATA,
    payload
});

export const setAccreditationsCheckbox = (payload) => ({
    type: SET_ACCREDITATIONS_CHECKBOX,
    payload
});

export const setSkillsCheckbox = (payload) => ({
    type: SET_SKILLS_CHECKBOX,
    payload
});
