import React, { useCallback, useEffect } from 'react';
import LocumSettingsForm from '../../../../components/forms/locum-settings-form';

import { weekDays } from '../../../../services/datesHelper';
import moment from 'moment';

export function LocumSettings({ settings, getLocumSettingsRequest, onLocumSettingsUpdateRequest }) {
    useEffect(() => {
        getLocumSettingsRequest();
    }, [getLocumSettingsRequest]);

    const onSettingsUpdate = useCallback(
        (settings) => {
            if (settings.preferred_working_days) {
                settings.preferred_working_days = settings.preferred_working_days.reduce((result, el) => {
                    if (el.value) {
                        return result.concat(el.key);
                    }
                    return result;
                }, []);
            }
            onLocumSettingsUpdateRequest({
                ...settings
            });
        },
        // eslint-disable-next-line
        [settings, onLocumSettingsUpdateRequest]
    );

    if (!settings.loaded) return null;

    const workingDaysFormatted = weekDays.map((weekDay) => {
        const daySelected = settings.preferred_working_days ? settings.preferred_working_days.includes(weekDay) : false;
        return {
            key: weekDay,
            value: daySelected,
            label: moment(weekDay, 'dddd').format('ddd')
        };
    });

    return (
        <div className="locum-settings-page">
            <LocumSettingsForm
                onSettingsChange={onSettingsUpdate}
                filledForm={{
                    ...settings,
                    preferred_working_days: workingDaysFormatted
                }}
            />
        </div>
    );
}
