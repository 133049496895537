import {
    GET_LOCUM_BY_GPHC_SUCCESS,
    GET_LOCUM_SCHEDULE_AVAILABILITY_SUCCESS,
    GET_LOCUM_BY_GPHC_FAILED,
    GET_LOCUM_SCHEDULE_AVAILABILITY_FAILED,
    CLEAR_LOCUM_DATA
} from '../../../actions/admin-view/locum/action-types';

const initialState = {
    data: null,
    loading: false,
    error: ''
};

const locumData = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCUM_BY_GPHC_SUCCESS:
        case GET_LOCUM_SCHEDULE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: ''
            };
        case GET_LOCUM_BY_GPHC_FAILED:
        case GET_LOCUM_SCHEDULE_AVAILABILITY_FAILED:
            return {
                ...state,
                data: {},
                error: action.payload.data.message
            };
        case CLEAR_LOCUM_DATA:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default locumData;
