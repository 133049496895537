import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react';
import TextInput from '../../UI/inputs/text-input';
import SelectComponent from '../../UI/select';
import ButtonComponent from '../../UI/buttons/button';
import TextAreaComponent from '../../UI/inputs/text-area';
import UploadFileHandler from '../../upload-remove-file-handler';
import RadioGroup from '../../UI/radio-group';
import CheckboxListContainer from '../../../containers/checkbox-list';
import LocationSearchInput from '../../UI/inputs/location-search-input';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import {
    dropdownError,
    zipError,
    phoneError,
    infoInputError,
    emptyInputError,
    textAreaError,
    branchNumberError
} from '../../../services/validate';

import { getSize, getExtention, getAddressFields } from '../../../services/helpers';
import notificationSettings from '../../../services/notificationSettings';

import { store } from 'react-notifications-component';

import './style.scss';

class EditBranchForm extends Component {
    state = {
        title: '',
        number: '',
        addressLineOne: '',
        addressLineTwo: '',
        description: '',
        country: '',
        region: '',
        regionSelected: '',
        systemSelected: '',
        city: '',
        zip: '',
        phone: '',
        itemPerMonth: '',
        MDSPerMonth: '',
        methadonePatientsPerDay: '',
        careHomesSupplied: '',
        supportLevel: this.props.data.staffSupportLevels[0].id,
        parkingInfo: '',
        pharmacySystem: '',
        providingServices: [],
        selectedServices: [],
        branchLogo: '',
        latitude: null,
        longitude: null,
        uploadedFiles: {
            branchLogo: ''
        },
        errors: {
            title: null,
            number: null,
            addressLineOne: null,
            addressLineTwo: null,
            description: null,
            country: null,
            region: null,
            zip: null,
            phone: null,
            branchLogo: null,
            itemPerMonth: null,
            MDSPerMonth: null,
            methadonePatientsPerDay: null,
            careHomesSupplied: null,
            supportLevel: null,
            parkingInfo: null,
            pharmacySystem: null,
            providingServices: null,
            form: null
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getBranchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getBranchData = () => {
        const {
            data: { branchServices, regions, pharmacySystems },
            branchInfo
        } = this.props;

        const getServices = branchServices.flatMap((service) => [
            {
                ...service,
                checked: !!branchInfo.branchServices.find((item) => item === service.id)
            }
        ]);

        let selectedServices = [];

        getServices.forEach((el) => {
            if (el.checked) selectedServices.push(el.id);
        });

        const {
            title,
            number,
            address1,
            address2,
            description,
            regionId,
            city,
            postalCode,
            phone,
            logo,
            itemsPerMonth,
            mdsPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            parkingInformation,
            pharmacySystemId,
            staffSupportLevelId,
            latitude,
            longitude
        } = branchInfo;

        let setRegion;
        regions.forEach((el, i) => {
            if (regionId === el.id) {
                setRegion = el.title;
            }
        });

        let setPharmacySystem;
        pharmacySystems.forEach((el, i) => {
            if (pharmacySystemId === el.id) {
                setPharmacySystem = el.title;
            }
        });

        this.setState({
            title,
            number,
            addressLineOne: address1,
            addressLineTwo: address2,
            description,
            region: regionId,
            regionSelected: setRegion,
            city,
            zip: postalCode,
            phone,
            branchLogo: logo,
            itemPerMonth: itemsPerMonth,
            MDSPerMonth: mdsPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            supportLevel: staffSupportLevelId,
            parkingInfo: parkingInformation,
            pharmacySystem: pharmacySystemId,
            systemSelected: setPharmacySystem,
            providingServices: getServices,
            selectedServices,
            latitude,
            longitude
        });
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        let inputValue;

        let setCoords = {};

        if (name === 'addressLineOne') {
            setCoords = {
                latitude: null,
                longitude: null
            };
        }

        switch (name) {
            case 'itemPerMonth':
                inputValue = Math.max(Math.min(value, 10000), 0) || '';
                break;
            case 'MDSPerMonth':
            case 'methadonePatientsPerDay':
            case 'careHomesSupplied':
                inputValue = !isNaN(value) && value ? Math.min(value, 10000) : '';
                break;
            default:
                inputValue = value;
        }

        return this.setState(
            {
                [name]: inputValue,
                ...setCoords
            },
            () => this.validate(name)
        );
    };

    onDropdownChange = (e, { name, value }) => {
        const { countries, regions, pharmacySystems } = this.props.data;
        let inputValue = value;

        if (name === 'country') {
            countries.forEach((el, i) => {
                if (value === el.name) {
                    inputValue = el.id;
                }
                return inputValue;
            });
        } else if (name === 'region') {
            regions.forEach((el, i) => {
                if (value === el.title) {
                    inputValue = el.id;
                }
            });
        } else if (name === 'pharmacySystem') {
            pharmacySystems.forEach((el, i) => {
                if (value === el.title) {
                    inputValue = el.id;
                }
            });
        }

        return this.setState(
            {
                [name]: inputValue
            },
            () => this.validate(name)
        );
    };

    onLogoChange = (e) => {
        const { errors } = this.state;
        const name = e.target.name;
        const value = e.target.value;
        const file = e.target.files[0];
        let errorMessage = null;

        // validate uploaded file
        errorMessage = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.bmp']);
        errorMessage = getSize(file);

        // display error message
        if (errorMessage !== null) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Warning',
                message: errorMessage,
                type: 'warning'
            });
        }

        this.setState({
            uploadedFiles: {
                [name]: file,
                [name + 'URL']: URL.createObjectURL(file)
            },
            [name]: value,
            errors: {
                ...errors,
                form: errorMessage
            }
        });
    };

    onFileRemoveHandler = (name) => {
        const {
            branchInfo: { logo },
            onRemoveFile
        } = this.props;

        if (logo) onRemoveFile();

        this.setState((prevState) => ({
            ...prevState,
            [name]: '',
            uploadedFiles: {
                ...prevState.uploadedFiles,
                [name]: '',
                [`${name}URL`]: ''
            }
        }));
    };

    handleAddressSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            let getStateProps = {
                zip: 'postal_code',
                city: ['locality', 'postal_town']
            };
            let getAddressProps = {
                streetNumber: 'street_number',
                streetName: 'route',
                district: ['administrative_area_level_2', 'political']
            };
            const getAddressLine = Object.values(getAddressFields(results[0], getAddressProps)).join(', ');
            const getFieldsResult = getAddressFields(results[0], getStateProps);
            const { lat, lng } = await getLatLng(results[0]);
            this.setState(
                (prevState) => ({
                    ...prevState,
                    addressLineOne: getAddressLine,
                    latitude: lat,
                    longitude: lng,
                    ...getFieldsResult
                }),
                () => Promise.all([this.validate('addressLineOne'), this.validate('zip')])
            );
        } catch (error) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Error',
                message: 'No results found',
                type: 'danger'
            });
        }
    };

    onCheckboxChange = (e, { name }) => {
        let checkBoxID = name.split('#ID=')[1];

        const { providingServices } = this.state;

        providingServices.forEach((el) => {
            if (+checkBoxID === el.id) {
                el.checked = !el.checked;
            }
        });

        let selectedServices = [];

        providingServices.forEach((el) => {
            if (el.checked) selectedServices.push(el.id);
        });

        this.setState({ selectedServices });
    };

    onLocationBlurHandler = (name) => {
        const { latitude, longitude } = this.state;

        if (!latitude && !longitude) {
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [name]: 'You should choose the address from the dropdown list'
                }
            }));
        }
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'title':
                error = infoInputError(value);
                break;
            case 'number':
                error = branchNumberError(value);
                break;
            case 'region':
            case 'pharmacySystem':
                error = dropdownError(value);
                break;
            case 'description':
                error = value ? textAreaError(value) : null;
                break;
            case 'zip':
                error = zipError(value);
                break;
            case 'phone':
                error = phoneError(value);
                break;
            case 'addressLineOne':
            case 'itemPerMonth':
            case 'MDSPerMonth':
            case 'methadonePatientsPerDay':
            case 'careHomesSupplied':
                error = emptyInputError(value);
                break;
            case 'addressLineTwo':
            case 'parkingInfo':
            case 'city':
                error = infoInputError(value, true);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('title'),
            this.validate('number'),
            this.validate('addressLineOne'),
            this.validate('city'),
            this.validate('zip'),
            this.validate('phone'),
            this.validate('itemPerMonth'),
            this.validate('MDSPerMonth'),
            this.validate('methadonePatientsPerDay'),
            this.validate('careHomesSupplied'),
            this.validate('parkingInfo'),
            this.validate('pharmacySystem')
        ]);

        if (this.state.description) {
            await this.validate('description');
        } else if (this.state.region) {
            await this.validate('region');
        }

        const {
            title,
            number,
            addressLineOne,
            addressLineTwo,
            description,
            region,
            city,
            zip,
            phone,
            uploadedFiles,
            itemPerMonth,
            MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            supportLevel,
            parkingInfo,
            pharmacySystem,
            latitude,
            longitude,
            selectedServices,
            errors
        } = this.state;
        const { onSuccess } = this.props;

        if (Object.values(errors).some((error) => !!error)) return;

        const editBranchData = {
            title,
            number,
            address1: addressLineOne,
            address2: addressLineTwo,
            description,
            city,
            postalCode: zip,
            phone,
            itemsPerMonth: itemPerMonth,
            mdsPerMonth: MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            staffSupportLevelId: supportLevel,
            parkingInformation: parkingInfo,
            pharmacySystemId: pharmacySystem,
            pharmacyServicesIds: selectedServices,
            latitude,
            longitude
        };

        if (region) editBranchData.regionId = region;

        // check if logo was uploaded
        if (typeof uploadedFiles.branchLogo === 'object') editBranchData.logo = uploadedFiles.branchLogo;

        onSuccess(editBranchData);
    };

    triggerInputFile = (e) => {
        e.preventDefault();
        this.fileInput.click();
    };

    render() {
        const {
            title,
            number,
            addressLineOne,
            addressLineTwo,
            description,
            city,
            regionSelected,
            systemSelected,
            latitude,
            longitude,
            zip,
            uploadedFiles,
            branchLogo,
            phone,
            supportLevel,
            itemPerMonth,
            MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            parkingInfo,
            providingServices,
            errors
        } = this.state;
        const {
            onBackClick,
            data: { regions, staffSupportLevels, pharmacySystems },
            loading
        } = this.props;

        const formatedRegionsOptions = regions.map((option) => {
            return {
                key: option.id,
                value: option.title,
                text: option.title
            };
        });

        const radioButtons = staffSupportLevels.map((item) => {
            return {
                id: item.id,
                label: `${item.title} staff`,
                name: 'supportLevel',
                value: item.id,
                checked: supportLevel === item.id,
                onChange: this.onChangeHandler
            };
        });

        const checkboxes = providingServices.map((item) => {
            return {
                id: item.id,
                label: item.title,
                name: `${item.title}#ID=${item.id}`,
                checked: item.checked,
                onChange: this.onCheckboxChange
            };
        });

        const formatedpharmacysOptions = pharmacySystems.map((option) => {
            return {
                key: option.id,
                value: option.title,
                text: option.title
            };
        });

        return (
            <>
                <div className="branch-info-page__head">
                    <h3 className="branch-info-page__title">Edit Branch</h3>
                    <div className="edit-branch-form__row buttons-row">
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="small"
                            onClick={onBackClick}
                            disabled={loading}
                        />
                        <ButtonComponent
                            value="Save"
                            onClick={this.onSubmit}
                            size="medium"
                            loading={loading}
                            disabled={Object.values(errors).some((error) => !!error) || !latitude || !longitude}
                        />
                    </div>
                </div>
                <Form className="edit-branch-form" autoComplete="some-random-string">
                    <div className="edit-branch-form__row">
                        <div className="edit-branch-form__contact-info col">
                            <div className="input-row">
                                <TextInput
                                    name="title"
                                    label="Branch Name"
                                    type="text"
                                    value={title}
                                    fluid={false}
                                    error={!!errors.title}
                                    helperText={errors.title}
                                    onChange={this.onChangeHandler}
                                />
                                <TextInput
                                    name="number"
                                    label="Branch number"
                                    type="text"
                                    value={number}
                                    fluid={false}
                                    error={!!errors.number}
                                    helperText={errors.number}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <LocationSearchInput
                                name="addressLineOne"
                                label="Address Line 1"
                                type="text"
                                value={addressLineOne}
                                error={!!errors.addressLineOne}
                                helperText={errors.addressLineOne}
                                searchOptions={{
                                    componentRestrictions: { country: 'uk' }
                                }}
                                onChangeHandler={this.onChangeHandler}
                                onHandleSelect={this.handleAddressSelect}
                                onBlur={() => this.onLocationBlurHandler('addressLineOne')}
                                fluid
                            />
                            <TextInput
                                name="addressLineTwo"
                                label="Address Line 2"
                                type="text"
                                value={addressLineTwo}
                                error={!!errors.addressLineTwo}
                                helperText={errors.addressLineTwo}
                                onChange={this.onChangeHandler}
                            />
                            <div className="input-row">
                                <TextInput
                                    name="city"
                                    label="Town/City"
                                    type="text"
                                    value={city}
                                    fluid={false}
                                    error={!!errors.city}
                                    helperText={errors.city}
                                    onChange={this.onChangeHandler}
                                />
                                <TextInput
                                    name="zip"
                                    label="Postcode"
                                    type="text"
                                    value={zip}
                                    fluid={false}
                                    error={!!errors.zip}
                                    helperText={errors.zip}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="input-row">
                                <SelectComponent
                                    name="region"
                                    label="Region"
                                    icon="search"
                                    key={regionSelected}
                                    defaultValue={regionSelected}
                                    search
                                    fluid={false}
                                    options={formatedRegionsOptions}
                                    error={!!errors.region}
                                    helperText={errors.region}
                                    onChange={this.onDropdownChange}
                                />
                                <TextInput
                                    name="phone"
                                    label="Phone"
                                    type="tel"
                                    value={phone}
                                    fluid={false}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <TextAreaComponent
                                name="description"
                                label="About Branch"
                                value={description || ''}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={this.onChangeHandler}
                            />
                        </div>
                        <div className="edit-branch-form__add-info col">
                            <div className="input-row">
                                <TextInput
                                    name="itemPerMonth"
                                    label="Item per month"
                                    type="text"
                                    value={String(itemPerMonth)}
                                    fluid={false}
                                    error={!!errors.itemPerMonth}
                                    helperText={errors.itemPerMonth}
                                    onChange={this.onChangeHandler}
                                />
                                <TextInput
                                    name="MDSPerMonth"
                                    label="MDS per month"
                                    type="text"
                                    value={String(MDSPerMonth)}
                                    fluid={false}
                                    error={!!errors.MDSPerMonth}
                                    helperText={errors.MDSPerMonth}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="input-row">
                                <TextInput
                                    name="methadonePatientsPerDay"
                                    label="Methadone patients per day"
                                    type="text"
                                    value={String(methadonePatientsPerDay)}
                                    fluid={false}
                                    error={!!errors.methadonePatientsPerDay}
                                    helperText={errors.methadonePatientsPerDay}
                                    onChange={this.onChangeHandler}
                                />
                                <TextInput
                                    name="careHomesSupplied"
                                    label="Care homes supplied"
                                    type="text"
                                    value={String(careHomesSupplied)}
                                    fluid={false}
                                    error={!!errors.careHomesSupplied}
                                    helperText={errors.careHomesSupplied}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <RadioGroup items={radioButtons} groupLabel="Staff Support Level" />
                            <div className="input-row">
                                <TextInput
                                    name="parkingInfo"
                                    label="Parking Information"
                                    type="text"
                                    value={String(parkingInfo)}
                                    fluid={false}
                                    error={!!errors.parkingInfo}
                                    helperText={errors.parkingInfo}
                                    onChange={this.onChangeHandler}
                                />
                                <SelectComponent
                                    name="pharmacySystem"
                                    label="Pharmacy System"
                                    fluid={false}
                                    key={systemSelected}
                                    defaultValue={systemSelected}
                                    options={formatedpharmacysOptions}
                                    error={!!errors.pharmacySystem}
                                    helperText={errors.pharmacySystem}
                                    onChange={this.onDropdownChange}
                                />
                            </div>
                            <CheckboxListContainer label="Providing services" items={checkboxes} />
                            <div className="input-row avatar-holder">
                                <label className="upload-label">Branch logo</label>
                                <UploadFileHandler
                                    inputName="branchLogo"
                                    buttonValue="Upload"
                                    stateProp={branchLogo}
                                    isAvatar
                                    uploadedFile={uploadedFiles.branchLogo}
                                    avatarURL={uploadedFiles.branchLogoURL}
                                    onChangeHandler={this.onLogoChange}
                                    onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                    refFunc={(fileInput) => (this.fileInput = fileInput)}
                                    triggerInput={this.triggerInputFile}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

EditBranchForm.propTypes = {
    data: PropTypes.object.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onRemoveFile: PropTypes.func.isRequired
};

export default EditBranchForm;
