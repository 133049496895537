import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import classnames from 'classnames';

import './style.scss';

const DateRangesPicker = ({
    name,
    label,
    value,
    fluid = true,
    locale,
    format = 'DD/MM/YYYY',
    loading,
    onChange,
    error,
    helperText,
    disabled,
    placeholder,
    datePickerOnly = true,
    minDate,
    maxDate,
    hidden,
    keepOpenOnClear = false,
    className
}) => {
    const handleOnChange = useCallback(
        (event, data) => {
            if (!event) return;
            onChange(null, data);
        },
        [onChange]
    );

    return (
        <div className={classnames('input-group', { fluid, hidden }, className)}>
            <label>{label}</label>
            <SemanticDatepicker
                datePickerOnly={datePickerOnly}
                value={value}
                autoComplete="off"
                name={name}
                type="range"
                onChange={handleOnChange}
                keepOpenOnClear={keepOpenOnClear}
                error={error}
                isDatepicker
                locale={locale}
                format={format}
                loading={loading}
                disabled={disabled}
                placeholder={placeholder}
                minDate={minDate}
                maxDate={maxDate}
            />
            <p className="helper-text">{helperText}</p>
        </div>
    );
};

DateRangesPicker.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    locale: PropTypes.string,
    format: PropTypes.string,
    fluid: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    datePickerOnly: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    hidden: PropTypes.bool,
    keepOpenOnClear: PropTypes.bool
};

export default DateRangesPicker;
