import {
    GET_STAFF_LIST_REQUEST,
    GET_STAFF_LIST_SUCCESS,
    GET_STAFF_LIST_FAILED,
    ON_STAFF_FILTER_CHANGE,
    ON_STAFF_FILTER_FALSE,
    CLEAR_STAFF_FILTERS
} from './action-types';

export const getStaffListRequest = (payload, filters) => ({
    type: GET_STAFF_LIST_REQUEST,
    payload,
    filters
});

export const getStaffListSuccess = (payload) => ({
    type: GET_STAFF_LIST_SUCCESS,
    payload
});

export const getStaffListFailed = () => ({
    type: GET_STAFF_LIST_FAILED
});

export const onStaffFilterChange = (payload, page) => ({
    type: ON_STAFF_FILTER_CHANGE,
    payload,
    page
});

export const onStaffFilterFalse = () => ({
    type: ON_STAFF_FILTER_FALSE
});

export const clearStaffFilters = () => ({
    type: CLEAR_STAFF_FILTERS
});
