import { ManagerSchedule } from './ManagerSchedule';
import { connect } from 'react-redux';
import {
    getStaffUserEventsRequest,
    onStaffUserEventsDateRangeChange,
    onLocumActionRequest,
    getLocumJobDetailsRequest,
    onLocumJobActionRequest,
    onGetScheduleShiftsByFilterRequest
} from '../../../../store/actions';

export default connect(
    (state) => ({
        schedule: state.staffUserSchedule,
        shiftsByFilter: state.branchSchedule.shiftsByFilter,
        jobApplyResponseData: state.jobPreviewPage.jobApplyResponseData,
        locumJobData: state.locumJobDetails
    }),
    {
        getStaffUserEventsRequest,
        onStaffUserEventsDateRangeChange,
        onLocumActionRequest,
        getLocumJobDetailsRequest,
        onLocumJobActionRequest,
        onGetScheduleShiftsByFilterRequest
    }
)(ManagerSchedule);
