import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../UI/inputs/text-input';
import SelectComponent from './../../../UI/select';
import ButtonComponent from '../../../UI/buttons/button';
import UploadFileHandler from '../../../upload-remove-file-handler';
import LocationSearchInput from '../../../UI/inputs/location-search-input';

import {
    dropdownError,
    zipError,
    phoneError,
    nameError,
    infoInputError,
    branchNumberError,
    emptyInputError
} from '../../../../services/validate';
import { getSize, getExtention, getAddressFields } from '../../../../services/helpers';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import notificationSettings from '../../../../services/notificationSettings';

import { store } from 'react-notifications-component';

import './style.scss';

class CreateBranchContactInfoForm extends Component {
    state = {
        title: '',
        number: '',
        addressLineOne: '',
        addressLineTwo: '',
        country: '',
        region: '',
        regionSelected: '',
        city: '',
        zip: '',
        phone: '',
        latitude: null,
        longitude: null,
        branchLogo: '',
        uploadedFiles: {
            branchLogo: ''
        },
        loading: false,
        validate: {
            title: false,
            number: false,
            addressLineOne: false,
            addressLineTwo: false,
            country: false,
            region: false,
            zip: false,
            phone: false,
            branchLogo: false
        },
        errors: {
            title: null,
            number: null,
            addressLineOne: null,
            addressLineTwo: null,
            country: null,
            region: null,
            zip: null,
            phone: null,
            branchLogo: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;

        const { filledForm, regions } = this.props;

        if (filledForm !== undefined && Object.keys(filledForm).length !== 0) {
            const { title, number, address1, address2, latitude, longitude, regionId, city, postalCode, phone, logo } =
                filledForm;

            let setRegion;
            regions.forEach((el, i) => {
                if (regionId === el.id) {
                    setRegion = el.title;
                }
            });

            this.setState({
                title,
                number,
                addressLineOne: address1,
                addressLineTwo: address2,
                region: regionId,
                regionSelected: setRegion,
                city,
                zip: postalCode,
                phone,
                latitude,
                longitude,
                uploadedFiles: {
                    branchLogo: logo,
                    branchLogoURL: logo ? URL.createObjectURL(logo) : ''
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLogoChange = (e) => {
        const { errors } = this.state;
        const name = e.target.name;
        const value = e.target.value;
        const file = e.target.files[0];
        let errorMessage = null;

        // validate uploaded file
        errorMessage = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.bmp']);
        errorMessage = getSize(file);

        // display error message
        if (errorMessage !== null) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Warning',
                message: errorMessage,
                type: 'warning'
            });
        }

        this.setState({
            uploadedFiles: {
                [name]: file,
                [name + 'URL']: URL.createObjectURL(file)
            },
            [name]: value,
            errors: {
                ...errors,
                form: errorMessage
            }
        });
    };

    onFileRemoveHandler = (name) =>
        this.setState((prevState) => ({
            ...prevState,
            [name]: '',
            uploadedFiles: {
                ...prevState.uploadedFiles,
                [name]: '',
                [name + 'URL']: ''
            }
        }));

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;
        let inputValue = value;

        let setCoords = {};

        if (name === 'addressLineOne') {
            setCoords = {
                latitude: null,
                longitude: null
            };
        }

        if (name === 'title' || name === 'city') {
            inputValue = value.replace(/[0-9]/g, '');
        }

        return this.setState(
            {
                [name]: /^\s/.test(inputValue) ? '' : inputValue,
                ...setCoords
            },
            () => this.validate(name)
        );
    };

    onDropdownChange = (e, { name, value }) => {
        const { errors, validate } = this.state;
        const { countries, regions } = this.props;
        let inputValue = value;

        if (name === 'country') {
            countries.forEach((el, i) => {
                if (value === el.name) {
                    inputValue = el.id;
                }
                return inputValue;
            });
        } else if (name === 'region') {
            regions.forEach((el, i) => {
                if (value === el.title) {
                    inputValue = el.id;
                }
            });
        }

        return this.setState(
            {
                [name]: inputValue
            },
            () => {
                if (errors[name] || validate[name]) this.validate(name);
            }
        );
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'title':
                error = nameError(value, true);
                break;
            case 'number':
                error = branchNumberError(value);
                break;
            case 'region':
            case 'country':
                error = dropdownError(value);
                break;
            case 'addressLineOne':
                error = emptyInputError(value);
                break;
            case 'addressLineTwo':
            case 'city':
                error = infoInputError(value, true);
                break;
            case 'zip':
                error = zipError(value);
                break;
            case 'phone':
                error = phoneError(value);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    triggerInputFile = (e) => {
        e.preventDefault();
        this.fileInput.click();
    };

    onProceedToStepTwo = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('title'),
            this.validate('number'),
            this.validate('addressLineOne'),
            this.validate('city'),
            this.validate('zip'),
            this.validate('phone')
        ]);

        const {
            title,
            number,
            addressLineOne,
            addressLineTwo,
            region,
            city,
            zip,
            phone,
            uploadedFiles,
            errors,
            longitude,
            latitude
        } = this.state;
        const { onSuccess } = this.props;

        if (Object.values(errors).some((error) => !!error)) return;

        const contactInfoData = {
            title,
            number,
            address1: addressLineOne,
            address2: addressLineTwo,
            city,
            postalCode: zip,
            phone,
            longitude,
            latitude,
            logo: uploadedFiles.branchLogo
        };

        if (region) contactInfoData.regionId = region;

        onSuccess(contactInfoData);
    };

    handleAddressSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            let getStateProps = {
                zip: 'postal_code',
                city: ['locality', 'postal_town']
            };
            let getAddressProps = {
                streetNumber: 'street_number',
                streetName: 'route',
                district: ['administrative_area_level_2', 'political']
            };
            const getAddressLine = Object.values(getAddressFields(results[0], getAddressProps)).join(', ');
            const getFieldsResult = getAddressFields(results[0], getStateProps);
            const { lat, lng } = await getLatLng(results[0]);
            this.setState(
                (prevState) => ({
                    ...prevState,
                    addressLineOne: getAddressLine,
                    latitude: lat,
                    longitude: lng,
                    ...getFieldsResult
                }),
                () => Promise.all([this.validate('addressLineOne'), this.validate('zip')])
            );
        } catch (error) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Error',
                message: 'No results found',
                type: 'danger'
            });
        }
    };

    onLocationBlurHandler = (name) => {
        const { latitude, longitude } = this.state;

        if (!latitude && !longitude) {
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [name]: 'You should choose the address from the dropdown list'
                }
            }));
        }
    };

    render() {
        const {
            title,
            number,
            addressLineOne,
            addressLineTwo,
            latitude,
            longitude,
            city,
            regionSelected,
            zip,
            branchLogo,
            uploadedFiles,
            phone,
            loading,
            errors
        } = this.state;
        const { onCancelClick, regions } = this.props;

        const formatedRegionsOptions = regions.map((option) => {
            return {
                key: option.id,
                value: option.title,
                text: option.title
            };
        });

        return (
            <div className="create-branch-contact-info-form">
                <div className="input-row">
                    <TextInput
                        name="title"
                        label="Title"
                        type="text"
                        value={title}
                        fluid={false}
                        error={!!errors.title}
                        helperText={errors.title}
                        onChange={this.onChangeHandler}
                    />
                    <SelectComponent
                        name="region"
                        label="Region"
                        icon="search"
                        key={regionSelected}
                        defaultValue={regionSelected}
                        search
                        fluid={false}
                        options={formatedRegionsOptions}
                        error={!!errors.region}
                        helperText={errors.region}
                        onChange={this.onDropdownChange}
                    />
                </div>
                <LocationSearchInput
                    name="addressLineOne"
                    label="Address Line 1"
                    type="text"
                    value={addressLineOne}
                    error={!!errors.addressLineOne}
                    helperText={errors.addressLineOne}
                    searchOptions={{
                        componentRestrictions: { country: 'uk' }
                    }}
                    onChangeHandler={this.onChangeHandler}
                    onHandleSelect={this.handleAddressSelect}
                    onBlur={() => this.onLocationBlurHandler('addressLineOne')}
                    fluid
                />
                <TextInput
                    name="addressLineTwo"
                    label="Address Line 2"
                    type="text"
                    value={addressLineTwo}
                    error={!!errors.addressLineTwo}
                    helperText={errors.addressLineTwo}
                    onChange={this.onChangeHandler}
                />
                <div className="input-row">
                    <TextInput
                        name="city"
                        label="City"
                        type="text"
                        value={city}
                        fluid={false}
                        error={!!errors.city}
                        helperText={errors.city}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="number"
                        label="Branch number"
                        type="text"
                        value={number}
                        fluid={false}
                        error={!!errors.number}
                        helperText={errors.number}
                        onChange={this.onChangeHandler}
                    />
                </div>
                <div className="input-row">
                    <TextInput
                        name="zip"
                        label="Post Code"
                        type="text"
                        value={zip}
                        fluid={false}
                        error={!!errors.zip}
                        helperText={errors.zip}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="phone"
                        label="Phone"
                        type="tel"
                        value={phone}
                        fluid={false}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        onChange={this.onChangeHandler}
                    />
                </div>
                <div className="input-row avatar-holder">
                    <label className="upload-label">Branch logo</label>
                    <UploadFileHandler
                        inputName="branchLogo"
                        buttonValue="Upload"
                        stateProp={branchLogo}
                        isAvatar
                        uploadedFile={uploadedFiles.branchLogo}
                        avatarURL={uploadedFiles.branchLogoURL}
                        onChangeHandler={this.onLogoChange}
                        onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                        refFunc={(fileInput) => (this.fileInput = fileInput)}
                        triggerInput={this.triggerInputFile}
                    />
                </div>
                <div className="buttons-row">
                    <ButtonComponent
                        value="Continue"
                        onClick={this.onProceedToStepTwo}
                        loading={loading}
                        size="medium"
                        disabled={Object.values(errors).some((error) => !!error) || !latitude || !longitude}
                    />
                    <ButtonComponent
                        value="Cancel"
                        type="transparent"
                        size="small"
                        onClick={onCancelClick}
                        loading={loading}
                    />
                </div>
            </div>
        );
    }
}

CreateBranchContactInfoForm.propTypes = {
    onSuccess: PropTypes.func,
    onCancelClick: PropTypes.func,
    countries: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    filledForm: PropTypes.object
};

export default CreateBranchContactInfoForm;
