import React from 'react';
import PropTypes from 'prop-types';

const DayViewShiftCell = ({
    startTime,
    endTime,
    lunchStartTime,
    lunchEndTime,
    isDraft,
    isJob,
    isClosed,
    closedStartTime,
    closedEndTime,
    className,
    onClick,
    paidLunch
}) => {
    const cellInner = isClosed ? (
        <>
            <span className="inner__title">Branch Closed</span>
            {closedStartTime ? <span className="inner__lunch">{`${closedStartTime} - ${closedEndTime}`}</span> : null}
        </>
    ) : (
        <>
            <span className="inner__title">{`${startTime} - ${endTime}`}</span>
            <span className="inner__lunch">
                {!lunchStartTime ? 'no lunch' : `lunch ${lunchStartTime} - ${lunchEndTime}`}
            </span>
        </>
    );

    return (
        <div
            onClick={onClick}
            className={`schedule-day-view__shift-cell ${className} ${
                isDraft ? 'is-draft' : isJob ? 'job-posted' : isClosed ? 'closed' : 'shift'
            }`}
        >
            <div className={`inner ${isClosed && !closedStartTime ? 'centered' : ''}`}>{cellInner}</div>
        </div>
    );
};

DayViewShiftCell.propTypes = {
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    lunchStartTime: PropTypes.string,
    lunchEndTime: PropTypes.string,
    isDraft: PropTypes.bool,
    isJob: PropTypes.bool,
    isClosed: PropTypes.bool,
    closedStartTime: PropTypes.string,
    closedEndTime: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    paidLunch: PropTypes.bool
};

export default DayViewShiftCell;
