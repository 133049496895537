import { SAVE_FILE_REQUEST, SAVE_FILE_SUCCESS, SAVE_FILE_FAILED } from './action-types';

export const saveFileRequest = (payload) => ({
    type: SAVE_FILE_REQUEST,
    payload
});

export const saveFileSuccess = () => ({
    type: SAVE_FILE_SUCCESS
});

export const saveFileFailed = () => ({
    type: SAVE_FILE_FAILED
});
