import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'semantic-ui-react';
import classnames from 'classnames';

import './style.scss';

const ProfileCard = ({ avatar, name, surname, role, status, stats, rating, ...props }) => {
    return (
        <Card className="profile-card">
            <Image
                className={classnames({
                    error: !avatar
                })}
                src={avatar}
                wrapped
                size="small"
                circular
            />
            <Card.Content>
                <Card.Header className="profile-card__title">{`${name === null ? '' : name} ${
                    surname === null ? '' : surname
                }`}</Card.Header>
                <Card.Description>
                    <p className="profile-card__role">{role ? role.replace(/_/g, ' ') : null}</p>
                    <p className="profile-card__status">{status || null}</p>
                    <div className="profile-card__stats">
                        {rating && (
                            <div
                                className="profile-card__stats_tile profile-card__stats_rating"
                                style={{ background: '#8ac575' }}
                            >
                                Rating
                                <div className="profile-card__stats_value">{rating.value}</div>
                            </div>
                        )}
                        {stats && (
                            <div className="profile-card__stats_tile profile-card__stats_jobs">
                                Completed / cancelled
                                <div className="profile-card__stats_value">
                                    {stats.completedShifts} / {stats.cancelledShifts}
                                </div>
                            </div>
                        )}
                    </div>
                </Card.Description>
            </Card.Content>
            <Card.Content extra>{props.children}</Card.Content>
        </Card>
    );
};

ProfileCard.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    role: PropTypes.string.isRequired,
    status: PropTypes.string,
    rating: PropTypes.object,
    stats: PropTypes.object
};

export default ProfileCard;
