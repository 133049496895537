import React, { useEffect } from 'react';
import PageNav from '../../../../components/page-nav';
import TableComponent from '../../../../components/table';

import { usePage } from './../../../../services/hooks';
import { connect } from 'react-redux';

import {
    getJobOffersListRequest,
    onJobOffersListFilterChange,
    clearJobOffersListFilters
} from '../../../../store/actions';
import { onFilter } from '../../../../services/fetchDataHelper';
import { usePrevious } from '../../../../services/hooks';

import moment from 'moment';

import './style.scss';

function AdminJobOffersPage({
    jobOffersPage: { data, totalData, itemsPerPage, filters, isFiltering, redirectPageTo },
    getJobOffersListRequest,
    onJobOffersListFilterChange,
    clearJobOffersListFilters,
    location: { pathname },
    history
}) {
    const page = usePage();
    const prevPage = usePrevious(page);
    const prevRoute = usePrevious(pathname);

    useEffect(() => {
        const unlisten = history.listen(({ pathname }) => {
            if (pathname !== prevRoute) clearJobOffersListFilters();
        });
        return () => {
            unlisten();
        };
    }, [history, clearJobOffersListFilters, prevRoute]);

    useEffect(() => {
        if (!page || !!redirectPageTo) return;

        if (page && page !== prevPage && prevPage === undefined) {
            getJobOffersListRequest({ page });
        } else if (page && page !== prevPage && !isFiltering) {
            getJobOffersListRequest({ page });
        } else if (page && page !== prevPage && isFiltering) {
            getJobOffersListRequest({ page }, filters);
        }
    }, [filters, getJobOffersListRequest, isFiltering, page, prevPage, redirectPageTo]);

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'id',
                title: 'id',
                value: filters.id,
                render: (item) => item.id,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'pharmacyName',
                title: 'pharmacy',
                value: filters.pharmacyName,
                render: (item) => item.pharmacyName,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'branchTitle',
                title: 'branch',
                value: filters.branchTitle,
                render: (item) => item.branchTitle,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'startDate',
                title: 'date from',
                value: filters.startDate ? new Date(filters.startDate) : null,
                render: (item) => moment(item.startDate).format('DD/MM/YY'),
                type: 'date',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'endDate',
                title: 'date to',
                value: filters.endDate ? new Date(filters.endDate) : null,
                render: (item) => moment(item.endDate).format('DD/MM/YY'),
                type: 'date',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'hourlyRate',
                title: 'hourly rate',
                value: filters.hourlyRate,
                render: (item) => `£${item.hourlyRate}`,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'locumName',
                title: 'locum',
                value: filters.locumName,
                render: (item) => item.locumName || 'None',
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            },
            {
                name: 'status',
                title: 'status',
                value: filters.status,
                render: (item) => item.status,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onJobOffersListFilterChange, page)
            }
        ];

        return (
            <div className="admin-job-offers-page">
                <div className="main-container">
                    <PageNav title="Job Offers" />
                    <TableComponent
                        cols={tableCols}
                        data={data.rows}
                        totalItems={totalData}
                        itemsPerPage={itemsPerPage}
                        page={Number(page)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    jobOffersPage: state.adminJobOffersPage
});

const mapDispatchToProps = {
    getJobOffersListRequest,
    onJobOffersListFilterChange,
    clearJobOffersListFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminJobOffersPage);
