import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CreateBranchContactInfoForm from '../../components/forms/create-branch-forms/contact-info-form';
import CreateBranchAddInfoForm from '../../components/forms/create-branch-forms/additional-info-form';

import CloseButton from '../../components/UI/buttons/close-button';
import LoaderLocal from '../../components/UI/local-loader';

import { Form } from 'semantic-ui-react';

import './style.scss';

export function CreateBranchContainer({
    onCreateBranchSuccess,
    onCancel,
    closeIcon = true,
    getBranchesDataRequest,
    onCreateBranchRequest,
    data,
    loading,
    ...props
}) {
    const [formStep, setFormStep] = useState('contactInfo');
    const [contactInfoData, setContactInfoData] = useState({});
    const [addInfoData, setAddInfoData] = useState({});

    useEffect(() => {
        getBranchesDataRequest();
    }, [getBranchesDataRequest]);

    const onProceedToStepTwo = (data) => {
        setContactInfoData(data);
        setFormStep('additionalinfo');
    };

    const onCreateBranch = useCallback(
        (data) => {
            const submitData = {
                ...contactInfoData,
                ...data
            };
            let formData = new FormData();

            for (let key in submitData) {
                formData.append(key, submitData[key]);
            }

            onCreateBranchRequest({
                formData,
                callback: () => onCreateBranchSuccess()
            });
        },
        [contactInfoData, onCreateBranchRequest, onCreateBranchSuccess]
    );

    let formStepView;
    let subtitle;

    let displayCloseIcon = closeIcon ? (
        <div className="col">
            <CloseButton onClick={() => onCancel()} />
        </div>
    ) : null;

    if (data === null) {
        return (
            <Form className="create-branch-form">
                <LoaderLocal size="huge" text="Please wait..." color="blue" />
            </Form>
        );
    } else {
        if (formStep === 'contactInfo') {
            subtitle = 'Contact information';
            formStepView = (
                <CreateBranchContactInfoForm
                    onSuccess={onProceedToStepTwo}
                    onCancelClick={() => onCancel()}
                    countries={data.countries}
                    regions={data.regions}
                    filledForm={contactInfoData}
                />
            );
        } else if (formStep === 'additionalinfo') {
            subtitle = 'Additional information';
            formStepView = (
                <CreateBranchAddInfoForm
                    onSuccess={onCreateBranch}
                    onCancelClick={(data) => {
                        setAddInfoData(data);
                        setFormStep('contactInfo');
                    }}
                    filledForm={addInfoData}
                    supportLevels={data.staffSupportLevels}
                    pharmacySystems={data.pharmacySystems}
                    branchServices={data.branchServices}
                />
            );
        }

        return (
            <Form loading={loading} className="create-branch-form">
                <div className="create-branch-form__head">
                    <div className="col">
                        <h2 className="create-branch-form__title">Add Branch</h2>
                        <p className="create-branch-form__subtitle">{subtitle}</p>
                    </div>
                    {displayCloseIcon}
                </div>
                {formStepView}
            </Form>
        );
    }
}

CreateBranchContainer.propTypes = {
    onCreateBranchSuccess: PropTypes.func,
    onCancel: PropTypes.func
};
