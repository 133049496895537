import { call, put, fork, takeLatest, takeLeading, select, delay } from 'redux-saga/effects';
import {
    getBranchesListRequest,
    getBranchesListSuccess,
    getBranchesListFailed,
    onBranchesFilterFalse
} from '../../../actions';
import { selectItemsPerPage, selectFilters } from './selectors';
import * as branchesListActionTypes from '../../../actions/admin-view/branches-page/action-types';
import api from '../../../../services/api';

function* onBranchesListRequest({ payload, filters }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page } = payload;
    const searchFilters = filters ? filters : {};

    try {
        const { data } = yield call(api.request, {
            url: '/branches/list',
            method: 'post',
            payload: {
                filters: searchFilters,
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getBranchesListSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getBranchesListFailed());
    }
}

function* onBranchesFilterChange({ payload: { value }, page }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field.length > 0);

    if (value.length > 0) {
        yield delay(1000);
        yield put(getBranchesListRequest({ page }, filters));
    } else if ((value.length === 0 && isFilled) || typeof value === 'boolean') {
        yield put(getBranchesListRequest({ page }, filters));
    } else if (value.length === 0 && !isFilled && page !== 1) {
        yield put(onBranchesFilterFalse());
    } else if (value.length === 0 && !isFilled && page === 1) {
        yield put(getBranchesListRequest({ page }, filters));
        yield put(onBranchesFilterFalse());
    }
}

// watchers
function* watchBranchesListRequest() {
    yield takeLeading(branchesListActionTypes.GET_BRANCHES_LIST_REQUEST, onBranchesListRequest);
}

function* watchBranchesFilterchange() {
    yield takeLatest(branchesListActionTypes.ON_BRANCHES_FILTER_CHANGE, onBranchesFilterChange);
}

const branchesListSaga = [fork(watchBranchesListRequest), fork(watchBranchesFilterchange)];

export default branchesListSaga;
