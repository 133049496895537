import {
    GET_PHARMACY_STAFF_FAILED,
    GET_PHARMACY_STAFF_SUCCESS
} from '../../../actions/super-admin-view/pharmacy-staff-page/action-types';

const initialState = {
    redirectPageTo: null,
    loading: false,
    data: null,
    totalData: 0,
    itemsPerPage: 9
};

const adminPharmacyStaffPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_PHARMACY_STAFF_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case GET_PHARMACY_STAFF_FAILED:
            return state;
        default:
            return state;
    }
};

export default adminPharmacyStaffPage;
