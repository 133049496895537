import {
    ON_CREATE_BRANCH_REQUEST,
    ON_CREATE_BRANCH_SUCCESS,
    ON_CREATE_BRANCH_FAILED,
    GET_BRANCHES_DATA_REQUEST,
    GET_BRANCHES_DATA_SUCCESS,
    GET_BRANCHES_DATA_FAILED,
    CLEAR_CREATED_BRANCH_INFO,
    UPDATE_BRANCH_RATE_REQUEST,
    UPDATE_BRANCH_RATE_SUCCESS,
    UPDATE_BRANCH_RATE_FAILED
} from './action-types';

export const onCreateBranchRequest = (payload) => ({
    type: ON_CREATE_BRANCH_REQUEST,
    payload
});

export const onCreateBranchSuccess = (payload) => ({
    type: ON_CREATE_BRANCH_SUCCESS,
    payload
});

export const onCreateBranchFailed = () => ({
    type: ON_CREATE_BRANCH_FAILED
});

export const getBranchesDataRequest = () => ({
    type: GET_BRANCHES_DATA_REQUEST
});

export const getBranchesDataSuccess = (payload) => ({
    type: GET_BRANCHES_DATA_SUCCESS,
    payload
});

export const getBranchesDataFailed = () => ({
    type: GET_BRANCHES_DATA_FAILED
});

export const clearCreatedBranchInfo = () => ({
    type: CLEAR_CREATED_BRANCH_INFO
});

export const onUpdateBranchRateRequest = (payload) => ({
    type: UPDATE_BRANCH_RATE_REQUEST,
    payload
});

export const onUpdateBranchRateSuccess = (payload) => ({
    type: UPDATE_BRANCH_RATE_SUCCESS,
    payload
});

export const onUpdateBranchRateFailed = () => ({
    type: UPDATE_BRANCH_RATE_FAILED
});
