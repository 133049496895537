import React, { useState, useCallback, useEffect } from 'react';
import { useModal } from './../../../../../../services/hooks';
import BasicModal from '../../../../../../components/modals/basic-modal';
import SuperAdminBillingDetailsForm from '../../../../../../components/forms/super-admin-billing-details-form';
import StripeCustomPagination from '../../../../../../components/stripe-custom-pagination';
import TransactionsTable from '../../../../admin-view/pharmacy-info-page/billing/TransactionsTable';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    getPharmacyInvoicesRequest,
    getPharmacyAllBranchesRequest,
    getPharmacySubscriptionRequest,
    getPharmacyBranchDeactivateRequest,
    clearInvoicesList
} from '../../../../../../store/actions/super-admin-view/pharmacy-billing-page';
import { putPharmacyUpdateSubscriptionsRequest } from '../../../../../../store/actions/super-admin-view/pharmacy-billing-page';

import './style.scss';

const perPage = 10;

const AdminPharmacyBillingPage = ({
    match: {
        params: { id }
    },
    getPharmacyInvoicesRequest,
    getPharmacyAllBranchesRequest,
    getPharmacySubscriptionRequest,
    getPharmacyBranchDeactivateRequest,
    putPharmacyUpdateSubscriptionsRequest,
    clearInvoicesList,
    transactions: { data, hasMore, page },
    subscriptionPlan,
    branches,
    adminPharmacyInfoPage: { data: personalInfo }
}) => {
    const [subscriptionModalOpen, setSubscriptionModalOpen] = useModal(false);
    const [activeListPage, setActivePage] = useState(1);

    useEffect(() => {
        getPharmacySubscriptionRequest(id);
        getPharmacyAllBranchesRequest(id);
        getPharmacyInvoicesRequest({ page: 1, perPage, id });
    }, [id, getPharmacySubscriptionRequest, getPharmacyAllBranchesRequest, getPharmacyInvoicesRequest]);

    useEffect(() => {
        getPharmacyAllBranchesRequest(id);

        return () => {
            clearInvoicesList();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionPlan.redirectUrl]);

    const onPaginate = useCallback(
        (direction) => {
            let payload = { perPage, page, id };

            if (direction === 'next') {
                payload = { ...payload, page: page + 1 };
                setActivePage(activeListPage + 1);
            } else {
                payload = { ...payload, page: page - 1 };
                setActivePage(activeListPage - 1);
            }

            getPharmacyInvoicesRequest(payload);
        },
        [activeListPage, getPharmacyInvoicesRequest, page, id]
    );

    const onSubmitBranchSubscriptions = useCallback(
        (updatedBranches) =>
            putPharmacyUpdateSubscriptionsRequest({
                branches: updatedBranches,
                id,
                cb: () => {
                    getPharmacyAllBranchesRequest(id);
                    getPharmacyInvoicesRequest({ page: 1, perPage, id });
                    setSubscriptionModalOpen(false);
                }
            }),
        // eslint-disable-next-line
        [personalInfo]
    );

    const onDeactivateBranch = (branchId) => {
        getPharmacyBranchDeactivateRequest({
            id,
            branchId,
            cb: () => getPharmacyAllBranchesRequest(id)
        });
    };

    const reloadPersonalInfo = useCallback(() => {
        getPharmacyAllBranchesRequest(id);
    }, [getPharmacyAllBranchesRequest, id]);

    const pagination =
        data && data.length >= 1 ? (
            <StripeCustomPagination
                prevDisabled={page === 1}
                nextDisabled={!hasMore}
                onPaginate={onPaginate}
                modalView
            />
        ) : null;

    const transactions = data
        ? data.map((item) => {
              return {
                  ...item,
                  id: item.transaction_id,
                  total: item.amount
              };
          })
        : [];

    return (
        <>
            <div className="pharmacy-info-page billing-page">
                <div className="pharmacy-info-page__head">
                    <h3 className="pharmacy-info-page__title">{'Subscription & Billing'}</h3>
                </div>
                <div className="info-dashboard">
                    <div className="info-dashboard__row">
                        <div className="col">
                            <div className="info-box">
                                <h4 className="info-box__title">
                                    Subscription Details
                                    <span
                                        role="button"
                                        className="edit-button"
                                        onClick={() => setSubscriptionModalOpen(!subscriptionModalOpen)}
                                    >
                                        Edit
                                    </span>
                                </h4>
                                <div className="item-row">
                                    <div className="info-box__item">
                                        <p className="name">Subscription Type</p>
                                        <div>{subscriptionPlan.data && subscriptionPlan.data.plan.name}</div>
                                    </div>
                                    <div className="info-box__item">
                                        <p className="name">Price</p>
                                        <div className="description price">
                                            {subscriptionPlan.data ? `£${subscriptionPlan.data.amount}` : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="item-row">
                                    <div className="info-box__item">
                                        <p className="name">Billing Period</p>
                                        <div className="description">Month</div>
                                    </div>
                                    <div className="info-box__item">
                                        <p className="name">Next Payment</p>
                                        <div className="description price">
                                            {subscriptionPlan.data
                                                ? moment(subscriptionPlan.data.next_billing_at).format('DD/MM/YY')
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-dashboard__row">
                        <div className="col">
                            <div className="info-box">
                                <h4 className="info-box__title">List of Transactions</h4>
                                <TransactionsTable data={transactions} />
                                <div className="pagination-wrapper">{pagination}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {subscriptionModalOpen && (
                <BasicModal
                    open={subscriptionModalOpen}
                    onClose={() => setSubscriptionModalOpen()}
                    title="Subscription per branch"
                    size="small"
                >
                    <SuperAdminBillingDetailsForm
                        onCancelClick={() => setSubscriptionModalOpen()}
                        onSubmit={onSubmitBranchSubscriptions}
                        loading={false}
                        branches={branches.data || []}
                        onDeactivateBranch={onDeactivateBranch}
                        reloadPersonalInfo={reloadPersonalInfo}
                    />
                </BasicModal>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    subscriptionPlan: state.pharmacyBillingPage.subscriptionPlan,
    transactions: state.pharmacyBillingPage.transactions,
    branches: state.pharmacyBillingPage.branches,
    adminPharmacyInfoPage: state.adminPharmacyInfoPage
});

const mapDispatchToProps = {
    getPharmacyInvoicesRequest,
    getPharmacyAllBranchesRequest,
    getPharmacySubscriptionRequest,
    getPharmacyBranchDeactivateRequest,
    putPharmacyUpdateSubscriptionsRequest,
    clearInvoicesList
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPharmacyBillingPage);
