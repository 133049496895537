import { call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteBranchTemplateFailed,
    getBranchTemplateFailed,
    getBranchTemplateRequest,
    getBranchTemplateSuccess,
    postBranchTemplateFailed,
    scheduleActionSuccess,
    scheduleActionFailed,
    templatePublishSuccess,
    templatePublishFailed
} from '../../../actions';
import * as branchTemplateActionTypes from '../../../actions/admin-view/branch-template/action-types';
import api from '../../../../services/api';

function* onBranchTemplateRequest({ id }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${id}/template/`,
            method: 'get'
        });
        yield put(getBranchTemplateSuccess(data));
    } catch (err) {
        yield put(getBranchTemplateFailed());
    }
}

function* onBranchTemplatePublishPost({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${payload.branchId}/template/publish`,
            method: 'post',
            payload
        });
        yield put(templatePublishSuccess(data));
    } catch (err) {
        yield put(templatePublishFailed());
    }
}

function* onPostTemplateRequest({ payload: { data: template, id } }) {
    try {
        yield call(api.request, {
            url: `/branches/${id}/template/`,
            method: 'post',
            payload: template
        });
        yield put(getBranchTemplateRequest(id));
    } catch (err) {
        yield put(postBranchTemplateFailed());
    }
}

function* onEditTemplateRequest({ payload: { data: template, id, currentShiftId } }) {
    try {
        yield call(api.request, {
            url: `branches/${id}/template/${currentShiftId}`,
            method: 'post',
            payload: template
        });
        yield put(getBranchTemplateRequest(id));
    } catch (err) {
        yield put(postBranchTemplateFailed());
    }
}

function* onDeleteTemplateRequest({ payload: { id, currentShiftId } }) {
    try {
        yield call(api.request, {
            url: `branches/${id}/template/${currentShiftId}`,
            method: 'delete'
        });
        yield put(getBranchTemplateRequest(id));
    } catch (err) {
        yield put(deleteBranchTemplateFailed());
    }
}

function* onScheduleActionRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${payload.branchId}/template/action`,
            method: 'post',
            payload
        });
        yield put(scheduleActionSuccess(data));
    } catch (err) {
        yield put(scheduleActionFailed());
    }
}

// watchers
function* watchBranchTemplateRequest() {
    yield takeEvery(branchTemplateActionTypes.GET_BRANCH_TEMPLATE_REQUEST, onBranchTemplateRequest);
}

function* watchonBranchTemplatePublishPost() {
    yield takeEvery(branchTemplateActionTypes.TEMPLATE_PUBLISH_REQUEST, onBranchTemplatePublishPost);
}

function* watchPostTemplateRequest() {
    yield takeEvery(branchTemplateActionTypes.POST_TEMPLATE_REQUEST, onPostTemplateRequest);
}

function* watchEditTemplateRequest() {
    yield takeEvery(branchTemplateActionTypes.EDIT_TEMPLATE_REQUEST, onEditTemplateRequest);
}

function* watchDeleteTemplateRequest() {
    yield takeEvery(branchTemplateActionTypes.DELETE_TEMPLATE_REQUEST, onDeleteTemplateRequest);
}

function* watchScheduleActionRequestRequest() {
    yield takeEvery(branchTemplateActionTypes.SCHEDULE_ACTION_REQUEST, onScheduleActionRequest);
}

const branchTemplateSaga = [
    fork(watchBranchTemplateRequest),
    fork(watchPostTemplateRequest),
    fork(watchEditTemplateRequest),
    fork(watchDeleteTemplateRequest),
    fork(watchScheduleActionRequestRequest),
    fork(watchonBranchTemplatePublishPost)
];

export default branchTemplateSaga;
