import {
    GET_JOB_DETAILS_SUCCESS,
    GET_JOB_DETAILS_FAILED,
    GET_WORKED_LOCUMS_SUCCESS,
    GET_WORKED_LOCUMS_FAILED,
    ON_EDIT_JOB_REQUEST,
    ON_EDIT_JOB_SUCCESS,
    ON_EDIT_JOB_FAILED,
    ON_CANCEL_JOB_REQUEST,
    ON_CANCEL_JOB_SUCCESS,
    ON_CANCEL_JOB_FAILED,
    ON_LOCUM_ACTION_REQUEST,
    ON_LOCUM_ACTION_SUCCESS,
    ON_LOCUM_ACTION_FAILED,
    GET_JOB_CANCEL_REASONS_FAILED,
    GET_JOB_CANCEL_REASONS_SUCCESS
} from '../../../actions/admin-view/job-preview-page/action-types';

const initialState = {
    removingLoading: false,
    loading: false,
    jobData: null,
    workedLocums: null,
    cancellationReasons: null,
    jobApplyResponseData: {
        loading: false,
        error: false,
        success: false,
        action: ''
    }
};

export const handleJobDetails = (state, action) => {
    if (state === undefined) {
        return initialState.jobData;
    }
    switch (action.type) {
        case GET_JOB_DETAILS_SUCCESS:
            return (state = action.payload);
        case GET_JOB_DETAILS_FAILED:
            return state.jobData;
        default:
            return state.jobData;
    }
};

export const handleWorkedLocums = (state, action) => {
    if (state === undefined) {
        return initialState.workedLocums;
    }
    switch (action.type) {
        case GET_WORKED_LOCUMS_SUCCESS:
            return (state = action.payload);
        case GET_WORKED_LOCUMS_FAILED:
            return state.workedLocums;
        default:
            return state.workedLocums;
    }
};

export const handleJobActions = (state, action) => {
    if (state === undefined) {
        return initialState.jobApplyResponseData;
    }
    switch (action.type) {
        case ON_EDIT_JOB_REQUEST:
        case ON_LOCUM_ACTION_REQUEST:
            return {
                loading: true,
                success: false,
                error: false,
                action: action.payload.action
            };
        case ON_EDIT_JOB_SUCCESS:
        case ON_EDIT_JOB_FAILED:
        case ON_LOCUM_ACTION_SUCCESS:
            return {
                loading: false,
                success: true,
                error: false,
                action: action.payload && action.payload.action
            };
        case ON_LOCUM_ACTION_FAILED:
            return {
                loading: false,
                success: false,
                error: true,
                action: action.payload.action
            };
        default:
            return {
                loading: false,
                success: false,
                error: false,
                action: ''
            };
    }
};

export const handleJobCancellation = (state, action) => {
    if (state === undefined) {
        return initialState.removingLoading;
    }
    switch (action.type) {
        case ON_CANCEL_JOB_REQUEST:
            return (state = true);
        case ON_CANCEL_JOB_SUCCESS:
        case ON_CANCEL_JOB_FAILED:
            return (state = false);
        default:
            return state.removingLoading;
    }
};

export const handleJobCancellationReasons = (state, action) => {
    if (state === undefined) {
        return initialState.cancellationReasons;
    }
    switch (action.type) {
        case GET_JOB_CANCEL_REASONS_SUCCESS:
            return (state = action.payload);
        case GET_JOB_CANCEL_REASONS_FAILED:
            return state.cancellationReasons;
        default:
            return state.cancellationReasons;
    }
};
