import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from 'semantic-ui-react';
import { getCalculatedEmployeeShiftHoursClear, getCalculatedEmployeeShiftHoursRequest } from '../../../store/actions';
import ButtonModal from '../../UI/buttons/button-modal';
import Datepicker from '../../UI/datepicker';
import TextAreaComponent from '../../UI/inputs/text-area';
import SelectComponent from '../../UI/select';
import './style.scss';

const AbsenceRequestForm = ({ onCloseClick, getStaffList, onSubmit, calculatedActualStaffShiftTime }) => {
    const dispatch = useDispatch();
    const params = useParams();

    const { data } = useSelector((state) => state.branchStaffPage);

    const user = useSelector((state) => state.auth.user);
    const [formData, setFormData] = useState({
        absence_from: moment().startOf('day').toDate(),
        absence_to: moment().startOf('day').toDate(),
        absence_type: '',
        absence_note: '',
        employeeId: undefined,
        timeStart: null,
        timeFinish: null
    });
    // eslint-disable-next-line
    const [, setTotalDays] = useState(0);
    const [staffOptions, setStaffOptions] = useState([]);
    // eslint-disable-next-line
    const [typeOptions, setTypeOptions] = useState([
        {
            value: 'annual_leave',
            key: 'annual_leave',
            text: 'Annual Leave'
        },
        {
            value: 'sick_leave',
            key: 'sick_leave',
            text: 'Sick Leave'
        },
        {
            value: 'bank_holiday',
            key: 'bank_holiday',
            text: 'Bank Holiday'
        },
        {
            value: 'branch_swap',
            key: 'branch_swap',
            text: 'Branch Swap'
        },
        {
            value: 'maternity_leave',
            key: 'maternity_leave',
            text: 'Maternity Leave'
        },
        {
            value: 'paternity_leave',
            key: 'paternity_leave',
            text: 'Paternity Leave'
        }
    ]);

    useEffect(() => {
        const from = moment(formData.absence_from);
        const to = moment(formData.absence_to);
        let total = to.diff(from, 'days') + 1; // +1 include start
        isNaN(total) ? setTotalDays(0) : setTotalDays(total);
    }, [formData]);

    useEffect(() => {
        getStaffList();
    }, [getStaffList]);

    useEffect(() => {
        if (data) {
            const options = data.map((employee) => {
                return {
                    value: employee.id,
                    key: `${employee.id}-${employee.fullName}`,
                    // image: employee.image,
                    text: employee.fullName
                };
            });
            setStaffOptions(options);
        } else {
            setStaffOptions([]);
        }
    }, [data]);

    useEffect(() => {
        const { id } = params;
        if (!formData.employeeId || !formData.absence_from || !formData.absence_to) {
            dispatch(getCalculatedEmployeeShiftHoursClear());
            return;
        }
        dispatch(
            getCalculatedEmployeeShiftHoursRequest({
                branchId: id,
                employeeId: formData.employeeId,
                dateFrom: moment(formData.absence_from).format('YYYY-MM-DD'),
                dateTo: moment(formData.absence_to).format('YYYY-MM-DD')
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        getCalculatedEmployeeShiftHoursRequest,
        formData.absence_from,
        formData.absence_to,
        formData.employeeId
    ]);

    const onChangeHandler = (ev, data) => {
        const newData = {
            ...formData,
            [data.name]: data.value
        };
        const resetTo = moment(newData.absence_from).isAfter(moment(newData.absence_to));

        if (resetTo) {
            newData.absence_to = null;
        }

        setFormData(newData);
    };

    const formIsValid = () => {
        const { absence_type, absence_from, absence_to, employeeId } = formData;
        return !(absence_type && absence_from && absence_to && employeeId);
    };

    const convertHMToMS = () => {
        if (!calculatedActualStaffShiftTime) return 0;
        const { hours, minutes } = calculatedActualStaffShiftTime;
        const hoursToMs = (hours || 0) * 3600000;
        const minutesToMs = (minutes || 0) * 60000;
        return hoursToMs + minutesToMs;
    };

    const onSubmitHandler = (event) => {
        event.preventDefault();
        if (formIsValid) {
            const sendData = { ...formData };
            sendData.absence_from = moment(formData.absence_from).startOf('day').format('YYYY-MM-DD').toString();
            sendData.absence_to = moment(formData.absence_to).startOf('day').format('YYYY-MM-DD').toString();
            sendData.requestedById = user.id;
            sendData.calculatedTimeMS = convertHMToMS();
            onSubmit(sendData);
        }
    };

    return (
        <Form className="absence-request-form" onSubmit={onSubmitHandler}>
            <div className="content content-row">
                <div className="content-row__item" hidden={!formIsValid()}>
                    {/* <p className="locum-error">
            Please fill-out all mandatory fields to proceed
          </p> */}
                </div>
                <div className="content-row__item">
                    <div className="inner">
                        <Datepicker
                            name="absence_from"
                            value={formData.absence_from}
                            label="From*"
                            onChange={onChangeHandler}
                            format="DD/MM/YY"
                            disabled={false}
                            minDate={new Date(Date.now() - 864e5)}
                            maxDate={moment().add(1, 'year').toDate()}
                            fluid={false}
                        />
                        <div
                            className={classNames('calculated-hours', {
                                'd-none': !calculatedActualStaffShiftTime
                            })}
                        >
                            <div>
                                {calculatedActualStaffShiftTime && calculatedActualStaffShiftTime.hours
                                    ? calculatedActualStaffShiftTime.hours + 'h'
                                    : null}
                            </div>
                            <div>
                                {calculatedActualStaffShiftTime && calculatedActualStaffShiftTime.minutes
                                    ? calculatedActualStaffShiftTime.minutes + 'm'
                                    : null}
                            </div>
                        </div>

                        <Datepicker
                            name="absence_to"
                            value={formData.absence_to}
                            label="To*"
                            onChange={onChangeHandler}
                            format="DD/MM/YY"
                            minDate={formData.absence_from} // 864e5 == 86400000 == 24*60*60*1000
                            maxDate={moment().add(1, 'years').toDate()}
                            disabled={false}
                            fluid={false}
                        />
                    </div>
                    {/* <div className="content-row__item total-days" hidden={!totalDays}>
            {totalDays > 1 ? `${totalDays} Days` : `${totalDays} Day`}
          </div> */}
                    <div className="content-row__item content">
                        <p className="content-cancel-title">Type*</p>
                        <div className="">
                            <SelectComponent
                                name="absence_type"
                                icon="caret down"
                                floating
                                defaultValue={''}
                                onChange={onChangeHandler}
                                options={typeOptions}
                            />
                        </div>
                    </div>
                    <div className="content-row__item content">
                        <p className="content-cancel-title">Employee*</p>
                        <div className="">
                            <SelectComponent
                                name="employeeId"
                                icon="caret down"
                                floating
                                defaultValue={''}
                                onChange={onChangeHandler}
                                options={staffOptions}
                            />
                        </div>
                    </div>
                    <div className="content-row__item reasons">
                        <p className="content-cancel-title">Notes</p>
                        <div className="">
                            <TextAreaComponent
                                name="absence_note"
                                label=""
                                value={formData.absence_note}
                                error={null}
                                helperText={null}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons-row double">
                    <ButtonModal
                        value={'Cancel'}
                        colorType="red"
                        type="button"
                        // loading={removingLoading}
                        onClick={() => onCloseClick()}
                    />
                    <ButtonModal
                        type="submit"
                        value={'Approve Leave'}
                        colorType="blue"
                        onClick={onSubmitHandler}
                        disabled={formIsValid()}
                        loading={false}
                    />
                </div>
            </div>
        </Form>
    );
};

AbsenceRequestForm.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    getStaffList: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    calculatedActualStaffShiftTime: PropTypes.object
    // shiftsList: PropTypes.object.isRequired,
};

export default AbsenceRequestForm;
