import {
    GET_LOCUM_SETTINGS_FAILED,
    GET_LOCUM_SETTINGS_SUCCESS,
    GET_LOCUM_SETTINGS_REQUEST
} from '../../../actions/locum-view/settings/action-types';

export default (state = { loaded: false }, { type, payload }) => {
    switch (type) {
        case GET_LOCUM_SETTINGS_REQUEST:
            return (state = { ...payload, loading: true, loaded: false });
        case GET_LOCUM_SETTINGS_SUCCESS:
            return (state = { ...payload, loading: false, loaded: true });
        case GET_LOCUM_SETTINGS_FAILED:
            return { ...state, loading: false, loaded: true };
        default:
            return state;
    }
};
