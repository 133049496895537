import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    onJobLocumRateSuccess,
    onJobLocumRateFailed,
    onEditJobLocumRateSuccess,
    onEditJobLocumRateFailed
} from '../../../actions';
import * as jobLocumRatingActionTypes from '../../../actions/admin-view/job-locum-rating/action-types';
import api from '../../../../services/api';

function* onJobLocumRateRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/locums/rating`,
            method: 'post',
            payload
        });
        yield put(onJobLocumRateSuccess(data));
    } catch (err) {
        yield put(onJobLocumRateFailed(err));
    }
}

function* onEditJobLocumRateRequest({ payload: { ratingId, ...rest } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/locums/rating/${ratingId}`,
            method: 'post',
            payload: { ...rest }
        });
        yield put(onEditJobLocumRateSuccess(data));
    } catch (err) {
        yield put(onEditJobLocumRateFailed(err));
    }
}

function* watchJobLocumRateRequest() {
    yield takeEvery(jobLocumRatingActionTypes.POST_JOB_RATE_REQUEST, onJobLocumRateRequest);
}

function* watchEditJobLocumRateRequest() {
    yield takeEvery(jobLocumRatingActionTypes.EDIT_JOB_RATE_REQUEST, onEditJobLocumRateRequest);
}

const locumSaga = [fork(watchJobLocumRateRequest), fork(watchEditJobLocumRateRequest)];

export default locumSaga;
