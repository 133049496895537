import React, { useState, useCallback } from 'react';

import TextInput from '../../../../components/UI/inputs/text-input';
import ButtonComponent from '../../../../components/UI/buttons/button';
import ConfirmModal from '../../../../components/modals/confirm-modal';

import './style.scss';

export const VerificationPage = ({ userEmail, resendActivationLinkRequest }) => {
    const [confirmModal, toggleModal] = useState(false);

    const onSubmit = useCallback(
        () =>
            resendActivationLinkRequest({
                email: userEmail,
                toggleModal: () => toggleModal(true)
            }),
        [resendActivationLinkRequest, userEmail]
    );

    const confirmModalButton = {
        value: 'Ok',
        onClick: () => toggleModal(false),
        size: 'big',
        width: 'big'
    };

    return (
        <div className="verification-page">
            <div className="verification-page__inner">
                <h2 className="verification-page__title">Please verify your email</h2>
                <TextInput name="email" value={userEmail} label="Email" type="email" disabled />
                <div className="buttons-row">
                    <ButtonComponent value="Submit" onClick={onSubmit} size="big" width="big" />
                </div>
            </div>
            {confirmModal && (
                <ConfirmModal
                    open={confirmModal}
                    onClose={() => toggleModal(false)}
                    title="Success"
                    text="Please, check your email for a message with your verification link"
                    button={confirmModalButton}
                />
            )}
        </div>
    );
};
