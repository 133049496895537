import { LocumJobPreview } from './LocumJobPreview';
import { connect } from 'react-redux';
import { getLocumJobDetailsRequest, onLocumJobActionRequest } from '../../../../../store/actions';

export default connect(
    (state) => ({
        locumJobData: state.locumJobDetails
    }),
    {
        getLocumJobDetailsRequest,
        onLocumJobActionRequest
    }
)(LocumJobPreview);
