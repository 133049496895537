import React from 'react';

export default function SectionRuleList({ rows, onEdit, cols, type }) {
    if (!rows || !rows.length) return <div className="no-items">No rules</div>;
    return (
        <>
            <div className="section__cols">
                {cols.map((col) => {
                    return (
                        <div className="section__col_title" key={col}>
                            {col}
                        </div>
                    );
                })}
            </div>
            <div className="section__items_container">
                {rows.map((row) => {
                    return (
                        <div key={row[0]} className="section__row" onClick={onEdit({ id: row[0], type })}>
                            {row.slice(1).map((data) => {
                                return (
                                    <div key={type + row[0] + data} className="table__cell__content section__col">
                                        <span className="section__item_value">{data}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
