import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import CheckboxListContainer from '../../../containers/checkbox-list';
import { getDuration, getDurationFormat, getShiftDates, parseTime, weekDays } from '../../../services/datesHelper';
import AttachedStaffCard from '../../UI/attached-staff-card';
import ButtonModal from '../../UI/buttons/button-modal';
import CustomCheckbox from '../../UI/checkbox';
import Datepicker from '../../UI/datepicker';
import SelectComponent from '../../UI/select/SelectComponent';
import Timepicker from '../../UI/timepicker';
import './style.scss';

const TIME_FORMAT = 'HH:mm';

class AddEditBulkShiftScheduleForm extends Component {
    state = {
        startDate: this.props.currentDate ? new Date(this.props.currentDate) : null,
        endDate: null,
        withEndDate: false,
        weekDaysCheckboxes: [],
        selectedDays: [],
        showAttachStaff: true,
        attachedStaff: null,
        canEdit: true,
        isInitialStaffRecieved: false,
        savedAsDraft: false
    };

    componentDidMount() {
        this._isMounted = true;
        const { shiftEdit, operatingTime, currentDate } = this.props;
        const weekDaysCheckboxes = weekDays.map((item) => {
            const weekTime = operatingTime.find((ot) => ot.dayOfWeek === item);
            const startTime =
                (shiftEdit || weekTime) && moment(parseTime(shiftEdit ? shiftEdit.startTime : weekTime.startTime));
            const endTime =
                (shiftEdit || weekTime) && moment(parseTime(shiftEdit ? shiftEdit.endTime : weekTime.endTime));
            let lunchStartTime = shiftEdit ? shiftEdit.lunchStartTime : weekTime ? weekTime.lunchStartTime : null;
            let lunchEndTime = shiftEdit ? shiftEdit.lunchEndTime : weekTime ? weekTime.lunchEndTime : null;
            lunchStartTime = lunchStartTime && moment(parseTime(lunchStartTime));
            lunchEndTime = lunchStartTime && moment(parseTime(lunchEndTime));

            return {
                title: item,
                checked: currentDate && moment(currentDate).format('dddd').toLowerCase() === item,
                startTime,
                endTime,
                lunchStartTime,
                lunchEndTime,
                disabled: !weekTime,
                duration: startTime && endTime && getDuration(startTime, endTime)
            };
        });

        const { endDate } = this.state;

        this.setState(
            {
                weekDaysCheckboxes,
                isInitialStaffRecieved: shiftEdit && shiftEdit.staff ? true : false,
                attachedStaff: shiftEdit && shiftEdit.staff,
                startDate: shiftEdit ? new Date(shiftEdit.startDate) : new Date(currentDate),
                withEndDate: !!endDate,
                endDate: endDate ? new Date(endDate) : null,
                canEdit: !shiftEdit || shiftEdit.staff === null ? true : false
            },
            () => {
                if (shiftEdit && !shiftEdit.staff) this.onShowStaffList(true);
            }
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onStatTimeChange = (day, time, startTime, timeFinish, duration, type) => {
        time.set({ second: 0 });
        const { isInitialStaffRecieved } = this.state;
        let setDuration = timeFinish ? getDuration(time, timeFinish) : '';
        this.setState(
            {
                weekDaysCheckboxes: this.state.weekDaysCheckboxes.map((week) => {
                    if (week === day) {
                        return {
                            ...week,
                            [startTime]: time,
                            [duration]: setDuration
                        };
                    }
                    return week;
                })
            },
            () => {
                if (type !== 'lunch' && !isInitialStaffRecieved) this.onShowStaffList();
            }
        );
    };

    onFinishTimeChange = (day, time, timeFinish, startTime, duration, type) => {
        time.set({ second: 0 });
        const { isInitialStaffRecieved } = this.state;
        let setDuration = startTime ? getDuration(startTime, time) : '';
        this.setState(
            {
                weekDaysCheckboxes: this.state.weekDaysCheckboxes.map((week) => {
                    if (week === day) {
                        return {
                            ...week,
                            [timeFinish]: time,
                            [duration]: setDuration
                        };
                    }
                    return week;
                })
            },
            () => {
                if (type !== 'lunch' && !isInitialStaffRecieved) this.onShowStaffList();
            }
        );
    };

    onCheckboxChangeHandler = (e, { name }) => {
        const { weekDaysCheckboxes } = this.state;
        let selectedDays = [];

        weekDaysCheckboxes.forEach((el) => {
            if (name === el.title) {
                el.checked = !el.checked;
            }
            if (el.checked) selectedDays.push(el.title);
        });

        this.setState(
            (prevState) => ({ [name]: !prevState[name], selectedDays }),
            () => this.onShowStaffList()
        );
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { isInitialStaffRecieved, startDate } = this.state;
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        let weekDaysCheckboxes = this.state.weekDaysCheckboxes;

        const selectedWeeks = [];

        if (startDate && name === 'endDate' && value) {
            let dateFrom = moment(startDate).format('YYYY-MM-DD');
            while (moment(dateFrom).isSameOrBefore(moment(value).format('YYYY-MM-DD'))) {
                selectedWeeks.push(moment(dateFrom).format('dddd').toLowerCase());
                dateFrom = moment(dateFrom).add(1, 'day').format('YYYY-MM-DD');
            }
        } else if (name === 'startDate') {
            const dateFrom = moment(value).format('YYYY-MM-DD');
            selectedWeeks.push(moment(dateFrom).format('dddd').toLowerCase());
        }

        return this.setState(
            {
                [name]: value,
                weekDaysCheckboxes: selectedWeeks.length
                    ? weekDaysCheckboxes.map((week) => {
                          const checked = !!selectedWeeks.find((sw) => sw === week.title) && !week.disabled;
                          return {
                              ...week,
                              checked
                          };
                      })
                    : weekDaysCheckboxes
            },
            () => (['startDate', 'endDate'].includes(name) && !isInitialStaffRecieved ? this.onShowStaffList() : false)
        );
    };

    onDropdownChange = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () => this.onAttachStaff(value)
        );
    };

    onShowStaffList = (isEditMode = false) => {};

    onAttachStaff = (id) => {
        const { staffList } = this.props;
        let attachedItem;

        staffList.forEach((staff) => {
            if (staff.id === id) attachedItem = staff;
        });

        this.setState({
            attachedStaff: attachedItem,
            showAttachStaff: false
        });
    };

    onRemoveAttachedStaff = () => {
        this.setState({
            attachedStaff: null,
            canEdit: true,
            isInitialStaffRecieved: false
        });
        this.onShowStaffList();
    };

    onSubmitClickHandler = (e, submitMode, editMode) => {
        e.preventDefault();
        const { onSubmitClick, onSubmitDraftBulkClick, onSubmitConfirm, onSubmitBulkShift, shiftEdit } = this.props;
        const isEdit = shiftEdit ? true : false;
        let isDraft;

        if (!editMode) {
            isDraft = submitMode === 'draft' ? true : false;
        } else {
            isDraft = shiftEdit.isDraft;
        }

        const { startDate, endDate, attachedStaff, withEndDate, weekDaysCheckboxes } = this.state;

        const newShifts = [];
        let data;

        let editableStartDate = moment(startDate).format('YYYY-MM-DD');
        const newEndDate = endDate ? endDate : moment(startDate).add(6, 'day').format('YYYY-MM-DD');

        while (moment(editableStartDate).isSameOrBefore(newEndDate)) {
            const dayOfWeek = moment(editableStartDate).format('dddd').toLowerCase();
            const selectedWeek = weekDaysCheckboxes.find((week) => week.title === dayOfWeek);
            if (selectedWeek && selectedWeek.checked)
                newShifts.push({
                    startDate: editableStartDate,
                    endDate: editableStartDate,
                    lunchStartTime:
                        selectedWeek && selectedWeek.lunchStartTime && selectedWeek.lunchStartTime.format('HH:mm'),
                    lunchEndTime:
                        selectedWeek && selectedWeek.lunchEndTime && selectedWeek.lunchEndTime.format('HH:mm'),
                    startTime: selectedWeek.startTime.format('HH:mm'),
                    endTime: selectedWeek.endTime.format('HH:mm'),
                    staffId: attachedStaff ? attachedStaff.id : attachedStaff,
                    days: [dayOfWeek]
                });
            editableStartDate = moment(editableStartDate).add(1, 'day').format('YYYY-MM-DD');
        }

        if (!newShifts.length) {
            data = {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                staffId: attachedStaff ? attachedStaff.id : attachedStaff
            };

            if (withEndDate && endDate) {
                data.endDate = moment(endDate).format('YYYY-MM-DD');
            }

            if (isDraft) {
                data.isDraft = isDraft;
            }
        }

        if (isEdit) {
            const currentShiftDataUpdate = Object.assign({}, data);
            currentShiftDataUpdate.isDraft = isDraft;
            currentShiftDataUpdate.isInitialDraft = shiftEdit.isDraft;
            currentShiftDataUpdate.submitMode = submitMode;
            onSubmitConfirm(data, currentShiftDataUpdate);
        } else if (isDraft) {
            onSubmitDraftBulkClick(newShifts, isEdit);
        } else if (!isDraft && !isEdit && !newShifts.length) {
            onSubmitClick(data, isEdit);
        } else if (!isDraft && newShifts.length) {
            onSubmitBulkShift(newShifts, isEdit);
        }
    };

    onCancelClickHandler = (e) => {
        e.preventDefault();
        const { startDate, endDate } = this.state;
        const { onCancelClick } = this.props;
        const data = {
            startDate: moment(startDate).format('YYYY-MM-DD')
        };
        if (endDate) data.endDate = moment(endDate).format('YYYY-MM-DD');
        onCancelClick(data);
    };

    isFormValid = () => {
        const { endDate, withEndDate, weekDaysCheckboxes } = this.state;
        if (withEndDate && !endDate) return false;
        const daySelected = weekDaysCheckboxes.find((w) => w.checked);
        if (!daySelected) return false;
        return true;
    };

    render() {
        const { startDate, endDate, weekDaysCheckboxes, withEndDate, attachedStaff, canEdit, savedAsDraft } =
            this.state;
        const { loading, removingLoading, shiftEdit, staffList, draftSaved, currentDate } = this.props;

        const daySelected = !!weekDaysCheckboxes.find((week) => week.checked);

        const filteredStaffOptions = staffList
            ? staffList.map((option) => {
                  const { id, name, surname, image, role } = option;
                  return {
                      key: id,
                      value: id,
                      text: name,
                      content: (
                          <div className="option-inner">
                              <span className="option-inner__name">{`${name} ${surname}`}</span>
                              <span className="option-inner__role">{role.replace(/_/g, ' ')}</span>
                          </div>
                      ),
                      image: { avatar: !!image, src: image }
                  };
              })
            : [];

        const staffListDropdown = (
            <div className="content-row__item">
                <div className="inner column">
                    <SelectComponent
                        name="currentStaff"
                        icon="caret down"
                        floating
                        search
                        onChange={this.onDropdownChange}
                        options={filteredStaffOptions}
                        label="Find manager"
                    />
                </div>
            </div>
        );

        const displayAttachedStaff = attachedStaff ? (
            <>
                <p className="title">Employee</p>
                <AttachedStaffCard
                    id={attachedStaff.id}
                    name={attachedStaff.name}
                    surname={attachedStaff.surname}
                    image={attachedStaff.image}
                    role={attachedStaff.role}
                    onDangerButtonClick={this.onRemoveAttachedStaff}
                />
            </>
        ) : null;

        const removeShiftButton = shiftEdit ? (
            <ButtonModal
                value="Remove shift"
                colorType="red"
                loading={removingLoading}
                onClick={this.onCancelClickHandler}
            />
        ) : null;

        const saveAsDraftButton = (
            <ButtonModal
                value={savedAsDraft || draftSaved ? 'Saved as Draft' : 'Save as Draft'}
                colorType="blue"
                onClick={(e) => this.onSubmitClickHandler(e, 'draft', !!shiftEdit)}
                disabled={!this.isFormValid()}
            />
        );

        const editHelperText =
            canEdit || !shiftEdit ? null : (
                <div className="helper-conainer">
                    <p className="edit-helper-text">
                        <Icon name="warning sign" /> Please, remove your staff before editing
                    </p>
                </div>
            );

        const daysCheckboxes = weekDaysCheckboxes.map((item) => {
            return {
                id: item.title,
                disabled: item.disabled,
                label: item.title.substring(0, 3),
                name: item.title,
                checked: item.checked,
                onChange: this.onCheckboxChangeHandler
            };
        });

        return (
            <Form className="add-shift-schedule-form" onSubmit={this.onSubmitClickHandler} loading={loading}>
                <div className="content-row">
                    <div className="content-row__item">
                        <div className="inner datepickers">
                            <Datepicker
                                name="startDate"
                                label="Starting Date"
                                value={startDate}
                                onChange={this.onChangeHandler}
                                format="DD/MM/YY"
                                minDate={getShiftDates(currentDate, 'start')}
                                disabled={loading || !canEdit}
                                fluid={false}
                            />
                            <Datepicker
                                name="endDate"
                                label={
                                    <CustomCheckbox
                                        name="withEndDate"
                                        label={shiftEdit ? 'Affective To' : 'Finish Date'}
                                        checked={withEndDate}
                                        size="small"
                                        className="end-date-checkbox"
                                        onChange={this.onCheckboxChangeHandler}
                                        disabled={loading || !canEdit}
                                    />
                                }
                                value={endDate}
                                onChange={this.onChangeHandler}
                                format="DD/MM/YY"
                                fluid={false}
                                disablePast
                                minDate={getShiftDates(startDate, 'end', !!shiftEdit)}
                                disabled={loading || !withEndDate || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <CheckboxListContainer
                            items={daysCheckboxes}
                            layout="row"
                            boxesSize="small"
                            disabled={(withEndDate && !startDate && !endDate) || !canEdit}
                        />
                    </div>
                    {weekDaysCheckboxes
                        .filter((day) => day.checked)
                        .map((day, index) => {
                            const displayShiftDuration = getDurationFormat(day.duration);
                            return (
                                <div key={index} className="content-row__item">
                                    <p className="week-title">{day.title[0].toUpperCase() + day.title.slice(1)}</p>
                                    <p className="title">Operating Hours</p>
                                    <div className="inner timepickers">
                                        <Timepicker
                                            onChange={(time) =>
                                                this.onStatTimeChange(day, time, 'startTime', day.endTime, 'duration')
                                            }
                                            format={TIME_FORMAT}
                                            minuteStep={15}
                                            value={day.startTime || moment(new Date())}
                                            disabled={loading || !canEdit}
                                            size="large"
                                        />
                                        <p className="duration">{displayShiftDuration}</p>
                                        <Timepicker
                                            onChange={(time) =>
                                                this.onFinishTimeChange(day, time, 'endTime', day.startTime, 'duration')
                                            }
                                            format={TIME_FORMAT}
                                            minuteStep={15}
                                            value={day.endTime || moment(new Date())}
                                            disabled={loading || !canEdit}
                                            size="large"
                                        />
                                    </div>
                                </div>
                            );
                        })}

                    <div className={`content-row__item ${attachedStaff ? '' : 'hidden'}`}>
                        <div className="inner column">{displayAttachedStaff}</div>
                    </div>
                    {daySelected && staffListDropdown}
                    <div className="helper-holder">{editHelperText}</div>
                </div>
                <div className="buttons-row">
                    {removeShiftButton}
                    {saveAsDraftButton}
                    <ButtonModal
                        value={'Save & Publish'}
                        colorType="blue"
                        onClick={(e) => this.onSubmitClickHandler(e, 'shift', !!shiftEdit)}
                        disabled={!this.isFormValid()}
                    />
                </div>
            </Form>
        );
    }
}

AddEditBulkShiftScheduleForm.propTypes = {
    staffList: PropTypes.array,
    operatingTime: PropTypes.array,
    shiftEdit: PropTypes.object,
    loading: PropTypes.bool,
    getStaff: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSubmitClick: PropTypes.func.isRequired,
    onSubmitBulkShift: PropTypes.func.isRequired,
    onSubmitDraftBulkClick: PropTypes.func.isRequired,
    draftSaved: PropTypes.bool,
    currentDate: PropTypes.string,
    onSubmitConfirm: PropTypes.func
};

export default AddEditBulkShiftScheduleForm;
