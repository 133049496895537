import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonComponent from '../../../UI/buttons/button';
import UploadFileHandler from '../../../upload-remove-file-handler';
import CheckboxListContainer from '../../../../containers/checkbox-list';
import AnswerTabs from '../../../UI/answer-tabs';
import TextInput from '../../../UI/inputs/text-input';

import { getSize, getExtention } from '../../../../services/helpers';
import { nameError } from '../../../../services/validate';

import notificationSettings from '../../../../services/notificationSettings';
import { store } from 'react-notifications-component';
import { Form } from 'semantic-ui-react';

import './style.scss';

export function SkillsForm({
    setStoreDocument,
    removeStoreDocument,
    onRemoveSkillsFile,
    accreditations,
    loading,
    removingLoading,
    onGoBack,
    onSuccess,
    pharmacySystemsOptions,
    previouslySelectedSystems,
    skills: {
        cv,
        uploadedFiles,
        independentPrescriber,
        methadonePatients,
        MBSBlistersTrays,
        careHomes,
        sellingMedication,
        sellingMedicationText
    },
    setSkillsData,
    setSkillsCheckbox,
    setSkillsStoreDocument,
    removeSkillsDocument,
    isEditProfile,
    ...props
}) {
    const [fileError, setFileError] = useState(null);
    const [inputError, setInputError] = useState(null);
    const [pharmacySystemsState, setPharmacySystems] = useState([]);
    const cvRef = useRef();

    useEffect(() => {
        let getPharmacySystems = [];
        if (pharmacySystemsOptions) {
            getPharmacySystems = pharmacySystemsOptions.map((item) => {
                return {
                    ...item,
                    checked: false
                };
            });
        }
        if (pharmacySystemsOptions && previouslySelectedSystems && previouslySelectedSystems.length) {
            getPharmacySystems = pharmacySystemsOptions.flatMap((accreditation) => [
                {
                    ...accreditation,
                    checked: !!previouslySelectedSystems.find((item) => item.id === accreditation.id)
                }
            ]);

            const getSelectedIds = previouslySelectedSystems.map((el) => el.id);
            setSkillsCheckbox(getSelectedIds);
        }

        setPharmacySystems(getPharmacySystems);
    }, [pharmacySystemsOptions, previouslySelectedSystems, setSkillsCheckbox]);

    const triggerInputFile = (name) => cvRef.current.click();

    const onFileUpload = useCallback(
        (e) => {
            const name = e.target.name;
            const value = e.target.value;
            const file = e.target.files[0];
            let errorMessage = {};
            let error = null;

            // validate uploaded file
            errorMessage.extention = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.pdf']);
            errorMessage.size = getSize(file);

            // display error message
            Object.values(errorMessage).some((err) => (!!err ? (error = err) : false));

            if (error) {
                store.addNotification({
                    ...notificationSettings.settings,
                    title: 'Warning',
                    message: error,
                    type: 'warning'
                });
            }

            setFileError(error);

            setSkillsStoreDocument({ name, value, file });
        },
        [setSkillsStoreDocument]
    );

    const onFileRemoveHandler = useCallback(
        (name) => {
            if (cv && !uploadedFiles.cv) {
                onRemoveSkillsFile({
                    profileType: 'profileSkills',
                    fileName: name
                });
            }
            removeSkillsDocument(name);
            setFileError(null);
        },
        [cv, onRemoveSkillsFile, removeSkillsDocument, uploadedFiles.cv]
    );

    const onCheckboxChange = (e, { name }) => {
        let checkBoxID = name.split('#ID=')[1];

        const selectedOptions = pharmacySystemsState.map((el) => {
            if (+checkBoxID === el.id) {
                el.checked = !el.checked;
            }
            return el;
        });

        const getSelectedIds = selectedOptions.filter(({ checked, id }) => (checked ? id : false)).map((el) => el.id);

        setPharmacySystems(selectedOptions);
        setSkillsCheckbox(getSelectedIds);
    };

    const onChangeHandler = ({ name, value }) => {
        if (name === 'sellingMedicationText' && value.length > 0) setInputError(nameError(value));
        setSkillsData({ name, value });
    };

    const checkboxes = pharmacySystemsState
        ? pharmacySystemsState.map((item) => {
              return {
                  id: item.id,
                  label: item.title,
                  name: `${item.title}#ID=${item.id}`,
                  checked: item.checked,
                  onChange: onCheckboxChange
              };
          })
        : [];

    let heading,
        subHeading = null;

    if (isEditProfile) {
        heading = <h3 className="staff-info-page__title">Personal Information</h3>;
        subHeading = <h4 className="row-title">Expierence</h4>;
    }

    return (
        <Form className={classnames('skills-form', { isEditProfile })} autoComplete="off" loading={loading}>
            <div className="skills-form__row">
                <div className="col">
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Upload CV (Optional)</label>
                        <UploadFileHandler
                            inputName="cv"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={cv}
                            uploadedFile={uploadedFiles.cv}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(cv) => (cvRef.current = cv)}
                            triggerInput={() => triggerInputFile('cv')}
                            loading={removingLoading}
                        />
                    </div>
                    <CheckboxListContainer
                        label="Pharmacy systems used:"
                        items={checkboxes}
                        layout="singleColumn"
                        boxesSize="small"
                    />
                </div>
                <div className="col">
                    <AnswerTabs
                        label="Are you independent prescriber?"
                        name="independentPrescriber"
                        initialState={independentPrescriber}
                        onClick={(data) => onChangeHandler(data)}
                    />
                    <AnswerTabs
                        label="Have you deal with methadone patients?"
                        name="methadonePatients"
                        initialState={methadonePatients}
                        onClick={(data) => onChangeHandler(data)}
                    />
                    <AnswerTabs
                        label="Are you comfortable with MDS/Blisters/Trays?"
                        name="MBSBlistersTrays"
                        initialState={MBSBlistersTrays}
                        onClick={(data) => onChangeHandler(data)}
                    />
                </div>
                <div className="col">
                    <AnswerTabs
                        label="Have you worked in a pharmacy that deals with care homes?"
                        name="careHomes"
                        initialState={careHomes}
                        onClick={(data) => onChangeHandler(data)}
                    />
                    <AnswerTabs
                        label="Do you have any obligation selling any particular medication?"
                        name="sellingMedication"
                        initialState={sellingMedication}
                        onClick={(data) => onChangeHandler(data)}
                    />
                    <TextInput
                        name="sellingMedicationText"
                        label="If Yes, please enter details below"
                        type="text"
                        placeholder="Obligations"
                        fluid={false}
                        value={sellingMedicationText || ''}
                        error={!!inputError}
                        helperText={inputError}
                        onChange={({ target }) => onChangeHandler(target)}
                    />
                </div>
            </div>
            {subHeading}
            <div className="skills-form__row button-row">
                {heading}
                <div className="buttons-holder">
                    <ButtonComponent
                        value={isEditProfile ? 'Cancel' : 'Back'}
                        type="transparent"
                        onClick={onGoBack}
                        size="medium"
                        disabled={loading}
                    />
                    <ButtonComponent
                        value="Save"
                        onClick={onSuccess}
                        size="medium"
                        disabled={!!inputError || !!fileError || loading}
                    />
                </div>
            </div>
        </Form>
    );
}

SkillsForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onRemoveSkillsFile: PropTypes.func.isRequired,
    pharmacySystems: PropTypes.array,
    isEditProfile: PropTypes.bool
};
