import {
    GET_POSTED_JOBS_SUCCESS,
    GET_POSTED_JOBS_FAILED,
    GET_CSV_JOBS_DOWNLOAD_SUCCESS,
    GET_CSV_JOBS_DOWNLOAD_FAILED,
    GET_MIN_AND_MAX_JOBS_SUCCESS,
    GET_MIN_AND_MAX_JOBS_FAILED,
    ON_JOBS_FILTER_CHANGE,
    ON_JOBS_FILTER_FALSE,
    CLEAR_JOBS_FILTERS
} from '../../../actions/admin-view/posted-jobs-page/action-types';

const initialState = {
    postedJobsPage: {
        filters: {
            id: '',
            startDate: null,
            branchTitle: '',
            hourlyRate: '',
            locumName: '',
            status: '',
            applicationsCount: ''
        },
        isFiltering: false,
        redirectPageTo: null,
        loading: false,
        data: {},
        totalData: 0,
        itemsPerPage: 9
    },
    locumShiftDownloadModal: {
        file: null
    },
    minAndMaxJobs: {
        minJob: null,
        maxJob: null
    }
};

export const postedJobsData = (state = initialState, action) => {
    if (state === undefined) {
        return initialState.postedJobsPage;
    }
    switch (action.type) {
        case GET_POSTED_JOBS_SUCCESS:
            return {
                ...state.postedJobsPage,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case GET_POSTED_JOBS_FAILED:
            return {
                ...state.postedJobsPage,
                loading: false
            };
        case ON_JOBS_FILTER_CHANGE:
            return {
                ...state.postedJobsPage,
                filters: {
                    ...action.payload
                },
                isFiltering: true
            };
        case ON_JOBS_FILTER_FALSE:
            return {
                ...state.postedJobsPage,
                isFiltering: false,
                redirectPageTo: 1
            };
        case CLEAR_JOBS_FILTERS:
            return {
                ...state.postedJobsPage,
                filters: {
                    id: '',
                    startDate: null,
                    dateRange: null,
                    branchTitle: '',
                    hourlyRate: '',
                    locum: '',
                    status: '',
                    applicationsCount: ''
                },
                isFiltering: false
            };
        default:
            return state.postedJobsPage;
    }
};

export const locumShiftsFile = (state = initialState.locumShiftDownloadModal, action) => {
    switch (action.type) {
        case GET_CSV_JOBS_DOWNLOAD_SUCCESS:
            return {
                file: action.payload
            };
        case GET_CSV_JOBS_DOWNLOAD_FAILED:
            return state.locumShiftDownloadModal;
        default:
            return initialState.locumShiftDownloadModal;
    }
};

export const minAndMaxJobs = (state = initialState.minAndMaxJobs, action) => {
    switch (action.type) {
        case GET_MIN_AND_MAX_JOBS_SUCCESS:
            return {
                ...action.payload.data
            };
        case GET_MIN_AND_MAX_JOBS_FAILED:
            return state.minAndMaxJobs;
        default:
            return initialState.minAndMaxJobs;
    }
};
