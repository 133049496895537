import { connect } from 'react-redux';
import BranchTemplate from './BranchTemplate';

import {
    getBranchStaffRequest,
    getBranchTemplateRequest,
    postBranchTemplateRequest,
    editBranchTemplateRequest,
    deleteBranchTemplateRequest,
    scheduleActionRequest,
    getAllStaffRequest,
    getMasterDateRequest,
    templatePublishRequest
} from '../../../../../../store/actions';

export default connect(
    (state) => ({
        branchTemplatePage: state.branchTemplatePage,
        operatingTime: state.branchInfoPage.branchInfo.data.operatingTime,
        staffList: state.branchStaffPage.data,
        masterDate: state.pharmacySettingsPage.masterDate
    }),
    {
        getBranchStaffRequest,
        getBranchTemplateRequest,
        postBranchTemplateRequest,
        editBranchTemplateRequest,
        deleteBranchTemplateRequest,
        scheduleActionRequest,
        getAllStaffRequest,
        getMasterDateRequest,
        templatePublishRequest
    }
)(BranchTemplate);
