import { GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILED } from '../../../actions/shared/profile-page/action-types';

const initialState = {
    data: null,
    loading: false
};

const staffUserProfile = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        case GET_USER_PROFILE_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default staffUserProfile;
