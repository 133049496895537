import React from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../buttons/button';
import StarsRating from '../../stars-rating';

import locumIcon from '../../../assets/icons/profile-icon.svg';
import './style.scss';

const AppliedLocumCard = ({
    name,
    surname,
    id,
    role,
    image,
    email,
    phone,
    gphcNumber,
    status,
    loading,
    rating,
    inactive,
    isExternal,
    onLocumClick,
    onDenyButtonClick,
    onApproveButtonClick,
    onCancelButtonClick
}) => {
    const onDenyButton = (e) => {
        e.preventDefault();
        onDenyButtonClick(id);
    };

    const onApproveButton = (e) => {
        e.preventDefault();
        onApproveButtonClick(id);
    };

    const onCancelButton = (e) => {
        e.preventDefault();
        onCancelButtonClick(id);
    };

    let controls;

    switch (status) {
        case 'applied':
            controls = (
                <div className="applied-locum-card__buttons">
                    <ButtonComponent
                        type="bordered-green"
                        size="medium"
                        value="Approve"
                        disabled={loading || inactive}
                        onClick={onApproveButton}
                    />
                    <ButtonComponent
                        type="bordered-danger"
                        size="medium"
                        value="Deny"
                        disabled={loading || inactive}
                        onClick={onDenyButton}
                    />
                </div>
            );
            break;
        case 'rejected':
            controls = <p className="locum-status cancelled">Invite Cancelled</p>;
            break;
        case 'declined':
            controls = <p className="locum-status denied">Denied</p>;
            break;
        case 'accepted':
            controls = <p className="locum-status accepted">Accepted</p>;
            break;
        case 'invited':
            controls = (
                <ButtonComponent
                    type="bordered-danger"
                    size="medium"
                    value="Cancel Invite"
                    disabled={loading}
                    onClick={onCancelButton || inactive}
                />
            );
            break;
        default:
            break;
    }

    return (
        <div className={`applied-locum-card ${inactive ? 'inactive' : ''}`}>
            <div className="applied-locum-card__container">
                <div className="applied-locum-card__col col-left" onClick={onLocumClick}>
                    <div className="image-holder">
                        <img
                            src={image || locumIcon}
                            alt={image === undefined || image === null ? '' : 'staff'}
                            className={image === undefined || image === null ? 'error' : ''}
                        />
                    </div>
                    <div className="text-wrapper">
                        <div className="name-holder">
                            <p className="name">{name && surname ? `${name} ${surname.charAt(0)}.` : gphcNumber}</p>
                            <p className="role">{isExternal ? `External ${role}` : role}</p>
                        </div>
                        <p className="email">{email}</p>
                        {isExternal && <p className="email">{phone}</p>}
                    </div>
                </div>
                <div className="applied-locum-card__col col-right">
                    {!!rating && <StarsRating value={rating || 0} />} {controls}
                </div>
            </div>
        </div>
    );
};

AppliedLocumCard.propTypes = {
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    image: PropTypes.string,
    phone: PropTypes.string,
    gphcNumber: PropTypes.number,
    status: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    inactive: PropTypes.bool,
    isExternal: PropTypes.bool,
    onDenyButtonClick: PropTypes.func,
    onApproveButtonClick: PropTypes.func,
    onCancelButtonClick: PropTypes.func,
    onLocumClick: PropTypes.func
};

export default AppliedLocumCard;
