import React, { useEffect, useCallback } from 'react';
import { generatePath } from 'react-router';
import BranchInformation from './information';
import BranchSchedule from './schedule';
import BranchStaff from './staff';
import BranchTemplate from './template';
import JobPreview from './job-preview';
import ScheduleCalendarDayView from './schedule-calendar-day-view';
import { useModal } from '../../../../../services/hooks';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../../routerConfig';

import Sidebar from '../../../../sidebar';
import ContentContainer from '../../../../../components/content-container';
import BranchCard from '../../../../../components/branch-card';
import BackButton from '../../../../../components/UI/buttons/back-button';

import { useDispatch, useSelector } from 'react-redux';
import {
    getBranchInfoRequest,
    getBranchGeneralDataRequest,
    onArchiveBranchRequest
} from '../../../../../store/actions';

import './style.scss';

const BranchDetails = ({
    match: {
        params: { id }
    },
    history
}) => {
    const [archiveBranchModal, togglerchiveBranchModal] = useModal(false);

    const { sidebarVisibility, backbuttonVisibility, containerFluid } = useSelector(
        (state) => state.branchDetailsPageView
    );
    const { branchInfo, generalInfo } = useSelector((state) => state.branchInfoPage);
    const dispatch = useDispatch();

    const onArchiveBranch = useCallback(() => {
        dispatch(
            onArchiveBranchRequest({
                branchId: id,
                toggleModal: () => togglerchiveBranchModal()
            })
        );
    }, [dispatch, id, togglerchiveBranchModal]);

    useEffect(() => {
        dispatch(getBranchInfoRequest(id));
        dispatch(getBranchGeneralDataRequest());
    }, [dispatch, id]);

    const sidebarLinks = [
        {
            text: 'Information',
            to: generatePath(RouterConfig.branchAdminBranchInformation, { id }),
            disabled: false,
            hidden: false
        },
        {
            text: 'Schedule',
            to: generatePath(RouterConfig.branchAdminBranchSchedule, { id }),
            disabled: !!branchInfo.isArchived,
            hidden: false
        },
        {
            text: 'Template',
            to: generatePath(RouterConfig.branchAdminBranchTemplate, { id }),
            disabled: !!branchInfo.isArchived,
            hidden: false
        },
        {
            text: 'Staff',
            to: generatePath(RouterConfig.branchAdminBranchStaff, { id }),
            disabled: !!branchInfo.isArchived,
            hidden: false
        }
    ];

    return (
        <div className="content-view-page details-page">
            <div className="main-wrapper">
                <div className="back-button-holder">
                    <BackButton visible={backbuttonVisibility} onClick={() => history.goBack()} />
                </div>
                <div className="content-holder">
                    <Sidebar links={sidebarLinks} visible={sidebarVisibility}>
                        <BranchCard
                            logo={branchInfo.data.logo}
                            title={branchInfo.data.title}
                            region={branchInfo.data.regionName}
                            number={branchInfo.data.number}
                        />
                    </Sidebar>
                    <ContentContainer smallPaddings fluid={containerFluid}>
                        <Switch>
                            <Route
                                path={RouterConfig.branchAdminBranchInformation}
                                render={() => (
                                    <BranchInformation
                                        data={branchInfo.data}
                                        generalData={generalInfo.data}
                                        branchId={id}
                                        getLoading={branchInfo.loading}
                                        archiveBranchModal={archiveBranchModal}
                                        onOpenModal={() => togglerchiveBranchModal()}
                                        onCloseModal={() => togglerchiveBranchModal()}
                                        onArchiveBranch={onArchiveBranch}
                                    />
                                )}
                            />
                            <Route path={RouterConfig.branchAdminBranchSchedule} component={BranchSchedule} />
                            <Route path={RouterConfig.branchAdminBranchTemplate} component={BranchTemplate} />
                            <Route path={RouterConfig.branchAdminBranchStaff} component={BranchStaff} />
                            <Route path={RouterConfig.branchAdminJobPreview} component={JobPreview} />
                            <Route
                                path={RouterConfig.branchAdminBranchScheduleDayView}
                                component={ScheduleCalendarDayView}
                            />
                            <Redirect
                                from={RouterConfig.branchAdminBranchRoot}
                                to={RouterConfig.branchAdminBranchInformation}
                            />
                        </Switch>
                    </ContentContainer>
                </div>
            </div>
        </div>
    );
};

export default BranchDetails;
