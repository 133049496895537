import { PHARMACY_SIGN_UP_REQUEST, LOCUM_SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILED } from './action-types';

export const pharmacySignupRequest = (payload) => ({
    type: PHARMACY_SIGN_UP_REQUEST,
    payload
});

export const locumSignupRequest = (payload) => ({
    type: LOCUM_SIGN_UP_REQUEST,
    payload
});

export const signupSuccess = () => ({
    type: SIGN_UP_SUCCESS
});

export const signupFailed = () => ({
    type: SIGN_UP_FAILED
});
