import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { momentLocalizer } from 'react-big-calendar';
import PopupComponent from '../../../../components/popup';

import ScheduleCalendar from '../../../../components/schedule-calendar';
import Toolbar from '../../../../components/schedule-calendar/Toolbar';
import EventCell from '../../../../components/schedule-calendar/EventCell';
import ScheduleDayPreviewPopover from '../../../../components/schedule-calendar-popover';

import moment from 'moment';
import Switcher from '../../../../components/UI/switcher';
import { useLocation, useHistory } from 'react-router';
import { usePage } from '../../../../services/hooks';
import PageNav from '../../../../components/page-nav';
import TableComponent from '../../../../components/table';
import { LocumJobPreview } from '../../locum-view/job-offers/job-preview/LocumJobPreview';

moment.updateLocale('ko', {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment);
const date = new Date();
let calendarFormats = {
    weekdayFormat: 'dddd'
};

const initialForm = {
    startDate: '',
    endDate: '',
    tab: 'upcoming',
    page: 0
};

export function ManagerSchedule({
    schedule: { dateRange, events },
    getStaffUserEventsRequest,
    onStaffUserEventsDateRangeChange,
    shiftsByFilter: { data, itemsPerPage, totalData },
    onGetScheduleShiftsByFilterRequest,
    getLocumJobDetailsRequest,
    onLocumJobActionRequest,
    locumJobData
}) {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const page = usePage();

    const [viewMode, setViewMode] = useState('calendar'); // calendar | table
    const [jobId, setJobId] = useState(null);
    const [shifts, setShifts] = useState([]);
    const [filterForm, setFilterForm] = useState(initialForm);

    useEffect(() => {
        if (!dateRange.startDateRange && !dateRange.endDateRange)
            onStaffUserEventsDateRangeChange({
                startDateRange: moment(dates.firstVisibleDay(date, localizer)),
                endDateRange: moment(dates.lastVisibleDay(date, localizer))
            });
        getStaffUserEventsRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.endDateRange, dateRange.startDateRange, onStaffUserEventsDateRangeChange]);

    useEffect(() => {
        if (!data) return;
        setShifts(data);
    }, [data]);

    useEffect(() => {
        const newFilter = {
            ...filterForm
        };

        const searchParams = new URLSearchParams(search);
        const localViewMode = searchParams.get('viewMode') || 'calendar';
        setViewMode(localViewMode || 'calendar');

        if (localViewMode === 'calendar') return;

        newFilter.tab = searchParams.get('tab') || 'upcoming';
        newFilter.page = searchParams.get('page') || 0;
        if (searchParams.has('startDate')) {
            newFilter.startDate = new Date(searchParams.get('startDate'));
        } else {
            newFilter.startDate = '';
        }
        if (searchParams.has('endDate')) {
            newFilter.endDate = new Date(searchParams.get('endDate'));
        } else {
            newFilter.endDate = '';
        }

        setFilterForm(newFilter);
        onGetScheduleShiftsByFilterRequest({
            ...newFilter,
            tab: localViewMode === 'table' ? newFilter.tab : null,
            perPage: itemsPerPage
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (!dateRange.startDateRange && !dateRange.endDateRange)
            onStaffUserEventsDateRangeChange({
                startDateRange: moment(dates.firstVisibleDay(date, localizer)),
                endDateRange: moment(dates.lastVisibleDay(date, localizer))
            });
        getStaffUserEventsRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.endDateRange, dateRange.startDateRange, onStaffUserEventsDateRangeChange]);

    const onNavigate = useCallback(
        (date, view, action) => {
            onStaffUserEventsDateRangeChange({
                startDateRange: moment(dates.firstVisibleDay(date, localizer)),
                endDateRange: moment(dates.lastVisibleDay(date, localizer))
            });

            getStaffUserEventsRequest();
        },
        [getStaffUserEventsRequest, onStaffUserEventsDateRangeChange]
    );

    const handleCellClick = (jobId) => {
        setJobId(jobId);
    };

    const tabs = [
        {
            title: 'Upcoming',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=upcoming&viewMode=table`);
            },
            isActive: filterForm.tab === 'upcoming'
        },
        {
            title: 'Completed',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=completed&viewMode=table`);
            },
            isActive: filterForm.tab === 'completed'
        }
    ];

    const tableCols = [
        {
            name: 'pharmacyName',
            title: 'PHARMACY',
            render: (item) => (
                <div onClick={() => handleCellClick(item.id)} style={{ display: 'flex', alignItems: 'center' }}>
                    {item.logo ? (
                        <img alt={'item-logo'} width="25px" height="25px" src={item.logo} />
                    ) : (
                        <div className="table-default-logo" />
                    )}
                    <span style={{ marginLeft: '10px' }}>{item.pharmacyName}</span>
                </div>
            ),
            type: 'disabled'
        },
        {
            name: 'city',
            title: 'CITY',
            render: (item) => (
                <div onClick={() => handleCellClick(item.id)}>
                    <div>{item.city}</div>
                </div>
            ),
            type: 'disabled'
        },
        {
            name: 'startDate',
            title: 'DATE',
            render: (item) => (
                <div onClick={() => handleCellClick(item.id)}>{moment(item.startDate).format('DD/MM/YYYY')}</div>
            ),
            type: 'disabled'
        },
        {
            name: 'shiftTime',
            title: 'TIME',
            render: (item) => {
                return (
                    <div onClick={() => handleCellClick(item.id)}>
                        {item.startTime} - {item.endTime}
                    </div>
                );
            },
            type: 'disabled'
        }
    ];

    const renderShifts = () => {
        const getEvents = events.map((event) => {
            if (event.isEmpty)
                return {
                    ...event,
                    status: 'closed-branch',
                    title: (
                        <div className="event-inner">
                            <span className="event-inner__title staff">No shifts</span>
                        </div>
                    )
                };
            const { shifts } = event;

            let dayProps = {
                minTime: [],
                maxTime: [],
                shifts: '',
                branchTitle: ''
            };

            shifts.forEach((shift) => {
                dayProps.minTime.push(moment(shift.startTime, 'HH:mm'));
                dayProps.maxTime.push(moment(shift.endTime, 'HH:mm'));
                dayProps.branchTitle = shift.branchTitle;
            });

            const getMinTime = moment.min(dayProps.minTime).format('HH:mm');
            const getMaxTime = moment.max(dayProps.maxTime).format('HH:mm');

            if (shifts.length === 0) {
                dayProps.shifts = null;
            } else if (shifts.length === 1) {
                dayProps.shifts = dayProps.branchTitle;
            } else if (shifts.length > 1) {
                dayProps.shifts = `${shifts.length} Shifts`;
            }

            return {
                ...event,
                startDate: moment(event.startDate),
                endDate: moment(event.endDate),
                allDay: true,
                status: 'shift',
                time: `${getMinTime} - ${getMaxTime}`,
                title: (
                    <>
                        <PopupComponent
                            triggerComponent={<div className="event-inner__overlay" />}
                            nonePaddings
                            color="white"
                            mouseEnterDelay={300}
                            hoverable={false}
                            wide
                            hidePointingArrow
                        >
                            <ScheduleDayPreviewPopover
                                shifts={event.shifts}
                                startDate={moment(event.startDate)}
                                time={`${getMinTime} - ${getMaxTime}`}
                            />
                        </PopupComponent>
                        <div className="event-inner">
                            <span className="event-inner__title">{`${getMinTime} - ${getMaxTime}`}</span>
                            <span className="event-inner__staff">{dayProps.shifts}</span>
                        </div>
                    </>
                )
            };
        });
        return getEvents;
    };

    let calendarComponents = {
        toolbar: (props) => <Toolbar {...props} switcher={false} />,
        event: (props) => <EventCell {...props} />
    };

    if (jobId) {
        return (
            <LocumJobPreview
                getLocumJobDetailsRequest={getLocumJobDetailsRequest}
                onLocumJobActionRequest={onLocumJobActionRequest}
                jobId={jobId}
                locumJobData={locumJobData}
                onCloseJobPreview={() => {
                    setJobId(null);
                    history.replace({ pathname, search });
                }}
            />
        );
    }

    if (!events) {
        return null;
    } else
        return (
            <div className="calendar-page">
                <div className="calendar-page__switcher">
                    <Switcher
                        onLeftClick={() => {
                            history.replace({ pathname, search: 'page=1&viewMode=calendar' });
                        }}
                        leftValue="calendar"
                        onRightClick={() => {
                            history.replace({
                                pathname,
                                search: 'page=1&tab=upcoming&viewMode=table'
                            });
                        }}
                        rightValue="table"
                        activeView={viewMode}
                    />
                </div>
                {viewMode === 'calendar' ? (
                    <ScheduleCalendar
                        localizer={localizer}
                        formats={calendarFormats}
                        components={calendarComponents}
                        events={renderShifts()}
                        selectable
                        onNavigate={onNavigate}
                    />
                ) : (
                    <div>
                        <PageNav title="Locum Shifts" tabs={tabs} noMargin />
                        <TableComponent
                            cols={tableCols}
                            data={shifts}
                            totalItems={totalData}
                            itemsPerPage={itemsPerPage}
                            page={Number(page)}
                            preserveQueryParams={true}
                        />
                    </div>
                )}
            </div>
        );
}

ManagerSchedule.propTypes = {
    schedule: PropTypes.object
};
