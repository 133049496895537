import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';

import './style.scss';

const TextAreaComponent = ({
    name,
    label,
    value,
    loading,
    onChange,
    error,
    fluid = true,
    autoComplete = 'nope',
    helperText,
    disabled,
    placeholder
}) => {
    return (
        <div className={`input-group ${fluid ? 'fluid' : ''}`}>
            <label>{label}</label>
            <TextArea
                className={`textarea ${disabled ? ' disabled' : ''}`}
                name={name}
                onChange={onChange}
                // error={error}
                autoComplete={autoComplete}
                value={value}
                loading={loading}
                disabled={disabled}
                placeholder={placeholder}
            />
            <p className="helper-text">{helperText}</p>
        </div>
    );
};

TextAreaComponent.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    autoComplete: PropTypes.string,
    fluid: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
};

export default TextAreaComponent;
