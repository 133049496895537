import React, { useEffect } from 'react';
import PageNav from '../../../../../components/page-nav';
import TableComponent from '../../../../../components/table';

import { usePage } from '../../../../../services/hooks';
import { connect } from 'react-redux';

import { getUsersListRequest, onUsersListFilterChange, clearUsersListFilters } from '../../../../../store/actions';
import { usePrevious } from '../../../../../services/hooks';
import { onFilter } from '../../../../../services/fetchDataHelper';

import moment from 'moment';

import './style.scss';

function UsersListPage({
    usersPage: { data, totalData, itemsPerPage, filters, isFiltering, redirectPageTo },
    getUsersListRequest,
    onUsersListFilterChange,
    clearUsersListFilters,
    location: { pathname },
    history
}) {
    const page = usePage();
    const prevPage = usePrevious(page);
    const prevRoute = usePrevious(pathname);

    useEffect(() => {
        const unlisten = history.listen(({ pathname }) => {
            if (pathname !== prevRoute) clearUsersListFilters();
        });
        return () => {
            unlisten();
        };
    }, [history, clearUsersListFilters, prevRoute]);

    useEffect(() => {
        if (!page || !!redirectPageTo) return;

        if (page && page !== prevPage && prevPage === undefined) {
            getUsersListRequest({ page });
        } else if (page && page !== prevPage && !isFiltering) {
            getUsersListRequest({ page });
        } else if (page && page !== prevPage && isFiltering) {
            getUsersListRequest({ page }, filters);
        }
    }, [filters, getUsersListRequest, isFiltering, page, prevPage, redirectPageTo]);

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'id',
                title: 'user id',
                value: filters.id,
                render: (item) => item.id,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            },
            {
                name: 'name',
                title: 'name',
                value: filters.name,
                render: (item) => item.name,
                //type: "disabled",
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            },
            {
                name: 'surname',
                title: 'surname',
                value: filters.surname,
                render: (item) => item.surname,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            },
            {
                name: 'role',
                title: 'role',
                value: filters.role,
                render: (item) => (item.role ? item.role.replace(/_/g, ' ') : ''),
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            },
            {
                name: 'createdAt',
                title: 'creation date',
                value: filters.createdAt ? new Date(filters.createdAt) : null,
                render: (item) => moment(item.createdAt).format('DD/MM/YY'),
                type: 'date',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            },
            {
                name: 'pharmacy',
                title: 'pharmacy',
                value: filters.pharmacy,
                render: (item) => item.pharmacy,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            },
            {
                name: 'status',
                title: 'status',
                value: filters.status,
                render: (item) => item.status,
                type: 'search',
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onUsersListFilterChange, page)
            }
        ];

        return (
            <div className="admin-users-page">
                <div className="main-container">
                    <PageNav title="Users" />
                    <TableComponent
                        cols={tableCols}
                        data={data.rows}
                        totalItems={totalData}
                        itemsPerPage={itemsPerPage}
                        page={Number(page)}
                        openInfo="/users/details"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    usersPage: state.adminUsersPage
});

const mapDispatchToProps = {
    getUsersListRequest,
    onUsersListFilterChange,
    clearUsersListFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListPage);
