import {
    POST_PAYMENT_METHOD_REQUEST,
    POST_PAYMENT_METHOD_SUCCESS,
    POST_PAYMENT_METHOD_FAILED,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILED,
    GET_SUBSCRIPTION_PLAN_SUCCESS,
    GET_SUBSCRIPTION_PLAN_FAILED,
    SET_SELECTED_SUBSCRIPTION_PLAN,
    GET_SUBSCRIPTION_PLANS_LIST_SUCCESS,
    GET_SUBSCRIPTION_PLANS_LIST_FAILED,
    GET_ACTIVE_BRANCHES_LIST_REQUEST,
    GET_ACTIVE_BRANCHES_LIST_SUCCESS,
    GET_ACTIVE_BRANCHES_LIST_FAILED,
    GET_SELECTED_BRANCHES,
    POST_PLAN_SUBSCRIPTION_REQUEST,
    POST_PLAN_SUBSCRIPTION_SUCCESS,
    POST_PLAN_SUBSCRIPTION_FAILED,
    CANCEL_PLAN_SUBSCRIPTION_REQUEST,
    CANCEL_PLAN_SUBSCRIPTION_SUCCESS,
    CANCEL_PLAN_SUBSCRIPTION_FAILED,
    GET_TRANSACTIONS_LIST_SUCCESS,
    GET_TRANSACTIONS_LIST_FAILED,
    CLEAR_TRANSACTIONS_LIST,
    CLEAR_SUBSCRIPTION_DATA,
    SUBSCRIPTION_STANDARD_PLAN_SUCCESS,
    SUBSCRIPTION_STANDARD_PLAN_FAILED,
    GET_PROFILE_SUBSCRIPTION_SUCCESS,
    GET_PROFILE_SUBSCRIPTION_FAILED,
    GET_PROFILE_SUBSCRIPTION_REQUEST,
    UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST,
    UPDATE_BRANCH_SUBSCRIPTIONS_SUCCESS,
    UPDATE_BRANCH_SUBSCRIPTIONS_FAILED,
    GET_ADDONS_SUCCESS,
    GET_ADDONS_FAILED,
    POST_DEACTIVATE_SUBSCRIPTION_FAILED,
    POST_DEACTIVATE_SUBSCRIPTION_SUCCESS,
    POST_DEACTIVATE_SUBSCRIPTION_REQUEST
} from '../../../actions/admin-view/billing-page/action-types';

const initialState = {
    paymentMethod: {
        data: null,
        loading: false
    },
    addons: {
        data: [],
        loading: false
    },
    transactions: {
        data: null,
        firstItemId: null,
        lastItemId: null,
        page: 1,
        perPage: 10,
        hasMore: false
    },
    subscription: {
        data: null,
        loading: false
    },
    subscriptionPlan: {
        plansList: null,
        currentPlan: null,
        selectedPlan: {
            planId: null,
            branchesAllowed: null,
            price: null
        },
        redirectUrl: null,
        loading: false
    },
    branches: {
        data: null,
        totalData: 0,
        itemsPerPage: 10,
        pages: 0,
        loading: false,
        activeBranches: null,
        selectedBranches: null
    }
};

export const handleTransactions = (state, action) => {
    if (state === undefined) {
        return initialState.transactions;
    }
    switch (action.type) {
        case GET_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...state.transactions,
                data: action.payload.rows,
                page: action.payload.page,
                hasMore: action.payload.hasMore
            };
        case GET_TRANSACTIONS_LIST_FAILED:
            return state.transactions;
        case CLEAR_TRANSACTIONS_LIST:
            return initialState.transactions;
        default:
            return state.transactions;
    }
};

export const handleAddons = (state, action) => {
    if (state === undefined) {
        return initialState.addons;
    }
    switch (action.type) {
        case GET_ADDONS_SUCCESS:
            return {
                ...state.addons,
                data: action.payload
            };
        case GET_ADDONS_FAILED:
            return state.addons;
        default:
            return state.addons;
    }
};

export const handleActiveBranches = (state, action) => {
    if (state === undefined) {
        return initialState.branches;
    }

    switch (action.type) {
        case GET_ACTIVE_BRANCHES_LIST_REQUEST:
            return {
                ...state.branches,
                loading: true
            };
        case GET_ACTIVE_BRANCHES_LIST_SUCCESS:
            return {
                ...state.branches,
                data: action.payload,
                totalData: action.payload.count,
                activeBranches: !state.branches.activeBranches
                    ? action.payload.activeCount
                    : state.branches.activeBranches,
                pages: action.payload.pages,
                loading: false
            };
        case GET_ACTIVE_BRANCHES_LIST_FAILED:
            return {
                ...state.branches,
                loading: false
            };
        case GET_SELECTED_BRANCHES:
            return {
                ...state.branches,
                selectedBranches: action.payload,
                activeBranches: action.payload.length
            };
        default:
            return state.branches;
    }
};

export const handlePaymentMethod = (state, action) => {
    if (state === undefined) {
        return initialState.paymentMethod;
    }

    switch (action.type) {
        case POST_PAYMENT_METHOD_REQUEST:
            return {
                ...state.paymentMethod,
                loading: true
            };
        case POST_DEACTIVATE_SUBSCRIPTION_REQUEST:
            return {
                ...state.paymentMethod,
                loading: true
            };
        case POST_DEACTIVATE_SUBSCRIPTION_SUCCESS:
        case POST_DEACTIVATE_SUBSCRIPTION_FAILED:
            return {
                ...state.paymentMethod,
                loading: false
            };
        case UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST:
            return {
                ...state.paymentMethod,
                loading: true
            };
        case UPDATE_BRANCH_SUBSCRIPTIONS_SUCCESS:
        case UPDATE_BRANCH_SUBSCRIPTIONS_FAILED:
            return {
                ...state.paymentMethod,
                loading: false
            };

        case POST_PAYMENT_METHOD_SUCCESS:
        case POST_PAYMENT_METHOD_FAILED:
            return {
                ...state.paymentMethod,
                loading: false
            };
        case GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state.paymentMethod,
                data: action.payload
            };
        case GET_PAYMENT_METHOD_FAILED:
            return state.paymentMethod;
        default:
            return state.paymentMethod;
    }
};

export const handleSubscriptionPlan = (state, action) => {
    if (state === undefined) {
        return initialState.subscriptionPlan;
    }
    const { payload } = action;
    switch (action.type) {
        case GET_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state.subscriptionPlan,
                currentPlan: payload,
                selectedPlan: payload
                    ? {
                          planId: payload.planId,
                          branchesAllowed: payload.branchesAllowed,
                          price: payload.price
                      }
                    : {
                          planId: null,
                          branchesAllowed: null,
                          price: null
                      }
            };
        case SUBSCRIPTION_STANDARD_PLAN_SUCCESS:
            return {
                ...state.subscriptionPlan,
                currentPlan: payload
            };
        case SET_SELECTED_SUBSCRIPTION_PLAN:
            return {
                ...state.subscriptionPlan,
                selectedPlan: action.payload
            };
        case GET_SUBSCRIPTION_PLANS_LIST_SUCCESS:
            return {
                ...state.subscriptionPlan,
                plansList: action.payload
            };
        case GET_SUBSCRIPTION_PLAN_FAILED:
        case GET_SUBSCRIPTION_PLANS_LIST_FAILED:
            return state.subscriptionPlan;
        case POST_PLAN_SUBSCRIPTION_REQUEST:
        case CANCEL_PLAN_SUBSCRIPTION_REQUEST:
            return {
                ...state.subscriptionPlan,
                loading: true
            };
        case POST_PLAN_SUBSCRIPTION_SUCCESS:
            return {
                ...state.subscriptionPlan,
                redirectUrl: action.payload ? action.payload.redirect_url : null,
                loading: false
            };
        case POST_PLAN_SUBSCRIPTION_FAILED:
        case CANCEL_PLAN_SUBSCRIPTION_SUCCESS:
        case CANCEL_PLAN_SUBSCRIPTION_FAILED:
            return {
                ...state.subscriptionPlan,
                loading: false
            };
        case SUBSCRIPTION_STANDARD_PLAN_FAILED:
            return {
                ...state.subscriptionPlan,
                loading: false
            };
        case GET_PROFILE_SUBSCRIPTION_REQUEST:
            return {
                ...state.subscriptionPlan,
                subscription: {
                    data: payload,
                    loading: true
                }
            };
        case GET_PROFILE_SUBSCRIPTION_SUCCESS:
            return {
                ...state.subscriptionPlan,
                subscription: {
                    data: payload,
                    loading: false
                }
            };
        case GET_PROFILE_SUBSCRIPTION_FAILED:
            return {
                ...state.subscriptionPlan,
                subscription: {
                    data: payload,
                    loading: false
                }
            };
        case CLEAR_SUBSCRIPTION_DATA:
            return initialState.subscriptionPlan;
        default:
            return state.subscriptionPlan;
    }
};
