import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getAdminTransactionsListSuccess,
    getAdminTransactionsListFailed,
    getAdminBranchInvoiceListSuccess,
    getAdminBranchInvoiceListFailed,
    postAdminZohoReportEmailFailed,
    postAdminZohoReportEmailSuccess
} from '../../../actions';
import * as adminBillingActionTypes from '../../../actions/super-admin-view/billing-page/action-types';
import api from '../../../../services/api';

function* onGetAdminTransactionsListRequest({ payload: { page, perPage } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/admin/billing/transactions/list',
            method: 'POST',
            payload: {
                pagination: {
                    page,
                    perPage
                }
            }
        });
        yield put(getAdminTransactionsListSuccess(data));
    } catch (err) {
        yield put(getAdminTransactionsListFailed());
    }
}

function* onGetAdminBranchInvoiceListRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: '/admin/invoices/list',
            method: 'POST',
            payload
        });
        yield put(getAdminBranchInvoiceListSuccess(data));
    } catch (err) {
        yield put(getAdminBranchInvoiceListFailed());
    }
}

function* onPostUpdateZohoReportEmailRequest({ zohoReportEmail }) {
    try {
        yield call(api.request, {
            url: '/admin/invoices/update-zoho-report-email',
            method: 'POST',
            payload: {
                zohoReportEmail
            }
        });
        yield put(postAdminZohoReportEmailSuccess());
    } catch (err) {
        yield put(postAdminZohoReportEmailFailed());
    }
}

function* watchAdminTransactionsListRequest() {
    yield takeEvery(adminBillingActionTypes.GET_ADMIN_TRANSACTIONS_LIST_REQUEST, onGetAdminTransactionsListRequest);
}

function* watchGetAdminBranchInvoiceListRequest() {
    yield takeEvery(adminBillingActionTypes.GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST, onGetAdminBranchInvoiceListRequest);
}

function* watchOnPostUpdateZohoReportEmailRequest() {
    yield takeEvery(adminBillingActionTypes.POST_UPDATE_ZOHO_REPORT_EMAIL_REQUEST, onPostUpdateZohoReportEmailRequest);
}

const adminBillingPageSaga = [
    fork(watchAdminTransactionsListRequest),
    fork(watchGetAdminBranchInvoiceListRequest),
    fork(watchOnPostUpdateZohoReportEmailRequest)
];

export default adminBillingPageSaga;
