import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { userRoles, userStatusesList, roleAbilityTypes, roleFeatureTypes } from '../userConfig';
import query from 'query-string';
import { AbilityContext } from './ability';
import { useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';

/**
 * @param {object} params
 * @param {string} params.defaultTab set default tab search query ex: ?tab=all
 * @returns {number} page
 */
export const usePage = ({ defaultTab } = {}) => {
    const { search, pathname } = useLocation();
    const history = useHistory();
    const { page, tab } = query.parse(search);

    useEffect(() => {
        let searchParams = new URLSearchParams();
        if (!page) searchParams.set('page', 1);
        if (!tab && defaultTab) searchParams.set('tab', defaultTab);

        if (searchParams.toString()) history.replace(`${pathname}?${searchParams.toString()}`);
    }, [defaultTab, history, page, pathname, tab]);

    return Number(page);
};

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useModal = (initialValue) => {
    const [showModal, setShowModal] = useState(!!initialValue);

    return [showModal, (value) => setShowModal(typeof value === 'boolean' ? value : !showModal)];
};

export const useSearch = (onSearch) => {
    const { search, pathname } = useLocation();
    const history = useHistory();
    const { page } = query.parse(search);

    const [searchValue, setSearchValue] = useState('');

    const onChange = ({ target: { value } }) => setSearchValue(value);

    useEffect(() => {
        if (typeof onSearch !== 'function') return;
        history.replace(`${pathname}?page=1`);
        if (!!searchValue.length) onSearch({ input: searchValue, page });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return [searchValue, onChange];
};

export const useStaffInfoAccessCheck = (userStatus, userRole) => {
    const ability = useContext(AbilityContext);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (
            ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE) &&
            userStatus === userStatusesList.PENDING
        ) {
            setResult('Pending user');
        } else if (
            ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE) &&
            userStatus === userStatusesList.ACTIVE
        ) {
            setResult('Active user');
        } else if (
            ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE) &&
            userStatus === userStatusesList.DEACTIVATED
        ) {
            setResult('Deavtivated user');
        } else if (
            ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_PERSONAL_INFO_EDIT) &&
            userRole !== userRoles.LOCUM
        ) {
            setResult('Staff personal info');
        } else if (ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.LOCUM_PERSONAL_INFO_EDIT)) {
            setResult('Locum personal info');
        } else if (userStatus === userStatusesList.REJECTED) {
            setResult('Rejected user');
        } else setResult(null);
        // eslint-disable-next-line
    }, [ability, userStatus]);

    return result;
};

export const useTablePaginationReset = (isFilterChanged, path) => {
    const { search } = useLocation();
    const history = useHistory();
    const { page } = query.parse(search);

    useEffect(() => {
        if (isFilterChanged && +page > 1) {
            history.replace({}, null, `${path}?page=1`);
        }
    }, [history, isFilterChanged, page, path]);
};

export const useContractStaffId = () => {
    const { staffId } = useParams();
    const user = useSelector((state) => state.auth.user);
    return staffId || user.id;
};

export const useSearchParams = ({ initSearchParams } = {}) => {
    const { search, pathname, state } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);

    useEffect(() => {
        // TODO: fix or delete
        if (!initSearchParams || isEmpty(initSearchParams)) return;

        const currentParams = Object.fromEntries(params);
        if (isEqual(currentParams, initSearchParams) || state?.setSearchParamInit) return;

        Object.keys(initSearchParams).forEach((key) => {
            !params.get(key) && params.set(key, initSearchParams[key]);
        });

        if (params.toString()) history.replace(`${pathname}?${params.toString()}`, { setSearchParamInit: true });
    }, [history, initSearchParams, params, pathname, state]);

    /**
     * @param {object} params new search params
     */
    const setSearchParam = (params) => {
        const newParams = new URLSearchParams(params);
        if (newParams.toString()) history.replace(`${pathname}?${newParams.toString()}`);
    };

    return [Object.fromEntries(params), setSearchParam];
};
