import { LocumCheckInPage } from './LocumCheckInPage';
import { connect } from 'react-redux';
import { onValidateGphcRequest } from '../../../../store/actions';

export default connect(
    (state) => ({
        isAuth: state.auth.isAuth,
        loading: state.locumCheckInPage.loading,
        user: state.auth.user
    }),
    {
        onValidateGphcRequest
    }
)(LocumCheckInPage);
