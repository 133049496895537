import { call, put, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    getLocumBankByFilterFailed,
    getLocumBankByFilterSuccess,
    addLocumBankSuccess,
    addLocumBankFailed
} from '../../../actions';
import * as actionTypes from '../../../actions/admin-view/locum-bank-page/action-types';
import api from '../../../../services/api';

function* onLocumBankByFilterRequest({ pagination, filters }) {
    try {
        const { data } = yield call(api.request, {
            url: '/locum-banks/list',
            method: 'post',
            payload: {
                pagination,
                filters
            }
        });
        yield put(
            getLocumBankByFilterSuccess({
                data,
                totalData: data.count
            })
        );
    } catch (err) {
        yield put(getLocumBankByFilterFailed());
    }
}

function* onLocumBankAddRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: '/locum-banks/add',
            method: 'post',
            payload
        });

        yield put(addLocumBankSuccess({ data }));
    } catch (err) {
        yield put(addLocumBankFailed());
    }
}

// watchers
function* watchAddLocumBankRequest() {
    yield takeEvery(actionTypes.ADD_LOCUM_BANK_REQUEST, onLocumBankAddRequest);
}

function* watchLocumBankByFilterChange() {
    yield takeLatest(actionTypes.GET_LOCUM_BANK_BY_FILTER_REQUEST, onLocumBankByFilterRequest);
}

const locumBankPageSaga = [fork(watchAddLocumBankRequest), fork(watchLocumBankByFilterChange)];

export default locumBankPageSaga;
