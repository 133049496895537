import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const BranchItemCard = ({ id, title, region }) => {
    return (
        <div className="branch-item-card">
            <div className="branch-item-card__container">
                <div className="col col-left">
                    <div className="text-wrapper">
                        <p className="region">
                            <span>#{id} </span>
                            {region && (
                                <>
                                    <span className="connection-dot"></span>
                                    <span> {region}</span>
                                </>
                            )}
                        </p>
                        <p className="title">{title}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

BranchItemCard.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    region: PropTypes.string
};

export default BranchItemCard;
