import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { saveFileSuccess, saveFileFailed } from '../../actions';
import * as saveFileActionTypes from '../../actions/save-file/action-types';
import api from '../../../services/api';
import { saveFile } from '../../../services/helpers';

function* onSaveFileRequest({ payload: { fileUrl, fileName } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/files/download',
            method: 'POST',
            payload: {
                fileUrl
            }
        });
        yield put(saveFileSuccess());
        saveFile(data, fileName);
    } catch (err) {
        yield put(saveFileFailed());
    }
}

function* watchSaveFileRequest() {
    yield takeEvery(saveFileActionTypes.SAVE_FILE_REQUEST, onSaveFileRequest);
}

const saveFileSaga = [fork(watchSaveFileRequest)];

export default saveFileSaga;
