import { Information } from './Information';
import { connect } from 'react-redux';
import {
    onEditBranchRequest,
    onEditBranchScheduleRequest,
    removeBranchLogoRequest,
    onUpdateBranchRateRequest,
    clearCreatedBranchInfo
} from '../../../../../../store/actions/';

export default connect(
    (state) => ({
        createdBranchId: state.createBranch.createdBranchId
    }),
    {
        onEditBranchRequest,
        onEditBranchScheduleRequest,
        removeBranchLogoRequest,
        clearCreatedBranchInfo,
        onUpdateBranchRateRequest
    }
)(Information);
