import {
    GET_USERS_LIST_SUCCESS,
    GET_USERS_LIST_FAILED,
    ON_USERS_LIST_FILTER_CHANGE,
    ON_USERS_LIST_FILTER_FALSE,
    CLEAR_USERS_LIST_FILTERS
} from '../../../actions/super-admin-view/users-page/action-types';

const initialState = {
    filters: {
        name: '',
        surname: '',
        role: '',
        createdAt: '',
        status: '',
        pharmacy: ''
    },
    isFiltering: false,
    redirectPageTo: null,
    loading: false,
    data: null,
    totalData: 0,
    itemsPerPage: 10
};

const adminUsersPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case GET_USERS_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        case ON_USERS_LIST_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                },
                isFiltering: true
            };
        case ON_USERS_LIST_FILTER_FALSE:
            return {
                ...state,
                isFiltering: false,
                redirectPageTo: 1
            };
        case CLEAR_USERS_LIST_FILTERS:
            return {
                ...state,
                filters: {
                    name: '',
                    surname: '',
                    role: '',
                    createdAt: '',
                    status: '',
                    pharmacy: ''
                },
                isFiltering: false
            };
        default:
            return state;
    }
};

export default adminUsersPage;
