import React, { useState } from 'react';
import SignupForm from '../../../components/forms/signup-form';
import CreateBranchContainer from '../../create-branch';
import logo from '../../../assets/img/header-login-logo.svg';
import ConfirmModal from '../../../components/modals/confirm-modal';
import { RouterConfig } from '../../../routerConfig';
import { userRoles } from './../../../userConfig';
import { generatePath } from 'react-router';

import './style.scss';

export function SignupPage({
    history,
    pharmacySignupRequest,
    locumSignupRequest,
    registerProcessOff,
    loading,
    ...props
}) {
    const [authStep, setAuthStep] = useState('signup');
    const [confirmModal, toggleModal] = useState(false);

    const onSignupSuccess = (recievedData, userRole) => {
        if (userRole === userRoles.BRANCH_ADMIN) {
            pharmacySignupRequest({
                recievedData,
                callback: () => setAuthStep('createBranch')
            });
        } else {
            locumSignupRequest({ recievedData, callback: () => toggleModal(true) });
        }
    };

    const onConfirmLocumSignup = () => {
        toggleModal(false);
        history.push(generatePath(RouterConfig.login, { token: null }));
    };

    const onCloseRegistration = () => {
        registerProcessOff();
        history.push(RouterConfig.root);
    };

    const onProceedToLogin = () => history.push(generatePath(RouterConfig.login, { token: null }));

    // button for confirm modal
    const confirmSignupButton = {
        value: 'Confirm',
        onClick: () => onConfirmLocumSignup(),
        size: 'big',
        width: 'big'
    };

    let authForm =
        authStep === 'signup' ? (
            <SignupForm onSuccess={onSignupSuccess} onBackToLogin={onProceedToLogin} loading={loading} />
        ) : (
            <CreateBranchContainer onCreateBranchSuccess={onCloseRegistration} onCancel={onCloseRegistration} />
        );

    return (
        <div className="signup-page">
            <img className="logo" src={logo} alt="logo" />
            <div className="inner">{authForm}</div>
            {confirmModal && (
                <ConfirmModal
                    open={confirmModal}
                    onClose={() => onConfirmLocumSignup()}
                    title="Success"
                    text="Please, check your email for a message with your signup link"
                    button={confirmSignupButton}
                />
            )}
        </div>
    );
}
