import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonComponent from '../../UI/buttons/button';
import TextInput from '../../UI/inputs/text-input';

import { passError } from '../../../services/validate';

import './style.scss';

class ResetPasswordForm extends Component {
    state = {
        password: '',
        repeatPassword: '',
        errors: {
            password: null,
            repeatPassword: null
        },
        validate: {
            password: false,
            repeatPassword: false
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'password':
                error = passError(value);
                break;
            case 'repeatPassword':
                error = value !== this.state.password ? 'Passwords must be the same.' : null;
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onChangeHandler = ({ target: { name, value } }) => {
        const { errors, validate } = this.state;
        return this.setState(
            {
                [name]: value
            },
            () => {
                if (errors[name] || validate[name]) this.validate(name);
                if (name === 'repeatPassword' && (validate.repeatPassword || errors.repeatPassword))
                    return this.validate('repeatPassword');
            }
        );
    };

    onSubmitHandler = async (e) => {
        e.preventDefault();
        await Promise.all([this.validate('password'), this.validate('repeatPassword')]);

        const { errors, password } = this.state;
        const { onSuccess } = this.props;
        if (errors.password || errors.repeatPassword) return;

        onSuccess(password);
    };

    render() {
        const { password, repeatPassword, errors } = this.state;
        const { loading, onGoBack } = this.props;

        return (
            <form className="reset-pass-form" onSubmit={this.onSubmitHandler}>
                <h2 className="reset-pass-form__title">Reset password</h2>
                <TextInput
                    name="password"
                    value={password}
                    label="Password"
                    type="password"
                    onChange={this.onChangeHandler}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <TextInput
                    name="repeatPassword"
                    value={repeatPassword}
                    label="Repeat password"
                    type="password"
                    onChange={this.onChangeHandler}
                    error={!!errors.repeatPassword}
                    helperText={errors.repeatPassword}
                />
                <ButtonComponent
                    value="Reset"
                    onClick={this.onSubmitHandler}
                    loading={loading}
                    disabled={!!errors.password || !!errors.repeatPassword}
                />
                <ButtonComponent value="Cancel" type="bordered" onClick={() => onGoBack()} />
            </form>
        );
    }
}

ResetPasswordForm.propTypes = {
    onGoBack: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default ResetPasswordForm;
