export const GET_INVITED_STAFF_INFO_REQUEST = 'GET_INVITED_STAFF_INFO_REQUEST';
export const GET_INVITED_STAFF_INFO_SUCCESS = 'GET_INVITED_STAFF_INFO_SUCCESS';
export const GET_INVITED_STAFF_INFO_FAILED = 'GET_INVITED_STAFF_INFO_FAILED';

export const ON_CONFIRM_IDENTITY_REQUEST = 'ON_CONFIRM_IDENTITY_REQUEST';
export const ON_CONFIRM_IDENTITY_SUCCESS = 'ON_CONFIRM_IDENTITY_SUCCESS';
export const ON_CONFIRM_IDENTITY_FAILED = 'ON_CONFIRM_IDENTITY_FAILED';

export const ON_INVITED_STAFF_LOGIN_REQUEST = 'ON_INVITED_STAFF_LOGIN_REQUEST';
export const ON_INVITED_STAFF_LOGIN_SUCCESS = 'ON_INVITED_STAFF_LOGIN_SUCCESS';
export const ON_INVITED_STAFF_LOGIN_FAILED = 'ON_INVITED_STAFF_LOGIN_FAILED';

export const ON_CANCEL_GPHC_VERIFICATION_REQUEST = 'ON_CANCEL_GPHC_VERIFICATION_REQUEST';
export const ON_CANCEL_GPHC_VERIFICATION_SUCCESS = 'ON_CANCEL_GPHC_VERIFICATION_SUCCESS';
export const ON_CANCEL_GPHC_VERIFICATION_FAILED = 'ON_CANCEL_GPHC_VERIFICATION_FAILED';
