import React, { useState, useEffect } from 'react';
import { generatePath } from 'react-router';
import { useParams, useHistory } from 'react-router-dom';
import { RouterConfig } from '../../../../../../routerConfig';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { momentLocalizer } from 'react-big-calendar';

import ScheduleCalendar from './../../../../../../components/schedule-calendar';
import Toolbar from '../../../../../../components/schedule-calendar/Toolbar';
import EventCell from '../../../../../../components/schedule-calendar/EventCell';
import PopupComponent from '../../../../../../components/popup';
import ScheduleDayPreviewPopover from '../../../../../../components/schedule-calendar-popover';

import { useDispatch, useSelector } from 'react-redux';
import { getStaffShiftsForAdminRequest } from '../../../../../../store/actions';

import moment from 'moment';

moment.updateLocale('ko', {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment);
const date = new Date();
let calendarFormats = {
    weekdayFormat: 'dddd'
};

function StaffSchedulePage(props) {
    const { staffId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.staffSchedulePage);
    const [dateRange, setDateRange] = useState({
        startDateRange: moment(dates.firstVisibleDay(date, localizer)).format('YYYY-MM-DD'),
        endDateRange: moment(dates.lastVisibleDay(date, localizer)).format('YYYY-MM-DD')
    });

    useEffect(() => {
        const { startDateRange, endDateRange } = dateRange;
        dispatch(
            getStaffShiftsForAdminRequest({
                staffId,
                startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
                endDateRange: moment(endDateRange).format('YYYY-MM-DD')
            })
        );
    }, [dateRange, dispatch, staffId]);

    const onNavigate = (date, view, action) => {
        setDateRange({
            startDateRange: moment(dates.firstVisibleDay(date, localizer)),
            endDateRange: moment(dates.lastVisibleDay(date, localizer))
        });

        getStaffShiftsForAdminRequest({
            staffId,
            startDateRange: moment(dateRange.startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(dateRange.endDateRange).format('YYYY-MM-DD')
        });
    };

    let calendarComponents = {
        toolbar: (props) => <Toolbar {...props} switcher={false} />,
        event: (props) => <EventCell {...props} />
    };

    if (!data) {
        return null;
    } else {
        const renderShifts = () => {
            const events = data.map((event) => {
                if (event.isEmpty)
                    return {
                        ...event,
                        status: 'closed-branch',
                        title: (
                            <div className="event-inner">
                                <span className="event-inner__title staff">No shifts</span>
                            </div>
                        )
                    };
                const { shifts } = event;

                let dayProps = {
                    minTime: [],
                    maxTime: [],
                    shifts: '',
                    branchTitle: ''
                };

                shifts.forEach((shift) => {
                    dayProps.minTime.push(moment(shift.startTime, 'HH:mm'));
                    dayProps.maxTime.push(moment(shift.endTime, 'HH:mm'));
                    dayProps.branchTitle = shift.branchTitle;
                });

                const getMinTime = moment.min(dayProps.minTime).format('HH:mm');
                const getMaxTime = moment.max(dayProps.maxTime).format('HH:mm');

                if (shifts.length === 0) {
                    dayProps.shifts = null;
                } else if (shifts.length === 1) {
                    dayProps.shifts = dayProps.branchTitle;
                } else if (shifts.length > 1) {
                    dayProps.shifts = `${shifts.length} Shifts`;
                }

                return {
                    ...event,
                    startDate: moment(event.startDate),
                    endDate: moment(event.endDate),
                    allDay: true,
                    status: 'shift',
                    time: `${getMinTime} - ${getMaxTime}`,
                    title: (
                        <>
                            <PopupComponent
                                triggerComponent={<div className="event-inner__overlay" />}
                                nonePaddings
                                color="white"
                                mouseEnterDelay={300}
                                hoverable={false}
                                wide
                                hidePointingArrow
                            >
                                <ScheduleDayPreviewPopover
                                    shifts={event.shifts}
                                    startDate={moment(event.startDate)}
                                    time={`${getMinTime} - ${getMaxTime}`}
                                    isStaffShift
                                    openInfo={(id) =>
                                        history.push(
                                            generatePath(RouterConfig.branchAdminBranchScheduleDayView, {
                                                id,
                                                date: moment(event.startDate).format('YYYY-MM-DD')
                                            })
                                        )
                                    }
                                />
                            </PopupComponent>
                            <div className="event-inner">
                                <span className="event-inner__title">{`${getMinTime} - ${getMaxTime}`}</span>
                                <span className="event-inner__staff">{dayProps.shifts}</span>
                            </div>
                        </>
                    )
                };
            });
            return events;
        };

        return (
            <div className="staff-info-page staff-schedule">
                <ScheduleCalendar
                    localizer={localizer}
                    formats={calendarFormats}
                    components={calendarComponents}
                    events={renderShifts()}
                    onEventClick={(event) => {
                        if (event.isEmpty) return false;
                    }}
                    selectable
                    onNavigate={onNavigate}
                />
            </div>
        );
    }
}

export default StaffSchedulePage;
