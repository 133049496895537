import React, { useEffect } from 'react';

import { generatePath, useParams } from 'react-router';
import PharmacyInformation from './information';
import PharmacyBranchesList from './branches';
import PharmacyJobsList from './jobs';
import PharmacyStaffList from './staff';

import { Switch, Redirect, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../../routerConfig';

import Sidebar from '../../../../sidebar';
import ContentContainer from '../../../../../components/content-container';
import BranchCard from '../../../../../components/branch-card';
import BackButton from '../../../../../components/UI/buttons/back-button';

import { connect } from 'react-redux';
import { getPharmacyInfoRequest, showBackButton, hideBackButton } from '../../../../../store/actions';
import AdminPharmacyBillingPage from './billing';

function PharmacyDetails({
    history,
    pharmacyInfoPage,
    getPharmacyInfoRequest,
    showBackButton,
    hideBackButton,
    detailsPageView: { backbuttonVisibility }
}) {
    let { id } = useParams();
    useEffect(() => {
        showBackButton();
        getPharmacyInfoRequest(id);
        return () => hideBackButton();
    }, [getPharmacyInfoRequest, hideBackButton, id, showBackButton]);

    const sidebarLinks = [
        {
            text: 'Information',
            to: generatePath(RouterConfig.superAdminPharmacyInformation, { id }),
            disabled: false,
            hidden: false
        },
        {
            text: 'Branches',
            to: generatePath(RouterConfig.superAdminPharmacyBranches, { id }),
            disabled: false,
            hidden: false
        },
        {
            text: 'Staff',
            to: generatePath(RouterConfig.superAdminPharmacyStaff, { id }),
            disabled: false,
            hidden: false
        },
        {
            text: 'Jobs',
            to: generatePath(RouterConfig.superAdminPharmacyJobs, { id }),
            disabled: false,
            hidden: false
        },
        {
            text: 'Subscription & Billing',
            to: generatePath(RouterConfig.superAdminPharmacyBilling, { id }),
            disabled: false,
            hidden: false
        }
    ];

    return (
        <div className="content-view-page details-page">
            <div className="main-wrapper">
                <div className="back-button-holder">
                    <BackButton
                        visible={backbuttonVisibility}
                        onClick={() => history.push(RouterConfig.superAdminPharmacies)}
                    />
                </div>
                <div className="content-holder">
                    <Sidebar links={sidebarLinks} visible>
                        <BranchCard
                            logo={pharmacyInfoPage.data.image}
                            title={pharmacyInfoPage.data.pharmacyName}
                            email={pharmacyInfoPage.data.email}
                            id={pharmacyInfoPage.data.id}
                        />
                    </Sidebar>
                    <ContentContainer smallPaddings fluid={false}>
                        <Switch>
                            <Route
                                path={RouterConfig.superAdminPharmacyInformation}
                                render={() => <PharmacyInformation data={pharmacyInfoPage.data} />}
                            />
                            <Route path={RouterConfig.superAdminPharmacyBranches} component={PharmacyBranchesList} />
                            <Route path={RouterConfig.superAdminPharmacyJobs} component={PharmacyJobsList} />
                            <Route path={RouterConfig.superAdminPharmacyBilling} component={AdminPharmacyBillingPage} />
                            <Route path={RouterConfig.superAdminPharmacyStaff} component={PharmacyStaffList} />
                            <Redirect
                                from={RouterConfig.superAdminPharmacyRoot}
                                to={RouterConfig.superAdminPharmacyInformation}
                            />
                        </Switch>
                    </ContentContainer>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    pharmacyInfoPage: state.adminPharmacyInfoPage,
    detailsPageView: state.branchDetailsPageView
});

const mapDispatchToProps = {
    getPharmacyInfoRequest,
    showBackButton,
    hideBackButton
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyDetails);
