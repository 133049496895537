import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from './../../../UI/inputs/text-input';
import ButtonComponent from './../../../UI/buttons/button';
import SelectComponent from './../../../UI/select';
import RadioGroup from './../../../UI/radio-group';
import CheckboxListContainer from '../../../../containers/checkbox-list';

import { emptyInputError, infoInputError, dropdownError } from '../../../../services/validate';

import './style.scss';

class CreateBranchAddInfoForm extends Component {
    state = {
        itemPerMonth: '',
        MDSPerMonth: '',
        methadonePatientsPerDay: '',
        careHomesSupplied: '',
        supportLevel: this.props.supportLevels[0].id,
        parkingInfo: '',
        pharmacySystem: '',
        initialPharmacySystem: '',
        providingServices: [],
        selectedServices: [],
        loading: false,
        errors: {
            itemPerMonth: null,
            MDSPerMonth: null,
            methadonePatientsPerDay: null,
            careHomesSupplied: null,
            supportLevel: null,
            parkingInfo: null,
            pharmacySystem: null,
            providingServices: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;
        const { branchServices, pharmacySystems, filledForm } = this.props;

        if (Object.keys(filledForm).length) {
            const {
                itemPerMonth,
                MDSPerMonth,
                methadonePatientsPerDay,
                careHomesSupplied,
                supportLevel,
                parkingInfo,
                pharmacySystem,
                selectedServices
            } = filledForm;

            const providingServices = branchServices.flatMap((service) => [
                {
                    ...service,
                    checked: !!selectedServices.find((item) => item === service.id)
                }
            ]);

            let setSystem;
            pharmacySystems.forEach((el, i) => {
                if (pharmacySystem === el.id) {
                    setSystem = el.title;
                }
            });

            this.setState({
                itemPerMonth,
                MDSPerMonth,
                methadonePatientsPerDay,
                careHomesSupplied,
                supportLevel,
                parkingInfo,
                pharmacySystem,
                initialPharmacySystem: setSystem,
                selectedServices,
                providingServices
            });
        } else {
            const providingServices = branchServices.map((item) => {
                return {
                    ...item,
                    checked: false
                };
            });

            this.setState({ providingServices });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeHandler = (e, { name, value }) => {
        const { pharmacySystems, supportLevels } = this.props;
        let inputValue;

        switch (name) {
            case 'itemPerMonth':
                inputValue = Math.max(Math.min(value, 10000), 0) || '';
                break;
            case 'MDSPerMonth':
            case 'methadonePatientsPerDay':
            case 'careHomesSupplied':
                inputValue = !isNaN(value) && value ? Math.min(value, 10000) : '';
                break;
            case 'pharmacySystem':
                pharmacySystems.forEach((el, i) => {
                    if (value === el.title) {
                        inputValue = el.id;
                    }
                    return inputValue;
                });
                break;
            case 'supportLevel':
                supportLevels.forEach((el, i) => {
                    if (value === el.id) {
                        inputValue = el.id;
                    }
                    return inputValue;
                });
                break;
            default:
                inputValue = value;
        }

        return this.setState(
            {
                [name]: inputValue
            },
            () => this.validate(name)
        );
    };

    onCheckboxChange = (e, { name }) => {
        let checkBoxID = name.split('#ID=')[1];

        const { providingServices } = this.state;

        providingServices.forEach((el) => {
            if (+checkBoxID === el.id) {
                el.checked = !el.checked;
            }
        });

        let selectedServices = [];

        providingServices.forEach((el) => {
            if (el.checked) selectedServices.push(el.id);
        });

        this.setState({ selectedServices });
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;

        switch (name) {
            case 'itemPerMonth':
            case 'MDSPerMonth':
            case 'methadonePatientsPerDay':
            case 'careHomesSupplied':
                error = emptyInputError(value);
                break;
            case 'parkingInfo':
                error = infoInputError(value, true);
                break;
            case 'pharmacySystem':
                error = dropdownError(value);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    }
                }),
                resolve
            );
        });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('itemPerMonth'),
            this.validate('MDSPerMonth'),
            this.validate('methadonePatientsPerDay'),
            this.validate('careHomesSupplied'),
            this.validate('parkingInfo'),
            this.validate('pharmacySystem')
        ]);

        const {
            itemPerMonth,
            MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            supportLevel,
            parkingInfo,
            pharmacySystem,
            selectedServices,
            errors
        } = this.state;
        const { onSuccess } = this.props;

        if (
            errors.itemPerMonth ||
            errors.MDSPerMonth ||
            errors.methadonePatientsPerDay ||
            errors.careHomesSupplied ||
            errors.parkingInfo
        )
            return;

        const additionalInfoData = {
            itemsPerMonth: itemPerMonth,
            mdsPerMonth: MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            staffSupportLevelId: supportLevel,
            parkingInformation: parkingInfo,
            pharmacySystemId: pharmacySystem,
            pharmacyServicesIds: selectedServices
        };

        onSuccess(additionalInfoData);
    };

    onCancel = () => {
        const {
            itemPerMonth,
            MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            supportLevel,
            parkingInfo,
            pharmacySystem,
            selectedServices
        } = this.state;
        const { onCancelClick } = this.props;

        onCancelClick({
            itemPerMonth,
            MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            supportLevel,
            parkingInfo,
            pharmacySystem,
            selectedServices
        });
    };

    render() {
        const {
            supportLevel,
            itemPerMonth,
            MDSPerMonth,
            methadonePatientsPerDay,
            careHomesSupplied,
            parkingInfo,
            initialPharmacySystem,
            providingServices,
            loading,
            errors
        } = this.state;
        const { supportLevels, pharmacySystems } = this.props;

        const radioButtons = supportLevels.map((item) => {
            return {
                id: item.id,
                label: `${item.title} staff`,
                name: 'supportLevel',
                value: item.id,
                checked: supportLevel === item.id,
                onChange: this.onChangeHandler
            };
        });

        const checkboxes = providingServices.map((item) => {
            return {
                id: item.id,
                label: item.title,
                name: `${item.title}#ID=${item.id}`,
                checked: item.checked,
                onChange: this.onCheckboxChange
            };
        });

        // convert into correct array of objects
        const formatedpharmacysOptions = pharmacySystems.map((option) => {
            return {
                key: option.id,
                value: option.title,
                text: option.title
            };
        });

        return (
            <div className="create-branch-add-info-form">
                <div className="input-row">
                    <TextInput
                        name="itemPerMonth"
                        label="Item per month"
                        type="text"
                        value={String(itemPerMonth)}
                        fluid={false}
                        error={!!errors.itemPerMonth}
                        helperText={errors.itemPerMonth}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="MDSPerMonth"
                        label="MDS per month"
                        type="text"
                        value={String(MDSPerMonth)}
                        fluid={false}
                        error={!!errors.MDSPerMonth}
                        helperText={errors.MDSPerMonth}
                        onChange={this.onChangeHandler}
                    />
                </div>
                <div className="input-row">
                    <TextInput
                        name="methadonePatientsPerDay"
                        label="Methadone patients per day"
                        type="text"
                        value={String(methadonePatientsPerDay)}
                        fluid={false}
                        error={!!errors.methadonePatientsPerDay}
                        helperText={errors.methadonePatientsPerDay}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="careHomesSupplied"
                        label="Care homes supplied"
                        type="text"
                        value={String(careHomesSupplied)}
                        fluid={false}
                        error={!!errors.careHomesSupplied}
                        helperText={errors.careHomesSupplied}
                        onChange={this.onChangeHandler}
                    />
                </div>
                <RadioGroup items={radioButtons} groupLabel="Staff Support Level" />
                <div className="input-row">
                    <TextInput
                        name="parkingInfo"
                        label="Parking Information"
                        type="text"
                        value={String(parkingInfo)}
                        fluid={false}
                        error={!!errors.parkingInfo}
                        helperText={errors.parkingInfo}
                        onChange={this.onChangeHandler}
                    />
                    <SelectComponent
                        name="pharmacySystem"
                        label="Pharmacy System"
                        key={initialPharmacySystem}
                        defaultValue={initialPharmacySystem}
                        fluid={false}
                        options={formatedpharmacysOptions}
                        error={!!errors.pharmacySystem}
                        helperText={errors.pharmacySystem}
                        onChange={this.onChangeHandler}
                    />
                </div>
                <CheckboxListContainer label="Providing services" items={checkboxes} />
                <div className="buttons-row">
                    <ButtonComponent
                        value="Continue"
                        onClick={this.onSubmit}
                        size="medium"
                        loading={loading}
                        disabled={
                            !!errors.itemPerMonth ||
                            !!errors.MDSPerMonth ||
                            !!errors.methadonePatientsPerDay ||
                            !!errors.careHomesSupplied ||
                            !!errors.supportLevel ||
                            !!errors.parkingInfo ||
                            !!errors.pharmacySystem
                        }
                    />
                    <ButtonComponent
                        value="Back"
                        type="transparent"
                        size="small"
                        onClick={this.onCancel}
                        loading={loading}
                    />
                </div>
            </div>
        );
    }
}

CreateBranchAddInfoForm.propTypes = {
    onSuccess: PropTypes.func,
    onCancelClick: PropTypes.func,
    supportLevels: PropTypes.array.isRequired,
    pharmacySystems: PropTypes.array.isRequired,
    branchServices: PropTypes.array.isRequired
};

export default CreateBranchAddInfoForm;
