import { DataTable, DataTableSection, DataTableSectionItem } from 'components/data-table';
import ButtonComponent from 'components/UI/buttons/button';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { CONTRACT_TYPE_TEXT } from 'services/constants';
import { contractPages, jobTitleIdText } from 'services/contract';
import { useSearchParams } from 'services/hooks';
import { userRoles } from 'userConfig';

import './style.scss';

function ContractBoard({ contract, onEditClick, onDeleteClick, onStartClick }) {
    const [{ tab = contractPages.currentContract }] = useSearchParams();

    const user = useSelector((state) => state.auth.user);

    const isAdmin = user.role === userRoles.BRANCH_ADMIN;

    const userControlButtons = () => {
        if (!isAdmin) return null;

        switch (tab) {
            case contractPages.currentContract:
                return <ButtonComponent value="Edit" size="small" onClick={onEditClick} />;
            case contractPages.upcomingContract:
                return (
                    <>
                        <ButtonComponent value="Start" size="small" onClick={onStartClick && onStartClick(contract)} />
                        <ButtonComponent
                            value="Delete"
                            size="small"
                            onClick={onDeleteClick && onDeleteClick(contract)}
                        />
                        <ButtonComponent value="Edit" size="small" onClick={onEditClick} />
                    </>
                );
            default:
                break;
        }
    };

    const noContentText = isAdmin
        ? `This pharmacist doesn't have an ongoing contract. You can create and start one from the "Upcoming contracts" tab.`
        : `You don't have an ongoing contract now. Please, contact your manager.`;

    if (!contract) {
        return (
            <div className="contract-board">
                <h3 className="contract-board__empty-message">{noContentText}</h3>
            </div>
        );
    }

    return (
        <div className="contract-board">
            <div className="contract-board__contract-title-wrapper">
                <h3 className="contract-board__contract-title">{contract.title}</h3>
                <div className="contract-board__contract-control">{userControlButtons()}</div>
            </div>
            <DataTable>
                <DataTableSection sectionTitle={'Basic information'}>
                    <DataTableSectionItem description="Job title:" value={jobTitleIdText[contract.jobRoleId]} />
                    <DataTableSectionItem
                        description="Start date:"
                        value={moment(contract.startDate).format('DD/MM/yyyy')}
                    />
                    <DataTableSectionItem
                        description="End date:"
                        value={moment(contract.endDate).format('DD/MM/yyyy')}
                    />
                    <DataTableSectionItem description="Contract type" value={CONTRACT_TYPE_TEXT[contract.type]} />
                </DataTableSection>

                <DataTableSection sectionTitle={'Working hours'}>
                    <DataTableSectionItem
                        description="Working hours:"
                        value={`${contract.workingOurs} per ${contract.workingPeriodType}`}
                    />
                    <DataTableSectionItem
                        description="Absence days:"
                        value={`${contract.absenceDays} per year (${
                            !contract.includeBankHolidays ? 'not ' : ''
                        }including holidays)`}
                    />
                </DataTableSection>
                <DataTableSection sectionTitle={'Gross salary'}>
                    <DataTableSectionItem
                        description="Amount:"
                        value={`${contract.grossSalaryPay} per ${contract.grossSalaryPeriodType}`}
                    />
                </DataTableSection>
                <DataTableSection sectionTitle={'OverTime salary'}>
                    <DataTableSectionItem description="Amount:" value={`${contract.overTimePayHourly} per hour`} />
                </DataTableSection>
                <DataTableSection sectionTitle={'Additional compensations'}>
                    {contract.contractAdditionCompensations && contract.contractAdditionCompensations.length ? (
                        contract.contractAdditionCompensations.map((item, i) => (
                            <DataTableSectionItem
                                key={i}
                                description={item.name}
                                value={`${item.payAmount} per ${item.timePeriod}`}
                            />
                        ))
                    ) : (
                        <DataTableSectionItem description="No compensations" />
                    )}
                </DataTableSection>
            </DataTable>
        </div>
    );
}

ContractBoard.propTypes = {
    contract: PropTypes.object
};

export default ContractBoard;
