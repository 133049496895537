import {
    GET_SCHEDULE_SHIFTS_SUCCESS,
    GET_SCHEDULE_SHIFTS_FAILED,
    ON_SCHEDULE_FILTER_CHANGE,
    ON_SCHEDULE_DATE_RANGE_CHANGE,
    CLEAR_SCHEDULE_FILTERS,
    ON_SCHEDULE_POST_JOB_REQUEST,
    ON_SCHEDULE_POST_JOB_SUCCESS,
    ON_SCHEDULE_POST_JOB_FAILED,
    ON_SCHEDULE_SUBMIT_SHIFT_REQUEST,
    ON_SCHEDULE_SUBMIT_SHIFT_SUCCESS,
    ON_SCHEDULE_SUBMIT_SHIFT_FAILED
} from '../../../actions/admin-view/schedule-page/action-types';
import moment from 'moment';

const initialState = {
    dateRange: {
        startDateRange: moment().startOf('week').format('YYYY-MM-DD'),
        endDateRange: moment().endOf('week').format('YYYY-MM-DD')
    },
    filters: {
        branch: '',
        region: '',
        staff: ''
    },
    branches: null,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHEDULE_SHIFTS_SUCCESS:
            return {
                ...state,
                branches: action.payload
            };
        case ON_SCHEDULE_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                }
            };
        case GET_SCHEDULE_SHIFTS_FAILED:
            return state;
        case ON_SCHEDULE_DATE_RANGE_CHANGE:
            return {
                ...state,
                dateRange: action.payload
            };
        case CLEAR_SCHEDULE_FILTERS:
            return {
                ...state,
                filters: {
                    branch: '',
                    region: '',
                    staff: ''
                }
            };
        case ON_SCHEDULE_POST_JOB_REQUEST:
        case ON_SCHEDULE_SUBMIT_SHIFT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ON_SCHEDULE_POST_JOB_SUCCESS:
        case ON_SCHEDULE_POST_JOB_FAILED:
        case ON_SCHEDULE_SUBMIT_SHIFT_SUCCESS:
        case ON_SCHEDULE_SUBMIT_SHIFT_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};
