import {
    GET_LOCUM_JOB_DETAILS_REQUEST,
    GET_LOCUM_JOB_DETAILS_SUCCESS,
    GET_LOCUM_JOB_DETAILS_FAILED,
    LOCUM_JOB_ACTION_REQUEST,
    LOCUM_JOB_ACTION_SUCCESS,
    LOCUM_JOB_ACTION_FAILED,
    JOB_MILEAGE_VALUE_UPDATE_REQUEST,
    JOB_MILEAGE_VALUE_UPDATE_SUCCESS,
    JOB_MILEAGE_VALUE_UPDATE_FAILED
} from './action-types';

export const getLocumJobDetailsRequest = (payload) => ({
    type: GET_LOCUM_JOB_DETAILS_REQUEST,
    payload
});

export const getLocumJobDetailsSuccess = (payload) => ({
    type: GET_LOCUM_JOB_DETAILS_SUCCESS,
    payload
});

export const getLocumJobDetailsFailed = () => ({
    type: GET_LOCUM_JOB_DETAILS_FAILED
});

export const onLocumJobActionRequest = (payload) => ({
    type: LOCUM_JOB_ACTION_REQUEST,
    payload
});

export const onLocumJobActionSuccess = () => ({
    type: LOCUM_JOB_ACTION_SUCCESS
});

export const onLocumJobActionFailed = () => ({
    type: LOCUM_JOB_ACTION_FAILED
});

export const onJobMileageValueUpdateRequest = (payload) => ({
    type: JOB_MILEAGE_VALUE_UPDATE_REQUEST,
    payload
});

export const onJobMileageValueUpdateSuccess = () => ({
    type: JOB_MILEAGE_VALUE_UPDATE_SUCCESS
});

export const onJobMileageValueUpdateFailed = () => ({
    type: JOB_MILEAGE_VALUE_UPDATE_FAILED
});
