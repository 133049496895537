import {
    GET_LOCUMS_BY_GPHC_CLEAR,
    GET_LOCUMS_BY_GPHC_FAILED,
    GET_LOCUMS_BY_GPHC_SUCCESS,
    GET_LOCUMS_BY_GPHC_REQUEST,
    GET_LOCUMS_BY_GPHC_LOADING
} from './action-types';

export const getLocumsByGphcRequest = (payload) => ({
    type: GET_LOCUMS_BY_GPHC_REQUEST,
    payload
});

export const getLocumsByGphcSuccess = (payload) => ({
    type: GET_LOCUMS_BY_GPHC_SUCCESS,
    payload
});

export const getLocumsByGphcFailed = () => ({
    type: GET_LOCUMS_BY_GPHC_FAILED
});

export const getLocumsByGphcClear = () => ({
    type: GET_LOCUMS_BY_GPHC_CLEAR
});

export const getLocumsByGphcLoading = () => ({
    type: GET_LOCUMS_BY_GPHC_LOADING
});
