export const GET_CURRENT_SHIFT = 'GET_CURRENT_SHIFT';
export const GET_CURRENT_SHIFT_ID = 'GET_CURRENT_SHIFT_ID';

export const GET_DAY_SHIFTS_REQUEST = 'GET_DAY_SHIFTS_REQUEST';
export const GET_DAY_SHIFTS_SUCCESS = 'GET_DAY_SHIFTS_SUCCESS';
export const GET_DAY_SHIFTS_FAILED = 'GET_DAY_SHIFTS_FAILED';

export const ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST = 'ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST';
export const ON_DAY_VIEW_SUBMIT_SHIFT_SUCCESS = 'ON_DAY_VIEW_SUBMIT_SHIFT_SUCCESS';
export const ON_DAY_VIEW_SUBMIT_SHIFT_FAILED = 'ON_DAY_VIEW_SUBMIT_SHIFT_FAILED';

export const ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST = 'ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST';
export const ON_DAY_VIEW_SUBMIT_DRAFT_SUCCESS = 'ON_DAY_VIEW_SUBMIT_DRAFT_SUCCESS';
export const ON_DAY_VIEW_SUBMIT_DRAFT_FAILED = 'ON_DAY_VIEW_SUBMIT_DRAFT_FAILED';

export const ON_REMOVE_SHIFT_REQUEST = 'ON_REMOVE_SHIFT_REQUEST';
export const ON_REMOVE_SHIFT_SUCCESS = 'ON_REMOVE_SHIFT_SUCCESS';
export const ON_REMOVE_SHIFT_FAILED = 'ON_REMOVE_SHIFT_FAILED';

export const ON_DAY_VIEW_POST_JOB_REQUEST = 'ON_DAY_VIEW_POST_JOB_REQUEST';
export const ON_DAY_VIEW_POST_JOB_SUCCESS = 'ON_DAY_VIEW_POST_JOB_SUCCESS';
export const ON_DAY_VIEW_POST_JOB_FAILED = 'ON_DAY_VIEW_POST_JOB_FAILED';
