import {
    GET_BRANCH_INFO_REQUEST,
    GET_BRANCH_INFO_SUCCESS,
    GET_BRANCH_INFO_FAILED,
    GET_BRANCHES_GENERAL_DATA_REQUEST,
    GET_BRANCHES_GENERAL_DATA_SUCCESS,
    GET_BRANCHES_GENERAL_DATA_FAILED,
    ON_ARCHIVE_BRANCH_REQUEST,
    ON_ARCHIVE_BRANCH_SUCCESS,
    ON_ARCHIVE_BRANCH_FAILED,
    ON_EDIT_BRANCH_REQUEST,
    ON_EDIT_BRANCH_SUCCESS,
    ON_EDIT_BRANCH_FAILED,
    ON_EDIT_BRANCH_SCHEDULE_REQUEST,
    ON_EDIT_BRANCH_SCHEDULE_SUCCESS,
    ON_EDIT_BRANCH_SCHEDULE_FAILED,
    REMOVE_BRANCH_LOGO_REQUEST,
    REMOVE_BRANCH_LOGO_SUCCESS,
    REMOVE_BRANCH_LOGO_FAILED
} from './action-types';

export const getBranchInfoRequest = (branchId) => ({
    type: GET_BRANCH_INFO_REQUEST,
    branchId
});

export const getBranchInfoSuccess = (payload) => ({
    type: GET_BRANCH_INFO_SUCCESS,
    payload
});

export const getBranchInfoFailed = () => ({
    type: GET_BRANCH_INFO_FAILED
});

export const getBranchGeneralDataRequest = () => ({
    type: GET_BRANCHES_GENERAL_DATA_REQUEST
});

export const getBranchGeneralDataSuccess = (payload) => ({
    type: GET_BRANCHES_GENERAL_DATA_SUCCESS,
    payload
});

export const getBranchGeneralDataFailed = () => ({
    type: GET_BRANCHES_GENERAL_DATA_FAILED
});

export const onArchiveBranchRequest = (payload) => ({
    type: ON_ARCHIVE_BRANCH_REQUEST,
    payload
});

export const onArchiveBranchSuccess = () => ({
    type: ON_ARCHIVE_BRANCH_SUCCESS
});

export const onArchiveBranchFailed = () => ({
    type: ON_ARCHIVE_BRANCH_FAILED
});

export const onEditBranchRequest = (payload) => ({
    type: ON_EDIT_BRANCH_REQUEST,
    payload
});

export const onEditBranchSuccess = () => ({
    type: ON_EDIT_BRANCH_SUCCESS
});

export const onEditBranchFailed = () => ({
    type: ON_EDIT_BRANCH_FAILED
});

export const onEditBranchScheduleRequest = (payload) => ({
    type: ON_EDIT_BRANCH_SCHEDULE_REQUEST,
    payload
});

export const onEditBranchScheduleSuccess = () => ({
    type: ON_EDIT_BRANCH_SCHEDULE_SUCCESS
});

export const onEditBranchScheduleFailed = () => ({
    type: ON_EDIT_BRANCH_SCHEDULE_FAILED
});

export const removeBranchLogoRequest = (payload) => ({
    type: REMOVE_BRANCH_LOGO_REQUEST,
    payload
});

export const removeBranchLogoSuccess = () => ({
    type: REMOVE_BRANCH_LOGO_SUCCESS
});

export const removeBranchLogoFailed = () => ({
    type: REMOVE_BRANCH_LOGO_FAILED
});
