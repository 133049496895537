import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

function CalendarButtons({ onPostJobClick, onAddShiftClick }) {
    return (
        <div className={classnames('calendar-buttons')}>
            <button onClick={onAddShiftClick} className="calendar-buttons__shift">
                Add Shift
            </button>
            <button onClick={onPostJobClick} className="calendar-buttons__job">
                Post job
            </button>
        </div>
    );
}

CalendarButtons.propTypes = {
    onPostJobClick: PropTypes.func.isRequired,
    onAddShiftClick: PropTypes.func.isRequired
};

export default CalendarButtons;
