import {
    GET_STAFF_ABSENCE_REQUEST,
    GET_STAFF_ABSENCE_SUCCESS,
    GET_STAFF_ABSENCE_FAILED,
    CLEAR_STAFF_ABSENCE_FILTERS,
    STAFF_ABSENCE_FILTER_CHANGE,
    ON_ABSENCE_FILTER_FALSE
} from './action-types';

export const getStaffAbsenceRequest = (payload, filters) => ({
    type: GET_STAFF_ABSENCE_REQUEST,
    payload,
    filters
});

export const getStaffAbsenceSuccess = (payload) => ({
    type: GET_STAFF_ABSENCE_SUCCESS,
    payload
});

export const getStaffAbsenceFailed = () => ({
    type: GET_STAFF_ABSENCE_FAILED
});

export const clearStaffAbsenceFilters = () => ({
    type: CLEAR_STAFF_ABSENCE_FILTERS
});

export const onAbsenseFilterFalse = () => ({
    type: ON_ABSENCE_FILTER_FALSE
});

export const onStaffAbsenceFilterChange = (payload, page) => ({
    type: STAFF_ABSENCE_FILTER_CHANGE,
    payload,
    page
});
