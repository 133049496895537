import {
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAILED,
    ON_CHANGE_USER_STATUS_REQUEST,
    ON_CHANGE_USER_STATUS_SUCCESS,
    ON_CHANGE_USER_STATUS_FAILED
} from './action-types';

export const getUserInfoRequest = (userId) => ({
    type: GET_USER_INFO_REQUEST,
    userId
});

export const getUserInfoSuccess = (payload) => ({
    type: GET_USER_INFO_SUCCESS,
    payload
});

export const getUserInfoFailed = () => ({
    type: GET_USER_INFO_FAILED
});

export const onChangeUserStatusRequest = (payload, actionName) => ({
    type: ON_CHANGE_USER_STATUS_REQUEST,
    payload,
    actionName
});

export const onChangeUserStatusSuccess = () => ({
    type: ON_CHANGE_USER_STATUS_SUCCESS
});

export const onChangeUserStatusFailed = () => ({
    type: ON_CHANGE_USER_STATUS_FAILED
});
