import { call, put, fork, select, takeEvery } from 'redux-saga/effects';
import {
    postPaymentMethodSuccess,
    postPaymentMethodFailed,
    getPaymentMethodRequest,
    getPaymentMethodSuccess,
    getPaymentMethodFailed,
    getSubscriptionPlanRequest,
    getSubscriptionPlanSuccess,
    getSubscriptionPlanFailed,
    getSubscriptionPlansListSuccess,
    getSubscriptionPlansListFailed,
    getActiveBranchesListSuccess,
    getActiveBranchesListFailed,
    postPlanSubscriptionSuccess,
    postPlanSubscriptionFailed,
    cancelPlanSubscriptionSuccess,
    cancelPlanSubscriptionFailed,
    abortSubscriptionActionSuccess,
    abortSubscriptionActionFailed,
    getTransactionsListRequest,
    getTransactionsListSuccess,
    getTransactionsListFailed,
    updateBranchSubscriptionsSuccess,
    updateBranchSubscriptionsFailed,
    updateProfileHostedPageSuccess,
    updateProfileHostedPageFailed,
    subscriptionStandardPlanSuccess,
    subscriptionStandardPlanFailed,
    getProfileSubscriptionSuccess,
    getProfileSubscriptionFailed,
    getAddonsSuccess,
    getAddonsFailed,
    postDeactivateSubscriptionSuccess,
    postDeactivateSubscriptionFailed
} from '../../../actions';
import {
    selectCurrentSubscriptionPlan,
    selectSelectedSubscriptionPlan,
    selectSubscriptionLoading,
    selectActivatedBranches
} from './selectors';
import * as billingActionTypes from '../../../actions/admin-view/billing-page/action-types';
import api from '../../../../services/api';

function* onGetSubscriptionStandardPlanRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/payments/subscription-standard-plan',
            method: 'GET'
        });
        yield put(subscriptionStandardPlanSuccess(data));
    } catch (err) {
        yield put(subscriptionStandardPlanFailed());
    }
}

function* onPostPaymentMethodRequest({ payload: { id, toggleModal } }) {
    try {
        yield call(api.request, {
            url: '/payments/payment-method',
            method: 'POST',
            payload: {
                paymentMethodId: id
            }
        });
        yield put(postPaymentMethodSuccess());
        toggleModal();
        yield put(getPaymentMethodRequest());
    } catch (err) {
        yield put(postPaymentMethodFailed());
    }
}

function* onPostDeactivateBranchRequest({ payload: { id, cb } }) {
    try {
        yield call(api.request, {
            url: `/branches/${id}/deactivate`,
            method: 'POST'
        });
        yield put(postDeactivateSubscriptionSuccess());
        cb();
    } catch (err) {
        yield put(postDeactivateSubscriptionFailed());
    }
}

function* onUpdateBranchSubscriptionsRequest({ payload: { branches, toggleModal } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/branches/update-subscriptions',
            method: 'PUT',
            payload: {
                branches
            }
        });
        yield put(updateBranchSubscriptionsSuccess());
        toggleModal(data);
    } catch (err) {
        yield put(updateBranchSubscriptionsFailed());
    }
}

function* onUpdateProfileHostedPageRequest({ payload: { hostedPageId, callback } }) {
    try {
        yield call(api.request, {
            url: '/profile/update-hosted-page',
            method: 'PUT',
            payload: {
                hostedPageId
            }
        });
        yield put(updateProfileHostedPageSuccess());
        callback();
    } catch (err) {
        yield put(updateProfileHostedPageFailed());
    }
}

function* onGetPaymentMethodRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/payments/payment-method',
            method: 'GET'
        });
        yield put(getPaymentMethodSuccess(data));
    } catch (err) {
        yield put(getPaymentMethodFailed());
    }
}

function* onProfileSubscriptionRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/profile/get-subscription',
            method: 'GET'
        });
        yield put(getProfileSubscriptionSuccess(data));
    } catch (err) {
        yield put(getProfileSubscriptionFailed());
    }
}

function* onGetCurrentSubscriptionPlanRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/payments/current-plan',
            method: 'GET'
        });
        yield put(getSubscriptionPlanSuccess(data));
    } catch (err) {
        yield put(getSubscriptionPlanFailed());
    }
}

function* onGetSubscriptionPlansListRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/payments/plans',
            method: 'GET'
        });
        yield put(getSubscriptionPlansListSuccess(data));
    } catch (err) {
        yield put(getSubscriptionPlansListFailed());
    }
}

function* onGetActiveBranchesListRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/payments/branches',
            method: 'post'
        });
        yield put(getActiveBranchesListSuccess(data));
    } catch (err) {
        yield put(getActiveBranchesListFailed());
    }
}

function* onGetAddonsRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/payments/addons',
            method: 'get'
        });
        yield put(getAddonsSuccess(data));
    } catch (err) {
        yield put(getAddonsFailed());
    }
}

function* onPostPlanSubscriptionRequest({
    payload: { planId, toggleModal, firstItemId, lastItemId, toggleSuccessModal },
    downgrade
}) {
    const currentPlan = yield select(selectCurrentSubscriptionPlan);
    const selectedPlan = yield select(selectSelectedSubscriptionPlan);
    const selectedBranches = yield select(selectActivatedBranches);
    const loading = yield select(selectSubscriptionLoading);
    const apiUrl = currentPlan || (currentPlan && downgrade) ? '/payments/update-subscription' : '/payments/subscribe';
    try {
        const { data } = yield call(api.request, {
            url: apiUrl,
            method: 'POST',
            payload: {
                planId,
                branches: selectedBranches,
                immediately: downgrade ? downgrade.immediately : false
            },
            loading
        });
        yield put(postPlanSubscriptionSuccess(data));
        toggleModal();
        if ((currentPlan && +currentPlan.price < +selectedPlan.price) || !currentPlan) toggleSuccessModal();
        yield put(getSubscriptionPlanRequest());
        yield put(getTransactionsListRequest({ firstItemId, lastItemId }));
    } catch (err) {
        yield put(postPlanSubscriptionFailed());
    }
}

function* onCancelPlanSubscriptionRequest({ payload: { immediately, toggleModal } }) {
    const loading = yield select(selectSubscriptionLoading);
    try {
        yield call(api.request, {
            url: '/payments/cancel-subscription',
            method: 'POST',
            payload: {
                immediately
            },
            loading
        });
        yield put(cancelPlanSubscriptionSuccess());
        toggleModal();
        yield put(getSubscriptionPlanRequest());
    } catch (err) {
        yield put(cancelPlanSubscriptionFailed());
    }
}

function* onAbortSubscriptionActionRequest({ action }) {
    try {
        yield call(api.request, {
            url: `/payments/abort-${action}`,
            method: 'POST'
        });
        yield put(abortSubscriptionActionSuccess());
        yield put(getSubscriptionPlanRequest());
    } catch (err) {
        yield put(abortSubscriptionActionFailed());
    }
}

function* onGetTransactionsListRequest({ payload: { page, perPage } }) {
    //   const perPage = yield select(selectTransactionsItemsPerPage);
    try {
        const { data } = yield call(api.request, {
            url: '/payments/invoices',
            method: 'POST',
            payload: {
                pagination: {
                    page,
                    perPage
                }
            }
        });
        yield put(getTransactionsListSuccess(data));
    } catch (err) {
        yield put(getTransactionsListFailed());
    }
}

// watchers
function* watchPostPaymentMethodRequest() {
    yield takeEvery(billingActionTypes.POST_PAYMENT_METHOD_REQUEST, onPostPaymentMethodRequest);
}

function* watchUpdateBranchSubscriptionsRequest() {
    yield takeEvery(billingActionTypes.UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST, onUpdateBranchSubscriptionsRequest);
}

function* watchUpdateHostedPageIdRequest() {
    yield takeEvery(billingActionTypes.UPDATE_PROFILE_HOSTED_PAGE_REQUEST, onUpdateProfileHostedPageRequest);
}

function* watchGetPaymentMethodRequest() {
    yield takeEvery(billingActionTypes.GET_PAYMENT_METHOD_REQUEST, onGetPaymentMethodRequest);
}

function* watchGetCurrentSubscriptionPlanRequest() {
    yield takeEvery(billingActionTypes.GET_SUBSCRIPTION_PLAN_REQUEST, onGetCurrentSubscriptionPlanRequest);
}

function* watchGetSubscriptionPlansListRequest() {
    yield takeEvery(billingActionTypes.GET_SUBSCRIPTION_PLANS_LIST_REQUEST, onGetSubscriptionPlansListRequest);
}

function* watchGetActiveBranchesListRequest() {
    yield takeEvery(billingActionTypes.GET_ACTIVE_BRANCHES_LIST_REQUEST, onGetActiveBranchesListRequest);
}

function* watchPostPlanSubscriptionRequest() {
    yield takeEvery(billingActionTypes.POST_PLAN_SUBSCRIPTION_REQUEST, onPostPlanSubscriptionRequest);
}

function* watchCancelPlanSubscriptionRequest() {
    yield takeEvery(billingActionTypes.CANCEL_PLAN_SUBSCRIPTION_REQUEST, onCancelPlanSubscriptionRequest);
}

function* watchAbortSubscriptionActionRequest() {
    yield takeEvery(billingActionTypes.ABORT_SUBSCRIPTION_ACTION_REQUEST, onAbortSubscriptionActionRequest);
}

function* watchGetTransactionsListRequest() {
    yield takeEvery(billingActionTypes.GET_TRANSACTIONS_LIST_REQUEST, onGetTransactionsListRequest);
}

function* watchGetSubscriptionStandardPlanRequest() {
    yield takeEvery(billingActionTypes.SUBSCRIPTION_STANDARD_PLAN_REQUEST, onGetSubscriptionStandardPlanRequest);
}
function* watchGetProfileSubscriptionRequest() {
    yield takeEvery(billingActionTypes.GET_PROFILE_SUBSCRIPTION_REQUEST, onProfileSubscriptionRequest);
}
function* watchPostDeactivateBranchRequest() {
    yield takeEvery(billingActionTypes.POST_DEACTIVATE_SUBSCRIPTION_REQUEST, onPostDeactivateBranchRequest);
}

function* watchGetAddonsRequest() {
    yield takeEvery(billingActionTypes.GET_ADDONS_REQUEST, onGetAddonsRequest);
}

const billingPageSaga = [
    fork(watchPostPaymentMethodRequest),
    fork(watchGetPaymentMethodRequest),
    fork(watchGetCurrentSubscriptionPlanRequest),
    fork(watchGetSubscriptionPlansListRequest),
    fork(watchGetActiveBranchesListRequest),
    fork(watchPostPlanSubscriptionRequest),
    fork(watchCancelPlanSubscriptionRequest),
    fork(watchAbortSubscriptionActionRequest),
    fork(watchGetTransactionsListRequest),
    fork(watchUpdateBranchSubscriptionsRequest),
    fork(watchUpdateHostedPageIdRequest),
    fork(watchGetSubscriptionStandardPlanRequest),
    fork(watchGetProfileSubscriptionRequest),
    fork(watchGetAddonsRequest),
    fork(watchPostDeactivateBranchRequest)
];

export default billingPageSaga;
