import {
    handlePersonalInfo,
    handleRemoveFile,
    handleRigthsToWorkInfo,
    handleAccreditationsInfo,
    handleSkillsInfo
} from './handlers';

const userProfileInfo = (state, action) => ({
    personalInfo: handlePersonalInfo(state, action),
    rigthsToWork: handleRigthsToWorkInfo(state, action),
    skills: handleSkillsInfo(state, action),
    accreditations: handleAccreditationsInfo(state, action),
    loading: handleRemoveFile(state, action)
});

export default userProfileInfo;
