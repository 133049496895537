import {
    GET_POSTED_JOBS_REQUEST,
    GET_POSTED_JOBS_SUCCESS,
    GET_POSTED_JOBS_FAILED,
    ON_JOBS_FILTER_CHANGE,
    ON_JOBS_FILTER_FALSE,
    CLEAR_JOBS_FILTERS,
    SET_JOB_STATUS_FAILED,
    SET_JOB_STATUS_REQUEST,
    SET_JOB_STATUS_SUCCESS,
    GET_CSV_JOBS_DOWNLOAD_REQUEST,
    GET_CSV_JOBS_DOWNLOAD_SUCCESS,
    GET_CSV_JOBS_DOWNLOAD_FAILED,
    GET_MIN_AND_MAX_JOBS_REQUEST,
    GET_MIN_AND_MAX_JOBS_SUCCESS,
    GET_MIN_AND_MAX_JOBS_FAILED
} from './action-types';

export const getPostedJobsRequest = (payload, filters) => ({
    type: GET_POSTED_JOBS_REQUEST,
    payload,
    filters
});

export const getPostedJobsSuccess = (payload) => ({
    type: GET_POSTED_JOBS_SUCCESS,
    payload
});

export const getPostedJobsFailed = () => ({
    type: GET_POSTED_JOBS_FAILED
});

export const onJobsFilterChange = (payload, page) => ({
    type: ON_JOBS_FILTER_CHANGE,
    payload,
    page
});

export const onJobsFilterFalse = () => ({
    type: ON_JOBS_FILTER_FALSE
});

export const clearJobsFilters = () => ({
    type: CLEAR_JOBS_FILTERS
});

export const setJobStatusRequest = (payload) => {
    return {
        type: SET_JOB_STATUS_REQUEST,
        payload
    };
};
export const setJobStatusSuccess = (payload) => {
    return {
        type: SET_JOB_STATUS_SUCCESS,
        payload
    };
};
export const setJobStatusFailed = () => {
    return {
        type: SET_JOB_STATUS_FAILED
    };
};

export const getCSVJobsDownloadRequest = (filters) => ({
    type: GET_CSV_JOBS_DOWNLOAD_REQUEST,
    filters
});

export const getCSVJobsDownloadSuccess = (payload) => ({
    type: GET_CSV_JOBS_DOWNLOAD_SUCCESS,
    payload
});

export const getCSVJobsDownloadFailed = () => ({
    type: GET_CSV_JOBS_DOWNLOAD_FAILED
});

export const getMinAndMaxJobRequest = (payload) => ({
    type: GET_MIN_AND_MAX_JOBS_REQUEST,
    payload
});

export const getMinAndMaxJobSuccess = (payload) => ({
    type: GET_MIN_AND_MAX_JOBS_SUCCESS,
    payload
});

export const getMinAndMaxJobFailed = () => ({
    type: GET_MIN_AND_MAX_JOBS_FAILED
});
