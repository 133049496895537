import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextInput from './../../UI/inputs/text-input';
import ButtonComponent from './../../UI/buttons/button';

import { emailError, passError } from './../../../services/validate';

import './style.scss';

function ChangePasswordForm({ loading, onSuccess, onCancelClick, formTitle, defaultEmail }) {
    const [formData, setFormData] = useState({
        email: {
            value: defaultEmail,
            errorText: ''
        },
        password: {
            value: '',
            errorText: ''
        }
    });

    const handleChange = ({ target: { value, name } }) => {
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value,
                errorText: name === 'email' ? emailError(value) : passError(value)
            }
        });
    };

    const onSubmitHandler = (evt) => {
        evt.preventDefault();
        const emailValidateMessage = emailError(formData.email.value);
        const passValidateMessage = passError(formData.password.value);
        if (emailValidateMessage || passValidateMessage) {
            setFormData({
                ...formData,
                email: {
                    ...formData.email,
                    errorText: emailValidateMessage
                },
                password: {
                    ...formData.password,
                    errorText: passValidateMessage
                }
            });
            return;
        }
        onSuccess(formData.password.value);
    };

    const isFormTitle = formTitle ? <h2 className="change-pass-form__title">{formTitle}</h2> : null;

    return (
        <form className="change-pass-form" onSubmit={onSubmitHandler}>
            {isFormTitle}
            <TextInput
                name="email"
                label="Email"
                type="email"
                disabled={true}
                value={formData.email.value}
                error={!!formData.email.errorText}
                helperText={formData.email.errorText}
                onChange={handleChange}
            />
            <TextInput
                name="password"
                label="Password"
                type="password"
                value={formData.password.value}
                error={!!formData.password.errorText}
                helperText={formData.password.errorText}
                onChange={handleChange}
            />
            <div className="change-pass-form__buttons-row">
                <ButtonComponent value="Cancel" type="bordered" onClick={onCancelClick} />
                <ButtonComponent
                    value="Submit"
                    onClick={onSubmitHandler}
                    loading={loading}
                    disabled={!!formData.email.errorText || !!formData.password.errorText}
                />
            </div>
        </form>
    );
}

ChangePasswordForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    formTitle: PropTypes.string,
    defaultEmail: PropTypes.string
};

export default ChangePasswordForm;
