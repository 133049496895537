import { call, put, fork, takeEvery, select, delay, takeLatest } from 'redux-saga/effects';
import {
    getStaffAbsenceSuccess,
    getStaffAbsenceFailed,
    getStaffAbsenceRequest,
    onAbsenseFilterFalse
} from '../../../actions/admin-view/staff-absence';
import * as staffScheduleActionTypes from '../../../actions/admin-view/staff-absence/action-types';
import api from '../../../../services/api';
import { selectItemsPerPage, selectFilters } from './selectors';

function* onGetStaffAbsenceRequest({ payload, filters }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page } = payload;
    const searchFilters = filters ? filters : {};

    try {
        const { data } = yield call(api.request, {
            url: `/staff/${payload.staffId}/absence/`,
            method: 'post',
            payload: {
                filters: searchFilters,
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });

        yield put(
            getStaffAbsenceSuccess({
                data,
                totalData: data.count
            })
        );
    } catch (err) {
        yield put(getStaffAbsenceFailed());
    }
}

function* onFilterChange({ payload: { value, staffId }, page }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field.length > 0);
    if (value.length > 2) {
        yield delay(1000);
        yield put(getStaffAbsenceRequest({ page, staffId }, filters));
    } else if (value.length === 0 && isFilled) {
        yield put(getStaffAbsenceRequest({ page, staffId }, filters));
    } else if (value.length === 0 && !isFilled && page !== 1) {
        yield put(onAbsenseFilterFalse());
    } else if (value.length === 0 && !isFilled && page === 1) {
        yield put(getStaffAbsenceRequest({ page, staffId }, filters));
        yield put(onAbsenseFilterFalse());
    }
}

function* watchAbsenseFilterchange() {
    yield takeLatest(staffScheduleActionTypes.STAFF_ABSENCE_FILTER_CHANGE, onFilterChange);
}

function* watchGetStaffAbsenceRequest() {
    yield takeEvery(staffScheduleActionTypes.GET_STAFF_ABSENCE_REQUEST, onGetStaffAbsenceRequest);
}

const staffAbsenceSaga = [fork(watchGetStaffAbsenceRequest), fork(watchAbsenseFilterchange)];

export default staffAbsenceSaga;
