import { PharmacyProfile } from './PharmacyProfile';
import { connect } from 'react-redux';
import {
    postPersonalInfoRequest,
    getPersonalInfoRequest,
    removeUploadedFileRequest
} from '../../../../../store/actions';

export default connect(
    (state) => ({
        removeFileLoading: state.userProfileInfo.loading,
        userEmail: state.userProfileInfo.personalInfo.data.email
    }),
    {
        postPersonalInfoRequest,
        getPersonalInfoRequest,
        removeUploadedFileRequest
    }
)(PharmacyProfile);
