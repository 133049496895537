import { App } from './App';
import { connect } from 'react-redux';
import { authUserRequest } from '../../store/actions';

export default connect(
    (state) => ({
        isAuth: state.auth.isAuth,
        registerProcess: state.auth.registerProcess,
        loader: state.loader
    }),
    {
        authUserRequest
    }
)(App);
