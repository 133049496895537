import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import PageNav from '../../../../components/page-nav';
import TableComponent from '../../../../components/table';
import { RouterConfig } from '../../../../routerConfig';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from 'query-string';
import {
    getPostedJobsRequest,
    onJobsFilterChange,
    setJobStatusRequest,
    onJobLocumRateRequest,
    onEditJobLocumRateRequest,
    getCSVJobsDownloadRequest,
    getCSVJobsDownloadSuccess,
    getMinAndMaxJobRequest
} from '../../../../store/actions';
import { usePage } from '../../../../services/hooks';
import ButtonComponent from '../../../../components/UI/buttons/button';
import StarsRating from '../../../../components/stars-rating';
import { JOB, JOB_STATUS_TO_ACTION, JOB_STATUS_TO_NEXT_STATE } from '../../../../services/constants';
import { Modal } from 'semantic-ui-react';
import { Icon, Button } from 'semantic-ui-react';
import LocumShiftsDownloadModal from './locum-shifts-download-modal';

import './style.scss';

function PostedJobsPage({ location: { pathname, search }, history }) {
    const page = usePage({ defaultTab: 'all' });
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [noDataOpen, setNoDataOpen] = useState(false);
    const [defaultDates, setDefaultDates] = useState({
        dateFrom: '',
        dateTo: ''
    });

    const currentParams = useMemo(() => {
        return queryString.parse(search);
    }, [search]);

    const { data, totalData, itemsPerPage, filters } = useSelector((state) => state.postedJobsPage.postedJobsPage);

    const { file } = useSelector((state) => state.postedJobsPage.locumShiftsDownloadModal);

    const { minJob, maxJob } = useSelector((state) => state.postedJobsPage.minAndMaxJobs);

    const dispatch = useDispatch();

    const onRate = useCallback(
        ({ value, job }) => {
            if (job.ratingId) {
                dispatch(onEditJobLocumRateRequest({ ratingId: job.ratingId, value }));
            } else {
                dispatch(onJobLocumRateRequest({ value, jobId: job.id }));
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!filters.tab) return;
        dispatch(getMinAndMaxJobRequest(filters.tab));
    }, [filters.tab, dispatch]);

    useEffect(() => {
        if (!minJob || !maxJob) {
            return;
        }
        setDefaultDates({
            dateFrom: minJob.startDate,
            dateTo: maxJob.endDate
        });
    }, [minJob, maxJob]);

    useEffect(() => {
        if (file) {
            let fetchDataModified = `data:application/csv;base64,${file}`;
            let a = document.createElement('a');
            a.href = fetchDataModified;
            a.download = 'doc.csv';
            a.click();
            setNoDataOpen(false);
            return;
        } else if (file === '') {
            setNoDataOpen(true);
            return;
        }
        if (noDataOpen) {
            setNoDataOpen(false);
        }
    }, [file, noDataOpen]);

    useEffect(() => {
        const filter = {
            ...currentParams
        };

        if (currentParams.dateFrom) {
            filter.startDate = moment(new Date(Number(currentParams.dateFrom))).format('YYYY-MM-DD');
            delete filter.dateFrom;
        }

        if (currentParams.dateTo) {
            filter.endDate = moment(new Date(Number(currentParams.dateTo))).format('YYYY-MM-DD');
            delete filter.dateTo;
        }

        dispatch(onJobsFilterChange(filter, Number(currentParams.page) || 1));
    }, [currentParams, dispatch]);

    const handleDownloadCSV = useCallback(
        (filters) => {
            dispatch(getCSVJobsDownloadRequest({ ...filters, tab: filters.tab }));
        },
        [dispatch]
    );

    if (!data) {
        return null;
    }

    const renderJobStatusAction = (item) => {
        switch (item.status) {
            // case JOB.ACTIVE:
            case JOB.COMPLETED:
            case JOB.PAYMENT_APPROVED:
                return (
                    <ButtonComponent
                        value={JOB_STATUS_TO_ACTION[item.status]}
                        type="statusUpdateButton"
                        size="medium"
                        width="medium"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(
                                setJobStatusRequest({
                                    status: JOB_STATUS_TO_NEXT_STATE[item.status],
                                    jobId: item.id,
                                    callback() {
                                        dispatch(getPostedJobsRequest({ page }, filters));
                                    }
                                })
                            );
                        }}
                    />
                );
            case JOB.PAID:
                return (
                    <StarsRating
                        value={item.ratingValue || 0}
                        onRate={(value) => onRate({ value, job: item })}
                        starSize={20}
                        disabled={false}
                    />
                );
            default:
                return null;
        }
    };

    const formatJobStatus = (status) => {
        const result = status.split('_');
        const first = result[0].charAt(0).toUpperCase() + result[0].slice(1);
        const second = result[1] ? ` ${result[1].charAt(0).toUpperCase() + result[1].slice(1)}` : '';
        return first + second;
    };

    const renderJobStatusDropDown = () => {
        return Object.keys(JOB).map((status) => ({
            key: JOB[status],
            value: JOB[status],
            text: formatJobStatus(JOB[status])
        }));
    };

    const updateQueryParams = (syntheticEvent, eventObj) => {
        const { name, value } = !eventObj ? syntheticEvent.target : eventObj;
        const parsedQueryParamsObj = queryString.parse(search);
        if (value) {
            if (eventObj.isDatepicker && Array.isArray(value)) {
                parsedQueryParamsObj['dateFrom'] = new Date(value[0]).getTime();
                if (value.length > 1) parsedQueryParamsObj['dateTo'] = new Date(value[1]).getTime();
            } else {
                parsedQueryParamsObj[name] = value;
            }
        } else {
            if (eventObj.isDatepicker) {
                delete parsedQueryParamsObj['dateFrom'];
                delete parsedQueryParamsObj['dateTo'];
            } else {
                delete parsedQueryParamsObj[name];
            }
        }
        parsedQueryParamsObj['page'] = 1;

        history.replace(`${pathname}?${queryString.stringify(parsedQueryParamsObj)}`);
    };

    const mileageSpecified = (job) => {
        return job.mileageRuleId || job.mileageValue;
    };

    const handleCloseNoData = () => {
        setNoDataOpen(false);
        dispatch(getCSVJobsDownloadSuccess(null));
    };

    const expensesSpecified = (job) => {
        return (
            job.accommodationAndFoodRuleId ||
            job.parkingRuleId ||
            job.transportRuleId ||
            job.accommodationValue ||
            job.transportValue ||
            job.parkingValue
        );
    };

    const tableCols = [
        {
            name: 'id',
            title: 'job id',
            value: filters.id,
            render: (item) => item.id,
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: updateQueryParams
        },
        {
            name: 'dateRange',
            title: 'Dates',
            className: 'class-postedJob-dates',
            value: filters.dateRange ? filters.dateRange.map((date) => new Date(date)) : '',
            render: (item) => {
                return (
                    <>
                        <span className="class-postedJob-locumName">
                            {moment(item.startDate).format('MMM Do, YYYY')}
                        </span>
                        <br />
                        <div>
                            <span className="time-color">
                                {item.startTime} - {item.endTime}
                            </span>
                            <span className="ml-1 time-color">
                                {item.paidLunch ? <Icon name="clock outline" /> : null}
                                {mileageSpecified(item) ? <Icon name="map outline" /> : null}
                                {expensesSpecified(item) ? <Icon name="pound sign" /> : null}
                            </span>
                        </div>
                    </>
                );
            },
            type: 'range',
            onChange: updateQueryParams
        },
        {
            name: 'branchTitle',
            title: 'branch',
            value: filters.branchTitle,
            render: (item) => item.branchTitle,
            type: 'search',
            onChange: updateQueryParams
        },
        {
            name: 'totalDailyRate',
            title: 'Total Daily Rate',
            value: filters.totalDailyRate,
            render: (item) => {
                return (
                    <>
                        <span className="class-postedJob-locumName">£{item.total || 0}</span>
                        <br />
                        <span className="time-color">£{item.hourlyRate}</span>
                    </>
                );
            },
            className: 'class-postedJob-hourlyRate',
            type: 'search',
            onChange: updateQueryParams
        },
        {
            name: 'locumName',
            title: 'locum',
            value: filters.locumName,
            render: (item) => {
                return (
                    <>
                        {item.locumName && <span className="class-postedJob-locumName">{item.locumName}</span>}
                        <br />
                        {item.gphcNumber && (
                            <span className="class-postedJob-gphcId">
                                GPhC: <b className="bold-text">{item.gphcNumber}</b>
                            </span>
                        )}
                    </>
                );
            },
            type: 'search',
            onChange: updateQueryParams
        },
        {
            name: 'status',
            title: 'status',
            value: filters.status || '',
            options: renderJobStatusDropDown(),
            render: (item) => {
                let message = formatJobStatus(item.status);
                if (JOB.PENDING === item.status) {
                    message += ` - ${item.applicationsCount}`;
                }
                return message;
            },
            className: 'class-postedJob-status',
            search: true,
            icon: 'search',
            type: 'select',
            onChange: updateQueryParams
        },
        {
            name: 'updateJobStatus',
            title: 'Action',
            value: filters.status,
            render: (item) => {
                return renderJobStatusAction(item);
            },
            type: 'none',
            onChange: updateQueryParams
        }
    ];

    const tabs = [
        {
            title: 'All shifts',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=all`);
            },
            isActive: filters.tab === 'all'
        },
        {
            title: 'Upcoming',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=upcoming`);
            },
            isActive: filters.tab === 'upcoming'
        },
        {
            title: 'Past',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=past`);
            },
            isActive: filters.tab === 'past'
        }
    ];

    return (
        <div className="posted-jobs-admin-page">
            <div className="main-container">
                <div className="header-panel mt-1">
                    <PageNav title="Locum Shifts" tabs={tabs} noMargin />
                    <div>
                        <Button
                            disabled={!data.rows || !data.rows.length}
                            onClick={() => setDownloadModalOpen(true)}
                            basic
                        >
                            <Icon name="download" /> Download .csv
                        </Button>
                    </div>
                </div>
                <TableComponent
                    cols={tableCols}
                    data={data.rows || []}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                    preserveQueryParams={true}
                    getItem
                    format="DD/MM"
                    openInfo={(item) => {
                        const path = generatePath(RouterConfig.branchAdminJobPreview, {
                            id: item.branchId,
                            jobId: item.id
                        });
                        history.push(path + search);
                    }}
                />

                <Modal size="mini" centered={true} onClose={handleCloseNoData} open={noDataOpen}>
                    <Modal.Content>
                        <Modal.Description>
                            There are no shifts that meet the applied filters. Please adjust values in the filters and
                            try again!
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Ok" onClick={handleCloseNoData} color="blue" />
                    </Modal.Actions>
                </Modal>
                <LocumShiftsDownloadModal
                    startDate={defaultDates.dateFrom}
                    endDate={defaultDates.dateTo}
                    onDownload={handleDownloadCSV}
                    open={downloadModalOpen}
                    onClose={() => setDownloadModalOpen(false)}
                    tab={currentParams.tab || 'all'}
                />
            </div>
        </div>
    );
}

export default PostedJobsPage;
