import {
    CLEAR_LOCUM_BANK_FILTERS,
    ADD_LOCUM_BANK_REQUEST,
    ADD_LOCUM_BANK_SUCCESS,
    ADD_LOCUM_BANK_FAILED,
    GET_LOCUM_BANK_BY_FILTER_REQUEST,
    GET_LOCUM_BANK_BY_FILTER_FAILED,
    GET_LOCUM_BANK_BY_FILTER_SUCCESS,
    ON_LOCUM_BANK_FILTER_CHANGE
} from './action-types';

export const getLocumBankByFilterRequest = ({ pagination, filters }) => ({
    type: GET_LOCUM_BANK_BY_FILTER_REQUEST,
    pagination,
    filters
});

export const getLocumBankByFilterSuccess = (payload) => ({
    type: GET_LOCUM_BANK_BY_FILTER_SUCCESS,
    payload
});

export const getLocumBankByFilterFailed = () => ({
    type: GET_LOCUM_BANK_BY_FILTER_FAILED
});

export const onLocumBankFilterChange = (payload, page) => ({
    type: ON_LOCUM_BANK_FILTER_CHANGE,
    payload,
    page
});

export const addLocumBankRequest = (payload) => ({
    type: ADD_LOCUM_BANK_REQUEST,
    payload
});

export const addLocumBankSuccess = (payload) => ({
    type: ADD_LOCUM_BANK_SUCCESS,
    payload
});

export const addLocumBankFailed = () => ({
    type: ADD_LOCUM_BANK_FAILED
});

export const clearLocumBankFilters = () => ({
    type: CLEAR_LOCUM_BANK_FILTERS
});
