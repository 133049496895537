import React from 'react';
import PropTypes from 'prop-types';

import { Step, Icon } from 'semantic-ui-react';

import './style.scss';

const OnboardingSteps = ({ steps = [] }) => {
    const getSteps = steps.map((step) => {
        return (
            <Step
                key={step.title}
                className={`${
                    step.status === 'moderation'
                        ? 'moderation'
                        : step.status === 'completed'
                        ? 'completed'
                        : step.status === 'active'
                        ? 'active'
                        : ''
                } onboarding-step`}
            >
                <Step.Content>
                    <Step.Title className="onboarding-step__title">
                        {step.title}
                        <Icon
                            name={
                                step.status === 'moderation'
                                    ? 'clock outline'
                                    : step.status === 'completed'
                                    ? 'check'
                                    : ''
                            }
                        />
                    </Step.Title>
                    <Step.Description className="onboarding-step__description">{step.description}</Step.Description>
                </Step.Content>
            </Step>
        );
    });
    return (
        <Step.Group className="onboarding-steps-group" size="mini">
            {getSteps}
        </Step.Group>
    );
};

OnboardingSteps.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            status: PropTypes.string
        }).isRequired
    )
};

export default OnboardingSteps;
