import ReactPixel from 'react-facebook-pixel';
import { call, put, fork, takeEvery, takeLeading } from 'redux-saga/effects';
import {
    authSuccess,
    authFailed,
    logInSuccess,
    logInFailed,
    resetPassSuccess,
    resetPassFailed,
    forgotPassSuccess,
    forgotPassFailed,
    profileResetPassFailed,
    profileResetPassSuccess
} from '../../../actions';
import * as authActionTypes from '../../../actions/auth/action-types';
import * as loginActionTypes from '../../../actions/login-page/action-types';
import api from '../../../../services/api';
import cookies from 'browser-cookies';

function* authUser() {
    try {
        if (cookies.get('accessToken')) {
            const { data } = yield call(api.request, {
                url: '/profile/short',
                method: 'get'
            });
            yield put(authSuccess(data));
        } else {
            yield put({
                type: authActionTypes.AUTH_USER_FALSE
            });
        }
    } catch (err) {
        yield put(authFailed(new Error('not authenticated')));
    }
}

function* userLogout() {
    try {
        yield call(api.request, {
            url: '/auth/logout',
            method: 'post'
        });
        cookies.erase('accessToken');
        cookies.erase('refreshToken');
    } catch (err) {
        yield put(authFailed(new Error('not authenticated')));
    }
}

function* userLogin({ payload: { email, password } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/auth/login',
            method: 'post',
            payload: { email, password }
        });
        api.setAccessToken(data.access);
        api.setRefreshToken(data.refresh);
        yield put(authSuccess(data));
        ReactPixel.trackCustom('login');
        yield put(logInSuccess(data));
    } catch (err) {
        yield put(logInFailed());
    }
}

function* userForgotPassRequest({ payload: { email, toggleModal } }) {
    try {
        yield call(api.request, {
            url: '/auth/request-password-reset',
            method: 'post',
            payload: { email }
        });
        yield put(forgotPassSuccess());
        toggleModal();
    } catch (err) {
        yield put(forgotPassFailed());
    }
}

function* userProfilePasswordResetRequest({ payload: { password, toggleModal } }) {
    try {
        yield call(api.request, {
            url: '/auth/password-reset',
            method: 'post',
            payload: { password }
        });
        yield put(profileResetPassSuccess());
        toggleModal();
    } catch (err) {
        yield put(profileResetPassFailed());
    }
}

function* userResetPassRequest({ payload: { token, password, toggleModal } }) {
    try {
        yield call(api.request, {
            url: `/auth/password-reset/${token}`,
            method: 'post',
            payload: { password }
        });
        yield put(resetPassSuccess());
        toggleModal();
    } catch (err) {
        yield put(resetPassFailed());
    }
}

// watchers
function* watchAuthUserRequest() {
    yield takeEvery(authActionTypes.AUTH_USER, authUser);
}

function* watchUserLogout() {
    yield takeLeading(authActionTypes.LOGOUT, userLogout);
}

function* watchUserLogin() {
    yield takeEvery(loginActionTypes.LOG_IN_SUMBIT, userLogin);
}

function* watchUserForgotPass() {
    yield takeEvery(loginActionTypes.FORGOT_PASS_SUBMIT, userForgotPassRequest);
}

function* watchUserResetPass() {
    yield takeEvery(loginActionTypes.RESET_PASS_SUBMIT, userResetPassRequest);
}
function* watchUserProfilePassReset() {
    yield takeEvery(loginActionTypes.PROFILE_RESET_PASS_SUBMIT, userProfilePasswordResetRequest);
}

const authSaga = [
    fork(watchAuthUserRequest),
    fork(watchUserLogout),
    fork(watchUserLogin),
    fork(watchUserForgotPass),
    fork(watchUserResetPass),
    fork(watchUserProfilePassReset)
];

export default authSaga;
