import {
    GET_REGIONS_LIST_REQUEST,
    GET_REGIONS_LIST_SUCCESS,
    GET_REGIONS_LIST_FAILED,
    ON_SUBMIT_REGION_REQUEST,
    ON_SUBMIT_REGION_SUCCESS,
    ON_SUBMIT_REGION_FAILED
} from './action-types';

export const getRegionsListRequest = (payload) => ({
    type: GET_REGIONS_LIST_REQUEST,
    payload
});

export const getRegionsListSuccess = (payload) => ({
    type: GET_REGIONS_LIST_SUCCESS,
    payload
});

export const getRegionsListFailed = () => ({
    type: GET_REGIONS_LIST_FAILED
});

export const onSubmitRegionRequest = (payload) => ({
    type: ON_SUBMIT_REGION_REQUEST,
    payload
});

export const onSubmitRegionSuccess = () => ({
    type: ON_SUBMIT_REGION_SUCCESS
});

export const onSubmitRegionFailed = () => ({
    type: ON_SUBMIT_REGION_FAILED
});
