import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

import './style.scss';

const LocalLoader = ({ size = 'large', text, color }) => {
    return (
        <Dimmer active className={`local-loader ${color}`}>
            <Loader size={size} indeterminate>
                {text}
            </Loader>
        </Dimmer>
    );
};

LocalLoader.propTypes = {
    size: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string
};

export default LocalLoader;
