import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'semantic-ui-react';

import classnames from 'classnames';

import './style.scss';

const RadioGroup = ({ items, groupLabel, hidden }) => {
    return (
        <div className={classnames('radio-group', { hidden })}>
            <label className="group-label">{groupLabel}</label>
            <div className="radio-group__inner">
                {items.map((item) => {
                    return (
                        <Radio
                            key={item.id}
                            label={item.label}
                            name={item.name}
                            value={item.value}
                            checked={item.checked}
                            onChange={item.onChange}
                        />
                    );
                })}
            </div>
        </div>
    );
};

RadioGroup.propTypes = {
    items: PropTypes.array.isRequired,
    groupLabel: PropTypes.string,
    hidden: PropTypes.bool
};

export default RadioGroup;
