export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_TRUE = 'AUTH_USER:TRUE';
export const AUTH_USER_FALSE = 'AUTH_USER:FALSE';

export const REGISTER_PROCESS_TRUE = 'REGISTER_PROCESS_TRUE';
export const REGISTER_PROCESS_FALSE = 'REGISTER_PROCESS_FALSE';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const LOGOUT = 'LOGOUT';
