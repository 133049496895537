import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonComponent from '../../../UI/buttons/button';
import UploadFileHandler from '../../../upload-remove-file-handler';
import CheckboxListContainer from '../../../../containers/checkbox-list';

import { getSize, getExtention, isOnboardingFileUploaded } from '../../../../services/helpers';

import notificationSettings from '../../../../services/notificationSettings';
import { store } from 'react-notifications-component';
import { Form } from 'semantic-ui-react';

import './style.scss';

export function AccreditationsForm({
    setStoreDocument,
    removeStoreDocument,
    onRemoveAccreditationsFile,
    accreditations: {
        medUseReview,
        newMedService,
        repeatDispensing,
        dementiaFriend,
        CPPERisk,
        fluJabTrained,
        SCR,
        consultationSkills,
        substanceMisuse,
        CPPEOralHelth,
        SDVALevel2,
        EHC,
        uploadedFiles,
        errors
    },
    additionalAccreditations,
    previouslySelectedAccreditations,
    loading,
    removingLoading,
    onGoBack,
    onSuccess,
    setAccreditationsCheckbox,
    isEditProfile,
    ...props
}) {
    const [accreditationsOptions, setAccreditations] = useState([]);

    // TODO: rewrite this part
    const refs = {
        medUseReview: useRef(),
        newMedService: useRef(),
        repeatDispensing: useRef(),
        dementiaFriend: useRef(),
        CPPERisk: useRef(),
        fluJabTrained: useRef(),
        SCR: useRef(),
        consultationSkills: useRef(),
        substanceMisuse: useRef(),
        CPPEOralHelth: useRef(),
        SDVALevel2: useRef(),
        EHC: useRef(),
        uploadedFiles: useRef()
    };

    useEffect(() => {
        let getAccreditations = [];
        if (additionalAccreditations) {
            getAccreditations = additionalAccreditations.map((item) => {
                return {
                    ...item,
                    checked: false
                };
            });
        }
        if (previouslySelectedAccreditations && previouslySelectedAccreditations.length) {
            getAccreditations = additionalAccreditations.flatMap((accreditation) => [
                {
                    ...accreditation,
                    checked: !!previouslySelectedAccreditations.find((item) => item.id === accreditation.id)
                }
            ]);
        }

        setAccreditations(getAccreditations);
    }, [additionalAccreditations, previouslySelectedAccreditations]);

    const triggerInputFile = (name) => {
        for (let key in refs) {
            if (key === name) refs[key].current.click();
        }
    };

    const onFileUpload = useCallback(
        (e) => {
            const name = e.target.name;
            const value = e.target.value;
            const file = e.target.files[0];
            let errorMessage = {};
            let error = null;

            // validate uploaded file
            errorMessage.extention = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.pdf']);
            errorMessage.size = getSize(file);

            // display error message
            Object.values(errorMessage).some((err) => (!!err ? (error = err) : false));

            if (error) {
                store.addNotification({
                    ...notificationSettings.settings,
                    title: 'Warning',
                    message: error,
                    type: 'warning'
                });
            }

            setStoreDocument({ name, value, file, error });
        },
        [setStoreDocument]
    );

    const onFileRemoveHandler = useCallback(
        (name) => {
            const isUploaded = isOnboardingFileUploaded(name, uploadedFiles);

            if (isUploaded) {
                onRemoveAccreditationsFile({
                    profileType: 'profileAccreditations',
                    fileName: name
                });
            }
            removeStoreDocument(name);
        },
        [onRemoveAccreditationsFile, removeStoreDocument, uploadedFiles]
    );

    const onCheckboxChange = (e, { name }) => {
        let checkBoxID = name.split('#ID=')[1];

        const selectedOptions = accreditationsOptions.map((el) => {
            if (+checkBoxID === el.id) {
                el.checked = !el.checked;
            }
            return el;
        });

        const getSelectedIds = selectedOptions.filter(({ checked, id }) => (checked ? id : false)).map((el) => el.id);

        setAccreditations(selectedOptions);
        setAccreditationsCheckbox(getSelectedIds);
    };

    const checkboxes = accreditationsOptions
        ? accreditationsOptions.map((item) => {
              return {
                  id: item.id,
                  label: item.title,
                  name: `${item.title}#ID=${item.id}`,
                  checked: item.checked,
                  onChange: onCheckboxChange
              };
          })
        : [];

    let heading,
        subHeading = null;

    if (isEditProfile) {
        heading = <h3 className="staff-info-page__title">Personal Information</h3>;
        subHeading = <h4 className="row-title">Qualification</h4>;
    }

    return (
        <Form className={classnames('accreditations-form', { isEditProfile })} autoComplete="off" loading={loading}>
            <div className="accreditations-form__row">
                <div className="col">
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Medicine Use Review MUR</label>
                        <UploadFileHandler
                            inputName="medUseReview"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={medUseReview}
                            uploadedFile={uploadedFiles.medUseReview}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(medUseReview) => (refs.medUseReview.current = medUseReview)}
                            triggerInput={() => triggerInputFile('medUseReview')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">New Medicine Service</label>
                        <UploadFileHandler
                            inputName="newMedService"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={newMedService}
                            uploadedFile={uploadedFiles.newMedService}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(newMedService) => (refs.newMedService.current = newMedService)}
                            triggerInput={() => triggerInputFile('newMedService')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Repeat dispensing</label>
                        <UploadFileHandler
                            inputName="repeatDispensing"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={repeatDispensing}
                            uploadedFile={uploadedFiles.repeatDispensing}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(repeatDispensing) => (refs.repeatDispensing.current = repeatDispensing)}
                            triggerInput={() => triggerInputFile('repeatDispensing')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Registered as a dementia friend</label>
                        <UploadFileHandler
                            inputName="dementiaFriend"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={dementiaFriend}
                            uploadedFile={uploadedFiles.dementiaFriend}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(dementiaFriend) => (refs.dementiaFriend.current = dementiaFriend)}
                            triggerInput={() => triggerInputFile('dementiaFriend')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">EHC OTC</label>
                        <UploadFileHandler
                            inputName="EHC"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={EHC}
                            uploadedFile={uploadedFiles.EHC}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(EHC) => (refs.EHC.current = EHC)}
                            triggerInput={() => triggerInputFile('EHC')}
                            loading={removingLoading}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Flu jab Trained</label>
                        <UploadFileHandler
                            inputName="fluJabTrained"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={fluJabTrained}
                            uploadedFile={uploadedFiles.fluJabTrained}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(fluJabTrained) => (refs.fluJabTrained.current = fluJabTrained)}
                            triggerInput={() => triggerInputFile('fluJabTrained')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Summary Care Record (SCR)</label>
                        <UploadFileHandler
                            inputName="SCR"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={SCR}
                            uploadedFile={uploadedFiles.SCR}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(SCR) => (refs.SCR.current = SCR)}
                            triggerInput={() => triggerInputFile('SCR')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Pharmacy consultation skills</label>
                        <UploadFileHandler
                            inputName="consultationSkills"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={consultationSkills}
                            uploadedFile={uploadedFiles.consultationSkills}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(consultationSkills) => (refs.consultationSkills.current = consultationSkills)}
                            triggerInput={() => triggerInputFile('consultationSkills')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Substance Misuse</label>
                        <UploadFileHandler
                            inputName="substanceMisuse"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={substanceMisuse}
                            uploadedFile={uploadedFiles.substanceMisuse}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(substanceMisuse) => (refs.substanceMisuse.current = substanceMisuse)}
                            triggerInput={() => triggerInputFile('substanceMisuse')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">CPPE Risk Management</label>
                        <UploadFileHandler
                            inputName="CPPERisk"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={CPPERisk}
                            uploadedFile={uploadedFiles.CPPERisk}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(CPPERisk) => (refs.CPPERisk.current = CPPERisk)}
                            triggerInput={() => triggerInputFile('CPPERisk')}
                            loading={removingLoading}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">CPPE Children’s Oral Health</label>
                        <UploadFileHandler
                            inputName="CPPEOralHelth"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={CPPEOralHelth}
                            uploadedFile={uploadedFiles.CPPEOralHelth}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(CPPEOralHelth) => (refs.CPPEOralHelth.current = CPPEOralHelth)}
                            triggerInput={() => triggerInputFile('CPPEOralHelth')}
                            loading={removingLoading}
                        />
                    </div>
                    <div className="col__upload-inputs-row">
                        <label className="upload-label">Safeguarding children and vulnerable adults</label>
                        <UploadFileHandler
                            inputName="SDVALevel2"
                            buttonValue="Upload document"
                            acceptFormats=".jpg, .jpeg, .png, .pdf"
                            stateProp={SDVALevel2}
                            uploadedFile={uploadedFiles.SDVALevel2}
                            onChangeHandler={onFileUpload}
                            onRemoveFile={(name) => onFileRemoveHandler(name)}
                            refFunc={(SDVALevel2) => (refs.SDVALevel2.current = SDVALevel2)}
                            triggerInput={() => triggerInputFile('SDVALevel2')}
                            loading={removingLoading}
                        />
                    </div>
                    <CheckboxListContainer
                        label="Additional Accreditations"
                        items={checkboxes}
                        layout="singleColumn"
                        boxesSize="small"
                    />
                </div>
            </div>
            {subHeading}
            <div className="accreditations-form__row button-row">
                {heading}
                <div className="buttons-holder">
                    <ButtonComponent
                        value={isEditProfile ? 'Cancel' : 'Back'}
                        type="transparent"
                        onClick={onGoBack}
                        size="medium"
                        disabled={loading}
                    />
                    <ButtonComponent
                        value="Save"
                        onClick={onSuccess}
                        size="medium"
                        disabled={Object.values(errors).some((error) => !!error) || loading}
                    />
                </div>
            </div>
        </Form>
    );
}

AccreditationsForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onRemoveAccreditationsFile: PropTypes.func.isRequired,
    additionalAccreditations: PropTypes.array,
    isEditProfile: PropTypes.bool
};
