import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import LocumJobPreviewDashboard from './LocumJobPreviewDashboard';

export function LocumJobPreview({
    getLocumJobDetailsRequest,
    locumJobData,
    jobId,
    onCloseJobPreview,
    onLocumJobActionRequest
}) {
    useEffect(() => {
        getLocumJobDetailsRequest({ jobId });
    }, [getLocumJobDetailsRequest, jobId]);

    const onLocumJobAction = useCallback(
        (action) => onLocumJobActionRequest({ jobId, action }),
        [jobId, onLocumJobActionRequest]
    );

    if (!locumJobData) {
        return null;
    } else {
        return (
            <div className="locum-job-preview-page">
                <LocumJobPreviewDashboard
                    jobId={String(jobId)}
                    data={locumJobData}
                    onCloseClick={onCloseJobPreview}
                    onLocumJobAction={(action) => onLocumJobAction(action)}
                />
            </div>
        );
    }
}

LocumJobPreview.propTypes = {
    jobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    getLocumJobDetailsRequest: PropTypes.func.isRequired,
    locumJobData: PropTypes.object,
    onCloseJobPreview: PropTypes.func.isRequired,
    onLocumJobActionRequest: PropTypes.func.isRequired
};
