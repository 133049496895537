import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TableComponent from '../../../../../../components/table';
import PageNav from '../../../../../../components/page-nav';

import { getPharmacyBranchesRequest } from '../../../../../../store/actions';
import { usePage } from '../../../../../../services/hooks';

const PharmacyBranchesList = ({
    pharmacyBranches: { data, itemsPerPage, totalData },
    getPharmacyBranchesRequest,
    match: {
        params: { id }
    }
}) => {
    const page = usePage();

    useEffect(() => {
        if (!page) return;
        getPharmacyBranchesRequest({ page, id });
    }, [getPharmacyBranchesRequest, page, id]);

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'id',
                title: 'branch id',
                render: (item) => item.id,
                type: 'disabled'
            },
            {
                name: 'title',
                title: 'Title',
                render: (item) => item.title,
                type: 'disabled'
            },
            {
                name: 'address',
                title: 'address',
                render: (item) => item.address,
                type: 'disabled'
            },
            {
                name: 'phone',
                title: 'phone',
                render: (item) => item.phone,
                type: 'disabled'
            },
            {
                name: 'status',
                title: 'status',
                render: (item) => (item.active ? 'Active' : 'Inactive'),
                type: 'disabled'
            }
        ];

        return (
            <div className="admin-pharmacies-page">
                <PageNav title="Branches" />
                <TableComponent
                    cols={tableCols}
                    data={data.rows}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    pharmacyBranches: state.adminPharmacyBranchesPage
});

const mapDispatchToProps = {
    getPharmacyBranchesRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyBranchesList);
