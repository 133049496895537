import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import './style.scss';

const TextInput = React.forwardRef(
    (
        {
            name,
            label,
            type = 'text',
            value,
            fluid = true,
            loading,
            onChange,
            error,
            autoComplete = 'nope',
            helperText,
            disabled,
            placeholder,
            icon,
            hidden,
            iconPosition,
            className = ''
        },
        ref
    ) => {
        return (
            <div
                className={`${classnames('input-group', className, {
                    fluid,
                    hidden
                })}`}
            >
                <label>{label}</label>
                <Input
                    ref={ref}
                    name={name}
                    type={type}
                    onChange={onChange}
                    error={error}
                    autoComplete={autoComplete}
                    value={value}
                    icon={icon}
                    loading={loading}
                    disabled={disabled}
                    placeholder={placeholder}
                    iconPosition={iconPosition}
                />
                <p className="helper-text">{helperText}</p>
            </div>
        );
    }
);

TextInput.defaultProps = {
    value: ''
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
    fluid: PropTypes.bool,
    error: PropTypes.bool,
    autoComplete: PropTypes.string,
    helperText: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    icon: PropTypes.string,
    hidden: PropTypes.bool
};

export default TextInput;
