import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getPharmacyAllBranchesFailed,
    getPharmacyAllBranchesSuccess,
    getPharmacySubscriptionSuccess,
    getPharmacySubscriptionFailed,
    getPharmacyInvoicesFailed,
    getPharmacyInvoicesSuccess,
    getPharmacyBranchDeactivateSuccess,
    getPharmacyBranchDeactivateFailed,
    putPharmacyUpdateSubscriptionsFailed,
    putPharmacyUpdateSubscriptionsSuccess
} from '../../../actions';
import * as pharmacyBillingActionTypes from '../../../actions/super-admin-view/pharmacy-billing-page/action-types';
import api from '../../../../services/api';

function* onPharmacyBranchesListRequest({ pharmacyId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${pharmacyId}/branches`,
            method: 'get'
        });
        yield put(
            getPharmacyAllBranchesSuccess({
                data
            })
        );
    } catch (err) {
        yield put(getPharmacyAllBranchesFailed());
    }
}
function* onPharmacySubscriptionGetRequest({ pharmacyId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${pharmacyId}/get-subscription`,
            method: 'get'
        });
        yield put(
            getPharmacySubscriptionSuccess({
                data
            })
        );
    } catch (err) {
        yield put(getPharmacySubscriptionFailed());
    }
}

function* onPharmacyInvoicesRequest({ payload: { page, perPage, id } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${id}/invoices`,
            method: 'POST',
            payload: {
                pagination: {
                    page,
                    perPage
                }
            }
        });
        yield put(getPharmacyInvoicesSuccess(data));
    } catch (err) {
        yield put(getPharmacyInvoicesFailed());
    }
}
function* onPharmacyBranchDeactivateRequest({ payload: { branchId, id, cb } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${id}/branch/${branchId}/deactivate`,
            method: 'POST'
        });
        yield put(getPharmacyBranchDeactivateSuccess(data));
        cb();
    } catch (err) {
        yield put(getPharmacyBranchDeactivateFailed());
    }
}

function* onPharmacyUpdateSubscriptionsRequest({ payload: { id, branches, cb } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${id}/update-subscriptions`,
            method: 'PUT',
            payload: {
                branches
            }
        });
        yield put(putPharmacyUpdateSubscriptionsSuccess(data));
        cb();
    } catch (err) {
        yield put(putPharmacyUpdateSubscriptionsFailed());
    }
}

function* watchPharmacyBranchesListRequest() {
    yield takeEvery(pharmacyBillingActionTypes.GET_PHARMACY_ALL_BRANCHES_REQUEST, onPharmacyBranchesListRequest);
}

function* watchPharmacyInvoicesRequest() {
    yield takeEvery(pharmacyBillingActionTypes.GET_PHARMACY_INVOICES_REQUEST, onPharmacyInvoicesRequest);
}
function* watchPharmacySubscriptionRequest() {
    yield takeEvery(pharmacyBillingActionTypes.GET_PHARMACY_SUBSCRIPTION_REQUEST, onPharmacySubscriptionGetRequest);
}
function* watchPharmacyBranchDeactivateRequest() {
    yield takeEvery(
        pharmacyBillingActionTypes.GET_PHARMACY_BRANCH_DEACTIVATE_REQUEST,
        onPharmacyBranchDeactivateRequest
    );
}
function* watchPharmacyUpdateSubscriptionsRequest() {
    yield takeEvery(
        pharmacyBillingActionTypes.PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_REQUEST,
        onPharmacyUpdateSubscriptionsRequest
    );
}

const adminPharmacyBillingSaga = [
    fork(watchPharmacyBranchesListRequest),
    fork(watchPharmacyInvoicesRequest),
    fork(watchPharmacySubscriptionRequest),
    fork(watchPharmacyBranchDeactivateRequest),
    fork(watchPharmacyUpdateSubscriptionsRequest)
];

export default adminPharmacyBillingSaga;
