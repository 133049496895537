import {
    SHOW_BACK_BUTTON,
    HIDE_BACK_BUTTON,
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    SET_CONTAINER_WIDE,
    SET_CONTAINER_DEFAULT,
    TOGGLE_PAGE_VIEW
} from './action-types';

export const showBackButton = () => ({ type: SHOW_BACK_BUTTON });

export const hideBackButton = () => ({ type: HIDE_BACK_BUTTON });

export const showSidebar = () => ({ type: SHOW_SIDEBAR });

export const hideSidebar = () => ({ type: HIDE_SIDEBAR });

export const setContainerWide = () => ({ type: SET_CONTAINER_WIDE });

export const setContainerDefault = () => ({ type: SET_CONTAINER_DEFAULT });

export const togglePageView = (payload) => ({
    type: TOGGLE_PAGE_VIEW,
    payload
});
