import { call, put, fork, takeLeading, takeLatest, select, delay } from 'redux-saga/effects';
import {
    getPharmaciesListRequest,
    getPharmaciesListSuccess,
    getPharmaciesListFailed,
    onPharmaciesFilterFalse
} from '../../../actions';
import { selectItemsPerPage, selectFilters } from './selectors';
import * as pharmaciesListActionTypes from '../../../actions/super-admin-view/pharmacies-page/action-types';
import api from '../../../../services/api';

function* onPharmaciesListRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page } = payload;
    const filters = yield select(selectFilters);

    try {
        const { data } = yield call(api.request, {
            url: '/admin/pharmacy/list',
            method: 'post',
            payload: {
                pagination: {
                    page,
                    perPage: itemsPerPage
                },
                filters
            }
        });
        yield put(
            getPharmaciesListSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getPharmaciesListFailed());
    }
}

function* onPharmaciesFilterChange({ payload: { value }, page }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field && field.length > 0);

    if (value.length > 0) {
        yield delay(1000);
        yield put(getPharmaciesListRequest({ page }));
    } else if (value.length === 0 && isFilled) {
        yield put(getPharmaciesListRequest({ page }));
    } else if (value.length === 0 && !isFilled && page !== 1) {
        yield put(onPharmaciesFilterFalse());
    } else if (value.length === 0 && !isFilled && page === 1) {
        yield put(getPharmaciesListRequest({ page }));
        yield put(onPharmaciesFilterFalse());
    }
}

// watchers
function* watchPharmaciesListRequest() {
    yield takeLeading(pharmaciesListActionTypes.GET_PHARMACIES_LIST_REQUEST, onPharmaciesListRequest);
}

function* watchPharmaciesFilterChange() {
    yield takeLatest(pharmaciesListActionTypes.ON_PHARMACIES_FILTER_CHANGE, onPharmaciesFilterChange);
}

const adminPharmaciesSaga = [fork(watchPharmaciesListRequest), fork(watchPharmaciesFilterChange)];

export default adminPharmaciesSaga;
