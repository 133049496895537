import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from '../../components/UI/checkbox';
import classnames from 'classnames';

import './style.scss';

const CheckboxListContainer = ({ label, items, layout, boxesSize, disabled }) => {
    return (
        <div className="checkbox-list">
            <label className="checkbox-list__label">{label}</label>
            <div className={classnames('checkbox-list__container', { [layout]: layout })}>
                {items.map((item) => {
                    return (
                        <CustomCheckbox
                            key={item.id}
                            name={item.name}
                            label={item.label}
                            checked={item.checked}
                            onChange={(e, data) => {
                                if (item.disabled) return;
                                item.onChange(e, data);
                            }}
                            size={boxesSize}
                            disabled={disabled || item.disabled}
                        />
                    );
                })}
            </div>
        </div>
    );
};

CheckboxListContainer.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array.isRequired,
    layout: PropTypes.string,
    boxesSize: PropTypes.string,
    disabled: PropTypes.bool
};

export default CheckboxListContainer;
