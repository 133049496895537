import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const PageTab = ({ tab: { title, onClick, isActive } }) => {
    return (
        <button className={classnames('page-tab', { active: isActive })} onClick={onClick}>
            {title}
        </button>
    );
};

PageTab.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool
};

export default PageTab;
