import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { momentLocalizer } from 'react-big-calendar';
import { RouterConfig } from './../../../../routerConfig';

import PopupComponent from '../../../../components/popup';
import ScheduleCalendar from '../../../../components/schedule-calendar';
import Toolbar from '../../../../components/schedule-calendar/Toolbar';
import EventCell from '../../../../components/schedule-calendar/EventCell';
import ScheduleDayPreviewPopover from '../../../../components/schedule-calendar-popover';

import { getCalendarEvents } from '../../../../services/datesHelper';

import moment from 'moment';

import './style.scss';
import Switcher from '../../../../components/UI/switcher';
import TableComponent from '../../../../components/table';
import ButtonModal from '../../../../components/UI/buttons/button-modal';
import { LocumJobPreview } from '../job-offers/job-preview/LocumJobPreview';
import { usePage } from '../../../../services/hooks';
import PageNav from '../../../../components/page-nav';

moment.updateLocale('ko', {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment);
const date = new Date();
let calendarFormats = {
    weekdayFormat: 'dddd'
};

const initialForm = {
    startDate: '',
    endDate: '',
    tab: 'upcoming',
    page: 0
};

export function LocumSchedule({
    schedule: { dateRange, events },
    getStaffUserEventsRequest,
    onStaffUserEventsDateRangeChange,
    jobOffers: { data, itemsPerPage, totalData },
    getJobOffersRequest,
    getLocumJobDetailsRequest,
    onLocumJobActionRequest,
    locumJobData
}) {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const page = usePage();

    const [jobId, setJobId] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [filterForm, setFilterForm] = useState(initialForm);
    const [viewMode, setViewMode] = useState('calendar');

    useEffect(() => {
        if (!dateRange.startDateRange && !dateRange.endDateRange)
            onStaffUserEventsDateRangeChange({
                startDateRange: moment(dates.firstVisibleDay(date, localizer)),
                endDateRange: moment(dates.lastVisibleDay(date, localizer))
            });
        getStaffUserEventsRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.endDateRange, dateRange.startDateRange, onStaffUserEventsDateRangeChange]);

    useEffect(() => {
        if (!data || !data.rows) return;
        setJobs(data.rows);
    }, [data]);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const localViewMode = searchParams.get('viewMode') || 'calendar';
        setViewMode(localViewMode);

        const newFilter = {
            ...filterForm
        };
        newFilter.tab = searchParams.get('tab') || 'upcoming';
        newFilter.page = searchParams.get('page') || 0;
        if (searchParams.has('startDate')) {
            newFilter.startDate = new Date(searchParams.get('startDate'));
        } else {
            newFilter.startDate = '';
        }
        if (searchParams.has('endDate')) {
            newFilter.endDate = new Date(searchParams.get('endDate'));
        } else {
            newFilter.endDate = '';
        }

        if (localViewMode === 'calendar') return;
        setFilterForm(newFilter);
        getJobOffersRequest({
            ...newFilter,
            tab: localViewMode === 'table' ? newFilter.tab : null
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const onNavigate = useCallback(
        (date, view, action) => {
            onStaffUserEventsDateRangeChange({
                startDateRange: moment(dates.firstVisibleDay(date, localizer)),
                endDateRange: moment(dates.lastVisibleDay(date, localizer))
            });

            getStaffUserEventsRequest();
        },
        [getStaffUserEventsRequest, onStaffUserEventsDateRangeChange]
    );

    let calendarComponents = {
        toolbar: (props) => <Toolbar {...props} switcher={false} />,
        event: (props) => <EventCell {...props} />
    };

    const handleCellClick = (jobId) => {
        setJobId(jobId);
    };

    const tabs = [
        {
            title: 'Upcoming',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=upcoming&viewMode=table`);
            },
            isActive: filterForm.tab === 'upcoming'
        },
        {
            title: 'Completed',
            onClick: () => {
                history.replace(`${pathname}?page=1&tab=completed&viewMode=table`);
            },
            isActive: filterForm.tab === 'completed'
        }
    ];

    const tableCols = [
        {
            name: 'pharmacyName',
            title: 'PHARMACY',
            render: (item) => (
                <div onClick={() => handleCellClick(item.id)} style={{ display: 'flex', alignItems: 'center' }}>
                    {item.logo ? (
                        <img alt={'item-logo'} width="25px" height="25px" src={item.logo} />
                    ) : (
                        <div className="table-default-logo" />
                    )}
                    <span style={{ marginLeft: '10px' }}>{item.pharmacyName}</span>
                </div>
            ),
            type: 'disabled'
        },
        {
            name: 'city',
            title: 'CITY',
            render: (item) => (
                <div onClick={() => handleCellClick(item.id)}>
                    <div>{item.city}</div>
                </div>
            ),
            type: 'disabled'
        },
        {
            name: 'startDate',
            title: 'DATE',
            render: (item) => (
                <div onClick={() => handleCellClick(item.id)}>{moment(item.startDate).format('DD/MM/YYYY')}</div>
            ),
            type: 'disabled'
        },
        {
            name: 'shiftTime',
            title: 'TIME',
            render: (item) => {
                return (
                    <div onClick={() => handleCellClick(item.id)}>
                        {item.startTime} - {item.endTime}
                    </div>
                );
            },
            type: 'disabled'
        },
        {
            name: 'apply',
            title: 'Action',
            render: (item) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ButtonModal type="button" onClick={() => handleCellClick(item.id)} value="View" colorType="blue" />
                </div>
            ),
            type: 'disabled'
        }
    ];

    if (!events) {
        return null;
    } else {
        const getEvents = getCalendarEvents(dateRange.startDateRange, dateRange.endDateRange, events);

        const renderJobsEvents = () =>
            getEvents.map((event) => {
                const { startDate, endDate, jobs } = event;

                let dayProps = {
                    minTime: [],
                    maxTime: [],
                    jobs: '',
                    branchTitle: ''
                };

                jobs.forEach((job) => {
                    dayProps.minTime.push(moment(job.startTime, 'HH:mm'));
                    dayProps.maxTime.push(moment(job.endTime, 'HH:mm'));
                    dayProps.branchTitle = job.branchTitle;
                });

                const getMinTime = moment.min(dayProps.minTime).format('HH:mm');
                const getMaxTime = moment.max(dayProps.maxTime).format('HH:mm');

                if (jobs.length === 0) {
                    dayProps.jobs = null;
                } else if (jobs.length === 1) {
                    dayProps.jobs = dayProps.branchTitle;
                } else if (jobs.length > 1) {
                    dayProps.jobs = `${jobs.length} Shifts`;
                }

                return {
                    ...event,
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                    allDay: true,
                    status: 'locums-job',
                    time: `${getMinTime} - ${getMaxTime}`,
                    title: (
                        <>
                            <PopupComponent
                                triggerComponent={<div className="event-inner__overlay" />}
                                nonePaddings
                                color="white"
                                mouseEnterDelay={300}
                                hoverable={false}
                                wide
                                hidePointingArrow
                            >
                                <ScheduleDayPreviewPopover
                                    shifts={jobs}
                                    startDate={moment(event.startDate)}
                                    time={`${getMinTime} - ${getMaxTime}`}
                                    color="blue"
                                    openInfo={(id) => history.push(generatePath(RouterConfig.locum.jobOffers), [id])}
                                />
                            </PopupComponent>
                            <div className="event-inner">
                                <span className="event-inner__title">{`${getMinTime} - ${getMaxTime}`}</span>
                                <span className="event-inner__staff">{dayProps.jobs}</span>
                            </div>
                        </>
                    )
                };
            });

        if (jobId) {
            return (
                <LocumJobPreview
                    getLocumJobDetailsRequest={getLocumJobDetailsRequest}
                    onLocumJobActionRequest={onLocumJobActionRequest}
                    jobId={jobId}
                    locumJobData={locumJobData}
                    onCloseJobPreview={() => {
                        setJobId(null);
                        history.replace({ pathname, search });
                    }}
                />
            );
        }

        return (
            <div className="calendar-page locum-schedule-page">
                <div className="locum-schedule-page__switcher-container">
                    <Switcher
                        onLeftClick={() => {
                            history.replace({
                                pathname,
                                search: 'page=1&viewMode=calendar'
                            });
                        }}
                        leftValue="calendar"
                        onRightClick={() => {
                            history.replace({
                                pathname,
                                search: 'page=1&tab=upcoming&viewMode=table'
                            });
                        }}
                        rightValue="table"
                        activeView={viewMode}
                    />
                </div>
                {viewMode === 'calendar' ? (
                    <ScheduleCalendar
                        localizer={localizer}
                        formats={calendarFormats}
                        components={calendarComponents}
                        events={renderJobsEvents()}
                        selectable
                        onNavigate={onNavigate}
                    />
                ) : (
                    <div>
                        <PageNav title="Locum Shifts" tabs={tabs} noMargin />
                        <TableComponent
                            cols={tableCols}
                            data={jobs}
                            totalItems={totalData}
                            itemsPerPage={itemsPerPage}
                            page={Number(page)}
                            preserveQueryParams={true}
                        />
                    </div>
                )}
            </div>
        );
    }
}

LocumSchedule.propTypes = {
    schedule: PropTypes.object
};
