import {
    GET_BRANCH_STAFF_REQUEST,
    GET_BRANCH_STAFF_SUCCESS,
    GET_BRANCH_STAFF_FAILED,
    GET_ALL_STAFF_REQUEST,
    GET_ALL_STAFF_SUCCESS,
    GET_ALL_STAFF_FAILED,
    POST_STAFF_ABSENCE_FAILED,
    POST_STAFF_ABSENCE_REQUEST,
    POST_STAFF_ABSENCE_SUCCESS
} from './action-types';

export const getBranchStaffRequest = (id) => ({
    type: GET_BRANCH_STAFF_REQUEST,
    id
});

export const getBranchStaffSuccess = (payload) => ({
    type: GET_BRANCH_STAFF_SUCCESS,
    payload
});

export const getBranchStaffFailed = () => ({
    type: GET_BRANCH_STAFF_FAILED
});
export const getAllStaffRequest = () => ({
    type: GET_ALL_STAFF_REQUEST
});

export const getAllStaffSuccess = (payload) => ({
    type: GET_ALL_STAFF_SUCCESS,
    payload
});

export const getAllStaffFailed = () => ({
    type: GET_ALL_STAFF_FAILED
});
export const postStaffAbsenceRequest = (payload) => ({
    type: POST_STAFF_ABSENCE_REQUEST,
    payload
});

export const postStaffAbsenceSuccess = (payload) => ({
    type: POST_STAFF_ABSENCE_SUCCESS,
    payload
});

export const postStaffAbsenceFailed = () => ({
    type: POST_STAFF_ABSENCE_FAILED
});
