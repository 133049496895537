import BranchSchedule from './BranchSchedule';
import { connect } from 'react-redux';
import {
    getCurrentDay,
    clearCurrentDay,
    updateDayShifts,
    getPostJobDraft,
    separateJob,
    longTermJob,
    getBranchShiftsRequest,
    getBranchAvailableStaffRequest,
    onSubmitShiftRequest,
    onSubmitDraftRequest,
    onPostJobRequest,
    getLocumByGphcRequest,
    getLocumScheduleAvailabilityRequest,
    clearLocumData,
    getAllStaffRequest,
    postStaffAbsenceRequest,
    getJobLocumsByFilterRequest,
    clearGetJobLocumsByFilter,
    getAllMileageRulesRequest,
    getAllTransportRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    onSubmitBulkShiftRequest,
    onSubmitShiftDraftBulkRequest,
    onPostJobBulkRequest
} from '../../../../../../store/actions';

import { getCalculatedEmployeeShiftHoursClear } from '../../../../../../store/actions';

export default connect(
    (state) => ({
        currentDay: state.branchSchedule.currentDay,
        postJobDraft: state.branchSchedule.postJob,
        shiftsList: state.branchSchedule.shiftsList,
        staffList: state.branchSchedule.staffList,
        allStaff: state.branchStaffPage.data,
        operatingTime: state.branchInfoPage.branchInfo.data.operatingTime,
        locumData: state.locumData,
        calculatedActualStaffShiftTime: state.absenceRequestForm.calculatedActualStaffShiftTime,
        locums: state.postJobForm.data,
        mileageRules: state.pharmacySettingsPage.mileageRules,
        transportRules: state.pharmacySettingsPage.transportRules,
        parkingRules: state.pharmacySettingsPage.parkingRules,
        accommodationRules: state.pharmacySettingsPage.accommodationRules
    }),
    {
        getBranchShiftsRequest,
        getBranchAvailableStaffRequest,
        onSubmitShiftRequest,
        onSubmitDraftRequest,
        onSubmitBulkShiftRequest,
        onPostJobRequest,
        getCurrentDay,
        clearCurrentDay,
        updateDayShifts,
        getPostJobDraft,
        separateJob,
        longTermJob,
        getLocumByGphcRequest,
        getLocumScheduleAvailabilityRequest,
        clearLocumData,
        getAllStaffRequest,
        postStaffAbsenceRequest,
        getCalculatedEmployeeShiftHoursClear,
        getJobLocumsByFilterRequest,
        clearGetJobLocumsByFilter,
        getAllMileageRulesRequest,
        getAllTransportRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest,
        onSubmitShiftDraftBulkRequest,
        onPostJobBulkRequest
    }
)(BranchSchedule);
