import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ProfileInfoBoard from './ProfileInfoBoard';
import EditPersonalInfoForm from '../../../../../components/forms/edit-personal-info-form';

import './style.scss';

export function PharmacyProfile({
    data,
    userEmail,
    loading,
    removeFileLoading,
    postPersonalInfoRequest,
    getPersonalInfoRequest,
    removeUploadedFileRequest
}) {
    const [activeView, setActiveView] = useState('infoBoard');

    const onSubmit = useCallback(
        (payload) => {
            postPersonalInfoRequest(
                {
                    data: payload,
                    callback: () => setActiveView('infoBoard')
                },
                getPersonalInfoRequest,
                'auth'
            );
        },
        [getPersonalInfoRequest, postPersonalInfoRequest]
    );

    const onRemoveFile = (payload) => removeUploadedFileRequest(payload, getPersonalInfoRequest, 'auth');
    const onEditClick = () => setActiveView('editInfoForm');
    const onCancelClick = () => setActiveView('infoBoard');

    const switchView =
        activeView === 'infoBoard' ? (
            <ProfileInfoBoard data={data} onEditClick={onEditClick} />
        ) : (
            <EditPersonalInfoForm
                data={data}
                userEmail={userEmail}
                loading={loading}
                isPharmacyAdmin
                removeFileLoading={removeFileLoading}
                onCancelClick={onCancelClick}
                onSaveClick={(payload) => onSubmit(payload)}
                onRemoveFile={(payload) => onRemoveFile(payload)}
            />
        );

    return <div className="pharmacy-info-page">{switchView}</div>;
}

PharmacyProfile.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};
