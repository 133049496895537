import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react';
import ButtonComponent from '../../UI/buttons/button';
import TextInput from '../../UI/inputs/text-input';
import Checkbox from '../../UI/checkbox';
import LocalLoader from '../../UI/local-loader';

import { digitsNumberError, emailError, passError } from '../../../services/validate';

import './style.scss';

class InviteBasicLoginForm extends Component {
    state = {
        gphcNumber: '',
        email: '',
        password: '',
        repeatPassword: '',
        isAgreed: false,
        errors: {
            gphcNumber: null,
            email: null,
            password: null,
            repeatPassword: null
        },
        validate: {
            gphcNumber: false,
            email: false,
            password: false,
            repeatPassword: false
        },
        userProfile: this.props.userProfile
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userProfile !== prevState.userProfile) {
            return {
                email: nextProps.userProfile.email
            };
        }
        return null;
    }

    onJoinTeamClick = (e) => {
        e.preventDefault();
        const { onJoinTeam } = this.props;
        onJoinTeam();
    };

    onConfirmIdentityClick = async (e) => {
        e.preventDefault();
        const { onConfirmIdentity } = this.props;
        await this.validate('gphcNumber');

        const { gphcNumber, errors } = this.state;

        if (errors.gphcNumber) return;

        onConfirmIdentity(gphcNumber);
    };

    onCorrectProfileClick = (e) => {
        e.preventDefault();
        const { onCorrectProfile } = this.props;
        onCorrectProfile();
    };

    onLoginClick = async (e) => {
        e.preventDefault();

        await Promise.all([this.validate('email'), this.validate('password'), this.validate('repeatPassword')]);

        const { email, password, errors } = this.state;
        const { onLogin } = this.props;

        if (errors.email || errors.password || errors.repeatPassword) return;

        onLogin({ email, password });
    };

    onCancelInviteClick = (e) => {
        e.preventDefault();
        const { onCancelInvite } = this.props;
        onCancelInvite();
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'gphcNumber':
                error = digitsNumberError(value);
                break;
            case 'email':
                error = emailError(value);
                break;
            case 'password':
                error = passError(value);
                break;
            case 'repeatPassword':
                error = value !== this.state.password ? 'Passwords must be the same.' : null;
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onChangeHandler = ({ target: { name, value } }) => {
        const { errors, validate } = this.state;

        return this.setState(
            {
                [name]: value
            },
            () => {
                if (errors[name] || validate[name]) this.validate(name);
                if (name === 'repeatPassword' && (validate.repeatPassword || errors.repeatPassword))
                    return this.validate('repeatPassword');
            }
        );
    };

    onCheckboxChangeHandler = () => {
        this.setState((prevState) => ({ isAgreed: !prevState.isAgreed }));
    };

    render() {
        const { gphcNumber, isAgreed, email, password, repeatPassword, errors } = this.state;
        const { loading, user, onboardStep, userProfile } = this.props;

        let stepView;

        if (onboardStep === 'welcome') {
            stepView = (
                <div className="invite-basic-form__step step-one">
                    <h2 className="invite-basic-form__title">{`Welcome Aboard, ${user.name} ${user.surname}!`}</h2>
                    <p className="invite-basic-form__subtitle">
                        You recieve this invitation because <span>{user.pharmacyName}</span> Select you as{' '}
                        <span>{user.role}</span> for. If you want join team, just start Onboarding Process
                    </p>
                    <ButtonComponent
                        value="Join Team!"
                        size="huge"
                        width="huge"
                        onClick={this.onJoinTeamClick}
                        loading={loading}
                    />
                </div>
            );
        } else if (onboardStep === 'confirm') {
            stepView = (
                <div className="invite-basic-form__step step-two">
                    <h2 className="invite-basic-form__title">Let’s Confirm your Identity</h2>
                    <p className="invite-basic-form__subtitle">
                        Enter your GPhC number in the box below so we can check your details from the GPhC register
                    </p>
                    <TextInput
                        name="gphcNumber"
                        type="text"
                        value={gphcNumber}
                        label="GPHC Number"
                        onChange={this.onChangeHandler}
                        error={!!errors.gphcNumber}
                        helperText={errors.gphcNumber}
                    />
                    <div className="buttons-row">
                        <ButtonComponent
                            value="Next"
                            onClick={this.onConfirmIdentityClick}
                            loading={loading}
                            size="big"
                            width="big"
                            disabled={!!errors.gphcNumber}
                        />
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="big"
                            width="big"
                            onClick={this.onCancelInviteClick}
                            disabled={loading}
                        />
                    </div>
                </div>
            );
        } else if (onboardStep === 'profile') {
            stepView = (
                <div className="invite-basic-form__step step-three">
                    <h2 className="invite-basic-form__title">We found your GPhC Profile</h2>
                    <p className="invite-basic-form__subtitle">
                        Please check that the GPhC number and name match your records. We will use this information to
                        verify you.
                    </p>
                    <div className="profile-info">
                        <div className="row">
                            <div className="col">
                                <p className="col__title">Surname</p>
                                <p className="col__info">{userProfile.surname}</p>
                            </div>
                            <div className="col">
                                <p className="col__title">GPhC Number</p>
                                <p className="col__info">{userProfile.gphcNumber}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="col__title">Forname</p>
                                <p className="col__info">{userProfile.name}</p>
                            </div>
                            <div className="col">
                                <p className="col__title">Status</p>
                                <p className="col__info">{userProfile.gphcStatus}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="col__title">Postal Town</p>
                                <p className="col__info">{userProfile.postalTown}</p>
                            </div>
                            <div className="col">
                                <p className="col__title">Expiry date of registration</p>
                                <p className="col__info">{userProfile.expirationDate}</p>
                            </div>
                        </div>
                    </div>
                    <Checkbox
                        name="isAgreed"
                        label="I agree that the above information is correct and I am registering on behalf of myself"
                        onChange={this.onCheckboxChangeHandler}
                        checked={isAgreed}
                    />
                    <div className="buttons-row">
                        <ButtonComponent
                            value="Correct"
                            onClick={this.onCorrectProfileClick}
                            loading={loading}
                            size="big"
                            width="big"
                            disabled={!isAgreed}
                        />
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="big"
                            width="big"
                            onClick={this.onCancelInviteClick}
                            disabled={loading}
                        />
                    </div>
                </div>
            );
        } else if (onboardStep === 'login') {
            stepView = (
                <div className="invite-basic-form__step step-four">
                    <h2 className="invite-basic-form__title">Let’s setup Login</h2>
                    <TextInput
                        name="email"
                        value={email}
                        label="Email"
                        type="email"
                        disabled
                        onChange={this.onChangeHandler}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextInput
                        name="password"
                        value={password}
                        label="Password"
                        type="password"
                        onChange={this.onChangeHandler}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <TextInput
                        name="repeatPassword"
                        value={repeatPassword}
                        label="Repeat password"
                        type="password"
                        onChange={this.onChangeHandler}
                        error={!!errors.repeatPassword}
                        helperText={errors.repeatPassword}
                    />
                    <div className="buttons-row">
                        <ButtonComponent
                            value="Login"
                            onClick={this.onLoginClick}
                            loading={loading}
                            size="big"
                            width="big"
                            disabled={!!errors.email || !!errors.password || !!errors.repeatPassword}
                        />
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="big"
                            width="big"
                            onClick={this.onCancelInviteClick}
                            disabled={loading}
                        />
                    </div>
                </div>
            );
        }

        return <Form className="invite-basic-form">{Object.keys(user).length === 0 ? <LocalLoader /> : stepView}</Form>;
    }
}

InviteBasicLoginForm.propTypes = {
    loading: PropTypes.bool,
    onboardStep: PropTypes.string,
    user: PropTypes.object.isRequired,
    onJoinTeam: PropTypes.func.isRequired,
    onConfirmIdentity: PropTypes.func.isRequired,
    onCorrectProfile: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    onCancelInvite: PropTypes.func.isRequired,
    userProfile: PropTypes.object
};

export default InviteBasicLoginForm;
