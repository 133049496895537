import React from 'react';
import PropTypes from 'prop-types';

import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../../services/privateRoute';

import AdminPharmaciesPage from './pharmacies-page';
import AdminUsersPage from './users-page';
import AdminJobOffersPage from './job-offers-page';
import AdminBillingPage from './billing-page';
import AdminInfoPage from './admin-info-page';

import ErrorBoundary from '../../error-boundary';

import Header from '../../../components/header';
import { RouterConfig } from './../../../routerConfig';

function SuperAdminView({ role, roles, user: { name, surname, image } }) {
    const tabs = [
        {
            title: 'Pharmacies',
            to: RouterConfig.superAdminPharmacies
        },
        { title: 'Users', to: RouterConfig.superAdminUsers },
        { title: 'Job Offers', to: RouterConfig.superAdminJobOffers },
        { title: 'Billing', to: RouterConfig.superAdminBilling }
    ];
    const profileTab = {
        title: `${name} ${!surname ? '' : surname}`,
        to: RouterConfig.superAdminInfo,
        image
    };

    return (
        <div>
            <Header tabs={tabs} profileTab={profileTab} />
            <ErrorBoundary>
                <Switch>
                    <PrivateRoute
                        path={RouterConfig.superAdminPharmacies}
                        user={role}
                        roles={roles}
                        component={AdminPharmaciesPage}
                    />
                    <PrivateRoute
                        path={RouterConfig.superAdminUsers}
                        user={role}
                        roles={roles}
                        component={AdminUsersPage}
                    />
                    <PrivateRoute
                        path={RouterConfig.superAdminJobOffers}
                        user={role}
                        roles={roles}
                        component={AdminJobOffersPage}
                        exact
                    />
                    <PrivateRoute
                        path={RouterConfig.superAdminBilling}
                        user={role}
                        roles={roles}
                        component={AdminBillingPage}
                        exact
                    />
                    <PrivateRoute
                        path={RouterConfig.superAdminInfo}
                        user={role}
                        roles={roles}
                        component={AdminInfoPage}
                    />
                    <Redirect from={RouterConfig.root} to={RouterConfig.superAdminPharmacies} />
                </Switch>
            </ErrorBoundary>
        </div>
    );
}

SuperAdminView.propTypes = {
    role: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
};

export default SuperAdminView;
