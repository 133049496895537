import ConfirmModal from 'components/modals/confirm-modal';
import { TabPanel, TabSwitcher } from 'components/tab-switcher';
import TableComponent from 'components/table';
import ButtonComponent from 'components/UI/buttons/button';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { CONTRACT_TYPE_TEXT } from 'services/constants';
import { contractPages } from 'services/contract';
import { useContractStaffId, useSearchParams } from 'services/hooks';
import {
    deleteContractRequest,
    getContractByIdRequest,
    getContractListRequest,
    startContractRequest
} from 'store/actions/admin-view/staff-contract/index';
import ContractBoard from '../contract-board';
import ContractForm from '../contract-form';
import { getStaffInfoRequest } from './../../../../../../store/actions/admin-view/staff-info/index';

import './style.scss';

const UpcomingContracts = () => {
    const [{ page = 1 }, setSearchParams] = useSearchParams();

    const staffId = useContractStaffId();
    const dispatch = useDispatch();

    const { data, loading, list } = useSelector((state) => state.staffContractPage);

    const [mode, setMode] = useState('table');
    const [openDelete, setOpenDelete] = useState(false);
    const [openStart, setOpenStart] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);

    const getList = useCallback(() => {
        dispatch(
            getContractListRequest({
                staffId,
                filters: {
                    status: 'upcoming'
                },
                pagination: {
                    page: page,
                    perPage: list.itemsPerPage
                }
            })
        );
    }, [dispatch, list.itemsPerPage, page, staffId]);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        if (!selectedContract) return;
        dispatch(getContractByIdRequest({ contractId: selectedContract.id }));
    }, [dispatch, selectedContract, staffId]);

    const handleDelete = () => {
        dispatch(
            deleteContractRequest({
                contractId: selectedContract.id,
                staffId,
                callback: () => {
                    setOpenDelete(false);
                    getList();
                    setMode('table');
                }
            })
        );
    };

    const handleStart = () => {
        const callback = () => {
            dispatch(getStaffInfoRequest(staffId));
            setSearchParams({ tab: contractPages.currentContract });
        };

        dispatch(startContractRequest({ staffId, contractId: selectedContract.id, callback }));
    };

    const handleStartContract = (contract) => () => {
        setSelectedContract(contract);
        setOpenStart(true);
    };

    const handleDeleteItem = (contract) => () => {
        setSelectedContract(contract);
        setOpenDelete(true);
    };

    const handleEditItem = (contract) => () => {
        setSelectedContract(contract);
        setMode('edit');
    };
    const handleOpenInfo = (id) => {
        setSelectedContract({ id });
        setMode('view');
    };

    const handleSuccessCreate = (contract) => {
        setMode('view');
        if (moment(contract.startDate).isSameOrBefore()) {
            setSelectedContract(contract);
            setOpenStart(true);
        }
    };

    const tableCols = [
        {
            name: 'title',
            title: 'Contract title',
            render: (item) => item.title,
            type: 'disabled'
        },
        {
            name: 'startDate',
            title: 'Start date',
            render: (item) => moment(item.startDate).format('DD/MM/YY'),
            type: 'disabled'
        },
        {
            name: 'endDate',
            title: 'End date',
            render: (item) => moment(item.endDate).format('DD/MM/YY'),
            type: 'disabled'
        },
        {
            name: 'type',
            title: 'Contract type',
            render: (item) => CONTRACT_TYPE_TEXT[item.type],
            type: 'disabled'
        },
        {
            name: 'actions',
            title: 'Actions',
            render: (item) => (
                <div className="upcoming-contracts__actions-wrapper">
                    <Button size="mini" onClick={handleStartContract(item)}>
                        Start
                    </Button>
                    <Button size="mini" circular icon="edit" onClick={handleEditItem(item)} />
                    <Button size="mini" circular icon="trash alternate" onClick={handleDeleteItem(item)} />
                </div>
            ),
            type: 'disabled',
            isAction: true
        }
    ];

    return (
        <div className="upcoming-contracts">
            <ConfirmModal
                open={openDelete}
                showCancelButton={true}
                onClose={() => setOpenDelete(false)}
                title="Delete contract"
                text={`Are you sure you want to delete a contract "${selectedContract?.title}"?`}
                button={{
                    value: 'Delete',
                    onClick: handleDelete,
                    disabled: loading
                }}
                cancelButton={{ value: 'Cancel', onClick: () => setOpenDelete(false), disabled: loading }}
            />
            <ConfirmModal
                open={openStart}
                showCancelButton={true}
                onClose={() => setOpenStart(false)}
                title="Start contract"
                text={`Your current contract shall stop. Are you sure you want to start the "${selectedContract?.title}" contract?`}
                button={{
                    disabled: loading,
                    value: 'Start',
                    onClick: handleStart
                }}
                cancelButton={{
                    value: 'Cancel',
                    disabled: loading,
                    onClick: () => setOpenStart(false)
                }}
            />
            <TabSwitcher tabName={mode}>
                <TabPanel value="table">
                    <div className="upcoming-contracts__header">
                        <p className="upcoming-contracts__header-info">Click on a contract below to see full details</p>
                        <ButtonComponent value="Add new contract" size="small" onClick={() => setMode('create')} />
                    </div>
                    <TableComponent
                        cols={tableCols}
                        data={list.data?.rows}
                        totalItems={list.data?.count}
                        itemsPerPage={list.itemsPerPage}
                        page={Number(page)}
                        preserveQueryParams={true}
                        openInfo={handleOpenInfo}
                    />
                </TabPanel>
                <TabPanel value="create">
                    <ContractForm onGoBack={() => setMode('table')} onSuccess={handleSuccessCreate} />
                </TabPanel>
                <TabPanel value="edit">
                    <ContractForm
                        contract={data}
                        onGoBack={() => setMode('table')}
                        onSuccess={() => {
                            setMode('view');
                        }}
                        loading={loading}
                    />
                </TabPanel>
                <TabPanel value="view">
                    <ContractBoard
                        onStartClick={handleStartContract}
                        onDeleteClick={handleDeleteItem}
                        contract={data}
                        onEditClick={() => setMode('edit')}
                    />
                </TabPanel>
            </TabSwitcher>
        </div>
    );
};

UpcomingContracts.propTypes = {};

export default UpcomingContracts;
