import {
    CREATE_MILEAGE_RULE_REQUEST,
    CREATE_MILEAGE_RULE_SUCCESS,
    CREATE_MILEAGE_RULE_FAILED,
    DELETE_MILEAGE_RULE_REQUEST,
    DELETE_MILEAGE_RULE_SUCCESS,
    DELETE_MILEAGE_RULE_FAILED,
    GET_ALL_MILEAGE_RULES_FAILED,
    GET_ALL_MILEAGE_RULES_SUCCESS,
    GET_ALL_MILEAGE_RULES_REQUEST,
    CREATE_ACCOMMODATION_RULE_REQUEST,
    CREATE_TRANSPORT_RULE_REQUEST,
    CREATE_PARKING_RULE_REQUEST,
    DELETE_ACCOMMODATION_RULE_REQUEST,
    DELETE_PARKING_RULE_REQUEST,
    DELETE_TRANSPORT_RULE_REQUEST,
    GET_ALL_PARKING_RULES_REQUEST,
    GET_ALL_TRANSPORT_RULES_REQUEST,
    GET_ALL_ACCOMMODATION_RULES_REQUEST,
    CREATE_ACCOMMODATION_RULE_FAILED,
    CREATE_TRANSPORT_RULE_FAILED,
    CREATE_PARKING_RULE_FAILED,
    DELETE_ACCOMMODATION_RULE_FAILED,
    DELETE_PARKING_RULE_FAILED,
    DELETE_TRANSPORT_RULE_FAILED,
    GET_ALL_PARKING_RULES_FAILED,
    GET_ALL_TRANSPORT_RULES_FAILED,
    GET_ALL_ACCOMMODATION_RULES_FAILED,
    GET_ALL_PARKING_RULES_SUCCESS,
    GET_ALL_ACCOMMODATION_RULES_SUCCESS,
    GET_ALL_TRANSPORT_RULES_SUCCESS,
    CREATE_PARKING_RULE_SUCCESS,
    CREATE_ACCOMMODATION_RULE_SUCCESS,
    CREATE_TRANSPORT_RULE_SUCCESS,
    DELETE_ACCOMMODATION_RULE_SUCCESS,
    DELETE_TRANSPORT_RULE_SUCCESS,
    DELETE_PARKING_RULE_SUCCESS,
    GET_MASTER_DATE_FAILED,
    GET_MASTER_DATE_SUCCESS
} from '../../../actions/admin-view/pharmacy-settings-page/action-types';

const initialState = {
    settings: {
        mileageRules: [],
        transportRules: [],
        parkingRules: [],
        accommodationRules: [],
        masterDate: null
    },
    loading: false
};

function addOrUpdate(action, items) {
    const ruleExists = items.find((rule) => rule.id === action.payload.id);
    let newRules = [];

    if (ruleExists) {
        newRules = items.map((rule) => {
            if (rule.id === action.payload.id) return action.payload;
            return rule;
        });
    } else {
        newRules = [action.payload, ...items];
    }
    return newRules;
}

function deleteAndReturnNew(id, items) {
    return items.filter((rule) => rule.id !== id);
}

const pharmacySettingsPage = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_MILEAGE_RULE_REQUEST:
        case DELETE_MILEAGE_RULE_REQUEST:
        case GET_ALL_MILEAGE_RULES_REQUEST:
        case CREATE_ACCOMMODATION_RULE_REQUEST:
        case CREATE_TRANSPORT_RULE_REQUEST:
        case CREATE_PARKING_RULE_REQUEST:
        case DELETE_ACCOMMODATION_RULE_REQUEST:
        case DELETE_TRANSPORT_RULE_REQUEST:
        case DELETE_PARKING_RULE_REQUEST:
        case GET_ALL_PARKING_RULES_REQUEST:
        case GET_ALL_TRANSPORT_RULES_REQUEST:
        case GET_ALL_ACCOMMODATION_RULES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_MILEAGE_RULES_SUCCESS:
            return {
                ...state,
                mileageRules: action.payload,
                loading: false
            };
        case GET_ALL_PARKING_RULES_SUCCESS:
            return {
                ...state,
                parkingRules: action.payload,
                loading: false
            };
        case GET_ALL_ACCOMMODATION_RULES_SUCCESS:
            return {
                ...state,
                accommodationRules: action.payload,
                loading: false
            };
        case GET_ALL_TRANSPORT_RULES_SUCCESS:
            return {
                ...state,
                transportRules: action.payload,
                loading: false
            };
        case CREATE_MILEAGE_RULE_SUCCESS:
            return {
                ...state,
                mileageRules: addOrUpdate(action, state.mileageRules),
                loading: false
            };
        case CREATE_PARKING_RULE_SUCCESS:
            return {
                ...state,
                parkingRules: addOrUpdate(action, state.parkingRules),
                loading: false
            };
        case CREATE_TRANSPORT_RULE_SUCCESS:
            return {
                ...state,
                transportRules: addOrUpdate(action, state.transportRules),
                loading: false
            };
        case CREATE_ACCOMMODATION_RULE_SUCCESS:
            return {
                ...state,
                accommodationRules: addOrUpdate(action, state.accommodationRules),
                loading: false
            };
        case DELETE_MILEAGE_RULE_SUCCESS:
            return {
                ...state,
                mileageRules: deleteAndReturnNew(action.id, state.mileageRules),
                loading: false
            };
        case DELETE_ACCOMMODATION_RULE_SUCCESS:
            return {
                ...state,
                accommodationRules: deleteAndReturnNew(action.id, state.accommodationRules),
                loading: false
            };
        case DELETE_TRANSPORT_RULE_SUCCESS:
            return {
                ...state,
                transportRules: deleteAndReturnNew(action.id, state.transportRules),
                loading: false
            };
        case DELETE_PARKING_RULE_SUCCESS:
            return {
                ...state,
                parkingRules: deleteAndReturnNew(action.id, state.parkingRules),
                loading: false
            };
        case GET_MASTER_DATE_SUCCESS:
            return {
                ...state,
                masterDate: action.payload,
                loading: false
            };
        case CREATE_MILEAGE_RULE_FAILED:
        case DELETE_MILEAGE_RULE_FAILED:
        case GET_ALL_MILEAGE_RULES_FAILED:
        case CREATE_ACCOMMODATION_RULE_FAILED:
        case CREATE_TRANSPORT_RULE_FAILED:
        case CREATE_PARKING_RULE_FAILED:
        case DELETE_ACCOMMODATION_RULE_FAILED:
        case DELETE_PARKING_RULE_FAILED:
        case DELETE_TRANSPORT_RULE_FAILED:
        case GET_ALL_PARKING_RULES_FAILED:
        case GET_ALL_TRANSPORT_RULES_FAILED:
        case GET_ALL_ACCOMMODATION_RULES_FAILED:
        case GET_MASTER_DATE_FAILED:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};

export default pharmacySettingsPage;
