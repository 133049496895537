import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    createMileageRuleFailed,
    createMileageRuleSuccess,
    createAccommodationRuleSuccess,
    createAccommodationRuleFailed,
    createParkingRuleSuccess,
    createParkingRuleFailed,
    createTransportRuleSuccess,
    createTransportRuleFailed,
    mileageRuleDeleteFailed,
    mileageRuleDeleteSuccess,
    accommodationRuleDeleteSuccess,
    accommodationRuleDeleteFailed,
    parkingRuleDeleteSuccess,
    parkingRuleDeleteFailed,
    transportRuleDeleteSuccess,
    transportRuleDeleteFailed,
    getAllMileageRulesFailed,
    getAllMileageRulesSuccess,
    getAllTransportRulesSuccess,
    getAllTransportRulesFailed,
    getAllAccommodationRulesSuccess,
    getAllAccommodationRulesFailed,
    getAllParkingRulesSuccess,
    getAllParkingRulesFailed,
    updateMasterDateFailed,
    updateMasterDateSuccess,
    getMasterDateSuccess,
    getMasterDateFailed
} from '../../../actions';

import * as actionTypes from '../../../actions/admin-view/pharmacy-settings-page/action-types';
import api from '../../../../services/api';

function* onGetAllMileageRulesRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/mileage-rules/get-all`,
            method: 'get'
        });
        yield put(getAllMileageRulesSuccess(data));
    } catch (err) {
        yield put(getAllMileageRulesFailed());
    }
}

function* onGetAllTransportRulesRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/transport-rules/get-all`,
            method: 'get'
        });
        yield put(getAllTransportRulesSuccess(data));
    } catch (err) {
        yield put(getAllTransportRulesFailed());
    }
}

function* onGetAllParkingRulesRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/parking-rules/get-all`,
            method: 'get'
        });
        yield put(getAllParkingRulesSuccess(data));
    } catch (err) {
        yield put(getAllParkingRulesFailed());
    }
}

function* onGetAllAccommodationRulesRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/accommodation-and-food-rules/get-all`,
            method: 'get'
        });
        yield put(getAllAccommodationRulesSuccess(data));
    } catch (err) {
        yield put(getAllAccommodationRulesFailed());
    }
}

function* onAddMileageRuleRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/mileage-rules/save`,
            method: 'post',
            payload
        });
        yield put(createMileageRuleSuccess(data));
    } catch (err) {
        yield put(createMileageRuleFailed());
    }
}

function* onAddTransportRuleRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/transport-rules/save`,
            method: 'post',
            payload
        });
        yield put(createTransportRuleSuccess(data));
    } catch (err) {
        yield put(createTransportRuleFailed());
    }
}

function* onAddParkingRuleRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/parking-rules/save`,
            method: 'post',
            payload
        });
        yield put(createParkingRuleSuccess(data));
    } catch (err) {
        yield put(createParkingRuleFailed());
    }
}

function* onAddAccommodationRuleRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/accommodation-and-food-rules/save`,
            method: 'post',
            payload
        });
        yield put(createAccommodationRuleSuccess(data));
    } catch (err) {
        yield put(createAccommodationRuleFailed());
    }
}

function* onDeleteMileageRuleRequest({ id }) {
    try {
        yield call(api.request, {
            url: `/settings/mileage-rules/${id}`,
            method: 'delete'
        });
        yield put(mileageRuleDeleteSuccess({ id }));
    } catch (err) {
        yield put(mileageRuleDeleteFailed());
    }
}

function* onDeleteTransportRuleRequest({ id }) {
    try {
        yield call(api.request, {
            url: `/settings/transport-rules/${id}`,
            method: 'delete'
        });
        yield put(transportRuleDeleteSuccess({ id }));
    } catch (err) {
        yield put(transportRuleDeleteFailed());
    }
}

function* onDeleteParkingRuleRequest({ id }) {
    try {
        yield call(api.request, {
            url: `/settings/parking-rules/${id}`,
            method: 'delete'
        });
        yield put(parkingRuleDeleteSuccess({ id }));
    } catch (err) {
        yield put(parkingRuleDeleteFailed());
    }
}

function* onDeleteAccommodationRuleRequest({ id }) {
    try {
        yield call(api.request, {
            url: `/settings/accommodation-and-food-rules/${id}`,
            method: 'delete'
        });
        yield put(accommodationRuleDeleteSuccess({ id }));
    } catch (err) {
        yield put(accommodationRuleDeleteFailed());
    }
}

function* updateMasterDateRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/update-master-and-planning`,
            method: 'post',
            payload
        });
        yield put(updateMasterDateSuccess(data));
    } catch (err) {
        yield put(updateMasterDateFailed());
    }
}

function* onGetMasterDateRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/get-master-and-planning`,
            method: 'get'
        });
        yield put(getMasterDateSuccess(data));
    } catch (err) {
        yield put(getMasterDateFailed());
    }
}

function* watchCreateMileageRuleRequest() {
    yield takeEvery(actionTypes.CREATE_MILEAGE_RULE_REQUEST, onAddMileageRuleRequest);
}

function* watchCreateTransportRuleRequest() {
    yield takeEvery(actionTypes.CREATE_TRANSPORT_RULE_REQUEST, onAddTransportRuleRequest);
}

function* watchCreateParkingRuleRequest() {
    yield takeEvery(actionTypes.CREATE_PARKING_RULE_REQUEST, onAddParkingRuleRequest);
}

function* watchCreateAccommodationRuleRequest() {
    yield takeEvery(actionTypes.CREATE_ACCOMMODATION_RULE_REQUEST, onAddAccommodationRuleRequest);
}

function* watchDeleteMileageRuleRequest() {
    yield takeEvery(actionTypes.DELETE_MILEAGE_RULE_REQUEST, onDeleteMileageRuleRequest);
}

function* watchDeleteTransportRuleRequest() {
    yield takeEvery(actionTypes.DELETE_TRANSPORT_RULE_REQUEST, onDeleteTransportRuleRequest);
}

function* watchDeleteParkingRuleRequest() {
    yield takeEvery(actionTypes.DELETE_PARKING_RULE_REQUEST, onDeleteParkingRuleRequest);
}
function* watchDeleteAccommodationRuleRequest() {
    yield takeEvery(actionTypes.DELETE_ACCOMMODATION_RULE_REQUEST, onDeleteAccommodationRuleRequest);
}

function* watchGetAllMileageRulesRequest() {
    yield takeEvery(actionTypes.GET_ALL_MILEAGE_RULES_REQUEST, onGetAllMileageRulesRequest);
}

function* watchGetAllTransportRulesRequest() {
    yield takeEvery(actionTypes.GET_ALL_TRANSPORT_RULES_REQUEST, onGetAllTransportRulesRequest);
}

function* watchGetAllParkingRulesRequest() {
    yield takeEvery(actionTypes.GET_ALL_PARKING_RULES_REQUEST, onGetAllParkingRulesRequest);
}

function* watchGetAllAccommodationRulesRequest() {
    yield takeEvery(actionTypes.GET_ALL_ACCOMMODATION_RULES_REQUEST, onGetAllAccommodationRulesRequest);
}

function* watchUpdateMasterDateRequest() {
    yield takeEvery(actionTypes.UPDATE_MASTER_DATE_REQUEST, updateMasterDateRequest);
}
function* watchGetMasterDateRequest() {
    yield takeEvery(actionTypes.GET_MASTER_DATE_REQUEST, onGetMasterDateRequest);
}

const pharmacySettingsPageSaga = [
    fork(watchCreateMileageRuleRequest),
    fork(watchCreateAccommodationRuleRequest),
    fork(watchCreateTransportRuleRequest),
    fork(watchCreateParkingRuleRequest),
    fork(watchDeleteMileageRuleRequest),
    fork(watchDeleteAccommodationRuleRequest),
    fork(watchDeleteParkingRuleRequest),
    fork(watchDeleteTransportRuleRequest),
    fork(watchGetAllMileageRulesRequest),
    fork(watchGetAllAccommodationRulesRequest),
    fork(watchGetAllParkingRulesRequest),
    fork(watchGetAllTransportRulesRequest),
    fork(watchUpdateMasterDateRequest),
    fork(watchGetMasterDateRequest)
];

export default pharmacySettingsPageSaga;
