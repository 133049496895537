import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../UI/inputs/text-input';
import ButtonComponent from '../../UI/buttons/button';

import { nameError } from '../../../services/validate';
import { Form } from 'semantic-ui-react';

import './style.scss';

const AddRegionForm = ({ onCancelClick, onRemoveClick, onSubmit, loading, getRegion }) => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (getRegion) setTitle(getRegion);
    }, [getRegion]);

    const onChangeHandler = ({ target: { name, value } }) => {
        setTitle(/^\s/.test(value) ? '' : value);
        setError(value.length !== 0 ? nameError(value) : '');
    };

    const onSubmitClick = (e) => {
        e.preventDefault();
        onSubmit(title);
    };

    return (
        <Form className="add-region-form" autoComplete="nope">
            <TextInput
                name="title"
                label="new Region"
                type="text"
                value={title}
                error={!!error}
                helperText={error}
                onChange={onChangeHandler}
                placeholder="Region name"
            />
            <div className="buttons-row">
                <ButtonComponent
                    value={!!getRegion ? 'Remove' : 'Cancel'}
                    type="transparent"
                    size="small"
                    width="small"
                    onClick={!!getRegion ? onRemoveClick : onCancelClick}
                    disabled={loading}
                    colorType={!!getRegion ? 'danger' : ''}
                />
                <ButtonComponent
                    value={!!getRegion ? 'Edit' : 'Add'}
                    onClick={onSubmitClick}
                    loading={loading}
                    size="medium"
                    disabled={!!error || title.length === 0}
                />
            </div>
        </Form>
    );
};

AddRegionForm.propTypes = {
    loading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    onSubmit: PropTypes.func,
    getRegion: PropTypes.string
};

export default AddRegionForm;
