import {
    AUTH_USER_TRUE,
    AUTH_USER_FALSE,
    AUTH_USER,
    LOGOUT,
    REGISTER_PROCESS_TRUE,
    REGISTER_PROCESS_FALSE,
    UPDATE_USER_PROFILE
} from './action-types';

export const authUserRequest = () => ({
    type: AUTH_USER
});

export const authSuccess = (data) => ({
    type: AUTH_USER_TRUE,
    payload: data
});

export const authFailed = (err) => ({
    type: AUTH_USER_FALSE,
    payload: err
});

export const logout = () => ({
    type: LOGOUT
});

export const registerProcessOn = () => ({
    type: REGISTER_PROCESS_TRUE
});

export const registerProcessOff = () => ({
    type: REGISTER_PROCESS_FALSE
});

export const updateUserProfile = (data) => {
    return {
        type: UPDATE_USER_PROFILE,
        payload: data
    };
};
