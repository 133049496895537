import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonComponent from '../../../../../components/UI/buttons/button';
import ButtonIcon from '../../../../../components/UI/buttons/button-icon';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-icon.svg';
import { locumStatusesList } from '../../../../../userConfig';
import { getDuration, getDurationFormat } from '../../../../../services/datesHelper';
import JobAdjustments from '../../../../../components/job-adjustments-table';
import { Image } from 'semantic-ui-react';

const LocumJobPreviewDashboard = ({
    jobId,
    onCloseClick,
    onLocumJobAction,
    data: {
        branchTitle,
        startDate,
        endDate,
        startTime,
        endTime,
        hourlyRate,
        paidLunch,
        description,
        status,
        zip,
        currentApplication,
        jobAdjustments,
        email,
        phone,
        address,
        branchOperatingTime,
        accommodationAndFoodRule,
        mileageRule,
        parkingRule,
        transportRule,
        logo
    },
    ...props
}) => {
    const jobPeriod = moment(endDate).isSame(startDate) ? (
        <>
            <p className="name">Date</p>
            <div className="description">{moment(startDate).format('D MMMM YYYY')}</div>
        </>
    ) : (
        <>
            <p className="name">Dates</p>
            <div className="description">
                {`${moment(startDate).format('D MMMM YYYY')} - ${moment(endDate).format('D MMMM YYYY')}`}
            </div>
        </>
    );
    let locumControlButtons = null;

    if (currentApplication && currentApplication.status === locumStatusesList.INVITED) {
        locumControlButtons = (
            <>
                <ButtonComponent
                    value="Decline"
                    type="bordered"
                    size="small"
                    colorType="bordered-danger"
                    onClick={() => onLocumJobAction('decline-invite')}
                />
                <ButtonComponent
                    value="Accept Invite"
                    size="medium"
                    colorType="blue"
                    onClick={() => onLocumJobAction('accept-invite')}
                />
            </>
        );
    } else if (currentApplication && currentApplication.status === locumStatusesList.APPLIED) {
        locumControlButtons = (
            <ButtonComponent
                value="Cancel apply"
                type="bordered"
                size="small"
                colorType="bordered-danger"
                onClick={() => onLocumJobAction('cancel')}
            />
        );
    } else if (!currentApplication) {
        locumControlButtons = (
            <ButtonComponent
                value="Apply for a job"
                size="small"
                colorType="blue"
                onClick={() => onLocumJobAction('apply')}
            />
        );
    }

    const getOperatingTimeDudarion = getDurationFormat(
        getDuration(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')),
        true
    );

    return (
        <div className="locum-job-preview-page__board">
            <div className="row">
                <div className="heading">
                    <div className="col">
                        <h2 className="col__title">Job Details</h2>
                    </div>
                    <div className="col close-button-holder">
                        <ButtonIcon onClick={onCloseClick}>
                            <CloseIcon />
                        </ButtonIcon>
                    </div>
                </div>
            </div>
            <div className="row content-row">
                <div className="col">
                    <p className="col__subtitle">{`#${jobId}`}</p>
                    <div className="info-box">
                        <div className="item-row">
                            <div className="info-box__item">{jobPeriod}</div>
                            <div className="info-box__item">
                                <p className="name">Operating Time</p>
                                <div className="description">{`${startTime} - ${endTime} (${getOperatingTimeDudarion}) `}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Hourly Rate</p>
                                <div className="description">{`£${hourlyRate}`}</div>
                            </div>
                            <div className="info-box__item">
                                <p className="name">Lunch</p>
                                <div className="description">{paidLunch ? 'Paid' : 'Unpaid'}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Job Description</p>
                                <div className="description">{description}</div>
                            </div>
                            <div className="info-box__item">
                                <p className="name">Branch title</p>
                                <div className="description">{branchTitle}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Address</p>
                                <div className="description">
                                    {address} / {zip}
                                </div>
                            </div>
                            <div className="info-box__item">
                                <p className="name">Phone</p>
                                <div className="description">{phone}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Email</p>
                                <div className="description email">{email}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p style={{ marginBottom: '2rem' }} className="name">
                                    Covered Expenses
                                </p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="name description">Mileage</div>
                                        <p className="description" style={{ marginBottom: '1rem', textTransform: '' }}>
                                            {mileageRule
                                                ? `${mileageRule.ratePerMile} pences per mile after ${mileageRule.payMileageAfter} miles, maximum ${mileageRule.maximumMileage} miles`
                                                : 'no rule'}
                                        </p>
                                        <div className="name description">Transport</div>
                                        <p className="description" style={{ marginBottom: '1rem', textTransform: '' }}>
                                            {transportRule
                                                ? `up to £${transportRule.upTo} over ${transportRule.payOver}`
                                                : 'no rule'}
                                        </p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                                        <div className="name description">Accommodation & Food</div>
                                        <p className="description" style={{ marginBottom: '1rem', textTransform: '' }}>
                                            {accommodationAndFoodRule ? accommodationAndFoodRule.name : 'no rule'}
                                        </p>
                                        <div className="name description">Parking</div>
                                        <p className="description" style={{ marginBottom: '1rem', textTransform: '' }}>
                                            {parkingRule ? `up to £${parkingRule.upTo}` : 'no rule'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {jobAdjustments && jobAdjustments.length > 0 && <JobAdjustments jobAdjustments={jobAdjustments} />}
                </div>
                <div className="col no-title">
                    <div className="info-box">
                        <div className="info-box__item">
                            <Image src={logo} wrapped size="small" circular />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row button-row">{locumControlButtons}</div>
        </div>
    );
};

LocumJobPreviewDashboard.propTypes = {
    jobId: PropTypes.string,
    data: PropTypes.object.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onLocumJobAction: PropTypes.func.isRequired
};

export default LocumJobPreviewDashboard;
