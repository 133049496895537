import React, { useState, useEffect, useCallback } from 'react';
import ReactPixel from 'react-facebook-pixel';
import OnboardingSteps from '../../../../components/onboarding-steps';
import PersonalInfoForm from '../../../../components/forms/onboarding-forms/personal-info-form';
import RightToWork from '../../../../components/forms/onboarding-forms/right-to-work-form';
import SkillsForm from '../../../../components/forms/onboarding-forms/skills-form';
import AccreditationsForm from '../../../../components/forms/onboarding-forms/accreditations-form';
import { userStatusesList } from '../../../../userConfig';

import './style.scss';

export function OnboardingPage({
    getPersonalInfoRequest,
    clearPersonalInfo,
    postPersonalInfoRequest,
    getRightsToWorkInfoRequest,
    postRightsToWorkInfoRequest,
    getSkillsInfoRequest,
    postSkillsInfoRequest,
    getAccreditationsInfoRequest,
    postAccreditationsInfoRequest,
    removeUploadedFileRequest,
    getProfileDataRequest,
    personalInfo,
    rigthsToWork,
    skills,
    accreditations,
    removingLoading,
    user
}) {
    const [activeStep, setActiveStep] = useState('PersonalInfo');
    const [stepsStatus, setStepsStatus] = useState({});

    useEffect(() => {
        if (user.status !== userStatusesList.ACTIVE && !personalInfo.data) {
            getPersonalInfoRequest({
                callback: () =>
                    setStepsStatus({
                        ...stepsStatus,
                        [activeStep]: 'active'
                    })
            });
            getProfileDataRequest();
        }

        if (activeStep === 'RightToWork') getRightsToWorkInfoRequest();
        if (activeStep === 'Skills') getSkillsInfoRequest();
        if (activeStep === 'Accreditations') getAccreditationsInfoRequest();
        if (activeStep === 'Completed') ReactPixel.trackCustom('LocumSignUp');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, user.status]);

    const onRemoveFile = (payload, action, userInfoAction, onboardingForm) =>
        removeUploadedFileRequest(payload, action, userInfoAction, onboardingForm);

    const onPersonalInfoSubmit = useCallback(
        (payload) => {
            postPersonalInfoRequest(
                {
                    data: payload,
                    callback: () => {
                        setStepsStatus({
                            ...stepsStatus,
                            PersonalInfo: 'moderation',
                            RightToWork: 'active'
                        });
                        setActiveStep('RightToWork');
                    }
                },
                null,
                'auth'
            );
        },
        [postPersonalInfoRequest, stepsStatus]
    );

    const onRightToWorkSubmit = useCallback(
        (payload) => {
            postRightsToWorkInfoRequest({
                data: payload,
                callback: () => {
                    setStepsStatus({
                        ...stepsStatus,
                        PersonalInfo: 'moderation',
                        RightToWork: 'moderation',
                        Skills: 'active'
                    });
                    setActiveStep('Skills');
                }
            });
        },
        [postRightsToWorkInfoRequest, stepsStatus]
    );

    const onSkillsSubmit = useCallback(() => {
        postSkillsInfoRequest({
            callback: () => {
                setStepsStatus({
                    ...stepsStatus,
                    Skills: 'moderation',
                    Accreditations: 'active'
                });
                setActiveStep('Accreditations');
            }
        });
    }, [postSkillsInfoRequest, stepsStatus]);

    const onAccreditationsSubmit = useCallback(() => {
        postAccreditationsInfoRequest(
            {
                callback: () => {
                    setStepsStatus({
                        ...stepsStatus,
                        Accreditations: 'moderation'
                    });
                    setActiveStep('Completed');
                }
            },
            clearPersonalInfo,
            'auth'
        );
    }, [clearPersonalInfo, postAccreditationsInfoRequest, stepsStatus]);

    const onReturnToRepsonalInfo = useCallback(() => {
        getPersonalInfoRequest({
            callback: () => {
                setStepsStatus({
                    PersonalInfo: 'active'
                });
                setActiveStep('PersonalInfo');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onReturnToRightToWork = () => {
        setStepsStatus({
            PersonalInfo: 'moderation',
            RightToWork: 'active'
        });
        setActiveStep('RightToWork');
    };

    const onReturnToSkills = () => {
        setStepsStatus({
            PersonalInfo: 'moderation',
            RightToWork: 'moderation',
            Skills: 'active'
        });
        setActiveStep('Skills');
    };

    const steps = [
        {
            label: 'PersonalInfo',
            title: '1. Personal info',
            description: 'Let us know a bit about yourself',
            status:
                user.status === userStatusesList.ONBOARDING || user.status === userStatusesList.REJECTED
                    ? stepsStatus.PersonalInfo
                    : user.status === userStatusesList.PENDING || activeStep === 'Completed'
                    ? 'completed'
                    : ''
        },
        {
            label: 'RightToWork',
            title: '2. Right to Work',
            description: 'We need to make sure you have a right to work in the United Kingdom',
            status:
                user.status === userStatusesList.ONBOARDING || user.status === userStatusesList.REJECTED
                    ? stepsStatus.RightToWork
                    : user.status === userStatusesList.PENDING || activeStep === 'Completed'
                    ? 'completed'
                    : ''
        },
        {
            label: 'Skills',
            title: '3. Skills',
            description: 'Let your employer know about your skills and accreditations',
            status:
                user.status === userStatusesList.ONBOARDING || user.status === userStatusesList.REJECTED
                    ? stepsStatus.Skills
                    : user.status === userStatusesList.PENDING || activeStep === 'Completed'
                    ? 'completed'
                    : ''
        },
        {
            label: 'Accreditations',
            title: '4. Accreditations',
            description: 'Let your employer know about your skills and accreditations',
            status:
                user.status === userStatusesList.ONBOARDING || user.status === userStatusesList.REJECTED
                    ? stepsStatus.Accreditations
                    : user.status === userStatusesList.PENDING || activeStep === 'Completed'
                    ? 'completed'
                    : ''
        }
    ];

    let formView;

    if (activeStep === 'PersonalInfo') {
        formView = (
            <PersonalInfoForm
                role={user.role}
                name={user.name}
                surname={user.surname}
                filledForm={personalInfo.data}
                onRemoveFile={(payload) => onRemoveFile(payload, getPersonalInfoRequest, 'auth')}
                onSuccess={onPersonalInfoSubmit}
                loading={personalInfo.loading}
                removingLoading={removingLoading}
            />
        );
    } else if (activeStep === 'RightToWork') {
        if (!rigthsToWork.data) return null;
        formView = (
            <RightToWork
                onSuccess={onRightToWorkSubmit}
                onRemoveRigthToWorkFile={(payload) => onRemoveFile(payload, getRightsToWorkInfoRequest)}
                onGoBack={onReturnToRepsonalInfo}
                filledForm={rigthsToWork.data}
                loading={rigthsToWork.loading}
                removingLoading={removingLoading}
            />
        );
    } else if (activeStep === 'Skills') {
        formView = (
            <SkillsForm
                onSuccess={onSkillsSubmit}
                onGoBack={onReturnToRightToWork}
                onRemoveSkillsFile={(payload) => onRemoveFile(payload, getSkillsInfoRequest, null, 'skills')}
            />
        );
    } else if (activeStep === 'Accreditations') {
        formView = (
            <AccreditationsForm
                onSuccess={onAccreditationsSubmit}
                onGoBack={onReturnToSkills}
                onRemoveAccreditationsFile={(payload) => onRemoveFile(payload, getAccreditationsInfoRequest)}
            />
        );
    }

    if (user.status === userStatusesList.PENDING || activeStep === 'Completed') {
        formView = (
            <div className="invite-profile-page__approval">
                <h3 className="title">
                    We’re verifying your profile, once this is done you’ll receive an email from us. You can see answers
                    to some important questions and find out more about the locum app at{' '}
                    <a href="https://support.thelocumapp.co.uk" target="_blank" rel="noopener noreferrer">
                        support.thelocumapp.co.uk
                    </a>
                </h3>
            </div>
        );
    }

    if (!personalInfo.data && activeStep !== 'Completed') {
        return null;
    } else
        return (
            <div className="invite-profile-page">
                <h2 className="invite-profile-page__title">Before you start</h2>
                <p className="invite-profile-page__subtitle">Let’s complete your profile in 4 easy steps</p>
                <OnboardingSteps steps={steps} />
                {formView}
            </div>
        );
}
