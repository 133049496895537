import {
    GET_JOB_LOCUMS_BY_FILTER_REQUEST,
    GET_JOB_LOCUMS_BY_FILTER_SUCCESS,
    GET_JOB_LOCUMS_BY_FILTER_FAILED,
    CLEAR_JOB_LOCUMS_FILTERS
} from '../../../actions/admin-view/post-job-form/action-types';

const initialState = {
    data: [],
    loading: false
};

const postJobForm = (state = initialState, action) => {
    switch (action.type) {
        case GET_JOB_LOCUMS_BY_FILTER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_JOB_LOCUMS_BY_FILTER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case GET_JOB_LOCUMS_BY_FILTER_FAILED:
            return {
                ...state,
                loading: false
            };
        case CLEAR_JOB_LOCUMS_FILTERS:
            return initialState;

        default:
            return state;
    }
};

export default postJobForm;
