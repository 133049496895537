import { TabPanel, TabSwitcher } from 'components/tab-switcher';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContractStaffId } from 'services/hooks';
import { getStaffContractRequest } from 'store/actions';
import { getStaffInfoRequest } from './../../../../../../store/actions/admin-view/staff-info/index';
import ContractBoard from './../contract-board/index';
import ContractForm from './../contract-form/ContractForm';

const ContractView = () => {
    const staffId = useContractStaffId();
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.staffContractPage);

    const [mode, setMode] = useState('view');

    useEffect(() => {
        dispatch(getStaffContractRequest({ staffId }));
    }, [dispatch, staffId]);

    const handleSuccess = () => {
        dispatch(getStaffInfoRequest(staffId));
        setMode('view');
    };

    return (
        <div className="contract-view">
            <TabSwitcher tabName={mode}>
                <TabPanel value="view">
                    <ContractBoard contract={data} onEditClick={() => setMode('edit')} />
                </TabPanel>
                <TabPanel value="edit">
                    <ContractForm
                        contract={data}
                        onGoBack={() => setMode('view')}
                        onSuccess={handleSuccess}
                        loading={loading}
                    />
                </TabPanel>
            </TabSwitcher>
        </div>
    );
};

ContractView.propTypes = {};

export default ContractView;
