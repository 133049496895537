import {
    GET_SCHEDULE_SHIFTS_REQUEST,
    GET_SCHEDULE_SHIFTS_SUCCESS,
    GET_SCHEDULE_SHIFTS_FAILED,
    ON_SCHEDULE_FILTER_CHANGE,
    ON_SCHEDULE_FILTER_FALSE,
    ON_SCHEDULE_DATE_RANGE_CHANGE,
    CLEAR_SCHEDULE_FILTERS,
    ON_SCHEDULE_POST_JOB_REQUEST,
    ON_SCHEDULE_POST_JOB_SUCCESS,
    ON_SCHEDULE_POST_JOB_FAILED,
    ON_SCHEDULE_SUBMIT_SHIFT_REQUEST,
    ON_SCHEDULE_SUBMIT_SHIFT_SUCCESS,
    ON_SCHEDULE_SUBMIT_SHIFT_FAILED
} from './action-types';

export const getScheduleShiftsRequest = () => ({
    type: GET_SCHEDULE_SHIFTS_REQUEST
});

export const getScheduleShiftsSuccess = (payload) => ({
    type: GET_SCHEDULE_SHIFTS_SUCCESS,
    payload
});

export const getScheduleShiftsFailed = () => ({
    type: GET_SCHEDULE_SHIFTS_FAILED
});

export const onScheduleFilterChange = (payload) => ({
    type: ON_SCHEDULE_FILTER_CHANGE,
    payload
});

export const onScheduleFilterFalse = () => ({
    type: ON_SCHEDULE_FILTER_FALSE
});

export const onScheduleDateRangeChange = (payload) => ({
    type: ON_SCHEDULE_DATE_RANGE_CHANGE,
    payload
});

export const clearScheduleFilters = () => ({
    type: CLEAR_SCHEDULE_FILTERS
});

export const onSchedulePostJobRequest = (payload) => ({
    type: ON_SCHEDULE_POST_JOB_REQUEST,
    payload
});

export const onSchedulePostJobSuccess = () => ({
    type: ON_SCHEDULE_POST_JOB_SUCCESS
});

export const onSchedulePostJobFailed = () => ({
    type: ON_SCHEDULE_POST_JOB_FAILED
});

export const onScheduleSubmitShiftRequest = (payload) => ({
    type: ON_SCHEDULE_SUBMIT_SHIFT_REQUEST,
    payload
});

export const onScheduleSubmitShiftSuccess = () => ({
    type: ON_SCHEDULE_SUBMIT_SHIFT_SUCCESS
});

export const onScheduleSubmitShiftFailed = () => ({
    type: ON_SCHEDULE_SUBMIT_SHIFT_FAILED
});
