import { AccreditationsForm } from './AccreditationsForm';
import { connect } from 'react-redux';
import { setStoreDocument, removeStoreDocument, setAccreditationsCheckbox } from '../../../../store/actions';

export default connect(
    (state) => ({
        accreditations: state.userProfileInfo.accreditations.data,
        additionalAccreditations: state.profileAdditionalInfoData.additionalAccreditations,
        previouslySelectedAccreditations: state.userProfileInfo.accreditations.data.additionalAccreditations,
        removingLoading: state.userProfileInfo.loading,
        loading: state.userProfileInfo.accreditations.loading
    }),
    {
        setStoreDocument,
        removeStoreDocument,
        setAccreditationsCheckbox
    }
)(AccreditationsForm);
