import {
    SCHEDULE,
    REGIONS,
    BRANCHES,
    STAFF,
    POSTED_JOBS,
    PHARMACY_INFO,
    ONBOARDING,
    PERSONAL_INFO,
    SETTINGS,
    VERIFICATION,
    PHARMACIES,
    USERS,
    MODERATION,
    JOB_OFFERS,
    BILLING,
    ADMIN_INFO,
    LOCUM_BANK,
    ABSENCE,
    CONTRACT
} from './routerPaths';

export const RouterConfig = {
    root: '/',

    // authentication
    login: '/login/:token?',
    signup: '/signup',
    invite: '/invite/:id',
    locumCheckIn: '/check-in/:id',

    // branch admin main navigation
    branchAdminSchedule: SCHEDULE,
    branchAdminRegions: REGIONS,
    branchAdminBranches: BRANCHES,
    branchAdminStaff: STAFF,
    branchAdminPostedJobs: POSTED_JOBS,
    branchAdminPharmacyInfo: PHARMACY_INFO,
    branchAdminLocumBank: LOCUM_BANK,

    // branch admin branch details page navigation
    branchAdminBranchRoot: `${BRANCHES}/details/:id`,
    branchAdminBranchInformation: `${BRANCHES}/details/:id/information/`,
    branchAdminBranchSchedule: `${BRANCHES}/details/:id/schedule/`,
    branchAdminBranchScheduleDayView: `${BRANCHES}/details/:id/schedule-day-view/:date`,
    branchAdminBranchTemplate: `${BRANCHES}/details/:id/template/`,
    branchAdminBranchStaff: `${BRANCHES}/details/:id/staff/`,
    branchAdminBranchAnalytics: `${BRANCHES}/details/:id/analytics/`,
    branchAdminBranchSettings: `${BRANCHES}/details/:id/settings/`,
    branchAdminJobPreview: `${BRANCHES}/details/:id/job-details/:jobId/`,

    // branch admin staff details page navigation
    branchAdminStaffRoot: `${STAFF}/details/:id/:staffId`,
    branchAdminStaffInformation: `${STAFF}/details/:id/:staffId/information`,
    branchAdminStaffSchedule: `${STAFF}/details/:id/:staffId/schedule`,
    branchAdminStaffAbsence: `${STAFF}/details/:id/:staffId/absence`,
    branchAdminStaffContract: `${STAFF}/details/:id/:staffId/contract`,

    // branch admin profile page navigation
    branchAdminProfileInfo: `${PHARMACY_INFO}/profile`,
    branchAdminSettings: `${PHARMACY_INFO}/settings`,
    branchAdminBilling: `${PHARMACY_INFO}/billing`,

    // super admin main navigation
    superAdminPharmacies: PHARMACIES,
    superAdminBranches: BRANCHES,
    superAdminUsers: USERS,
    superAdminModeration: MODERATION,
    superAdminJobOffers: JOB_OFFERS,
    superAdminBilling: BILLING,
    superAdminInfo: ADMIN_INFO,

    // super admin pharmacy details page navigation
    superAdminPharmacyRoot: `${PHARMACIES}/details/:id`,
    superAdminPharmacyInformation: `${PHARMACIES}/details/:id/information/`,
    superAdminPharmacyBranches: `${PHARMACIES}/details/:id/branches/`,
    superAdminPharmacyStaff: `${PHARMACIES}/details/:id/staff/`,
    superAdminPharmacyJobs: `${PHARMACIES}/details/:id/jobs/`,
    superAdminPharmacyBilling: `${PHARMACIES}/details/:id/billing/`,

    // super admin user details page navigation
    superAdminUserRoot: `${USERS}/details/:staffId`,
    superAdminUserInformation: `${USERS}/details/:staffId/information/`,

    // super admin profile page navigation
    superAdminInfoProfile: `${ADMIN_INFO}/profile`,
    superAdminInfoSettings: `${ADMIN_INFO}/settings`,

    // managers view
    manager: {
        onboarding: ONBOARDING,
        personalInfo: PERSONAL_INFO,
        verification: VERIFICATION,
        settings: SETTINGS,
        schedule: SCHEDULE,
        absence: ABSENCE,
        contract: CONTRACT
    },

    locum: {
        onboarding: ONBOARDING,
        personalInfo: PERSONAL_INFO,
        verification: VERIFICATION,
        schedule: SCHEDULE,
        jobOffers: JOB_OFFERS,
        settings: SETTINGS,
        absence: ABSENCE
    }
};
