import { StaffProfilePage } from './StaffProfilePage';
import { connect } from 'react-redux';
import {
    saveFileRequest,
    removeUploadedFileRequest,
    postPersonalInfoRequest,
    getRightsToWorkInfoRequest,
    postRightsToWorkInfoRequest,
    getSkillsInfoRequest,
    postSkillsInfoRequest,
    getAccreditationsInfoRequest,
    postAccreditationsInfoRequest,
    getProfileDataRequest,
    toggleAddReleaseLocumBankRequest
} from '../../../../store/actions';

export default connect(
    (state) => ({
        getInitLoading: state.userProfileInfo.personalInfo.loading,
        rigthsToWork: state.userProfileInfo.rigthsToWork,
        experience: state.userProfileInfo.skills,
        qualifications: state.userProfileInfo.accreditations,
        removingLoading: state.userProfileInfo.loading,
        profileAdditionalInfoData: state.profileAdditionalInfoData
    }),
    {
        saveFileRequest,
        removeUploadedFileRequest,
        postPersonalInfoRequest,
        getRightsToWorkInfoRequest,
        postRightsToWorkInfoRequest,
        getSkillsInfoRequest,
        postSkillsInfoRequest,
        getAccreditationsInfoRequest,
        postAccreditationsInfoRequest,
        getProfileDataRequest,
        toggleAddReleaseLocumBankRequest
    }
)(StaffProfilePage);
