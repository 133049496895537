import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { getProfileDataSuccess, getProfileDataFailed } from '../../../actions';
import * as profileAddInfoActionTypes from '../../../actions/shared/profile-additional-info/action-types';
import api from '../../../../services/api';

function* onProfileDataRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/profile/get-data',
            method: 'GET'
        });
        yield put(getProfileDataSuccess(data));
    } catch (err) {
        yield put(getProfileDataFailed());
    }
}

function* watchProfileDataRequest() {
    yield takeEvery(profileAddInfoActionTypes.GET_PROFILE_DATA_REQUEST, onProfileDataRequest);
}

const profileAdditionalInfoSaga = [fork(watchProfileDataRequest)];

export default profileAdditionalInfoSaga;
