import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function InfoTabs({ tabs }) {
    const displayTab = tabs.map((tab) => {
        const { title, className, onClick, isActive } = tab;
        return (
            <span
                key={title}
                role="button"
                className={`info-tabs__tab ${className} ${isActive ? 'active' : ''}`}
                onClick={onClick}
            >
                {title}
            </span>
        );
    });
    return <div className="info-tabs">{displayTab}</div>;
}

InfoTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object)
};

export default InfoTabs;
