import { ON_VALIDATE_GPHC_REQUEST, ON_VALIDATE_GPHC_SUCCESS, ON_VALIDATE_GPHC_FAILED } from './action-types';

export const onValidateGphcRequest = (payload) => ({
    type: ON_VALIDATE_GPHC_REQUEST,
    payload
});

export const onValidateGphcSuccess = () => ({
    type: ON_VALIDATE_GPHC_SUCCESS
});

export const onValidateGphcFailed = () => ({
    type: ON_VALIDATE_GPHC_FAILED
});
