export const selectPersonalInfoLoading = (state) => state.userProfileInfo.personalInfo.loading;
export const selectRightsToWorkInfoLoading = (state) => state.userProfileInfo.rigthsToWork.loading;
export const selectSkillsInfoLoading = (state) => state.userProfileInfo.skills.loading;
export const selectAdditionalAccreditations = (state) =>
    state.userProfileInfo.accreditations.data.selectedAccreditationsIds;
export const selectPharmacySystems = (state) => state.userProfileInfo.skills.data.selectedSystemsIds;
export const selectSkillsData = (state) => state.userProfileInfo.skills.data;
export const selectSkillsUploadedFiles = (state) => state.userProfileInfo.skills.data.uploadedFiles;
export const selectAccreditationsInfoLoading = (state) => state.userProfileInfo.accreditations.loading;
export const selectRemoveFileLoading = (state) => state.userProfileInfo.loading;
export const selectUploadedFiles = (state) => state.userProfileInfo.accreditations.data.uploadedFiles;
