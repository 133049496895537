import {
    GET_INVITES_DATA_SUCCESS,
    GET_INVITES_DATA_FAILED,
    ON_INVITE_STAFF_REQUEST,
    ON_INVITE_STAFF_SUCCESS,
    ON_INVITE_STAFF_FAILED
} from '../../../actions/admin-view/invite-staff/action-types';

const initialState = {
    loading: false,
    invitesData: {}
};

const inviteStaffForm = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVITES_DATA_SUCCESS:
            return {
                ...state,
                invitesData: action.payload
            };
        case ON_INVITE_STAFF_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_INVITES_DATA_FAILED:
        case ON_INVITE_STAFF_SUCCESS:
        case ON_INVITE_STAFF_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default inviteStaffForm;
