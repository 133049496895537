import { connect } from 'react-redux';
import { ManagerInfoPage } from './ManagerInfoPage';
import { getPersonalInfoRequest } from './../../../../store/actions';

export default connect(
    (state) => ({
        personalInfo: state.userProfileInfo.personalInfo
    }),
    {
        getPersonalInfoRequest
    }
)(ManagerInfoPage);
