import {
    ON_CREATE_BRANCH_REQUEST,
    ON_CREATE_BRANCH_SUCCESS,
    ON_CREATE_BRANCH_FAILED,
    GET_BRANCHES_DATA_REQUEST,
    GET_BRANCHES_DATA_SUCCESS,
    GET_BRANCHES_DATA_FAILED,
    CLEAR_CREATED_BRANCH_INFO
} from '../../../actions/admin-view/create-branch/action-types';

const initialState = {
    loading: false,
    branchesData: null,
    createdBranchId: null
};

const createBranch = (state = initialState, { type, payload }) => {
    switch (type) {
        case ON_CREATE_BRANCH_REQUEST:
        case GET_BRANCHES_DATA_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BRANCHES_DATA_SUCCESS:
            return {
                ...state,
                branchesData: payload,
                loading: false
            };
        case ON_CREATE_BRANCH_SUCCESS:
            return {
                ...state,
                createdBranchId: payload.id
            };
        case ON_CREATE_BRANCH_FAILED:
        case GET_BRANCHES_DATA_FAILED:
            return {
                ...state,
                loading: false
            };
        case CLEAR_CREATED_BRANCH_INFO:
            return {
                ...state,
                createdBranchId: null
            };
        default:
            return state;
    }
};

export default createBranch;
