import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { getCalculatedEmployeeShiftHoursFailed, getCalculatedEmployeeShiftHoursSuccess } from '../../../actions';

import * as actionTypes from '../../../actions/admin-view/absence-request-form/action-types';

import api from '../../../../services/api';

function* onCalculateBranchStaffShiftHoursRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${payload.employeeId}/calculated-shift-hours`,
            method: 'post',
            payload
        });
        yield put(getCalculatedEmployeeShiftHoursSuccess(data));
    } catch (err) {
        yield put(getCalculatedEmployeeShiftHoursFailed());
    }
}

function* watchCalculateBranchStaffShiftHoursRequest() {
    yield takeEvery(actionTypes.GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_REQUEST, onCalculateBranchStaffShiftHoursRequest);
}

const absenceRequestFormSaga = [fork(watchCalculateBranchStaffShiftHoursRequest)];

export default absenceRequestFormSaga;
