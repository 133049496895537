export const GET_PROFILE_SUBSCRIPTION_REQUEST = 'GET_PROFILE_SUBSCRIPTION_REQUEST';
export const GET_PROFILE_SUBSCRIPTION_SUCCESS = 'GET_PROFILE_SUBSCRIPTION_SUCCESS';
export const GET_PROFILE_SUBSCRIPTION_FAILED = 'GET_PROFILE_SUBSCRIPTION_FAILED';

export const GET_ADDONS_REQUEST = 'GET_ADDONS_REQUEST';
export const GET_ADDONS_SUCCESS = 'GET_ADDONS_SUCCESS';
export const GET_ADDONS_FAILED = 'GET_ADDONS_FAILED';

export const SUBSCRIPTION_STANDARD_PLAN_REQUEST = 'SUBSCRIPTION_STANDARD_PLAN_REQUEST';
export const SUBSCRIPTION_STANDARD_PLAN_SUCCESS = 'SUBSCRIPTION_STANDARD_PLAN_SUCCESS';
export const SUBSCRIPTION_STANDARD_PLAN_FAILED = 'SUBSCRIPTION_STANDARD_PLAN_FAILED';

export const UPDATE_PROFILE_HOSTED_PAGE_REQUEST = 'UPDATE_PROFILE_HOSTED_PAGE_REQUEST';
export const UPDATE_PROFILE_HOSTED_PAGE_SUCCESS = 'UPDATE_PROFILE_HOSTED_PAGE_SUCCESS';
export const UPDATE_PROFILE_HOSTED_PAGE_FAILED = 'UPDATE_PROFILE_HOSTED_PAGE_FAILED';

export const POST_PAYMENT_METHOD_REQUEST = 'POST_PAYMENT_METHOD_REQUEST';
export const POST_PAYMENT_METHOD_SUCCESS = 'POST_PAYMENT_METHOD_SUCCESS';
export const POST_PAYMENT_METHOD_FAILED = 'POST_PAYMENT_METHOD_FAILED';

export const UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST = 'UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST';
export const UPDATE_BRANCH_SUBSCRIPTIONS_SUCCESS = 'UPDATE_BRANCH_SUBSCRIPTIONS_SUCCESS';
export const UPDATE_BRANCH_SUBSCRIPTIONS_FAILED = 'UPDATE_BRANCH_SUBSCRIPTIONS_FAILED';

export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILED = 'GET_PAYMENT_METHOD_FAILED';

export const GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST';
export const GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS';
export const GET_SUBSCRIPTION_PLAN_FAILED = 'GET_SUBSCRIPTION_PLAN_FAILED';

export const SET_SELECTED_SUBSCRIPTION_PLAN = 'SET_SELECTED_SUBSCRIPTION_PLAN';

export const POST_PLAN_SUBSCRIPTION_REQUEST = 'POST_PLAN_SUBSCRIPTION_REQUEST';
export const POST_PLAN_SUBSCRIPTION_SUCCESS = 'POST_PLAN_SUBSCRIPTION_SUCCESS';
export const POST_PLAN_SUBSCRIPTION_FAILED = 'POST_PLAN_SUBSCRIPTION_FAILED';

export const POST_DEACTIVATE_SUBSCRIPTION_REQUEST = 'POST_DEACTIVATE_SUBSCRIPTION_REQUEST';
export const POST_DEACTIVATE_SUBSCRIPTION_SUCCESS = 'POST_DEACTIVATE_SUBSCRIPTION_SUCCESS';
export const POST_DEACTIVATE_SUBSCRIPTION_FAILED = 'POST_DEACTIVATE_SUBSCRIPTION_FAILED';

export const CANCEL_PLAN_SUBSCRIPTION_REQUEST = 'CANCEL_PLAN_SUBSCRIPTION_REQUEST';
export const CANCEL_PLAN_SUBSCRIPTION_SUCCESS = 'CANCEL_PLAN_SUBSCRIPTION_SUCCESS';
export const CANCEL_PLAN_SUBSCRIPTION_FAILED = 'CANCEL_PLAN_SUBSCRIPTION_FAILED';

export const ABORT_SUBSCRIPTION_ACTION_REQUEST = 'ABORT_SUBSCRIPTION_ACTION_REQUEST';
export const ABORT_SUBSCRIPTION_ACTION_SUCCESS = 'ABORT_SUBSCRIPTION_ACTION_SUCCESS';
export const ABORT_SUBSCRIPTION_ACTION_FAILED = 'ABORT_SUBSCRIPTION_ACTION_FAILED';

export const GET_SUBSCRIPTION_PLANS_LIST_REQUEST = 'GET_SUBSCRIPTION_PLANS_LIST_REQUEST';
export const GET_SUBSCRIPTION_PLANS_LIST_SUCCESS = 'GET_SUBSCRIPTION_PLANS_LIST_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_LIST_FAILED = 'GET_SUBSCRIPTION_PLANS_LIST_FAILED';

export const GET_ACTIVE_BRANCHES_LIST_REQUEST = 'GET_ACTIVE_BRANCHES_LIST_REQUEST';
export const GET_ACTIVE_BRANCHES_LIST_SUCCESS = 'GET_ACTIVE_BRANCHES_LIST_SUCCESS';
export const GET_ACTIVE_BRANCHES_LIST_FAILED = 'GET_ACTIVE_BRANCHES_LIST_FAILED';

export const GET_SELECTED_BRANCHES = 'GET_SELECTED_BRANCHES';

export const GET_TRANSACTIONS_LIST_REQUEST = 'GET_TRANSACTIONS_LIST_REQUEST';
export const GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS';
export const GET_TRANSACTIONS_LIST_FAILED = 'GET_TRANSACTIONS_LIST_FAILED';

export const CLEAR_TRANSACTIONS_LIST = 'CLEAR_TRANSACTIONS_LIST';
export const CLEAR_SUBSCRIPTION_DATA = 'CLEAR_SUBSCRIPTION_DATA';
