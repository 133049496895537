import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';

import './style.scss';
import ButtonModal from '../../../../../../components/UI/buttons/button-modal';
import TextInput from '../../../../../../components/UI/inputs/text-input';

const initialState = {
    name: '',
    ratePerMile: null,
    payMileageAfter: null,
    maximumMileage: null,
    errors: {
        name: null,
        ratePerMile: null,
        payMileageAfter: null,
        maximumMileage: null
    }
};

const CreateMileageRuleForm = ({ onSubmit, onRemove, editableRule }) => {
    const [formData, setFormData] = useState(initialState);
    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    useEffect(() => {
        if (!editableRule) return;
        setFormData({
            ...formData,
            name: editableRule.name.toString(),
            ratePerMile: editableRule.ratePerMile.toString(),
            payMileageAfter: editableRule.payMileageAfter.toString(),
            maximumMileage: editableRule.maximumMileage.toString()
        });
        // eslint-disable-next-line
    }, [editableRule]);

    const onChangeHandler = (_, data) => {
        setFormData({
            ...formData,
            [data.name]: data.value
        });
    };

    const formIsValid = () => {
        const { name, ratePerMile, payMileageAfter, maximumMileage } = formData;
        return !(name && ratePerMile && payMileageAfter && maximumMileage);
    };

    const onFormSubmit = () => {
        onSubmit({
            ...formData,
            id: editableRule && editableRule.id,
            errors: undefined
        });
    };

    const confirmButton = {
        value: 'Yes',
        onClick: () => onRemove(),
        size: 'big',
        width: 'big'
    };

    return (
        <>
            {confirmPopupOpen && (
                <ConfirmModal
                    open={true}
                    onClose={() => setConfirmPopupOpen(!confirmPopupOpen)}
                    title="Delete rule"
                    text="Are you sure want to delete this Rule"
                    button={confirmButton}
                />
            )}
            <Form className="pharmacy-settings-create-rule-form" onSubmit={onFormSubmit}>
                <div className="content content-row">
                    <div className="content-row__item">
                        <div className="content-row__item reasons">
                            <div className="">
                                <TextInput
                                    name="name"
                                    label="RULE NAME"
                                    placeholder="Rule Name"
                                    type="text"
                                    value={formData.name}
                                    error={!!formData.errors.name}
                                    helperText={formData.errors.name}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-row__item">
                        <div className="content-row__item reasons">
                            <div className="">
                                <TextInput
                                    name="ratePerMile"
                                    label="RATE PER MILE, PENCE"
                                    type="number"
                                    min={1}
                                    max={99}
                                    placeholder="Rate per mile, pence"
                                    value={formData.ratePerMile || ''}
                                    error={!!formData.errors.ratePerMile}
                                    helperText={formData.errors.ratePerMile}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-row__item">
                        <div className="content-row__item reasons">
                            <div className="">
                                <TextInput
                                    name="payMileageAfter"
                                    label="PAY MILEAGE AFTER"
                                    type="number"
                                    min={1}
                                    max={99}
                                    placeholder="Pay mileage after"
                                    value={formData.payMileageAfter || ''}
                                    error={!!formData.errors.payMileageAfter}
                                    helperText={formData.errors.payMileageAfter}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-row__item content">
                        <div className="">
                            <TextInput
                                name="maximumMileage"
                                label="MAXIMUM MILEAGE"
                                type="number"
                                min={1}
                                max={99}
                                placeholder="Maximum mileage"
                                value={formData.maximumMileage || ''}
                                error={!!formData.errors.maximumMileage}
                                helperText={formData.errors.maximumMileage}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>

                    <div className="buttons-row">
                        {editableRule && (
                            <ButtonModal
                                className="mr-2"
                                value={'Delete'}
                                colorType="red"
                                type="button"
                                onClick={() => setConfirmPopupOpen(true)}
                            />
                        )}
                        <ButtonModal
                            type="submit"
                            value={'Save'}
                            colorType="blue"
                            disabled={formIsValid()}
                            loading={false}
                        />
                    </div>
                </div>
            </Form>
        </>
    );
};

CreateMileageRuleForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    editableRule: PropTypes.object
};

export default CreateMileageRuleForm;
