import { GET_PROFILE_DATA_REQUEST, GET_PROFILE_DATA_SUCCESS, GET_PROFILE_DATA_FAILED } from './action-types';

export const getProfileDataRequest = () => ({
    type: GET_PROFILE_DATA_REQUEST
});

export const getProfileDataSuccess = (payload) => ({
    type: GET_PROFILE_DATA_SUCCESS,
    payload
});

export const getProfileDataFailed = () => ({
    type: GET_PROFILE_DATA_FAILED
});
