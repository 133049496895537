import { call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../../../actions';
import * as staffScheduleActionTypes from '../../../actions/admin-view/staff-contract/action-types';
import api from 'services/api';

function* onGetStaffContractRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${payload.staffId}/contract/`,
            method: 'get'
        });

        yield put(
            actions.getStaffContractSuccess({
                data
            })
        );
    } catch (err) {
        yield put(actions.getStaffContractFailed());
    }
}

function* onPostStaffContractRequest({ payload: { staffId, formData, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${staffId}/contract/`,
            method: 'post',
            payload: formData
        });
        callback(data);
        yield put(
            actions.postContractFormSuccess({
                data
            })
        );
    } catch (err) {
        yield put(actions.postContractFormFailed());
        callback && callback({ ...err?.data, error: true });
    }
}

function* watchGetStaffContractRequest() {
    yield takeEvery(staffScheduleActionTypes.GET_STAFF_CONTRACT_REQUEST, onGetStaffContractRequest);
}

function* watchPostStaffContractRequest() {
    yield takeEvery(staffScheduleActionTypes.POST_STAFF_CONTRACT_REQUEST, onPostStaffContractRequest);
}

function* onPostStaffContractListRequest({ payload: { staffId, filters, pagination, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${staffId}/contract/list`,
            method: 'post',
            payload: { filters, pagination }
        });
        yield put(
            actions.getContractListSuccess({
                data
            })
        );
        callback();
    } catch (err) {
        yield put(actions.getContractListFailed());
    }
}

function* watchGetContractListRequest() {
    yield takeEvery(staffScheduleActionTypes.GET_STAFF_CONTRACT_LIST_REQUEST, onPostStaffContractListRequest);
}

function* onGetContractByIdRequest({ payload: { contractId, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/contract/${contractId}`,
            method: 'get'
        });
        yield put(
            actions.getContractByIdSuccess({
                data
            })
        );
        callback && callback();
    } catch (err) {
        yield put(actions.getContractByIdFailed());
    }
}

function* watchGetContractByIdRequest() {
    yield takeEvery(staffScheduleActionTypes.GET_STAFF_CONTRACT_BY_ID_REQUEST, onGetContractByIdRequest);
}

function* onUpdateContract({ payload: { callback, contractId, staffId, contractData } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${staffId}/contract/${contractId}`,
            method: 'put',
            payload: contractData
        });
        callback && callback(data);
        yield put(
            actions.updateContractSuccess({
                data
            })
        );
    } catch (err) {
        yield put(actions.updateContractFailed());
        callback && callback({ ...err?.data, error: true });
    }
}

function* watchUpdateContract() {
    yield takeEvery(staffScheduleActionTypes.UPDATE_STAFF_CONTRACT_REQUEST, onUpdateContract);
}

function* onDeleteContract({ payload: { callback, staffId, contractId } }) {
    try {
        yield call(api.request, {
            url: `staff/${staffId}/contract/${contractId}`,
            method: 'delete'
        });
        callback && callback();
        yield put(
            actions.deleteContractSuccess({
                contractId
            })
        );
    } catch (err) {
        yield put(actions.deleteContractFailed());
    }
}

function* watchDeleteContract() {
    yield takeEvery(staffScheduleActionTypes.DELETE_STAFF_CONTRACT_REQUEST, onDeleteContract);
}

function* onStartContract({ payload: { callback, staffId, contractId } }) {
    try {
        const { data } = yield call(api.request, {
            url: `staff/${staffId}/contract/${contractId}/start`,
            method: 'post'
        });
        callback && callback();
        yield put(
            actions.startContractSuccess({
                data
            })
        );
    } catch (err) {
        yield put(actions.startContractFailed());
    }
}

function* watchStartContract() {
    yield takeEvery(staffScheduleActionTypes.START_STAFF_CONTRACT_REQUEST, onStartContract);
}

function* onGetContractMinDate({ payload: { callback, staffId } }) {
    try {
        const { data } = yield call(api.request, {
            url: `staff/${staffId}/contract/min-date`,
            method: 'get'
        });
        callback && callback();
        yield put(
            actions.getContractMinDateSuccess({
                data
            })
        );
    } catch (err) {
        yield put(actions.getContractMinDateFailed());
    }
}

function* watchGetContractMinDate() {
    yield takeEvery(staffScheduleActionTypes.GET_CONTRACT_MIN_DATE_REQUEST, onGetContractMinDate);
}

function* onDuplicateContract({ payload: { callback, staffId, contractId, formData } }) {
    try {
        const { data } = yield call(api.request, {
            url: `staff/${staffId}/contract/${contractId}/duplicate`,
            method: 'post',
            payload: formData
        });
        callback && callback(data);
        yield put(
            actions.duplicateContractSuccess({
                data
            })
        );
    } catch (err) {
        yield put(actions.duplicateContractFailed());
        callback && callback({ ...err?.data, error: true });
    }
}

function* watchDuplicateContract() {
    yield takeEvery(staffScheduleActionTypes.DUPLICATE_CONTRACT_REQUEST, onDuplicateContract);
}

const staffContractSaga = [
    fork(watchDuplicateContract),
    fork(watchGetContractMinDate),
    fork(watchStartContract),
    fork(watchDeleteContract),
    fork(watchGetStaffContractRequest),
    fork(watchPostStaffContractRequest),
    fork(watchGetContractListRequest),
    fork(watchGetContractByIdRequest),
    fork(watchUpdateContract)
];

export default staffContractSaga;
