import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import TableComponent from '../../../../../components/table';
import PageNav from '../../../../../components/page-nav';

import { usePage } from '../../../../../services/hooks';
import './style.scss';
import ButtonModal from '../../../../../components/UI/buttons/button-modal';
import notificationSettings from '../../../../../services/notificationSettings';
import { store } from 'react-notifications-component';

const initialForm = {
    startDate: '',
    endDate: '',
    minHourlyRate: '',
    tab: 'new',
    distance: 20,
    page: 1
};

const Tab = {
    New: 'new',
    Pending: 'pending',
    Invite: 'invite'
};

export function LocumJobOffersList({
    jobOffers: { data, itemsPerPage, totalData },
    getJobOffersRequest,
    openJobPreview,
    settings,
    getLocumSettingsRequest,
    onLocumActionRequest,
    jobApplyResponseData
}) {
    const page = usePage();
    const { pathname, search } = useLocation();
    const history = useHistory();
    const [filterForm, setFilterForm] = useState(initialForm);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        getLocumSettingsRequest();
    }, [getLocumSettingsRequest]);

    useEffect(() => {
        if (!data || !data.rows) return;
        setJobs(data.rows);
    }, [data]);

    useEffect(() => {
        const { success, action } = jobApplyResponseData;
        const getMessageByAction = (action) => {
            switch (action) {
                case 'apply':
                    return 'Your application is sent to the pharmacy';
                case 'cancel':
                    return 'Your application is canceled';
                case 'accept-invite':
                    return 'You are successfully booked into the locum shift';
                case 'decline-invite':
                    return 'You declined to take the job';
                default:
                    return 'Something went wrong';
            }
        };
        if (success) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Success',
                message: getMessageByAction(action),
                type: 'success'
            });
        }
    }, [jobApplyResponseData]);

    useEffect(() => {
        const newFilter = {
            ...filterForm
        };

        const searchParams = new URLSearchParams(search);
        newFilter.minHourlyRate = Number(searchParams.get('minHourlyRate'));
        const distance = searchParams.get('distance');
        newFilter.distance = distance === 'nationwide' ? null : Number(distance) || initialForm.distance;

        if (searchParams.has('startDate')) {
            newFilter.startDate = new Date(searchParams.get('startDate'));
        } else {
            newFilter.startDate = '';
        }
        if (searchParams.has('endDate')) {
            newFilter.endDate = new Date(searchParams.get('endDate'));
        } else {
            newFilter.endDate = '';
        }

        newFilter.tab = searchParams.get('tab') || 'new';
        newFilter.page = searchParams.get('page') || 1;
        setFilterForm(newFilter);
        getJobOffersRequest(newFilter);
        if (!searchParams.get('tab')) {
            history.replace({
                search: new URLSearchParams(newFilter).toString()
            });
        }
        // eslint-disable-next-line
    }, [search]);

    const handleCellClick = (data) => {
        openJobPreview(data.id);
    };

    const handleJobAction = ({ id, action }) => {
        onLocumActionRequest({
            jobId: id,
            action,
            locumId: '',
            callback: () => setJobs(jobs.filter((job) => job.id !== id))
        });
    };

    const actionButtons = [
        {
            title: 'Apply',
            color: 'blue',
            tabs: [Tab.New],
            handleClick: (item) => handleJobAction({ id: item.id, action: 'apply' })
        },
        {
            title: 'Cancel',
            color: 'red',
            tabs: [Tab.Pending],
            handleClick: (item) => handleJobAction({ id: item.id, action: 'cancel' })
        },
        {
            title: 'Agree',
            color: 'blue',
            tabs: [Tab.Invite],
            handleClick: (item) => handleJobAction({ id: item.id, action: 'accept-invite' })
        },
        {
            title: 'Decline',
            color: 'red',
            tabs: [Tab.Invite],
            handleClick: (item) => handleJobAction({ id: item.id, action: 'decline-invite' })
        },
        {
            title: 'View',
            color: 'blue',
            tabs: [Tab.New, Tab.Pending, Tab.Invite],
            handleClick: (item) => openJobPreview(item.id)
        }
    ];

    const tableCols = [
        {
            name: 'pharmacyName',
            title: 'PHARMACY',
            render: (item) => (
                <div onClick={() => handleCellClick(item)} style={{ display: 'flex', alignItems: 'center' }}>
                    {item.logo ? (
                        <img alt={'item-logo'} width="25px" height="25px" src={item.logo} />
                    ) : (
                        <div className="table-default-logo" />
                    )}

                    <span style={{ marginLeft: '10px' }}>{item.pharmacyName}</span>
                </div>
            ),
            type: 'disabled'
        },
        {
            name: 'city',
            title: 'CITY',
            render: (item) => (
                <div onClick={() => handleCellClick(item)}>
                    <div>{item.city}</div>
                </div>
            ),
            type: 'disabled'
        },
        {
            name: 'startDate',
            title: 'DATE',
            render: (item) => (
                <div onClick={() => handleCellClick(item)}>{moment(item.startDate).format('DD/MM/YYYY')}</div>
            ),
            type: 'disabled'
        },
        {
            name: 'shiftTime',
            title: 'TIME',
            render: (item) => {
                return (
                    <div onClick={() => handleCellClick(item)}>
                        {item.startTime} - {item.endTime}
                    </div>
                );
            },
            type: 'disabled'
        },
        {
            name: 'apply',
            title: 'Action',
            render: (item) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {actionButtons
                        .filter((button) => button.tabs.includes(filterForm.tab))
                        .map((button) => {
                            return (
                                <ButtonModal
                                    key={button.title}
                                    type="button"
                                    onClick={() => button.handleClick(item)}
                                    value={button.title}
                                    colorType={button.color}
                                />
                            );
                        })}
                </div>
            ),
            type: 'disabled'
        }
    ];

    const handleTabChange = (tab) => {
        const filter = {};
        const searchParams = new URLSearchParams(search);
        for (const [key, value] of searchParams.entries()) {
            filter[key] = value;
        }
        filter.tab = tab;
        history.replace(`${pathname}?${new URLSearchParams(filter).toString()}`);
    };

    const tabs = [
        {
            title: 'New shifts',
            onClick: () => handleTabChange('new'),
            isActive: filterForm.tab === 'new'
        },
        {
            title: 'Pending',
            onClick: () => handleTabChange('pending'),
            isActive: filterForm.tab === 'pending'
        },
        {
            title: 'Invitations',
            onClick: () => handleTabChange('invite'),
            isActive: filterForm.tab === 'invite'
        }
    ];

    return (
        <div className="locum-job-offers-page">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <PageNav title="Locum Shifts" tabs={tabs} noMargin />
            </div>

            <TableComponent
                cols={tableCols}
                data={jobs}
                totalItems={totalData}
                itemsPerPage={itemsPerPage}
                page={Number(page)}
                preserveQueryParams={true}
            />
        </div>
    );
}

LocumJobOffersList.propTypes = {
    jobOffers: PropTypes.object,
    getJobOffersRequest: PropTypes.func.isRequired,
    openJobPreview: PropTypes.func.isRequired
};
