import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';

import JobPreviewDashboard from './JobPreviewDashboard';
import EditTemplateModal from '../../../../../../components/modals/edit-template-modal';
import ConfirmModal, { ConfirmModalForm } from '../../../../../../components/modals/confirm-modal';

import { useModal } from '../../../../../../services/hooks';
import { JOB } from '../../../../../../services/constants';
import PostJobBulkForm from '../../../../../../components/forms/post-job-bulk-form';

import './style.scss';

export function JobPreview({
    togglePageView,
    getJobDetailsRequest,
    getWorkedLocumsRequest,
    onEditJobRequest,
    onCancelJobRequest,
    onLocumActionRequest,
    getJobCancelReasonsRequest,
    getLocumByGphcRequest,
    clearLocumData,
    locumData,
    operatingTime,
    jobPreviewPage: { jobData, workedLocums, loading, removingLoading, cancellationReasons },
    match: {
        params: { id, jobId }
    },
    getJobLocumsByFilterRequest,
    clearGetJobLocumsByFilter,
    locums,
    getAllMileageRulesRequest,
    getAllTransportRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    mileageRules,
    transportRules,
    parkingRules,
    accommodationRules,
    onPostJobBulkRequest
}) {
    const [editJob, toggleEditJob] = useModal(false);
    const [cancelJob, toggleCancelJob] = useModal(false);
    const [reasons, setReasons] = useState(null);

    useEffect(() => {
        togglePageView({
            sidebarVisibility: false,
            backbuttonVisibility: true,
            containerFluid: true
        });
        getJobDetailsRequest(jobId);
        getWorkedLocumsRequest();

        return () => {
            togglePageView({
                sidebarVisibility: true,
                backbuttonVisibility: false,
                containerFluid: false
            });
        };
    }, [getJobDetailsRequest, getWorkedLocumsRequest, jobId, togglePageView]);

    useEffect(() => {
        if (jobData && (jobData.status === JOB.COMPLETED || jobData.status === JOB.ACTIVE)) {
            const endTime = moment(jobData.endDate);
            const executed = endTime.isBefore(moment());
            if (!executed) {
                getJobCancelReasonsRequest();
            }
        }
    }, [getJobCancelReasonsRequest, jobData]);

    useEffect(() => {
        setReasons(cancellationReasons);
    }, [cancellationReasons]);

    useEffect(() => {
        if (
            !getAllMileageRulesRequest ||
            !getAllTransportRulesRequest ||
            !getAllParkingRulesRequest ||
            !getAllAccommodationRulesRequest
        )
            return;
        getAllMileageRulesRequest();
        getAllTransportRulesRequest();
        getAllParkingRulesRequest();
        getAllAccommodationRulesRequest();
    }, [
        getAllMileageRulesRequest,
        getAllTransportRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest
    ]);

    const onPostJob = useCallback(
        (data, isEdit) => {
            onEditJobRequest({
                branchId: id,
                jobId,
                data,
                toggleModal: () => {
                    toggleEditJob();
                    clearLocumData();
                }
            });
        },
        [id, jobId, onEditJobRequest, toggleEditJob, clearLocumData]
    );

    const onSubmitJobBulk = useCallback(
        (data) => {
            onPostJobBulkRequest({
                id,
                data,
                toggleModal: toggleEditJob(),
                startDateRange: moment().format('YYYY-MM-DD'),
                endDateRange: moment().format('YYYY-MM-DD')
            });
        },
        [onPostJobBulkRequest, toggleEditJob, id]
    );

    const onCancelJob = useCallback(
        (data = null) => {
            onCancelJobRequest({
                jobId,
                cancelData: data,
                toggleModal: () => {
                    toggleEditJob(false);
                    toggleCancelJob(false);
                }
            });
        },
        [jobId, onCancelJobRequest, toggleCancelJob, toggleEditJob]
    );

    const onLocumAction = useCallback(
        (locumId, action) => onLocumActionRequest({ jobId, action, locumId }),
        [jobId, onLocumActionRequest]
    );

    const onLocumDataClear = useCallback(() => {
        clearLocumData();
    }, [clearLocumData]);

    const cancelJobButton = {
        value: 'Confirm',
        onClick: (formData) => {
            onCancelJob(formData);
        },
        size: 'big',
        width: 'big',
        loading: removingLoading
    };

    const onGphcChange = useCallback(
        ({ gphcNumber, name }) => {
            getJobLocumsByFilterRequest({ gphcNumber, name });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getJobLocumsByFilterRequest, clearGetJobLocumsByFilter]
    );

    const onLocumsInitialGet = useCallback(() => {
        getJobLocumsByFilterRequest({});
    }, [getJobLocumsByFilterRequest]);

    if (!jobData || !workedLocums) {
        return null;
    } else {
        const jobPeriod = moment(jobData.endDate).isSame(jobData.startDate)
            ? moment(jobData.startDate).format('D MMM, dddd')
            : `${moment(jobData.startDate).format('D MMM, dddd')} - ${moment(jobData.endDate).format('D MMM, dddd')}`;
        return (
            <div className="job-preview-page">
                <JobPreviewDashboard
                    jobId={jobId}
                    branchId={id}
                    data={jobData}
                    workedLocums={workedLocums}
                    loading={loading}
                    onEditJobClick={() => toggleEditJob()}
                    onCancelJobClick={() => toggleCancelJob()}
                    onDenyLocum={(id) => onLocumAction(id, 'reject')}
                    onApproveLocum={(id) => onLocumAction(id, 'approve')}
                    onCancelLocum={(id) => onLocumAction(id, 'decline-invite')}
                    onInviteLocum={(id) => onLocumAction(id, 'invite')}
                />
                {editJob && (
                    <EditTemplateModal
                        open={editJob}
                        onClose={() => {
                            toggleEditJob();
                            clearLocumData();
                        }}
                        title="Edit job"
                        subtitle={jobPeriod}
                        type="none"
                        color="orange"
                        size="small"
                    >
                        <PostJobBulkForm
                            loading={loading}
                            calledFromShift
                            jobData={jobData}
                            isEdit
                            locumData={locumData}
                            operatingTime={operatingTime}
                            onLocumDataClear={onLocumDataClear}
                            onGphcChange={onGphcChange}
                            defaultDate={moment(jobData.startDate)}
                            onCancelClick={() => toggleCancelJob()}
                            onSubmitClick={onPostJob}
                            onSubmitBulk={(data) => onSubmitJobBulk(data)}
                            onSubmitUpdate={(data) => onPostJob(data)}
                            locums={locums}
                            onLocumsInitialGet={onLocumsInitialGet}
                            clearGetJobLocumsByFilter={clearGetJobLocumsByFilter}
                            mileageRules={mileageRules}
                            transportRules={transportRules}
                            parkingRules={parkingRules}
                            accommodationRules={accommodationRules}
                        />
                    </EditTemplateModal>
                )}
                {cancelJob && (
                    <ConfirmModal
                        open={cancelJob}
                        onClose={() => toggleCancelJob()}
                        title="Cancel job"
                        text="Are you sure you want to cancel this job?"
                        button={cancelJobButton}
                        showButton={false}
                        renderForm={() => <ConfirmModalForm cancellationReasons={reasons} button={cancelJobButton} />}
                    />
                )}
            </div>
        );
    }
}
