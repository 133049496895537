import { PharmaciesListPage } from './PharmaciesListPage';
import { connect } from 'react-redux';

import {
    getPharmaciesListRequest,
    onPharmaciesFilterChange,
    clearPharmaciesFilters
} from '../../../../../store/actions';

export default connect(
    (state) => ({
        pharmaciesPage: state.adminPharmaciesPage
    }),
    {
        getPharmaciesListRequest,
        onPharmaciesFilterChange,
        clearPharmaciesFilters
    }
)(PharmaciesListPage);
