import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import classnames from 'classnames';
import TextInput from './../../../UI/inputs/text-input';
import MaskedInput from '../../../UI/inputs/masked-input';
import LocationSearchInput from '../../../UI/inputs/location-search-input';
import ButtonComponent from './../../../UI/buttons/button';
import RadioGroup from './../../../UI/radio-group';
import UploadFileHandler from '../../../upload-remove-file-handler';
import ChangePasswordForm from '../../change-password-form';
import BasicModal from '../../../modals/basic-modal';

import { store } from 'react-notifications-component';

import {
    emptyInputError,
    infoInputError,
    phoneError,
    zipError,
    nameError,
    dobInputError
} from '../../../../services/validate';
import { getSize, getExtention, getAddressFields } from '../../../../services/helpers';
import notificationSettings from '../../../../services/notificationSettings';
import Moment from 'moment';

import { Form } from 'semantic-ui-react';

import './style.scss';

class PersonalInfoForm extends Component {
    state = {
        name: !this.props.name ? '' : this.props.name,
        surname: !this.props.surname ? '' : this.props.surname,
        gender: 'Male',
        gphcNumber: '',
        dateOfBirth: '',
        country: '',
        phone: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        email: '',
        latitude: null,
        longitude: null,
        avatar: '',
        loading: false,
        modals: {
            changePassword: false,
            changePasswordConfirm: false
        },
        uploadedFiles: {
            avatar: ''
        },
        errors: {
            name: null,
            surname: null,
            gender: null,
            dateOfBirth: null,
            country: null,
            phone: null,
            city: null,
            address1: null,
            address2: null,
            zipCode: null,
            avatar: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;

        const { filledForm } = this.props;

        if (filledForm) {
            for (let key in filledForm) {
                const initInputValue = '';
                if (filledForm[key] === null) filledForm[key] = initInputValue;
            }

            const {
                name,
                surname,
                gender,
                phone,
                city,
                address1,
                address2,
                zipCode,
                dob,
                image,
                gphcNumber,
                provisionallyRegisterNumber,
                email,
                latitude,
                longitude
            } = filledForm;

            this.setState({
                name,
                surname,
                gender,
                phone,
                city,
                address1,
                address2,
                zipCode,
                dateOfBirth: dob ? Moment(dob).format('DD/MM/YYYY') : '',
                avatar: image,
                gphcNumber: gphcNumber || provisionallyRegisterNumber,
                email,
                latitude,
                longitude
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    triggerInputFile = (e) => {
        e.preventDefault();
        this.fileInput.click();
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;

        switch (name) {
            case 'name':
            case 'surname':
            case 'city':
                error = nameError(value);
                break;
            case 'address1':
                error = emptyInputError(value);
                break;
            case 'address2':
                error = infoInputError(value, true);
                break;
            case 'phone':
                error = phoneError(value);
                break;
            case 'dateOfBirth':
                error = dobInputError(value);
                break;
            case 'zipCode':
                error = zipError(value);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    toggleModal = (name) => {
        const { modals } = this.state;
        const currentState = modals[name];

        return this.setState({
            modals: { ...modals, [name]: !currentState }
        });
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        let inputValue = value;

        let setCoords = {};

        if (name === 'address1') {
            setCoords = {
                latitude: null,
                longitude: null
            };
        }

        if (name === 'name' || name === 'surname' || name === 'city') {
            inputValue = value.replace(/[0-9]/g, '');
        }
        if (name === 'phone') inputValue = value.replace(/[a-zA-Zа-яА-Я]/g, '');

        return this.setState(
            {
                [name]: /^\s/.test(inputValue) ? '' : inputValue,
                ...setCoords
            },
            () => this.validate(name)
        );
    };

    handleAddressSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            let getStateProps = {
                zipCode: 'postal_code',
                city: ['locality', 'postal_town']
            };
            let getAddressProps = {
                streetNumber: 'street_number',
                streetName: 'route',
                district: ['administrative_area_level_2', 'political']
            };
            const getAddressLine = Object.values(getAddressFields(results[0], getAddressProps)).join(', ');
            const getFieldsResult = getAddressFields(results[0], getStateProps);
            const { lat, lng } = await getLatLng(results[0]);

            this.setState(
                (prevState) => ({
                    ...prevState,
                    address1: getAddressLine,
                    latitude: lat,
                    longitude: lng,
                    ...getFieldsResult
                }),
                () => Promise.all([this.validate('address1'), this.validate('zipCode')])
            );
        } catch (error) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Error',
                message: 'No results found',
                type: 'danger'
            });
        }
    };

    onLocationBlurHandler = () => {
        const { latitude, longitude } = this.state;

        if (!latitude && !longitude) {
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    address1: 'You should choose the address from the dropdown list'
                }
            }));
        }
    };

    onLogoChange = (e) => {
        const { errors } = this.state;
        const name = e.target.name;
        const value = e.target.value;
        const file = e.target.files[0];
        let errorMessage = null;

        // validate uploaded file
        errorMessage = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.bmp']);
        errorMessage = getSize(file);

        // display error message
        if (errorMessage !== null) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Warning',
                message: errorMessage,
                type: 'warning'
            });
        }

        this.setState({
            uploadedFiles: {
                [name]: file,
                [name + 'URL']: URL.createObjectURL(file)
            },
            [name]: value,
            errors: {
                ...errors,
                form: errorMessage
            }
        });
    };

    onFileRemoveHandler = (name) => {
        const {
            filledForm: { image },
            onRemoveFile
        } = this.props;

        if (image) {
            onRemoveFile({
                profileType: 'userProfile',
                fileName: 'image'
            });
        }
        this.setState((prevState) => ({
            ...prevState,
            [name]: '',
            uploadedFiles: {
                ...prevState.uploadedFiles,
                [name]: '',
                [`${name}URL`]: ''
            },
            errors: {
                ...prevState.errors,
                form: null
            }
        }));
    };

    onChangePassSuccess = (password) => {
        const { profileResetPassSubmit } = this.props;
        profileResetPassSubmit({
            password,
            toggleModal: () => {
                this.toggleModal('changePassword');
                this.toggleModal('changePasswordConfirm');
            }
        });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('name'),
            this.validate('surname'),
            this.validate('phone'),
            this.validate('city'),
            this.validate('address1'),
            this.validate('dateOfBirth'),
            this.validate('zipCode')
        ]);

        const {
            name,
            surname,
            gender,
            dateOfBirth,
            phone,
            city,
            address1,
            address2,
            zipCode,
            latitude,
            longitude,
            uploadedFiles,
            errors
        } = this.state;
        const { onSuccess } = this.props;

        if (Object.values(errors).some((error) => !!error)) return;

        const personalInfoData = {
            name,
            surname,
            gender,
            phone,
            city,
            address1,
            address2,
            zipCode,
            latitude,
            longitude,
            dob: Moment(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
        };

        // check if image was uploaded
        if (uploadedFiles.avatar) personalInfoData.image = uploadedFiles.avatar;

        onSuccess(personalInfoData);
    };

    render() {
        const {
            name,
            surname,
            gphcNumber,
            gender,
            phone,
            dateOfBirth,
            city,
            address1,
            address2,
            latitude,
            longitude,
            zipCode,
            avatar,
            email,
            uploadedFiles,
            modals: { changePassword },
            errors
        } = this.state;
        const { loading, removingLoading, changePassLoading, isEditProfile, onGoBack, userEmail } = this.props;

        const radioButtons = [
            {
                id: 0,
                label: 'Male',
                name: 'gender',
                value: 'Male',
                checked: gender === 'Male',
                onChange: this.onChangeHandler
            },
            {
                id: 1,
                label: 'Female',
                name: 'gender',
                value: 'Female',
                checked: gender === 'Female',
                onChange: this.onChangeHandler
            }
        ];

        let heading,
            bioSubHeading,
            contactsSubHeading = null;

        if (isEditProfile) {
            heading = <h3 className="staff-info-page__title">Personal Information</h3>;
            bioSubHeading = <h4 className="row-title">Bio</h4>;
            contactsSubHeading = <h4 className="row-title">Contacts</h4>;
        }

        return (
            <>
                <Form
                    className={classnames('personal-info-form', { isEditProfile })}
                    autoComplete="nope"
                    loading={loading}
                >
                    <div className="personal-info-form__row">
                        <div className="col">
                            {bioSubHeading}
                            <div className="input-row no-margin">
                                <TextInput
                                    name="name"
                                    label="First Name"
                                    type="text"
                                    value={name}
                                    disabled
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    onChange={this.onChangeHandler}
                                />
                                <MaskedInput
                                    name="dateOfBirth"
                                    value={dateOfBirth}
                                    label="Date of Birth"
                                    type="text"
                                    error={errors.dateOfBirth}
                                    helperText={errors.dateOfBirth}
                                    onChange={this.onChangeHandler}
                                    placeholder="DD/MM/YYYY"
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                />
                            </div>
                            <div className="input-row">
                                <TextInput
                                    name="surname"
                                    label="Last Name"
                                    type="text"
                                    value={surname}
                                    disabled
                                    error={!!errors.surname}
                                    helperText={errors.surname}
                                    onChange={this.onChangeHandler}
                                />
                                <TextInput
                                    name="gphcNumber"
                                    label="GPhC Number"
                                    type="text"
                                    value={String(gphcNumber)}
                                    disabled
                                    error={!!errors.gphcNumber}
                                    helperText={errors.gphcNumber}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <RadioGroup items={radioButtons} groupLabel="Gender" />
                            <div className={classnames('input-row', { grid: isEditProfile })}>
                                {isEditProfile && (
                                    <div className="security-btn">
                                        <label className="upload-label">Security</label>
                                        <ButtonComponent
                                            value="Change Password"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                this.toggleModal('changePassword');
                                            }}
                                            size="medium"
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                                <div
                                    className={classnames('avatar-holder', {
                                        small: isEditProfile
                                    })}
                                >
                                    <label className="upload-label">Profile Picture</label>
                                    <UploadFileHandler
                                        inputName="avatar"
                                        buttonValue="Upload"
                                        stateProp={avatar}
                                        isAvatar
                                        uploadedFile={uploadedFiles.avatar}
                                        avatarURL={uploadedFiles.avatarURL}
                                        onChangeHandler={this.onLogoChange}
                                        onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                        refFunc={(fileInput) => (this.fileInput = fileInput)}
                                        triggerInput={this.triggerInputFile}
                                        loading={removingLoading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {contactsSubHeading}
                            <LocationSearchInput
                                name="address1"
                                label="Address Line 1"
                                type="text"
                                value={address1}
                                searchOptions={{
                                    componentRestrictions: { country: 'uk' }
                                }}
                                fluid={false}
                                error={!!errors.address1}
                                helperText={errors.address1}
                                onChangeHandler={this.onChangeHandler}
                                onHandleSelect={this.handleAddressSelect}
                                onBlur={() => this.onLocationBlurHandler('address1')}
                            />
                            <TextInput
                                name="address2"
                                label="Address Line 2"
                                type="text"
                                value={address2}
                                fluid={false}
                                error={!!errors.address2}
                                helperText={errors.address2}
                                onChange={this.onChangeHandler}
                            />
                            <div className="input-row">
                                <TextInput
                                    name="city"
                                    label="Town/City"
                                    type="text"
                                    value={city}
                                    error={!!errors.city}
                                    helperText={errors.city}
                                    onChange={this.onChangeHandler}
                                />
                                <TextInput
                                    name="zipCode"
                                    label="Post code"
                                    type="text"
                                    value={zipCode}
                                    error={!!errors.zipCode}
                                    helperText={errors.zipCode}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <TextInput
                                name="email"
                                label="Email"
                                type="email"
                                value={email}
                                error={!!errors.email}
                                helperText={errors.email}
                                onChange={this.onChangeHandler}
                                disabled
                            />
                            <TextInput
                                name="phone"
                                label="Phone"
                                type="tel"
                                value={phone}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                onChange={this.onChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="personal-info-form__row button-row">
                        {heading}
                        <div className="buttons-holder">
                            {isEditProfile && (
                                <ButtonComponent
                                    value="Cancel"
                                    type="transparent"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onGoBack();
                                    }}
                                    size="medium"
                                    disabled={loading}
                                />
                            )}
                            <ButtonComponent
                                value="Save"
                                onClick={this.onSubmit}
                                size="medium"
                                disabled={Object.values(errors).some((error) => !!error) || !latitude || !longitude}
                            />
                        </div>
                    </div>
                </Form>
                {changePassword && (
                    <BasicModal
                        open={changePassword}
                        onClose={() => this.toggleModal('changePassword')}
                        title="Change password"
                    >
                        <ChangePasswordForm
                            onSuccess={(password) => this.onChangePassSuccess(password)}
                            loading={changePassLoading}
                            defaultEmail={userEmail}
                            onCancelClick={() => this.toggleModal('changePassword')}
                        />
                    </BasicModal>
                )}
            </>
        );
    }
}

PersonalInfoForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    name: PropTypes.string,
    surname: PropTypes.string,
    role: PropTypes.string,
    filledForm: PropTypes.object,
    onRemoveFile: PropTypes.func.isRequired,
    changePassLoading: PropTypes.bool,
    removingLoading: PropTypes.bool,
    isEditProfile: PropTypes.bool,
    onGoBack: PropTypes.func,
    userEmail: PropTypes.string
};

export default PersonalInfoForm;
