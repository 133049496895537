import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

function TransactionsTable({ data }) {
    const transactionsList = data
        ? data.map((item) => {
              const { id, date, plan, total, status, redirect_url } = item;
              return (
                  <Table.Row key={id}>
                      <Table.Cell>{moment(date, 'YYYY-MM-DD').format('Do/MM/YYYY')}</Table.Cell>
                      <Table.Cell>{`£${total}`}</Table.Cell>
                      <Table.Cell>{plan}</Table.Cell>
                      <Table.Cell>
                          {redirect_url ? (
                              <a href={redirect_url} target="_blank" rel="noopener noreferrer">
                                  {status}
                              </a>
                          ) : (
                              status
                          )}
                      </Table.Cell>
                  </Table.Row>
              );
          })
        : null;

    return (
        <Table compact="very" className="transactions-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Subscription</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{transactionsList}</Table.Body>
        </Table>
    );
}

TransactionsTable.propTypes = {
    data: PropTypes.array
};

export default TransactionsTable;
