import {
    RESEND_ACTIVATION_LINK_REQUEST,
    RESEND_ACTIVATION_LINK_SUCCESS,
    RESEND_ACTIVATION_LINK_FAILED
} from './action-types';

export const resendActivationLinkRequest = (payload) => ({
    type: RESEND_ACTIVATION_LINK_REQUEST,
    payload
});

export const resendActivationLinkSuccess = () => ({
    type: RESEND_ACTIVATION_LINK_SUCCESS
});

export const resendActivationLinkFailed = () => ({
    type: RESEND_ACTIVATION_LINK_FAILED
});
