import { connect } from 'react-redux';
import {
    createMileageRuleRequest,
    createParkingRuleRequest,
    createAccommodationRuleRequest,
    createTransportRuleRequest,
    getAllMileageRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    getAllTransportRulesRequest,
    mileageRuleDeleteRequest,
    parkingRuleDeleteRequest,
    accommodationRuleDeleteRequest,
    transportRuleDeleteRequest,
    updateMasterDateRequest,
    getMasterDateRequest
} from '../../../../../store/actions';
import PharmacySettingsPage from './PharmarcySettingsPage';

export default connect(
    (state) => ({
        mileageRules: state.pharmacySettingsPage.mileageRules,
        parkingRules: state.pharmacySettingsPage.parkingRules,
        transportRules: state.pharmacySettingsPage.transportRules,
        accommodationRules: state.pharmacySettingsPage.accommodationRules,
        masterDate: state.pharmacySettingsPage.masterDate
    }),
    {
        createMileageRuleRequest,
        createParkingRuleRequest,
        createAccommodationRuleRequest,
        createTransportRuleRequest,
        getAllMileageRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest,
        getAllTransportRulesRequest,
        mileageRuleDeleteRequest,
        parkingRuleDeleteRequest,
        accommodationRuleDeleteRequest,
        transportRuleDeleteRequest,
        updateMasterDateRequest,
        getMasterDateRequest
    }
)(PharmacySettingsPage);
