import React, { useEffect } from 'react';
import StaffProfilePage from '../../shared/staff-profile-page';

export function LocumInfoPage({ getPersonalInfoRequest, personalInfo }) {
    useEffect(() => {
        getPersonalInfoRequest();
    }, [getPersonalInfoRequest]);

    if (!personalInfo.data) {
        return null;
    } else
        return (
            <StaffProfilePage
                personalInfo={personalInfo.data}
                updateInfoAction={getPersonalInfoRequest}
                isPersonalProfile
            />
        );
}
