import {
    GET_LOCUM_BY_GPHC_REQUEST,
    GET_LOCUM_BY_GPHC_SUCCESS,
    GET_LOCUM_BY_GPHC_FAILED,
    GET_LOCUM_SCHEDULE_AVAILABILITY_REQUEST,
    GET_LOCUM_SCHEDULE_AVAILABILITY_SUCCESS,
    GET_LOCUM_SCHEDULE_AVAILABILITY_FAILED,
    CLEAR_LOCUM_DATA
} from './action-types';

export const getLocumByGphcRequest = (payload) => ({
    type: GET_LOCUM_BY_GPHC_REQUEST,
    payload
});

export const getLocumScheduleAvailabilityRequest = (payload) => ({
    type: GET_LOCUM_SCHEDULE_AVAILABILITY_REQUEST,
    payload
});

export const getLocumScheduleAvailabilitySuccess = (payload) => ({
    type: GET_LOCUM_SCHEDULE_AVAILABILITY_SUCCESS,
    payload
});

export const getLocumScheduleAvailabilityFailed = (payload) => ({
    type: GET_LOCUM_SCHEDULE_AVAILABILITY_FAILED,
    payload
});

export const getLocumByGphcSuccess = (payload) => ({
    type: GET_LOCUM_BY_GPHC_SUCCESS,
    payload
});

export const getLocumByGphcFailed = (payload) => ({
    type: GET_LOCUM_BY_GPHC_FAILED,
    payload
});

export const clearLocumData = () => ({
    type: CLEAR_LOCUM_DATA
});
