import {
    CREATE_MILEAGE_RULE_REQUEST,
    CREATE_MILEAGE_RULE_SUCCESS,
    CREATE_MILEAGE_RULE_FAILED,
    CREATE_TRANSPORT_RULE_REQUEST,
    CREATE_TRANSPORT_RULE_SUCCESS,
    CREATE_TRANSPORT_RULE_FAILED,
    CREATE_ACCOMMODATION_RULE_REQUEST,
    CREATE_ACCOMMODATION_RULE_SUCCESS,
    CREATE_ACCOMMODATION_RULE_FAILED,
    CREATE_PARKING_RULE_REQUEST,
    CREATE_PARKING_RULE_SUCCESS,
    CREATE_PARKING_RULE_FAILED,
    DELETE_MILEAGE_RULE_REQUEST,
    DELETE_MILEAGE_RULE_SUCCESS,
    DELETE_MILEAGE_RULE_FAILED,
    GET_ALL_MILEAGE_RULES_REQUEST,
    GET_ALL_MILEAGE_RULES_SUCCESS,
    GET_ALL_MILEAGE_RULES_FAILED,
    DELETE_ACCOMMODATION_RULE_REQUEST,
    DELETE_ACCOMMODATION_RULE_SUCCESS,
    DELETE_ACCOMMODATION_RULE_FAILED,
    DELETE_PARKING_RULE_REQUEST,
    DELETE_PARKING_RULE_SUCCESS,
    DELETE_PARKING_RULE_FAILED,
    DELETE_TRANSPORT_RULE_REQUEST,
    DELETE_TRANSPORT_RULE_SUCCESS,
    DELETE_TRANSPORT_RULE_FAILED,
    GET_ALL_ACCOMMODATION_RULES_REQUEST,
    GET_ALL_ACCOMMODATION_RULES_SUCCESS,
    GET_ALL_ACCOMMODATION_RULES_FAILED,
    GET_ALL_PARKING_RULES_REQUEST,
    GET_ALL_PARKING_RULES_SUCCESS,
    GET_ALL_PARKING_RULES_FAILED,
    GET_ALL_TRANSPORT_RULES_REQUEST,
    GET_ALL_TRANSPORT_RULES_SUCCESS,
    GET_ALL_TRANSPORT_RULES_FAILED,
    UPDATE_MASTER_DATE_REQUEST,
    UPDATE_MASTER_DATE_SUCCESS,
    UPDATE_MASTER_DATE_FAILED,
    GET_MASTER_DATE_REQUEST,
    GET_MASTER_DATE_SUCCESS,
    GET_MASTER_DATE_FAILED
} from './action-types';

export const createMileageRuleRequest = (payload) => ({
    type: CREATE_MILEAGE_RULE_REQUEST,
    payload
});

export const createMileageRuleSuccess = (payload) => ({
    type: CREATE_MILEAGE_RULE_SUCCESS,
    payload
});

export const createMileageRuleFailed = () => ({
    type: CREATE_MILEAGE_RULE_FAILED
});

export const createTransportRuleRequest = (payload) => ({
    type: CREATE_TRANSPORT_RULE_REQUEST,
    payload
});

export const createTransportRuleSuccess = (payload) => ({
    type: CREATE_TRANSPORT_RULE_SUCCESS,
    payload
});

export const createTransportRuleFailed = () => ({
    type: CREATE_TRANSPORT_RULE_FAILED
});

export const createAccommodationRuleRequest = (payload) => ({
    type: CREATE_ACCOMMODATION_RULE_REQUEST,
    payload
});

export const createAccommodationRuleSuccess = (payload) => ({
    type: CREATE_ACCOMMODATION_RULE_SUCCESS,
    payload
});

export const createAccommodationRuleFailed = () => ({
    type: CREATE_ACCOMMODATION_RULE_FAILED
});

export const createParkingRuleRequest = (payload) => ({
    type: CREATE_PARKING_RULE_REQUEST,
    payload
});

export const createParkingRuleSuccess = (payload) => ({
    type: CREATE_PARKING_RULE_SUCCESS,
    payload
});

export const createParkingRuleFailed = () => ({
    type: CREATE_PARKING_RULE_FAILED
});

export const mileageRuleDeleteRequest = ({ id }) => ({
    type: DELETE_MILEAGE_RULE_REQUEST,
    id
});

export const mileageRuleDeleteSuccess = ({ id }) => ({
    type: DELETE_MILEAGE_RULE_SUCCESS,
    id
});

export const mileageRuleDeleteFailed = () => ({
    type: DELETE_MILEAGE_RULE_FAILED
});

export const transportRuleDeleteRequest = ({ id }) => ({
    type: DELETE_TRANSPORT_RULE_REQUEST,
    id
});

export const transportRuleDeleteSuccess = ({ id }) => ({
    type: DELETE_TRANSPORT_RULE_SUCCESS,
    id
});

export const transportRuleDeleteFailed = () => ({
    type: DELETE_TRANSPORT_RULE_FAILED
});

export const parkingRuleDeleteRequest = ({ id }) => ({
    type: DELETE_PARKING_RULE_REQUEST,
    id
});

export const parkingRuleDeleteSuccess = ({ id }) => ({
    type: DELETE_PARKING_RULE_SUCCESS,
    id
});

export const parkingRuleDeleteFailed = () => ({
    type: DELETE_PARKING_RULE_FAILED
});

export const accommodationRuleDeleteRequest = ({ id }) => ({
    type: DELETE_ACCOMMODATION_RULE_REQUEST,
    id
});

export const accommodationRuleDeleteSuccess = ({ id }) => ({
    type: DELETE_ACCOMMODATION_RULE_SUCCESS,
    id
});

export const accommodationRuleDeleteFailed = () => ({
    type: DELETE_ACCOMMODATION_RULE_FAILED
});

export const getAllMileageRulesRequest = (payload) => ({
    type: GET_ALL_MILEAGE_RULES_REQUEST,
    payload
});

export const getAllMileageRulesSuccess = (payload) => ({
    type: GET_ALL_MILEAGE_RULES_SUCCESS,
    payload
});

export const getAllMileageRulesFailed = () => ({
    type: GET_ALL_MILEAGE_RULES_FAILED
});

export const getAllTransportRulesRequest = (payload) => ({
    type: GET_ALL_TRANSPORT_RULES_REQUEST,
    payload
});

export const getAllTransportRulesSuccess = (payload) => ({
    type: GET_ALL_TRANSPORT_RULES_SUCCESS,
    payload
});

export const getAllTransportRulesFailed = () => ({
    type: GET_ALL_TRANSPORT_RULES_FAILED
});

export const getAllParkingRulesRequest = (payload) => ({
    type: GET_ALL_PARKING_RULES_REQUEST,
    payload
});

export const getAllParkingRulesSuccess = (payload) => ({
    type: GET_ALL_PARKING_RULES_SUCCESS,
    payload
});

export const getAllParkingRulesFailed = () => ({
    type: GET_ALL_PARKING_RULES_FAILED
});

export const getAllAccommodationRulesRequest = (payload) => ({
    type: GET_ALL_ACCOMMODATION_RULES_REQUEST,
    payload
});

export const getAllAccommodationRulesSuccess = (payload) => ({
    type: GET_ALL_ACCOMMODATION_RULES_SUCCESS,
    payload
});

export const getAllAccommodationRulesFailed = () => ({
    type: GET_ALL_ACCOMMODATION_RULES_FAILED
});

export const updateMasterDateRequest = (payload) => ({
    type: UPDATE_MASTER_DATE_REQUEST,
    payload
});

export const updateMasterDateSuccess = (payload) => ({
    type: UPDATE_MASTER_DATE_SUCCESS,
    payload
});

export const updateMasterDateFailed = () => ({
    type: UPDATE_MASTER_DATE_FAILED
});

export const getMasterDateRequest = (payload) => ({
    type: GET_MASTER_DATE_REQUEST,
    payload
});

export const getMasterDateSuccess = (payload) => ({
    type: GET_MASTER_DATE_SUCCESS,
    payload
});

export const getMasterDateFailed = () => ({
    type: GET_MASTER_DATE_FAILED
});
