import { OnboardingPage } from './OnboardingPage';
import { connect } from 'react-redux';
import {
    getPersonalInfoRequest,
    clearPersonalInfo,
    postPersonalInfoRequest,
    getRightsToWorkInfoRequest,
    postRightsToWorkInfoRequest,
    getSkillsInfoRequest,
    postSkillsInfoRequest,
    getAccreditationsInfoRequest,
    postAccreditationsInfoRequest,
    removeUploadedFileRequest,
    getProfileDataRequest
} from '../../../../store/actions';

export default connect(
    (state) => ({
        user: state.auth.user,
        personalInfo: state.userProfileInfo.personalInfo,
        rigthsToWork: state.userProfileInfo.rigthsToWork,
        skills: state.userProfileInfo.skills,
        accreditations: state.userProfileInfo.accreditations,
        removingLoading: state.userProfileInfo.loading
    }),
    {
        postPersonalInfoRequest,
        getPersonalInfoRequest,
        getRightsToWorkInfoRequest,
        postRightsToWorkInfoRequest,
        getSkillsInfoRequest,
        postSkillsInfoRequest,
        getAccreditationsInfoRequest,
        postAccreditationsInfoRequest,
        removeUploadedFileRequest,
        clearPersonalInfo,
        getProfileDataRequest
    }
)(OnboardingPage);
