import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Image } from 'semantic-ui-react';

import './style.scss';

const BranchCard = ({ logo, title, email = '', id, number, region, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <Card className="branch-card">
            <Image
                className={classnames({
                    error: !logo
                })}
                style={loaded ? { opacity: '1' } : {}}
                onLoad={() => setLoaded(true)}
                src={logo}
                wrapped
                ui={false}
            />
            <Card.Content>
                <Card.Header className="branch-card__title">
                    <p>{!title ? '' : title}</p>
                    <span className="id">{`#${number || id}`}</span>
                </Card.Header>
                <Card.Description>
                    <p>{region}</p>
                    <p className="email">{email}</p>
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

BranchCard.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default BranchCard;
