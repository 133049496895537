import React, { useEffect } from 'react';
import OnboardingPage from '../shared/onboarding-page';
import VerificationPage from '../shared/verification-page';
import ManagerInfoPage from './info-page';
import ManagerSchedule from './schedule';

import { Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { PrivateRoute } from '../../../services/privateRoute';

import Header from '../../../components/header';
import Sidebar from '../../sidebar';
import ContentContainer from '../../../components/content-container';
import ProfileCard from '../../../components/profile-card';

import ErrorBoundary from '../../error-boundary';

import { RouterConfig } from './../../../routerConfig';
import { userStatusesList } from '../../../userConfig';
import { userStatusCheck } from '../../../services/helpers';
import StaffAbsencePage from '../admin-view/staff-page/staff-absence';
import StaffContractPage from '../admin-view/staff-page/staff-contract';

const ManagerView = ({ user, roles, ...props }) => {
    const redirect = userStatusCheck(user.status, 'manager');
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (user.status === userStatusesList.ACTIVE && pathname === RouterConfig.manager.onboarding)
            history.replace(redirect);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sidebarLinks = [
        {
            text: 'onboarding',
            to: RouterConfig.manager.onboarding,
            disabled: false,
            hidden: user.status === userStatusesList.ACTIVE
        },
        {
            text: 'Personal information',
            to: RouterConfig.manager.personalInfo,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        },
        {
            text: 'schedule',
            to: RouterConfig.manager.schedule,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        },
        {
            text: 'absence',
            to: RouterConfig.manager.absence,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        },
        {
            text: 'contract',
            to: RouterConfig.manager.contract,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        }
    ];

    const profileTab =
        user.status === userStatusesList.ACTIVE
            ? {
                  title: `${user.name} ${user.surname}`,
                  to: RouterConfig.manager.personalInfo,
                  image: user.image
              }
            : { title: '', to: RouterConfig.root };

    return (
        <div className="content-view-page">
            <Header profileTab={profileTab} />
            <ErrorBoundary>
                <div className="main-wrapper">
                    <div className="content-holder">
                        {user.status !== userStatusesList.NEW && (
                            <Sidebar links={sidebarLinks} visible>
                                <ProfileCard
                                    name={user.name}
                                    surname={user.surname}
                                    role={user.role}
                                    avatar={user.image}
                                />
                            </Sidebar>
                        )}
                        <ContentContainer
                            transparent={user.status === userStatusesList.NEW}
                            autoHeight={user.status === userStatusesList.NEW}
                        >
                            <Switch>
                                <PrivateRoute
                                    path={RouterConfig.manager.onboarding}
                                    user={user.role}
                                    roles={roles}
                                    component={OnboardingPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.manager.verification}
                                    user={user.role}
                                    roles={roles}
                                    component={VerificationPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.manager.personalInfo}
                                    user={user.role}
                                    roles={roles}
                                    component={ManagerInfoPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.manager.schedule}
                                    user={user.role}
                                    roles={roles}
                                    component={ManagerSchedule}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.manager.absence}
                                    user={user.role}
                                    roles={roles}
                                    component={StaffAbsencePage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.manager.contract}
                                    user={user.role}
                                    roles={roles}
                                    component={() => <StaffContractPage staffId={user.id} />}
                                    exact
                                />
                                <Redirect to={redirect} exact />
                            </Switch>
                        </ContentContainer>
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default ManagerView;
