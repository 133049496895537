import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { authSuccess, onValidateGphcSuccess, onValidateGphcFailed } from '../../actions';
import * as locumCheckInActionTypes from '../../actions/locum-check-in-page/action-types';
import api from '../../../services/api';

function* onValidateGphcRequest({ payload: { id, gphcNumber, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/auth/validate-gphc/${id}`,
            method: 'post',
            payload: { gphcNumber: +gphcNumber }
        });
        yield put(onValidateGphcSuccess());
        api.setAccessToken(data.access);
        yield put(authSuccess(data));
        callback();
    } catch (err) {
        yield put(onValidateGphcFailed());
    }
}

function* watchValidateGphcRequest() {
    yield takeEvery(locumCheckInActionTypes.ON_VALIDATE_GPHC_REQUEST, onValidateGphcRequest);
}

const locumCheckInSaga = [fork(watchValidateGphcRequest)];

export default locumCheckInSaga;
