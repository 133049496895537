import * as actionTypes from 'store/actions/admin-view/staff-contract/action-types';

const initialState = {
    data: {},
    loading: false,
    minDate: null,
    list: {
        data: {},
        totalData: 0,
        itemsPerPage: 9
    }
};

const staffContractPage = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_STAFF_CONTRACT_REQUEST:
        case actionTypes.POST_STAFF_CONTRACT_REQUEST:
        case actionTypes.GET_STAFF_CONTRACT_LIST_REQUEST:
        case actionTypes.START_STAFF_CONTRACT_REQUEST:
        case actionTypes.GET_STAFF_CONTRACT_BY_ID_REQUEST:
        case actionTypes.DELETE_STAFF_CONTRACT_REQUEST:
        case actionTypes.GET_CONTRACT_MIN_DATE_REQUEST:
        case actionTypes.UPDATE_STAFF_CONTRACT_REQUEST:
        case actionTypes.DUPLICATE_CONTRACT_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.GET_STAFF_CONTRACT_BY_ID_SUCCESS:
        case actionTypes.GET_STAFF_CONTRACT_SUCCESS:
        case actionTypes.UPDATE_STAFF_CONTRACT_SUCCESS:
        case actionTypes.POST_STAFF_CONTRACT_SUCCESS:
        case actionTypes.START_STAFF_CONTRACT_SUCCESS:
        case actionTypes.DUPLICATE_CONTRACT_SUCCESS:
            return {
                ...state,
                data: payload.data,
                loading: false
            };

        case actionTypes.GET_STAFF_CONTRACT_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: { ...state.list, data: payload.data }
            };
        }

        case actionTypes.DELETE_STAFF_CONTRACT_SUCCESS: {
            const newList = state.list.filter(({ id }) => id !== payload.contractId);
            return {
                ...state,
                loading: false,
                list: { ...state.list, data: { ...state.list.data, rows: newList } }
            };
        }

        case actionTypes.GET_CONTRACT_MIN_DATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                minDate: new Date(payload.data)
            };
        }

        case actionTypes.DUPLICATE_CONTRACT_FAILED:
        case actionTypes.GET_CONTRACT_MIN_DATE_FAILED:
        case actionTypes.START_STAFF_CONTRACT_FAILED:
        case actionTypes.DELETE_STAFF_CONTRACT_FAILED:
        case actionTypes.UPDATE_STAFF_CONTRACT_FAILED:
        case actionTypes.GET_STAFF_CONTRACT_BY_ID_FAILED:
        case actionTypes.GET_STAFF_CONTRACT_LIST_FAILED:
        case actionTypes.GET_STAFF_CONTRACT_FAILED:
        case actionTypes.POST_STAFF_CONTRACT_FAILED:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};

export default staffContractPage;
