import { CreateBranchContainer } from './CreateBranchContainer';
import { connect } from 'react-redux';
import { getBranchesDataRequest, onCreateBranchRequest } from '../../store/actions/';

export default connect(
    (state) => ({
        data: state.createBranch.branchesData,
        loading: state.createBranch.loading
    }),
    {
        getBranchesDataRequest,
        onCreateBranchRequest
    }
)(CreateBranchContainer);
