import { call, put, fork, throttle } from 'redux-saga/effects';
import {
    getLocumByGphcSuccess,
    getLocumByGphcFailed,
    getLocumScheduleAvailabilitySuccess,
    getLocumScheduleAvailabilityFailed
} from '../../../actions';
import * as locumActionTypes from '../../../actions/admin-view/locum/action-types';
import api from '../../../../services/api';

function* onLocumByGphcRequest({ payload: { gphcNumber } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/locums/gphc/${gphcNumber}`,
            method: 'get'
        });
        yield put(getLocumByGphcSuccess(data));
    } catch (err) {
        yield put(getLocumByGphcFailed(err));
    }
}

function* onLocumScheduleAvailabilityRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/locums/schedule-available`,
            method: 'post',
            payload
        });
        yield put(getLocumScheduleAvailabilitySuccess(data));
    } catch (err) {
        yield put(getLocumScheduleAvailabilityFailed(err));
    }
}

function* watchLocumByGphcRequest() {
    yield throttle(1500, locumActionTypes.GET_LOCUM_BY_GPHC_REQUEST, onLocumByGphcRequest);
}
function* watchLocumScheduleAvailabilityRequest() {
    yield throttle(1500, locumActionTypes.GET_LOCUM_SCHEDULE_AVAILABILITY_REQUEST, onLocumScheduleAvailabilityRequest);
}

const locumSaga = [fork(watchLocumByGphcRequest), fork(watchLocumScheduleAvailabilityRequest)];

export default locumSaga;
