import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';
import classnames from 'classnames';

import './style.scss';

const SelectComponent = React.forwardRef(
    (
        {
            name,
            label,
            value,
            defaultValue,
            onChange,
            onSearchChange,
            options,
            search = false,
            icon = null,
            floating = false,
            fluid = true,
            multiple = false,
            autoComplete = 'nope',
            placeholder,
            helperText,
            error,
            className = '',
            disabled = false
        },
        ref
    ) => {
        return (
            <div className={`${classnames('input-group', className, { fluid })}`}>
                <label>{label}</label>
                <Select
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onSearchChange={onSearchChange}
                    options={options}
                    autoComplete={autoComplete}
                    error={error}
                    selectOnBlur={false}
                    disabled={disabled}
                    multiple={multiple}
                    onFocus={(e) => e.target.setAttribute('autoComplete', 'nope')}
                    floating={floating}
                    search={search}
                    icon={icon}
                    fluid
                    clearable
                    selection
                />
                <p className="helper-text">{helperText}</p>
            </div>
        );
    }
);

SelectComponent.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.bool]),
    fluid: PropTypes.bool,
    floating: PropTypes.bool,
    search: PropTypes.bool,
    icon: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSearchChange: PropTypes.func
};

export default SelectComponent;
