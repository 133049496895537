import {
    GET_PERSONAL_INFO_SUCCESS,
    GET_PERSONAL_INFO_FAILED,
    POST_PERSONAL_INFO_REQUEST,
    POST_PERSONAL_INFO_SUCCESS,
    POST_PERSONAL_INFO_FAILED,
    GET_RIGHTS_TO_WORK_INFO_SUCCESS,
    GET_RIGHTS_TO_WORK_INFO_FAILED,
    POST_RIGHTS_TO_WORK_INFO_REQUEST,
    POST_RIGHTS_TO_WORK_INFO_SUCCESS,
    POST_RIGHTS_TO_WORK_INFO_FAILED,
    GET_SKILLS_INFO_SUCCESS,
    GET_SKILLS_INFO_FAILED,
    POST_SKILLS_INFO_SUCCESS,
    POST_SKILLS_INFO_FAILED,
    GET_ACCREDITATIONS_INFO_SUCCESS,
    GET_ACCREDITATIONS_INFO_FAILED,
    CLEAR_PERSONAL_INFO,
    REMOVE_UPLOADED_FILE_REQUEST,
    REMOVE_UPLOADED_FILE_SUCCESS,
    REMOVE_UPLOADED_FILE_FAILED,
    SET_STORE_DOCUMENT,
    REMOVE_STORE_DOCUMENT,
    CLEAR_ACCREDITATIONS_DOCS,
    SET_SKILLS_DATA,
    SET_ACCREDITATIONS_CHECKBOX,
    SET_SKILLS_CHECKBOX,
    SET_SKILLS_STORE_DOCUMENT,
    REMOVE_SKILLS_DOCUMENT
} from '../../actions/profile/action-types';
import { isEmptyFieldsRemoved, getPayloadData } from '../../../services/helpers';

const initialState = {
    personalInfo: {
        data: null,
        loading: false
    },
    rigthsToWork: {
        data: null,
        loading: false
    },
    skills: {
        data: {
            cv: '',
            uploadedFiles: {
                cv: ''
            },
            independentPrescriber: null,
            methadonePatients: null,
            MBSBlistersTrays: null,
            NUMSASService: null,
            careHomes: null,
            sellingMedication: null,
            sellingMedicationText: '',
            pharmacySystems: [],
            selectedSystemsIds: []
        },
        loading: false
    },
    accreditations: {
        data: {
            medUseReview: '',
            newMedService: '',
            repeatDispensing: '',
            dementiaFriend: '',
            CPPERisk: '',
            fluJabTrained: '',
            SCR: '',
            consultationSkills: '',
            substanceMisuse: '',
            CPPEOralHelth: '',
            SDVALevel2: '',
            EHC: '',
            additionalAccreditations: null,
            selectedAccreditationsIds: [],
            uploadedFiles: {
                medUseReview: '',
                newMedService: '',
                repeatDispensing: '',
                dementiaFriend: '',
                CPPERisk: '',
                fluJabTrained: '',
                SCR: '',
                consultationSkills: '',
                substanceMisuse: '',
                CPPEOralHelth: '',
                SDVALevel2: '',
                EHC: ''
            },
            errors: {}
        },
        loading: false
    },
    loading: false
};

export const handlePersonalInfo = (state, action) => {
    if (state === undefined) {
        return initialState.personalInfo;
    }

    switch (action.type) {
        case GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state.personalInfo,
                data: action.payload
            };
        case GET_PERSONAL_INFO_FAILED:
            return state.personalInfo;
        case POST_PERSONAL_INFO_REQUEST:
            return {
                ...state.personalInfo,
                loading: true
            };
        case POST_PERSONAL_INFO_SUCCESS:
        case POST_PERSONAL_INFO_FAILED:
            return {
                ...state.personalInfo,
                loading: false
            };
        case CLEAR_PERSONAL_INFO:
            return {
                data: null,
                loading: false
            };
        default:
            return state.personalInfo;
    }
};

export const handleRigthsToWorkInfo = (state, action) => {
    if (state === undefined) {
        return initialState.rigthsToWork;
    }

    switch (action.type) {
        case GET_RIGHTS_TO_WORK_INFO_SUCCESS:
            return {
                ...state.personalInfo,
                data: action.payload
            };
        case GET_RIGHTS_TO_WORK_INFO_FAILED:
            return state.rigthsToWork;
        case POST_RIGHTS_TO_WORK_INFO_REQUEST:
            return {
                ...state.rigthsToWork,
                loading: true
            };
        case POST_RIGHTS_TO_WORK_INFO_SUCCESS:
            return {
                data: null,
                loading: false
            };
        case POST_RIGHTS_TO_WORK_INFO_FAILED:
            return {
                ...state.rigthsToWork,
                loading: false
            };
        default:
            return state.rigthsToWork;
    }
};

export const handleSkillsInfo = (state, { type, payload, name }) => {
    if (state === undefined) {
        return initialState.skills;
    }

    switch (type) {
        case GET_SKILLS_INFO_SUCCESS:
            const ignoredFieldsArray = ['userId', 'createdAt', 'updatedAt'];
            const preloadedFiles = getPayloadData(payload, ignoredFieldsArray);
            return {
                ...state.skills,
                data: {
                    ...state.skills.data,
                    ...preloadedFiles
                }
            };
        case GET_SKILLS_INFO_FAILED:
            return state.skills;
        case SET_SKILLS_DATA:
            return {
                ...state.skills,
                data: {
                    ...state.skills.data,
                    [payload.name]: payload.value
                }
            };
        case SET_SKILLS_CHECKBOX:
            return {
                ...state.skills,
                data: {
                    ...state.skills.data,
                    selectedSystemsIds: payload
                }
            };
        case SET_SKILLS_STORE_DOCUMENT:
            return {
                ...state.skills,
                data: {
                    ...state.skills.data,
                    [payload.name]: payload.value,
                    uploadedFiles: {
                        ...state.skills.data.uploadedFiles,
                        [payload.name]: payload.file
                    }
                }
            };
        case REMOVE_SKILLS_DOCUMENT:
            return {
                ...state.skills,
                data: {
                    ...state.skills.data,
                    [name]: '',
                    uploadedFiles: {
                        ...state.skills.data.uploadedFiles,
                        [name]: ''
                    }
                }
            };
        case POST_SKILLS_INFO_SUCCESS:
            return initialState.skills;
        case POST_SKILLS_INFO_FAILED:
            return state.skills;
        default:
            return state.skills;
    }
};

export const handleAccreditationsInfo = (state, { type, payload, name }) => {
    if (state === undefined) {
        return initialState.accreditations;
    }

    switch (type) {
        case GET_ACCREDITATIONS_INFO_SUCCESS:
            const preloadedFiles = isEmptyFieldsRemoved(payload);
            const getAccreditations = payload.additionalAccreditations.map((item) => item.id);
            return {
                ...state.accreditations,
                data: {
                    ...state.accreditations.data,
                    ...preloadedFiles,
                    selectedAccreditationsIds: getAccreditations
                }
            };
        case GET_ACCREDITATIONS_INFO_FAILED:
            return state.accreditations;
        case SET_STORE_DOCUMENT:
            return {
                ...state.accreditations,
                data: {
                    ...state.accreditations.data,
                    [payload.name]: payload.value,
                    uploadedFiles: {
                        ...state.accreditations.data.uploadedFiles,
                        [payload.name]: payload.file
                    },
                    errors: {
                        ...state.accreditations.data.errors,
                        [payload.name]: payload.error
                    }
                }
            };
        case REMOVE_STORE_DOCUMENT:
            return {
                ...state.accreditations,
                data: {
                    ...state.accreditations.data,
                    [name]: '',
                    uploadedFiles: {
                        ...state.accreditations.data.uploadedFiles,
                        [name]: ''
                    },
                    errors: {
                        ...state.accreditations.data.errors,
                        [name]: null
                    }
                }
            };
        case SET_ACCREDITATIONS_CHECKBOX:
            return {
                ...state.accreditations,
                data: {
                    ...state.accreditations.data,
                    selectedAccreditationsIds: payload
                }
            };
        case CLEAR_ACCREDITATIONS_DOCS:
            return initialState.accreditations;
        default:
            return state.accreditations;
    }
};

export const handleRemoveFile = (state, action) => {
    if (state === undefined) {
        return initialState.loading;
    }

    switch (action.type) {
        case REMOVE_UPLOADED_FILE_REQUEST:
            return (state.loading = true);
        case REMOVE_UPLOADED_FILE_SUCCESS:
        case REMOVE_UPLOADED_FILE_FAILED:
            return (state.loading = false);
        default:
            return state.loading;
    }
};
