export const GET_BRANCH_INFO_REQUEST = 'GET_BRANCH_INFO_REQUEST';
export const GET_BRANCH_INFO_SUCCESS = 'GET_BRANCH_INFO_SUCCESS';
export const GET_BRANCH_INFO_FAILED = 'GET_BRANCH_INFO_FAILED';

export const GET_BRANCHES_GENERAL_DATA_REQUEST = 'GET_BRANCHES_GENERAL_DATA_REQUEST';
export const GET_BRANCHES_GENERAL_DATA_SUCCESS = 'GET_BRANCHES_GENERAL_DATA_SUCCESS';
export const GET_BRANCHES_GENERAL_DATA_FAILED = 'GET_BRANCHES_GENERAL_DATA_FAILED';

export const ON_ARCHIVE_BRANCH_REQUEST = 'ON_ARCHIVE_BRANCH_REQUEST';
export const ON_ARCHIVE_BRANCH_SUCCESS = 'ON_ARCHIVE_BRANCH_SUCCESS';
export const ON_ARCHIVE_BRANCH_FAILED = 'ON_ARCHIVE_BRANCH_FAILED';

export const ON_EDIT_BRANCH_REQUEST = 'ON_EDIT_BRANCH_REQUEST';
export const ON_EDIT_BRANCH_SUCCESS = 'ON_EDIT_BRANCH_SUCCESS';
export const ON_EDIT_BRANCH_FAILED = 'ON_EDIT_BRANCH_FAILED';

export const ON_EDIT_BRANCH_SCHEDULE_REQUEST = 'ON_EDIT_BRANCH_SCHEDULE_REQUEST';
export const ON_EDIT_BRANCH_SCHEDULE_SUCCESS = 'ON_EDIT_BRANCH_SCHEDULE_SUCCESS';
export const ON_EDIT_BRANCH_SCHEDULE_FAILED = 'ON_EDIT_BRANCH_SCHEDULE_FAILED';

export const REMOVE_BRANCH_LOGO_REQUEST = 'REMOVE_BRANCH_LOGO_REQUEST';
export const REMOVE_BRANCH_LOGO_SUCCESS = 'REMOVE_BRANCH_LOGO_SUCCESS';
export const REMOVE_BRANCH_LOGO_FAILED = 'REMOVE_BRANCH_LOGO_FAILED';
