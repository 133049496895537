import React, { Component } from 'react';
import TemplateBoardSchedule from './TemplateBoardSchedule';
import EditTemplateModal from '../../../../../../components/modals/edit-template-modal';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';
import AddEditShiftForm from '../../../../../../components/forms/add-edit-shift-form';
import { weekDays } from '../../../../../../services/datesHelper';
import './style.scss';
import ButtonComponent from '../../../../../../components/UI/buttons/button';
import PublishTemplateShiftForm from '../../../../../../components/forms/publish-template-shift-form';

class BranchTemplate extends Component {
    state = {
        data: null,
        action: null,
        publishModalOpen: false,
        loading: false
    };

    async componentDidMount() {
        this._isMounted = true;
        const {
            match: {
                params: { id }
            },
            getAllStaffRequest,
            getBranchTemplateRequest,
            getMasterDateRequest
        } = this.props;
        getAllStaffRequest();
        getMasterDateRequest();
        getBranchTemplateRequest(id);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal = (action) => {
        return this.setState({
            action
        });
    };

    onSubmitShift = async (data, isEdit) => {
        try {
            const {
                match: {
                    params: { id }
                },
                postBranchTemplateRequest,
                editBranchTemplateRequest
            } = this.props;
            const { action } = this.state;

            this.setState({ loading: true });
            if (action.name === 'edit') {
                await editBranchTemplateRequest({ data: data[0], id, currentShiftId: action.value.id });
            } else {
                await postBranchTemplateRequest({ data, id });
            }

            this.setState({ loading: false, action: null });
        } catch {
            this.setState({ loading: false });
        }
    };

    handleDropdownSelect = (_, data) => {
        const { scheduleActionRequest } = this.props;
        const parseData = JSON.parse(data.value);
        parseData.branchId = this.props.match.params.id;

        if (parseData.name === 'clear') {
            this.setState({
                ...this.state,
                action: parseData
            });
        } else {
            scheduleActionRequest(parseData);
        }
    };

    onConfirmRemoveWeek = async () => {
        this.props.scheduleActionRequest(this.state.action);
        this.setState({
            ...this.state,
            loading: false,
            action: null
        });
    };

    onConfirmRemoveShift = async () => {
        try {
            const {
                match: {
                    params: { id }
                },
                deleteBranchTemplateRequest
            } = this.props;
            const { action } = this.state;

            this.setState({ loading: true });
            await deleteBranchTemplateRequest({ id, currentShiftId: action.value.id });
            this.setState({
                ...this.state,
                loading: false,
                action: null
            });
        } catch {
            this.setState({ loading: false });
        }
    };

    onCancelClick = (action) => this.setState({ action, publishModalOpen: false });

    togglePublishModal = () => this.setState({ publishModalOpen: !this.state.publishModalOpen });

    onPublishSubmit = (formData) => {
        this.props.templatePublishRequest({
            ...formData,
            branchId: this.props.match.params.id
        });
        this.setState({ publishModalOpen: !this.state.publishModalOpen });
    };

    render() {
        const { action, loading, publishModalOpen } = this.state;

        const { data, loading: submitLoading } = this.props.branchTemplatePage;
        const { operatingTime, staffList, masterDate } = this.props;
        const { data: templateData } = this.props.branchTemplatePage;

        const removeShiftButton = {
            value: 'Confirm',
            onClick: this.onConfirmRemoveShift,
            size: 'big',
            width: 'big',
            loading
        };

        const removeWeekButton = {
            value: 'Confirm',
            onClick: this.onConfirmRemoveWeek,
            size: 'big',
            width: 'big',
            loading
        };

        if (!data) return null;

        return (
            <div className="branch-info-page branch-template">
                <div className="week-container">
                    <div>
                        <h3 className="branch-info-page__title">Template</h3>
                    </div>
                    <div>
                        <ButtonComponent
                            value="Publish"
                            onClick={this.togglePublishModal}
                            size="medium"
                            disabled={!templateData || !templateData.length}
                        />
                    </div>
                </div>
                <>
                    <div className="branch-template__row heading-row mb-2">
                        {weekDays.map((wd) => {
                            return (
                                <div key={wd} className="col">
                                    <h4 className="day-title">{wd[0].toUpperCase() + wd.slice(1)}</h4>
                                    <p className="day-subtitle">even dates</p>
                                </div>
                            );
                        })}
                    </div>
                    <TemplateBoardSchedule
                        data={templateData}
                        operatingTime={operatingTime}
                        toggleModal={this.toggleModal}
                        handleDropdownSelect={this.handleDropdownSelect}
                    />
                </>
                {action && ['add', 'edit'].includes(action.name) && (
                    <EditTemplateModal
                        open={true}
                        onClose={() => this.setState({ action: null })}
                        title={publishModalOpen || (action && action.name === 'add') ? 'New Shifts' : 'Edit Shifts'}
                        type={publishModalOpen ? 'add' : action.name}
                    >
                        <AddEditShiftForm
                            loading={loading}
                            week={action.weekNumber}
                            day={action.value.day}
                            operatingTime={operatingTime}
                            shiftEdit={data.find((shift) => shift.id === action.value.id)}
                            staffList={staffList}
                            onSuccess={this.onInviteStaff}
                            onCancelClick={this.onCancelClick}
                            onSubmitClick={this.onSubmitShift}
                        />
                    </EditTemplateModal>
                )}
                {publishModalOpen && (
                    <EditTemplateModal
                        open={true}
                        onClose={() => this.setState({ publishModalOpen: false })}
                        title={'Publish'}
                        type={'add'}
                    >
                        <PublishTemplateShiftForm
                            onSubmitClick={this.onPublishSubmit}
                            submitLoading={submitLoading}
                            planningPeriod={masterDate && masterDate.planningPeriod}
                        />
                    </EditTemplateModal>
                )}
                {action && action.name === 'clear' && (
                    <ConfirmModal
                        open={true}
                        onClose={() => this.setState({ action: null })}
                        title="Clear week"
                        text="Are you sure you want to clear this week?"
                        button={removeWeekButton}
                    />
                )}
                {action && action.name === 'remove' && (
                    <ConfirmModal
                        open={true}
                        onClose={() => this.setState({ action: null })}
                        title="Clear shift"
                        text="Are you sure you want to clear this shift?"
                        button={removeShiftButton}
                    />
                )}
            </div>
        );
    }
}

export default BranchTemplate;
