import moment from 'moment';
import store from '../store/store';

export const onFilter = (syntheticEvent, eventObj, actionCreator, page) => {
    const pageNumber = page || 1;
    const { name, value } = !eventObj ? syntheticEvent.target : eventObj;

    let inputValue = value;
    if (value && eventObj.isDatepicker) {
        if (Array.isArray(value)) {
            inputValue = value.map((d) => moment(d).format('YYYY-MM-DD'));
        } else {
            inputValue = moment(value).format('YYYY-MM-DD');
        }
    } else if (!value && eventObj.isDatepicker) {
        inputValue = '';
    }

    store.dispatch(actionCreator({ name, value: inputValue }, Number(pageNumber)));
};
