import {
    GET_USER_EVENTS_REQUEST,
    GET_USER_EVENTS_SUCCESS,
    GET_USER_EVENTS_FAILED,
    ON_USER_EVENTS_DATE_RANGE_CHANGE
} from './action-types';

export const getStaffUserEventsRequest = (payload) => ({
    type: GET_USER_EVENTS_REQUEST,
    payload
});

export const getStaffUserEventsSuccess = (payload) => ({
    type: GET_USER_EVENTS_SUCCESS,
    payload
});

export const getStaffUserEventsFailed = () => ({
    type: GET_USER_EVENTS_FAILED
});

export const onStaffUserEventsDateRangeChange = (payload) => ({
    type: ON_USER_EVENTS_DATE_RANGE_CHANGE,
    payload
});
