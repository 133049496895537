import {
    LOG_IN_SUMBIT,
    LOG_IN_SUCCESS,
    LOG_IN_FAILED,
    RESET_PASS_SUBMIT,
    RESET_PASS_SUCCESS,
    RESET_PASS_FAILED,
    FORGOT_PASS_SUBMIT,
    FORGOT_PASS_SUCCESS,
    FORGOT_PASS_FAILED,
    PROFILE_RESET_PASS_SUBMIT,
    PROFILE_RESET_PASS_SUCCESS,
    PROFILE_RESET_PASS_FAILED
} from './action-types';

// login event
export const logInSubmit = (payload) => ({
    type: LOG_IN_SUMBIT,
    payload
});

export const logInSuccess = (payload) => ({
    type: LOG_IN_SUCCESS,
    payload
});

export const logInFailed = () => ({
    type: LOG_IN_FAILED
});

// reset password event
export const resetPassSubmit = (payload) => ({
    type: RESET_PASS_SUBMIT,
    payload
});

export const resetPassSuccess = () => ({
    type: RESET_PASS_SUCCESS
});

export const resetPassFailed = () => ({
    type: RESET_PASS_FAILED
});

export const profileResetPassSubmit = (payload) => ({
    type: PROFILE_RESET_PASS_SUBMIT,
    payload
});

export const profileResetPassSuccess = () => ({
    type: PROFILE_RESET_PASS_SUCCESS
});

export const profileResetPassFailed = () => ({
    type: PROFILE_RESET_PASS_FAILED
});

// forgot password event
export const forgotPassSubmit = (payload) => ({
    type: FORGOT_PASS_SUBMIT,
    payload
});

export const forgotPassSuccess = () => ({
    type: FORGOT_PASS_SUCCESS
});

export const forgotPassFailed = () => ({
    type: FORGOT_PASS_FAILED
});
