export const GET_JOB_DETAILS_REQUEST = 'GET_JOB_DETAILS_REQUEST';
export const GET_JOB_DETAILS_SUCCESS = 'GET_JOB_DETAILS_SUCCESS';
export const GET_JOB_DETAILS_FAILED = 'GET_JOB_DETAILS_FAILED';

export const GET_WORKED_LOCUMS_REQUEST = 'GET_WORKED_LOCUMS_REQUEST';
export const GET_WORKED_LOCUMS_SUCCESS = 'GET_WORKED_LOCUMS_SUCCESS';
export const GET_WORKED_LOCUMS_FAILED = 'GET_WORKED_LOCUMS_FAILED';

export const ON_EDIT_JOB_REQUEST = 'ON_EDIT_JOB_REQUEST';
export const ON_EDIT_JOB_SUCCESS = 'ON_EDIT_JOB_SUCCESS';
export const ON_EDIT_JOB_FAILED = 'ON_EDIT_JOB_FAILED';

export const ON_CANCEL_JOB_REQUEST = 'ON_CANCEL_JOB_REQUEST';
export const ON_CANCEL_JOB_SUCCESS = 'ON_CANCEL_JOB_SUCCESS';
export const ON_CANCEL_JOB_FAILED = 'ON_CANCEL_JOB_FAILED';

export const ON_LOCUM_ACTION_REQUEST = 'ON_LOCUM_ACTION_REQUEST';
export const ON_LOCUM_ACTION_SUCCESS = 'ON_LOCUM_ACTION_SUCCESS';
export const ON_LOCUM_ACTION_FAILED = 'ON_LOCUM_ACTION_FAILED';

export const GET_JOB_CANCEL_REASONS_REQUEST = 'GET_JOB_CANCEL_REASONS_REQUEST';
export const GET_JOB_CANCEL_REASONS_SUCCESS = 'GET_JOB_CANCEL_REASONS_SUCCESS';
export const GET_JOB_CANCEL_REASONS_FAILED = 'GET_JOB_CANCEL_REASONS_FAILED';
