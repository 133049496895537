import React from 'react';
import PropTypes from 'prop-types';

import ButtonModal from '../../../../../components/UI/buttons/button-modal';

import { Icon } from 'semantic-ui-react';

import moment from 'moment';

function SubscriptionNotification({ subscriptionEndDate, onClick, isDowngrade }) {
    const date = <span className="date">{moment(subscriptionEndDate, 'YYYY-MM-DD').format('Do MMM YYYY')}</span>;

    return (
        <div className="billing-page__subscription-notification">
            <div className="text-inner">
                <p className="text-inner__text">
                    <Icon name="warning sign" />
                    {`Note: Subscription will be ${isDowngrade ? 'downgraded' : 'cancelled'}`} {date}
                </p>
            </div>
            <div className="button-holder">
                <ButtonModal
                    value={isDowngrade ? 'Cancel downgrade' : 'Abort cancellation'}
                    onClick={() => onClick(isDowngrade ? 'downgrade' : 'cancellation')}
                    colorType="blue"
                />
            </div>
        </div>
    );
}

SubscriptionNotification.propTypes = {
    subscriptionEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onClick: PropTypes.func.isRequired,
    isDowngrade: PropTypes.bool
};

export default SubscriptionNotification;
