import {
    handleCurrentDay,
    handleJobPosting,
    handleBranchShifts,
    handleAvailableStaff,
    handleShiftsByFilterGet
} from './handlers';

export default (state, action) => ({
    currentDay: handleCurrentDay(state, action),
    postJob: handleJobPosting(state, action),
    shiftsList: handleBranchShifts(state, action),
    staffList: handleAvailableStaff(state, action),
    shiftsByFilter: handleShiftsByFilterGet(state, action)
});
