import { UserInformation } from './UserInformation';
import { connect } from 'react-redux';
import { onChangeUserStatusRequest } from '../../../../../../store/actions';

export default connect(
    (state) => ({
        loading: state.adminUserInfoPage.loading
    }),
    {
        onChangeUserStatusRequest
    }
)(UserInformation);
