import React from 'react';
// { useState, useEffect } unused
import PropTypes from 'prop-types';
import ButtonComponent from '../buttons/button';

import './style.scss';

function GroupCheckboxButtons({ data, label, onClick }) {
    return (
        <div className="group-checkbox-buttons">
            <label>{label}</label>
            <div className="group-checkbox-buttons__buttons-list">
                {data.map((button) => {
                    return (
                        <ButtonComponent
                            key={button.key}
                            value={button.label}
                            type={button.value === true ? 'confirm' : 'bordered'}
                            size="medium"
                            width="medium"
                            onClick={() => onClick({ ...button, value: !button.value })}
                        />
                    );
                })}
            </div>
        </div>
    );
}

GroupCheckboxButtons.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.bool.isRequired,
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    label: PropTypes.string
};
export default GroupCheckboxButtons;
