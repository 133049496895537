import {
    GET_PROFILE_DATA_SUCCESS,
    GET_PROFILE_DATA_FAILED
} from '../../../actions/shared/profile-additional-info/action-types';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case GET_PROFILE_DATA_SUCCESS:
            return (state = payload);
        case GET_PROFILE_DATA_FAILED:
            return state;
        default:
            return state;
    }
};
