import {
    GET_USER_EVENTS_SUCCESS,
    GET_USER_EVENTS_FAILED,
    ON_USER_EVENTS_DATE_RANGE_CHANGE
} from '../../../actions/shared/schedule/action-types';

const initialState = {
    dateRange: {
        startDateRange: null,
        endDateRange: null
    },
    events: null,
    loading: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_USER_EVENTS_SUCCESS:
            return {
                ...state,
                events: payload
            };
        case GET_USER_EVENTS_FAILED:
            return state;
        case ON_USER_EVENTS_DATE_RANGE_CHANGE:
            return {
                ...state,
                dateRange: payload
            };
        default:
            return state;
    }
};
