import {
    handlePaymentMethod,
    handleActiveBranches,
    handleSubscriptionPlan,
    handleTransactions,
    handleAddons
} from './handlers';

const billingPage = (state, action) => ({
    paymentMethod: handlePaymentMethod(state, action),
    addons: handleAddons(state, action),
    branches: handleActiveBranches(state, action),
    subscriptionPlan: handleSubscriptionPlan(state, action),
    transactions: handleTransactions(state, action)
});

export default billingPage;
