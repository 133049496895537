import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import './style.scss';

const ButtonModal = ({ value, colorType, onClick, loading, disabled, className, type }) => {
    return (
        <Button
            size="small"
            type={type || 'submit'}
            loading={loading}
            className={`button-modal ${className} ${colorType}`}
            disabled={disabled}
            onClick={onClick}
        >
            {value}
        </Button>
    );
};

ButtonModal.propTypes = {
    value: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    colorType: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string
};

export default ButtonModal;
