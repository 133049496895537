import { PharmacyBillingPage } from './PharmacyBillingPage';
import { connect } from 'react-redux';
import {
    getPaymentMethodRequest,
    getSubscriptionPlanRequest,
    setSelectedSubscriptionPlan,
    getSubscriptionPlansListRequest,
    getActiveBranchesListRequest,
    postPlanSubscriptionRequest,
    cancelPlanSubscriptionRequest,
    abortSubscriptionActionRequest,
    getTransactionsListRequest,
    clearTransactionsList,
    clearSubscriptionData,
    updateBranchSubscriptionsRequest,
    updateProfileHostedPageRequest,
    subscriptionStandardPlanRequest,
    getProfileSubscriptionRequest,
    getPersonalInfoRequest,
    postDeactivateSubscriptionRequest,
    authUserRequest
} from '../../../../../store/actions';

export default connect(
    (state) => ({
        paymentMethod: state.billingPage.paymentMethod,
        subscriptionPlan: state.billingPage.subscriptionPlan,
        transactions: state.billingPage.transactions,
        branches: state.billingPage.branches,
        personalInfo: state.userProfileInfo.personalInfo
    }),
    {
        updateBranchSubscriptionsRequest,
        getPaymentMethodRequest,
        getSubscriptionPlanRequest,
        setSelectedSubscriptionPlan,
        getSubscriptionPlansListRequest,
        getActiveBranchesListRequest,
        postPlanSubscriptionRequest,
        cancelPlanSubscriptionRequest,
        abortSubscriptionActionRequest,
        getTransactionsListRequest,
        clearTransactionsList,
        clearSubscriptionData,
        updateProfileHostedPageRequest,
        subscriptionStandardPlanRequest,
        getProfileSubscriptionRequest,
        getPersonalInfoRequest,
        postDeactivateSubscriptionRequest,
        authUserRequest
    }
)(PharmacyBillingPage);
