import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const StaffItemCard = ({
    type,
    name = '',
    surname = '',
    id,
    roleTitle,
    image,
    startTime,
    endTime,
    duration,
    startDate,
    branchId,
    onEditButtonClick
}) => (
    <div className="staff-item-card">
        <div className="staff-item-card__container">
            <div
                className={classnames('col col-left', {
                    locum: roleTitle === 'Locum'
                })}
            >
                <img
                    src={image}
                    alt={image === undefined || image === null ? '' : 'staff'}
                    className={image === undefined || image === null ? 'error' : ''}
                />
                <div onClick={() => onEditButtonClick({ id, startDate, branchId })} className="text-wrapper">
                    <p className="name">{!name ? 'No staff' : `${name} ${surname.charAt(0)}.`}</p>
                    <p className="role">{roleTitle}</p>
                </div>
            </div>
            <div
                className={classnames('col col-right', {
                    small: type === 'small'
                })}
            >
                <div className="info-item">
                    <p className="time">{`${startTime} - ${endTime}`}</p>
                    <p className="duration">{duration}</p>
                </div>
            </div>
        </div>
    </div>
);

StaffItemCard.propTypes = {
    type: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    duration: PropTypes.string,
    roleTitle: PropTypes.string,
    image: PropTypes.string,
    onEditButtonClick: PropTypes.func
};

export default StaffItemCard;
