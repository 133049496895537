import { combineReducers } from 'redux';
import auth from './auth/';
import loader from './loader/';
import loginPage from './login-page';
import signupPage from './signup-page';
import locumCheckInPage from './locum-check-in-page';
import staffInvitePage from './staff-invite-page';
import createBranch from './admin-view/create-branch';
import schedulePage from './admin-view/schedule-page/';
import branchSchedule from './admin-view/branch-schedule/';
import branchesListPage from './admin-view/branches-page';
import branchScheduleDayView from './admin-view/branch-schedule-day-view';
import branchDetailsPageView from './admin-view/branch-details-page-view';
import branchInfoPage from './admin-view/branch-info';
import branchStaffPage from './admin-view/branch-staff';
import staffListPage from './admin-view/staff-page/';
import inviteStaffForm from './admin-view/invite-staff/';
import regionsPage from './admin-view/regions-page/';
import postedJobsPage from './admin-view/posted-jobs-page/';
import jobPreviewPage from './admin-view/job-preview-page';
import staffInfoPage from './admin-view/staff-info/';
import staffSchedulePage from './admin-view/staff-schedule/';
import userProfileInfo from './profile/';
import billingPage from './admin-view/billing-page/';
import adminPharmaciesPage from './super-admin-view/pharmacies-page/';
import adminPharmacyInfoPage from './super-admin-view/pharmacy-info-page/';
import adminUsersPage from './super-admin-view/users-page/';
import adminJobOffersPage from './super-admin-view/job-offers-page/';
import adminPharmacyBranchesPage from './super-admin-view/pharmacy-branches-page';
import adminPharmacyJobsPage from './super-admin-view/pharmacy-jobs-page';
import adminPharmacyStaffPage from './super-admin-view/pharmacy-staff-page';
import adminUserInfoPage from './super-admin-view/user-info';
import adminBillingPage from './super-admin-view/billing-page';
import staffUserProfile from './shared/profile-page';
import staffUserSchedule from './shared/schedule';
import locumJobOffersPage from './locum-view/job-offers';
import locumJobDetails from './locum-view/job-preview';
import locumSettings from './locum-view/settings';
import profileAdditionalInfoData from './shared/profile-additional-info';
import locumData from './admin-view/locum';
import absenceRequestForm from './admin-view/absence-request-form';
import staffAbsencePage from './admin-view/staff-absence';
import locumBankLocumsData from './admin-view/locum-bank-add-locum-form';
import locumBankPage from './admin-view/locum-bank-page';
import postJobForm from './admin-view/post-job-form';
import pharmacySettingsPage from './admin-view/pharmacy-settings-page';
import branchTemplatePage from './admin-view/branch-template';
import staffContractPage from './admin-view/staff-contract';
import pharmacyBillingPage from './super-admin-view/pharmacy-billing-page';

import { LOGOUT } from '../actions/auth/action-types';

const rootReducer = combineReducers({
    auth,
    loader,
    loginPage,
    signupPage,
    locumCheckInPage,
    userProfileInfo,
    staffInvitePage,
    createBranch,
    schedulePage,
    branchSchedule,
    branchesListPage,
    branchScheduleDayView,
    branchDetailsPageView,
    branchInfoPage,
    branchStaffPage,
    branchTemplatePage,
    staffListPage,
    inviteStaffForm,
    regionsPage,
    postedJobsPage,
    jobPreviewPage,
    staffInfoPage,
    staffSchedulePage,
    billingPage,
    adminPharmaciesPage,
    adminPharmacyInfoPage,
    adminPharmacyBranchesPage,
    adminUsersPage,
    adminJobOffersPage,
    adminPharmacyJobsPage,
    adminPharmacyStaffPage,
    adminUserInfoPage,
    adminBillingPage,
    staffUserProfile,
    staffUserSchedule,
    locumJobOffersPage,
    locumJobDetails,
    locumSettings,
    profileAdditionalInfoData,
    locumData,
    absenceRequestForm,
    staffAbsencePage,
    locumBankLocumsData,
    locumBankPage,
    postJobForm,
    pharmacySettingsPage,
    staffContractPage,
    pharmacyBillingPage
});

const appReducer = (state, action) => rootReducer(action.type === LOGOUT ? undefined : state, action);

export default appReducer;
