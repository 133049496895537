import InfoTabs from 'components/info-tabs';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'services/hooks';
import { userRoles } from 'userConfig';
import ContractView from './contract-view';
import UpcomingContracts from './upcoming-contracts';
import ClosedContracts from './closed-contracts';
import { contractPages } from 'services/contract';

import './style.scss';

function StaffContractPage() {
    const [searchParams, setSearchParam] = useSearchParams();
    const { tab = contractPages.currentContract } = searchParams;

    const user = useSelector((state) => state.auth.user);

    const setTab = (tab) => setSearchParam({ tab });

    const displayView = useMemo(() => {
        switch (tab) {
            case contractPages.currentContract:
                return <ContractView />;

            case contractPages.upcomingContract:
                return <UpcomingContracts />;

            case contractPages.closedContract:
                return <ClosedContracts />;

            default:
                break;
        }
    }, [tab]);

    const isAdmin = user.role === userRoles.BRANCH_ADMIN;

    const tabs = [
        {
            title: 'Current Contract',
            onClick: () => setTab(contractPages.currentContract),
            isActive: tab === contractPages.currentContract
        },
        {
            title: 'Upcoming Contracts',
            onClick: () => setTab(contractPages.upcomingContract),
            isActive: tab === contractPages.upcomingContract
        },
        {
            title: 'Closed Contracts',
            onClick: () => setTab(contractPages.closedContract),
            isActive: tab === contractPages.closedContract
        }
    ];

    return (
        <div className="staff-contract-page">
            <div className="staff-contract-page__header">
                <div className="staff-contract-page__row buttons-row with-tabs">
                    <h3 className="staff-contract-page__title">Contract</h3>
                    {isAdmin && <InfoTabs tabs={tabs} />}
                </div>
            </div>
            {displayView}
        </div>
    );
}

export default StaffContractPage;
