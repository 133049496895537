import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
// { page, totalPages, linkTo, preserveQueryParams }
import * as queryString from 'query-string';

const TablePagination = ({
    page,
    totalPages,
    linkTo,
    preserveQueryParams,
    history: {
        location: { search }
    }
}) => {
    const generateQueryParams = (page) => {
        if (!preserveQueryParams)
            return queryString.stringify({
                page
            });
        const parsedParams = queryString.parse(search);
        return queryString.stringify({
            ...parsedParams,
            page
        });
    };
    return (
        <Menu floated="right" pagination className="table__footer__pagination">
            <Menu.Item icon>
                <NavLink
                    className={`table__footer__pagination__button${
                        page === 1 ? ' table__footer__pagination__button--disabled' : ''
                    }`}
                    to={`${linkTo}?${generateQueryParams(page - 1)}`}
                >
                    <Icon name="chevron left" />
                </NavLink>
            </Menu.Item>
            {renderPages({ page, totalPages, linkTo, generateQueryParams })}
            <Menu.Item icon>
                <NavLink
                    className={`table__footer__pagination__button table__footer__pagination__button--active${
                        page === totalPages || totalPages === 0 ? ' table__footer__pagination__button--disabled' : ''
                    }`}
                    to={`${linkTo}?${generateQueryParams(page + 1)}`}
                >
                    <Icon name="chevron right" />
                </NavLink>
            </Menu.Item>
        </Menu>
    );
};

const renderPages = ({ page, totalPages, linkTo, generateQueryParams }) => (
    <>
        {page > 3 && (
            <Menu.Item>
                <NavLink
                    className="table__footer__pagination__button page-number"
                    to={`${linkTo}?${generateQueryParams(1)}`}
                >
                    ...
                </NavLink>
            </Menu.Item>
        )}
        {page > 2 && (
            <Menu.Item>
                <NavLink
                    className="table__footer__pagination__button page-number"
                    to={`${linkTo}?${generateQueryParams(page - 2)}`}
                >
                    {page - 2}
                </NavLink>
            </Menu.Item>
        )}
        {page > 1 && (
            <Menu.Item>
                <NavLink
                    className="table__footer__pagination__button page-number"
                    to={`${linkTo}?${generateQueryParams(page - 1)}`}
                >
                    {page - 1}
                </NavLink>
            </Menu.Item>
        )}
        <Menu.Item>
            <NavLink
                className="table__footer__pagination__button table__footer__pagination__button--active page-number"
                to={`${linkTo}?${generateQueryParams(page)}`}
            >
                {String(page)}
            </NavLink>
        </Menu.Item>
        {totalPages > page && (
            <Menu.Item>
                <NavLink
                    className="table__footer__pagination__button page-number"
                    to={`${linkTo}?${generateQueryParams(page + 1)}`}
                >
                    {page + 1}
                </NavLink>
            </Menu.Item>
        )}
        {totalPages > page + 1 && (
            <Menu.Item>
                <NavLink
                    className="table__footer__pagination__button page-number"
                    to={`${linkTo}?${generateQueryParams(page + 2)}`}
                >
                    {page + 2}
                </NavLink>
            </Menu.Item>
        )}
        {totalPages > page + 2 && (
            <Menu.Item>
                <NavLink
                    className="table__footer__pagination__button page-number"
                    to={`${linkTo}?${generateQueryParams(totalPages)}`}
                >
                    ...
                </NavLink>
            </Menu.Item>
        )}
    </>
);

TablePagination.propTypes = {
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    linkTo: PropTypes.string
};

export default withRouter(TablePagination);
