import {
    GET_CURRENT_DAY,
    CLEAR_CURRENT_DAY,
    UPDATE_DAY_SHIFTS,
    GET_POST_JOB_DRAFT,
    ON_POST_JOB_REQUEST,
    ON_POST_JOB_SUCCESS,
    ON_POST_JOB_FAILED,
    SEPARATE_JOB,
    LONG_TERM_JOB,
    GET_BRANCH_SHIFTS_REQUEST,
    GET_BRANCH_SHIFTS_SUCCESS,
    GET_BRANCH_SHIFTS_FAILED,
    GET_BRANCH_AVAILABLE_STAFF_REQUEST,
    GET_BRANCH_AVAILABLE_STAFF_SUCCESS,
    GET_BRANCH_AVAILABLE_STAFF_FAILED,
    ON_SUBMIT_SHIFT_REQUEST,
    ON_SUBMIT_SHIFT_SUCCESS,
    ON_SUBMIT_SHIFT_FAILED,
    ON_SUBMIT_DRAFT_REQUEST,
    ON_SUBMIT_DRAFT_SUCCESS,
    ON_SUBMIT_DRAFT_FAILED,
    ON_SUBMIT_BULK_SHIFT_REQUEST,
    ON_SUBMIT_BULK_SHIFT_SUCCESS,
    ON_SUBMIT_BULK_SHIFT_FAILED,
    ON_SUBMIT_SHIFT_DRAFT_BULK_REQUEST,
    ON_SUBMIT_SHIFT_DRAFT_BULK_SUCCESS,
    ON_SUBMIT_SHIFT_DRAFT_BULK_FAILED,
    POST_JOB_BULK_REQUEST,
    POST_JOB_BULK_SUCCESS,
    POST_JOB_BULK_FAILED,
    ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST,
    ON_GET_SCHEDULE_SHIFTS_BY_FILTER_SUCCESS,
    ON_GET_SCHEDULE_SHIFTS_BY_FILTER_FAILED
} from './action-types';

export const getCurrentDay = (item) => ({
    type: GET_CURRENT_DAY,
    payload: item
});

export const clearCurrentDay = () => ({
    type: CLEAR_CURRENT_DAY
});

export const updateDayShifts = (shifts) => ({
    type: UPDATE_DAY_SHIFTS,
    payload: shifts
});

export const getPostJobDraft = (job) => ({
    type: GET_POST_JOB_DRAFT,
    payload: job
});

export const separateJob = () => ({
    type: SEPARATE_JOB
});

export const longTermJob = () => ({
    type: LONG_TERM_JOB
});

export const getBranchShiftsRequest = (payload) => ({
    type: GET_BRANCH_SHIFTS_REQUEST,
    payload
});

export const getBranchShiftsSuccess = (payload) => ({
    type: GET_BRANCH_SHIFTS_SUCCESS,
    payload
});

export const getBranchShiftsFailed = () => ({
    type: GET_BRANCH_SHIFTS_FAILED
});

export const getBranchAvailableStaffRequest = (payload) => ({
    type: GET_BRANCH_AVAILABLE_STAFF_REQUEST,
    payload
});

export const getBranchAvailableStaffSuccess = (payload) => ({
    type: GET_BRANCH_AVAILABLE_STAFF_SUCCESS,
    payload
});

export const getBranchAvailableStaffFailed = () => ({
    type: GET_BRANCH_AVAILABLE_STAFF_FAILED
});

export const onSubmitShiftRequest = (payload) => ({
    type: ON_SUBMIT_SHIFT_REQUEST,
    payload
});

export const onSubmitShiftSuccess = () => ({
    type: ON_SUBMIT_SHIFT_SUCCESS
});

export const onSubmitShiftFailed = () => ({
    type: ON_SUBMIT_SHIFT_FAILED
});

export const onSubmitBulkShiftRequest = (payload) => ({
    type: ON_SUBMIT_BULK_SHIFT_REQUEST,
    payload
});

export const onSubmitBulkShiftSuccess = () => ({
    type: ON_SUBMIT_BULK_SHIFT_SUCCESS
});

export const onSubmitBulkShiftFailed = () => ({
    type: ON_SUBMIT_BULK_SHIFT_FAILED
});

export const onSubmitDraftRequest = (payload) => ({
    type: ON_SUBMIT_DRAFT_REQUEST,
    payload
});

export const onSubmitDraftSuccess = () => ({
    type: ON_SUBMIT_DRAFT_SUCCESS
});

export const onSubmitDraftFailed = () => ({
    type: ON_SUBMIT_DRAFT_FAILED
});

export const onSubmitShiftDraftBulkRequest = (payload) => ({
    type: ON_SUBMIT_SHIFT_DRAFT_BULK_REQUEST,
    payload
});

export const onSubmitShiftDraftBulkSuccess = () => ({
    type: ON_SUBMIT_SHIFT_DRAFT_BULK_SUCCESS
});

export const onSubmitShiftDraftBulkFailed = () => ({
    type: ON_SUBMIT_SHIFT_DRAFT_BULK_FAILED
});

export const onPostJobRequest = (payload) => ({
    type: ON_POST_JOB_REQUEST,
    payload
});

export const onPostJobSuccess = () => ({
    type: ON_POST_JOB_SUCCESS
});

export const onPostJobFailed = () => ({
    type: ON_POST_JOB_FAILED
});

export const onPostJobBulkRequest = (payload) => ({
    type: POST_JOB_BULK_REQUEST,
    payload
});

export const onPostJobBulkSuccess = () => ({
    type: POST_JOB_BULK_SUCCESS
});

export const onPostJobBulkFailed = () => ({
    type: POST_JOB_BULK_FAILED
});

export const onGetScheduleShiftsByFilterRequest = (payload) => ({
    type: ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST,
    payload
});
export const onGetScheduleShiftsByFilterSuccess = (payload) => ({
    type: ON_GET_SCHEDULE_SHIFTS_BY_FILTER_SUCCESS,
    payload
});
export const onGetScheduleShiftsByFilterFailed = () => ({
    type: ON_GET_SCHEDULE_SHIFTS_BY_FILTER_FAILED
});
