import { connect } from 'react-redux';
import { LocumInfoPage } from './LocumInfoPage';
import { getPersonalInfoRequest } from './../../../../store/actions';

export default connect(
    (state) => ({
        personalInfo: state.userProfileInfo.personalInfo
    }),
    {
        getPersonalInfoRequest
    }
)(LocumInfoPage);
