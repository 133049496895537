import { yupResolver } from '@hookform/resolvers/yup';
import { DataTable, DataTableSection, DataTableSectionItem } from 'components/data-table';
import ConfirmModal from 'components/modals/confirm-modal';
import ButtonComponent from 'components/UI/buttons/button';
import CustomCheckbox from 'components/UI/checkbox/index';
import Datepicker from 'components/UI/datepicker';
import TextInput from 'components/UI/inputs/text-input';
import SelectComponent from 'components/UI/select';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Icon } from 'semantic-ui-react';
import { CONTRACT_TYPE_TEXT, PERIOD_TYPE } from 'services/constants';
import {
    additionCompensationOptions,
    contractTypeOptions,
    formatContractData,
    grossSalaryPeriodOptions,
    jobTitleOptions,
    workingHourPeriodOptions
} from 'services/contract';
import { useContractStaffId } from 'services/hooks';
import * as validationMessages from 'services/validationMessages';
import { getContractMinDateRequest, postContractFormRequest, updateContractRequest } from 'store/actions';
import * as yup from 'yup';

import './style.scss';

const validationSchema = yup.object().shape({
    jobRoleId: yup.number().required(validationMessages.requiredMessage),
    title: yup.string().required(validationMessages.requiredMessage),
    absenceDays: yup.number().typeError(validationMessages.number).required(validationMessages.requiredMessage),
    overTimePayHourly: yup
        .number()
        .typeError(validationMessages.floatNumber)
        .required(validationMessages.requiredMessage),
    startDate: yup.date().required(validationMessages.requiredMessage).typeError(validationMessages.date),
    endDate: yup
        .date()
        .typeError(validationMessages.date)
        .required(validationMessages.requiredMessage)
        .test({
            name: 'min Date',
            exclusive: false,
            params: {},
            message: 'End date must be grater then start date at list for 1 day',
            test: function (value) {
                return moment(value).isAfter(this.parent.startDate);
            }
        }),
    workingOurs: yup.number().typeError(validationMessages.floatNumber).required(validationMessages.requiredMessage),
    workingPeriodType: yup.string().required(validationMessages.requiredMessage),
    grossSalaryPay: yup.number().typeError(validationMessages.floatNumber).required(validationMessages.requiredMessage),
    grossSalaryPeriodType: yup.string().required(validationMessages.requiredMessage),
    includeBankHolidays: yup.boolean(),
    type: yup.string().required(validationMessages.requiredMessage),
    additionCompensation: yup.array(
        yup.object({
            name: yup.string().required(validationMessages.requiredMessage),
            timePeriod: yup.string().required(validationMessages.requiredMessage),
            payAmount: yup
                .number()
                .typeError(validationMessages.floatNumber)
                .required(validationMessages.requiredMessage)
        })
    )
});
function ContractForm({ contract, onGoBack, onSuccess }) {
    const dispatch = useDispatch();
    const staffId = useContractStaffId();

    const { loading, minDate } = useSelector((state) => state.staffContractPage);

    const [openExitConfirm, setOpenExitConfirm] = useState(false);

    const defaultValues = {
        includeBankHolidays: false,
        workingPeriodType: PERIOD_TYPE.MONTH,
        grossSalaryPeriodType: PERIOD_TYPE.MONTH,
        type: CONTRACT_TYPE_TEXT.FULL_TIME
    };

    const {
        handleSubmit,
        control,
        getValues,
        setError,
        formState: { errors, isValid, isDirty },
        reset
    } = useForm({
        mode: 'all',

        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });
    const { fields, append, remove } = useFieldArray({ name: 'contractAdditionCompensations', control });

    const isContract = !isEmpty(contract);

    useEffect(() => {
        if (isContract)
            reset({ ...contract, startDate: new Date(contract.startDate), endDate: new Date(contract.endDate) });
    }, [contract, isContract, reset]);

    useEffect(() => {
        dispatch(getContractMinDateRequest({ staffId }));
    }, [dispatch, staffId]);

    const requestCallback = (data) => {
        if (data.error) {
            return (
                data?.message &&
                data?.message.includes('title') &&
                setError('title', { message: data?.message }, { shouldFocus: true })
            );
        }

        onSuccess(data);
    };

    const onSubmit = (data) => {
        if (isContract) {
            dispatch(
                updateContractRequest({
                    contractId: contract.id,
                    contractData: formatContractData(data),
                    staffId,
                    callback: requestCallback
                })
            );
        } else {
            dispatch(
                postContractFormRequest({
                    staffId,
                    formData: formatContractData(data),
                    callback: requestCallback
                })
            );
        }
    };

    const handleAddCompensation = () =>
        append({
            name: '',
            timePeriod: PERIOD_TYPE.MONTH,
            payAmount: ''
        });

    const handleRemoveCompensation = (i) => () => remove(i);

    const handleCancel = (e) => {
        e.preventDefault();
        if (isDirty) {
            setOpenExitConfirm(true);
            return;
        }
        onGoBack();
    };

    return (
        <div className="staff-contract-form">
            <ConfirmModal
                open={openExitConfirm}
                showCancelButton={true}
                onClose={() => setOpenExitConfirm(false)}
                title="Leave without saving"
                text="Are you sure you want to leave without saving changes?"
                button={{
                    value: 'Leave',
                    onClick: onGoBack
                }}
                cancelButton={{ value: 'Cancel', disabled: loading, onClick: () => setOpenExitConfirm(false) }}
            />
            <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>
                <div className="staff-contract-form__header">
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                {...field}
                                className="staff-contract-form__header-input"
                                label="Contract name"
                                error={!!errors.title}
                                helperText={errors?.title?.message || ''}
                            />
                        )}
                    />

                    <div className="staff-contract-form__btn-control">
                        <ButtonComponent
                            onClick={handleCancel}
                            value="Cancel"
                            type="transparent"
                            role="button"
                            size="medium"
                            disabled={loading}
                        />
                        <ButtonComponent
                            value="Save"
                            size="medium"
                            role="submit"
                            disabled={!isValid || !isDirty || loading}
                        />
                    </div>
                </div>
                <DataTable>
                    <DataTableSection sectionTitle={'Basic information'}>
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Job title:"
                            value={
                                <Controller
                                    name="jobRoleId"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectComponent
                                            {...field}
                                            className="staff-contract-form__select"
                                            onChange={(ev, data) => field.onChange(data.value)}
                                            error={!!errors.jobRoleId}
                                            helperText={errors?.jobRoleId?.message}
                                            options={jobTitleOptions}
                                        />
                                    )}
                                />
                            }
                        />
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Start date:"
                            value={
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({ field }) => (
                                        <Datepicker
                                            {...field}
                                            className="staff-contract-form__select"
                                            onChange={(e, data) => !isContract && field.onChange(data.value)}
                                            format="DD/MM/YY"
                                            minDate={minDate}
                                            maxDate={getValues().endDate}
                                            disabled={isContract}
                                            locale={'fr-FR'}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message || ''}
                                        />
                                    )}
                                />
                            }
                        />
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="End date:"
                            value={
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({ field }) => (
                                        <Datepicker
                                            {...field}
                                            className="staff-contract-form__select"
                                            onChange={(e, data) => field.onChange(data.value)}
                                            format="DD/MM/YY"
                                            minDate={moment(getValues().startDate).add(1, 'day').toDate()}
                                            disabled={false}
                                            locale={'fr-FR'}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message || ''}
                                        />
                                    )}
                                />
                            }
                        />
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Contract type:"
                            value={
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectComponent
                                            {...field}
                                            className="staff-contract-form__select"
                                            onChange={(ev, data) => field.onChange(data.value)}
                                            error={!!errors.type}
                                            helperText={errors?.type?.message || ''}
                                            options={contractTypeOptions}
                                        />
                                    )}
                                />
                            }
                        />
                    </DataTableSection>

                    <DataTableSection sectionTitle={'Working hours'}>
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Working hours:"
                            value={
                                <div className="staff-contract-form__section-row">
                                    <Controller
                                        name="workingOurs"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                className="staff-contract-form__input"
                                                error={!!errors.workingOurs}
                                                helperText={errors?.workingOurs?.message || ''}
                                            />
                                        )}
                                    />
                                    <span>per</span>
                                    <Controller
                                        name="workingPeriodType"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectComponent
                                                {...field}
                                                className="staff-contract-form__select"
                                                onChange={(ev, data) => field.onChange(data.value)}
                                                error={!!errors.workingPeriodType}
                                                helperText={errors?.workingPeriodType?.message || ''}
                                                options={workingHourPeriodOptions}
                                            />
                                        )}
                                    />
                                </div>
                            }
                        />
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Absence days:"
                            value={
                                <div className="staff-contract-form__section-row">
                                    <Controller
                                        name="absenceDays"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                className="staff-contract-form__input"
                                                error={!!errors.absenceDays}
                                                helperText={errors?.absenceDays?.message || ''}
                                            />
                                        )}
                                    />
                                    <span>per Year</span>
                                    <Controller
                                        name="includeBankHolidays"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomCheckbox
                                                {...field}
                                                onChange={(e, data) => field.onChange(data.checked)}
                                                label="Include bank holidays"
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                </div>
                            }
                        />
                    </DataTableSection>
                    <DataTableSection sectionTitle={'Gross salary'}>
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Amount:"
                            value={
                                <div className="staff-contract-form__section-row">
                                    <Controller
                                        name="grossSalaryPay"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                className="staff-contract-form__input"
                                                error={!!errors.grossSalaryPay}
                                                helperText={errors?.grossSalaryPay?.message || ''}
                                            />
                                        )}
                                    />
                                    <span>per</span>
                                    <Controller
                                        name="grossSalaryPeriodType"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectComponent
                                                {...field}
                                                className="staff-contract-form__select"
                                                error={!!errors.grossSalaryPeriodType}
                                                onChange={(ev, data) => field.onChange(data.value)}
                                                helperText={errors?.grossSalaryPeriodType?.message || ''}
                                                options={grossSalaryPeriodOptions}
                                            />
                                        )}
                                    />
                                </div>
                            }
                        />
                    </DataTableSection>
                    <DataTableSection sectionTitle={'OverTime salary'}>
                        <DataTableSectionItem
                            classes={{ description: 'staff-contract-form__section-row-description' }}
                            description="Amount:"
                            value={
                                <div className="staff-contract-form__section-row">
                                    <Controller
                                        name="overTimePayHourly"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                className="staff-contract-form__input"
                                                error={!!errors.overTimePayHourly}
                                                helperText={errors?.overTimePayHourly?.message || ''}
                                            />
                                        )}
                                    />
                                    <span>per Hour</span>
                                </div>
                            }
                        />
                    </DataTableSection>
                    <DataTableSection sectionTitle={'Additional compensations'}>
                        {fields.map((item, i) => (
                            <DataTableSectionItem
                                classes={{ description: 'staff-contract-form__section-row-description-add-comp' }}
                                key={item.id}
                                description={
                                    <div className="staff-contract-form__section-row-add-comp">
                                        <Icon
                                            onClick={handleRemoveCompensation(i)}
                                            name="remove"
                                            className="red staff-contract-form__remove-comp-btn"
                                        />
                                        <Controller
                                            name={`contractAdditionCompensations[${i}].name`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextInput
                                                    {...field}
                                                    label="Name"
                                                    className="staff-contract-form__select"
                                                    error={!!errors?.contractAdditionCompensations?.[i]?.name}
                                                    helperText={
                                                        errors?.contractAdditionCompensations?.[i]?.name?.message || ''
                                                    }
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`contractAdditionCompensations[${i}].payAmount`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextInput
                                                    {...field}
                                                    label="Payment Amount"
                                                    className="staff-contract-form__input"
                                                    error={!!errors?.contractAdditionCompensations?.[i]?.payAmount}
                                                    helperText={
                                                        errors?.contractAdditionCompensations?.[i]?.payAmount
                                                            ?.message || ''
                                                    }
                                                />
                                            )}
                                        />
                                        <span>per</span>
                                        <Controller
                                            name={`contractAdditionCompensations[${i}].timePeriod`}
                                            control={control}
                                            render={({ field }) => (
                                                <SelectComponent
                                                    {...field}
                                                    label="Time period"
                                                    className="staff-contract-form__select"
                                                    error={!!errors?.contractAdditionCompensations?.[i]?.timePeriod}
                                                    helperText={
                                                        errors?.contractAdditionCompensations?.[i]?.timePeriod
                                                            ?.message || ''
                                                    }
                                                    onChange={(ev, data) => field.onChange(data.value)}
                                                    options={additionCompensationOptions}
                                                />
                                            )}
                                        />
                                    </div>
                                }
                            />
                        ))}
                        <DataTableSectionItem
                            description={
                                <div className="staff-contract-form__add-comp-btn" onClick={handleAddCompensation}>
                                    <Icon name="plus" className="green" />
                                    <span>Add compensations</span>
                                </div>
                            }
                        />
                    </DataTableSection>
                </DataTable>
            </Form>
        </div>
    );
}

ContractForm.propTypes = {
    onSuccess: PropTypes.func,
    contract: PropTypes.object,
    onGoBack: PropTypes.func,
    loading: PropTypes.bool
};

export default ContractForm;
