import {
    GET_INVITED_STAFF_INFO_REQUEST,
    GET_INVITED_STAFF_INFO_SUCCESS,
    GET_INVITED_STAFF_INFO_FAILED,
    ON_CONFIRM_IDENTITY_REQUEST,
    ON_CONFIRM_IDENTITY_SUCCESS,
    ON_CONFIRM_IDENTITY_FAILED,
    ON_INVITED_STAFF_LOGIN_REQUEST,
    ON_INVITED_STAFF_LOGIN_SUCCESS,
    ON_INVITED_STAFF_LOGIN_FAILED,
    ON_CANCEL_GPHC_VERIFICATION_REQUEST,
    ON_CANCEL_GPHC_VERIFICATION_SUCCESS,
    ON_CANCEL_GPHC_VERIFICATION_FAILED
} from '../../actions/staff-invite-page/action-types';

const initialState = {
    loading: false,
    user: {},
    userProfile: {}
};

const staffInvitePage = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVITED_STAFF_INFO_REQUEST:
        case ON_CONFIRM_IDENTITY_REQUEST:
        case ON_INVITED_STAFF_LOGIN_REQUEST:
        case ON_CANCEL_GPHC_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_INVITED_STAFF_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            };
        case ON_CONFIRM_IDENTITY_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
                loading: false
            };
        case GET_INVITED_STAFF_INFO_FAILED:
        case ON_CONFIRM_IDENTITY_FAILED:
        case ON_INVITED_STAFF_LOGIN_SUCCESS:
        case ON_CANCEL_GPHC_VERIFICATION_SUCCESS:
        case ON_INVITED_STAFF_LOGIN_FAILED:
        case ON_CANCEL_GPHC_VERIFICATION_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default staffInvitePage;
