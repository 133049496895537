import React from 'react';
import PropTypes from 'prop-types';
import ArrowButton from '../UI/buttons/arrow-button';
import ButtonComponent from '../UI/buttons/button';
import Switcher from '../UI/switcher';
import TextInput from '../UI/inputs/text-input';
import classnames from 'classnames';

const Toolbar = ({
    label,
    onNavigate,
    onAddShiftClick,
    onPostJobClick,
    onPrevClick,
    onNextClick,
    onMonthClick,
    changeView,
    view,
    switcher = true,
    searchInputs,
    onAddAbsenceClick,
    fullWidth
}) => {
    const navigate = (action) => onNavigate(action);
    const getSearchInputs = searchInputs
        ? searchInputs.map((input) => {
              const { name, value, placeholder, disabled, loading, onChange } = input;
              return (
                  <TextInput
                      key={name}
                      type="text"
                      name={name}
                      onChange={onChange}
                      value={value}
                      loading={loading}
                      disabled={disabled}
                      placeholder={placeholder}
                      icon="search"
                      fluid={false}
                  />
              );
          })
        : null;

    return (
        <div
            className={classnames('schedule-calendar__toolbar', {
                fullWidth
            })}
        >
            <div className="row">
                <div className="col col-title">
                    <ArrowButton
                        direction="left"
                        color="grey"
                        onClick={() => (onPrevClick ? onPrevClick() : navigate('PREV'))}
                    />
                    <h3 className="schedule-calendar__title">{label}</h3>
                    <ArrowButton
                        direction="right"
                        color="grey"
                        onClick={() => (onNextClick ? onNextClick() : navigate('NEXT'))}
                    />
                </div>
                {switcher && (
                    <div className="col col-buttons">
                        <Switcher
                            onLeftClick={() => (onMonthClick ? onMonthClick() : navigate('month'))}
                            leftValue="month"
                            onRightClick={() => changeView()}
                            rightValue="day"
                            activeView={view}
                        />
                    </div>
                )}
            </div>
            <div
                className={classnames('row', {
                    'inputs-row': searchInputs
                })}
            >
                {onAddAbsenceClick && (
                    <ButtonComponent value="Absence" width="medium" colorType="danger" onClick={onAddAbsenceClick} />
                )}

                {onPostJobClick && (
                    <ButtonComponent
                        value="Post job"
                        width="medium"
                        colorType="orange-color"
                        onClick={onPostJobClick}
                    />
                )}
                {onAddShiftClick && <ButtonComponent value="Add Shift" width="medium" onClick={onAddShiftClick} />}
                {getSearchInputs}
            </div>
        </div>
    );
};

Toolbar.propTypes = {
    view: PropTypes.string,
    label: PropTypes.node.isRequired,
    onNavigate: PropTypes.func,
    onPostJobClick: PropTypes.func,
    onAddShiftClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onMonthClick: PropTypes.func,
    changeView: PropTypes.func,
    switcher: PropTypes.bool,
    searchInputs: PropTypes.arrayOf(PropTypes.object),
    onAddAbsenceClick: PropTypes.func
};

export default Toolbar;
