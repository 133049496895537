import { call, put, fork, select, takeEvery } from 'redux-saga/effects';
import {
    getDayShiftsRequest,
    getDayShiftsSuccess,
    getDayShiftsFailed,
    onDayViewPostJobSuccess,
    onDayViewPostJobFailed,
    onDayViewSubmitDraftSuccess,
    onDayViewSubmitDraftFailed,
    onDayViewSubmitShiftSuccess,
    onDayViewSubmitShiftFailed,
    onRemoveShiftSuccess,
    onRemoveShiftFailed
} from '../../../actions';
import { selectPostJobDraft } from '../branch-schedule/selectors';
import { selectCurrentShiftId, selectCurrentShift } from './selectors';
import * as scheduleDayViewActionTypes from '../../../actions/admin-view/branch-schedule-day-view/action-types';
import api from '../../../../services/api';

function* onDayShiftsRequest({ payload: { id, date } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${id}/schedule/list`,
            method: 'post',
            payload: {
                startDateRange: date,
                endDateRange: date
            }
        });
        const setData = data.length > 0 ? data[0] : data;
        yield put(getDayShiftsSuccess(setData));
    } catch (err) {
        yield put(getDayShiftsFailed());
    }
}

function* onDayViewSubmitDraftRequest({ payload: { id, isEdit, recievedData, callback, date } }) {
    try {
        const currentShiftId = yield select(selectCurrentShiftId);
        let apiUrl;

        if (isEdit && recievedData.isInitialDraft) {
            apiUrl = `/branches/${id}/draft/${currentShiftId}`;
        } else if (isEdit && !recievedData.isInitialDraft) {
            apiUrl = `/branches/${id}/draft/`;
            recievedData.draftFor = currentShiftId;
        } else apiUrl = `/branches/${id}/draft/`;

        delete recievedData.isInitialDraft;

        yield call(api.request, {
            url: apiUrl,
            method: 'post',
            payload: recievedData
        });
        yield put(onDayViewSubmitDraftSuccess());
        callback();
        yield put(getDayShiftsRequest({ id, date }));
    } catch (err) {
        yield put(onDayViewSubmitDraftFailed());
    }
}

function* onDayViewSubmitShiftRequest({ payload: { id, isEdit, data, callback, date } }) {
    try {
        const currentShiftId = yield select(selectCurrentShiftId);
        let apiUrl = `/branches/${id}/schedule/`;

        if (isEdit && data.isInitialDraft) {
            apiUrl = `/branches/${id}/draft/${currentShiftId}/publish`;
        } else if (isEdit && !data.isInitialDraft) {
            apiUrl = `/branches/${id}/schedule/${currentShiftId}`;
        }

        if (data.isInitialDraft) delete data.isInitialDraft;

        yield call(api.request, {
            url: apiUrl,
            method: 'post',
            payload: data
        });
        yield put(onDayViewSubmitShiftSuccess());
        callback();
        yield put(getDayShiftsRequest({ id, date }));
    } catch (err) {
        yield put(onDayViewSubmitShiftFailed());
    }
}

function* onDayViewPostJobRequest({ payload: { id, data, toggleModal, date } }) {
    try {
        const postJobDraft = yield select(selectPostJobDraft);

        if (data) data.branchId = id;
        postJobDraft.branchId = id;

        yield call(api.request, {
            url: '/jobs',
            method: 'post',
            payload: !data ? postJobDraft : data
        });
        yield put(onDayViewPostJobSuccess());
        yield put(getDayShiftsRequest({ id, date }));
        toggleModal();
    } catch (err) {
        yield put(onDayViewPostJobFailed());
    }
}

function* onRemoveShiftRequest({ payload: { id, removeShift, callback, date } }) {
    try {
        const currentShift = yield select(selectCurrentShift);
        const currentShiftId = yield select(selectCurrentShiftId);
        const isDraft = currentShift.isDraft ? 'draft' : 'schedule';

        yield call(api.request, {
            url: `/branches/${id}/${isDraft}/${currentShiftId}`,
            method: 'delete',
            payload: removeShift
        });
        yield put(onRemoveShiftSuccess());
        callback();
        yield put(getDayShiftsRequest({ id, date }));
    } catch (err) {
        yield put(onRemoveShiftFailed());
    }
}
// watchers
function* watchDayShiftsRequest() {
    yield takeEvery(scheduleDayViewActionTypes.GET_DAY_SHIFTS_REQUEST, onDayShiftsRequest);
}

function* watchDayViewPostJobRequest() {
    yield takeEvery(scheduleDayViewActionTypes.ON_DAY_VIEW_POST_JOB_REQUEST, onDayViewPostJobRequest);
}

function* watchDayViewSubmitDraftRequest() {
    yield takeEvery(scheduleDayViewActionTypes.ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST, onDayViewSubmitDraftRequest);
}

function* watchDayViewSubmitShiftRequest() {
    yield takeEvery(scheduleDayViewActionTypes.ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST, onDayViewSubmitShiftRequest);
}

function* watchRemoveShiftRequest() {
    yield takeEvery(scheduleDayViewActionTypes.ON_REMOVE_SHIFT_REQUEST, onRemoveShiftRequest);
}

const scheduleDayViewSaga = [
    fork(watchDayShiftsRequest),
    fork(watchDayViewPostJobRequest),
    fork(watchDayViewSubmitDraftRequest),
    fork(watchDayViewSubmitShiftRequest),
    fork(watchRemoveShiftRequest)
];

export default scheduleDayViewSaga;
