import {
    GET_PHARMACY_INFO_SUCCESS,
    GET_PHARMACY_INFO_FAILED,
    ON_PHARMACY_STATUS_CHANGE_REQUEST,
    ON_PHARMACY_STATUS_CHANGE_SUCCESS,
    ON_PHARMACY_STATUS_CHANGE_FAILED
} from '../../../actions/super-admin-view/pharmacy-info-page/action-types';

const initialState = {
    data: {},
    loading: false
};

const adminPharmacyInfoPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_PHARMACY_INFO_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        case GET_PHARMACY_INFO_FAILED:
            return state;
        case ON_PHARMACY_STATUS_CHANGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ON_PHARMACY_STATUS_CHANGE_SUCCESS:
        case ON_PHARMACY_STATUS_CHANGE_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default adminPharmacyInfoPage;
