import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import StaffProfilePage from '../../../../shared/staff-profile-page';

export function UserInformation({ data, status, loading, onChangeUserStatusRequest }) {
    let { staffId } = useParams();
    const onUserAction = useCallback(
        (actionName) => onChangeUserStatusRequest({ userId: staffId }, actionName),
        [onChangeUserStatusRequest, staffId]
    );

    return (
        <StaffProfilePage
            personalInfo={data}
            userStatus={status}
            loading={loading}
            isSuperAdmin
            onUserAction={onUserAction}
        />
    );
}

UserInformation.propTypes = {
    data: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired
};
