import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useLocation } from 'react-router';
import moment from 'moment';
import { RouterConfig } from '../../../../../../routerConfig';

import DayViewItem from './DayViewItem';
import Toolbar from '../../../../../../components/schedule-calendar/Toolbar';
import EditTemplateModal from '../../../../../../components/modals/edit-template-modal';
import EditShiftDraftConfirmModal from '../../../../../../components/modals/edit-shift-draft-confirm-modal';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';
import AbsenceRequestModal from '../../../../../../components/modals/absence-request-modal';

import AddEditShiftScheduleForm from '../../../../../../components/forms/add-edit-shift-schedule-form';
import AbsenceRequestForm from '../../../../../../components/forms/absence-request-form';

import { getUniqueEvents } from '../../../../../../services/helpers';
import { useModal } from '../../../../../../services/hooks';

import './style.scss';
import AddEditBulkShiftScheduleForm from '../../../../../../components/forms/add-edit-bulk-shift-schedule-form/AddEditBulkShiftScheduleForm';
import * as queryString from 'query-string';
import PostJobBulkForm from '../../../../../../components/forms/post-job-bulk-form';

export function ScheduleCalendarDayView({
    showBackButton,
    hideBackButton,
    getPostJobDraft,
    postJobDraft,
    separateJob,
    longTermJob,
    getCurrentShift,
    getCurrentShiftId,
    getDayShiftsRequest,
    getBranchAvailableStaffRequest,
    onDayViewPostJobRequest,
    onDayViewSubmitDraftRequest,
    onDayViewSubmitShiftRequest,
    onRemoveShiftRequest,
    currentShift,
    currentShiftId,
    dayEvents,
    staffList,
    loading,
    removingLoading,
    getLocumScheduleAvailabilityRequest,
    clearLocumData,
    locumData,
    operatingTime,
    currentDay,
    match: {
        params: { id, date }
    },
    history,
    postStaffAbsenceRequest,
    shiftsList,
    calculatedActualStaffShiftTime,
    getAllStaffRequest,
    getCalculatedEmployeeShiftHoursClear,
    getJobLocumsByFilterRequest,
    clearGetJobLocumsByFilter,
    locums,
    getAllMileageRulesRequest,
    getAllTransportRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    mileageRules,
    transportRules,
    parkingRules,
    accommodationRules,
    allStaff,
    onSubmitBulkShiftRequest,
    onSubmitShiftDraftBulkRequest,
    onPostJobBulkRequest
}) {
    const [removeShift, setRemoveShift] = useState(null);
    const [addShift, toggleAddShift] = useModal(false);
    const [editShift, toggleEditShift] = useModal(false);
    const [postJob, togglePostJob] = useModal(false);
    const [absenceRequest, toggleAbsenceRequest] = useModal(false);
    const [editShiftConfirm, toggleEditShiftConfirm] = useModal(false);
    const [removeShiftModal, toggleRemoveShift] = useModal(false);
    const [inviteExternalLocumConfirm, toggleInviteExternalLocumConfirm] = useModal(false);
    const [jobType, setJobType] = useState(1);

    const { error: locumDataError } = locumData;

    // get updated job object dependency
    const currentPostJobDraft = useRef(null);
    currentPostJobDraft.current = postJobDraft;

    const location = useLocation();
    useEffect(() => {
        if (!location.search || !dayEvents || !dayEvents.shifts.length) return;

        const params = queryString.parse(location.search);
        if (!params.openShiftId) return;

        getShiftForEditing(parseInt(params.openShiftId));
        // eslint-disable-next-line
    }, [location.search, dayEvents]);

    const getAvailableStaff = useCallback(
        (recievedData) => {
            getBranchAvailableStaffRequest({
                recievedData
            });
        },
        [getBranchAvailableStaffRequest]
    );

    useEffect(() => {
        if (editShift && location.search) {
            history.replace({ search: '' });
        }
        // eslint-disable-next-line
    }, [editShift, location.search]);

    const getShiftForEditing = (shiftId) => {
        dayEvents.shifts.forEach((el) => {
            if (el.id === shiftId) {
                getCurrentShift(el);
                getCurrentShiftId(shiftId);
                toggleEditShift();
            }
        });
    };

    const onPostJob = useCallback(
        (data) => {
            onDayViewPostJobRequest({
                id,
                data,
                toggleModal: () => {
                    togglePostJob();
                },
                date
            });
            clearLocumData();
        },
        [date, id, onDayViewPostJobRequest, clearLocumData, togglePostJob]
    );

    const onLocumDataClear = useCallback(() => {
        clearLocumData();
    }, [clearLocumData]);

    const onSubmitShift = useCallback(
        (data, isEdit) => {
            onDayViewSubmitShiftRequest({
                id,
                isEdit,
                data,
                callback: () => {
                    if (isEdit) {
                        toggleEditShift();
                        toggleEditShiftConfirm();
                    } else {
                        toggleAddShift();
                    }
                },
                date
            });
        },
        [date, id, onDayViewSubmitShiftRequest, toggleAddShift, toggleEditShift, toggleEditShiftConfirm]
    );

    const onGphcChange = useCallback(
        ({ gphcNumber, name, startDate, endDate, startTime, endTime }) => {
            getJobLocumsByFilterRequest({
                gphcNumber,
                name,
                startDate,
                endDate,
                startTime,
                endTime
            });
        },
        // eslint-disable-next-line
        [getJobLocumsByFilterRequest, clearGetJobLocumsByFilter]
    );

    const onLocumsInitialGet = useCallback(() => {
        getJobLocumsByFilterRequest({});
    }, [getJobLocumsByFilterRequest]);

    const onSubmitDraft = useCallback(
        (recievedData, isEdit) => {
            onDayViewSubmitDraftRequest({
                id,
                isEdit,
                recievedData,
                callback: () => {
                    if (isEdit) {
                        toggleEditShift();
                        toggleEditShiftConfirm();
                    } else {
                        toggleAddShift();
                    }
                },
                date
            });
        },
        [date, id, onDayViewSubmitDraftRequest, toggleAddShift, toggleEditShift, toggleEditShiftConfirm]
    );

    const onRemoveShift = useCallback(() => {
        onRemoveShiftRequest({
            id,
            removeShift,
            callback: () => {
                toggleRemoveShift();
                toggleEditShift();
            },
            date
        });
    }, [date, id, onRemoveShiftRequest, removeShift, toggleEditShift, toggleRemoveShift]);

    useEffect(() => {
        showBackButton();
        getDayShiftsRequest({ id, date });
        return () => hideBackButton();
    }, [date, getDayShiftsRequest, hideBackButton, id, showBackButton]);

    useEffect(() => {
        if (absenceRequest) return;
        getCalculatedEmployeeShiftHoursClear();
    }, [absenceRequest, getCalculatedEmployeeShiftHoursClear]);

    useEffect(() => {
        getAllMileageRulesRequest();
        getAllTransportRulesRequest();
        getAllParkingRulesRequest();
        getAllAccommodationRulesRequest();
        getAllStaffRequest();
    }, [
        getAllMileageRulesRequest,
        getAllTransportRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest,
        getAllStaffRequest
    ]);

    const onDateChange = useCallback(
        (dateChange) => {
            const getNewDate = moment(date).add(dateChange, 'days').format('YYYY-MM-DD');
            history.push(
                generatePath(RouterConfig.branchAdminBranchScheduleDayView, {
                    id,
                    date: getNewDate
                })
            );
        },
        [date, history, id]
    );

    const onInviteExternalLocumConfirm = useCallback(() => {
        toggleInviteExternalLocumConfirm();
        onPostJob();
        // eslint-disable-next-line
    }, [toggleInviteExternalLocumConfirm, onPostJob, jobType]);

    const checkExternalLocum = useCallback(
        (data, jobType) => {
            getPostJobDraft(data);
            setJobType(jobType);
            if (locumDataError) {
                toggleInviteExternalLocumConfirm();
            } else {
                onPostJob(data);
            }
        },
        [toggleInviteExternalLocumConfirm, onPostJob, getPostJobDraft, locumDataError, setJobType]
    );

    const getStaffList = useCallback(() => {
        getAllStaffRequest();
    }, [getAllStaffRequest]);

    const onAbsenceRequestSubmit = useCallback(
        (absenceData) => {
            postStaffAbsenceRequest({
                id: id,
                absenceData,
                callback: () => {
                    toggleAbsenceRequest();
                    getDayShiftsRequest({ id, date });
                }
            });
        },
        [id, postStaffAbsenceRequest, toggleAbsenceRequest, date, getDayShiftsRequest]
    );

    const onSubmitBulkShift = (shifts) => {
        onSubmitBulkShiftRequest({
            id,
            data: shifts,
            toggleModal: () => toggleAddShift(),
            startDateRange: moment().format('YYYY-MM-DD'),
            endDateRange: moment().format('YYYY-MM-DD')
        });
    };

    const onSubmitBulkDraft = (data) => {
        onSubmitShiftDraftBulkRequest({
            id,
            data,
            toggleModal: () => toggleAddShift(),
            startDateRange: moment().format('YYYY-MM-DD'),
            endDateRange: moment().format('YYYY-MM-DD')
        });
    };

    const onSubmitJobBulk = (data) => {
        onPostJobBulkRequest({
            id,
            data,
            toggleModal: togglePostJob(),
            startDateRange: date,
            endDateRange: date
        });
    };

    if (!dayEvents) {
        return null;
    } else if (dayEvents && dayEvents.branchClosed) {
        return (
            <div className="schedule-day-view">
                <Toolbar
                    label={moment(date).format('dddd Do, MMM')}
                    onPrevClick={() => onDateChange(-1)}
                    onNextClick={() => onDateChange(1)}
                    onMonthClick={() => history.push(generatePath(RouterConfig.branchAdminBranchSchedule, { id }))}
                    view="day"
                />
                <div className="schedule-day-view__closed">
                    <h3 className="title">Branch closed</h3>
                </div>
            </div>
        );
    } else {
        const settled = getUniqueEvents(dayEvents);
        const getDayEvents = [];

        settled.forEach((el, i) => {
            getDayEvents.push(
                <DayViewItem
                    key={id + i}
                    data={el}
                    startWorkingTime={dayEvents.startWorkingTime}
                    endWorkingTime={dayEvents.endWorkingTime}
                    onJobCellClick={(jobId) =>
                        history.push(
                            generatePath(RouterConfig.branchAdminJobPreview, {
                                id,
                                jobId
                            })
                        )
                    }
                    onShiftCellClick={(shiftId) => getShiftForEditing(shiftId)}
                />
            );
        });

        const removeShiftButton = {
            value: 'Confirm',
            onClick: onRemoveShift,
            size: 'big',
            width: 'big',
            loading: removingLoading
        };

        return (
            <div className="schedule-day-view">
                <Toolbar
                    label={moment(date).format('dddd Do, MMM')}
                    onPrevClick={() => onDateChange(-1)}
                    onNextClick={() => onDateChange(1)}
                    onMonthClick={() => history.push(generatePath(RouterConfig.branchAdminBranchSchedule, { id }))}
                    onAddShiftClick={() => toggleAddShift()}
                    onPostJobClick={() => togglePostJob()}
                    onAddAbsenceClick={() => toggleAbsenceRequest()}
                    view="day"
                />
                <div className="working-time-bar">
                    <span className="time start">{dayEvents.startWorkingTime}</span>
                    <span className="time end">{dayEvents.endWorkingTime}</span>
                </div>
                <div className={`cards-holder ${getDayEvents && getDayEvents.length > 6 ? 'scrollable' : ''}`}>
                    {dayEvents && dayEvents.shifts ? (
                        getDayEvents
                    ) : dayEvents && dayEvents.length === 0 ? (
                        <p className="empty-shifts-list">No shifts yet</p>
                    ) : null}
                </div>
                {addShift && (
                    <EditTemplateModal
                        open={addShift}
                        onClose={() => toggleAddShift()}
                        title="Add Shift"
                        subtitle={moment(date).format('D MMM, dddd')}
                        type="add"
                        color="blue"
                        size="small"
                    >
                        <AddEditBulkShiftScheduleForm
                            loading={loading}
                            staffList={allStaff}
                            getStaff={getAvailableStaff}
                            onSubmitClick={onSubmitShift}
                            onSubmitBulkShift={onSubmitBulkShift}
                            onSubmitDraftBulkClick={onSubmitBulkDraft}
                            operatingTime={operatingTime}
                            onSubmitDraftClick={onSubmitDraft}
                            draftSaved={dayEvents.draftSaved}
                            currentDate={date}
                        />
                    </EditTemplateModal>
                )}
                {editShift && (
                    <EditTemplateModal
                        open={editShift}
                        onClose={() => toggleEditShift()}
                        title={currentShift.isDraft ? 'Edit draft' : 'Edit shift'}
                        subtitle={moment(date).format('D MMM, dddd')}
                        type="edit"
                        color={currentShift.isDraft ? 'lightblue' : 'green'}
                        size="small"
                    >
                        <AddEditShiftScheduleForm
                            loading={loading}
                            staffList={staffList}
                            getStaff={getAvailableStaff}
                            onSubmitClick={onSubmitShift}
                            onSubmitDraftClick={onSubmitDraft}
                            onSubmitConfirm={(data, updatedCurrentShift) => {
                                getCurrentShift(updatedCurrentShift);
                                toggleEditShiftConfirm();
                            }}
                            onCancelClick={(data) => {
                                setRemoveShift(data);
                                toggleRemoveShift();
                            }}
                            shiftEdit={currentShift}
                            draftSaved={dayEvents.draftSaved}
                            currentDate={date}
                        />
                    </EditTemplateModal>
                )}
                {editShiftConfirm && (
                    <EditShiftDraftConfirmModal
                        open={editShiftConfirm}
                        onClose={() => toggleEditShiftConfirm()}
                        shift={currentShift}
                        loading={loading}
                        onSaveDraftClick={(data) => onSubmitDraft(data, true)}
                        onPublishClick={(data) => onSubmitShift(data, true)}
                    />
                )}
                {postJob && (
                    <EditTemplateModal
                        open={postJob}
                        onClose={() => {
                            togglePostJob();
                            clearLocumData();
                        }}
                        title="Post job"
                        subtitle={moment(date).format('D MMM, dddd')}
                        type="none"
                        color="orange"
                        size="small"
                    >
                        <PostJobBulkForm
                            loading={loading}
                            calledFromShift
                            locumData={locumData}
                            locums={locums}
                            jobData={{
                                startTime: dayEvents.startWorkingTime,
                                endTime: dayEvents.endWorkingTime,
                                startDate: dayEvents.startDate,
                                endDate: dayEvents.endDate,
                                lunchEndTime: dayEvents.lunchEndTime,
                                lunchStartTime: dayEvents.lunchStartTime
                            }}
                            operatingTime={operatingTime}
                            onGphcChange={onGphcChange}
                            onLocumDataClear={onLocumDataClear}
                            defaultDate={moment(date)}
                            onCloseClick={() => togglePostJob()}
                            onSubmitClick={(data) => checkExternalLocum(data, 1)}
                            onSubmitBulk={(data) => onSubmitJobBulk(data)}
                            clearGetJobLocumsByFilter={clearGetJobLocumsByFilter}
                            onLocumsInitialGet={onLocumsInitialGet}
                            mileageRules={mileageRules}
                            transportRules={transportRules}
                            parkingRules={parkingRules}
                            accommodationRules={accommodationRules}
                        />
                    </EditTemplateModal>
                )}

                {removeShiftModal && (
                    <ConfirmModal
                        open={removeShiftModal}
                        onClose={() => toggleRemoveShift()}
                        title="Remove shift"
                        text="Are you sure you want to remove this shift?"
                        button={removeShiftButton}
                    />
                )}
                {inviteExternalLocumConfirm && (
                    <ConfirmModal
                        open={inviteExternalLocumConfirm}
                        onClose={() => toggleInviteExternalLocumConfirm()}
                        title="Warning"
                        text="Are you sure want to invite this Locum for a Job?"
                        button={{
                            value: 'Yes',
                            onClick: () => onInviteExternalLocumConfirm(),
                            size: 'big',
                            width: 'big'
                        }}
                    />
                )}
                {absenceRequest && (
                    <AbsenceRequestModal
                        open={absenceRequest}
                        subtitle={moment(currentDay.startDate).format('D MMM, dddd')}
                        onClose={() => toggleAbsenceRequest()}
                        title="Absence Request"
                    >
                        <AbsenceRequestForm
                            getStaffList={getStaffList}
                            onCloseClick={() => toggleAbsenceRequest()}
                            onSubmit={onAbsenceRequestSubmit}
                            calculatedActualStaffShiftTime={calculatedActualStaffShiftTime}
                        />
                    </AbsenceRequestModal>
                )}
            </div>
        );
    }
}

ScheduleCalendarDayView.propTypes = {
    currentShift: PropTypes.object,
    showBackButton: PropTypes.func,
    hideBackButton: PropTypes.func,
    getPostJobDraft: PropTypes.func,
    postJobDraft: PropTypes.object,
    separateJob: PropTypes.func,
    longTermJob: PropTypes.func,
    getCurrentShift: PropTypes.func,
    getCurrentShiftId: PropTypes.func,
    currentShiftId: PropTypes.number,
    getLocumByGphcRequest: PropTypes.func,
    clearLocumData: PropTypes.func,
    locumData: PropTypes.object,
    calculatedActualStaffShiftTime: PropTypes.object
};
