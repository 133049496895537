import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { getJobLocumsByFilterSuccess, getJobLocumsByFilterFailed } from '../../../actions';

import * as actionTypes from '../../../actions/admin-view/post-job-form/action-types';

import api from '../../../../services/api';

function* onGetJobLocumsByFilterRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/jobs/external-locums-by-filter`,
            method: 'post',
            payload: { filters: payload }
        });
        yield put(getJobLocumsByFilterSuccess(data));
    } catch (err) {
        yield put(getJobLocumsByFilterFailed());
    }
}

function* watchJobLocumsByFilterRequest() {
    yield takeEvery(actionTypes.GET_JOB_LOCUMS_BY_FILTER_REQUEST, onGetJobLocumsByFilterRequest);
}

const postJobFormSaga = [fork(watchJobLocumsByFilterRequest)];

export default postJobFormSaga;
