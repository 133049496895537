export const SHOW_BACK_BUTTON = 'SHOW_BACK_BUTTON';
export const HIDE_BACK_BUTTON = 'HIDE_BACK_BUTTON';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

export const SET_CONTAINER_WIDE = 'SET_CONTAINER_WIDE';
export const SET_CONTAINER_DEFAULT = 'SET_CONTAINER_DEFAULT';

export const TOGGLE_PAGE_VIEW = 'TOGGLE_PAGE_VIEW';
