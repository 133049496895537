import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const EventCell = ({ title, event: { status, date } }) => {
    return (
        <div
            data-event-date={date}
            className={classnames('schedule-calendar__event-cell', {
                [status]: status
            })}
        >
            {title}
        </div>
    );
};

EventCell.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    event: PropTypes.object
};

export default EventCell;
