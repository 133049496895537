import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getJobDetailsRequest,
    getJobDetailsSuccess,
    getJobDetailsFailed,
    getWorkedLocumsRequest,
    getWorkedLocumsSuccess,
    getWorkedLocumsFailed,
    onEditJobSuccess,
    onEditJobFailed,
    onCancelJobSuccess,
    onCancelJobFailed,
    onLocumActionSuccess,
    onLocumActionFailed
} from '../../../actions';
import * as jobPreviewActionTypes from '../../../actions/admin-view/job-preview-page/action-types';
import api from '../../../../services/api';
import { getJobCancelReasonsSuccess, getJobCancelReasonsFailed } from '../../../actions/admin-view/job-preview-page';

function* onJobDetailsRequest({ jobId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/jobs/${jobId}/`,
            method: 'get'
        });
        yield put(getJobDetailsSuccess(data));
    } catch (err) {
        yield put(getJobDetailsFailed());
    }
}

function* onWorkedLocumsRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/staff/worked-locums',
            method: 'get'
        });
        yield put(getWorkedLocumsSuccess(data));
    } catch (err) {
        yield put(getWorkedLocumsFailed());
    }
}

function* onEditJobRequest({ payload: { branchId, jobId, data, toggleModal } }) {
    try {
        data.branchId = branchId;
        data.split = false;
        yield call(api.request, {
            url: `/jobs/${jobId}/`,
            method: 'post',
            payload: data
        });
        yield put(onEditJobSuccess());
        toggleModal();
        yield put(getJobDetailsRequest(jobId));
        yield put(getWorkedLocumsRequest());
    } catch (err) {
        yield put(onEditJobFailed());
    }
}

function* onCancelJobRequest({ payload: { jobId, cancelData, toggleModal } }) {
    try {
        yield call(api.request, {
            url: `/jobs/${jobId}/cancel`,
            method: 'post',
            payload: {
                cancelData
            }
        });
        yield put(onCancelJobSuccess());
        toggleModal();
        yield put(getJobDetailsRequest(jobId));
    } catch (err) {
        yield put(onCancelJobFailed());
    }
}

function* onLocumActionRequest({ payload: { jobId, action, locumId, callback } }) {
    try {
        yield call(api.request, {
            url: `/jobs/${jobId}/${action}/${locumId}`,
            method: 'post'
        });
        yield put(onLocumActionSuccess({ action }));
        yield put(getJobDetailsRequest(jobId));
        callback && callback();
    } catch (err) {
        yield put(onLocumActionFailed({ action }));
    }
}

function* onGetJobCancelReasonsRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/jobs/cancellation-reasons`,
            method: 'get'
        });
        yield put(getJobCancelReasonsSuccess(data));
    } catch (err) {
        yield put(getJobCancelReasonsFailed());
    }
}

// watchers
function* watchJobDetailsRequest() {
    yield takeEvery(jobPreviewActionTypes.GET_JOB_DETAILS_REQUEST, onJobDetailsRequest);
}

function* watchWorkedLocumsRequest() {
    yield takeEvery(jobPreviewActionTypes.GET_WORKED_LOCUMS_REQUEST, onWorkedLocumsRequest);
}

function* watchEditJobRequest() {
    yield takeEvery(jobPreviewActionTypes.ON_EDIT_JOB_REQUEST, onEditJobRequest);
}

function* watchCancelJobRequest() {
    yield takeEvery(jobPreviewActionTypes.ON_CANCEL_JOB_REQUEST, onCancelJobRequest);
}

function* watchLocumActionRequest() {
    yield takeEvery(jobPreviewActionTypes.ON_LOCUM_ACTION_REQUEST, onLocumActionRequest);
}

function* watchGetJobCancelReasonsSuccess() {
    yield takeEvery(jobPreviewActionTypes.GET_JOB_CANCEL_REASONS_REQUEST, onGetJobCancelReasonsRequest);
}

const jobPreviewPageSaga = [
    fork(watchJobDetailsRequest),
    fork(watchWorkedLocumsRequest),
    fork(watchEditJobRequest),
    fork(watchCancelJobRequest),
    fork(watchLocumActionRequest),
    fork(watchGetJobCancelReasonsSuccess)
];

export default jobPreviewPageSaga;
