import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LocumJobOffersList from './list';
import LocumJobPreview from './job-preview';

function LocumJobOffers({ history, location: { pathname } }) {
    const [jobPreviewId, setJobPreviewId] = useState(null);
    const { state, search } = useLocation();

    useEffect(() => {
        if (state) setJobPreviewId(state[0]);
    }, [state]);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const previewOpen = searchParams.get('jobPreviewOpen');
        if (jobPreviewId && !previewOpen) {
            searchParams.append('jobPreviewOpen', 'true');
            history.replace({
                search: searchParams.toString()
            });
        } else {
            if (previewOpen && !jobPreviewId) {
                searchParams.delete('jobPreviewOpen');
                history.replace({
                    search: searchParams.toString()
                });
            }
        }
        // eslint-disable-next-line
    }, [jobPreviewId, search]);

    return (
        <>
            {!jobPreviewId ? (
                <LocumJobOffersList openJobPreview={(jobId) => setJobPreviewId(jobId)} />
            ) : (
                <LocumJobPreview
                    jobId={jobPreviewId}
                    onCloseJobPreview={() => {
                        setJobPreviewId(null);
                        history.replace({ pathname, search });
                    }}
                />
            )}
        </>
    );
}

export default LocumJobOffers;
