import React from 'react';
import PropTypes from 'prop-types';

import ButtonModal from '../../UI/buttons/button-modal';

import { Modal } from 'semantic-ui-react';
import moment from 'moment';

import './style.scss';

// sizes options: mini, tiny, small, large, fullscreen

const EditShiftDraftConfirmModal = ({
    size = 'small',
    open,
    onClose,
    loading,
    onSaveDraftClick,
    onPublishClick,
    shift,
    ...props
}) => {
    const onSubmitClick = () => {
        const getDataObject = Object.assign({}, shift);
        if (getDataObject.submitMode === 'draft') {
            delete getDataObject.submitMode;
            delete getDataObject.isDraft;
            onSaveDraftClick(getDataObject);
        } else {
            delete getDataObject.submitMode;
            delete getDataObject.isDraft;
            onPublishClick(getDataObject);
        }
    };
    const confirmText = shift.endDate ? (
        <p className="content-text">
            This update would affect shifts from <span>{moment(shift.startDate).format('D MMMM YYYY')}</span> to{' '}
            <span>{moment(shift.endDate).format('D MMMM YYYY')}</span>. If you want to update all shifts please remove
            finish date.
        </p>
    ) : (
        <p className="content-text">
            This update would affect all shift from <span>{moment(shift.startDate).format('D MMMM YYYY')}</span>. If you
            want to update selected shifts please add finish date.
        </p>
    );
    return (
        <Modal
            closeIcon
            size={size}
            open={open}
            centered
            onClose={onClose}
            className="edit-shift-draft-confirm-modal confirm-modal "
        >
            <Modal.Header className=" confirm-modal__header">
                {`Do you want to update ${shift.isDraft ? 'draft' : 'shift'}?`}
            </Modal.Header>
            <Modal.Content>
                {confirmText}
                <div className="edit-shift-draft-confirm-modal__btn-controller confirm-and-cancel-buttons">
                    <ButtonModal value="Back to Edit" colorType="red" onClick={onClose} disabled={loading} />
                    <ButtonModal
                        value={shift.isDraft ? 'Save Updates' : 'Publish Updates'}
                        colorType="blue"
                        onClick={onSubmitClick}
                        disabled={loading}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
};

EditShiftDraftConfirmModal.propTypes = {
    size: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSaveDraftClick: PropTypes.func.isRequired,
    onPublishClick: PropTypes.func.isRequired,
    shift: PropTypes.object.isRequired
};

export default EditShiftDraftConfirmModal;
