import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import CheckboxListContainer from '../../../containers/checkbox-list';
import { getDuration, getDurationFormat, parseTime, weekDays } from '../../../services/datesHelper';
import AttachedStaffCard from '../../UI/attached-staff-card';
import ButtonModal from '../../UI/buttons/button-modal';
import SelectComponent from '../../UI/select/SelectComponent';
import Timepicker from '../../UI/timepicker';
import './style.scss';

const TIME_FORMAT = 'HH:mm';

class AddEditShiftForm extends Component {
    state = {
        weekDaysCheckboxes: [],
        attachedStaff: null
    };

    componentDidMount() {
        this._isMounted = true;
        const { shiftEdit, operatingTime, day, week } = this.props;
        const weekDaysCheckboxes = weekDays.map((item) => {
            const available = operatingTime.find((ot) => ot.dayOfWeek === item);
            const editable = shiftEdit && shiftEdit.dayOfWeek === item;
            const timeStart =
                editable && shiftEdit.startTime
                    ? moment(parseTime(shiftEdit.startTime))
                    : available && available.startTime
                    ? moment(parseTime(available.startTime))
                    : '';
            const timeFinish =
                editable && shiftEdit.endTime
                    ? moment(parseTime(shiftEdit.endTime))
                    : available && available.endTime
                    ? moment(parseTime(available.endTime))
                    : '';
            const duration = getDuration(timeStart, timeFinish);
            return {
                title: item,
                checked: editable ? editable : item === day,
                disabled: !available,
                timeStart,
                timeFinish,
                dayOfWeek: day,
                weekNumber: week + '',
                duration
            };
        });

        this.setState({
            weekDaysCheckboxes,
            attachedStaff: shiftEdit ? shiftEdit.staff : null
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onStatTimeChange = ({ time, day }) => {
        const { weekDaysCheckboxes } = this.state;
        const newWeeks = weekDaysCheckboxes.map((week) => {
            if (week.title === day.id) {
                time.set({ second: 0 });
                const setDuration = week.timeFinish ? getDuration(time, week.timeFinish) : '';
                return {
                    ...week,
                    timeStart: time,
                    duration: setDuration
                };
            }
            return week;
        });

        this.setState({ weekDaysCheckboxes: newWeeks });
    };

    onFinishTimeChange = ({ time, day }) => {
        const { weekDaysCheckboxes } = this.state;
        const newWeeks = weekDaysCheckboxes.map((week) => {
            if (week.title === day.id) {
                time.set({ second: 0 });
                const setDuration = week.timeStart ? getDuration(week.timeStart, time) : '';
                return {
                    ...week,
                    timeFinish: time,
                    duration: setDuration
                };
            }
            return week;
        });

        this.setState({ weekDaysCheckboxes: newWeeks });
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            this.onAttachStaff(value)
        );
    };

    onShowStaffListClick = () => {
        const { showAttachStaff } = this.state;
        this.setState({ showAttachStaff: !showAttachStaff });
    };

    onAttachStaff = (id) => {
        const { staffList } = this.props;

        this.setState({
            attachedStaff: staffList.find((staff) => staff.id === id)
        });
    };

    onRemoveAttachedStaff = () => {
        this.setState({
            attachedStaff: null
        });
    };

    onSubmitClickHandler = (e) => {
        e.preventDefault();
        const { onSubmitClick, shiftEdit, week } = this.props;
        const isEdit = shiftEdit ? true : false;

        const { weekDaysCheckboxes, attachedStaff } = this.state;

        const data = weekDaysCheckboxes
            .filter((wd) => wd.checked)
            .map((wd) => {
                return {
                    dayOfWeek: wd.title,
                    weekNumber: week + '',
                    startTime: wd.timeStart.format('HH:mm'),
                    endTime: wd.timeFinish.format('HH:mm'),
                    staffId: attachedStaff ? attachedStaff.id : null
                };
            });

        onSubmitClick(data, isEdit);
    };

    onCancelClickHandler = (e) => {
        e.preventDefault();
        const { shiftEdit, day, week } = this.props;
        this.props.onCancelClick({
            name: 'remove',
            value: { id: shiftEdit.id, day },
            weekNumber: week
        });
    };

    onCheckboxChangeHandler = (e, { name }) => {
        const { weekDaysCheckboxes } = this.state;
        let selectedDays = [];

        weekDaysCheckboxes.forEach((el) => {
            if (name === el.title) {
                el.checked = !el.checked;
            }
            if (el.checked) selectedDays.push(el.title);
        });

        this.setState(
            (prevState) => ({ [name]: !prevState[name], selectedDays }),
            () => this.onShowStaffListClick()
        );
    };

    render() {
        const { weekDaysCheckboxes, attachedStaff } = this.state;
        const { loading, shiftEdit, staffList } = this.props;

        const anyDaySelected = weekDaysCheckboxes.find((wd) => wd.checked);
        const filteredStaffOptions = staffList.map((option) => {
            const { id, name, surname, image, role } = option;
            return {
                key: id,
                value: id,
                text: (
                    <div className="option-inner">
                        <span className="option-inner__name">{`${name} ${surname}`}</span>
                        <span className="option-inner__role">{role.replace(/_/g, ' ')}</span>
                    </div>
                ),
                image: { avatar: !!image, src: image }
            };
        });

        const daysCheckboxes = weekDaysCheckboxes.map((item) => {
            return {
                id: item.title,
                label: item.title.substring(0, 3),
                name: item.title,
                checked: item.checked,
                onChange: this.onCheckboxChangeHandler,
                disabled: item.disabled,
                duration: item.duration
            };
        });

        const displayAttachedStaff = attachedStaff ? (
            <AttachedStaffCard
                id={attachedStaff.id}
                name={attachedStaff.name}
                surname={attachedStaff.surname}
                image={attachedStaff.image}
                role={attachedStaff.role}
                onDangerButtonClick={this.onRemoveAttachedStaff}
            />
        ) : null;

        const removeShiftButton = shiftEdit ? (
            <ButtonModal value="Remove shift" colorType="red" onClick={this.onCancelClickHandler} />
        ) : null;

        return (
            <Form className="add-shift-form" onSubmit={this.onSubmitClickHandler}>
                <div className="content-row__item">
                    <CheckboxListContainer
                        items={daysCheckboxes}
                        layout="row"
                        boxesSize="small"
                        disabled={!!this.props.shiftEdit}
                    />
                </div>
                {daysCheckboxes
                    .filter((day) => day.checked)
                    .map((day) => {
                        const displayShiftDuration = getDurationFormat(day.duration);
                        const selectedDay = weekDaysCheckboxes.find((wd) => wd.title === day.id);
                        return (
                            <div key={day.id} className="content-row">
                                <div className="content-row__item">
                                    <p className="week-title">{day.name[0].toUpperCase() + day.name.slice(1)}</p>
                                    <p className="title">Operating Hours</p>
                                    <div className="inner">
                                        <Timepicker
                                            size="large"
                                            onChange={(time) =>
                                                this.onStatTimeChange({
                                                    time,
                                                    day
                                                })
                                            }
                                            format={TIME_FORMAT}
                                            minuteStep={15}
                                            value={selectedDay.timeStart || null}
                                            disabled={loading}
                                        />
                                        <p className="duration">{displayShiftDuration}</p>
                                        <Timepicker
                                            size="large"
                                            onChange={(time) =>
                                                this.onFinishTimeChange({
                                                    time,
                                                    day
                                                })
                                            }
                                            format={TIME_FORMAT}
                                            minuteStep={15}
                                            value={selectedDay.timeFinish || null}
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                <div className={`content-row__item ${attachedStaff ? '' : 'hidden'}`}>
                    <div className="inner column">{displayAttachedStaff}</div>
                </div>

                {anyDaySelected && (
                    <div className={`content-row__item ${attachedStaff ? '' : 'hidden'}`}>
                        <div className="inner column">
                            <SelectComponent
                                name="currentStaff"
                                icon="caret down"
                                floating
                                onChange={this.onChangeHandler}
                                options={filteredStaffOptions}
                            />
                        </div>
                    </div>
                )}

                <div className={`buttons-row ${shiftEdit ? 'double' : ''}`}>
                    {removeShiftButton}
                    <ButtonModal
                        disabled={!attachedStaff}
                        value={shiftEdit ? 'Save' : 'Create shift'}
                        colorType="blue"
                        onClick={this.onSubmitClickHandler}
                        loading={loading}
                    />
                </div>
            </Form>
        );
    }
}

AddEditShiftForm.propTypes = {
    branches: PropTypes.array,
    staffList: PropTypes.array,
    day: PropTypes.string,
    shiftEdit: PropTypes.object,
    loading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onSubmitClick: PropTypes.func.isRequired
};

export default AddEditShiftForm;
