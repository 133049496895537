import { GET_PHARMACY_JOBS_REQUEST, GET_PHARMACY_JOBS_SUCCESS, GET_PHARMACY_JOBS_FAILED } from './action-types';

export const getPharmacyJobsRequest = (payload) => ({
    type: GET_PHARMACY_JOBS_REQUEST,
    payload
});

export const getPharmacyJobsSuccess = (payload) => ({
    type: GET_PHARMACY_JOBS_SUCCESS,
    payload
});

export const getPharmacyJobsFailed = () => ({
    type: GET_PHARMACY_JOBS_FAILED
});
