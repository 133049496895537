import {
    ON_VALIDATE_GPHC_REQUEST,
    ON_VALIDATE_GPHC_SUCCESS,
    ON_VALIDATE_GPHC_FAILED
} from '../../actions/locum-check-in-page/action-types';

const initialState = {
    loading: false
};

const locumCheckInPage = (state = initialState, action) => {
    switch (action.type) {
        case ON_VALIDATE_GPHC_REQUEST:
            return {
                loading: true
            };
        case ON_VALIDATE_GPHC_SUCCESS:
        case ON_VALIDATE_GPHC_FAILED:
            return {
                loading: false
            };
        default:
            return state;
    }
};

export default locumCheckInPage;
