import React, { useEffect } from 'react';
import RoleItemsList from './RoleItemsList';

import { useDispatch, useSelector } from 'react-redux';
import { getBranchStaffRequest } from '../../../../../../store/actions';

import './style.scss';

function BranchStaff({
    match: {
        params: { id }
    },
    ...props
}) {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.branchStaffPage);

    useEffect(() => {
        dispatch(getBranchStaffRequest(id));
    }, [dispatch, id]);

    if (data === null) {
        return null;
    } else {
        const staffList = {};
        data.forEach((el, i, data) => {
            const { role } = el;
            if (!staffList[role]) {
                staffList[role] = [];
            }
            staffList[role].push(el);
        });

        const displayStaff = Object.keys(staffList).map((key) => {
            return <RoleItemsList key={key} list={staffList[key]} title={key} branchId={id} />;
        });

        return (
            <div className="branch-info-page branch-staff">
                <h3 className="branch-info-page__title">Staff</h3>
                <div className="branch-staff__staff-list">{displayStaff}</div>
            </div>
        );
    }
}

export default BranchStaff;
