import BranchesList from './BranchesList';
import { connect } from 'react-redux';
import {
    getBranchesListRequest,
    onBranchesFilterChange,
    getInvitesDataRequest,
    onInviteStaffRequest,
    clearBranchesfilters
} from '../../../../../store/actions';

export default connect(
    (state) => ({
        branchesPage: state.branchesListPage,
        inviteStaff: state.inviteStaffForm,
        createdBranchId: state.createBranch.createdBranchId
    }),
    {
        getBranchesListRequest,
        onBranchesFilterChange,
        getInvitesDataRequest,
        onInviteStaffRequest,
        clearBranchesfilters
    }
)(BranchesList);
