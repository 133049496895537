import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { getStaffShiftsForAdminSuccess, getStaffShiftsForAdminFailed } from '../../../actions';
import * as staffScheduleActionTypes from '../../../actions/admin-view/staff-schedule/action-types';
import api from '../../../../services/api';
import moment from 'moment';

function* onGetStaffShiftsRequest({ payload: { staffId, startDateRange, endDateRange } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${staffId}/schedule/`,
            method: 'post',
            payload: {
                startDateRange,
                endDateRange
            }
        });
        const formatedShifts = data.map((shift) => {
            const { startDate, endDate } = shift;
            return {
                ...shift,
                start: moment(startDate),
                end: moment(endDate)
            };
        });
        yield put(getStaffShiftsForAdminSuccess(formatedShifts));
    } catch (err) {
        yield put(getStaffShiftsForAdminFailed());
    }
}

function* watchGetStaffShiftsRequest() {
    yield takeEvery(staffScheduleActionTypes.GET_STAFF_SHIFTS_FOR_ADMIN_REQUEST, onGetStaffShiftsRequest);
}

const staffScheduleSaga = [fork(watchGetStaffShiftsRequest)];

export default staffScheduleSaga;
