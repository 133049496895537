import React from 'react';
import PropTypes from 'prop-types';
import UploadButton from '../UI/upload-file-button';
import ButtonModal from '../UI/buttons/button-modal';

import { formatBytes } from '../../services/helpers';

import { Image } from 'semantic-ui-react';

import './style.scss';

function UploadFileHandler({
    stateProp,
    inputName,
    buttonValue,
    onChangeHandler,
    onRemoveFile,
    uploadedFile,
    triggerInput,
    refFunc,
    isAvatar = false,
    avatarURL,
    acceptFormats = '.jpg, .jpeg, .png, .bmp',
    loading,
    ...props
}) {
    const uploadButton = (
        <UploadButton
            name={inputName}
            inputValue=""
            acceptFormats={acceptFormats}
            buttonValue={buttonValue}
            size="medium"
            inputRef={refFunc}
            onInputChange={onChangeHandler}
            triggerInputFile={triggerInput}
        />
    );

    const avatar = isAvatar ? <Image src={stateProp && !uploadedFile.name ? stateProp : avatarURL} avatar /> : null;

    let fileName;

    if (stateProp && !uploadedFile.name) {
        fileName = stateProp.split('/').pop();
    } else if (!stateProp && !uploadedFile.name && avatarURL) {
        fileName = avatarURL.split('/').pop();
    } else fileName = uploadedFile.name;

    const fileSize = uploadedFile.size ? (
        <span className="file-info__size">{formatBytes(uploadedFile.size)}</span>
    ) : null;

    const updatedState = (
        <div className="uploaded-file">
            <div className="file-info">
                {avatar}
                <p className={`file-info__name ${isAvatar ? 'small-width' : ''}`}>
                    {fileName} <br />
                    {fileSize}
                </p>
            </div>
            <ButtonModal
                value="Remove"
                colorType="red"
                onClick={(e) => {
                    e.preventDefault();
                    onRemoveFile(inputName);
                }}
                size="medium"
                width="medium"
                loading={loading}
            />
        </div>
    );

    return (
        <div className="upload-handler upload-inner">
            {stateProp || (!stateProp && avatarURL) ? updatedState : uploadButton}
        </div>
    );
}

UploadFileHandler.propTypes = {
    buttonValue: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    stateProp: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
    onRemoveFile: PropTypes.func.isRequired,
    triggerInput: PropTypes.func.isRequired,
    refFunc: PropTypes.func.isRequired,
    uploadedFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    acceptFormats: PropTypes.string,
    isAvatar: PropTypes.bool,
    avatarURL: PropTypes.string,
    loading: PropTypes.bool
};

export default UploadFileHandler;
