import {
    GET_STAFF_LIST_SUCCESS,
    GET_STAFF_LIST_FAILED,
    ON_STAFF_FILTER_CHANGE,
    ON_STAFF_FILTER_FALSE,
    CLEAR_STAFF_FILTERS
} from '../../../actions/admin-view/staff-page/action-types';

const initialState = {
    filters: {
        name: '',
        role: '',
        phone: '',
        dob: '',
        branchName: '',
        status: ''
    },
    isFiltering: false,
    redirectPageTo: null,
    loading: false,
    data: {},
    totalData: 0,
    itemsPerPage: 9
};

const staffListPage = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_STAFF_LIST_SUCCESS:
            return {
                ...state,
                data: payload.data,
                totalData: payload.totalData,
                redirectPageTo: null
            };
        case ON_STAFF_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.name]: payload.value
                },
                isFiltering: true
            };
        case ON_STAFF_FILTER_FALSE:
            return {
                ...state,
                isFiltering: false,
                redirectPageTo: 1
            };
        case GET_STAFF_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        case CLEAR_STAFF_FILTERS:
            return {
                ...state,
                filters: {
                    name: '',
                    role: '',
                    phone: '',
                    dob: '',
                    branchName: ''
                },
                isFiltering: false
            };
        default:
            return state;
    }
};

export default staffListPage;
