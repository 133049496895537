import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonModal from '../../UI/buttons/button-modal';
import moment from 'moment';
import { Form } from 'semantic-ui-react';
import './style.scss';
import Datepicker from '../../UI/datepicker';
import CustomCheckbox from '../../UI/checkbox';

function PublishTemplateShiftForm({ planningPeriod, onSubmitClick, submitLoading }) {
    const getEndDateByPlanningPeriod = (startDate) =>
        new Date(`${moment(startDate).add(planningPeriod, 'weeks').startOf('day').format('YYYY-MM-DD')} 00:00:00`);

    const [formData, setFormData] = useState(() => {
        return {
            startDate: new Date(`${moment(new Date()).startOf('day').format('YYYY-MM-DD')} 00:00:00`),
            endDate: getEndDateByPlanningPeriod(new Date()),
            indefinite: true
        };
    });

    const onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        const newForm = {
            ...formData,
            [name]: value
        };

        if (name === 'startDate' && formData.indefinite)
            newForm.endDate = getEndDateByPlanningPeriod(newForm.startDate || new Date());

        setFormData(newForm);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        onSubmitClick({
            ...formData,
            startDate: moment(formData.startDate).format('YYYY-MM-DD'),
            endDate: moment(formData.endDate).format('YYYY-MM-DD')
        });
    };

    return (
        <Form className="publish-template-shift-form" onSubmit={onSubmit}>
            <div className="content-row">
                <div className="content-row__item">
                    <div className="inner">
                        <Datepicker
                            name="startDate"
                            value={formData.startDate}
                            label="Starting Date"
                            onChange={onChangeHandler}
                            format="DD/MM/YY"
                            fluid={false}
                        />
                        <Datepicker
                            name="endDate"
                            value={formData.endDate}
                            label={
                                <CustomCheckbox
                                    name="indefinite"
                                    label={'Indefinite'}
                                    checked={formData.indefinite}
                                    size="small"
                                    className="end-date-checkbox"
                                    onChange={() =>
                                        setFormData({
                                            ...formData,
                                            indefinite: !formData.indefinite,
                                            endDate: getEndDateByPlanningPeriod(formData.startDate)
                                        })
                                    }
                                />
                            }
                            onChange={onChangeHandler}
                            format="DD/MM/YY"
                            disabled={formData.indefinite}
                            minDate={formData.startDate}
                            fluid={false}
                        />
                    </div>
                </div>
            </div>
            <div className={`buttons-row`}>
                <ButtonModal
                    value={'Publish'}
                    colorType="blue"
                    disabled={!formData.startDate || submitLoading}
                    type="submit"
                />
            </div>
        </Form>
    );
}

PublishTemplateShiftForm.propTypes = {
    onSubmitClick: PropTypes.func.isRequired,
    planningPeriod: PropTypes.number,
    submitLoading: PropTypes.bool
};

export default PublishTemplateShiftForm;
