import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import ShiftItemCard from '../UI/shift-item-card';

import './style.scss';

function ScheduleDayPreviewPopover({ shifts, time, startDate, color = 'green', openInfo, isStaffShift = false }) {
    const shiftsList = shifts.map(({ id, branchTitle, endTime, startTime, region, branchId }) => {
        return (
            <ShiftItemCard
                key={id}
                title={branchTitle}
                startTime={startTime}
                endTime={endTime}
                region={region}
                linked={!!openInfo}
                onClick={() => openInfo(isStaffShift ? branchId : id)}
            />
        );
    });

    return (
        <div className="day-preview-popup">
            <div className={classnames('day-preview-popup__header', { [color]: color })}>
                <div className="title-holder">
                    <p className="header-title">{moment(startDate).format('Do MMM, YYYY')}</p>
                    <small className="header-subtitle">{time}</small>
                </div>
            </div>
            {shiftsList}
        </div>
    );
}

ScheduleDayPreviewPopover.propTypes = {
    shifts: PropTypes.array,
    time: PropTypes.string,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string,
    openInfo: PropTypes.func,
    isStaffShift: PropTypes.bool
};

export default ScheduleDayPreviewPopover;
