import {
    GET_PHARMACY_INVOICES_REQUEST,
    GET_PHARMACY_INVOICES_SUCCESS,
    GET_PHARMACY_INVOICES_FAILED,
    GET_PHARMACY_ALL_BRANCHES_REQUEST,
    GET_PHARMACY_ALL_BRANCHES_SUCCESS,
    GET_PHARMACY_ALL_BRANCHES_FAILED,
    GET_PHARMACY_SUBSCRIPTION_REQUEST,
    GET_PHARMACY_SUBSCRIPTION_SUCCESS,
    GET_PHARMACY_SUBSCRIPTION_FAILED,
    GET_PHARMACY_BRANCH_DEACTIVATE_REQUEST,
    GET_PHARMACY_BRANCH_DEACTIVATE_SUCCESS,
    GET_PHARMACY_BRANCH_DEACTIVATE_FAILED,
    CLEAR_INVOICES_LIST,
    PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_REQUEST,
    PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_SUCCESS,
    PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_FAILED
} from './action-types';

export const getPharmacyInvoicesRequest = (payload) => ({
    type: GET_PHARMACY_INVOICES_REQUEST,
    payload
});
export const getPharmacyInvoicesSuccess = (payload) => ({
    type: GET_PHARMACY_INVOICES_SUCCESS,
    payload
});
export const getPharmacyInvoicesFailed = () => ({
    type: GET_PHARMACY_INVOICES_FAILED
});

export const getPharmacyAllBranchesRequest = (pharmacyId) => ({
    type: GET_PHARMACY_ALL_BRANCHES_REQUEST,
    pharmacyId
});
export const getPharmacyAllBranchesSuccess = (payload) => ({
    type: GET_PHARMACY_ALL_BRANCHES_SUCCESS,
    payload
});
export const getPharmacyAllBranchesFailed = () => ({
    type: GET_PHARMACY_ALL_BRANCHES_FAILED
});

export const getPharmacySubscriptionRequest = (pharmacyId) => ({
    type: GET_PHARMACY_SUBSCRIPTION_REQUEST,
    pharmacyId
});
export const getPharmacySubscriptionSuccess = (payload) => ({
    type: GET_PHARMACY_SUBSCRIPTION_SUCCESS,
    payload
});
export const getPharmacySubscriptionFailed = () => ({
    type: GET_PHARMACY_SUBSCRIPTION_FAILED
});

export const getPharmacyBranchDeactivateRequest = (payload) => ({
    type: GET_PHARMACY_BRANCH_DEACTIVATE_REQUEST,
    payload
});
export const getPharmacyBranchDeactivateSuccess = (payload) => ({
    type: GET_PHARMACY_BRANCH_DEACTIVATE_SUCCESS,
    payload
});
export const getPharmacyBranchDeactivateFailed = () => ({
    type: GET_PHARMACY_BRANCH_DEACTIVATE_FAILED
});

export const putPharmacyUpdateSubscriptionsRequest = (payload) => ({
    type: PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_REQUEST,
    payload
});
export const putPharmacyUpdateSubscriptionsSuccess = (payload) => ({
    type: PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_SUCCESS,
    payload
});
export const putPharmacyUpdateSubscriptionsFailed = () => ({
    type: PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_FAILED
});

export const clearInvoicesList = () => ({
    type: CLEAR_INVOICES_LIST
});
