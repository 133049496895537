import React, { useEffect } from 'react';

import AdminProfile from './profile';

import { Switch, Redirect, Route } from 'react-router-dom';
import { RouterConfig } from '../../../../routerConfig';

import Sidebar from '../../../sidebar';
import ContentContainer from '../../../../components/content-container';
import ProfileCard from '../../../../components/profile-card';

import { connect } from 'react-redux';
import { getPersonalInfoRequest, clearPersonalInfo } from '../../../../store/actions';

function AdminInfoPage({ getPersonalInfoRequest, clearPersonalInfo, personalInfo: { data, loading } }) {
    useEffect(() => {
        getPersonalInfoRequest();
        return () => {
            clearPersonalInfo();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sidebarLinks = [
        {
            text: 'Personal Information',
            to: RouterConfig.superAdminInfoProfile,
            disabled: false,
            hidden: false
        }
    ];

    if (!data) {
        return null;
    } else {
        const { name, surname, role, image } = data;
        return (
            <div className="content-view-page details-page">
                <div className="main-wrapper">
                    <div className="content-holder">
                        <Sidebar links={sidebarLinks} visible>
                            <ProfileCard name={name} surname={surname} role={role} avatar={image} />
                        </Sidebar>
                        <ContentContainer smallPaddings fluid={false}>
                            <Switch>
                                <Route
                                    path={RouterConfig.superAdminInfoProfile}
                                    render={() => <AdminProfile data={data} loading={loading} />}
                                />
                                <Redirect from={RouterConfig.superAdminInfo} to={RouterConfig.superAdminInfoProfile} />
                            </Switch>
                        </ContentContainer>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    personalInfo: state.userProfileInfo.personalInfo
});

const mapDispatchToProps = {
    getPersonalInfoRequest,
    clearPersonalInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminInfoPage);
