import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonModal from '../../UI/buttons/button-modal';
import Timepicker from '../../UI/timepicker';
import Datepicker from '../../UI/datepicker';
import CustomCheckbox from '../../UI/checkbox';
import SelectComponent from '../../UI/select/SelectComponent';
import AttachedStaffCard from '../../UI/attached-staff-card';
import CheckboxListContainer from '../../../containers/checkbox-list';

import {
    getDuration,
    getDurationFormat,
    parseTime,
    weekDays,
    getShiftDates,
    validateShiftSubmit
} from '../../../services/datesHelper';

import moment from 'moment';

import { Form, Icon } from 'semantic-ui-react';

import './style.scss';

const TIME_FORMAT = 'HH:mm';

class AddEditShiftScheduleForm extends Component {
    state = {
        startDate: this.props.currentDate ? new Date(this.props.currentDate) : null,
        endDate: null,
        withEndDate: false,
        weekDaysCheckboxes: [],
        selectedDays: [],
        timeStart: null,
        timeFinish: null,
        duration: '',
        showAttachStaff: false,
        attachedStaff: null,
        canEdit: true,
        isInitialStaffRecieved: false,
        savedAsDraft: false
    };

    componentDidMount() {
        this._isMounted = true;
        const { shiftEdit } = this.props;

        const weekDaysCheckboxes = weekDays.map((item) => {
            return {
                title: item,
                checked: false
            };
        });

        this.setState({ weekDaysCheckboxes }, () => {
            if (shiftEdit) {
                this.setInitialData();
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setInitialData = () => {
        const {
            shiftEdit: { startDate, endDate, startTime, endTime, staff, days },
            currentDate
        } = this.props;
        const { weekDaysCheckboxes } = this.state;

        const timeStart = startTime && moment(parseTime(startTime));
        const timeFinish = endTime && moment(parseTime(endTime));

        let selectedDays = [];

        const getInitialCheckboxes = weekDaysCheckboxes.map((item) => {
            return {
                title: item.title,
                checked: days
                    ? !!days.find((el) => {
                          if (el.dayOfWeek === item.title) {
                              selectedDays.push(item.title);
                              return true;
                          }
                          return false;
                      })
                    : false
            };
        });

        this.setState(
            {
                timeStart,
                timeFinish,
                duration: getDuration(timeStart, timeFinish),
                weekDaysCheckboxes: getInitialCheckboxes,
                selectedDays,
                isInitialStaffRecieved: staff ? true : false,
                attachedStaff: staff,
                startDate: new Date(currentDate) || new Date(startDate),
                withEndDate: !!endDate,
                endDate: endDate ? new Date(endDate) : null,
                canEdit: staff === null ? true : false
            },
            () => {
                if (this.props.shiftEdit && !staff) this.onShowStaffList(true);
            }
        );
    };

    onStatTimeChange = (time, timeStart, timeFinish, duration, type) => {
        time.set({ second: 0 });
        const { isInitialStaffRecieved } = this.state;
        let setDuration = timeFinish ? getDuration(time, timeFinish) : '';
        this.setState({ [timeStart]: time, [duration]: setDuration }, () => {
            if (type !== 'lunch' && !isInitialStaffRecieved) this.onShowStaffList();
        });
    };

    onFinishTimeChange = (time, timeFinish, timeStart, duration, type) => {
        time.set({ second: 0 });
        const { isInitialStaffRecieved } = this.state;
        let setDuration = timeStart ? getDuration(timeStart, time) : '';
        this.setState({ [timeFinish]: time, [duration]: setDuration }, () => {
            if (type !== 'lunch' && !isInitialStaffRecieved) this.onShowStaffList();
        });
    };

    onCheckboxChangeHandler = (e, { name }) => {
        const { weekDaysCheckboxes } = this.state;
        let selectedDays = [];

        weekDaysCheckboxes.forEach((el) => {
            if (name === el.title) {
                el.checked = !el.checked;
            }
            if (el.checked) selectedDays.push(el.title);
        });

        this.setState(
            (prevState) => ({ [name]: !prevState[name], selectedDays }),
            () => this.onShowStaffList()
        );
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { isInitialStaffRecieved } = this.state;
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () =>
                (name === 'startDate' || name === 'endDate') && !isInitialStaffRecieved ? this.onShowStaffList() : false
        );
    };

    onDropdownChange = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () => this.onAttachStaff(value)
        );
    };

    onShowStaffList = (isEditMode = false) => {
        const { shiftEdit, getStaff, currentDate } = this.props;
        const {
            startDate,
            endDate,
            timeStart,
            timeFinish,
            withEndDate,
            duration,
            isInitialStaffRecieved,
            selectedDays
        } = this.state;

        let isShown = false;

        const payload = {
            getStartDate: isEditMode ? currentDate : startDate,
            getEndDate: isEditMode ? shiftEdit.endDate : endDate,
            getTimeStart: isEditMode ? shiftEdit.startTime : timeStart,
            getTimeFinish: isEditMode ? shiftEdit.endTime : timeFinish,
            getDurationTime: isEditMode ? getDuration(shiftEdit.startTime, shiftEdit.endTime) : duration
        };

        if (!payload.getStartDate || (withEndDate && !payload.getEndDate) || payload.getDurationTime < 60) {
            isShown = false;
        } else isShown = true;

        const getStaffData = {
            startDate: moment(payload.getStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            startTime: moment(payload.getTimeStart, 'HH:mm').format('HH:mm'),
            endTime: moment(payload.getTimeFinish, 'HH:mm').format('HH:mm'),
            days: selectedDays
        };

        if (withEndDate && payload.getEndDate)
            getStaffData.endDate = moment(payload.getEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

        if (isInitialStaffRecieved) getStaffData.shiftId = shiftEdit.id;

        this.setState(
            {
                showAttachStaff: isShown,
                attachedStaff: null
            },
            () => (isShown ? getStaff(getStaffData) : false)
        );
    };

    onAttachStaff = (id) => {
        const { staffList } = this.props;
        let attachedItem;

        staffList.forEach((staff) => {
            if (staff.id === id) attachedItem = staff;
        });

        this.setState({
            attachedStaff: attachedItem,
            showAttachStaff: false
        });
    };

    onRemoveAttachedStaff = () => {
        this.setState({
            attachedStaff: null,
            canEdit: true,
            isInitialStaffRecieved: false
        });
        this.onShowStaffList();
    };

    onSubmitClickHandler = (e, submitMode, editMode) => {
        e.preventDefault();
        const { onSubmitClick, onSubmitDraftClick, onSubmitConfirm, shiftEdit } = this.props;
        const isEdit = shiftEdit ? true : false;
        let isDraft;

        if (!editMode) {
            isDraft = submitMode === 'draft' ? true : false;
        } else {
            isDraft = shiftEdit.isDraft;
        }

        const { startDate, endDate, timeStart, timeFinish, selectedDays, attachedStaff, withEndDate } = this.state;

        const data = {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            startTime: timeStart.format('HH:mm'),
            endTime: timeFinish.format('HH:mm'),
            staffId: attachedStaff ? attachedStaff.id : attachedStaff,
            days: selectedDays
        };

        if (withEndDate && endDate) {
            data.endDate = moment(endDate).format('YYYY-MM-DD');
        }

        if (isDraft) {
            data.isDraft = isDraft;
        }

        if (isEdit) {
            const currentShiftDataUpdate = Object.assign({}, data);
            currentShiftDataUpdate.isDraft = isDraft;
            currentShiftDataUpdate.isInitialDraft = shiftEdit.isDraft;
            currentShiftDataUpdate.submitMode = submitMode;
            onSubmitConfirm(data, currentShiftDataUpdate);
        } else if (isDraft && !isEdit) {
            onSubmitDraftClick(data, isEdit);
        } else if (!isDraft && !isEdit) {
            onSubmitClick(data, isEdit);
        }
    };

    onCancelClickHandler = (e) => {
        e.preventDefault();
        const { startDate, endDate } = this.state;
        const { onCancelClick } = this.props;
        const data = {
            startDate: moment(startDate).format('YYYY-MM-DD')
        };
        if (endDate) data.endDate = moment(endDate).format('YYYY-MM-DD');
        onCancelClick(data);
    };

    render() {
        const {
            startDate,
            endDate,
            weekDaysCheckboxes,
            timeStart,
            timeFinish,
            withEndDate,
            duration,
            selectedDays,
            showAttachStaff,
            attachedStaff,
            canEdit,
            savedAsDraft
        } = this.state;
        const { loading, removingLoading, shiftEdit, staffList, draftSaved, currentDate } = this.props;

        const displayShiftDuration = getDurationFormat(duration);

        const filteredStaffOptions = staffList
            ? staffList.map((option) => {
                  const { id, name, surname, image, role } = option;
                  return {
                      key: id,
                      value: id,
                      text: name,
                      content: (
                          <div className="option-inner">
                              <span className="option-inner__name">{`${name} ${surname}`}</span>
                              <span className="option-inner__role">{role.replace(/_/g, ' ')}</span>
                          </div>
                      ),
                      image: { avatar: !!image, src: image }
                  };
              })
            : [];

        const staffListDropdown = showAttachStaff ? (
            <div className="content-row__item">
                <div className="inner column">
                    <SelectComponent
                        name="currentStaff"
                        icon="caret down"
                        floating
                        search
                        onChange={this.onDropdownChange}
                        options={filteredStaffOptions}
                        label="Find manager"
                    />
                </div>
            </div>
        ) : null;

        const displayAttachedStaff = attachedStaff ? (
            <>
                <p className="title">Employee</p>
                <AttachedStaffCard
                    id={attachedStaff.id}
                    name={attachedStaff.name}
                    surname={attachedStaff.surname}
                    image={attachedStaff.image}
                    role={attachedStaff.role}
                    onDangerButtonClick={this.onRemoveAttachedStaff}
                />
            </>
        ) : null;

        const removeShiftButton = shiftEdit ? (
            <ButtonModal
                value="Remove shift"
                colorType="red"
                loading={removingLoading}
                onClick={this.onCancelClickHandler}
            />
        ) : null;

        const saveAsDraftButton = (
            <ButtonModal
                value={savedAsDraft || draftSaved ? 'Saved as Draft' : 'Save as Draft'}
                colorType="blue"
                onClick={(e) => this.onSubmitClickHandler(e, 'draft', !!shiftEdit)}
                disabled={
                    validateShiftSubmit({
                        startDate,
                        endDate,
                        withEndDate,
                        timeStart,
                        timeFinish,
                        duration,
                        selectedDays
                    }) || draftSaved
                }
            />
        );

        const editHelperText =
            canEdit || !shiftEdit ? null : (
                <div className="helper-conainer">
                    <p className="edit-helper-text">
                        <Icon name="warning sign" /> Please, remove your staff before editing
                    </p>
                </div>
            );

        const daysCheckboxes = weekDaysCheckboxes.map((item) => {
            return {
                id: item.title,
                label: item.title.substring(0, 3),
                name: item.title,
                checked: item.checked,
                onChange: this.onCheckboxChangeHandler
            };
        });

        return (
            <Form className="add-shift-schedule-form" onSubmit={this.onSubmitClickHandler} loading={loading}>
                <div className="content-row">
                    <div className="content-row__item">
                        <div className="inner datepickers">
                            <Datepicker
                                name="startDate"
                                label="Starting Date"
                                value={startDate}
                                onChange={this.onChangeHandler}
                                format="DD/MM/YY"
                                minDate={getShiftDates(currentDate, 'start')}
                                disabled={loading || !canEdit}
                                fluid={false}
                            />
                            <Datepicker
                                name="endDate"
                                label={
                                    <CustomCheckbox
                                        name="withEndDate"
                                        label={shiftEdit ? 'Affective To' : 'Finish Date'}
                                        checked={withEndDate}
                                        size="small"
                                        className="end-date-checkbox"
                                        onChange={this.onCheckboxChangeHandler}
                                        disabled={loading || !canEdit}
                                    />
                                }
                                value={endDate}
                                onChange={this.onChangeHandler}
                                format="DD/MM/YY"
                                fluid={false}
                                disablePast
                                minDate={getShiftDates(startDate, 'end', !!shiftEdit)}
                                disabled={loading || !withEndDate || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <CheckboxListContainer
                            items={daysCheckboxes}
                            layout="row"
                            boxesSize="small"
                            disabled={(withEndDate && !startDate && !endDate) || !canEdit}
                        />
                    </div>
                    <div className="content-row__item">
                        <p className="title">Operating Hours</p>
                        <div className="inner timepickers">
                            <Timepicker
                                onChange={(time) => this.onStatTimeChange(time, 'timeStart', timeFinish, 'duration')}
                                format={TIME_FORMAT}
                                minuteStep={15}
                                value={timeStart}
                                disabled={loading || !canEdit}
                                size="large"
                            />
                            <p className="duration">{displayShiftDuration}</p>
                            <Timepicker
                                onChange={(time) => this.onFinishTimeChange(time, 'timeFinish', timeStart, 'duration')}
                                format={TIME_FORMAT}
                                minuteStep={15}
                                value={timeFinish}
                                disabled={loading || !canEdit}
                                size="large"
                            />
                        </div>
                    </div>
                    <div className={`content-row__item ${attachedStaff ? '' : 'hidden'}`}>
                        <div className="inner column">{displayAttachedStaff}</div>
                    </div>
                    {staffListDropdown}
                    <div className="helper-holder">{editHelperText}</div>
                </div>
                <div className="buttons-row">
                    {removeShiftButton}
                    {saveAsDraftButton}
                    <ButtonModal
                        value={'Save & Publish'}
                        colorType="blue"
                        onClick={(e) => this.onSubmitClickHandler(e, 'shift', !!shiftEdit)}
                        disabled={validateShiftSubmit({
                            startDate,
                            endDate,
                            withEndDate,
                            timeStart,
                            timeFinish,
                            duration,
                            selectedDays
                        })}
                    />
                </div>
            </Form>
        );
    }
}

AddEditShiftScheduleForm.propTypes = {
    staffList: PropTypes.array,
    shiftEdit: PropTypes.object,
    loading: PropTypes.bool,
    getStaff: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSubmitClick: PropTypes.func.isRequired,
    onSubmitDraftClick: PropTypes.func.isRequired,
    draftSaved: PropTypes.bool,
    currentDate: PropTypes.string,
    onSubmitConfirm: PropTypes.func
};

export default AddEditShiftScheduleForm;
