import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import { getPharmacyStaffSuccess, getPharmacyStaffFailed } from '../../../actions';
import { selectItemsPerPage } from './selectors';
import * as pharmacyStaffActionTypes from '../../../actions/super-admin-view/pharmacy-staff-page/action-types';
import api from '../../../../services/api';

function* onPharmacyStaffListRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page, id } = payload;
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${id}/staff/list`,
            method: 'post',
            payload: {
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getPharmacyStaffSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getPharmacyStaffFailed());
    }
}

function* watchPharmacyStaffListRequest() {
    yield takeEvery(pharmacyStaffActionTypes.GET_PHARMACY_STAFF_REQUEST, onPharmacyStaffListRequest);
}

const adminPharmacyStaffSaga = [fork(watchPharmacyStaffListRequest)];

export default adminPharmacyStaffSaga;
