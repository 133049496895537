import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { salaryError } from '../../../services/validate';
import './style.scss';
import AnswerTabs from '../../UI/answer-tabs';
import TextInput from '../../UI/inputs/text-input';
import GroupCheckboxButtons from '../../UI/group-checkbox-buttons';
import { Dropdown } from 'semantic-ui-react';

const options = [
    { key: 1, text: '1 mile', value: 1 },
    { key: 2, text: '5 miles', value: 5 },
    { key: 3, text: '10 miles', value: 10 },
    { key: 4, text: '20 miles', value: 20 },
    { key: 5, text: '50 miles', value: 50 },
    { key: 6, text: '100 miles', value: 100 },
    { key: 7, text: '150 miles', value: 150 },
    { key: 8, text: '200 miles', value: 200 },
    { key: 9, text: 'Nationwide', value: 'nationwide' }
];

class LocumSettingsForm extends Component {
    state = {
        preferred_travel_distance: 1,
        send_daily_emails: true,
        send_invite_emails: true,
        hourly_rate_from: '',
        preferred_working_days: [
            {
                key: 'key',
                value: true,
                label: 'Key'
            }
        ],
        validate: {
            hourly_rate_from: false
        },
        errors: {
            hourly_rate_from: null
        }
    };

    componentDidMount() {
        this._isMounted = true;
        const { filledForm } = this.props;
        const {
            preferred_travel_distance,
            send_daily_emails,
            send_invite_emails,
            hourly_rate_from,
            preferred_working_days
        } = filledForm;
        this.setState({
            preferred_travel_distance: preferred_travel_distance || 'nationwide',
            send_daily_emails,
            send_invite_emails,
            hourly_rate_from,
            preferred_working_days
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        if (name === 'hourly_rate_from') error = salaryError(value);

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                () => {
                    resolve(true);
                }
            );
        });
    };
    onSliderChange = ({ name, value }) => {
        return this.setState(
            {
                [name]: value || null
            },
            () => {
                if (name === 'preferred_travel_distance') {
                    this.onSliderComplete();
                }
            }
        );
    };
    onSliderComplete = () => {
        const { onSettingsChange } = this.props;
        const { preferred_travel_distance } = this.state;
        return onSettingsChange({
            preferred_travel_distance: preferred_travel_distance === 'nationwide' ? null : preferred_travel_distance
        });
    };
    onChangeHandler = async (data) => {
        const { onSettingsChange } = this.props;
        const { name, value } = data.target ? data.target : data;
        return this.setState(
            {
                [name]: value
            },
            () => {
                this.validate(name).then(() => {
                    const { errors } = this.state;
                    if (!errors.hourly_rate_from && this.state.hourly_rate_from)
                        onSettingsChange({
                            [name]: value
                        });
                });
            }
        );
    };

    onChangeWorkingDay = ({ key, value }) => {
        let days = this.state.preferred_working_days;
        const { onSettingsChange } = this.props;
        const idx = days.findIndex((day) => day.key === key);
        days[idx] = {
            ...days[idx],
            value
        };
        return this.setState(
            {
                preferred_working_days: [...days]
            },
            () => {
                onSettingsChange({
                    preferred_working_days: [...days]
                });
            }
        );
    };

    render() {
        const {
            preferred_travel_distance,
            send_invite_emails,
            hourly_rate_from,
            send_daily_emails,
            preferred_working_days,
            errors
        } = this.state;
        const { loading } = this.props;

        return (
            <Form className={'personal-info-form'} autoComplete="nope" loading={loading}>
                <div className="personal-info-form__row">
                    <div className="col">
                        <h3 className="col__title">Settings</h3>
                        <div className="input-row">
                            <div className="input-group fluid">
                                <label>Preferred travel distance (radius)</label>
                                <Dropdown
                                    onChange={(_, { value }) => {
                                        this.onSliderChange({
                                            name: 'preferred_travel_distance',
                                            value
                                        });
                                    }}
                                    clearable
                                    options={options}
                                    selection
                                    name="preferred_travel_distance"
                                    value={preferred_travel_distance}
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-group">
                                <AnswerTabs
                                    label="Daily job summary emails"
                                    name="send_daily_emails"
                                    initialState={send_daily_emails}
                                    onClick={(data) => this.onChangeHandler(data)}
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-group">
                                <label>Preferred working days</label>
                                <GroupCheckboxButtons
                                    data={preferred_working_days}
                                    onClick={(data) => this.onChangeWorkingDay(data)}
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-group hourly-rate">
                                <label>Minimum hourly rate</label>
                                <TextInput
                                    name="hourly_rate_from"
                                    type="text"
                                    value={String(hourly_rate_from)}
                                    fluid={false}
                                    icon={'pound'}
                                    error={!!errors.hourly_rate_from}
                                    helperText={errors.hourly_rate_from}
                                    onChange={this.onChangeHandler}
                                    disabled={loading}
                                    iconPosition="left"
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-group">
                                <AnswerTabs
                                    label="Send me emails when i'm invited to a job"
                                    name="send_invite_emails"
                                    initialState={send_invite_emails}
                                    onClick={(data) => this.onChangeHandler(data)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

LocumSettingsForm.propTypes = {
    loading: PropTypes.bool
};

export default LocumSettingsForm;
