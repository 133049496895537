import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../UI/inputs/text-input';
import SelectComponent from '../../UI/select';
import ButtonComponent from '../../UI/buttons/button';

import { dropdownError, digitsNumberError, phoneError, emailError } from '../../../services/validate';

import './style.scss';

class InviteStaffForm extends Component {
    state = {
        GPHCnumber: '',
        email: '',
        phone: '',
        region: '',
        role: '',
        branch: '',
        loading: false,
        errors: {
            GPHCnumber: null,
            email: null,
            phone: null,
            region: null,
            role: null,
            branch: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeHandler = ({ target: { name, value } }) => {
        let inputValue = value;

        if (name === 'firstName' || name === 'lastName') {
            inputValue = value.replace(/[0-9]/g, '');
        }

        return this.setState(
            {
                [name]: /^\s/.test(inputValue) ? '' : inputValue
            },
            () => this.validate(name)
        );
    };

    onDropdownChange = (e, { name, value }) => {
        const { branches, roles, regions } = this.props;
        let inputValue = value;

        if (name === 'branch') {
            branches.forEach((el, i) => {
                if (value === el.title) {
                    inputValue = el.id;
                }
                return inputValue;
            });
        } else if (name === 'role') {
            roles.forEach((el, i) => {
                if (value === el.role) {
                    inputValue = el.id;
                }
                return inputValue;
            });
        } else if (name === 'region') {
            regions.forEach((el, i) => {
                if (value === el.title) {
                    inputValue = el.id;
                }
                return inputValue;
            });
        }

        return this.setState(
            {
                [name]: inputValue
            },
            () => this.validate(name)
        );
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'GPHCnumber':
                error = digitsNumberError(value);
                break;
            case 'region':
            case 'role':
            case 'branch':
                error = dropdownError(value);
                break;
            case 'email':
                error = emailError(value);
                break;
            case 'phone':
                error = phoneError(value);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('GPHCnumber'),
            this.validate('email'),
            this.validate('phone'),
            this.validate('region'),
            this.validate('role'),
            this.validate('branch')
        ]);

        const { GPHCnumber, email, phone, region, role, branch, errors } = this.state;
        const { onSuccess } = this.props;

        if (Object.values(errors).some((error) => !!error)) return;

        const inviteStaffData = {
            gphcNumber: GPHCnumber,
            email: email,
            phone: phone,
            branchId: branch,
            regionId: region,
            roleId: role
        };

        onSuccess(inviteStaffData);
    };

    render() {
        const { GPHCnumber, email, phone, errors } = this.state;
        const { onCancelInvite, roles, regions, branches, loading } = this.props;

        // convert into correct array of objects
        const formatedRolesOptions = roles.map((option) => {
            return {
                key: option.id,
                value: option.role,
                text: option.label
            };
        });

        const formatedRegionsOptions = regions.map((option) => {
            return {
                key: option.id,
                value: option.title,
                text: option.title
            };
        });

        const formatedBranchesOptions = branches.map((option) => {
            return {
                key: option.id,
                value: option.title,
                text: option.title
            };
        });

        return (
            <div className="invite-staff-form">
                <TextInput
                    name="GPHCnumber"
                    label="GPHC Number"
                    type="text"
                    value={GPHCnumber}
                    error={!!errors.GPHCnumber}
                    helperText={errors.GPHCnumber}
                    onChange={this.onChangeHandler}
                />
                <TextInput
                    name="email"
                    label="Email"
                    type="text"
                    value={email}
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={this.onChangeHandler}
                />
                <TextInput
                    name="phone"
                    label="Phone"
                    type="tel"
                    value={phone}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    onChange={this.onChangeHandler}
                />
                <SelectComponent
                    name="branch"
                    label="Branch"
                    floating
                    options={formatedBranchesOptions}
                    error={!!errors.branch}
                    helperText={errors.branch}
                    onChange={this.onDropdownChange}
                />
                <div className="input-row">
                    <SelectComponent
                        name="role"
                        label="Role"
                        fluid={false}
                        floating
                        options={formatedRolesOptions}
                        error={!!errors.role}
                        helperText={errors.role}
                        onChange={this.onDropdownChange}
                    />
                    <SelectComponent
                        name="region"
                        label="assign to region"
                        fluid={false}
                        floating
                        options={formatedRegionsOptions}
                        error={!!errors.region}
                        helperText={errors.region}
                        onChange={this.onDropdownChange}
                    />
                </div>
                <div className="buttons-row">
                    <ButtonComponent
                        value="Send invite"
                        onClick={this.onSubmit}
                        loading={loading}
                        size="medium"
                        disabled={Object.values(errors).some((error) => !!error)}
                    />
                    <ButtonComponent
                        value="Cancel"
                        type="transparent"
                        size="small"
                        width="small"
                        onClick={onCancelInvite}
                        disabled={loading}
                    />
                </div>
            </div>
        );
    }
}

InviteStaffForm.propTypes = {
    onSuccess: PropTypes.func,
    onCancelInvite: PropTypes.func,
    roles: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    branches: PropTypes.array.isRequired
};

export default InviteStaffForm;
