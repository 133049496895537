import { yupResolver } from '@hookform/resolvers/yup';
import ButtonComponent from 'components/UI/buttons/button';
import Datepicker from 'components/UI/datepicker';
import TextInput from 'components/UI/inputs/text-input';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { contractPages, formatContractData } from 'services/contract';
import * as validationMessages from 'services/validationMessages';
import { duplicateContractRequest, getContractMinDateRequest } from 'store/actions';
import * as yup from 'yup';
import { useContractStaffId } from './../../../../../../services/hooks';

import './style.scss';

const DuplicateModal = ({ onClose, contractId }) => {
    const dispatch = useDispatch();
    const staffId = useContractStaffId();
    const { loading, minDate } = useSelector((state) => state.staffContractPage);

    const validationSchema = yup.object().shape({
        title: yup.string().required(validationMessages.requiredMessage),
        startDate: yup.date().required(validationMessages.requiredMessage).typeError(validationMessages.date),
        endDate: yup
            .date()
            .required(validationMessages.requiredMessage)
            .typeError(validationMessages.date)
            .test({
                name: 'min Date',
                exclusive: false,
                params: {},
                message: 'End date must be grater then start date at list for 1 day',
                test: function (value) {
                    return moment(value).isAfter(this.parent.startDate);
                }
            })
    });
    const {
        control,
        handleSubmit,
        getValues,
        setError,
        formState: { errors, isDirty, isValid }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        dispatch(getContractMinDateRequest({ staffId }));
    }, [dispatch, staffId]);

    const onSubmit = (data) => {
        const callback = (contract) => {
            if (contract.error) {
                return (
                    contract?.message &&
                    contract?.message.includes('title') &&
                    setError('title', { message: contract?.message }, { shouldFocus: true })
                );
            }
            const tab = moment(contract.startDate).isSameOrBefore()
                ? contractPages.currentContract
                : contractPages.upcomingContract;
            onClose(tab);
        };

        dispatch(
            duplicateContractRequest({
                staffId,
                callback,
                formData: formatContractData(data),
                contractId
            })
        );
    };

    return (
        <div className="duplicate-modal">
            <Form loading={loading} className="duplicate-modal__form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            {...field}
                            label="Title*"
                            className="duplicate-modal__input"
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message || ''}
                        />
                    )}
                />
                <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                        <Datepicker
                            {...field}
                            label="Start date*"
                            className="duplicate-modal__input"
                            onChange={(e, data) => field.onChange(data.value)}
                            format="DD/MM/YY"
                            minDate={minDate}
                            maxDate={getValues().endDate}
                            disabled={false}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message || ''}
                        />
                    )}
                />
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                        <Datepicker
                            {...field}
                            label="End Date*"
                            className="duplicate-modal__input"
                            onChange={(e, data) => field.onChange(data.value)}
                            format="DD/MM/YY"
                            minDate={moment(getValues().startDate).add(1, 'day').toDate()}
                            disabled={false}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message || ''}
                        />
                    )}
                />
                <div className="duplicate-modal__control">
                    <ButtonComponent onClick={onClose} value="Cancel" type="cancel" role="button" />
                    <ButtonComponent
                        type="confirm"
                        value="Duplicate"
                        role="submit"
                        disabled={!isDirty || !isValid || loading}
                    />
                </div>
            </Form>
        </div>
    );
};

DuplicateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    contractId: PropTypes.number
};

export default DuplicateModal;
