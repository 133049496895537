import {
    POST_JOB_RATE_REQUEST,
    POST_JOB_RATE_SUCCESS,
    POST_JOB_RATE_FAILED,
    EDIT_JOB_RATE_REQUEST,
    EDIT_JOB_RATE_SUCCESS,
    EDIT_JOB_RATE_FAILED
} from './action-types';

export const onJobLocumRateRequest = (payload) => ({
    type: POST_JOB_RATE_REQUEST,
    payload
});

export const onJobLocumRateSuccess = (payload) => ({
    type: POST_JOB_RATE_SUCCESS,
    payload
});

export const onJobLocumRateFailed = (payload) => ({
    type: POST_JOB_RATE_FAILED,
    payload
});

export const onEditJobLocumRateRequest = (payload) => ({
    type: EDIT_JOB_RATE_REQUEST,
    payload
});

export const onEditJobLocumRateSuccess = (payload) => ({
    type: EDIT_JOB_RATE_SUCCESS,
    payload
});

export const onEditJobLocumRateFailed = (payload) => ({
    type: EDIT_JOB_RATE_FAILED,
    payload
});
