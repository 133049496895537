import {
    GET_JOB_OFFERS_SUCCESS,
    GET_JOB_OFFERS_REQUEST,
    GET_JOB_OFFERS_FAILED,
    ON_JOB_OFFERS_FILTER_CHANGE,
    ON_JOB_OFFERS_FILTER_FALSE,
    CLEAR_JOB_OFFERS_FILTERS
} from '../../../actions/locum-view/job-offers/action-types';

const initialState = {
    filters: {
        id: '',
        startDate: null,
        branchTitle: '',
        hourlyRate: '',
        locum: '',
        status: '',
        applicationsCount: ''
    },
    isFiltering: false,
    redirectPageTo: null,
    loading: false,
    data: null,
    totalData: 0,
    itemsPerPage: 10
};

const locumJobOffersPage = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_JOB_OFFERS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_JOB_OFFERS_SUCCESS:
            return {
                ...state,
                data: payload.data,
                totalData: payload.totalData,
                redirectPageTo: null,
                loading: false
            };
        case GET_JOB_OFFERS_FAILED:
            return {
                ...state,
                loading: false
            };
        case ON_JOB_OFFERS_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.name]: payload.value
                },
                isFiltering: true
            };
        case ON_JOB_OFFERS_FILTER_FALSE:
            return {
                ...state,
                isFiltering: false,
                redirectPageTo: 1
            };
        case CLEAR_JOB_OFFERS_FILTERS:
            return {
                ...state,
                filters: {
                    id: '',
                    startDate: null,
                    branchTitle: '',
                    hourlyRate: '',
                    locum: '',
                    status: '',
                    applicationsCount: ''
                },
                isFiltering: false
            };
        default:
            return state;
    }
};

export default locumJobOffersPage;
