import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import TableInput from '../UI/inputs/table-input';
import TablePagination from './TablePagination';
import StripeCustomPagination from '../stripe-custom-pagination';

import { calculatePages } from '../../services/helpers';
import './style.scss';

const TableComponent = ({
    cols = [],
    data = [],
    totalItems,
    totalItemsTitle = true,
    itemsPerPage = 9,
    page,
    itemContextMenu,
    history,
    linkTo = history.location.pathname,
    openInfo,
    getItem = false,
    preserveQueryParams = false,
    accessToCellContent,
    stripe = false,
    onPaginate,
    prevDisabled,
    format = 'DD/MM/YYYY',
    nextDisabled,
    stripePagDisabled
}) => {
    useEffect(() => {
        // if(!page) return;
        // const totalPages = calculatePages(totalItems, itemsPerPage);
        // if( page > totalPages && totalPages !== 0) {
        //   history.replace(`${history.location.pathname}?page=${totalPages}`);
        // }
        // else if( totalPages === 0) {
        //   history.replace(`${history.location.pathname}?page=1`);
        // }
    }, [page, totalItems, itemsPerPage, history]);

    const pagination = stripe ? (
        <StripeCustomPagination
            onPaginate={onPaginate}
            prevDisabled={prevDisabled}
            nextDisabled={nextDisabled}
            disabled={stripePagDisabled}
        />
    ) : (
        <TablePagination
            page={page}
            totalPages={calculatePages(totalItems, itemsPerPage)}
            linkTo={linkTo}
            preserveQueryParams={preserveQueryParams}
        />
    );

    const handleGetInfo = (item) => {
        if (getItem) {
            return openInfo(item);
        } else {
            switch (typeof openInfo) {
                case 'string':
                    return history.push(`${openInfo}/${item.id}`);
                case 'function':
                    return openInfo(item.id);
                default:
                    return false;
            }
        }
    };

    return (
        <Table>
            <Table.Header className="table__head">
                <Table.Row>
                    {cols.map(({ render, linkOptions, ...props }) => (
                        <Table.HeaderCell className="table__cell" key={props.name}>
                            <TableInput format={format} {...props} />
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {data.map((item, index) => (
                    <Table.Row
                        key={`${item.id}-${index}`}
                        className={`table__row${!linkTo ? ' table__row--no-link' : ''}`}
                    >
                        {cols.map(({ linkOptions, className = '', ...col }, index) => (
                            <Table.Cell
                                className={classNames('table__cell table__cell--body', col.name, className)}
                                key={col.name}
                                onClick={() => !col?.isAction && handleGetInfo(item)}
                            >
                                <div
                                    className={
                                        itemContextMenu && index === cols.length - 1 ? 'table__cell__menu-wrp' : ''
                                    }
                                >
                                    {linkOptions ? (
                                        <NavLink
                                            to={`${linkOptions.to}/${item[linkOptions.prop]}`}
                                            className="table__cell__content--link"
                                            onClick={(evt) => evt.stopPropagation()}
                                        >
                                            {col.render ? col.render(item) : item[col.name]}
                                        </NavLink>
                                    ) : (
                                        <span
                                            className={`table__cell__content ${
                                                index === 0 ? accessToCellContent : null
                                            }`}
                                        >
                                            {col.render ? col.render(item) : item[col.name]}
                                        </span>
                                    )}
                                </div>
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>

            <Table.Footer className="table__footer">
                <Table.Row>
                    <Table.HeaderCell colSpan="2">
                        <h3 className={`table__cell__footer-header ${totalItemsTitle ? '' : 'hidden'}`}>
                            {itemsPerPage * (page - 1) + data.length} from {totalItems}
                        </h3>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={cols.length - 2}>{pagination}</Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

TableComponent.propTypes = {
    cols: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    totalItems: PropTypes.number,
    totalItemsTitle: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    page: PropTypes.number.isRequired,
    linkTo: PropTypes.string,
    openInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    getItem: PropTypes.bool,
    accessToCellContent: PropTypes.string,
    itemContextMenu: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            handler: PropTypes.func.isRequired,
            color: PropTypes.string
        })
    ),
    stripe: PropTypes.bool,
    onPaginate: PropTypes.func,
    prevDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    stripePagDisabled: PropTypes.bool
};

export default withRouter(TableComponent);
