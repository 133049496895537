import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as AddJobIcon } from '../../../../assets/icons/add-job.svg';
import { ReactComponent as AddShiftIcon } from '../../../../assets/icons/add-shift.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

import './style.scss';

function ScheduleCalendarButtons({ onPostJobClick, onAddShiftClick }) {
    return (
        <div className={classnames('schedule-calendar-buttons')}>
            <div className="buttons-group">
                <button onClick={onPostJobClick} className="calendar-buttons__job">
                    <AddJobIcon className="add-job" />
                    Add job
                </button>
                <div className="delimiter" />
                <button onClick={onAddShiftClick} className="calendar-buttons__shift">
                    <AddShiftIcon className="add-shift" />
                    Add Shift
                </button>
            </div>
            <div className="empty-job">
                <PlusIcon />
            </div>
        </div>
    );
}

ScheduleCalendarButtons.propTypes = {
    onPostJobClick: PropTypes.func.isRequired,
    onAddShiftClick: PropTypes.func.isRequired
};

export default ScheduleCalendarButtons;
