import {
    GET_BRANCH_TEMPLATE_REQUEST,
    GET_BRANCH_TEMPLATE_SUCCESS,
    GET_BRANCH_TEMPLATE_FAILED,
    POST_TEMPLATE_FAILED,
    POST_TEMPLATE_REQUEST,
    POST_TEMPLATE_SUCCESS,
    EDIT_TEMPLATE_FAILED,
    EDIT_TEMPLATE_REQUEST,
    EDIT_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILED,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    SCHEDULE_ACTION_REQUEST,
    SCHEDULE_ACTION_SUCCESS,
    SCHEDULE_ACTION_FAILED,
    TEMPLATE_PUBLISH_REQUEST,
    TEMPLATE_PUBLISH_SUCCESS,
    TEMPLATE_PUBLISH_FAILED
} from './action-types';

export const getBranchTemplateRequest = (id) => ({
    type: GET_BRANCH_TEMPLATE_REQUEST,
    id
});

export const getBranchTemplateSuccess = (payload) => ({
    type: GET_BRANCH_TEMPLATE_SUCCESS,
    payload
});

export const getBranchTemplateFailed = () => ({
    type: GET_BRANCH_TEMPLATE_FAILED
});

export const postBranchTemplateRequest = (payload) => ({
    type: POST_TEMPLATE_REQUEST,
    payload
});

export const postBranchTemplateSuccess = (payload) => ({
    type: POST_TEMPLATE_SUCCESS,
    payload
});

export const postBranchTemplateFailed = () => ({
    type: POST_TEMPLATE_FAILED
});

export const editBranchTemplateRequest = (payload) => ({
    type: EDIT_TEMPLATE_REQUEST,
    payload
});

export const editBranchTemplateSuccess = (payload) => ({
    type: EDIT_TEMPLATE_SUCCESS,
    payload
});

export const editBranchTemplateFailed = () => ({
    type: EDIT_TEMPLATE_FAILED
});

export const deleteBranchTemplateRequest = (payload) => ({
    type: DELETE_TEMPLATE_REQUEST,
    payload
});

export const deleteBranchTemplateSuccess = () => ({
    type: DELETE_TEMPLATE_SUCCESS
});

export const deleteBranchTemplateFailed = () => ({
    type: DELETE_TEMPLATE_FAILED
});

export const scheduleActionRequest = (payload) => ({
    type: SCHEDULE_ACTION_REQUEST,
    payload
});

export const scheduleActionSuccess = (payload) => ({
    payload,
    type: SCHEDULE_ACTION_SUCCESS
});

export const scheduleActionFailed = () => ({
    type: SCHEDULE_ACTION_FAILED
});

export const templatePublishRequest = (payload) => ({
    type: TEMPLATE_PUBLISH_REQUEST,
    payload
});

export const templatePublishSuccess = (payload) => ({
    payload,
    type: TEMPLATE_PUBLISH_SUCCESS
});

export const templatePublishFailed = () => ({
    type: TEMPLATE_PUBLISH_FAILED
});
