export const GET_ADMIN_TRANSACTIONS_LIST_REQUEST = 'GET_ADMIN_TRANSACTIONS_LIST_REQUEST';
export const GET_ADMIN_TRANSACTIONS_LIST_SUCCESS = 'GET_ADMIN_TRANSACTIONS_LIST_SUCCESS';
export const GET_ADMIN_TRANSACTIONS_LIST_FAILED = 'GET_ADMIN_TRANSACTIONS_LIST_FAILED';

export const GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST = 'GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST';
export const GET_ADMIN_BRANCH_INVOICE_LIST_SUCCESS = 'GET_ADMIN_BRANCH_INVOICE_LIST_SUCCESS';
export const GET_ADMIN_BRANCH_INVOICE_LIST_FAILED = 'GET_ADMIN_BRANCH_INVOICE_LIST_FAILED';

export const POST_UPDATE_ZOHO_REPORT_EMAIL_REQUEST = 'POST_UPDATE_ZOHO_REPORT_EMAIL_REQUEST';
export const POST_UPDATE_ZOHO_REPORT_EMAIL_SUCCESS = 'POST_UPDATE_ZOHO_REPORT_EMAIL_SUCCESS';
export const POST_UPDATE_ZOHO_REPORT_EMAIL_FAILED = 'POST_UPDATE_ZOHO_REPORT_EMAIL_FAILED';
