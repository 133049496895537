import {
    GET_PHARMACY_BRANCHES_REQUEST,
    GET_PHARMACY_BRANCHES_SUCCESS,
    GET_PHARMACY_BRANCHES_FAILED
} from './action-types';

export const getPharmacyBranchesRequest = (payload) => ({
    type: GET_PHARMACY_BRANCHES_REQUEST,
    payload
});

export const getPharmacyBranchesSuccess = (payload) => ({
    type: GET_PHARMACY_BRANCHES_SUCCESS,
    payload
});

export const getPharmacyBranchesFailed = () => ({
    type: GET_PHARMACY_BRANCHES_FAILED
});
