import {
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_SUCCESS,
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_FAILED,
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_REQUEST,
    GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_CLEAR
} from '../../../actions/admin-view/absence-request-form/action-types';

const initialState = {
    hours: null,
    minutes: null
};

const absenceRequestForm = (state = initialState, action) => {
    switch (action.type) {
        case GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_REQUEST:
        case GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_SUCCESS:
            return {
                ...state,
                calculatedActualStaffShiftTime: action.payload
            };
        case GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_FAILED:
            return state;
        case GET_CALCULATED_EMPLOYEE_SHIFT_HOURS_CLEAR:
            return initialState;

        default:
            return state;
    }
};

export default absenceRequestForm;
