import {
    GET_PHARMACY_INVOICES_REQUEST,
    GET_PHARMACY_INVOICES_SUCCESS,
    GET_PHARMACY_INVOICES_FAILED,
    GET_PHARMACY_ALL_BRANCHES_REQUEST,
    GET_PHARMACY_ALL_BRANCHES_SUCCESS,
    GET_PHARMACY_ALL_BRANCHES_FAILED,
    GET_PHARMACY_SUBSCRIPTION_REQUEST,
    GET_PHARMACY_SUBSCRIPTION_SUCCESS,
    GET_PHARMACY_SUBSCRIPTION_FAILED,
    CLEAR_INVOICES_LIST
} from '../../../actions/super-admin-view/pharmacy-billing-page/action-types';

const initialState = {
    transactions: {
        data: null,
        page: 1,
        perPage: 10,
        hasMore: false
    },
    subscription: {
        data: null,
        loading: false
    },
    subscriptionPlan: {
        data: null,
        loading: false
    },
    branches: {
        data: null,
        loading: false
    }
};

export const handleTransactions = (state, action) => {
    if (state === undefined) {
        return initialState.transactions;
    }
    switch (action.type) {
        case GET_PHARMACY_INVOICES_REQUEST:
            return {
                ...state.transactions,
                loading: true
            };
        case GET_PHARMACY_INVOICES_SUCCESS:
            return {
                ...state.transactions,
                loading: false,
                data: action.payload.rows,
                page: action.payload.page,
                hasMore: action.payload.hasMore
            };
        case GET_PHARMACY_INVOICES_FAILED:
            return state.transactions;
        case CLEAR_INVOICES_LIST:
            return initialState.transactions;
        default:
            return state.transactions;
    }
};

export const handleActiveBranches = (state, action) => {
    if (state === undefined) {
        return initialState.branches;
    }

    switch (action.type) {
        case GET_PHARMACY_ALL_BRANCHES_REQUEST:
            return {
                ...state.branches,
                loading: true
            };
        case GET_PHARMACY_ALL_BRANCHES_SUCCESS:
            return {
                ...state.branches,
                data: action.payload.data,
                loading: false
            };
        case GET_PHARMACY_ALL_BRANCHES_FAILED:
            return initialState.branches;
        default:
            return state.branches;
    }
};

export const handleSubscriptionPlan = (state, action) => {
    if (state === undefined) {
        return initialState.subscriptionPlan;
    }
    const { payload } = action;
    switch (action.type) {
        case GET_PHARMACY_SUBSCRIPTION_REQUEST:
            return {
                ...state.subscriptionPlan,
                loading: true
            };
        case GET_PHARMACY_SUBSCRIPTION_SUCCESS:
            return {
                ...state.subscriptionPlan,
                data: payload.data,
                loading: false
            };
        case GET_PHARMACY_SUBSCRIPTION_FAILED:
            return initialState.subscriptionPlan;
        default:
            return state.subscriptionPlan;
    }
};
