import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import './style.scss';

const GlobalTab = ({ tab: { title, to, isActive, image }, isProfile = false }) => (
    <NavLink className="tabs__item" activeClassName="tabs__item--active" to={to} isActive={isActive}>
        {isProfile && image ? <Image src={image} avatar /> : null}
        {title}
    </NavLink>
);

GlobalTab.propTypes = {
    tab: PropTypes.shape({
        title: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        image: PropTypes.string,
        isActive: PropTypes.func
    }).isRequired,
    isProfile: PropTypes.bool
};

export default GlobalTab;
