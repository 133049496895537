import {
    handleJobDetails,
    handleWorkedLocums,
    handleJobActions,
    handleJobCancellation,
    handleJobCancellationReasons
} from './handlers';

export default (state, action) => ({
    removingLoading: handleJobCancellation(state, action),
    jobApplyResponseData: handleJobActions(state, action),
    jobData: handleJobDetails(state, action),
    workedLocums: handleWorkedLocums(state, action),
    cancellationReasons: handleJobCancellationReasons(state, action)
});
