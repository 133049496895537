export const GET_STAFF_CONTRACT_REQUEST = 'GET_STAFF_CONTRACT_REQUEST';
export const GET_STAFF_CONTRACT_SUCCESS = 'GET_STAFF_CONTRACT_SUCCESS';
export const GET_STAFF_CONTRACT_FAILED = 'GET_STAFF_CONTRACT_FAILED';

export const POST_STAFF_CONTRACT_REQUEST = 'POST_STAFF_CONTRACT_REQUEST';
export const POST_STAFF_CONTRACT_SUCCESS = 'POST_STAFF_CONTRACT_SUCCESS';
export const POST_STAFF_CONTRACT_FAILED = 'POST_STAFF_CONTRACT_FAILED';

export const GET_STAFF_CONTRACT_LIST_REQUEST = 'GET_STAFF_CONTRACT_LIST_REQUEST';
export const GET_STAFF_CONTRACT_LIST_SUCCESS = 'GET_STAFF_CONTRACT_LIST_SUCCESS';
export const GET_STAFF_CONTRACT_LIST_FAILED = 'GET_STAFF_CONTRACT_LIST_FAILED';

export const GET_STAFF_CONTRACT_BY_ID_REQUEST = 'GET_STAFF_CONTRACT_BY_ID_REQUEST';
export const GET_STAFF_CONTRACT_BY_ID_SUCCESS = 'GET_STAFF_CONTRACT_BY_ID_SUCCESS';
export const GET_STAFF_CONTRACT_BY_ID_FAILED = 'GET_STAFF_CONTRACT_BY_ID_FAILED';

export const UPDATE_STAFF_CONTRACT_REQUEST = 'UPDATE_STAFF_CONTRACT_REQUEST';
export const UPDATE_STAFF_CONTRACT_SUCCESS = 'UPDATE_STAFF_CONTRACT_SUCCESS';
export const UPDATE_STAFF_CONTRACT_FAILED = 'UPDATE_STAFF_CONTRACT_FAILED';

export const DELETE_STAFF_CONTRACT_REQUEST = 'DELETE_STAFF_CONTRACT_REQUEST';
export const DELETE_STAFF_CONTRACT_SUCCESS = 'DELETE_STAFF_CONTRACT_SUCCESS';
export const DELETE_STAFF_CONTRACT_FAILED = 'DELETE_STAFF_CONTRACT_FAILED';

export const START_STAFF_CONTRACT_REQUEST = 'START_STAFF_CONTRACT_REQUEST';
export const START_STAFF_CONTRACT_SUCCESS = 'START_STAFF_CONTRACT_SUCCESS';
export const START_STAFF_CONTRACT_FAILED = 'START_STAFF_CONTRACT_FAILED';

export const GET_CONTRACT_MIN_DATE_REQUEST = 'GET_CONTRACT_MIN_DATE_REQUEST';
export const GET_CONTRACT_MIN_DATE_SUCCESS = 'GET_CONTRACT_MIN_DATE_SUCCESS';
export const GET_CONTRACT_MIN_DATE_FAILED = 'GET_CONTRACT_MIN_DATE_FAILED';

export const DUPLICATE_CONTRACT_REQUEST = 'DUPLICATE_CONTRACT_REQUEST';
export const DUPLICATE_CONTRACT_SUCCESS = 'DUPLICATE_CONTRACT_SUCCESS';
export const DUPLICATE_CONTRACT_FAILED = 'DUPLICATE_CONTRACT_FAILED';