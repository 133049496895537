import {
    GET_ADMIN_BRANCH_INVOICE_LIST_SUCCESS,
    GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST
} from '../../../actions/super-admin-view/billing-page/action-types';

const initialState = {
    data: [],
    page: null,
    perPage: 10,
    count: 0,
    pages: 0
};

const adminBillingPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_BRANCH_INVOICE_LIST_SUCCESS:
            return {
                ...state.transactions,
                data: action.payload.rows,
                page: action.payload.page,
                count: action.payload.count
            };
        case GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST:
            return state;
        default:
            return state;
    }
};

export default adminBillingPage;
