import React from 'react';
import PropTypes from 'prop-types';
import SchedulePage from './schedule-page';
import RegionsPage from './regions-page';
import BranchesPage from './branches-page';
import StaffPage from './staff-page';
import PostedJobsPage from './posted-jobs-page';
import PharmacyInfoPage from './pharmacy-info-page';
import Header from '../../../components/header';
import ErrorBoundary from '../../error-boundary';

import { Switch, Redirect } from 'react-router-dom';
import { generatePath } from 'react-router';
import { PrivateRoute } from '../../../services/privateRoute';
import { useSelector } from 'react-redux';

import { RouterConfig } from './../../../routerConfig';
import LocumBankPage from './locum-bank';

const AdminView = ({ role, roles, user: { name, surname, image } }) => {
    const createdBranchId = useSelector((state) => state.createBranch.createdBranchId);

    const tabs = [
        {
            title: 'schedule',
            to: RouterConfig.branchAdminSchedule
        },
        { title: 'regions', to: RouterConfig.branchAdminRegions },
        { title: 'branches', to: RouterConfig.branchAdminBranches },
        { title: 'staff', to: RouterConfig.branchAdminStaff },
        { title: 'locum shifts', to: RouterConfig.branchAdminPostedJobs },
        { title: 'locum bank', to: RouterConfig.branchAdminLocumBank }
    ];
    const profileTab = {
        title: `${name} ${!surname ? '' : surname}`,
        to: RouterConfig.branchAdminPharmacyInfo,
        image
    };

    return (
        <div>
            <Header tabs={tabs} profileTab={profileTab} />
            <ErrorBoundary>
                <Switch>
                    <PrivateRoute
                        path={RouterConfig.branchAdminSchedule}
                        user={role}
                        roles={roles}
                        component={SchedulePage}
                        exact
                    />
                    <PrivateRoute
                        path={RouterConfig.branchAdminRegions}
                        user={role}
                        roles={roles}
                        component={RegionsPage}
                        exact
                    />
                    <PrivateRoute
                        path={RouterConfig.branchAdminBranches}
                        user={role}
                        roles={roles}
                        component={BranchesPage}
                    />
                    <PrivateRoute
                        path={RouterConfig.branchAdminStaff}
                        user={role}
                        roles={roles}
                        component={StaffPage}
                    />
                    <PrivateRoute
                        path={RouterConfig.branchAdminPostedJobs}
                        user={role}
                        roles={roles}
                        component={PostedJobsPage}
                    />
                    <PrivateRoute
                        path={RouterConfig.branchAdminPharmacyInfo}
                        user={role}
                        roles={roles}
                        component={PharmacyInfoPage}
                    />
                    <PrivateRoute
                        path={RouterConfig.branchAdminLocumBank}
                        user={role}
                        roles={roles}
                        component={LocumBankPage}
                    />
                    <Redirect
                        from={RouterConfig.root}
                        to={
                            createdBranchId
                                ? generatePath(RouterConfig.branchAdminBranchRoot, {
                                      id: createdBranchId
                                  })
                                : RouterConfig.branchAdminSchedule
                        }
                    />
                </Switch>
            </ErrorBoundary>
        </div>
    );
};

AdminView.propTypes = {
    role: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
};

export default AdminView;
