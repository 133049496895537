import {
    GET_LOCUMS_BY_GPHC_CLEAR,
    GET_LOCUMS_BY_GPHC_FAILED,
    GET_LOCUMS_BY_GPHC_SUCCESS,
    GET_LOCUMS_BY_GPHC_LOADING
} from '../../../actions/admin-view/locum-bank-add-locum-form/action-types';

const initialState = {
    data: null,
    loading: null,
    error: ''
};

const locumBankLocumsData = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCUMS_BY_GPHC_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: ''
            };
        case GET_LOCUMS_BY_GPHC_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_LOCUMS_BY_GPHC_FAILED:
            return {
                ...state,
                data: {},
                loading: false,
                error: action.payload.data.message
            };
        case GET_LOCUMS_BY_GPHC_CLEAR:
            return {
                ...initialState,
                loading: null
            };
        default:
            return state;
    }
};

export default locumBankLocumsData;
