class notificationSettings {
    settings = {
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animated', 'zoomIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true
        },
        slidingEnter: {
            duration: 100,
            timingFunction: 'ease-out',
            delay: 0
        },
        slidingExit: {
            duration: 300,
            timingFunction: 'ease-out',
            delay: 0
        },
        touchSlidingExit: {
            swipe: {
                duration: 300,
                timingFunction: 'ease-out',
                delay: 0
            },
            fade: {
                duration: 300,
                timingFunction: 'ease-out',
                delay: 0
            }
        }
    };
}

export default new notificationSettings();
