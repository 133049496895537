import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const GPHCInfoCard = ({ registered = false, gphcInfo, provisionallyRegisterNumberData }) => {
    if (!registered) {
        return (
            <div className="staff-info-page__gphc-info">
                <h2 className="gphc-info-title unregistered">No info from GPhC Server yet</h2>
            </div>
        );
    } else {
        let expiryDate,
            fitnessToPractiseIssues,
            forenames,
            gphcNumber,
            independentPrescriber,
            status,
            superintendentPharmacist,
            supplementaryPrescriber,
            surname,
            town;

        if (gphcInfo) {
            forenames = gphcInfo.forenames;
            status = gphcInfo.status;
            surname = gphcInfo.surname;
            town = gphcInfo.town;
            gphcNumber = gphcInfo.gphcNumber;
            expiryDate = gphcInfo.expiryDate;
            fitnessToPractiseIssues = gphcInfo.fitnessToPractiseIssues;
            independentPrescriber = gphcInfo.independentPrescriber;
            superintendentPharmacist = gphcInfo.superintendentPharmacist;
            supplementaryPrescriber = gphcInfo.supplementaryPrescriber;
        } else if (provisionallyRegisterNumberData) {
            forenames = provisionallyRegisterNumberData.forenames;
            status = provisionallyRegisterNumberData.status;
            surname = provisionallyRegisterNumberData.surname;
            gphcNumber = provisionallyRegisterNumberData.provisionallyNumber;
            town = provisionallyRegisterNumberData.town;
        }

        return (
            <div className="staff-info-page__gphc-info">
                <h2 className="gphc-info-title">{gphcInfo ? 'Info from GPhC Server' : 'Provisional registration'}</h2>
                <div className="info-container">
                    <div className="info-container__row">
                        <div className="info-container__item">
                            <p className="title abbreviation">GPhC Number:</p>
                            <p className="description">{gphcNumber}</p>
                        </div>
                        <div className="info-container__item">
                            <p className="title">Town:</p>
                            <p className="description">{town.charAt(0).toUpperCase() + town.slice(1).toLowerCase()}</p>
                        </div>
                    </div>
                    <div className="info-container__row">
                        <div className="info-container__item">
                            <p className="title">Surname:</p>
                            <p className="description">{surname || 'None'}</p>
                        </div>
                        <div className="info-container__item">
                            <p className="title">Forname:</p>
                            <p className="description">{forenames || 'None'}</p>
                        </div>
                    </div>
                    <div className="info-container__row">
                        <div className="info-container__item fluid">
                            <p className="title">Supllementary prescriber:</p>
                            <p className="description">{supplementaryPrescriber}</p>
                        </div>
                    </div>
                    <div className="info-container__row">
                        <div className="info-container__item fluid">
                            <p className="title">Independent prescriber:</p>
                            <p className="description">{independentPrescriber}</p>
                        </div>
                    </div>
                    <div className="info-container__row">
                        <div className="info-container__item fluid">
                            <p className="title">Superintendent Pharmacist:</p>
                            <p className="description">{superintendentPharmacist}</p>
                        </div>
                    </div>
                    <div className="info-container__row">
                        <div className="info-container__item">
                            <p className="title">Status Description:</p>
                            <p className="description">{status}</p>
                        </div>
                        <div className="info-container__item">
                            <p className="title">Expire Date:</p>
                            <p className="description">{expiryDate && moment(expiryDate).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                    <div className="info-container__row">
                        <div className="info-container__item fluid">
                            <p className="title">Fitness to Practice Information:</p>
                            <p className="description">{fitnessToPractiseIssues}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

GPHCInfoCard.propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    registered: PropTypes.bool
};

export default GPHCInfoCard;
