import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'semantic-ui-react';
import classnames from 'classnames';

import './style.scss';

export const LocationSearchInput = ({
    label,
    value,
    name,
    fluid,
    hidden,
    helperText,
    disabled,
    error,
    onHandleSelect,
    onChangeHandler,
    placeholder,
    searchOptions,
    onBlur,
    shouldFetchSuggestions
}) => {
    const [address, setAddress] = useState('');

    useEffect(() => {
        setAddress(value);
    }, [value]);

    const handleChange = (address) => {
        setAddress(address);
        onChangeHandler(null, { name, value: address });
    };

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={onHandleSelect}
            searchOptions={searchOptions}
            shouldFetchSuggestions={shouldFetchSuggestions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div
                    className={classnames('input-group', {
                        fluid,
                        hidden
                    })}
                >
                    <label>{label}</label>
                    <Input
                        {...getInputProps({
                            placeholder,
                            className: 'location-search-input'
                        })}
                        name={name}
                        value={address}
                        loading={loading}
                        error={error}
                        disabled={disabled}
                        autoComplete="off"
                        onFocus={(e) => e.target.setAttribute('autocomplete', 'off')}
                        onBlur={onBlur}
                    />
                    <div className="autocomplete-dropdown-container">
                        {!error &&
                            suggestions.map((suggestion) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                    </div>
                    <p className="helper-text">{helperText}</p>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

LocationSearchInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    fluid: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
    onHandleSelect: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    searchOptions: PropTypes.object,
    onBlur: PropTypes.func,
    shouldFetchSuggestions: PropTypes.bool
};
