import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react';
import Timepicker from '../../UI/timepicker';
import ButtonComponent from '../../UI/buttons/button';

import { weekDays, getDuration, getDurationFormat } from '../../../services/datesHelper';
import moment from 'moment';

import './style.scss';

const TIME_FORMAT = 'HH:mm';

class BranchOperatingTime extends Component {
    state = {
        week: weekDays.map((day) => {
            return {
                day,
                selected: false,
                startTime: null,
                endTime: null,
                duration: '',
                lunch: false,
                lunchStartTime: null,
                lunchEndTime: null,
                lunchDuration: ''
            };
        }),
        isValid: false
    };

    componentDidMount() {
        this._isMounted = true;
        const { operatingDays, onRemoveNewestBranchId } = this.props;

        if (operatingDays && operatingDays.length > 0) {
            const getOperatingDays = weekDays.map((day) => {
                const getDaysFormat = operatingDays.find((operatingDay) => operatingDay.dayOfWeek === day);
                if (getDaysFormat) {
                    return {
                        day,
                        selected: true,
                        startTime: moment(getDaysFormat.startTime, 'HH:mm'),
                        endTime: moment(getDaysFormat.endTime, 'HH:mm'),
                        duration: getDuration(
                            moment(getDaysFormat.startTime, 'HH:mm'),
                            moment(getDaysFormat.endTime, 'HH:mm')
                        ),
                        lunch: !!getDaysFormat.lunchStartTime,
                        lunchStartTime: getDaysFormat.lunchStartTime
                            ? moment(getDaysFormat.lunchStartTime, 'HH:mm')
                            : null,
                        lunchEndTime: getDaysFormat.lunchEndTime ? moment(getDaysFormat.lunchEndTime, 'HH:mm') : null,
                        lunchDuration: getDuration(
                            moment(getDaysFormat.lunchStartTime, 'HH:mm'),
                            moment(getDaysFormat.lunchEndTime, 'HH:mm')
                        )
                    };
                } else
                    return {
                        day,
                        selected: false,
                        startTime: null,
                        endTime: null,
                        duration: ''
                    };
            });

            this.setState({ week: getOperatingDays, isValid: true });
        }

        onRemoveNewestBranchId();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onStatTimeChange = (time, timeStart, timeFinish, duration, day) => {
        const { week } = this.state;
        time.set({ second: 0 });
        let setDuration = timeFinish ? getDuration(time, timeFinish) : '';
        const updatedState = week.map((el) => {
            if (el.day !== day) return el;
            return {
                ...el,
                [timeStart]: time,
                [duration]: setDuration
            };
        });

        this.setState(
            {
                week: updatedState
            },
            () => this.validate()
        );
    };

    onFinishTimeChange = (time, timeFinish, timeStart, duration, day) => {
        const { week } = this.state;
        time.set({ second: 0 });
        let setDuration = timeStart ? getDuration(timeStart, time) : '';
        const updatedState = week.map((el) => {
            if (el.day !== day) return el;
            return {
                ...el,
                [timeFinish]: time,
                [duration]: setDuration
            };
        });

        this.setState({ week: updatedState }, () => this.validate());
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () => this.validate(name)
        );
    };

    onSelectDay = (e, day, isLunch) => {
        e.preventDefault();
        const { week } = this.state;

        const updatedState = week.map((el) => {
            if (el.day !== day) return el;

            if (isLunch)
                return {
                    ...el,
                    lunch: !el.lunch
                };

            return {
                ...el,
                selected: !el.selected
            };
        });

        this.setState(
            {
                week: updatedState
            },
            () => this.validate()
        );
    };

    validate = () => {
        const { week } = this.state;
        let isValid = true;
        if (
            week.some(
                ({ selected, duration, lunchDuration, startTime, endTime, lunch, lunchStartTime, lunchEndTime }) =>
                    (selected && duration < 60) ||
                    (lunch && !lunchDuration) ||
                    (lunch && lunchDuration && lunchDuration <= 0) ||
                    moment(lunchStartTime).isSameOrBefore(moment(startTime)) ||
                    moment(lunchStartTime).isSameOrAfter(moment(endTime)) ||
                    moment(lunchEndTime).isSameOrAfter(moment(endTime))
            )
        )
            isValid = false;

        this.setState({ isValid });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { week } = this.state;
        const { onSuccess } = this.props;

        const branchSchedule = week
            .map(({ selected, day, startTime, endTime, lunch, lunchStartTime, lunchEndTime }) => {
                if (!selected) return null;
                let result = {};
                if (selected && startTime) {
                    result = {
                        dayOfWeek: day,
                        startTime: moment(startTime).format('HH:mm'),
                        endTime: moment(endTime).format('HH:mm')
                    };
                }
                if (lunch && lunchStartTime) {
                    result.lunchStartTime = moment(lunchStartTime).format('HH:mm');
                    result.lunchEndTime = moment(lunchEndTime).format('HH:mm');
                }
                return result;
            })
            .filter((item) => !!item);

        onSuccess(branchSchedule);
    };

    render() {
        const { week, isValid } = this.state;
        const { onBackClick, loading } = this.props;

        const dayOptions = week.map((item) => {
            const { day, selected, startTime, endTime, lunch, lunchStartTime, lunchEndTime } = item;
            return (
                <div className="day-row" key={day}>
                    <ButtonComponent
                        value={day}
                        onClick={(e) => this.onSelectDay(e, day)}
                        size="medium"
                        type={selected ? 'confirm' : 'bordered'}
                        loading={loading}
                        disabled={loading}
                    />
                    <div className="day-row__time">
                        <Timepicker
                            size="pre-medium"
                            onChange={(time) => this.onStatTimeChange(time, 'startTime', endTime, 'duration', day)}
                            format={TIME_FORMAT}
                            minuteStep={15}
                            value={startTime}
                            disabled={loading || !selected}
                        />
                        <p className="duration">
                            {selected
                                ? getDurationFormat(getDuration(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')))
                                : 'Branch closed'}
                        </p>
                        <Timepicker
                            size="pre-medium"
                            onChange={(time) => this.onFinishTimeChange(time, 'endTime', startTime, 'duration', day)}
                            format={TIME_FORMAT}
                            minuteStep={15}
                            value={endTime}
                            disabled={loading || !selected}
                        />
                    </div>
                    <ButtonComponent
                        value="Lunch"
                        onClick={(e) => this.onSelectDay(e, day, true)}
                        size="medium"
                        type={lunch ? 'confirm' : 'bordered'}
                        loading={loading}
                        disabled={!selected || loading}
                    />
                    <div className="day-row__time">
                        <Timepicker
                            size="pre-medium"
                            onChange={(time) =>
                                this.onStatTimeChange(time, 'lunchStartTime', lunchEndTime, 'lunchDuration', day)
                            }
                            format={TIME_FORMAT}
                            minuteStep={15}
                            value={lunchStartTime}
                            disabled={loading || !selected || !lunch}
                        />
                        <p className="duration">
                            {selected && lunch
                                ? getDurationFormat(
                                      getDuration(moment(lunchStartTime, 'HH:mm'), moment(lunchEndTime, 'HH:mm'))
                                  )
                                : selected && !lunch
                                ? 'Open all day'
                                : 'Branch closed'}
                        </p>
                        <Timepicker
                            size="pre-medium"
                            onChange={(time) =>
                                this.onFinishTimeChange(time, 'lunchEndTime', lunchStartTime, 'lunchDuration', day)
                            }
                            format={TIME_FORMAT}
                            minuteStep={15}
                            value={lunchEndTime}
                            disabled={loading || !selected || !lunch}
                        />
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="branch-info-page__head">
                    <h3 className="branch-info-page__title">Edit Operating Time</h3>
                    <div className="branch-operating-time-form__row buttons-row">
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="small"
                            onClick={onBackClick}
                            disabled={loading}
                        />
                        <ButtonComponent
                            value="Save"
                            onClick={this.onSubmit}
                            size="medium"
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                </div>
                <Form className="branch-operating-time-form" autoComplete="some-random-string">
                    <div className="branch-operating-time-form__inner">{dayOptions}</div>
                </Form>
            </>
        );
    }
}

BranchOperatingTime.propTypes = {
    operatingDays: PropTypes.array,
    onBackClick: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onRemoveNewestBranchId: PropTypes.func
};

export default BranchOperatingTime;
