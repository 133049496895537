import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Popup } from 'semantic-ui-react';

import './style.scss';

const PopupComponent = ({
    triggerComponent,
    context,
    event = 'click',
    pinned = true,
    color = 'blue',
    position = 'bottom center',
    size = 'huge',
    children,
    smallPaddings,
    nonePaddings,
    mouseEnterDelay,
    mouseLeaveDelay,
    hoverable,
    wide,
    hidePointingArrow,
    positionFixed
}) => (
    <Popup
        on={event}
        pinned={pinned}
        trigger={triggerComponent}
        context={context}
        className={classnames('info-popup', {
            [color]: color,
            smallPaddings,
            nonePaddings,
            wide
        })}
        position={position}
        size={size}
        hoverable={hoverable}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
        basic={hidePointingArrow}
        positionFixed={positionFixed}
    >
        {children}
    </Popup>
);

PopupComponent.propTypes = {
    triggerComponent: PropTypes.node,
    event: PropTypes.string,
    pinned: PropTypes.bool,
    color: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string,
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,
    hoverable: PropTypes.bool,
    wide: PropTypes.bool,
    hidePointingArrow: PropTypes.bool,
    positionFixed: PropTypes.bool
};

export default PopupComponent;
