import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { resendActivationLinkSuccess, resendActivationLinkFailed } from '../../actions';
import * as userVerificationActionTypes from '../../actions/verification-page/action-types';
import api from '../../../services/api';

function* resendActivationLinkRequest({ payload: { email, toggleModal } }) {
    try {
        yield call(api.request, {
            url: '/auth/resend-activation-link',
            method: 'post',
            payload: { email }
        });
        yield put(resendActivationLinkSuccess());
        toggleModal();
    } catch (err) {
        yield put(resendActivationLinkFailed());
    }
}

function* watchResendActivationLinkRequest() {
    yield takeEvery(userVerificationActionTypes.RESEND_ACTIVATION_LINK_REQUEST, resendActivationLinkRequest);
}

const verificationPageSaga = [fork(watchResendActivationLinkRequest)];

export default verificationPageSaga;
