import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import { getJobOffersSuccess, getJobOffersFailed } from '../../../actions';
import { selectItemsPerPage } from './selectors';
import * as locumJobOffersListActionTypes from '../../../actions/locum-view/job-offers/action-types';
import api from '../../../../services/api';

function* onLocumJobOffersRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const filters = payload;

    try {
        const { data } = yield call(api.request, {
            url: '/jobs/list',
            method: 'post',
            payload: {
                tabType: payload.tabType,
                filters,
                pagination: {
                    page: filters.page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getJobOffersSuccess({
                data,
                totalData: data.count
            })
        );
    } catch (err) {
        yield put(getJobOffersFailed());
    }
}

// watchers
function* watchLocumJobOffersRequest() {
    yield takeEvery(locumJobOffersListActionTypes.GET_JOB_OFFERS_REQUEST, onLocumJobOffersRequest);
}

const locumJobOffersSaga = [fork(watchLocumJobOffersRequest)];

export default locumJobOffersSaga;
