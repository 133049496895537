import React from 'react';
import PropTypes from 'prop-types';
import { weekDays, sortDays } from '../../../../../../services/datesHelper';
import { ReactComponent as LockIcon } from '../../../../../../assets/icons/lock.svg';
import TemplateButton from '../../../../../../components/UI/buttons/template-button';
import { Dropdown, Icon } from 'semantic-ui-react';
const weeks = ['1', '2', '3', '4'];

const MenuOptionValue = {
    clear: {
        name: 'clear',
        value: null,
        weekNumber: null
    },
    copy: {
        name: 'copy',
        value: null,
        weekNumber: null
    }
};

const TemplateBoardSchedule = ({ toggleModal, operatingTime, data, handleDropdownSelect }) => {
    return weeks.map((week) => {
        const weekData = data.filter((shift) => shift.weekNumber === week);
        return (
            <React.Fragment key={week}>
                <div className="week-container">
                    <div className="week-title">Week {week}</div>
                    <div>
                        <Dropdown icon={<Icon color="grey" name="ellipsis horizontal" size="small" />} text="">
                            <Dropdown.Menu>
                                {weeks
                                    .filter((eachWeek) => eachWeek !== week)
                                    .map((w) => (
                                        <Dropdown.Item
                                            value={JSON.stringify({
                                                ...MenuOptionValue.copy,
                                                value: w,
                                                weekNumber: week
                                            })}
                                            onClick={handleDropdownSelect}
                                            key={'dropdown_' + w}
                                            text={`Copy week ${w}`}
                                        />
                                    ))}
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    value={JSON.stringify({ ...MenuOptionValue.clear, weekNumber: week })}
                                    onClick={handleDropdownSelect}
                                    text={<span className="menu-item-red">Clear weak</span>}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="branch-template__row shifts-row">
                    {weekDays
                        .map((day) => {
                            const dayAvailable = operatingTime && operatingTime.find((ot) => ot.dayOfWeek === day);
                            return (
                                <div key={day} className="shift-holder">
                                    {weekData
                                        .filter((item) => day === item.dayOfWeek)
                                        .map((item) => {
                                            return (
                                                <div key={item.id}>
                                                    <div
                                                        className="branch-template__shift"
                                                        onClick={() =>
                                                            toggleModal({
                                                                name: 'edit',
                                                                value: { id: item.id, day },
                                                                weekNumber: week
                                                            })
                                                        }
                                                    >
                                                        {item.staff ? (
                                                            <p className="staff">{`${item.staff.name} ${item.staff.surname}`}</p>
                                                        ) : null}
                                                        <p className="shift-time">{`${item.startTime} - ${item.endTime}`}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    {dayAvailable ? (
                                        <TemplateButton
                                            onClick={() =>
                                                toggleModal({
                                                    name: 'add',
                                                    value: { day },
                                                    weekNumber: week
                                                })
                                            }
                                        />
                                    ) : (
                                        <div className={`branch-template__shift-cell closed`}>
                                            <div className={`inner centered`}>
                                                <LockIcon className="lock-icon" />
                                                <span className="inner__title">Branch Closed</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                        .sort((a, b) => sortDays(a, b, weekDays))}
                </div>
            </React.Fragment>
        );
    });
};

TemplateBoardSchedule.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    handleDropdownSelect: PropTypes.func.isRequired,
    operatingTime: PropTypes.array,
    data: PropTypes.array
};

export default TemplateBoardSchedule;
