import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';
import classnames from 'classnames';

import './style.scss';

function StripeCustomPagination({ onPaginate, prevDisabled, nextDisabled, disabled, modalView }) {
    return (
        <Button.Group
            className={classnames('stripe-custom-pagination', {
                disabled,
                modalView
            })}
        >
            <Button
                icon="left chevron"
                className="stripe-custom-pagination__left"
                disabled={prevDisabled}
                onClick={() => onPaginate('prev')}
            />
            <Button
                icon="right chevron"
                className="stripe-custom-pagination__right"
                disabled={nextDisabled}
                onClick={() => onPaginate('next')}
            />
        </Button.Group>
    );
}

StripeCustomPagination.propTypes = {
    onPaginate: PropTypes.func.isRequired,
    prevDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    modalView: PropTypes.bool
};

export default StripeCustomPagination;
