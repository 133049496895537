import { connect } from 'react-redux';
import LocumBankPage from './LocumBankPage';

import { getLocumBankByFilterRequest, addLocumBankRequest } from '../../../../store/actions';

export default connect(
    (state) => ({
        locumBanks: state.locumBankPage.locumBanks
    }),
    {
        getLocumBankByFilterRequest,
        addLocumBankRequest
    }
)(LocumBankPage);
