import {
    POST_PAYMENT_METHOD_REQUEST,
    POST_PAYMENT_METHOD_SUCCESS,
    POST_PAYMENT_METHOD_FAILED,
    GET_PAYMENT_METHOD_REQUEST,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILED,
    GET_ACTIVE_BRANCHES_LIST_REQUEST,
    GET_ACTIVE_BRANCHES_LIST_SUCCESS,
    GET_ACTIVE_BRANCHES_LIST_FAILED,
    GET_SELECTED_BRANCHES,
    GET_SUBSCRIPTION_PLAN_REQUEST,
    GET_SUBSCRIPTION_PLAN_SUCCESS,
    GET_SUBSCRIPTION_PLAN_FAILED,
    SET_SELECTED_SUBSCRIPTION_PLAN,
    GET_SUBSCRIPTION_PLANS_LIST_REQUEST,
    GET_SUBSCRIPTION_PLANS_LIST_SUCCESS,
    GET_SUBSCRIPTION_PLANS_LIST_FAILED,
    POST_PLAN_SUBSCRIPTION_REQUEST,
    POST_PLAN_SUBSCRIPTION_SUCCESS,
    POST_PLAN_SUBSCRIPTION_FAILED,
    CANCEL_PLAN_SUBSCRIPTION_REQUEST,
    CANCEL_PLAN_SUBSCRIPTION_SUCCESS,
    CANCEL_PLAN_SUBSCRIPTION_FAILED,
    ABORT_SUBSCRIPTION_ACTION_REQUEST,
    ABORT_SUBSCRIPTION_ACTION_SUCCESS,
    ABORT_SUBSCRIPTION_ACTION_FAILED,
    GET_TRANSACTIONS_LIST_REQUEST,
    GET_TRANSACTIONS_LIST_SUCCESS,
    GET_TRANSACTIONS_LIST_FAILED,
    CLEAR_TRANSACTIONS_LIST,
    CLEAR_SUBSCRIPTION_DATA,
    UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST,
    UPDATE_BRANCH_SUBSCRIPTIONS_SUCCESS,
    UPDATE_BRANCH_SUBSCRIPTIONS_FAILED,
    UPDATE_PROFILE_HOSTED_PAGE_REQUEST,
    UPDATE_PROFILE_HOSTED_PAGE_SUCCESS,
    UPDATE_PROFILE_HOSTED_PAGE_FAILED,
    SUBSCRIPTION_STANDARD_PLAN_REQUEST,
    SUBSCRIPTION_STANDARD_PLAN_SUCCESS,
    SUBSCRIPTION_STANDARD_PLAN_FAILED,
    GET_PROFILE_SUBSCRIPTION_REQUEST,
    GET_PROFILE_SUBSCRIPTION_SUCCESS,
    GET_PROFILE_SUBSCRIPTION_FAILED,
    GET_ADDONS_REQUEST,
    GET_ADDONS_SUCCESS,
    GET_ADDONS_FAILED,
    POST_DEACTIVATE_SUBSCRIPTION_REQUEST,
    POST_DEACTIVATE_SUBSCRIPTION_SUCCESS,
    POST_DEACTIVATE_SUBSCRIPTION_FAILED
} from './action-types';

export const getAddonsRequest = () => ({
    type: GET_ADDONS_REQUEST
});
export const getAddonsSuccess = (payload) => ({
    type: GET_ADDONS_SUCCESS,
    payload
});
export const getAddonsFailed = () => ({
    type: GET_ADDONS_FAILED
});

export const postDeactivateSubscriptionRequest = (payload) => ({
    type: POST_DEACTIVATE_SUBSCRIPTION_REQUEST,
    payload
});
export const postDeactivateSubscriptionSuccess = (payload) => ({
    type: POST_DEACTIVATE_SUBSCRIPTION_SUCCESS,
    payload
});
export const postDeactivateSubscriptionFailed = () => ({
    type: POST_DEACTIVATE_SUBSCRIPTION_FAILED
});

export const getProfileSubscriptionRequest = () => ({
    type: GET_PROFILE_SUBSCRIPTION_REQUEST
});
export const getProfileSubscriptionSuccess = (payload) => ({
    type: GET_PROFILE_SUBSCRIPTION_SUCCESS,
    payload
});
export const getProfileSubscriptionFailed = () => ({
    type: GET_PROFILE_SUBSCRIPTION_FAILED
});

export const subscriptionStandardPlanRequest = () => ({
    type: SUBSCRIPTION_STANDARD_PLAN_REQUEST
});

export const subscriptionStandardPlanSuccess = (payload) => ({
    type: SUBSCRIPTION_STANDARD_PLAN_SUCCESS,
    payload
});

export const subscriptionStandardPlanFailed = () => ({
    type: SUBSCRIPTION_STANDARD_PLAN_FAILED
});

export const updateProfileHostedPageRequest = (payload) => ({
    type: UPDATE_PROFILE_HOSTED_PAGE_REQUEST,
    payload
});

export const updateProfileHostedPageSuccess = () => ({
    type: UPDATE_PROFILE_HOSTED_PAGE_SUCCESS
});

export const updateProfileHostedPageFailed = () => ({
    type: UPDATE_PROFILE_HOSTED_PAGE_FAILED
});

// payment method actions
export const postPaymentMethodRequest = (payload) => ({
    type: POST_PAYMENT_METHOD_REQUEST,
    payload
});

export const postPaymentMethodSuccess = () => ({
    type: POST_PAYMENT_METHOD_SUCCESS
});

export const postPaymentMethodFailed = () => ({
    type: POST_PAYMENT_METHOD_FAILED
});

export const updateBranchSubscriptionsRequest = (payload) => ({
    type: UPDATE_BRANCH_SUBSCRIPTIONS_REQUEST,
    payload
});

export const updateBranchSubscriptionsSuccess = () => ({
    type: UPDATE_BRANCH_SUBSCRIPTIONS_SUCCESS
});

export const updateBranchSubscriptionsFailed = () => ({
    type: UPDATE_BRANCH_SUBSCRIPTIONS_FAILED
});

export const getPaymentMethodRequest = () => ({
    type: GET_PAYMENT_METHOD_REQUEST
});

export const getPaymentMethodSuccess = (payload) => ({
    type: GET_PAYMENT_METHOD_SUCCESS,
    payload
});

export const getPaymentMethodFailed = () => ({
    type: GET_PAYMENT_METHOD_FAILED
});

// subscription plan actions
export const getSubscriptionPlanRequest = () => ({
    type: GET_SUBSCRIPTION_PLAN_REQUEST
});

export const getSubscriptionPlanSuccess = (payload) => ({
    type: GET_SUBSCRIPTION_PLAN_SUCCESS,
    payload
});

export const getSubscriptionPlanFailed = () => ({
    type: GET_SUBSCRIPTION_PLAN_FAILED
});

export const setSelectedSubscriptionPlan = (payload) => ({
    type: SET_SELECTED_SUBSCRIPTION_PLAN,
    payload
});

export const postPlanSubscriptionRequest = (payload, downgrade) => ({
    type: POST_PLAN_SUBSCRIPTION_REQUEST,
    payload,
    downgrade
});

export const postPlanSubscriptionSuccess = (payload) => ({
    type: POST_PLAN_SUBSCRIPTION_SUCCESS,
    payload
});

export const postPlanSubscriptionFailed = () => ({
    type: POST_PLAN_SUBSCRIPTION_FAILED
});

export const cancelPlanSubscriptionRequest = (payload) => ({
    type: CANCEL_PLAN_SUBSCRIPTION_REQUEST,
    payload
});

export const cancelPlanSubscriptionSuccess = () => ({
    type: CANCEL_PLAN_SUBSCRIPTION_SUCCESS
});

export const cancelPlanSubscriptionFailed = () => ({
    type: CANCEL_PLAN_SUBSCRIPTION_FAILED
});

export const abortSubscriptionActionRequest = (action) => ({
    type: ABORT_SUBSCRIPTION_ACTION_REQUEST,
    action
});

export const abortSubscriptionActionSuccess = () => ({
    type: ABORT_SUBSCRIPTION_ACTION_SUCCESS
});

export const abortSubscriptionActionFailed = () => ({
    type: ABORT_SUBSCRIPTION_ACTION_FAILED
});

// subscription plans list actions
export const getSubscriptionPlansListRequest = () => ({
    type: GET_SUBSCRIPTION_PLANS_LIST_REQUEST
});

export const getSubscriptionPlansListSuccess = (payload) => ({
    type: GET_SUBSCRIPTION_PLANS_LIST_SUCCESS,
    payload
});

export const getSubscriptionPlansListFailed = () => ({
    type: GET_SUBSCRIPTION_PLANS_LIST_FAILED
});

// branches activation actions
export const getActiveBranchesListRequest = () => ({
    type: GET_ACTIVE_BRANCHES_LIST_REQUEST
});

export const getActiveBranchesListSuccess = (payload) => ({
    type: GET_ACTIVE_BRANCHES_LIST_SUCCESS,
    payload
});

export const getActiveBranchesListFailed = () => ({
    type: GET_ACTIVE_BRANCHES_LIST_FAILED
});

export const getSelectedBranches = (payload) => ({
    type: GET_SELECTED_BRANCHES,
    payload
});

// transactions list actions
export const getTransactionsListRequest = (payload) => ({
    type: GET_TRANSACTIONS_LIST_REQUEST,
    payload
});

export const getTransactionsListSuccess = (payload) => ({
    type: GET_TRANSACTIONS_LIST_SUCCESS,
    payload
});

export const getTransactionsListFailed = () => ({
    type: GET_TRANSACTIONS_LIST_FAILED
});

export const clearTransactionsList = () => ({
    type: CLEAR_TRANSACTIONS_LIST
});

export const clearSubscriptionData = () => ({
    type: CLEAR_SUBSCRIPTION_DATA
});
