import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import InfoDashboard from './InfoDashboard';
import EditBranchForm from '../../../../../../components/forms/edit-branch-form';
import BranchOperatingTime from '../../../../../../components/forms/branch-operating-time';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';

import './style.scss';

export function Information({
    data,
    generalData,
    getLoading,
    onArchiveBranch,
    archiveBranchModal,
    onOpenModal,
    onCloseModal,
    branchId,
    createdBranchId,
    onEditBranchRequest,
    onEditBranchScheduleRequest,
    removeBranchLogoRequest,
    onUpdateBranchRateRequest,
    clearCreatedBranchInfo
}) {
    const [activeView, setActiveView] = useState('dashboard');

    useEffect(() => {
        if (createdBranchId) {
            setActiveView('editOperatingTime');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditBranch = useCallback(
        (recievedData) => {
            let formData = new FormData();

            for (let key in recievedData) {
                formData.append(key, recievedData[key]);
            }

            onEditBranchRequest({
                branchId,
                formData,
                toggleModal: () => setActiveView('dashboard')
            });
        },
        [branchId, onEditBranchRequest]
    );

    const onPostBranchSchedule = useCallback(
        (recievedData) => {
            onEditBranchScheduleRequest({
                branchId,
                recievedData,
                toggleModal: () => setActiveView('dashboard')
            });
        },
        [branchId, onEditBranchScheduleRequest]
    );

    const onRemoveFile = useCallback(() => removeBranchLogoRequest({ branchId }), [branchId, removeBranchLogoRequest]);

    const onRemoveNewestBranchId = useCallback(
        () => (createdBranchId ? clearCreatedBranchInfo() : false),
        [clearCreatedBranchInfo, createdBranchId]
    );

    const archiveBranchButton = {
        value: 'Confirm',
        onClick: onArchiveBranch,
        size: 'big',
        width: 'big',
        loading: getLoading
    };
    const infoView =
        activeView === 'dashboard' ? (
            <InfoDashboard
                data={data}
                getData={generalData}
                onUpdateBranchRateRequest={onUpdateBranchRateRequest}
                onEditInfoClick={() => setActiveView('editBranchInfo')}
                onEditTimeClick={() => setActiveView('editOperatingTime')}
                onArchiveBranchClick={onOpenModal}
            />
        ) : activeView === 'editBranchInfo' ? (
            <EditBranchForm
                data={generalData}
                branchInfo={data}
                onBackClick={() => setActiveView('dashboard')}
                onSuccess={onEditBranch}
                loading={getLoading}
                onRemoveFile={onRemoveFile}
            />
        ) : (
            <BranchOperatingTime
                loading={getLoading}
                operatingDays={data.operatingTime}
                onBackClick={() => setActiveView('dashboard')}
                onSuccess={onPostBranchSchedule}
                onRemoveNewestBranchId={onRemoveNewestBranchId}
            />
        );
    return (
        <div className="branch-info-page">
            {infoView}
            {archiveBranchModal && (
                <ConfirmModal
                    open={archiveBranchModal}
                    onClose={onCloseModal}
                    title="Archive Branch"
                    text="Are you sure you want to archive this branch?"
                    button={archiveBranchButton}
                />
            )}
        </div>
    );
}

Information.propTypes = {
    branchId: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    generalData: PropTypes.object.isRequired,
    archiveBranchModal: PropTypes.bool,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onArchiveBranch: PropTypes.func.isRequired,
    getLoading: PropTypes.bool,
    createdBranchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
