import BasicModal from 'components/modals/basic-modal';
import { TabPanel, TabSwitcher } from 'components/tab-switcher';
import TableComponent from 'components/table';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { CONTRACT_TYPE_TEXT } from 'services/constants';
import { useContractStaffId, useSearchParams } from 'services/hooks';
import { getContractByIdRequest, getContractListRequest } from 'store/actions';
import ContractBoard from '../contract-board';
import DuplicateModal from '../duplicate-modal';

const ClosedContracts = () => {
    const dispatch = useDispatch();
    const { data, list } = useSelector((state) => state.staffContractPage);
    const staffId = useContractStaffId();

    const [{ page = 1 }, setSearchParams] = useSearchParams();
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [mode, setMode] = useState('table');
    const [selectedContract, setSelectedContract] = useState(null);

    const getList = useCallback(() => {
        dispatch(
            getContractListRequest({
                staffId,
                filters: {
                    status: 'closed'
                },
                pagination: {
                    page: page,
                    perPage: list.itemsPerPage
                }
            })
        );
    }, [dispatch, list.itemsPerPage, page, staffId]);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        if (!selectedContract) return;
        dispatch(getContractByIdRequest({ contractId: selectedContract.id }));
    }, [dispatch, selectedContract, staffId]);

    const handleOpenInfo = (id) => {
        setSelectedContract({ id });
        setMode('view');
    };
    const handleDuplicateItem = (contract) => () => {
        setSelectedContract(contract);
        setOpenDuplicate(true);
    };

    const handleOnClose = (tab) => {
        setOpenDuplicate(false);
        if (typeof tab === 'string') {
            setSearchParams({ tab });
        }
    };

    const tableCols = [
        {
            name: 'title',
            title: 'Contract title',
            render: (item) => item.title,
            type: 'disabled'
        },
        {
            name: 'startDate',
            title: 'Start date',
            render: (item) => moment(item.startDate).format('DD/MM/YY'),
            type: 'disabled'
        },
        {
            name: 'endDate',
            title: 'End date',
            render: (item) => moment(item.endDate).format('DD/MM/YY'),
            type: 'disabled'
        },
        {
            name: 'type',
            title: 'Contract type',
            render: (item) => CONTRACT_TYPE_TEXT[item.type],
            type: 'disabled'
        },
        {
            name: 'actions',
            title: 'Actions',
            render: (item) => (
                <div className="upcoming-contracts__actions-wrapper">
                    <Button size="mini" onClick={handleDuplicateItem(item)}>
                        Duplicate
                    </Button>
                </div>
            ),
            type: 'disabled',
            isAction: true
        }
    ];

    return (
        <div className="closed-contracts">
            <BasicModal open={openDuplicate} onClose={handleOnClose} title="Duplicate contract">
                <DuplicateModal contractId={selectedContract?.id} onClose={handleOnClose} />
            </BasicModal>
            <TabSwitcher tabName={mode}>
                <TabPanel value="table">
                    <TableComponent
                        cols={tableCols}
                        data={list.data?.rows}
                        totalItems={list.data?.count}
                        itemsPerPage={list.itemsPerPage}
                        page={Number(page)}
                        preserveQueryParams={true}
                        openInfo={handleOpenInfo}
                    />
                </TabPanel>
                <TabPanel value="view">
                    <ContractBoard contract={data} />
                </TabPanel>
            </TabSwitcher>
        </div>
    );
};

ClosedContracts.propTypes = {};

export default ClosedContracts;
