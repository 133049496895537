import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getLocumJobDetailsRequest,
    getLocumJobDetailsSuccess,
    getLocumJobDetailsFailed,
    onLocumJobActionSuccess,
    onLocumJobActionFailed,
    onJobMileageValueUpdateSuccess,
    onJobMileageValueUpdateFailed
} from '../../../actions';
import * as locumJobPreviewActionTypes from '../../../actions/locum-view/job-preview/action-types';
import api from '../../../../services/api';

function* onGetLocumJobRequest({ payload: { jobId } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/jobs/${jobId}`,
            method: 'GET'
        });
        yield put(getLocumJobDetailsSuccess(data));
    } catch (err) {
        yield put(getLocumJobDetailsFailed());
    }
}

function* onLocumJobActionRequest({ payload: { jobId, action } }) {
    try {
        yield call(api.request, {
            url: `/jobs/${jobId}/${action}`,
            method: 'POST'
        });
        yield put(onLocumJobActionSuccess());
        yield put(getLocumJobDetailsRequest({ jobId }));
    } catch (err) {
        yield put(onLocumJobActionFailed());
    }
}

function* onJobMileageValueUpdateRequest({ payload }) {
    try {
        yield call(api.request, {
            url: `/jobs/${payload.jobId}/update-mileage-value`,
            method: 'POST',
            payload
        });
        yield put(onJobMileageValueUpdateSuccess());
    } catch (err) {
        yield put(onJobMileageValueUpdateFailed());
    }
}

// watchers
function* watchGetLocumJobRequest() {
    yield takeEvery(locumJobPreviewActionTypes.GET_LOCUM_JOB_DETAILS_REQUEST, onGetLocumJobRequest);
}

function* watchLocumJobActionRequest() {
    yield takeEvery(locumJobPreviewActionTypes.LOCUM_JOB_ACTION_REQUEST, onLocumJobActionRequest);
}

function* watchJobMileageValueUpdateRequest() {
    yield takeEvery(locumJobPreviewActionTypes.JOB_MILEAGE_VALUE_UPDATE_REQUEST, onJobMileageValueUpdateRequest);
}

const locumJobDetailsSaga = [
    fork(watchGetLocumJobRequest),
    fork(watchLocumJobActionRequest),
    fork(watchJobMileageValueUpdateRequest)
];

export default locumJobDetailsSaga;
