import React from 'react';
import PropTypes from 'prop-types';

import TableComponent from '../table';

import './style.scss';

const JobAdjustments = ({ jobAdjustments }) => {
    const tableCols = [
        {
            name: 'prevValues',
            title: 'From',
            value: null,
            render: (item) => item.prevValues,
            type: 'disabled'
        },
        {
            name: 'newValues',
            title: 'To',
            value: null,
            render: (item) => item.newValues,
            type: 'disabled'
        },
        {
            name: 'userName',
            title: 'By',
            value: null,
            render: (item) => item.userName || `${item.name} ${item.surname}`,
            type: 'disabled'
        }
    ];

    return (
        <div className="job-adjustments">
            <p className="title">Operating Adjustment History</p>
            <TableComponent
                cols={tableCols}
                data={jobAdjustments}
                totalItems={jobAdjustments.length}
                itemsPerPage={3}
                page={Number(1)}
            />
        </div>
    );
};

JobAdjustments.propTypes = {
    jobAdjustments: PropTypes.array
};

JobAdjustments.defaultProps = {
    jobAdjustments: []
};

export default JobAdjustments;
