import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import './style.scss';

const ButtonIcon = ({ children, onClick }) => (
    <Button className="button-icon" icon onClick={onClick}>
        {children}
    </Button>
);

ButtonIcon.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default ButtonIcon;
