import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import Button from '../../UI/buttons/button';
import SelectComponent from '../../UI/select';
import TextAreaComponent from '../../UI/inputs/text-area';

function ConfirmModalForm({ cancellationReasons, button }) {
    const [repostJob, setRepostJob] = useState(false);
    const [showRepost, setShowRepost] = useState(false);
    const [cancelledNote, setCancelledNote] = useState('');
    const [reason, setReason] = useState('reason_0');

    const onCancelReason = (ev, { name, value, options }) => {
        const option = options.find((o) => o.key === value);
        setRepostJob(option && option.repostjob === 'true');
        setShowRepost(option && option.repostjob === 'true');
        setReason(value);
    };

    const renderCancellation = () => {
        if (!cancellationReasons) return null;

        const options = cancellationReasons.map((option) => {
            return {
                key: option.key,
                value: option.key,
                text: option.title,
                repostjob: option.repostJob.toString()
            };
        });

        return (
            <div className="content reasons">
                <p className="content-cancel-title">Let us know why you're canceling this job:</p>
                <div className="">
                    <SelectComponent
                        name="cancelled_reason"
                        icon="caret down"
                        floating
                        defaultValue={reason}
                        onChange={onCancelReason}
                        options={options}
                    />
                </div>
            </div>
        );
    };

    const renderRepostJob = () => {
        return (
            <div className="repost-job">
                {showRepost && (
                    <Checkbox
                        name="repostJob"
                        label="Repost Job"
                        onChange={() => {
                            setRepostJob(!repostJob);
                        }}
                        checked={repostJob}
                    />
                )}
                {!!cancellationReasons && (
                    <TextAreaComponent
                        name="cancelled_note"
                        label="Notes"
                        value={cancelledNote}
                        error={null}
                        helperText={null}
                        onChange={(ev, { value }) => {
                            setCancelledNote(value);
                        }}
                    />
                )}
            </div>
        );
    };

    const onSubmit = () => {
        const data = {
            cancelled_reason: reason,
            cancelled_note: cancelledNote,
            repostJob: repostJob
        };
        button.onClick(data);
    };

    return (
        <>
            {renderCancellation()}
            {renderRepostJob()}
            <Button
                size={button.size}
                value={button.value}
                loading={button.loading}
                disabled={button.disabled}
                onClick={onSubmit}
            />
        </>
    );
}

ConfirmModalForm.propTypes = {
    cancellationReasons: PropTypes.array
};

export default ConfirmModalForm;
