import React, { useEffect, useCallback } from 'react';
import Header from '../../../../components/header';
import LocumCheckInForm from '../../../../components/forms/locum-check-in-form';
import cookies from 'browser-cookies';
import { RouterConfig } from '../../../../routerConfig';

import './style.scss';

export const LocumCheckInPage = ({
    onValidateGphcRequest,
    loading,
    isAuth,
    user,
    history,
    match: {
        params: { id }
    },
    ...props
}) => {
    useEffect(() => {
        if (isAuth && history.action !== 'PUSH') {
            cookies.erase('accessToken');
            cookies.erase('refreshToken');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const onConfirmIdentity = useCallback(
        (gphcNumber) =>
            onValidateGphcRequest({
                id,
                gphcNumber,
                callback: () => history.push(RouterConfig.locum.onboarding)
            }),
        [history, id, onValidateGphcRequest]
    );

    const onCancelCheckIn = useCallback(() => history.push(RouterConfig.root), [history]);

    const withHeader = isAuth ? null : <Header />;

    return (
        <div className="check-in-page">
            {withHeader}
            <LocumCheckInForm
                loading={loading}
                onConfirmIdentity={onConfirmIdentity}
                onCancelCheckIn={onCancelCheckIn}
            />
        </div>
    );
};
