import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GlobalTab from '../global-tab';
import ConfirmModal from '../modals/confirm-modal';

import { connect } from 'react-redux';
import { logout } from '../../store/actions';

import logo from '../../assets/img/header-logo.svg';
import logoutIcon from '../../assets/icons/logout.svg';

import './style.scss';
import { Message, Icon } from 'semantic-ui-react';

const Header = ({ tabs = [], profileTab, isAuth, logout, user }) => {
    // logout confirm modal state
    const [logoutModal, toggleLogoutModal] = useState(false);
    const [displayBanner, setDisplayBanner] = useState(false);

    useEffect(() => {
        let visible = false;
        if (user && user.role === 'branch_admin') {
            visible = !user.hasBranches;
        }
        setDisplayBanner(visible);
    }, [user]);

    // navigation tabs
    const displayTabs = tabs.length === 0 ? null : tabs.map((tab) => <GlobalTab key={tab.title} tab={tab} />);

    // display profile tab
    const displayProfileTab = profileTab === undefined ? null : <GlobalTab tab={profileTab} isProfile />;

    // display logout button
    const logoutButton = isAuth ? (
        <div className="logout-button" role="button" onClick={() => toggleLogoutModal(true)}>
            <img src={logoutIcon} alt="logout icon" />
        </div>
    ) : null;

    // logout button for confirm modal
    const confirmLogoutButton = {
        value: 'Confirm',
        onClick: () => logout(),
        size: 'big',
        width: 'big'
    };

    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header-left">
                        <Link to="/">
                            <img className="logo" src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="tabs">{displayTabs}</div>
                    <div className="header-right">
                        <div className="notifications"></div>
                        <div className="profile-tab">{displayProfileTab}</div>
                        {logoutButton}
                    </div>
                </div>
                {logoutModal && (
                    <ConfirmModal
                        open={logoutModal}
                        onClose={() => toggleLogoutModal(false)}
                        title="Logout"
                        text="Are you sure you want to logout?"
                        button={confirmLogoutButton}
                    />
                )}
            </header>
            <div
                className="header"
                style={{
                    marginTop: '1rem',
                    backgroundColor: '#f9f9f9',
                    display: displayBanner ? 'block' : 'none'
                }}
            >
                <div className="info-container">
                    <Message info={true} className="info-text" size="small" icon>
                        <Message.Content>
                            <Icon color={null} name="info circle" />
                            You do not have an active billing plan, please add your&nbsp;
                            <Link className="link" to="/branches">
                                branches
                            </Link>{' '}
                            and{' '}
                            <Link className="link" to="/pharmacy-info/billing">
                                activate your subscription
                            </Link>
                        </Message.Content>
                    </Message>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuth: state.auth.isAuth,
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
