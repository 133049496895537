export const GET_PERSONAL_INFO_REQUEST = 'GET_PERSONAL_INFO_REQUEST';
export const GET_PERSONAL_INFO_SUCCESS = 'GET_PERSONAL_INFO_SUCCESS';
export const GET_PERSONAL_INFO_FAILED = 'GET_PERSONAL_INFO_FAILED';

export const POST_PERSONAL_INFO_REQUEST = 'POST_PERSONAL_INFO_REQUEST';
export const POST_PERSONAL_INFO_SUCCESS = 'POST_PERSONAL_INFO_SUCCESS';
export const POST_PERSONAL_INFO_FAILED = 'POST_PERSONAL_INFO_FAILED';

export const CLEAR_PERSONAL_INFO = 'CLEAR_PERSONAL_INFO';

export const GET_RIGHTS_TO_WORK_INFO_REQUEST = 'GET_RIGHTS_TO_WORK_INFO_REQUEST';
export const GET_RIGHTS_TO_WORK_INFO_SUCCESS = 'GET_RIGHTS_TO_WORK_INFO_SUCCESS';
export const GET_RIGHTS_TO_WORK_INFO_FAILED = 'GET_RIGHTS_TO_WORK_INFO_FAILED';

export const POST_RIGHTS_TO_WORK_INFO_REQUEST = 'POST_RIGHTS_TO_WORK_INFO_REQUEST';
export const POST_RIGHTS_TO_WORK_INFO_SUCCESS = 'POST_RIGHTS_TO_WORK_INFO_SUCCESS';
export const POST_RIGHTS_TO_WORK_INFO_FAILED = 'POST_RIGHTS_TO_WORK_INFO_FAILED';

export const GET_SKILLS_INFO_REQUEST = 'GET_SKILLS_INFO_REQUEST';
export const GET_SKILLS_INFO_SUCCESS = 'GET_SKILLS_INFO_SUCCESS';
export const GET_SKILLS_INFO_FAILED = 'GET_SKILLS_INFO_FAILED';

export const POST_SKILLS_INFO_REQUEST = 'POST_SKILLS_INFO_REQUEST';
export const POST_SKILLS_INFO_SUCCESS = 'POST_SKILLS_INFO_SUCCESS';
export const POST_SKILLS_INFO_FAILED = 'POST_SKILLS_INFO_FAILED';

export const GET_ACCREDITATIONS_INFO_REQUEST = 'GET_ACCREDITATIONS_INFO_REQUEST';
export const GET_ACCREDITATIONS_INFO_SUCCESS = 'GET_ACCREDITATIONS_INFO_SUCCESS';
export const GET_ACCREDITATIONS_INFO_FAILED = 'GET_ACCREDITATIONS_INFO_FAILED';

export const POST_ACCREDITATIONS_INFO_REQUEST = 'POST_ACCREDITATIONS_INFO_REQUEST';
export const POST_ACCREDITATIONS_INFO_SUCCESS = 'POST_ACCREDITATIONS_INFO_SUCCESS';
export const POST_ACCREDITATIONS_INFO_FAILED = 'POST_ACCREDITATIONS_INFO_FAILED';

export const REMOVE_UPLOADED_FILE_REQUEST = 'REMOVE_UPLOADED_FILE_REQUEST';
export const REMOVE_UPLOADED_FILE_SUCCESS = 'REMOVE_UPLOADED_FILE_SUCCESS';
export const REMOVE_UPLOADED_FILE_FAILED = 'REMOVE_UPLOADED_FILE_FAILED';

export const SET_STORE_DOCUMENT = 'SET_STORE_DOCUMENT';
export const REMOVE_STORE_DOCUMENT = 'REMOVE_STORE_DOCUMENT';
export const CLEAR_ACCREDITATIONS_DOCS = 'CLEAR_ACCREDITATIONS_DOCS';

export const SET_SKILLS_STORE_DOCUMENT = 'SET_SKILLS_STORE_DOCUMENT';
export const REMOVE_SKILLS_DOCUMENT = 'REMOVE_SKILLS_DOCUMENT';

export const SET_SKILLS_DATA = 'SET_SKILLS_DATA';
export const SET_SKILLS_CHECKBOX = 'SET_SKILLS_CHECKBOX';
export const SET_ACCREDITATIONS_CHECKBOX = 'SET_ACCREDITATIONS_CHECKBOX';
