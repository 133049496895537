import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ButtonComponent from '../../UI/buttons/button';
import TextInput from '../../UI/inputs/text-input';

import { Form, Checkbox } from 'semantic-ui-react';

import './style.scss';
import ConfirmModal from '../../modals/confirm-modal';

const cols = ['Branch', 'Numark Membership Number', 'Numark member', 'Subscription'];

const numarkPrice = 29.99;
const standardPrice = 34.99;

function SuperAdminBillingDetailsForm({
    onCancelClick,
    onSubmit,
    loading,
    branches,
    onDeactivateBranch,
    reloadPersonalInfo
}) {
    const [branchSubscriptions, setBranchSubscriptions] = useState([]);
    const [deactivatedBranchId, setDeactivatedBranchId] = useState(null);
    useEffect(() => {
        if (!branches) return;

        setBranchSubscriptions(
            branches.map((branch) => {
                let brancIsActive = branch.active;
                if (branch.inactiveDate) {
                    brancIsActive = false;
                }
                return {
                    ...branch,
                    numark: branch.numark || null,
                    active: brancIsActive,
                    paid: branch.active,
                    originalNumark: branch.numark || null,
                    updated: false,
                    validNumark: branch.numark && branch.numark.length === 7
                };
            })
        );
    }, [branches]);

    useEffect(() => {
        reloadPersonalInfo();
    }, [reloadPersonalInfo]);

    const handleSubmit = async (event) => {
        if (loading) return;
        event.preventDefault();
        onSubmit(branchSubscriptions);
    };

    const toggleSubscription = (branch) => {
        if (branch.paid) {
            if (branch.active) {
                return setDeactivatedBranchId(branch.id);
            }
        }

        // setBranchSubscriptions(
        //   branchSubscriptions.map((eachBranch) => {
        //     if (eachBranch.id === branch.id) {
        //       eachBranch.active = !eachBranch.active;
        //       eachBranch.paid = false;
        //       eachBranch.updated = true;
        //     }
        //     return eachBranch;
        //   })
        // );
    };

    const toggleNumark = (branch) => {
        setBranchSubscriptions(
            branchSubscriptions.map((eachBranch) => {
                if (eachBranch.id === branch.id) {
                    eachBranch.isNumark = !eachBranch.isNumark;
                }
                return eachBranch;
            })
        );
    };

    const onNumarkCodeChnge = (branch, code) => {
        setBranchSubscriptions(
            branchSubscriptions.map((eachBranch) => {
                if (eachBranch.id === branch.id) {
                    let value = code;
                    value = value.replace(/[^0-9]/g, '');

                    eachBranch.numark = value.length > 7 ? eachBranch.numark : value || null;
                    eachBranch.updated = eachBranch.originalNumark !== eachBranch.numark;
                    eachBranch.validNumark = eachBranch.numark && eachBranch.numark.length === 7;
                    eachBranch.isNumark = eachBranch.validNumark;
                }
                return eachBranch;
            })
        );
    };

    const numarkCalculatedPrice = () => {
        return parseFloat(
            numarkPrice * branchSubscriptions.filter((branch) => branch.isNumark && branch.active).length
        ).toFixed(2);
    };
    const standardCalculatedPrice = () => {
        return parseFloat(
            standardPrice * branchSubscriptions.filter((branch) => !branch.isNumark && branch.active).length
        ).toFixed(2);
    };

    const newSubscribedBranch = branchSubscriptions.filter((branch) => branch.active && !branch.paid);

    const existSubscribedBranch = branches.filter((branch) => {
        if (branch.active && branchSubscriptions.find((bs) => bs.id === branch.id && bs.active)) {
            return true;
        }
        return false;
    });

    const calculateNewPrice = () => {
        const numarkCount = newSubscribedBranch.filter((branch) => branch.isNumark).length;
        const standardCount = newSubscribedBranch.filter((branch) => !branch.isNumark).length;

        const numarkBranchPrice = numarkCount * numarkPrice;
        const standardBranchPrice = standardCount * standardPrice;
        return parseFloat(numarkBranchPrice + standardBranchPrice).toFixed(2);
    };

    const calculateExistingPrice = () => {
        const numarkCount = existSubscribedBranch.filter((branch) => branch.isNumark).length;
        const standardCount = existSubscribedBranch.filter((branch) => !branch.isNumark).length;

        const numarkBranchPrice = numarkCount * numarkPrice;
        const standardBranchPrice = standardCount * standardPrice;
        return parseFloat(numarkBranchPrice + standardBranchPrice).toFixed(2);
    };

    const submitAvailable = () => {
        const updatedSubscription = branchSubscriptions.find(
            (branch) => branch.updated && branch.validNumark && branch.isNumark
        );
        if (updatedSubscription) {
            return true;
        }

        const matchNoNumark = branchSubscriptions.find(
            (branch) => branch.active && branch.isNumark && !branch.validNumark
        );
        if (matchNoNumark) return false;

        const newSubscription = branchSubscriptions.find((branch) => branch.active && !branch.paid);

        if (!newSubscription) {
            return false;
        }

        return true;
    };

    const confirmModalButton = {
        value: 'Yes',
        onClick: () => {
            onDeactivateBranch(deactivatedBranchId);
            setDeactivatedBranchId(null);
        },
        size: 'big',
        width: 'big'
    };
    const cancelModalButton = {
        value: 'No',
        onClick: () => {
            setDeactivatedBranchId(null);
        },
        size: 'big',
        width: 'big'
    };

    return (
        <>
            {deactivatedBranchId && (
                <ConfirmModal
                    open={true}
                    showCancelButton={true}
                    onClose={() => setDeactivatedBranchId(null)}
                    title="Deactivate"
                    text="You are deactivating 1 branch - these will be deactivated on the next billing date. Are you sure you want to do this ?"
                    button={confirmModalButton}
                    cancelButton={cancelModalButton}
                />
            )}
            <Form className="billing-details-form" onSubmit={handleSubmit} loading={loading}>
                <div className="billing-details-form__row">
                    {cols.map((col) => {
                        return (
                            <div className="col-size" key={col}>
                                {col}
                            </div>
                        );
                    })}
                </div>
                <div className="billing-details-form__scroll-container">
                    {branchSubscriptions.map((branch) => {
                        return (
                            <div key={branch.id} className="billing-details-form__row">
                                <div className="col-size">{branch.title || '-'}</div>
                                <div className="col-size">
                                    <TextInput
                                        name="numark"
                                        value={branch.numark || ''}
                                        type="string"
                                        disabled={!branch.active}
                                        onChange={(e) => onNumarkCodeChnge(branch, e.target.value)}
                                        fluid
                                    />
                                    {!branch.validNumark && branch.active && branch.numark && (
                                        <span className="billing-details-form__validation-error">7 digits only</span>
                                    )}
                                </div>
                                <div className="col-size mt-1">
                                    <Checkbox
                                        name="isNumark"
                                        onChange={() => toggleNumark(branch)}
                                        checked={branch.isNumark}
                                    />
                                </div>
                                <div className="col-size mt-1">
                                    <Checkbox
                                        name="subscription"
                                        onChange={() => toggleSubscription(branch)}
                                        checked={branch.active}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div>
                    <div className="billing-details-form__row">
                        <div>
                            <strong className="bold-size">{newSubscribedBranch.length} branches subscribed</strong>
                        </div>
                        <div>
                            <strong className="bold-size">{calculateNewPrice()} £ +</strong>
                        </div>
                        <div>
                            <strong className="bold-size">{calculateExistingPrice()} £ -</strong>
                        </div>
                    </div>
                    <div className="billing-details-form__row">
                        <strong className="bold-size">Total to be billed via Numark: £{numarkCalculatedPrice()}</strong>
                    </div>
                    <div className="billing-details-form__row">
                        <strong className="bold-size">
                            Total to be billed by standard price: £{standardCalculatedPrice()}
                        </strong>
                    </div>
                </div>

                <div className="billing-details-form__buttons">
                    <ButtonComponent
                        value="Cancel"
                        type="transparent"
                        size="small"
                        onClick={onCancelClick}
                        disabled={loading}
                    />
                    <ButtonComponent
                        value="Submit"
                        role="submit"
                        size="medium"
                        loading={loading}
                        disabled={!submitAvailable() || loading}
                    />
                </div>
            </Form>
        </>
    );
}

SuperAdminBillingDetailsForm.propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    onDeactivateBranch: PropTypes.func.isRequired,
    reloadPersonalInfo: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    branches: PropTypes.array
};

export default SuperAdminBillingDetailsForm;
