import {
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAILED,
    ON_CHANGE_USER_STATUS_REQUEST,
    ON_CHANGE_USER_STATUS_SUCCESS,
    ON_CHANGE_USER_STATUS_FAILED
} from '../../../actions/super-admin-view/user-info/action-types';

const initialState = {
    data: null,
    loading: {
        approve: false,
        reject: false,
        activate: false,
        deactivate: false
    }
};

const adminUserInfoPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        case GET_USER_INFO_FAILED:
            return state;
        case ON_CHANGE_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.actionName]: true
                }
            };
        case ON_CHANGE_USER_STATUS_SUCCESS:
        case ON_CHANGE_USER_STATUS_FAILED:
            return {
                ...state,
                loading: {
                    accept: false,
                    reject: false,
                    activate: false,
                    deactivate: false
                }
            };
        default:
            return state;
    }
};

export default adminUserInfoPage;
