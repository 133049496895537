import {
    GET_JOB_OFFERS_LIST_REQUEST,
    GET_JOB_OFFERS_LIST_SUCCESS,
    GET_JOB_OFFERS_LIST_FAILED,
    ON_JOB_OFFERS_LIST_FILTER_CHANGE,
    ON_JOB_OFFERS_LIST_FILTER_FALSE,
    CLEAR_JOB_OFFERS_LIST_FILTERS
} from './action-types';

export const getJobOffersListRequest = (payload, filters) => ({
    type: GET_JOB_OFFERS_LIST_REQUEST,
    payload,
    filters
});

export const getJobOffersListSuccess = (payload) => ({
    type: GET_JOB_OFFERS_LIST_SUCCESS,
    payload
});

export const getJobOffersListFailed = () => ({
    type: GET_JOB_OFFERS_LIST_FAILED
});

export const onJobOffersListFilterChange = (payload, page) => ({
    type: ON_JOB_OFFERS_LIST_FILTER_CHANGE,
    payload,
    page
});

export const onJobOffersListFilterFalse = () => ({
    type: ON_JOB_OFFERS_LIST_FILTER_FALSE
});

export const clearJobOffersListFilters = () => ({
    type: CLEAR_JOB_OFFERS_LIST_FILTERS
});
