import { call, put, fork, takeLeading } from 'redux-saga/effects';
import { getUserProfileSuccess, getUserProfileFailed } from '../../../actions';
import * as staffUserInfoActionTypes from '../../../actions/shared/profile-page/action-types';
import api from '../../../../services/api';

function* onStaffUserProfileRequest() {
    try {
        const { data } = yield call(api.request, {
            url: '/profile/full',
            method: 'get'
        });
        yield put(getUserProfileSuccess(data));
    } catch (err) {
        yield put(getUserProfileFailed());
    }
}

function* watchStaffUserProfileRequest() {
    yield takeLeading(staffUserInfoActionTypes.GET_USER_PROFILE_REQUEST, onStaffUserProfileRequest);
}

const staffUserProfilePageSaga = [fork(watchStaffUserProfileRequest)];

export default staffUserProfilePageSaga;
