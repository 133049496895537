import { LoginPage } from './LoginPage';
import { connect } from 'react-redux';
import { logInSubmit, resetPassSubmit, forgotPassSubmit } from '../../../store/actions';

export default connect(
    (state) => ({
        loading: state.loginPage.loading
    }),
    {
        logInSubmit,
        resetPassSubmit,
        forgotPassSubmit
    }
)(LoginPage);
