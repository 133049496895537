import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { signupSuccess, signupFailed, registerProcessOn, authSuccess } from '../../../actions';
import * as signupActionTypes from '../../../actions/signup-page/action-types';
import api from '../../../../services/api';

function* pharmacySignUpRequest({ payload: { recievedData, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: '/auth/sign-up',
            method: 'post',
            payload: recievedData
        });
        api.setAccessToken(data.access);
        api.setRefreshToken(data.refresh);
        yield put(registerProcessOn());
        yield put(authSuccess(data));
        yield put(signupSuccess());
        callback();
    } catch (err) {
        yield put(signupFailed());
    }
}

function* locumSignUpRequest({ payload: { recievedData, callback } }) {
    try {
        yield call(api.request, {
            url: '/auth/sign-up',
            method: 'post',
            payload: recievedData
        });
        yield put(signupSuccess());
        callback();
    } catch (err) {
        yield put(signupFailed());
    }
}

function* watchPharmacySignup() {
    yield takeEvery(signupActionTypes.PHARMACY_SIGN_UP_REQUEST, pharmacySignUpRequest);
}

function* watchLocumSignup() {
    yield takeEvery(signupActionTypes.LOCUM_SIGN_UP_REQUEST, locumSignUpRequest);
}

const signupSaga = [fork(watchPharmacySignup), fork(watchLocumSignup)];

export default signupSaga;
