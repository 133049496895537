import moment from 'moment';

export const emailError = (input) => {
    const regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(input) ? null : 'Please provide a valid email.';
};

export const passError = (input) => {
    if (!/[A-Z]/.test(input)) return 'Password must contain at least one uppercase character';
    if (!/[a-z]/.test(input)) return 'Password must contain at least one lowercase character';
    if (!/[0-9]/.test(input)) return 'Password must contain digits';
    if (!/[!@#$%^&*(),._?/\-":{}|<>]/.test(input)) return 'Password must contain at least one special character';
    if (input.length < 8) return 'Password must be at least 8 characters long';
    return null;
};

export const salaryError = (input) => {
    const regex = /(^\d{1,3})+(\.\d{1,2})?$/;
    return regex.test(input) && Number(input) > 0 ? null : 'You entered an invalid rate. Example: 8.87';
};

export const phoneError = (input) => {
    const regex = /^(\+44\s?\d{10}|0044\s?\d{10}|0\s?\d{10})?$/;
    if (!regex.test(input)) return 'Phone number is invalid.';
    if (!input) return 'Please enter the phone number';
    return null;
};

export const streetError = (input) => {
    const regex = /^(?=.*[0-9]$)(?=.*[a-zA-Z])/;
    return regex.test(input) ? null : 'Street value is invalid.';
};

export const digitsNumberError = (input) => {
    const regex = /^[0-9]+$/;
    if (!regex.test(input)) return 'The number is invalid.';
    if (regex.test(input) && input.length > 10) return 'Maximum length 10 symbols.';
    return null;
};

export const branchNumberError = (input) => {
    const regex = /^(?![0]*$)\d{1,9}(?:\d{1,2})?$/;
    return !regex.test(input) ? 'Branch number is invalid.' : null;
};

export const nameError = (input, isTitle = false) => {
    let inputValue = input === null ? '' : input;
    // const regex = /^[a-zA-Z\s]+$/;
    // const regex = /^[a-zA-Z]+(-?|\s?)[a-zA-Z]+(-?|\s?)[a-zA-Z]+(-?|\s?)[a-zA-Z]+(-?|\s?)[a-zA-Z]+(-?|\s?)[a-zA-Z]+(-?|\s?)[a-zA-Z]+(-?|\s?)[a-zA-Z]+$/;
    // const regex = /^[a-zA-Z]+[a-zA-Z- ]*[a-zA-Z]$/;
    const regex = /^([a-zA-Z]+(\s|-?))*[a-zA-Z]+$/;

    const minLength = isTitle ? 6 : 2;
    if (!regex.test(inputValue)) {
        return 'You entered an invalid value.';
    } else if (inputValue.length < minLength) {
        return `Minimum length ${minLength} symbols.`;
    } else if (inputValue.length > 50) {
        return 'Maximum length 50 symbols.';
    } else return null;
};

export const textAreaError = (input) => {
    let inputValue = input === null ? '' : input;
    if (inputValue.length < 2) {
        return 'Minimum length 2 symbols.';
    } else if (inputValue.length > 255) {
        return 'Maximum length 255 symbols.';
    } else return null;
};

export const zipError = (input) => {
    const complicatedPatternRegex =
        /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;
    //	const simplifiedPatternRegex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
    if (!complicatedPatternRegex.test(input)) return 'Invalid UK Post Code.';
    return null;
};

export const dropdownError = (input) => {
    let inputValue = input === null ? '' : input;
    if (inputValue && inputValue.length < 1) return 'Please select the value.';
    return null;
};

export const emptyInputError = (input) => {
    let inputValue = input === null ? '' : input;
    if (inputValue.length < 1) return 'Please enter the value.';
    return null;
};

export const infoInputError = (input, isMaxLengthOnly = false) => {
    let inputValue = input === null ? '' : input;
    if (inputValue.length < 6 && !isMaxLengthOnly) return 'Minimum length 6 symbols.';
    if (inputValue.length > 50) return 'Maximum length 50 symbols.';
    return null;
};

export const dobError = (input) => {
    if (!input) return 'Invalid Date';

    const dtCurrent = new Date();
    if (dtCurrent.getFullYear() - input.getFullYear() < 18) {
        return 'Eligibility 18 years ONLY.';
    }

    if (dtCurrent.getFullYear() - input.getFullYear() === 18) {
        //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
        if (dtCurrent.getMonth() < input.getMonth()) {
            return 'Eligibility 18 years ONLY.';
        }
        if (dtCurrent.getMonth() === input.getMonth()) {
            //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
            if (dtCurrent.getDate() < input.getDate()) {
                return 'Eligibility 18 years ONLY.';
            }
        }
    }
    return null;
};

export const dobInputError = (input) => {
    const regex = /^([0-2][0-9]|3[01])\/([0][0-9]|1[0-2])\/(19|20)[0-9]{2}$/;
    const getInputDate = moment(input, 'DD/MM/YYYY');
    const eligibleDate = moment().subtract(18, 'years');
    if ((input && !regex.test(input)) || !getInputDate.isValid()) return 'Invalid date';

    if (getInputDate.isAfter(eligibleDate)) return 'Eligibility 18 years ONLY.';

    return null;
};

export const insuranceNumberError = (input) => {
    const regex =
        /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/;
    let inputValue = !input ? '' : input;
    if (inputValue && !regex.test(inputValue)) return 'Invalid insurance number';
    return null;
};
