import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TableComponent from '../../../../../../components/table';
import PageNav from '../../../../../../components/page-nav';

import { getPharmacyJobsRequest } from '../../../../../../store/actions';
import { usePage } from '../../../../../../services/hooks';

const PharmacyJobsList = ({
    pharmacyBranches: { data, itemsPerPage, totalData },
    getPharmacyJobsRequest,
    match: {
        params: { id }
    }
}) => {
    const page = usePage();

    useEffect(() => {
        if (!page) return;
        getPharmacyJobsRequest({ page, id });
    }, [getPharmacyJobsRequest, page, id]);

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'id',
                title: 'job id',
                render: (item) => item.id,
                type: 'disabled'
            },
            {
                name: 'branchTitle',
                title: 'Branch',
                render: (item) => item.branchTitle,
                type: 'disabled'
            },
            {
                name: 'startDate',
                title: 'date',
                render: (item) => item.startDate,
                type: 'disabled'
            },
            {
                name: 'shiftTime',
                title: 'shift details',
                render: (item) => `${item.startTime} - ${item.endTime}`,
                type: 'disabled'
            },
            /*{
        name: "shiftLunch",
        title: "lunch details",
        render: (item) => `${item.lunchStartTime} - ${item.lunchEndTime}`,
        type: "disabled",
      },*/
            {
                name: 'locum',
                title: 'locum',
                render: (item) => item.locumName,
                type: 'disabled'
            },
            {
                name: 'status',
                title: 'status',
                render: (item) => item.status,
                type: 'disabled'
            }
        ];

        return (
            <div className="admin-pharmacies-page">
                <PageNav title="Jobs" />
                <TableComponent
                    cols={tableCols}
                    data={data.rows}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    pharmacyBranches: state.adminPharmacyJobsPage
});

const mapDispatchToProps = {
    getPharmacyJobsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyJobsList);
