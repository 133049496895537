import {
    GET_ADMIN_TRANSACTIONS_LIST_REQUEST,
    GET_ADMIN_TRANSACTIONS_LIST_SUCCESS,
    GET_ADMIN_TRANSACTIONS_LIST_FAILED,
    GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST,
    GET_ADMIN_BRANCH_INVOICE_LIST_SUCCESS,
    GET_ADMIN_BRANCH_INVOICE_LIST_FAILED,
    POST_UPDATE_ZOHO_REPORT_EMAIL_FAILED,
    POST_UPDATE_ZOHO_REPORT_EMAIL_REQUEST,
    POST_UPDATE_ZOHO_REPORT_EMAIL_SUCCESS
} from './action-types';

export const getAdminTransactionsListRequest = (payload, filters) => ({
    type: GET_ADMIN_TRANSACTIONS_LIST_REQUEST,
    payload,
    filters
});

export const getAdminTransactionsListSuccess = (payload) => ({
    type: GET_ADMIN_TRANSACTIONS_LIST_SUCCESS,
    payload
});

export const getAdminTransactionsListFailed = () => ({
    type: GET_ADMIN_TRANSACTIONS_LIST_FAILED
});

export const getAdminBranchInvoiceListRequest = (payload, filters) => ({
    type: GET_ADMIN_BRANCH_INVOICE_LIST_REQUEST,
    payload,
    filters
});

export const getAdminBranchInvoiceListSuccess = (payload) => ({
    type: GET_ADMIN_BRANCH_INVOICE_LIST_SUCCESS,
    payload
});

export const getAdminBranchInvoiceListFailed = () => ({
    type: GET_ADMIN_BRANCH_INVOICE_LIST_FAILED
});

export const postAdminZohoReportEmailRequest = (zohoReportEmail) => ({
    type: POST_UPDATE_ZOHO_REPORT_EMAIL_REQUEST,
    zohoReportEmail
});

export const postAdminZohoReportEmailSuccess = () => ({
    type: POST_UPDATE_ZOHO_REPORT_EMAIL_SUCCESS
});

export const postAdminZohoReportEmailFailed = () => ({
    type: POST_UPDATE_ZOHO_REPORT_EMAIL_FAILED
});
