import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getDayStatus, sortByStartTime } from '../../services/helpers';
import BranchItemCard from '../UI/branch-item-card';
import CalendarButtons from '../UI/buttons/schedule-calendar-buttons';
import WeekViewShiftCell from './WeekViewShiftCell';

import './style.scss';

const ScheduleWeekViewItem = ({
    data: { title, id, region, events, operatingTime },
    getWeek,
    onDayClick,
    onAddShiftClick,
    onPostJobClick
}) => {
    const displayCards = useMemo(
        () =>
            getWeek.map((item, index) => {
                const formattedItem = moment(item).format('YYYY-MM-DD');

                const getDateEvent = events.find((event) => event.startDate === formattedItem);

                const isPastDate = moment(formattedItem).isBefore(moment(), 'day');

                if (getDateEvent && (getDateEvent.branchClosed || !getDateEvent.shifts)) {
                    return <WeekViewShiftCell key={index} status="closed" />;
                }

                if (getDateEvent && getDateEvent.shifts && getDateEvent.shifts.length > 0) {
                    const { shifts } = getDateEvent;
                    const currentDay = getDateEvent;
                    currentDay.id = id;

                    let dayProps = {
                        shifts: [...shifts].sort((a, b) => sortByStartTime(a, b)),
                        status: getDayStatus(shifts)
                    };

                    return (
                        <WeekViewShiftCell
                            key={index}
                            status={dayProps.status}
                            shifts={dayProps.shifts}
                            onClick={() => onDayClick(currentDay)}
                        />
                    );
                }

                if (isPastDate) {
                    return <div key={index} />;
                }

                if (getDateEvent) {
                    return (
                        <CalendarButtons
                            key={index}
                            onAddShiftClick={() => onAddShiftClick({ startDate: formattedItem, operatingTime })}
                            onPostJobClick={() =>
                                onPostJobClick({
                                    startTime: getDateEvent.startWorkingTime,
                                    endTime: getDateEvent.endWorkingTime,
                                    lunchEndTime: getDateEvent.lunchEndTime,
                                    lunchStartTime: getDateEvent.lunchStartTime,
                                    startDate: getDateEvent.startDate,
                                    endDate: getDateEvent.endDate,
                                    operatingTime
                                })
                            }
                        />
                    );
                }
                return <div key={index} />;
            }),
        [events, getWeek, id, onAddShiftClick, onDayClick, onPostJobClick, operatingTime]
    );

    return (
        <div className="schedule-page__week-view-item">
            <BranchItemCard id={id} region={region} title={title} />
            <div className="shifts">{displayCards}</div>
        </div>
    );
};

ScheduleWeekViewItem.propTypes = {
    data: PropTypes.object,
    startWorkingTime: PropTypes.string,
    endWorkingTime: PropTypes.string,
    onJobCellClick: PropTypes.func,
    onShiftCellClick: PropTypes.func,
    onAddShiftClick: PropTypes.func.isRequired,
    onPostJobClick: PropTypes.func.isRequired
};

export default ScheduleWeekViewItem;
