import React from 'react';
import PropTypes from 'prop-types';

function UploadedDoc({ title }) {
    return (
        <div className="file-info">
            <p className="file-info__name">
                {title.split('/').pop()} <br />
                <span className="file-info__size">Uploaded document</span>
            </p>
        </div>
    );
}

UploadedDoc.propTypes = {
    title: PropTypes.string.isRequired
};

export default UploadedDoc;
