export const LOG_IN_SUMBIT = 'LOG_IN_SUMBIT';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';

export const RESET_PASS_SUBMIT = 'RESET_PASS_SUBMIT';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_FAILED = 'RESET_PASS_FAILED';

export const PROFILE_RESET_PASS_SUBMIT = 'PROFILE_RESET_PASS_SUBMIT';
export const PROFILE_RESET_PASS_SUCCESS = 'PROFILE_RESET_PASS_SUCCESS';
export const PROFILE_RESET_PASS_FAILED = 'PROFILE_RESET_PASS_FAILED';

export const FORGOT_PASS_SUBMIT = 'FORGOT_PASS_SUBMIT';
export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS';
export const FORGOT_PASS_FAILED = 'FORGOT_PASS_FAILED';
