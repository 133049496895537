import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import PageNav from '../../../../components/page-nav';
import TableComponent from '../../../../components/table';
import BasicModal from '../../../../components/modals/basic-modal';
import AddRegionForm from '../../../../components/forms/add-region-form';
import ConfirmModal from '../../../../components/modals/confirm-modal';

import { useModal, usePage } from '../../../../services/hooks';

import { getRegionsListRequest, onSubmitRegionRequest } from '../../../../store/actions';

import './style.scss';

function RegionsPage({
    regionsPage: { data, totalData, itemsPerPage, loading },
    getRegionsListRequest,
    onSubmitRegionRequest
}) {
    const [currentItemId, setCurrentItemId] = useState(null);
    const [editedRegion, setEditedRegion] = useState(null);

    const page = usePage();

    const [create, toggleCreate] = useModal(false);
    const [edit, toggleEdit] = useModal(false);
    const [remove, toggleRemove] = useModal(false);

    useEffect(() => {
        if (!page) return;
        getRegionsListRequest({ page });
    }, [getRegionsListRequest, page]);

    const onRegionSubmit = useCallback(
        (data, submitMode = 'create') => {
            let apiUrl = '/regions';
            const method = submitMode === 'create' || submitMode === 'edit' ? 'POST' : 'DELETE';
            if (submitMode === 'edit' || submitMode === 'remove') {
                apiUrl = `/regions/${currentItemId}`;
            }
            const regionTitle = submitMode === 'remove' ? editedRegion : data;

            onSubmitRegionRequest({
                apiUrl,
                method,
                regionTitle,
                page,
                toggleModal: () => {
                    if (submitMode === 'create') {
                        toggleCreate();
                    } else if (submitMode === 'edit') {
                        toggleEdit();
                    } else if (submitMode === 'remove') {
                        toggleRemove();
                        toggleEdit();
                    }
                }
            });
        },
        [currentItemId, editedRegion, onSubmitRegionRequest, page, toggleCreate, toggleEdit, toggleRemove]
    );

    const getEditedRegion = (id) => {
        const getRegion = data.rows.find((item) => id === item.id);

        setEditedRegion(getRegion.title);
    };

    const headerButtons = [
        {
            componentType: 'button',
            value: 'New region',
            onClick: () => toggleCreate(),
            size: 'medium',
            width: 'medium'
        }
    ];

    const removeRegionButton = {
        value: 'Confirm',
        onClick: () => onRegionSubmit(null, 'remove'),
        size: 'big',
        width: 'big',
        loading
    };

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'title',
                title: 'Region name',
                render: (item) => item.title,
                type: 'disabled'
            },
            {
                name: 'createdAt',
                title: 'Creation date',
                render: (item) => moment(item.createdAt).format('DD/MM/YY'),
                type: 'disabled'
            },
            {
                name: 'branchesCount',
                title: 'assigned branches',
                render: (item) => item.branchesCount,
                type: 'disabled'
            },
            {
                name: 'staffCount',
                title: 'assigned staff',
                render: (item) => item.staffCount,
                type: 'disabled'
            }
        ];

        const regionsTable = (
            <TableComponent
                cols={tableCols}
                data={data.rows}
                totalItems={totalData}
                itemsPerPage={itemsPerPage}
                page={Number(page)}
                openInfo={(id) => {
                    getEditedRegion(id);
                    setCurrentItemId(id);
                    toggleEdit();
                }}
            />
        );

        return (
            <div className="regions-admin-page">
                <div className="main-container">
                    <PageNav title="Regions" inputs={headerButtons} />
                    {regionsTable}
                </div>
                {create && (
                    <BasicModal
                        open={create}
                        onClose={() => toggleCreate()}
                        title="Add Region"
                        autoSizeHeader
                        closeIcon={false}
                    >
                        <AddRegionForm
                            loading={loading}
                            onCancelClick={() => toggleCreate()}
                            onSubmit={(data) => onRegionSubmit(data)}
                        />
                    </BasicModal>
                )}
                {edit && (
                    <BasicModal open={edit} onClose={() => toggleEdit()} title="Edit Region" autoSizeHeader>
                        <AddRegionForm
                            loading={loading}
                            getRegion={editedRegion}
                            onCancelClick={() => toggleEdit()}
                            onRemoveClick={() => toggleRemove()}
                            onSubmit={(data) => onRegionSubmit(data, 'edit')}
                        />
                    </BasicModal>
                )}
                {remove && (
                    <ConfirmModal
                        open={remove}
                        onClose={() => toggleRemove()}
                        title="Remove region"
                        text="Are you sure you want to remove this region?"
                        button={removeRegionButton}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    regionsPage: state.regionsPage
});

const mapDispatchToProps = {
    getRegionsListRequest,
    onSubmitRegionRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionsPage);
