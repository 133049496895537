import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import {
    getRegionsListRequest,
    getRegionsListSuccess,
    getRegionsListFailed,
    onSubmitRegionSuccess,
    onSubmitRegionFailed
} from '../../../actions';
import { selectItemsPerPage } from './selectors';
import * as regionsListActionTypes from '../../../actions/admin-view/regions-page/action-types';
import api from '../../../../services/api';

function* onRegionsListRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page } = payload;

    try {
        const { data } = yield call(api.request, {
            url: '/regions/list',
            method: 'post',
            payload: {
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getRegionsListSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getRegionsListFailed());
    }
}

function* onSubmitRegionRequest({ payload: { apiUrl, method, regionTitle, page, toggleModal } }) {
    try {
        yield call(api.request, {
            url: apiUrl,
            method,
            payload: {
                title: regionTitle
            }
        });
        yield put(onSubmitRegionSuccess());
        toggleModal();
        yield put(getRegionsListRequest({ page }));
    } catch (err) {
        yield put(onSubmitRegionFailed());
    }
}

// watchers
function* watchRegionsListRequest() {
    yield takeEvery(regionsListActionTypes.GET_REGIONS_LIST_REQUEST, onRegionsListRequest);
}

function* watchSubmitRegionRequest() {
    yield takeEvery(regionsListActionTypes.ON_SUBMIT_REGION_REQUEST, onSubmitRegionRequest);
}

const regionsPageSaga = [fork(watchRegionsListRequest), fork(watchSubmitRegionRequest)];

export default regionsPageSaga;
