import { SignupPage } from './SignupPage';
import { connect } from 'react-redux';
import { pharmacySignupRequest, locumSignupRequest, registerProcessOff } from '../../../store/actions';

export default connect(
    (state) => ({
        loading: state.signupPage.loading
    }),
    {
        pharmacySignupRequest,
        locumSignupRequest,
        registerProcessOff
    }
)(SignupPage);
