import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'semantic-ui-react';
import Button from '../../UI/buttons/button';

import './style.scss';

const ConfirmModal = ({
    size = 'tiny',
    open,
    onClose,
    closeIcon = true,
    title,
    text,
    button,
    renderForm,
    showButton = true,
    showCancelButton = false,
    cancelButton
}) => {
    return (
        <Modal closeIcon={closeIcon} size={size} open={open} onClose={onClose} className="confirm-modal">
            <Modal.Header className="confirm-modal__header">{title}</Modal.Header>
            <Modal.Content>
                <p className="content-text">{text}</p>
                {renderForm && renderForm()}
                {showButton && !showCancelButton && (
                    <Button
                        size={button.size}
                        value={button.value}
                        loading={button.loading}
                        disabled={button.disabled}
                        onClick={button.onClick}
                    />
                )}
                {showButton && showCancelButton && (
                    <div className="confirm-and-cancel-buttons">
                        <Button
                            size={button.size}
                            value={button.value}
                            loading={button.loading}
                            disabled={button.disabled}
                            onClick={button.onClick}
                        />
                        <Button
                            type="cancel"
                            size={cancelButton.size}
                            value={cancelButton.value}
                            loading={cancelButton.loading}
                            disabled={cancelButton.disabled}
                            onClick={cancelButton.onClick}
                        />
                    </div>
                )}
            </Modal.Content>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    text: PropTypes.string.isRequired,
    button: PropTypes.object.isRequired,
    renderForm: PropTypes.func,
    showButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    cancelButton: PropTypes.object
};

export default ConfirmModal;
