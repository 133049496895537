import { SkillsForm } from './SkillsForm';
import { connect } from 'react-redux';
import {
    setStoreDocument,
    removeStoreDocument,
    setSkillsData,
    setSkillsStoreDocument,
    removeSkillsDocument,
    setSkillsCheckbox
} from '../../../../store/actions';

export default connect(
    (state) => ({
        skills: state.userProfileInfo.skills.data,
        removingLoading: state.userProfileInfo.loading,
        loading: state.userProfileInfo.skills.loading,
        pharmacySystemsOptions: state.profileAdditionalInfoData.pharmacySystems,
        previouslySelectedSystems: state.userProfileInfo.skills.data.pharmacySystems
    }),
    {
        setStoreDocument,
        removeStoreDocument,
        setSkillsData,
        setSkillsStoreDocument,
        removeSkillsDocument,
        setSkillsCheckbox
    }
)(SkillsForm);
