import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TableComponent from '../../../../../../components/table';
import PageNav from '../../../../../../components/page-nav';

import { getPharmacyStaffRequest } from '../../../../../../store/actions';
import { usePage } from '../../../../../../services/hooks';

const PharmacyStaffList = ({
    pharmacyStaff: { data, itemsPerPage, totalData },
    getPharmacyStaffRequest,
    match: {
        params: { id }
    }
}) => {
    const page = usePage();

    useEffect(() => {
        if (!page) return;
        getPharmacyStaffRequest({ page, id });
    }, [getPharmacyStaffRequest, page, id]);

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'id',
                title: 'staff id',
                render: (item) => item.id,
                type: 'disabled'
            },
            {
                name: 'name',
                title: 'first name',
                render: (item) => item.name,
                type: 'disabled'
            },
            {
                name: 'surname',
                title: 'last name',
                render: (item) => item.surname,
                type: 'disabled'
            },
            {
                name: 'email',
                title: 'email',
                render: (item) => item.email,
                type: 'disabled'
            },
            {
                name: 'phone',
                title: 'phone',
                render: (item) => item.phone,
                type: 'disabled'
            },
            {
                name: 'status',
                title: 'status',
                render: (item) => item.status,
                type: 'disabled'
            }
        ];

        return (
            <div className="admin-pharmacies-page">
                <PageNav title="Staff" />
                <TableComponent
                    cols={tableCols}
                    data={data.rows}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    pharmacyStaff: state.adminPharmacyStaffPage
});

const mapDispatchToProps = {
    getPharmacyStaffRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyStaffList);
