import { JobPreview } from './JobPreview';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions';

export default connect(
    (state) => ({
        jobPreviewPage: state.jobPreviewPage,
        locumData: state.locumData,
        operatingTime: state.branchInfoPage.branchInfo.data.operatingTime,
        locums: state.postJobForm.data,
        mileageRules: state.pharmacySettingsPage.mileageRules,
        transportRules: state.pharmacySettingsPage.transportRules,
        parkingRules: state.pharmacySettingsPage.parkingRules,
        accommodationRules: state.pharmacySettingsPage.accommodationRules
    }),
    {
        togglePageView: actions.togglePageView,
        getJobDetailsRequest: actions.getJobDetailsRequest,
        getWorkedLocumsRequest: actions.getWorkedLocumsRequest,
        onEditJobRequest: actions.onEditJobRequest,
        onCancelJobRequest: actions.onCancelJobRequest,
        onLocumActionRequest: actions.onLocumActionRequest,
        getJobCancelReasonsRequest: actions.getJobCancelReasonsRequest,
        getLocumByGphcRequest: actions.getLocumByGphcRequest,
        clearLocumData: actions.clearLocumData,
        getJobLocumsByFilterRequest: actions.getJobLocumsByFilterRequest,
        clearGetJobLocumsByFilter: actions.clearGetJobLocumsByFilter,
        getAllMileageRulesRequest: actions.getAllMileageRulesRequest,
        getAllTransportRulesRequest: actions.getAllTransportRulesRequest,
        getAllParkingRulesRequest: actions.getAllParkingRulesRequest,
        getAllAccommodationRulesRequest: actions.getAllAccommodationRulesRequest,
        onPostJobBulkRequest: actions.onPostJobBulkRequest
    }
)(JobPreview);
