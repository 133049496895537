import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Header from '../../../components/header';
import InviteBasicLoginForm from '../../../components/forms/invite-basic-login-form';
import { RouterConfig } from '../../../routerConfig';

import { useDispatch, useSelector } from 'react-redux';
import {
    getInvitedStaffInfoRequest,
    onConfirmIdentityRequest,
    onInvitedStaffLoginRequest,
    onCancelGphcVerificationRequest
} from '../../../store/actions';

import './style.scss';

function InvitePage(props) {
    const { id } = useParams();
    const history = useHistory();
    const [onboardStep, setOnboardStep] = useState('welcome');
    const dispatch = useDispatch();
    const { user, userProfile, loading } = useSelector((state) => state.staffInvitePage);

    useEffect(() => {
        dispatch(getInvitedStaffInfoRequest(id));
    }, [dispatch, id]);

    const onJoinTeam = () => setOnboardStep('confirm');

    const onConfirmIdentity = useCallback(
        (gphcNumber) => {
            dispatch(
                onConfirmIdentityRequest(id, {
                    gphcNumber,
                    toggleModal: () => setOnboardStep('profile')
                })
            );
        },
        [dispatch, id]
    );

    const onCorrectProfile = () => setOnboardStep('login');

    const onLogin = useCallback(
        (recievedData) => {
            dispatch(
                onInvitedStaffLoginRequest({
                    id,
                    recievedData,
                    redirect: () => history.push(RouterConfig.manager.personalInfo)
                })
            );
        },
        [dispatch, history, id]
    );

    const onCancelInvite = () => {
        dispatch(onCancelGphcVerificationRequest({ id }));
        history.push('/login?error=verificationDeclined');
    };

    return (
        <div className="invite-page">
            <Header />
            <InviteBasicLoginForm
                loading={loading}
                user={user}
                onboardStep={onboardStep}
                onJoinTeam={onJoinTeam}
                onConfirmIdentity={onConfirmIdentity}
                onCorrectProfile={onCorrectProfile}
                onLogin={onLogin}
                onCancelInvite={onCancelInvite}
                userProfile={userProfile}
            />
        </div>
    );
}

export default InvitePage;
