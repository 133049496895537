import React from 'react';
import PropTypes from 'prop-types';
import closeIconBlack from '../../../../assets/icons/close-icon.svg';
import closeIconWhite from '../../../../assets/icons/close-icon-white.svg';

import './style.scss';

const CloseButton = ({ color = 'black', size = 'small', onClick }) => {
    return (
        <div role="button" onClick={onClick} className={`close-button ${size}`}>
            <img src={color === 'black' ? closeIconBlack : closeIconWhite} alt="close icon" />
            {/* <i className="close icon"></i> */}
        </div>
    );
};

CloseButton.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default CloseButton;
