import StaffList from './StaffList';
import { connect } from 'react-redux';
import {
    getStaffListRequest,
    onStaffFilterChange,
    getInvitesDataRequest,
    onInviteStaffRequest,
    clearStaffFilters
} from '../../../../../store/actions';

export default connect(
    (state) => ({
        branchId: state.auth.user.id,
        staffPage: state.staffListPage,
        inviteStaff: state.inviteStaffForm
    }),
    {
        getStaffListRequest,
        onStaffFilterChange,
        getInvitesDataRequest,
        onInviteStaffRequest,
        clearStaffFilters
    }
)(StaffList);
