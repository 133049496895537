import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'semantic-ui-react';

import TextInput from '../../UI/inputs/text-input';
import TextAreaComponent from '../../UI/inputs/text-area';
import ButtonModal from '../../UI/buttons/button-modal';
import Timepicker from '../../UI/timepicker';
import CustomCheckbox from '../../UI/checkbox';
import Datepicker from '../../UI/datepicker';
import SelectComponent from '../../UI/select/SelectComponent';

import { digitsNumberError, emailError, phoneError, salaryError, textAreaError } from '../../../services/validate';
import { getDuration, getDurationFormat, parseTime } from '../../../services/datesHelper';
import { getTotalRate } from '../../../services/helpers';
import { JOB } from '../../../services/constants';

import './style.scss';
import ExpenceCoverageForm from './expence-coverage-form/ExpenceCoverageForm';

const TIME_FORMAT = 'HH:mm';

const FormType = {
    Job: 'JOB',
    Expence: 'EXPENCE'
};

// TODO remove locumData from parent components
class PostJobForm extends Component {
    state = {
        startDate: !this.props.defaultDate ? null : new Date(this.props.defaultDate),
        endDate: !this.props.defaultDate ? null : new Date(this.props.defaultDate),
        selectBranch: '',
        timeStart: null,
        timeFinish: null,
        lunchStartTime: null,
        lunchEndTime: null,
        lunchDuration: 0,
        duration: '',
        paidLunch: false,
        externalLocumBooking: false,
        gphcNumber: '',
        email: '',
        phone: '',
        hourlyRate: '',
        description: '',
        status: '',
        IR35: false,
        userSearchKeyword: '',
        selectedUser: null,
        mileageRuleId: null,
        hasExpences: false,
        formType: FormType.Job,
        expences: null,
        validate: {
            hourlyRate: false
        },
        errors: {
            hourlyRate: null
        }
    };

    timeout;

    componentDidMount() {
        this._isMounted = true;
        const { jobData } = this.props;
        if (jobData) {
            this.processJobData(jobData);
        }
        this.props.onLocumsInitialGet();
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.timeout) clearTimeout(this.timeout);

        this.props.clearGetJobLocumsByFilter();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        const { operatingTime } = this.props;
        const { startDate, endDate } = this.state;
        const { startDate: prevStartDate } = prevState;

        if (this.timeout) clearTimeout(this.timeout);

        if (prevStartDate && startDate && !moment(startDate).isSame(prevStartDate)) {
            const dayOfWeek = moment(startDate).format('dddd').toLowerCase();
            const currOperatingTime = operatingTime.find((time) => time.dayOfWeek === dayOfWeek);
            this.processJobData({
                startDate: moment(startDate).startOf('day').format('YYYY-MM-DD'),
                endDate: moment(moment(startDate).isAfter(endDate) ? startDate : endDate)
                    .startOf('day')
                    .format('YYYY-MM-DD'),
                endTime: currOperatingTime ? currOperatingTime.endTime : null,
                lunchEndTime: currOperatingTime ? currOperatingTime.lunchEndTime : null,
                lunchStartTime: currOperatingTime ? currOperatingTime.lunchStartTime : null,
                startTime: currOperatingTime ? currOperatingTime.startTime : null
            });
        }
    };

    processJobData = (jobData) => {
        let {
            startDate = moment().startOf('day').format('YYYY-MM-DD'),
            endDate = moment().startOf('day').format('YYYY-MM-DD'),
            startTime = null,
            lunchEndTime = null,
            lunchStartTime = null,
            endTime = null,
            paidLunch = this.state.paidLunch,
            hourlyRate = this.state.hourlyRate,
            description = this.state.description,
            status = JOB.PENDING,
            mileageRuleId,
            parkingRuleId,
            transportRuleId,
            accommodationAndFoodRuleId
        } = jobData;

        let expences = null;

        if (mileageRuleId || parkingRuleId || transportRuleId || accommodationAndFoodRuleId) {
            expences = {
                mileageRuleId,
                parkingRuleId,
                transportRuleId,
                accommodationAndFoodRuleId
            };
        }

        const timeStart = startTime ? moment(parseTime(startTime)) : startTime;
        const timeFinish = endTime ? moment(parseTime(endTime)) : endTime;

        lunchStartTime = lunchStartTime ? moment(parseTime(lunchStartTime)) : lunchStartTime;
        lunchEndTime = lunchEndTime ? moment(parseTime(lunchEndTime)) : lunchEndTime;
        this.setState({
            startDate: startDate && new Date(`${startDate} 00:00:00`),
            endDate: endDate && new Date(`${endDate} 00:00:00`),
            timeStart,
            timeFinish,
            lunchStartTime,
            lunchEndTime,
            duration: getDuration(timeStart, timeFinish),
            lunchDuration: getDuration(lunchStartTime, lunchEndTime) || 0,
            paidLunch: !!paidLunch,
            hourlyRate: String(hourlyRate || ''),
            description,
            status,
            expences,
            hasExpences: !!expences
        });
    };

    onStatTimeChange = (time, timeStart, timeFinish, duration) => {
        time.set({ second: 0 });
        let setDuration = timeFinish ? getDuration(time, timeFinish) : '';
        this.setState({ [timeStart]: time, [duration]: setDuration });
    };

    onFinishTimeChange = (time, timeFinish, timeStart, duration) => {
        time.set({ second: 0 });
        let setDuration = timeStart ? getDuration(timeStart, time) : '';
        this.setState({ [timeFinish]: time, [duration]: setDuration });
    };

    onCheckboxChangeHandler = (e, { name }) => {
        this.setState((prevState) => ({
            [name]: !prevState[name],
            gphcNumber: '',
            email: '',
            phone: ''
        }));
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () => {
                this.validate(name);
            }
        );
    };

    onLocumGet() {
        const { startDate, endDate, timeStart, timeFinish, userSearchKeyword } = this.state;
        this.props.onGphcChange({
            gphcNumber: isNaN(parseInt(userSearchKeyword)) ? null : userSearchKeyword,
            name: userSearchKeyword,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            startTime: timeStart ? timeStart.format('HH:mm') : null,
            endTime: timeFinish ? timeFinish.format('HH:mm') : null
        });
    }

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'hourlyRate':
                error = salaryError(value);
                break;
            case 'description':
                error = value ? textAreaError(value) : null;
                break;
            case 'gphcNumber':
                error = value ? digitsNumberError(value) : null;
                break;
            case 'email':
                error = value ? emailError(value) : null;
                break;
            case 'phone':
                error = value ? phoneError(value) : null;
                break;

            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onSubmitClickHandler = (e) => {
        e.preventDefault();
        this.postJob();
    };

    postJob() {
        if (this.state.hasExpences && this.state.formType === FormType.Job) {
            this.setState({
                formType: FormType.Expence
            });
            return;
        }
        const { onSubmitClick, isEdit } = this.props;

        const {
            startDate,
            endDate,
            timeStart,
            timeFinish,
            paidLunch,
            description,
            hourlyRate,
            externalLocumBooking,
            selectedUser,
            email,
            phone,
            status,
            expences,
            hasExpences,
            lunchStartTime,
            lunchEndTime
        } = this.state;
        const isEditable = status === JOB.UNFILLED || status === JOB.PENDING;

        const data = {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            startTime: timeStart ? timeStart.format('HH:mm') : null,
            endTime: timeFinish ? timeFinish.format('HH:mm') : null,
            lunchEndTime: lunchEndTime && paidLunch ? lunchEndTime.format('HH:mm') : null,
            lunchStartTime: lunchStartTime && paidLunch ? lunchStartTime.format('HH:mm') : null,
            description,
            hourlyRate,
            paidLunch,
            mileageRuleId: hasExpences ? parseInt(expences.mileageRuleId) || null : null,
            transportRuleId: hasExpences ? parseInt(expences.transportRuleId) || null : null,
            parkingRuleId: hasExpences ? parseInt(expences.parkingRuleId) || null : null,
            accommodationAndFoodRuleId: hasExpences ? parseInt(expences.accommodationAndFoodRuleId) || null : null,
            hasExternalLocum: externalLocumBooking
        };

        if (isEditable && externalLocumBooking) {
            data['externalLocumBooking'] = {
                gphcNumber: selectedUser.gphcNumber,
                email,
                phone
            };
        }
        onSubmitClick(data, isEdit);
    }

    onCancelClickHandler = (e) => {
        e.preventDefault();
        const { shiftDate, isTemplateShift } = this.state;
        const { onCancelClick, onCloseClick, isEdit } = this.props;
        const data = {
            isTemplateShift,
            date: moment(shiftDate).format('YYYY-MM-DD')
        };
        isEdit ? onCancelClick(data) : onCloseClick();
    };

    onLocumsSearch = (e) => {
        this.setState({
            userSearchKeyword: e.target.value
        });
    };

    getLocumName = (locum) => {
        let name = '';

        if (locum.name) {
            name = `${locum.name} ${locum.surname || ''}`;
        } else {
            name = `${locum.forenames || ''} ${locum.surname || ''}`;
        }

        return name;
    };

    onExpencesSubmit(expences) {
        this.setState(
            {
                expences
            },
            this.postJob
        );
    }

    onExpencesCancel = () => {
        this.setState({
            formType: FormType.Job,
            expences: this.props.isEdit ? this.state.expences : null
        });
    };

    render() {
        const {
            startDate,
            endDate,
            timeStart,
            timeFinish,
            paidLunch,
            duration,
            hourlyRate,
            description,
            externalLocumBooking,
            email,
            phone,
            lunchStartTime,
            lunchEndTime,
            lunchDuration,
            selectedUser,
            userSearchKeyword,
            errors,
            status,
            hasExpences,
            formType
        } = this.state;
        const { loading, isEdit, calledFromShift } = this.props;

        const displayShiftDuration = getDurationFormat(duration);
        const displayLunchDuration = getDurationFormat(lunchDuration);

        const branchesDropdown = calledFromShift ? null : (
            <div className="content-row__item">
                <p className="title">Branches</p>
                <div className="inner">
                    <SelectComponent
                        name="selectBranch"
                        icon="caret down"
                        floating
                        onChange={this.onChangeHandler}
                        options={[]}
                    />
                </div>
            </div>
        );

        const getTotalAmount = getTotalRate(
            startDate,
            endDate,
            hourlyRate,
            paidLunch ? duration : duration - lunchDuration
        );

        const isDisabled = status === JOB.PAID || status === JOB.PAYMENT_APPROVED;
        const canBeAdjusted = status === JOB.COMPLETED || status === JOB.ACTIVE;
        const isEditable = status === JOB.UNFILLED || status === JOB.PENDING;
        const lunchExist = this.props.operatingTime.find((ot) => !!ot.lunchStartTime);

        const isGphcNumberSearch = !isNaN(userSearchKeyword) && Number.isInteger(parseFloat(userSearchKeyword)); //Number.isFinite(userSearchKeyword);
        const typeOptions = this.props.locums.map((locum) => {
            const image = locum.userProfile && locum.userProfile.image ? locum.userProfile.image : null;
            const locumName = this.getLocumName(locum);
            const value = selectedUser || !isGphcNumberSearch ? locumName : locum.gphcNumber;
            return {
                value: value,
                key: locum.gphcNumber,
                text: value,
                content: (
                    <div className="option-inner">
                        <span className="option-inner__name">{locumName}</span>
                        <span className="option-inner__role">{locum.gphcNumber}</span>
                    </div>
                ),
                image: { avatar: !!image, src: image }
            };
        });

        if (formType === FormType.Expence) {
            const { mileageRules, transportRules, parkingRules, accommodationRules } = this.props;
            return (
                <ExpenceCoverageForm
                    onSubmit={this.onExpencesSubmit.bind(this)}
                    onCancel={this.onExpencesCancel}
                    mileageRules={mileageRules || []}
                    transportRules={transportRules || []}
                    parkingRules={parkingRules || []}
                    accommodationRules={accommodationRules || []}
                    submitLoading={loading}
                    savedExpences={this.state.expences}
                />
            );
        }

        return (
            <Form className="post-job-form" onSubmit={this.onSubmitClickHandler}>
                <div className="content-row">
                    {branchesDropdown}
                    <div className="content-row__item">
                        <div className="inner">
                            <Datepicker
                                name="startDate"
                                value={startDate}
                                label="Starting Date"
                                onChange={this.onChangeHandler}
                                format="DD/MM/YY"
                                minDate={
                                    !this.props.defaultDate
                                        ? new Date(Date.now() - 864e5)
                                        : new Date(this.props.defaultDate)
                                } // 864e5 == 86400000 == 24*60*60*1000
                                disabled={loading || isDisabled || canBeAdjusted}
                                fluid={false}
                            />
                            <Datepicker
                                name="endDate"
                                value={endDate}
                                label="Finish Date"
                                onChange={this.onChangeHandler}
                                minDate={startDate}
                                format="DD/MM/YY"
                                disabled={loading || isDisabled || canBeAdjusted}
                                fluid={false}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <p className="title">Operating Hours</p>
                        <div className="inner">
                            <Timepicker
                                size="large"
                                onChange={(time) => this.onStatTimeChange(time, 'timeStart', timeFinish, 'duration')}
                                format={TIME_FORMAT}
                                minuteStep={15}
                                value={timeStart}
                                disabled={loading || isDisabled}
                            />
                            <p className="duration">{displayShiftDuration}</p>
                            <Timepicker
                                size="large"
                                onChange={(time) => this.onFinishTimeChange(time, 'timeFinish', timeStart, 'duration')}
                                format={TIME_FORMAT}
                                minuteStep={15}
                                value={timeFinish}
                                disabled={loading || isDisabled}
                            />
                        </div>
                    </div>
                    <>
                        <div className="content-row__item">
                            <CustomCheckbox
                                name="paidLunch"
                                label="Paid Lunch"
                                checked={paidLunch}
                                onChange={this.onCheckboxChangeHandler}
                                disabled={isDisabled || !lunchExist}
                            />
                        </div>
                        <div className="content-row__item">
                            <div className="inner">
                                <Timepicker
                                    size="large"
                                    onChange={(time) =>
                                        this.onStatTimeChange(time, 'lunchStartTime', lunchEndTime, 'duration')
                                    }
                                    format={TIME_FORMAT}
                                    minuteStep={15}
                                    value={lunchStartTime}
                                    disabled={true}
                                />
                                <p className="duration">{displayLunchDuration}</p>
                                <Timepicker
                                    size="large"
                                    onChange={(time) =>
                                        this.onFinishTimeChange(time, 'lunchEndTime', lunchStartTime, 'duration')
                                    }
                                    format={TIME_FORMAT}
                                    minuteStep={15}
                                    value={lunchEndTime}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </>
                    <div className="content-row__item">
                        <div className="inner hourly-rate">
                            <div>
                                <p className={`title ${isEdit ? 'disabled' : ''}`}>Hourly Rate</p>
                                <TextInput
                                    name="hourlyRate"
                                    type="text"
                                    value={hourlyRate}
                                    fluid={false}
                                    error={!!errors.hourlyRate}
                                    helperText={errors.hourlyRate}
                                    onChange={this.onChangeHandler}
                                    disabled={loading || isDisabled || canBeAdjusted}
                                />
                            </div>
                            <p className="total-amount">{`Total: £${isNaN(getTotalAmount) ? '' : getTotalAmount}`}</p>
                            <CustomCheckbox
                                name="expenses"
                                label="Cover expenses"
                                checked={hasExpences}
                                onChange={() => this.setState({ ...this.state, hasExpences: !hasExpences })}
                                disabled={['paid', 'payment_approved'].includes(status) || isDisabled || isEdit}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <p className={`title ${isEdit ? 'disabled' : ''}`}>Description</p>
                        <div className="inner">
                            <TextAreaComponent
                                name="description"
                                value={description}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={this.onChangeHandler}
                                disabled={loading || isDisabled || canBeAdjusted}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <CustomCheckbox
                            name="externalLocumBooking"
                            label="Assign Locum"
                            checked={externalLocumBooking}
                            onChange={this.onCheckboxChangeHandler}
                            disabled={loading || isDisabled || canBeAdjusted}
                        />
                    </div>
                    {isEditable && externalLocumBooking && (
                        <>
                            <div className="content-row__item">
                                <SelectComponent
                                    name="userSearchKeyword"
                                    label="GPhC/Name"
                                    search
                                    onSearchChange={(syntheticEvent, eventObj) => {
                                        this.setState(
                                            {
                                                userSearchKeyword: eventObj.searchQuery,
                                                selectedUser: null,
                                                email: '',
                                                phone: ''
                                            },
                                            () => {
                                                this.onLocumGet();
                                            }
                                        );
                                    }}
                                    placeholder="GPhC/Name"
                                    icon="caret down"
                                    floating
                                    value={selectedUser ? this.getLocumName(selectedUser) : 0}
                                    onChange={(_, eventObj) => {
                                        const matchUser = this.props.locums.find(
                                            (locum) =>
                                                locum.gphcNumber === eventObj.value ||
                                                this.getLocumName(locum) === eventObj.value
                                        );
                                        this.setState({
                                            selectedUser: matchUser,
                                            email: matchUser.email || '',
                                            phone: matchUser.userProfile ? matchUser.userProfile.phone || '' : ''
                                        });
                                    }}
                                    options={typeOptions}
                                />
                            </div>

                            <div className="content-row__item">
                                <div className="inner">
                                    <TextInput
                                        name="email"
                                        type="text"
                                        label="Email"
                                        value={email}
                                        fluid={false}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        onChange={this.onChangeHandler}
                                        disabled={loading || isDisabled || canBeAdjusted}
                                    />
                                    <p className="duration" />
                                    <TextInput
                                        name="phone"
                                        type="text"
                                        label="Phone"
                                        value={phone}
                                        fluid={false}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        onChange={this.onChangeHandler}
                                        disabled={loading || isDisabled || canBeAdjusted}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="buttons-row double">
                    <ButtonModal
                        value={isEdit ? 'Cancel' : 'Close'}
                        colorType="red"
                        // loading={removingLoading}
                        onClick={this.onCancelClickHandler}
                    />
                    <ButtonModal
                        value={hasExpences ? 'Next' : isEdit ? 'Edit' : 'Post'}
                        colorType="blue"
                        type="submit"
                        disabled={
                            !startDate ||
                            !endDate ||
                            duration < 60 ||
                            !!errors.hourlyRate ||
                            !hourlyRate ||
                            !description ||
                            (isEditable && externalLocumBooking && (!selectedUser || !email || !phone))
                        }
                        loading={loading}
                    />
                </div>
            </Form>
        );
    }
}

PostJobForm.propTypes = {
    defaultDate: PropTypes.object,
    staffList: PropTypes.array,
    calledFromShift: PropTypes.bool,
    jobData: PropTypes.object,
    isEdit: PropTypes.bool,
    loading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    onSubmitClick: PropTypes.func.isRequired,
    onGphcChange: PropTypes.func,
    locums: PropTypes.array,
    operatingTime: PropTypes.array
};

PostJobForm.defaultProps = {
    operatingTime: [],
    locums: []
};

export default PostJobForm;
