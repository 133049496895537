import React, { useState, useEffect } from 'react';
import TableComponent from '../../../../components/table';
import PageNav from '../../../../components/page-nav';
import { useSelector, useDispatch } from 'react-redux';
import { usePage } from '../../../../services/hooks';
import BasicModal from '../../../../components/modals/basic-modal';
import { Redirect, useHistory } from 'react-router';
import LocumBankAddLocumForm from '../../../../components/forms/locum-bank-add-locum-form';
import { getLocumsByGphcClear } from '../../../../store/actions';
import { RouterConfig } from '../../../../routerConfig';
import { generatePath } from 'react-router';
import moment from 'moment';
import StarsRating from '../../../../components/stars-rating';

function LocumBankPage({ getLocumBankByFilterRequest, addLocumBankRequest, locumBanks }) {
    const page = usePage();
    const dispatch = useDispatch();
    const history = useHistory();
    const { data, totalData, itemsPerPage } = useSelector((state) => state.locumBankPage);

    const [modalOpen, setModalOpen] = useState(false);
    const [filter, setFilter] = useState({
        name: '',
        phone: '',
        gphcNumber: '',
        dob: null,
        city: '',
        rating: ''
    });

    useEffect(() => {
        if (!page) return;
        const timeout = setTimeout(() => {
            getLocumBankByFilterRequest({
                pagination: {
                    page,
                    perPage: itemsPerPage
                },
                filters: filter
            });
        }, 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [getLocumBankByFilterRequest, filter, page]);

    const handleCloseModal = () => {
        if (modalOpen) {
            dispatch(getLocumsByGphcClear());
        }
        setModalOpen(!modalOpen);
    };

    const onSubmit = (formData) => {
        addLocumBankRequest(formData);
        setModalOpen(false);
    };

    const getFormattedUserName = (user) => {
        let name = '';

        if (user.name) {
            name = `${user.name} ${user.surname || ''}`;
        } else {
            name = `${user.forenames || ''} ${user.surname || ''}`;
        }

        return name;
    };

    const tableCols = [
        {
            name: 'locumName',
            title: 'Locum Name',
            value: filter.name,
            render: (item) => getFormattedUserName(item.locum),
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: (_, eventObj) => {
                setFilter({ ...filter, name: eventObj.value });
            }
        },
        {
            name: 'phone',
            title: 'Phone',
            value: filter.phone,
            render: (item) => item.locum.userProfile.phone,
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: (_, eventObj) => setFilter({ ...filter, phone: eventObj.value })
        },
        {
            name: 'gphcNumber',
            title: 'GPHC ID',
            value: filter.gphcNumber,
            render: (item) => item.locum.gphcNumber,
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: (_, eventObj) => setFilter({ ...filter, gphcNumber: eventObj.value })
        },
        {
            name: 'dob',
            title: 'Date of birth',
            value: filter.dob ? new Date(filter.dob) : '',
            render: (item) =>
                item.locum.userProfile.dob ? moment(item.locum.userProfile.dob).format('MMM Do, YYYY') : '',
            className: 'class-postedJob-jobId',
            type: 'date',
            onChange: (_, eventObj) => {
                setFilter({
                    ...filter,
                    dob: eventObj.value ? moment(new Date(eventObj.value)).format('YYYY-MM-DD') : ''
                });
            }
        },
        {
            name: 'city',
            title: 'city',
            value: filter.city,
            render: (item) => item.locum.userProfile.city,
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: (_, eventObj) => setFilter({ ...filter, city: eventObj.value })
        },
        {
            name: 'rating',
            title: 'Rating',
            value: filter.rating,
            render: (item) => {
                return <StarsRating value={item.locum.rating} />;
            },
            className: 'class-postedJob-jobId',
            type: 'search',
            onChange: (_, eventObj) => setFilter({ ...filter, rating: eventObj.value })
        }
    ];

    const table = (
        <TableComponent
            cols={tableCols}
            data={data.rows || []}
            totalItems={totalData}
            itemsPerPage={itemsPerPage}
            page={Number(page)}
            openInfo={() => history.push(generatePath(RouterConfig.branchAdminLocumBank))}
        />
    );

    return (
        <div className="staff-admin-page">
            {!page && <Redirect from="/locum-bank" to="/locum-bank?page=1" exact />}
            <div className="main-container">
                <PageNav
                    title="Locum Bank"
                    inputs={[
                        {
                            componentType: 'button',
                            value: 'Add Locum',
                            onClick: () => setModalOpen(true),
                            size: 'medium',
                            width: 'medium'
                        }
                    ]}
                />
                {table}
            </div>
            {modalOpen && (
                <BasicModal open={true} onClose={handleCloseModal} title="Add Locum">
                    <LocumBankAddLocumForm onClose={handleCloseModal} onSubmit={onSubmit} />
                </BasicModal>
            )}
        </div>
    );
}

export default LocumBankPage;
