import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './style.scss';
import ButtonComponent from '../../../../../components/UI/buttons/button';
import BasicModal from '../../../../../components/modals/basic-modal';
import CreateMileageRuleForm from './create-mileage-rule-form';
import SectionRuleList from './view/SectionRuleList';
import CreateParkingRuleForm from './create-parking-rule-form/CreateParkingRuleForm';
import CreateTransportRuleForm from './create-transport-rule-form';
import CreateAccommodationAndFoodRuleForm from './create-accommodation-and-food-rule-form/CreateAccommodationAndFoodRuleForm';
import Datepicker from '../../../../../components/UI/datepicker/Datepicker';
import moment from 'moment';
import { Input } from 'semantic-ui-react';

const RuleType = {
    Mileage: 'MILEAGE',
    Parking: 'PARKING',
    Transport: 'TRANSPORT',
    Accommodation: 'ACCOMMODATIONANDFOODS'
};

const initState = {
    modalOpen: false,
    editableRule: null,
    type: ''
};

const sections = [
    {
        type: RuleType.Mileage,
        title: 'Mileage',
        cols: ['RULE NAME', 'RATE', 'PAY AFTER', 'MAX MILEAGE']
    },
    {
        type: RuleType.Transport,
        title: 'Transport',
        cols: ['RULE NAME', 'PAY OVER', 'UP TO']
    },
    {
        type: RuleType.Accommodation,
        title: 'Accommodation&Food',
        cols: ['RULE NAME', 'FOOD COVERAGE', 'PAY OVER', 'UP TO']
    },
    {
        type: RuleType.Parking,
        title: 'Parking',
        cols: ['RULE NAME', 'UP TO']
    }
];

export function PharmacySettingsPage({
    createMileageRuleRequest,
    createParkingRuleRequest,
    createAccommodationRuleRequest,
    createTransportRuleRequest,
    getAllMileageRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    getAllTransportRulesRequest,
    mileageRuleDeleteRequest,
    parkingRuleDeleteRequest,
    accommodationRuleDeleteRequest,
    transportRuleDeleteRequest,
    mileageRules,
    masterDate,
    parkingRules,
    transportRules,
    accommodationRules,
    getMasterDateRequest,
    updateMasterDateRequest
}) {
    const [ruleOptions, setRuleOptions] = useState(initState);
    const [editableMasterDate, setEditableMasterDate] = useState({
        masterDate: null,
        planningPeriod: null
    });

    useEffect(() => {
        if (!masterDate) return;
        setEditableMasterDate({
            masterDate: getConvertedMasterDate(masterDate.masterDate),
            planningPeriod: masterDate.planningPeriod
        });
    }, [masterDate]);

    useEffect(() => {
        if (editableMasterDate.masterDate === null && editableMasterDate.planningPeriod === null) return;
        const timeout = setTimeout(() => {
            updateMasterDateRequest({
                ...editableMasterDate,
                masterDate: editableMasterDate.masterDate
                    ? moment(editableMasterDate.masterDate).format('YYYY-MM-DD')
                    : null
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [editableMasterDate, updateMasterDateRequest]);

    useEffect(() => {
        if (
            !getAllMileageRulesRequest ||
            !getAllAccommodationRulesRequest ||
            !getAllParkingRulesRequest ||
            !getMasterDateRequest ||
            !getAllTransportRulesRequest
        )
            return;
        getAllMileageRulesRequest();
        getAllAccommodationRulesRequest();
        getAllParkingRulesRequest();
        getAllTransportRulesRequest();
        getMasterDateRequest();
    }, [
        getAllMileageRulesRequest,
        getAllAccommodationRulesRequest,
        getAllParkingRulesRequest,
        getMasterDateRequest,
        getAllTransportRulesRequest
    ]);

    const openCreateRuleModal = useCallback(
        (type) => () => {
            setRuleOptions({
                ...ruleOptions,
                modalOpen: true,
                editableRule: null,
                type
            });
        },
        [ruleOptions]
    );

    const onRuleCreateSubmit = useCallback(
        (data) => {
            switch (ruleOptions.type) {
                case RuleType.Mileage:
                    createMileageRuleRequest(data);
                    break;
                case RuleType.Parking:
                    createParkingRuleRequest(data);
                    break;
                case RuleType.Transport:
                    createTransportRuleRequest(data);
                    break;
                case RuleType.Accommodation:
                    createAccommodationRuleRequest(data);
                    break;
                default:
                    return null;
            }
            setRuleOptions(initState);
        },
        [
            createAccommodationRuleRequest,
            createMileageRuleRequest,
            createParkingRuleRequest,
            createTransportRuleRequest,
            ruleOptions.type
        ]
    );

    const onEdit = useCallback(
        ({ id, type }) =>
            () => {
                let rule;
                switch (type) {
                    case RuleType.Mileage:
                        rule = mileageRules.find((rule) => rule.id === id);
                        break;
                    case RuleType.Parking:
                        rule = parkingRules.find((rule) => rule.id === id);
                        break;
                    case RuleType.Transport:
                        rule = transportRules.find((rule) => rule.id === id);
                        break;
                    case RuleType.Accommodation:
                        rule = accommodationRules.find((rule) => rule.id === id);
                        break;
                    default:
                        return null;
                }
                setRuleOptions({
                    ...ruleOptions,
                    type,
                    editableRule: rule,
                    modalOpen: true
                });
            },
        [accommodationRules, mileageRules, parkingRules, ruleOptions, transportRules]
    );

    const onRuleRemove = useCallback(() => {
        const args = { id: ruleOptions.editableRule.id };
        switch (ruleOptions.type) {
            case RuleType.Mileage:
                mileageRuleDeleteRequest(args);
                break;
            case RuleType.Parking:
                parkingRuleDeleteRequest(args);
                break;
            case RuleType.Transport:
                transportRuleDeleteRequest(args);
                break;
            case RuleType.Accommodation:
                accommodationRuleDeleteRequest(args);
                break;
            default:
                return null;
        }
        setRuleOptions(initState);
    }, [
        accommodationRuleDeleteRequest,
        mileageRuleDeleteRequest,
        parkingRuleDeleteRequest,
        ruleOptions,
        transportRuleDeleteRequest
    ]);

    const getFormattedRuleData = useCallback(
        (section) => () => {
            if (!mileageRules || !transportRules || !accommodationRules || !parkingRules) return [];
            switch (section.type) {
                case RuleType.Mileage:
                    return mileageRules.map((rule) => {
                        return [
                            rule.id,
                            rule.name,
                            `£${rule.ratePerMile}/Mile`,
                            `${rule.payMileageAfter} Miles`,
                            `${rule.maximumMileage} Miles`
                        ];
                    });

                case RuleType.Parking:
                    return parkingRules.map((rule) => {
                        return [rule.id, rule.name, `£${rule.upTo}`];
                    });
                case RuleType.Transport:
                    return transportRules.map((rule) => {
                        return [rule.id, rule.name, `£${rule.payOver}`, `£${rule.upTo}`];
                    });
                case RuleType.Accommodation:
                    return accommodationRules.map((rule) => {
                        return [
                            rule.id,
                            rule.name,
                            `${rule.foodCoverage ? 'Yes' : 'No'}`,
                            `£${rule.payOver}`,
                            `£${rule.upTo}`
                        ];
                    });
                default:
                    return null;
            }
        },
        [mileageRules, parkingRules, transportRules, accommodationRules]
    );

    const ruleFormBody = useMemo(() => {
        switch (ruleOptions.type) {
            case RuleType.Mileage:
                return (
                    <CreateMileageRuleForm
                        onSubmit={onRuleCreateSubmit}
                        onRemove={onRuleRemove}
                        editableRule={ruleOptions.editableRule}
                    />
                );
            case RuleType.Parking:
                return (
                    <CreateParkingRuleForm
                        onSubmit={onRuleCreateSubmit}
                        onRemove={onRuleRemove}
                        editableRule={ruleOptions.editableRule}
                    />
                );
            case RuleType.Transport:
                return (
                    <CreateTransportRuleForm
                        onSubmit={onRuleCreateSubmit}
                        onRemove={onRuleRemove}
                        editableRule={ruleOptions.editableRule}
                    />
                );
            case RuleType.Accommodation:
                return (
                    <CreateAccommodationAndFoodRuleForm
                        onSubmit={onRuleCreateSubmit}
                        onRemove={onRuleRemove}
                        editableRule={ruleOptions.editableRule}
                    />
                );
            default:
                return null;
        }
    }, [onRuleCreateSubmit, onRuleRemove, ruleOptions.editableRule, ruleOptions.type]);

    function capitalizeFirstLetter(str) {
        const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
        return capitalized.length > 10 ? capitalized.slice(0, 10) + '...' : capitalized;
    }

    const getConvertedMasterDate = (masterDate) => {
        if (!masterDate) return '';
        const parsedDate = masterDate.split('-');
        const currentDate = new Date();
        const date = moment(new Date(currentDate.getFullYear(), parseInt(parsedDate[1]) - 1, parsedDate[0], 0, 0, 0, 0))
            .startOf('day')
            .toDate();
        return date;
    };

    return (
        <div className="pharmacy-settings-page">
            <div className="head mb-2">
                <div className="d-flex align-center">
                    <div>
                        <h3 className="title">Settings</h3>
                    </div>
                    <div className="ml-4 d-flex">
                        <Datepicker
                            name="masterDate"
                            label="Master Date"
                            onChange={(_, data) =>
                                setEditableMasterDate({
                                    ...editableMasterDate,
                                    masterDate: data.value ? data.value : ''
                                })
                            }
                            format="DD/MM"
                            value={editableMasterDate.masterDate}
                        />
                        <div className="input-group fluid ml-2">
                            <label>Planning period weeks</label>
                            <Input
                                size="large"
                                name="planningPeriod"
                                type="number"
                                value={editableMasterDate.planningPeriod || ''}
                                autoComplete="off"
                                onChange={(e) =>
                                    setEditableMasterDate({ ...editableMasterDate, planningPeriod: e.target.value })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {sections.map((section) => {
                return (
                    <div key={section.type} className="mb-2">
                        <section className="section">
                            <div className="section__header">
                                <h3 className="section__title">{section.title}</h3>
                                <div className="edit-branch-form__row buttons-row">
                                    <ButtonComponent
                                        value="Create Rule"
                                        onClick={openCreateRuleModal(section.type)}
                                        size="medium"
                                    />
                                </div>
                            </div>
                            <SectionRuleList
                                rows={getFormattedRuleData(section)()}
                                onEdit={onEdit}
                                type={section.type}
                                cols={section.cols}
                            ></SectionRuleList>
                        </section>
                    </div>
                );
            })}
            {ruleOptions.modalOpen && (
                <BasicModal
                    open={true}
                    onClose={() => setRuleOptions(initState)}
                    title={
                        ruleOptions.editableRule
                            ? `Update ${capitalizeFirstLetter(ruleOptions.type.toLowerCase())}`
                            : `Create ${capitalizeFirstLetter(ruleOptions.type.toLowerCase())}`
                    }
                >
                    {ruleFormBody}
                </BasicModal>
            )}
        </div>
    );
}

export default PharmacySettingsPage;
