import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import { getPharmacyJobsSuccess, getPharmacyJobsFailed } from '../../../actions';
import { selectItemsPerPage } from './selectors';
import * as pharmacyJobsActionTypes from '../../../actions/super-admin-view/pharmacy-jobs-page/action-types';
import api from '../../../../services/api';

function* onPharmacyJobsListRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page, id } = payload;
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${id}/jobs/list`,
            method: 'post',
            payload: {
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getPharmacyJobsSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getPharmacyJobsFailed());
    }
}

function* watchPharmacyBranchesListRequest() {
    yield takeEvery(pharmacyJobsActionTypes.GET_PHARMACY_JOBS_REQUEST, onPharmacyJobsListRequest);
}

const adminPharmacyJobsSaga = [fork(watchPharmacyBranchesListRequest)];

export default adminPharmacyJobsSaga;
