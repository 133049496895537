import {
    GET_BRANCHES_LIST_REQUEST,
    GET_BRANCHES_LIST_SUCCESS,
    GET_BRANCHES_LIST_FAILED,
    ON_BRANCHES_FILTER_CHANGE,
    ON_BRANCHES_FILTER_FALSE,
    CLEAR_BRANCHES_FILTERS
} from './action-types';

export const getBranchesListRequest = (payload, filters) => ({
    type: GET_BRANCHES_LIST_REQUEST,
    payload,
    filters
});

export const getBranchesListSuccess = (payload) => ({
    type: GET_BRANCHES_LIST_SUCCESS,
    payload
});

export const getBranchesListFailed = () => ({
    type: GET_BRANCHES_LIST_FAILED
});

export const onBranchesFilterChange = (payload, page) => ({
    type: ON_BRANCHES_FILTER_CHANGE,
    payload,
    page
});

export const onBranchesFilterFalse = () => ({
    type: ON_BRANCHES_FILTER_FALSE
});

export const clearBranchesfilters = () => ({
    type: CLEAR_BRANCHES_FILTERS
});
