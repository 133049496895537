import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { getDuration, getDurationFormat } from '../../../services/datesHelper';

import './style.scss';

function ShiftItemCard({ title, startTime, endTime, region, linked = false, onClick }) {
    const duration = getDurationFormat(getDuration(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')));

    return (
        <div className={classnames('shift-item-card', { linked })} onClick={onClick}>
            <div className="shift-item-card__container">
                <div className="col col-left">
                    <div className="text-wrapper">
                        <p className="title">{title}</p>
                        <p className="region">{region}</p>
                    </div>
                </div>
                <div className="col col-right">
                    <div className="info-item">
                        <p className="time">{`${startTime} - ${endTime}`}</p>
                        <p className="duration">{duration}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

ShiftItemCard.propTypes = {
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    region: PropTypes.string,
    linked: PropTypes.bool,
    onClick: PropTypes.func
};

export default ShiftItemCard;
