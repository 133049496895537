import React, { useCallback, useEffect } from 'react';
import { generatePath } from 'react-router';
import StaffProfilePage from './../../../shared/staff-profile-page/index';
import StaffSchedulePage from './schedule';

import { Switch, Redirect, Route } from 'react-router-dom';
import { RouterConfig } from '../../../../../routerConfig';

import Sidebar from '../../../../sidebar';
import ContentContainer from '../../../../../components/content-container';
import ProfileCard from '../../../../../components/profile-card';
import BackButton from '../../../../../components/UI/buttons/back-button';

import { useDispatch, useSelector } from 'react-redux';
import { showBackButton, hideBackButton, getStaffInfoRequest } from '../../../../../store/actions';
import StaffAbsencePage from '../staff-absence';
import StaffContractPage from '../staff-contract';

function StaffDetails({
    history,
    match: {
        params: { id, staffId }
    }
}) {
    const getPageView = useSelector((state) => state.branchDetailsPageView);
    const { data, loading } = useSelector((state) => state.staffInfoPage);
    const dispatch = useDispatch();

    const getStaffInfo = useCallback(
        (staffId) => {
            dispatch(getStaffInfoRequest(staffId));
        },
        // eslint-disable-next-line
        [staffId, dispatch]
    );
    useEffect(() => {
        dispatch(showBackButton());
        getStaffInfo(staffId);
        return () => {
            dispatch(hideBackButton());
        };
        // eslint-disable-next-line
    }, [dispatch, getStaffInfo]);

    if (!data) {
        return null;
    } else {
        const { name, surname, role, image, rating, stats } = data;
        const sidebarLinks = [
            {
                text: 'Personal Information',
                to: generatePath(RouterConfig.branchAdminStaffInformation, {
                    id,
                    staffId
                }),
                disabled: false,
                hidden: false
            },
            {
                text: 'Schedule',
                to: generatePath(RouterConfig.branchAdminStaffSchedule, {
                    id,
                    staffId
                }),
                disabled: false,
                hidden: role === 'locum'
            },
            {
                text: 'Absence',
                to: generatePath(RouterConfig.branchAdminStaffAbsence, {
                    id,
                    staffId
                }),
                disabled: false,
                hidden: false
            },
            {
                text: 'Contract',
                to: generatePath(RouterConfig.branchAdminStaffContract, {
                    id,
                    staffId
                }),
                disabled: false,
                hidden: false
            }
        ];

        return (
            <div className="content-view-page details-page">
                <div className="main-wrapper">
                    <div className="back-button-holder">
                        <BackButton visible={getPageView.backbuttonVisibility} onClick={() => history.goBack()} />
                    </div>
                    <div className="content-holder">
                        <Sidebar links={sidebarLinks} visible>
                            <ProfileCard
                                name={name}
                                surname={surname}
                                role={role}
                                avatar={image}
                                rating={rating}
                                stats={stats}
                            />
                        </Sidebar>
                        <ContentContainer smallPaddings fluid={false}>
                            <Switch>
                                <Route
                                    path={RouterConfig.branchAdminStaffInformation}
                                    component={() => (
                                        <StaffProfilePage
                                            personalInfo={data}
                                            updateInfoAction={() => getStaffInfo(staffId)}
                                            getLoading={loading}
                                        />
                                    )}
                                />
                                <Route path={RouterConfig.branchAdminStaffSchedule} component={StaffSchedulePage} />
                                <Route path={RouterConfig.branchAdminStaffAbsence} component={StaffAbsencePage} />
                                <Route
                                    path={RouterConfig.branchAdminStaffContract}
                                    component={() => <StaffContractPage staffId={staffId} />}
                                />
                                <Redirect
                                    from={RouterConfig.branchAdminStaffRoot}
                                    to={RouterConfig.branchAdminStaffInformation}
                                />
                            </Switch>
                        </ContentContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default StaffDetails;
