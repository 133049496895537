import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import ContentContainer from '../../../../components/content-container';
import AddEditBulkShiftScheduleForm from '../../../../components/forms/add-edit-bulk-shift-schedule-form/AddEditBulkShiftScheduleForm';
import PostJobForm from '../../../../components/forms/post-job-form';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import EditTemplateModal from '../../../../components/modals/edit-template-modal';
import PageNav from '../../../../components/page-nav';
import Toolbar from '../../../../components/schedule-calendar/Toolbar';
import ScheduleDayPreview from '../../../../components/schedule-day-preview';
import ScheduleWeekViewItem from '../../../../components/schedule-week-view-item';
import { getCurrentWeekDays } from '../../../../services/datesHelper';
import { onFilter } from '../../../../services/fetchDataHelper';
import { getDayStatus, sortByStartTime } from '../../../../services/helpers';
import { useModal, usePrevious } from '../../../../services/hooks';
import { RouterConfig } from './../../../../routerConfig';
import './style.scss';

export function SchedulePage({
    getCurrentDay,
    currentDay,
    schedulePage: { dateRange, filters, branches, loading },
    getScheduleShiftsRequest,
    onScheduleFilterChange,
    onScheduleDateRangeChange,
    clearScheduleFilters,
    getPostJobDraft,
    clearLocumData,
    locumData,
    postJobDraft,
    separateJob,
    longTermJob,
    onSchedulePostJobRequest,
    getBranchAvailableStaffRequest,
    onScheduleSubmitShiftRequest,
    getJobLocumsByFilterRequest,
    clearGetJobLocumsByFilter,
    locums,
    getAllMileageRulesRequest,
    getAllTransportRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    mileageRules,
    transportRules,
    parkingRules,
    accommodationRules,
    allStaff,
    onSubmitBulkShiftRequest,
    onSubmitShiftDraftBulkRequest,
    getAllStaffRequest,
    onPostJobBulkRequest
}) {
    const [dayPreview, togglePreview] = useModal(false);
    const [postJob, togglePostJob] = useModal(false);
    const [inviteExternalLocumConfirm, toggleInviteExternalLocumConfirm] = useModal(false);
    const [addShift, toggleAddShift] = useModal(false);

    const [jobType, setJobType] = useState(1);
    const [currentDayCell, setDayCellInfo] = useState(null);

    const history = useHistory();
    const { pathname } = useLocation();
    const prevRoute = usePrevious(pathname);

    const { error: locumDataError } = locumData;

    useEffect(() => {
        const unlisten = history.listen(({ pathname }) => {
            if (pathname !== prevRoute) clearScheduleFilters();
        });
        return () => {
            unlisten();
        };
    }, [history, clearScheduleFilters, prevRoute]);

    useEffect(() => {
        getScheduleShiftsRequest();
        getAllStaffRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getScheduleShiftsRequest, getAllStaffRequest]);

    useEffect(() => {
        if (
            !getAllMileageRulesRequest ||
            !getAllTransportRulesRequest ||
            !getAllParkingRulesRequest ||
            !getAllAccommodationRulesRequest
        )
            return;
        getAllMileageRulesRequest();
        getAllTransportRulesRequest();
        getAllParkingRulesRequest();
        getAllAccommodationRulesRequest();
    }, [
        getAllMileageRulesRequest,
        getAllTransportRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest
    ]);

    const onDateChange = useCallback(
        (dateChange) => {
            const getNewDate = moment(dateRange.startDateRange).add(dateChange, 'weeks').format('YYYY-MM-DD');
            onScheduleDateRangeChange({
                startDateRange: getNewDate,
                endDateRange: moment(getNewDate).add(6, 'days').format('YYYY-MM-DD')
            });
            getScheduleShiftsRequest();
        },
        [dateRange.startDateRange, getScheduleShiftsRequest, onScheduleDateRangeChange]
    );

    const onPostJob = useCallback(
        (data) => {
            const { id } = currentDayCell;
            onSchedulePostJobRequest({
                id,
                data,
                toggleModal: () => {
                    togglePostJob();
                }
            });
            clearLocumData();
        },
        [currentDayCell, onSchedulePostJobRequest, togglePostJob, clearLocumData]
    );

    const getWeekArray = getCurrentWeekDays(dateRange.startDateRange, (dateRange.startDateRange, 'YYYY-MM-DD'), '');
    const getWeekDays = getWeekArray.map((day) => {
        return (
            <div key={day} className="day-cell">
                <span className="day-cell__date">{moment(day).format('Do MMMM')}</span>
                <br />
                <span className="day-cell__weekDay">{moment(day).format('dddd')}</span>
            </div>
        );
    });

    const onOpenModal = (id, toggleModal, { operatingTime, ...data }) => {
        const payload = {
            id,
            data,
            operatingTime
        };
        setDayCellInfo(payload);
        return toggleModal();
    };

    const getAvailableStaff = useCallback(
        (recievedData) => {
            getBranchAvailableStaffRequest({
                recievedData
            });
        },
        [getBranchAvailableStaffRequest]
    );

    const onSubmitShift = useCallback(
        (data) => {
            const { id } = currentDayCell;
            onScheduleSubmitShiftRequest({
                id,
                data,
                isDraft: !!data.isDraft,
                callback: () => toggleAddShift()
            });
        },
        [currentDayCell, onScheduleSubmitShiftRequest, toggleAddShift]
    );

    const onGphcChange = useCallback(
        ({ gphcNumber, name }) => {
            getJobLocumsByFilterRequest({ gphcNumber, name });
        },
        [getJobLocumsByFilterRequest]
    );

    const onLocumsInitialGet = useCallback(() => {
        getJobLocumsByFilterRequest({});
    }, [getJobLocumsByFilterRequest]);

    const onInviteExternalLocumConfirm = useCallback(() => {
        toggleInviteExternalLocumConfirm();
        onPostJob();
    }, [toggleInviteExternalLocumConfirm, onPostJob]);

    const checkExternalLocum = useCallback(
        async (data) => {
            getPostJobDraft(data);
            setJobType(jobType);
            if (locumDataError) {
                toggleInviteExternalLocumConfirm();
            } else {
                await new Promise((resolve) => {
                    separateJob();
                    resolve();
                });
                onPostJob();
            }
        },
        [getPostJobDraft, jobType, locumDataError, toggleInviteExternalLocumConfirm, onPostJob, separateJob]
    );

    const onLocumDataClear = useCallback(() => {
        clearLocumData();
    }, [clearLocumData]);

    const onSubmitBulkShift = (shifts) => {
        const { startDateRange, endDateRange } = dateRange;
        onSubmitBulkShiftRequest({
            id: currentDayCell.id,
            data: shifts,
            toggleModal: () => {
                toggleAddShift();
                getScheduleShiftsRequest();
            },
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    };

    const onSubmitBulkDraft = (data) => {
        const { startDateRange, endDateRange } = dateRange;
        onSubmitShiftDraftBulkRequest({
            id: currentDayCell.id,
            data,
            callback: () => {
                toggleAddShift();
                getScheduleShiftsRequest();
            },
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    };

    const searchInputs = [
        {
            name: 'branch',
            value: filters.branch,
            placeholder: 'Branch',
            disabled: loading,
            loading,
            onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onScheduleFilterChange)
        },
        {
            name: 'region',
            value: filters.region,
            placeholder: 'Region',
            disabled: loading,
            loading,
            onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onScheduleFilterChange)
        },
        {
            name: 'staff',
            value: filters.staff,
            placeholder: 'Employee',
            disabled: loading,
            loading,
            onChange: (syntheticEvent, eventObj) => onFilter(syntheticEvent, eventObj, onScheduleFilterChange)
        }
    ];

    if (!branches) {
        return null;
    } else {
        const getBranchItem = branches.map((branch) => (
            <ScheduleWeekViewItem
                key={branch.id}
                data={branch}
                getWeek={getWeekArray}
                onDayClick={(data) => {
                    getCurrentDay(data);
                    togglePreview();
                }}
                onAddShiftClick={onOpenModal.bind(null, branch.id, toggleAddShift)}
                onPostJobClick={onOpenModal.bind(null, branch.id, togglePostJob)}
            />
        ));

        return (
            <div className="content-view-page schedule-page">
                <div className="main-wrapper">
                    <div className="content-holder topToolBar">
                        <div className="">
                            <PageNav title="Schedule" />
                        </div>
                        <Toolbar
                            label={`${moment(dateRange.startDateRange, 'YYYY-MM-DD').format('Do')} - ${moment(
                                dateRange.endDateRange,
                                'YYYY-MM-DD'
                            ).format('Do MMMM, Y')}`}
                            onPrevClick={() => onDateChange(-1)}
                            onNextClick={() => onDateChange(1)}
                            switcher={false}
                            searchInputs={searchInputs}
                            fullWidth
                        />
                    </div>
                    <div className="content-holder">
                        <ContentContainer xSmallPaddings fluid transparent>
                            <div className={`schedule-page__calendar ${branches.length > 7 ? 'scrollable' : ''}`}>
                                <div className="days-row">{getWeekDays}</div>
                                <div className="branches-list">{getBranchItem}</div>
                            </div>
                            {dayPreview && (
                                <EditTemplateModal
                                    open={dayPreview}
                                    onClose={() => togglePreview()}
                                    title={moment(currentDay.startDate).format('D MMM YYYY')}
                                    subtitle={`${currentDay.startWorkingTime} - ${currentDay.endWorkingTime}`}
                                    type="calendar"
                                    color={
                                        getDayStatus(currentDay.shifts) === 'shift'
                                            ? 'green'
                                            : getDayStatus(currentDay.shifts) === 'job-posted'
                                            ? 'orange'
                                            : 'lightblue'
                                    }
                                >
                                    <ScheduleDayPreview
                                        shifts={[...currentDay.shifts].sort((a, b) => sortByStartTime(a, b))}
                                        branchId={currentDay.id}
                                        onJobDetailsClick={({ jobId }) =>
                                            history.push(
                                                generatePath(RouterConfig.branchAdminJobPreview, {
                                                    id: currentDay.id,
                                                    jobId
                                                })
                                            )
                                        }
                                        onSubmit={() =>
                                            history.push(
                                                generatePath(RouterConfig.branchAdminBranchScheduleDayView, {
                                                    id: currentDay.id,
                                                    date: currentDay.startDate
                                                })
                                            )
                                        }
                                    />
                                </EditTemplateModal>
                            )}
                            {addShift && currentDayCell.operatingTime && (
                                <EditTemplateModal
                                    open={addShift}
                                    onClose={() => toggleAddShift()}
                                    title="Add Shift"
                                    subtitle={moment(currentDayCell.data.startDate).format('D MMM, dddd')}
                                    type="add"
                                    color="blue"
                                    size="small"
                                >
                                    <AddEditBulkShiftScheduleForm
                                        staffList={allStaff}
                                        getStaff={getAvailableStaff}
                                        onSubmitClick={onSubmitShift}
                                        onSubmitBulkShift={onSubmitBulkShift}
                                        onSubmitDraftBulkClick={onSubmitBulkDraft}
                                        operatingTime={currentDayCell.operatingTime}
                                        draftSaved={false}
                                        currentDate={currentDayCell.data.startDate}
                                    />
                                </EditTemplateModal>
                            )}
                            {postJob && (
                                <EditTemplateModal
                                    open={postJob}
                                    onClose={() => {
                                        togglePostJob();
                                        clearLocumData();
                                    }}
                                    title="Post job"
                                    subtitle={moment(currentDayCell.data.startDate).format('D MMM, dddd')}
                                    type="none"
                                    color="orange"
                                    size="small"
                                >
                                    <PostJobForm
                                        loading={loading}
                                        calledFromShift
                                        locumData={locumData}
                                        onLocumDataClear={onLocumDataClear}
                                        onGphcChange={onGphcChange}
                                        jobData={currentDayCell.data}
                                        operatingTime={currentDayCell.operatingTime}
                                        defaultDate={moment(currentDayCell.data.startDate)}
                                        onCloseClick={() => togglePostJob()}
                                        onSubmitClick={(data) => checkExternalLocum(data, 1)}
                                        locums={locums}
                                        onLocumsInitialGet={onLocumsInitialGet}
                                        clearGetJobLocumsByFilter={clearGetJobLocumsByFilter}
                                        mileageRules={mileageRules}
                                        transportRules={transportRules}
                                        parkingRules={parkingRules}
                                        accommodationRules={accommodationRules}
                                    />
                                </EditTemplateModal>
                            )}
                            {inviteExternalLocumConfirm && (
                                <ConfirmModal
                                    open={inviteExternalLocumConfirm}
                                    onClose={() => toggleInviteExternalLocumConfirm()}
                                    title="Warning"
                                    text="Are you sure want to invite this Locum for a Job?"
                                    button={{
                                        value: 'Yes',
                                        onClick: () => onInviteExternalLocumConfirm(),
                                        size: 'big',
                                        width: 'big'
                                    }}
                                />
                            )}
                        </ContentContainer>
                    </div>
                </div>
            </div>
        );
    }
}
