import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import './style.scss';

const Sidebar = ({ links = [], visible, ...props }) => {
    const navLinks = links.map((link) => {
        const { to, text, disabled, hidden, isActive } = link;
        return (
            <NavLink
                key={to}
                to={to}
                isActive={isActive}
                className={`${hidden ? 'hidden' : disabled ? 'disabled' : ''} sidebar__link`}
            >
                {text}
                <Icon name="chevron right" />
            </NavLink>
        );
    });
    return (
        <aside className={`sidebar ${visible ? '' : 'hidden'}`}>
            <div className="sidebar__top">{props.children}</div>
            <div className="sidebar__bottom">{navLinks}</div>
        </aside>
    );
};

Sidebar.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            hidden: PropTypes.bool,
            isActive: PropTypes.func
        }).isRequired
    ),
    visible: PropTypes.bool
};

export default withRouter(Sidebar);
