export const GET_LOCUM_JOB_DETAILS_REQUEST = 'GET_LOCUM_JOB_DETAILS_REQUEST';
export const GET_LOCUM_JOB_DETAILS_SUCCESS = 'GET_LOCUM_JOB_DETAILS_SUCCESS';
export const GET_LOCUM_JOB_DETAILS_FAILED = 'GET_LOCUM_JOB_DETAILS_FAILED';

export const LOCUM_JOB_ACTION_REQUEST = 'LOCUM_JOB_ACTION_REQUEST';
export const LOCUM_JOB_ACTION_SUCCESS = 'LOCUM_JOB_ACTION_SUCCESS';
export const LOCUM_JOB_ACTION_FAILED = 'LOCUM_JOB_ACTION_FAILED';

export const JOB_MILEAGE_VALUE_UPDATE_REQUEST = 'JOB_MILEAGE_VALUE_UPDATE_REQUEST';
export const JOB_MILEAGE_VALUE_UPDATE_SUCCESS = 'JOB_MILEAGE_VALUE_UPDATE_SUCCESS';
export const JOB_MILEAGE_VALUE_UPDATE_FAILED = 'JOB_MILEAGE_VALUE_UPDATE_FAILED';
