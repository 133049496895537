import React, { useState, useEffect } from 'react';
import PageNav from '../../../../components/page-nav';
import TableComponent from '../../../../components/table';
import { connect } from 'react-redux';
import { getAdminBranchInvoiceListRequest, postAdminZohoReportEmailRequest } from '../../../../store/actions';
import moment from 'moment';
import './style.scss';
import { usePage } from '../../../../services/hooks';
import TextInput from '../../../../components/UI/inputs/text-input';
import ButtonComponent from '../../../../components/UI/buttons/button';

const perPage = 10;

function AdminBillingPage({
    adminBillingPage: { data, count },
    getAdminBranchInvoiceListRequest,
    postAdminZohoReportEmailRequest,
    user
}) {
    const page = usePage();
    const [reportEmail, setReportEmail] = useState('');

    useEffect(() => {
        setReportEmail(user.zohoReportEmail || '');
    }, [user]);

    useEffect(() => {
        if (!page) return;
        const timeout = setTimeout(() => {
            getAdminBranchInvoiceListRequest({
                pagination: {
                    page,
                    perPage: perPage
                },
                filters: {}
            });
        }, 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const onEmailSubmit = () => {
        postAdminZohoReportEmailRequest(reportEmail);
    };

    const tableCols = [
        {
            name: 'email',
            title: 'Email',
            render: (item) => item.branch.user.email,
            type: 'disabled'
        },
        {
            name: 'title',
            title: 'Branch name',
            render: (item) => item.branch.title,
            type: 'disabled'
        },
        {
            name: 'numark',
            title: 'NUMARK membership',
            render: (item) => item.branch.numark,
            type: 'disabled'
        },
        {
            name: 'pharmacyName',
            title: 'Pharmacy',
            render: (item) => item.branch.user.pharmacyName,
            type: 'disabled'
        },
        {
            name: 'date',
            title: 'Date',
            render: (item) => moment(item.invoice.invoice_date).format('DD/MM/YY'),
            type: 'disabled'
        },
        {
            name: 'number',
            title: 'Invoice number',
            render: (item) => item.invoice.invoice_number,
            type: 'disabled'
        },
        {
            name: 'amount',
            title: 'Amount',
            render: (item) => `£${item.amount}`,
            type: 'disabled'
        },
        {
            name: 'status',
            title: 'Status',
            render: (item) => item.invoice.status,
            type: 'disabled'
        }
    ];
    return (
        <div className="admin-billing-page">
            <div className="main-container">
                <PageNav title="Billing" />
                <div className="input-row">
                    <div className="mr-1">Set up email for the reporting</div>
                    <TextInput
                        className="mr-1"
                        name="zohoReportEmail"
                        label=""
                        type="email"
                        value={reportEmail}
                        fluid={false}
                        onChange={(e) => setReportEmail(e.target.value)}
                    />
                    <ButtonComponent
                        value="Submit"
                        size="medium"
                        onClick={() => onEmailSubmit()}
                        disabled={user.zohoReportEmail === reportEmail}
                    />
                </div>

                <TableComponent
                    cols={tableCols}
                    data={data}
                    totalItems={count}
                    itemsPerPage={perPage}
                    page={page}
                    openInfo={() => {
                        // history.push(generatePath(RouterConfig.branchAdminBilling))
                    }}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    adminBillingPage: state.adminBillingPage,
    user: state.auth.user
});

const mapDispatchToProps = {
    getAdminBranchInvoiceListRequest,
    postAdminZohoReportEmailRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBillingPage);
