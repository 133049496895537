import { all } from 'redux-saga/effects';

import auth from './auth/login/';
import signupSaga from './auth/signup/';
import locumCheckInSaga from './locum-check-in-page/';
import verificationPageSaga from './verification-page/';
import userProfileInfoSaga from './profile/';
import staffInvitePageSaga from './staff-invite-page/';
import createBranchSaga from './admin-view/create-branch';
import schedulePageSaga from './admin-view/schedule-page/';
import branchesListSaga from './admin-view/branches-page/';
import branchInfoSaga from './admin-view/branch-info/';
import branchScheduleSaga from './admin-view/branch-schedule/';
import scheduleDayViewSaga from './admin-view/branch-schedule-day-view/';
import branchStaffSaga from './admin-view/branch-staff/';
import staffListSaga from './admin-view/staff-page';
import inviteStaffSaga from './admin-view/invite-staff/';
import regionsPageSaga from './admin-view/regions-page/';
import postedJobsSaga from './admin-view/posted-jobs-page/';
import jobPreviewPageSaga from './admin-view/job-preview-page/';
import staffInfoPageSaga from './admin-view/staff-info/';
import staffScheduleSaga from './admin-view/staff-schedule/';
import billingPageSaga from './admin-view/billing-page/';
import adminPharmaciesSaga from './super-admin-view/pharmacies-page/';
import adminPharmacyInfoPageSaga from './super-admin-view/pharmacy-info-page/';
import adminUsersListSaga from './super-admin-view/users-page/';
import adminJobOffersSaga from './super-admin-view/job-offers-page/';
import adminPharmacyBranchesSaga from './super-admin-view/pharmacy-branches-page/';
import adminPharmacyJobsSaga from './super-admin-view/pharmacy-jobs-page/';
import adminPharmacyStaffSaga from './super-admin-view/pharmacy-staff-page/';
import adminUserInfoPageSaga from './super-admin-view/user-info/';
import adminBillingPageSaga from './super-admin-view/billings-page/';
import staffUserProfilePageSaga from './shared/profile-page';
import staffUserScheduleSaga from './shared/schedule';
import locumJobOffersSaga from './locum-view/job-offers/';
import locumSettingsSaga from './locum-view/settings/';
import locumJobDetailsSaga from './locum-view/job-preview/';
import saveFileSaga from './save-file/';
import profileAdditionalInfoSaga from './shared/profile-additional-info/';
import locumSaga from './admin-view/locum/';
import jobLocumRatingSaga from './admin-view/job-locum-rating/';
import absenceRequestFormSaga from './admin-view/absence-request-form';
import staffAbsenceSaga from './admin-view/staff-absence';
import locumBankAddLocumFormSaga from './admin-view/locum-bank-add-locum-form';
import locumBankPageSaga from './admin-view/locum-bank-page';
import postJobFormSaga from './admin-view/post-job-form';
import pharmacySettingsPageSaga from './admin-view/pharmacy-settings-page';
import branchTemplateSaga from './admin-view/branch-template';
import staffContractSaga from './admin-view/staff-contract';
import adminPharmacyBillingSaga from './super-admin-view/pharmacy-billing-page';

export default function* rootSaga() {
    yield all([
        ...auth,
        ...signupSaga,
        ...locumCheckInSaga,
        ...verificationPageSaga,
        ...userProfileInfoSaga,
        ...staffInvitePageSaga,
        ...schedulePageSaga,
        ...branchesListSaga,
        ...branchInfoSaga,
        ...branchScheduleSaga,
        ...scheduleDayViewSaga,
        ...branchStaffSaga,
        ...createBranchSaga,
        ...staffListSaga,
        ...inviteStaffSaga,
        ...regionsPageSaga,
        ...postedJobsSaga,
        ...jobPreviewPageSaga,
        ...staffInfoPageSaga,
        ...staffScheduleSaga,
        ...billingPageSaga,
        ...adminPharmaciesSaga,
        ...adminPharmacyInfoPageSaga,
        ...adminUsersListSaga,
        ...adminJobOffersSaga,
        ...adminPharmacyBranchesSaga,
        ...adminPharmacyJobsSaga,
        ...adminPharmacyStaffSaga,
        ...adminUserInfoPageSaga,
        ...adminBillingPageSaga,
        ...staffUserProfilePageSaga,
        ...staffUserScheduleSaga,
        ...locumJobOffersSaga,
        ...locumJobDetailsSaga,
        ...locumSettingsSaga,
        ...saveFileSaga,
        ...profileAdditionalInfoSaga,
        ...locumSaga,
        ...jobLocumRatingSaga,
        ...absenceRequestFormSaga,
        ...staffAbsenceSaga,
        ...locumBankAddLocumFormSaga,
        ...locumBankPageSaga,
        ...postJobFormSaga,
        ...pharmacySettingsPageSaga,
        ...branchTemplateSaga,
        ...staffContractSaga,
        ...adminPharmacyBillingSaga
    ]);
}
