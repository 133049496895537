import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TextInput from './../../UI/inputs/text-input';
import ButtonComponent from './../../UI/buttons/button';
import SingleStep from './../../UI/single-step';

import { Step, Grid, Form } from 'semantic-ui-react';

import { emailError, passError, phoneError, nameError, digitsNumberError } from './../../../services/validate';
import { userRoles } from './../../../userConfig';

import pharmacyIcon from './../../../assets/icons/pharmacy-icon.png';
import locumIcon from './../../../assets/icons/locum-icon.png';
import './style.scss';

class SignupForm extends Component {
    state = {
        isStepOne: true,
        userRole: null,
        pharmacyName: '',
        gphcNumber: '',
        email: '',
        password: '',
        repeatPassword: '',
        contactPerson: '',
        phone: '',
        loading: false,
        validate: {
            pharmacyName: false,
            gphcNumber: false,
            email: false,
            password: false,
            repeatPassword: false,
            contactPerson: false,
            phone: false
        },
        errors: {
            pharmacyName: null,
            gphcNumber: null,
            email: null,
            password: null,
            repeatPassword: null,
            contactPerson: null,
            phone: null,
            form: null
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeHandler = ({ target: { name, value } }) => {
        return this.setState(
            {
                [name]: value
            },
            () => this.validate(name)
        );
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'pharmacyName':
                error = nameError(value);
                break;
            case 'gphcNumber':
                error = digitsNumberError(value);
                break;
            case 'email':
                error = emailError(value);
                break;
            case 'password':
                error = passError(value);
                break;
            case 'repeatPassword':
                error = value !== this.state.password ? 'Passwords must be the same.' : null;
                break;
            case 'contactPerson':
                error = nameError(value);
                break;
            case 'phone':
                error = phoneError(value);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onSubmitAdminHandler = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('pharmacyName'),
            this.validate('email'),
            this.validate('password'),
            this.validate('contactPerson'),
            this.validate('phone')
        ]);

        const { errors, pharmacyName, password, email, contactPerson, phone, userRole } = this.state;
        if (errors.pharmacyName || errors.password || errors.email || errors.contactPerson || errors.phone) return;
        const { onSuccess } = this.props;

        onSuccess(
            {
                name: contactPerson,
                email,
                password,
                pharmacyName,
                phone,
                role: userRole
            },
            userRole
        );
    };

    onSubmitLocumHandler = async (e) => {
        e.preventDefault();

        await Promise.all([
            this.validate('gphcNumber'),
            this.validate('email'),
            this.validate('password'),
            this.validate('repeatPassword')
        ]);

        const { errors, password, gphcNumber, email, userRole } = this.state;
        if (errors.password || errors.gphcNumber || errors.email || errors.repeatPassword) return;
        const { onSuccess } = this.props;

        onSuccess(
            {
                gphcNumber,
                email,
                password,
                role: userRole
            },
            userRole
        );
    };

    onGoBack = (e) => {
        e.preventDefault();
        const { errors } = this.state;
        let resetErrors = {};

        for (let key in errors) {
            errors[key] = null;
            resetErrors[key] = errors[key];
        }

        this.setState({ errors: resetErrors, isStepOne: true });
    };

    render() {
        const {
            isStepOne,
            userRole,
            pharmacyName,
            gphcNumber,
            email,
            password,
            repeatPassword,
            contactPerson,
            phone,
            errors
        } = this.state;
        const { loading, onBackToLogin } = this.props;
        const subtitle = isStepOne ? 'Please choose' : 'Register';

        const registerStep = isStepOne ? (
            <Grid columns={1}>
                <Grid.Column>
                    <Step.Group size="large" fluid vertical>
                        <SingleStep
                            avatar={pharmacyIcon}
                            title="Pharmacy"
                            onClick={() =>
                                this.setState({
                                    userRole: userRoles.BRANCH_ADMIN,
                                    isStepOne: false
                                })
                            }
                        />
                        <SingleStep
                            avatar={locumIcon}
                            title="Locum"
                            onClick={() => this.setState({ userRole: userRoles.LOCUM, isStepOne: false })}
                        />
                    </Step.Group>
                    <ButtonComponent value="Back" type="bordered" onClick={onBackToLogin} />
                </Grid.Column>
            </Grid>
        ) : !isStepOne && userRole === userRoles.BRANCH_ADMIN ? (
            <>
                <Form loading={loading} onSubmit={this.onSubmitHandler}>
                    <TextInput
                        name="pharmacyName"
                        label="Pharmacy Name"
                        type="text"
                        value={pharmacyName}
                        error={!!errors.pharmacyName}
                        helperText={errors.pharmacyName}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="email"
                        label="Email"
                        type="email"
                        value={email}
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="password"
                        label="Password"
                        type="password"
                        value={password}
                        error={!!errors.password}
                        helperText={errors.password}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="contactPerson"
                        label="Contact Person"
                        type="text"
                        value={contactPerson}
                        error={!!errors.contactPerson}
                        helperText={errors.contactPerson}
                        onChange={this.onChangeHandler}
                    />
                    <TextInput
                        name="phone"
                        label="Contact phone"
                        type="tel"
                        value={phone}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        onChange={this.onChangeHandler}
                    />
                    <ButtonComponent
                        value="Continue"
                        onClick={this.onSubmitAdminHandler}
                        disabled={
                            !!errors.pharmacyName ||
                            !!errors.password ||
                            !!errors.email ||
                            !!errors.contactPerson ||
                            !!errors.phone ||
                            loading
                        }
                    />
                </Form>
                <ButtonComponent value="Back" type="transparent" onClick={this.onGoBack} disabled={loading} />
            </>
        ) : !isStepOne && userRole === userRoles.LOCUM ? (
            <Form>
                <TextInput
                    name="gphcNumber"
                    type="text"
                    value={gphcNumber}
                    label="GPHC Number"
                    onChange={this.onChangeHandler}
                    error={!!errors.gphcNumber}
                    helperText={errors.gphcNumber}
                />
                <TextInput
                    name="email"
                    value={email}
                    label="Email"
                    type="email"
                    onChange={this.onChangeHandler}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextInput
                    name="password"
                    value={password}
                    label="Password"
                    type="password"
                    onChange={this.onChangeHandler}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <TextInput
                    name="repeatPassword"
                    value={repeatPassword}
                    label="Repeat password"
                    type="password"
                    onChange={this.onChangeHandler}
                    error={!!errors.repeatPassword}
                    helperText={errors.repeatPassword}
                />
                <ButtonComponent
                    value="Continue"
                    onClick={this.onSubmitLocumHandler}
                    loading={loading}
                    size="big"
                    width="big"
                    disabled={!!errors.gphcNumber || !!errors.email || !!errors.password || !!errors.repeatPassword}
                />
                <ButtonComponent value="Back" type="transparent" onClick={this.onGoBack} />
            </Form>
        ) : null;

        return (
            <div
                className={classnames('signup-form', {
                    hasError: userRole === userRoles.BRANCH_ADMIN && Object.values(errors).some((err) => !!err)
                })}
            >
                <h2 className="signup-form__title">Sign Up</h2>
                <p className="signup-form__subtitle">{subtitle}</p>
                {registerStep}
            </div>
        );
    }
}

SignupForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onBackToLogin: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

export default SignupForm;
