import React, { useEffect, useState } from 'react';
import OnboardingPage from '../shared/onboarding-page';
import VerificationPage from '../shared/verification-page';
import LocumCheckInPage from './check-in-page';
import LocumInfoPage from './info-page';
import LocumSchedule from './schedule';
import LocumJobOffers from './job-offers';
import LocumSettings from './settings';
import { Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { PrivateRoute } from '../../../services/privateRoute';
import { useLastLocation } from 'react-router-last-location';
import Header from '../../../components/header';
import Sidebar from '../../sidebar';
import ContentContainer from '../../../components/content-container';
import ProfileCard from '../../../components/profile-card';
import ErrorBoundary from '../../error-boundary';
import { RouterConfig } from './../../../routerConfig';
import { userStatusesList } from '../../../userConfig';
import { getUrlPathRapams, userStatusCheck } from '../../../services/helpers';
import JobFilterPanel from './job-offers/job-filter-panel';

const LocumView = ({ user, roles, ...props }) => {
    const { pathname, search } = useLocation();
    const lastLocation = useLastLocation();
    const history = useHistory();
    const redirect = userStatusCheck(user.status, 'locum');
    const [jobPreviewOpen, setJobPreviewOpen] = useState(false);

    useEffect(() => {
        if (
            history.action === 'REPLACE' &&
            lastLocation &&
            getUrlPathRapams(lastLocation.pathname).path === 'job-offers' &&
            getUrlPathRapams(lastLocation.pathname).param !== getUrlPathRapams(lastLocation.pathname).path
        )
            history.push(generatePath(RouterConfig.locum.jobOffers), [getUrlPathRapams(lastLocation.pathname).param]);

        if (user.status === userStatusesList.ACTIVE && pathname === RouterConfig.locum.onboarding)
            history.replace(redirect);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, lastLocation]);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const jobPreviewOpen = searchParams.get('jobPreviewOpen');
        setJobPreviewOpen(jobPreviewOpen && jobPreviewOpen === 'true');
    }, [search]);

    const sidebarLinks = [
        {
            text: 'Locum Shifts',
            to: RouterConfig.locum.jobOffers,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        },
        {
            text: 'onboarding',
            to: RouterConfig.locum.onboarding,
            disabled: false,
            hidden: user.status === userStatusesList.ACTIVE
        },
        {
            text: 'Personal information',
            to: RouterConfig.locum.personalInfo,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        },
        {
            text: 'Schedule',
            to: RouterConfig.locum.schedule,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        },
        {
            text: 'Notifications settings',
            to: RouterConfig.locum.settings,
            disabled: user.status !== userStatusesList.ACTIVE,
            hidden: false
        }
    ];

    const profileTab =
        user.status === userStatusesList.ACTIVE
            ? {
                  title: `${user.name} ${user.surname}`,
                  to: RouterConfig.locum.personalInfo,
                  image: user.image
              }
            : { title: '', to: RouterConfig.root };

    const displaySidebar =
        user.status !== userStatusesList.NEW && getUrlPathRapams(pathname).path !== 'check-in' ? (
            <Sidebar links={sidebarLinks} visible>
                <ProfileCard name={user.name} surname={user.surname} role={user.role} avatar={user.image} />
            </Sidebar>
        ) : null;

    return (
        <div className="content-view-page">
            <Header profileTab={profileTab} />
            <ErrorBoundary>
                <div className="main-wrapper">
                    <div className="content-holder">
                        {displaySidebar}
                        <ContentContainer
                            transparent={
                                user.status === userStatusesList.NEW || getUrlPathRapams(pathname).path === 'check-in'
                            }
                            autoHeight={
                                user.status === userStatusesList.NEW || getUrlPathRapams(pathname).path === 'check-in'
                            }
                        >
                            <Switch>
                                <PrivateRoute
                                    path={RouterConfig.locum.onboarding}
                                    user={user.role}
                                    roles={roles}
                                    component={OnboardingPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.locum.verification}
                                    user={user.role}
                                    roles={roles}
                                    component={VerificationPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.locumCheckIn}
                                    user={user.role}
                                    roles={roles}
                                    component={LocumCheckInPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.locum.jobOffers}
                                    user={user.role}
                                    roles={roles}
                                    component={LocumJobOffers}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.locum.personalInfo}
                                    user={user.role}
                                    roles={roles}
                                    component={LocumInfoPage}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.locum.schedule}
                                    user={user.role}
                                    roles={roles}
                                    component={LocumSchedule}
                                    exact
                                />
                                <PrivateRoute
                                    path={RouterConfig.locum.settings}
                                    user={user.role}
                                    roles={roles}
                                    component={LocumSettings}
                                    exact
                                />
                                <Redirect to={redirect} exact />
                            </Switch>
                        </ContentContainer>

                        {pathname === '/job-offers' && !jobPreviewOpen && (
                            <div className="sidebar locum-job-filter-panel__container">
                                <JobFilterPanel />
                            </div>
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default LocumView;
