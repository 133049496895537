import { call, put, fork, select, takeEvery } from 'redux-saga/effects';
import {
    getPharmacyInfoRequest,
    getPharmacyInfoSuccess,
    getPharmacyInfoFailed,
    onPharmacyStatusChangeSuccess,
    onPharmacyStatusChangeFailed
} from '../../../actions';
import { selectLoading } from './selectors';
import * as pharmacyInfoActionTypes from '../../../actions/super-admin-view/pharmacy-info-page/action-types';
import api from '../../../../services/api';

function* onPharmacyInfoRequest({ pharmacyId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/admin/pharmacy/${pharmacyId}`,
            method: 'GET'
        });
        yield put(getPharmacyInfoSuccess(data));
    } catch (err) {
        yield put(getPharmacyInfoFailed());
    }
}

function* onPharmacyStatusChangeRequest({ payload: { pharmacyId, action, toggleModal } }) {
    const loading = yield select(selectLoading);
    try {
        yield call(api.request, {
            url: `/admin/pharmacy/${pharmacyId}/${action}`,
            method: 'POST',
            loading
        });
        yield put(onPharmacyStatusChangeSuccess());
        toggleModal();
        yield put(getPharmacyInfoRequest(pharmacyId));
    } catch (err) {
        yield put(onPharmacyStatusChangeFailed());
    }
}

// watchers
function* watchPharmacyInfoRequest() {
    yield takeEvery(pharmacyInfoActionTypes.GET_PHARMACY_INFO_REQUEST, onPharmacyInfoRequest);
}

function* watchPharmacyStatusChangeRequest() {
    yield takeEvery(pharmacyInfoActionTypes.ON_PHARMACY_STATUS_CHANGE_REQUEST, onPharmacyStatusChangeRequest);
}

const adminPharmacyInfoPageSaga = [fork(watchPharmacyInfoRequest), fork(watchPharmacyStatusChangeRequest)];

export default adminPharmacyInfoPageSaga;
