import React, { PureComponent } from 'react';
import { generatePath } from 'react-router';
import moment from 'moment';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { momentLocalizer } from 'react-big-calendar';

import { RouterConfig } from '../../../../../../routerConfig';

import ScheduleCalendar from './../../../../../../components/schedule-calendar';
import Toolbar from '../../../../../../components/schedule-calendar/Toolbar';
import EventCell from '../../../../../../components/schedule-calendar/EventCell';
import CalendarButtons from '../../../../../../components/UI/buttons/calendar-buttons';
import EditTemplateModal from '../../../../../../components/modals/edit-template-modal';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';
import AbsenceRequestModal from '../../../../../../components/modals/absence-request-modal';
import ScheduleDayPreview from '../../../../../../components/schedule-day-preview';

import AbsenceRequestForm from '../../../../../../components/forms/absence-request-form';
import AddEditBulkShiftScheduleForm from '../../../../../../components/forms/add-edit-bulk-shift-schedule-form/AddEditBulkShiftScheduleForm';
import PostJobBulkForm from '../../../../../../components/forms/post-job-bulk-form';

moment.updateLocale('ko', {
    week: {
        dow: 1,
        doy: 1
    }
});

const localizer = momentLocalizer(moment);
const date = new Date();

class BranchSchedule extends PureComponent {
    state = {
        startDateRange: moment(dates.firstVisibleDay(date, localizer)).format('YYYY-MM-DD'),
        endDateRange: moment(dates.lastVisibleDay(date, localizer)).format('YYYY-MM-DD'),
        calendarView: 'month',
        modals: {
            addShift: false,
            dayPreview: false,
            postJob: false,
            jobPreview: false,
            inviteExternalLocumConfirm: false,
            absenceRequest: false
        },
        jobType: 1,
        currentItem: null,
        editShiftId: null,
        draftSaved: false,
        loading: 'shift'
    };

    componentDidMount() {
        this._isMounted = true;

        this.getBranchShiftList();

        this.props.getAllMileageRulesRequest();
        this.props.getAllStaffRequest();
        this.props.getAllTransportRulesRequest();
        this.props.getAllParkingRulesRequest();
        this.props.getAllAccommodationRulesRequest();
    }

    getBranchShiftList() {
        const {
            match: {
                params: { id }
            },
            getBranchShiftsRequest
        } = this.props;
        const { startDateRange, endDateRange } = this.state;

        getBranchShiftsRequest({
            id,
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAvailableStaff = (receivedData) => {
        this.setState({ loading: 'staff' });
        this.props.getBranchAvailableStaffRequest({
            receivedData,
            callback: () => this.setState({ loading: 'shift' })
        });
    };

    toggleModal = (name, item, shiftId) => {
        if (name === 'absenceRequest') {
            this.props.getCalculatedEmployeeShiftHoursClear();
        }
        const { modals } = this.state;
        const { getCurrentDay } = this.props;
        const currentState = modals[name];

        if (item && item.startDate) getCurrentDay(item);
        return this.setState({
            modals: { ...modals, [name]: !currentState },
            currentItem: item === undefined ? null : item,
            editShiftId: shiftId === undefined ? null : shiftId,
            draftSaved: false
        });
    };

    onNavigate = (date, view, action) => {
        const {
            match: {
                params: { id }
            },
            getBranchShiftsRequest
        } = this.props;
        if (action === 'day' || action === 'month') this.setState({ calendarView: action });
        this.setState(
            {
                startDateRange: moment(dates.firstVisibleDay(date, localizer)),
                endDateRange: moment(dates.lastVisibleDay(date, localizer))
            },
            () =>
                getBranchShiftsRequest({
                    id,
                    startDateRange: moment(this.state.startDateRange).format('YYYY-MM-DD'),
                    endDateRange: moment(this.state.endDateRange).format('YYYY-MM-DD')
                })
        );
    };

    onShowDayPreview = () => {
        const {
            currentDay,
            history,
            match: {
                params: { id }
            }
        } = this.props;

        const getDate = !currentDay.startDate
            ? moment().format('YYYY-MM-DD')
            : moment(currentDay.startDate).format('YYYY-MM-DD');

        history.push(
            generatePath(RouterConfig.branchAdminBranchScheduleDayView, {
                id,
                date: getDate
            })
        );
    };

    onShowJobPreview = ({ jobId }) => {
        const {
            history,
            match: {
                params: { id }
            }
        } = this.props;

        history.push(
            generatePath(RouterConfig.branchAdminJobPreview, {
                id,
                jobId
            })
        );
    };

    onSubmitShift = (data, isEdit) => {
        const {
            match: {
                params: { id }
            },
            onSubmitShiftRequest
        } = this.props;
        const { editShiftId, startDateRange, endDateRange } = this.state;
        onSubmitShiftRequest({
            id,
            isEdit,
            editShiftId,
            data,
            toggleModal: () => this.toggleModal(isEdit ? 'editShift' : 'addShift'),
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    };

    onSubmitBulkShift = (shifts, isEdit) => {
        const {
            match: {
                params: { id }
            },
            onSubmitBulkShiftRequest
        } = this.props;
        const { editShiftId, startDateRange, endDateRange } = this.state;
        onSubmitBulkShiftRequest({
            id,
            isEdit,
            editShiftId,
            data: shifts,
            toggleModal: () => this.toggleModal(isEdit ? 'editShift' : 'addShift'),
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    };

    onSubmitBulkDraft = (data) => {
        const {
            match: {
                params: { id }
            },
            onSubmitShiftDraftBulkRequest
        } = this.props;
        const { startDateRange, endDateRange } = this.state;
        onSubmitShiftDraftBulkRequest({
            id,
            data,
            callback: () => {
                this.setState({ draftSaved: true });
                this.toggleModal('addShift');
            },
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    };

    onSubmitDraft = (receivedData) => {
        const {
            match: {
                params: { id }
            },
            onSubmitDraftRequest
        } = this.props;
        const { startDateRange, endDateRange } = this.state;
        onSubmitDraftRequest({
            id,
            receivedData,
            callback: () => this.setState({ draftSaved: true }),
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
    };

    onGphcChange = ({ startDate, endDate, startTime, endTime, gphcNumber, name }) => {
        const { getJobLocumsByFilterRequest } = this.props;
        getJobLocumsByFilterRequest({
            gphcNumber,
            startDate,
            endDate,
            startTime,
            endTime,
            name
        });
    };

    onLocumsInitialGet = () => {
        this.props.getJobLocumsByFilterRequest({});
    };

    onPostJob = (data) => {
        const {
            match: {
                params: { id }
            },
            onPostJobRequest,
            clearLocumData,
            clearCurrentDay
        } = this.props;
        const { startDateRange, endDateRange } = this.state;

        onPostJobRequest({
            id,
            data,
            toggleModal: this.toggleModal('postJob'),
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
        clearLocumData();
        clearCurrentDay();
    };

    onInviteExternalLocumConfirm = () => {
        const { jobType, modals } = this.state;
        this.setState(
            {
                modals: {
                    ...modals,
                    inviteExternalLocumConfirm: !modals['inviteExternalLocumConfirm']
                }
            },
            () => (jobType === 1 ? this.onPostJob() : this.toggleModal('longTermJobConfirm'))
        );
    };

    checkExternalLocum = (data, jobType) => {
        const {
            getPostJobDraft,
            locumData: { error }
        } = this.props;

        getPostJobDraft(data);

        this.setState({ jobType }, () => {
            if (error) {
                this.toggleModal('inviteExternalLocumConfirm');
            } else {
                jobType === 1 ? this.onPostJob(data) : this.toggleModal('longTermJobConfirm');
            }
        });
    };

    onSubmitJobBulk = (data) => {
        const {
            match: {
                params: { id }
            },
            onPostJobBulkRequest,
            clearLocumData,
            clearCurrentDay
        } = this.props;
        const { startDateRange, endDateRange } = this.state;

        onPostJobBulkRequest({
            id,
            data,
            toggleModal: this.toggleModal('postJob'),
            startDateRange: moment(startDateRange).format('YYYY-MM-DD'),
            endDateRange: moment(endDateRange).format('YYYY-MM-DD')
        });
        clearLocumData();
        clearCurrentDay();
    };

    getStaffList = () => {
        this.props.getAllStaffRequest();
    };

    onAbsenceRequestSubmit = (absenceData) => {
        const {
            match: {
                params: { id }
            },
            postStaffAbsenceRequest
        } = this.props;

        postStaffAbsenceRequest({
            id: id,
            absenceData,
            callback: () => {
                this.toggleModal('absenceRequest');
                this.getBranchShiftList();
            }
        });
    };

    renderShifts = () => {
        const { startDateRange, endDateRange } = this.state;
        let result = [];
        for (let m = moment(startDateRange); m.diff(endDateRange, 'days') <= 0; m.add(1, 'days')) {
            const date = m.format('YYYY-MM-DD');
            const event = this.props.shiftsList.data.find(({ startDate }) => startDate === date);

            if (date && event && event.shifts && event.shifts.length === 0) {
                result.push({
                    startDate: date,
                    endDate: date,
                    status: 'empty-day',
                    title: (
                        <div className="event-inner">
                            <CalendarButtons
                                onAddShiftClick={() => this.toggleModal('addShift', { startDate: date })}
                                onPostJobClick={() =>
                                    this.toggleModal('postJob', {
                                        startTime: event.startWorkingTime,
                                        endTime: event.endWorkingTime,
                                        lunchEndTime: event.lunchEndTime,
                                        lunchStartTime: event.lunchStartTime,
                                        startDate: event.startDate,
                                        endDate: event.endDate
                                    })
                                }
                            />
                        </div>
                    )
                });
            } else if (event && !event.shifts && event.branchClosed) {
                result.push({
                    ...event,
                    status: 'closed-branch',
                    title: (
                        <div className="event-inner">
                            <span className="event-inner__title">Branch Closed</span>
                        </div>
                    )
                });
            } else if (event && event.shifts && event.shifts.length > 0) {
                const { shifts } = event;

                let dayProps = {
                    minTime: [],
                    maxTime: [],
                    shifts: '',
                    status: ''
                };
                shifts.forEach((shift) => {
                    dayProps.minTime.push(moment(shift.startTime, 'HH:mm'));
                    dayProps.maxTime.push(moment(shift.endTime, 'HH:mm'));
                    if (!shift.isDraft && !shift.isJob && !shift.hourlyRate) {
                        dayProps.status = 'shift';
                    } else if (shift.isJob && (dayProps.status === 'is-draft' || !dayProps.status)) {
                        dayProps.status = 'job-posted';
                    } else if (shift.isDraft && !dayProps.status) {
                        dayProps.status = 'is-draft';
                    }
                });

                if (shifts.length === 0) {
                    dayProps.shifts = null;
                } else if (shifts.length === 1) {
                    dayProps.shifts = '1 Shift';
                } else if (shifts.length > 1) {
                    dayProps.shifts = `${shifts.length} Shifts`;
                }

                const getMinTime = moment.min(dayProps.minTime).format('HH:mm');
                const getMaxTime = moment.max(dayProps.maxTime).format('HH:mm');

                result.push({
                    ...event,
                    startDate: moment(event.startDate),
                    endDate: moment(event.endDate),
                    allDay: true,
                    status: dayProps.status,
                    time: `${getMinTime} - ${getMaxTime}`,
                    title: (
                        <div className="event-inner">
                            <span className="event-inner__title">{`${getMinTime} - ${getMaxTime}`}</span>
                            <span className="event-inner__staff">{dayProps.shifts}</span>
                        </div>
                    )
                });
            }
        }

        return result;
    };

    render() {
        const {
            calendarView,
            modals: { addShift, dayPreview, postJob, inviteExternalLocumConfirm, absenceRequest },
            draftSaved,
            loading
        } = this.state;
        const {
            shiftsList: { data },
            staffList,
            currentDay,
            clearCurrentDay,
            postJobDraft,
            match: { params },
            clearLocumData,
            locumData,
            operatingTime,
            mileageRules,
            transportRules,
            parkingRules,
            accommodationRules,
            allStaff
        } = this.props;

        let calendarFormats = {
            weekdayFormat: 'dddd'
        };

        let calendarComponents = {
            toolbar: (props) => (
                <Toolbar
                    {...props}
                    onAddShiftClick={() =>
                        this.toggleModal('addShift', {
                            startDate: moment(new Date()).format('YYYY-MM-DD')
                        })
                    }
                    onPostJobClick={() => this.toggleModal('postJob')}
                    onAddAbsenceClick={() => this.toggleModal('absenceRequest')}
                    changeView={this.onShowDayPreview}
                    view={calendarView}
                />
            ),
            event: (props) => <EventCell {...props} />
        };

        if (!data) {
            return null;
        } else {
            return (
                <div className="branch-info-page branch-schedule">
                    <ScheduleCalendar
                        localizer={localizer}
                        formats={calendarFormats}
                        components={calendarComponents}
                        events={this.renderShifts()}
                        onEventClick={(event) => {
                            if (event.branchClosed || event.status === 'empty-day') return false;
                            this.toggleModal('dayPreview', event);
                        }}
                        selectable
                        onNavigate={this.onNavigate}
                    />
                    {addShift && (
                        <EditTemplateModal
                            open={addShift}
                            onClose={() => this.toggleModal('addShift')}
                            title="Add Shift"
                            subtitle={currentDay ? moment(currentDay.startDate).format('D MMM, dddd') : null}
                            type="add"
                            color="blue"
                            size="small"
                        >
                            <AddEditBulkShiftScheduleForm
                                loading={loading === 'staff' ? staffList.loading : this.props.shiftsList.loading}
                                staffList={allStaff}
                                getStaff={this.getAvailableStaff}
                                onSuccess={this.onInviteStaff}
                                onSubmitClick={this.onSubmitShift}
                                onSubmitBulkShift={this.onSubmitBulkShift}
                                onSubmitDraftBulkClick={this.onSubmitBulkDraft}
                                operatingTime={operatingTime}
                                onSubmitDraftClick={this.onSubmitDraft}
                                draftSaved={draftSaved}
                                currentDate={currentDay.startDate}
                            />
                        </EditTemplateModal>
                    )}
                    {dayPreview && (
                        <EditTemplateModal
                            open={dayPreview}
                            onClose={() => {
                                this.toggleModal('dayPreview');
                                clearCurrentDay();
                            }}
                            title={moment(currentDay.startDate).format('D MMM YYYY')}
                            subtitle={currentDay.time}
                            type="none"
                            color={
                                currentDay.status === 'shift'
                                    ? 'green'
                                    : currentDay.status === 'job-posted'
                                    ? 'orange'
                                    : 'lightblue'
                            }
                        >
                            <ScheduleDayPreview
                                shifts={currentDay.shifts}
                                branchId={params.id}
                                onJobDetailsClick={(data) => {
                                    clearCurrentDay();
                                    this.onShowJobPreview(data);
                                }}
                                onSubmit={() => {
                                    clearCurrentDay();
                                    this.onShowDayPreview();
                                }}
                            />
                        </EditTemplateModal>
                    )}
                    {postJob && (
                        <EditTemplateModal
                            open={postJob}
                            onClose={() => {
                                this.toggleModal('postJob');
                                clearLocumData();
                                clearCurrentDay();
                            }}
                            title="Post job"
                            subtitle={moment(currentDay.startDate).format('D MMM, dddd')}
                            type="none"
                            color="orange"
                        >
                            <PostJobBulkForm
                                loading={postJobDraft.loading}
                                calledFromShift
                                jobData={currentDay}
                                locumData={locumData}
                                onLocumDataClear={() => this.props.clearLocumData()}
                                operatingTime={operatingTime}
                                onGphcChange={this.onGphcChange}
                                defaultDate={currentDay.startDate ? moment(currentDay.startDate) : new Date()}
                                onCloseClick={() => this.toggleModal('postJob')}
                                onSubmitClick={(data) => this.checkExternalLocum(data, 1)}
                                onSubmitLongTermJob={(data) => this.checkExternalLocum(data, 2)}
                                onSubmitBulk={(data) => this.onSubmitJobBulk(data)}
                                locums={this.props.locums}
                                clearGetJobLocumsByFilter={this.props.clearGetJobLocumsByFilter}
                                onLocumsInitialGet={this.onLocumsInitialGet}
                                mileageRules={mileageRules}
                                transportRules={transportRules}
                                parkingRules={parkingRules}
                                accommodationRules={accommodationRules}
                            />
                        </EditTemplateModal>
                    )}
                    {inviteExternalLocumConfirm && (
                        <ConfirmModal
                            open={inviteExternalLocumConfirm}
                            onClose={() => this.toggleModal('inviteExternalLocumConfirm')}
                            title="Warning"
                            text="Are you sure want to invite this Locum for a Job?"
                            button={{
                                value: 'Yes',
                                onClick: () => this.onInviteExternalLocumConfirm(),
                                size: 'big',
                                width: 'big'
                            }}
                        />
                    )}
                    {absenceRequest && (
                        <AbsenceRequestModal
                            open={absenceRequest}
                            subtitle={moment(currentDay.startDate).format('D MMM, dddd')}
                            onClose={() => this.toggleModal('absenceRequest')}
                            title="Absence Request"
                        >
                            <AbsenceRequestForm
                                getStaffList={this.getStaffList}
                                onCloseClick={() => this.toggleModal('absenceRequest')}
                                onSubmit={this.onAbsenceRequestSubmit.bind(this)}
                                calculatedActualStaffShiftTime={this.props.calculatedActualStaffShiftTime}
                            />
                        </AbsenceRequestModal>
                    )}
                </div>
            );
        }
    }
}

export default BranchSchedule;
