import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PageTab from '../page-tab';
import Button from '../UI/buttons/button';
import SelectComponent from '../UI/select';
import TextInput from '../UI/inputs/text-input';

import './style.scss';

const PageNav = ({ title, tabs = [], calendar = null, inputs = [], cards = [], noMargin = false }) => {
    const leftNavElement =
        calendar !== null ? (
            <div className="nav">{calendar}</div>
        ) : (
            <div className="tabs">
                {tabs.map((tab) => (
                    <PageTab key={tab.title} tab={tab} />
                ))}
            </div>
        );

    return (
        <div className={classnames('page-nav', { noMargin })}>
            <div className="row main-row">
                <div className="col col-left">
                    <h2 className="title">{title}</h2>
                    {leftNavElement}
                </div>
                <div className="col col-right">
                    {inputs.map((element) => {
                        let returnedElement = null;
                        switch (element.componentType) {
                            case 'button':
                                return (returnedElement = (
                                    <Button
                                        key={element.value}
                                        value={element.value}
                                        type={element.type}
                                        onClick={element.onClick}
                                        loading={element.loading}
                                        size={element.size}
                                        width={element.width}
                                        disabled={element.disabled}
                                    />
                                ));
                            case 'select':
                                return (returnedElement = (
                                    <SelectComponent
                                        key={element.name}
                                        name={element.name}
                                        onChange={element.onChange}
                                        options={element.options}
                                        search={element.search}
                                        icon={element.icon}
                                        floating={element.floating}
                                        fluid={element.fluid}
                                    />
                                ));
                            case 'input':
                                return (returnedElement = (
                                    <TextInput
                                        key={element.name}
                                        name={element.name}
                                        type={element.type}
                                        value={element.value}
                                        onChange={element.onChange}
                                        placeholder={element.placeholder}
                                        fluid={element.fluid}
                                        loading={element.loading}
                                        disabled={element.disabled}
                                        error={element.error}
                                        helperText={element.helperText}
                                    />
                                ));
                            default:
                                return returnedElement;
                        }
                    })}
                </div>
            </div>
            <div className="row cards-row"></div>
        </div>
    );
};

PageNav.propTypes = {
    title: PropTypes.string,
    tabs: PropTypes.array,
    calendar: PropTypes.object,
    cards: PropTypes.array,
    noMargin: PropTypes.bool
};

export default PageNav;
