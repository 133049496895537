import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Can } from '../../../services/ability';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TextInput from '../../UI/inputs/text-input';
import LocationSearchInput from '../../UI/inputs/location-search-input';
import ButtonComponent from '../../UI/buttons/button';
import RadioGroup from '../../UI/radio-group';
import Datepicker from '../../UI/datepicker';
import UploadFileHandler from '../../upload-remove-file-handler';
import ChangePasswordForm from '../change-password-form';
import BasicModal from '../../modals/basic-modal';

import classnames from 'classnames';

import { phoneError, nameError, dobError, zipError, emptyInputError } from '../../../services/validate';
import { roleAbilityTypes, roleFeatureTypes } from '../../../userConfig';
import { getSize, getExtention, getAddressFields } from '../../../services/helpers';
import notificationSettings from '../../../services/notificationSettings';
import moment from 'moment';

import { store } from 'react-notifications-component';
import { Form } from 'semantic-ui-react';

import './style.scss';

class EditPersonalInfoForm extends PureComponent {
    constructor(props) {
        super(props);
        const { name, surname, gender, image, dob, city, address1, latitude, longitude, zipCode, phone, pharmacyName } =
            this.props.data;

        this.state = {
            name,
            surname: surname || '',
            gender: gender || 'Male',
            dateOfBirth: new Date(dob) || '',
            phone: phone || '',
            city: city || '',
            address: address1 || '',
            zipCode: zipCode || '',
            avatar: image || '',
            pharmacyName: pharmacyName || '',
            latitude: latitude || null,
            longitude: longitude || null,
            uploadedFiles: {
                avatar: '',
                avatarURL: ''
            },
            modals: {
                changePassword: false,
                changePasswordConfirm: false
            },
            errors: {
                name: null,
                surname: null,
                gender: null,
                dateOfBirth: null,
                phone: null,
                address: null,
                avatar: null,
                zipCode: null,
                pharmacyName: null,
                form: null
            }
        };
    }

    triggerInputFile = (e) => {
        e.preventDefault();
        this.fileInput.click();
    };

    toggleModal = (name) => {
        const { modals } = this.state;
        const currentState = modals[name];

        return this.setState({
            modals: { ...modals, [name]: !currentState }
        });
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;

        switch (name) {
            case 'name':
            case 'surname':
            case 'city':
            case 'pharmacyName':
                error = nameError(value);
                break;
            case 'address':
                error = emptyInputError(value);
                break;
            case 'zipCode':
                error = zipError(value);
                break;
            case 'phone':
                error = value ? phoneError(value) : 'This field is requiered';
                break;
            case 'dateOfBirth':
                error = dobError(value);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    }
                }),
                resolve
            );
        });
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        let inputValue = value;
        let setCoords = {};

        if (name === 'address1') {
            setCoords = {
                latitude: null,
                longitude: null
            };
        }

        if (name === 'name' || name === 'surname') {
            inputValue = value.replace(/[0-9]/g, '');
        }

        return this.setState(
            {
                [name]: /^\s/.test(inputValue) ? '' : inputValue,
                ...setCoords
            },
            () => this.validate(name)
        );
    };

    handleAddressSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            let getStateProps = {
                zipCode: 'postal_code',
                city: ['locality', 'postal_town']
            };
            let getAddressProps = {
                streetNumber: 'street_number',
                streetName: 'route',
                district: ['administrative_area_level_2', 'political']
            };
            const getAddressLine = Object.values(getAddressFields(results[0], getAddressProps)).join(', ');
            const getFieldsResult = getAddressFields(results[0], getStateProps);
            const { lat, lng } = await getLatLng(results[0]);

            this.setState(
                (prevState) => ({
                    ...prevState,
                    address: getAddressLine,
                    latitude: lat,
                    longitude: lng,
                    ...getFieldsResult
                }),
                () => Promise.all([this.validate('address'), this.validate('zipCode')])
            );
        } catch (error) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Error',
                message: 'No results found',
                type: 'danger'
            });
        }
    };

    onLocationBlurHandler = () => {
        const { latitude, longitude } = this.state;

        if (!latitude && !longitude) {
            this.setState((prevState) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    address: 'You should choose the address from the dropdown list'
                }
            }));
        }
    };

    onLogoChange = ({ target: { name, value, files } }) => {
        const { errors } = this.state;
        const file = files[0];
        let errorMessage = null;

        // validate uploaded file
        errorMessage = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.bmp']);
        errorMessage = getSize(file);

        // display error message
        if (errorMessage !== null) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Warning',
                message: errorMessage,
                type: 'warning'
            });
        }

        this.setState({
            uploadedFiles: {
                [name]: file,
                [name + 'URL']: URL.createObjectURL(file)
            },
            [name]: value,
            errors: {
                ...errors,
                form: errorMessage
            }
        });
    };

    onChangePassSuccess = (password) => {
        const { profileResetPassSubmit } = this.props;
        profileResetPassSubmit({
            password,
            toggleModal: () => {
                this.toggleModal('changePassword');
            }
        });
    };

    onFileRemoveHandler = (name) => {
        const {
            data: { image },
            onRemoveFile
        } = this.props;

        if (image) {
            onRemoveFile({
                profileType: 'userProfile',
                fileName: 'image'
            });
        }

        this.setState((prevState) => ({
            ...prevState,
            [name]: '',
            uploadedFiles: {
                ...prevState.uploadedFiles,
                [name]: '',
                [`${name}URL`]: ''
            }
        }));
    };

    onSubmitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { onSaveClick, isSuperAdmin, isPharmacyAdmin } = this.props;

        let validateArray = [];
        if (isSuperAdmin) {
            validateArray = [this.validate('name'), this.validate('surname')];
        } else if (isPharmacyAdmin) {
            validateArray = [
                this.validate('name'),
                this.validate('surname'),
                this.validate('phone'),
                this.validate('city'),
                this.validate('address'),
                this.validate('dateOfBirth'),
                this.validate('zipCode'),
                this.validate('pharmacyName')
            ];
        } else {
            validateArray = [
                this.validate('name'),
                this.validate('surname'),
                this.validate('phone'),
                this.validate('city'),
                this.validate('address'),
                this.validate('dateOfBirth'),
                this.validate('zipCode')
            ];
        }

        await Promise.all(validateArray);

        const {
            name,
            surname,
            gender,
            dateOfBirth,
            phone,
            city,
            address,
            latitude,
            longitude,
            zipCode,
            pharmacyName,
            uploadedFiles,
            errors
        } = this.state;

        if (Object.values(errors).some((error) => !!error)) return;

        let personalInfoData;
        if (isSuperAdmin) {
            personalInfoData = {
                name,
                surname
            };
        } else if (isPharmacyAdmin) {
            personalInfoData = {
                name,
                surname,
                gender,
                phone,
                city,
                address1: address,
                latitude,
                longitude,
                zipCode,
                pharmacyName,
                dob: moment(dateOfBirth).format('YYYY-MM-DD')
            };
        } else {
            personalInfoData = {
                name,
                surname,
                gender,
                phone,
                city,
                address,
                zipCode,
                dob: moment(dateOfBirth).format('YYYY-MM-DD')
            };
        }

        // check if image was uploaded
        if (uploadedFiles.avatar) personalInfoData.image = uploadedFiles.avatar;

        onSaveClick(personalInfoData);
    };

    render() {
        const {
            name,
            surname,
            gender,
            phone,
            dateOfBirth,
            city,
            address,
            zipCode,
            avatar,
            pharmacyName,
            uploadedFiles,
            errors,
            modals: { changePassword }
        } = this.state;
        const {
            onCancelClick,
            loading,
            removeFileLoading,
            changePassLoading,
            userEmail,
            isSuperAdmin,
            isPersonalProfile
        } = this.props;

        const radioButtons = [
            {
                id: 0,
                label: 'Male',
                name: 'gender',
                value: 'Male',
                checked: gender === 'Male',
                onChange: this.onChangeHandler
            },
            {
                id: 1,
                label: 'Female',
                name: 'gender',
                value: 'Female',
                checked: gender === 'Female',
                onChange: this.onChangeHandler
            }
        ];

        const heading = isPersonalProfile ? 'Personal Information' : 'Profile Information';

        return (
            <>
                <div className="pharmacy-info-page__head">
                    <h3 className="pharmacy-info-page__title">{heading}</h3>
                    <div className="edit-branch-form__row buttons-row">
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="small"
                            onClick={onCancelClick}
                            disabled={loading}
                        />
                        <ButtonComponent
                            value="Save"
                            onClick={(e) => {
                                e.preventDefault();
                                this.onSubmitHandler(e);
                            }}
                            size="medium"
                            loading={loading}
                            disabled={Object.values(errors).some((error) => !!error)}
                        />
                    </div>
                </div>
                <Form className="edit-personal-info-form" autoComplete="off" onSubmit={this.onSubmitHandler}>
                    <div
                        className={classnames('edit-personal-info-form__row', {
                            column: isSuperAdmin
                        })}
                    >
                        <div className="col">
                            <TextInput
                                name="name"
                                label="Name"
                                type="text"
                                value={name}
                                error={!!errors.name}
                                helperText={errors.name}
                                onChange={this.onChangeHandler}
                            />
                            <TextInput
                                name="surname"
                                label="Surname"
                                type="text"
                                value={surname}
                                error={!!errors.surname}
                                helperText={errors.surname}
                                onChange={this.onChangeHandler}
                            />
                            <RadioGroup items={radioButtons} groupLabel="Gender" hidden={isSuperAdmin} />
                            <Datepicker
                                name="dateOfBirth"
                                value={dateOfBirth}
                                label="Date of Birth"
                                type="text"
                                error={!!errors.dateOfBirth}
                                helperText={errors.dateOfBirth}
                                onChange={this.onChangeHandler}
                                maxDate={new Date()}
                                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                                selected
                                disableFuture
                                hidden={isSuperAdmin}
                            />
                            <Can do={roleAbilityTypes.MANAGE_ABILITY} on={roleFeatureTypes.PHARMACY_EDIT}>
                                <TextInput
                                    name="pharmacyName"
                                    label="pharmacy"
                                    type="text"
                                    value={pharmacyName}
                                    error={!!errors.pharmacyName}
                                    helperText={errors.pharmacyName}
                                    onChange={this.onChangeHandler}
                                />
                            </Can>
                            <div className="input-row security-btn-holder">
                                <label className="upload-label">Security</label>
                                <ButtonComponent
                                    value="Change Password"
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        this.toggleModal('changePassword');
                                    }}
                                    size="medium"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div
                            className={classnames('col', {
                                leftAligned: isSuperAdmin
                            })}
                        >
                            <TextInput
                                name="phone"
                                label="Phone"
                                type="tel"
                                value={phone}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                onChange={this.onChangeHandler}
                                hidden={isSuperAdmin}
                            />
                            <TextInput
                                name="city"
                                label="City"
                                type="text"
                                value={city}
                                fluid={false}
                                error={!!errors.city}
                                helperText={errors.city}
                                onChange={this.onChangeHandler}
                                hidden={isSuperAdmin}
                            />
                            <LocationSearchInput
                                name="address"
                                label="Address"
                                type="text"
                                value={address}
                                searchOptions={{
                                    componentRestrictions: { country: 'uk' }
                                }}
                                fluid={false}
                                error={!!errors.address}
                                helperText={errors.address}
                                onChangeHandler={this.onChangeHandler}
                                onHandleSelect={this.handleAddressSelect}
                                onBlur={() => this.onLocationBlurHandler('address1')}
                                hidden={isSuperAdmin}
                            />
                            <TextInput
                                name="zipCode"
                                label="Post Code"
                                type="text"
                                value={zipCode}
                                fluid={false}
                                error={!!errors.zipCode}
                                helperText={errors.zipCode}
                                onChange={this.onChangeHandler}
                                hidden={isSuperAdmin}
                            />
                            <div className="input-row avatar-holder">
                                <label className="upload-label">Avatar</label>
                                <UploadFileHandler
                                    inputName="avatar"
                                    buttonValue="Upload"
                                    stateProp={avatar}
                                    isAvatar
                                    uploadedFile={uploadedFiles.avatar}
                                    avatarURL={uploadedFiles.avatarURL}
                                    onChangeHandler={this.onLogoChange}
                                    onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                    refFunc={(fileInput) => (this.fileInput = fileInput)}
                                    triggerInput={this.triggerInputFile}
                                    loading={removeFileLoading}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
                {changePassword && (
                    <BasicModal
                        open={changePassword}
                        onClose={() => this.toggleModal('changePassword')}
                        title="Change password"
                    >
                        <ChangePasswordForm
                            onSuccess={(password) => this.onChangePassSuccess(password)}
                            loading={changePassLoading}
                            defaultEmail={userEmail}
                            onCancelClick={() => this.toggleModal('changePassword')}
                        />
                    </BasicModal>
                )}
            </>
        );
    }
}

EditPersonalInfoForm.propTypes = {
    isSuperAdmin: PropTypes.bool,
    isPharmacyAdmin: PropTypes.bool,
    onCancelClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onRemoveFile: PropTypes.func.isRequired
};

export default EditPersonalInfoForm;
