import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../../../../../components/UI/buttons/button';
import InfoTabs from '../../../../../components/info-tabs';
import UploadedDoc from '../UploadedDoc';

import { AbilityContext } from '../../../../../services/ability';
import { roleAbilityTypes, roleFeatureTypes } from '../../../../../userConfig';

function ExperienceBoard({
    activeView,
    onTabClick,
    accessStatus,
    isPersonalProfile,
    onUserAction,
    loading,
    onChangeView,
    onSaveFile,
    data: {
        MBSBlistersTrays,
        careHomes,
        cv,
        independentPrescriber,
        methadonePatients,
        pharmacySystems,
        sellingMedication,
        sellingMedicationText
    }
}) {
    const ability = useContext(AbilityContext);

    const tabs = [
        {
            title: 'Personal',
            onClick: () => onTabClick('Personal'),
            isActive: false
        },
        {
            title: 'Right to Work',
            onClick: () => onTabClick('RightToWork'),
            isActive: false
        },
        {
            title: 'Experience',
            onClick: () => onTabClick('Experience'),
            isActive: activeView === 'Experience'
        },
        {
            title: 'Qualifications',
            onClick: () => onTabClick('Qualifications'),
            isActive: false
        }
    ];

    let userControlButtons = null;

    switch (accessStatus) {
        case 'Pending user':
            userControlButtons = (
                <div className="admin-controls">
                    <ButtonComponent
                        value="Reject"
                        type="bordered"
                        size="small"
                        colorType="bordered-danger"
                        loading={loading.reject}
                        onClick={() => onUserAction('reject')}
                    />
                    <ButtonComponent
                        value="Approve"
                        size="medium"
                        colorType="green"
                        loading={loading.approve}
                        onClick={() => onUserAction('approve')}
                    />
                </div>
            );
            break;
        case 'Active user':
            userControlButtons = (
                <ButtonComponent
                    value="Deactivate"
                    type="bordered"
                    size="small"
                    colorType="bordered-danger"
                    loading={loading.deactivate}
                    onClick={() => onUserAction('deactivate')}
                />
            );
            break;
        case 'Deavtivated user':
            userControlButtons = (
                <ButtonComponent
                    value="Activate"
                    type="bordered"
                    size="small"
                    colorType="green"
                    loading={loading.activate}
                    onClick={() => onUserAction('activate')}
                />
            );
            break;
        case 'Staff personal info':
        case 'Locum personal info':
            userControlButtons = (
                <ButtonComponent value="Edit" size="small" onClick={() => onChangeView('EditExperience')} />
            );
            break;
        default:
            userControlButtons = null;
    }

    const heading = isPersonalProfile ? 'Personal Information' : 'Information';

    const displayCertificates = pharmacySystems.map((el) => {
        return (
            <div className="certif-wrapper" key={el.title}>
                <span className="text">{el.title}</span>
            </div>
        );
    });

    const getBoolianValue = (value) => (!!value ? 'Yes' : 'No');

    const isAdminPermission = ability.can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE);

    return (
        <>
            <div className="staff-info-page__header">
                <h3 className="staff-info-page__title">{heading}</h3>
                <div className="staff-info-page__row buttons-row with-tabs">
                    <InfoTabs tabs={tabs} />
                    {userControlButtons}
                </div>
            </div>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">Experience</h4>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Pharmacy System Used:</p>
                                <div className="description list">
                                    {!displayCertificates.find((el) => el !== null) ? 'None' : displayCertificates}
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">CV</p>
                                {cv && !isAdminPermission ? (
                                    <UploadedDoc title={cv} />
                                ) : cv && isAdminPermission ? (
                                    <ButtonComponent
                                        value="Download"
                                        size="medium"
                                        className="dowload-btn"
                                        onClick={() => onSaveFile(cv, 'cv')}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col no-title">
                        <div className="info-box">
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Are you independent prescriber?</p>
                                <div className="description">
                                    {independentPrescriber === null ? 'None' : getBoolianValue(independentPrescriber)}
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Have you deal with methadone patients?</p>
                                <div className="description">
                                    {methadonePatients === null ? 'None' : getBoolianValue(methadonePatients)}
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Are you comfortable with MDS/Blisters/Trays?</p>
                                <div className="description">
                                    {MBSBlistersTrays === null ? 'None' : getBoolianValue(MBSBlistersTrays)}
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Have you worked in a pharmacy that deals with care homes?</p>
                                <div className="description">
                                    {careHomes === null ? 'None' : getBoolianValue(careHomes)}
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Do you have any obligation selling any particular medication?</p>
                                <div className="description">
                                    {sellingMedication === null ? 'None' : getBoolianValue(sellingMedication)}
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Obligations</p>
                                <div className="description">{sellingMedicationText || 'None'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

ExperienceBoard.propTypes = {
    data: PropTypes.object,
    activeView: PropTypes.string.isRequired,
    onTabClick: PropTypes.func.isRequired,
    onSaveFile: PropTypes.func.isRequired,
    accessStatus: PropTypes.string,
    onUserAction: PropTypes.func,
    loading: PropTypes.object,
    onChangeView: PropTypes.func,
    isPersonalProfile: PropTypes.bool
};

export default ExperienceBoard;
