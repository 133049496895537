import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkbox } from 'semantic-ui-react';

import './style.scss';

const CustomCheckbox = React.forwardRef(
    ({ name, label, checked, size, onChange, disabled = false, className }, ref) => {
        return (
            <Checkbox
                ref={ref}
                className={classnames(`custom-checkbox`, {
                    [className]: className,
                    [size]: size
                })}
                name={name}
                label={label}
                disabled={disabled}
                onChange={onChange}
                checked={checked}
            />
        );
    }
);

CustomCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default CustomCheckbox;
