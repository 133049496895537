import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getBranchStaffSuccess,
    getBranchStaffFailed,
    getAllStaffSuccess,
    getAllStaffFailed,
    postStaffAbsenceFailed,
    postStaffAbsenceSuccess
} from '../../../actions';
import * as branchStaffActionTypes from '../../../actions/admin-view/branch-staff/action-types';
import api from '../../../../services/api';

function* onBranchStaffRequest({ id }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${id}/staff`,
            method: 'get'
        });
        yield put(getBranchStaffSuccess(data));
    } catch (err) {
        yield put(getBranchStaffFailed());
    }
}

function* onAllStaffRequest({ id }) {
    try {
        const { data } = yield call(api.request, {
            url: '/staff/all-staff',
            method: 'get'
        });
        yield put(getAllStaffSuccess(data));
    } catch (err) {
        yield put(getAllStaffFailed());
    }
}

function* onPostStaffAbsenceRequest({ payload: { id, absenceData, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${id}/schedule/absence`,
            method: 'post',
            payload: {
                absenceData
            }
        });
        yield callback();
        yield put(postStaffAbsenceSuccess({ absenceData: data }));
    } catch (err) {
        yield put(postStaffAbsenceFailed());
    }
}

// watchers
function* watchBranchStaffRequest() {
    yield takeEvery(branchStaffActionTypes.GET_BRANCH_STAFF_REQUEST, onBranchStaffRequest);
}

function* watchAllStaffRequest() {
    yield takeEvery(branchStaffActionTypes.GET_ALL_STAFF_REQUEST, onAllStaffRequest);
}

function* watchPostStaffAbsenceRequest() {
    yield takeEvery(branchStaffActionTypes.POST_STAFF_ABSENCE_REQUEST, onPostStaffAbsenceRequest);
}

const branchStaffSaga = [fork(watchBranchStaffRequest), fork(watchAllStaffRequest), fork(watchPostStaffAbsenceRequest)];

export default branchStaffSaga;
