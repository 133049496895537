import { call, put, fork, select, takeLeading } from 'redux-saga/effects';
import { getStaffUserEventsSuccess, getStaffUserEventsFailed } from '../../../actions';
import { selectDateRange } from './selectors';
import * as staffUserScheduleActionTypes from '../../../actions/shared/schedule/action-types';
import api from '../../../../services/api';

function* onStaffUserEventsRequest() {
    const { startDateRange, endDateRange } = yield select(selectDateRange);

    try {
        const { data } = yield call(api.request, {
            url: '/personal/schedule',
            method: 'post',
            payload: {
                startDateRange,
                endDateRange
            }
        });
        yield put(getStaffUserEventsSuccess(data));
    } catch (err) {
        yield put(getStaffUserEventsFailed());
    }
}

function* watchStaffUserEventsRequest() {
    yield takeLeading(staffUserScheduleActionTypes.GET_USER_EVENTS_REQUEST, onStaffUserEventsRequest);
}

const staffUserScheduleSaga = [fork(watchStaffUserEventsRequest)];

export default staffUserScheduleSaga;
