export const userRoles = {
    ADMIN: 'admin',
    MODERATOR: 'moderator',
    BRANCH_ADMIN: 'branch_admin',
    PHARMACY_MANAGER: 'pharmacy_manager',
    RELIEF: 'relief',
    BRANCH_PHARMACIST: 'branch_pharmacist',
    TECHNICIAN: 'technician',
    DISPENSER: 'dispenser',
    MEDICINE_COUNTER: 'medicine_counter',
    LOCUM: 'locum'
};

export const userStatusesList = {
    ONBOARDING: 'onboarding',
    PENDING: 'pending',
    ACTIVE: 'active',
    NEW: 'new',
    REJECTED: 'rejected',
    DEACTIVATED: 'deactivated'
};

export const locumStatusesList = {
    ACCEPTED: 'accepted',
    APPLIED: 'applied',
    REJECTED: 'rejected',
    INVITED: 'invited',
    DECLINED: 'declined'
};

// types of abilities
export const roleAbilityTypes = {
    CREATE_ABILITY: 'create',
    READ_ABILITY: 'read',
    UPDATE_ABILITY: 'update',
    DELETE_ABILITY: 'delete',
    MANAGE_ABILITY: 'manage'
};

// user features abilities
export const roleFeatureTypes = {
    STAFF_EDIT_FEATURE: 'staff_edit',
    STAFF_PERSONAL_INFO_EDIT: 'staff_personal_info_edit',
    LOCUM_PERSONAL_INFO_EDIT: 'locum_personal_info_edit',
    STAFF_PERSONAL_INFO_GPHC_VIEW: 'staff_personal_info_gphc_view',
    PHARMACY_EDIT: 'pharmacy_edit'
};

// google places api config
export const searchAddressConfig = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'short_name',
    political: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
    postal_town: 'long_name'
};
