import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TextInput from './../../../UI/inputs/text-input';
import Datepicker from '../../../UI/datepicker';
import ButtonComponent from './../../../UI/buttons/button';
import SelectComponent from './../../../UI/select';
import UploadFileHandler from '../../../upload-remove-file-handler';
import PopupComponent from '../../../popup';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info-icon.svg';

import { insuranceNumberError, infoInputError } from '../../../../services/validate';
import { getSize, getExtention, getListOfYears, isOnboardingFileUploaded } from '../../../../services/helpers';
import notificationSettings from '../../../../services/notificationSettings';
import Moment from 'moment';

import { store } from 'react-notifications-component';

import { Form } from 'semantic-ui-react';

import './style.scss';

const currentYear = new Date().getFullYear();

// convert into correct array of objects
const formatedListOfYears = getListOfYears(currentYear, 1900, -1).map((option, index) => {
    return {
        key: index,
        value: option,
        text: option
    };
});

class RightToWorkForm extends Component {
    state = {
        identificationExpirationDate: '',
        identificationUpload: '',
        insuranceExpirationDate: '',
        insuranceUpload: '',
        visaExpirationDate: '',
        visaUpload: '',
        certificateDate: '',
        certificateUpload: '',
        nationalInsuranceNumber: '',
        nationalInsuranceUpload: '',
        dbsAnnual: '',
        DBSAnnualUpdateDoc: '',
        qualifications: '',
        yearOfQualification: '',
        formatedListOfYears,
        filledYearOfQualification: '',
        uploadedFiles: {
            identificationUpload: '',
            insuranceUpload: '',
            visaUpload: '',
            certificateUpload: '',
            nationalInsuranceUpload: '',
            DBSAnnualUpdateDoc: ''
        },
        errors: {
            identificationExpirationDate: null,
            insuranceExpirationDate: null,
            visaExpirationDate: null,
            certificateDate: null,
            nationalInsuranceNumber: null,
            dbsAnnual: null,
            qualifications: null,
            yearOfQualification: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;

        const { filledForm } = this.props;

        if (filledForm) {
            const { formatedListOfYears } = this.state;
            const {
                DBSAnnualUpdate,
                DBSAnnualUpdateDoc,
                DBSCertificate,
                DBSCertificateDate,
                identification,
                identificationExpDate,
                insuranceNumber,
                insuranceNumberDoc,
                professionalInsurance,
                professionalInsuranceExpDate,
                qualifications,
                qualificationsYear,
                visa,
                visaExpDate
            } = filledForm;

            let setYearOfQualification;
            formatedListOfYears.forEach((el) => {
                if (Number(qualificationsYear) === el.value) {
                    setYearOfQualification = el.value;
                }
            });

            this.setState({
                identificationExpirationDate: !identificationExpDate ? '' : new Date(identificationExpDate),
                identificationUpload: identification || '',
                insuranceExpirationDate: !professionalInsuranceExpDate ? '' : new Date(professionalInsuranceExpDate),
                insuranceUpload: professionalInsurance || '',
                visaExpirationDate: !visaExpDate ? '' : new Date(visaExpDate),
                visaUpload: visa || '',
                certificateDate: !DBSCertificateDate ? '' : new Date(DBSCertificateDate),
                certificateUpload: DBSCertificate || '',
                nationalInsuranceNumber: insuranceNumber || '',
                nationalInsuranceUpload: insuranceNumberDoc || '',
                dbsAnnual: DBSAnnualUpdate || '',
                DBSAnnualUpdateDoc: DBSAnnualUpdateDoc || '',
                qualifications: !qualifications ? '' : qualifications,
                yearOfQualification: setYearOfQualification,
                filledYearOfQualification: setYearOfQualification
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    triggerInputFile = (name) => this[name].click();

    validate = async (name) => {
        const value = this.state[name];
        let error = null;

        switch (name) {
            case 'nationalInsuranceNumber':
                error = insuranceNumberError(value);
                break;
            case 'dbsAnnual':
            case 'qualifications':
                error = infoInputError(value, true);
                break;
            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    }
                }),
                resolve
            );
        });
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () => this.validate(name)
        );
    };

    onFileRemoveHandler = (name) => {
        const {
            filledForm: {
                insuranceNumberDoc,
                DBSCertificate,
                visa,
                identification,
                professionalInsurance,
                DBSAnnualUpdateDoc
            },
            onRemoveRigthToWorkFile
        } = this.props;
        const { uploadedFiles } = this.state;
        let payload = {};

        if (name === 'identificationUpload' && identification) {
            payload = {
                profileType: 'profileRightToWork',
                fileName: 'identification'
            };
        } else if (name === 'visaUpload' && visa) {
            payload = {
                profileType: 'profileRightToWork',
                fileName: 'visa'
            };
        } else if (name === 'nationalInsuranceUpload' && insuranceNumberDoc) {
            payload = {
                profileType: 'profileRightToWork',
                fileName: 'insuranceNumberDoc'
            };
        } else if (name === 'insuranceUpload' && professionalInsurance) {
            payload = {
                profileType: 'profileRightToWork',
                fileName: 'professionalInsurance'
            };
        } else if (name === 'certificateUpload' && DBSCertificate) {
            payload = {
                profileType: 'profileRightToWork',
                fileName: 'DBSCertificate'
            };
        } else if (name === 'DBSAnnualUpdateDoc' && DBSAnnualUpdateDoc) {
            payload = {
                profileType: 'profileRightToWork',
                fileName: 'DBSAnnualUpdateDoc'
            };
        }

        const isUploaded = isOnboardingFileUploaded(name, uploadedFiles);
        if (isUploaded) {
            onRemoveRigthToWorkFile(payload);
        }

        this.setState((prevState) => ({
            ...prevState,
            [name]: '',
            uploadedFiles: {
                ...prevState.uploadedFiles,
                [name]: ''
            },
            errors: {
                ...prevState.errors,
                [`${name}Doc`]: null
            }
        }));
    };

    onFileUpload = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const file = e.target.files[0];
        let errorMessage = {};
        let error = null;

        // validate uploaded file
        errorMessage.extention = getExtention(file.name, ['.jpg', '.jpeg', '.png', '.pdf']);
        errorMessage.size = getSize(file);

        // display error message
        Object.values(errorMessage).some((err) => (!!err ? (error = err) : false));

        if (error) {
            store.addNotification({
                ...notificationSettings.settings,
                title: 'Warning',
                message: error,
                type: 'warning'
            });
        }

        this.setState((prevState) => ({
            uploadedFiles: {
                ...prevState.uploadedFiles,
                [name]: file
            },
            [name]: value,
            errors: {
                ...prevState.errors,
                [`${name}Doc`]: error
            }
        }));
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const {
            identificationExpirationDate,
            insuranceExpirationDate,
            visaExpirationDate,
            certificateDate,
            nationalInsuranceNumber,
            dbsAnnual,
            qualifications,
            yearOfQualification,
            uploadedFiles
        } = this.state;
        const { onSuccess } = this.props;

        const rightToWorkData = {
            identificationExpDate: !identificationExpirationDate
                ? ''
                : Moment(identificationExpirationDate).format('YYYY-MM-DD'),
            visaExpDate: !visaExpirationDate ? '' : Moment(visaExpirationDate).format('YYYY-MM-DD'),
            DBSCertificateDate: !certificateDate ? '' : Moment(certificateDate).format('YYYY-MM-DD'),
            insuranceNumber: nationalInsuranceNumber || '',
            professionalInsuranceExpDate: !insuranceExpirationDate
                ? ''
                : Moment(insuranceExpirationDate).format('YYYY-MM-DD'),
            DBSAnnualUpdate: dbsAnnual || '',
            qualifications,
            qualificationsYear: !yearOfQualification ? '' : String(yearOfQualification)
        };

        if (uploadedFiles.nationalInsuranceUpload) {
            rightToWorkData.insuranceNumberDoc = uploadedFiles.nationalInsuranceUpload;
        }
        if (uploadedFiles.identificationUpload) {
            rightToWorkData.identification = uploadedFiles.identificationUpload;
        }
        if (uploadedFiles.visaUpload) {
            rightToWorkData.visa = uploadedFiles.visaUpload;
        }
        if (uploadedFiles.certificateUpload) {
            rightToWorkData.DBSCertificate = uploadedFiles.certificateUpload;
        }
        if (uploadedFiles.insuranceUpload) {
            rightToWorkData.professionalInsurance = uploadedFiles.insuranceUpload;
        }
        if (uploadedFiles.DBSAnnualUpdateDoc) {
            rightToWorkData.DBSAnnualUpdateDoc = uploadedFiles.DBSAnnualUpdateDoc;
        }

        onSuccess(rightToWorkData);
    };

    render() {
        const {
            identificationUpload,
            insuranceUpload,
            visaUpload,
            certificateUpload,
            nationalInsuranceNumber,
            nationalInsuranceUpload,
            identificationExpirationDate,
            visaExpirationDate,
            insuranceExpirationDate,
            certificateDate,
            dbsAnnual,
            DBSAnnualUpdateDoc,
            qualifications,
            formatedListOfYears,
            filledYearOfQualification,
            errors,
            uploadedFiles
        } = this.state;
        const { loading, removingLoading, onGoBack, isEditProfile } = this.props;

        let heading,
            subHeading = null;

        if (isEditProfile) {
            heading = <h3 className="staff-info-page__title">Personal Information</h3>;
            subHeading = <h4 className="row-title">Right to work</h4>;
        }

        return (
            <Form className={classnames('right-to-work-form', { isEditProfile })} autoComplete="off" loading={loading}>
                <div className="right-to-work-form__row">
                    {subHeading}
                    <div className="col">
                        <div className="col__upload-inputs-row">
                            <label className="info-label">
                                Identification
                                <PopupComponent
                                    triggerComponent={<InfoIcon className="info-icon" />}
                                    event="hover"
                                    position="top center"
                                    color="grey"
                                    size="small"
                                    smallPaddings
                                >
                                    <p className="info-text">Passport, Driver License, Biometric Card, etc.</p>
                                </PopupComponent>
                            </label>
                            <Datepicker
                                name="identificationExpirationDate"
                                type="text"
                                placeholder="Expiration Date"
                                fluid={false}
                                value={identificationExpirationDate}
                                error={!!errors.identificationExpirationDate}
                                helperText={errors.identificationExpirationDate}
                                onChange={this.onChangeHandler}
                                minDate={new Date()}
                            />
                            <UploadFileHandler
                                inputName="identificationUpload"
                                buttonValue="Upload document"
                                acceptFormats=".jpg, .jpeg, .png, .pdf"
                                stateProp={identificationUpload}
                                uploadedFile={uploadedFiles.identificationUpload}
                                onChangeHandler={this.onFileUpload}
                                onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                refFunc={(identificationUpload) => (this.identificationUpload = identificationUpload)}
                                triggerInput={() => this.triggerInputFile('identificationUpload')}
                                loading={removingLoading}
                            />
                        </div>
                        <div className="col__upload-inputs-row">
                            <Datepicker
                                name="insuranceExpirationDate"
                                label="Professional Indemnity Insurance"
                                type="text"
                                placeholder="Expiration Date"
                                fluid={false}
                                value={insuranceExpirationDate}
                                error={!!errors.insuranceExpirationDate}
                                helperText={errors.insuranceExpirationDate}
                                onChange={this.onChangeHandler}
                                minDate={new Date()}
                            />
                            <UploadFileHandler
                                inputName="insuranceUpload"
                                buttonValue="Upload document"
                                acceptFormats=".jpg, .jpeg, .png, .pdf"
                                stateProp={insuranceUpload}
                                uploadedFile={uploadedFiles.insuranceUpload}
                                onChangeHandler={this.onFileUpload}
                                onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                refFunc={(insuranceUpload) => (this.insuranceUpload = insuranceUpload)}
                                triggerInput={() => this.triggerInputFile('insuranceUpload')}
                                loading={removingLoading}
                            />
                        </div>
                        <div className="col__upload-inputs-row">
                            <label className="info-label">
                                Visa (For Non UK members)
                                <PopupComponent
                                    triggerComponent={<InfoIcon className="info-icon" />}
                                    event="hover"
                                    position="top center"
                                    color="grey"
                                    size="small"
                                    smallPaddings
                                >
                                    <p className="info-text">
                                        If your ID does not demonstrate that you have rights to work in the UK, please
                                        upload a copy of work visa
                                    </p>
                                </PopupComponent>
                            </label>
                            <Datepicker
                                name="visaExpirationDate"
                                type="text"
                                placeholder="Expiration Date"
                                fluid={false}
                                value={visaExpirationDate}
                                error={!!errors.visaExpirationDate}
                                helperText={errors.visaExpirationDate}
                                onChange={this.onChangeHandler}
                                minDate={new Date()}
                            />
                            <UploadFileHandler
                                inputName="visaUpload"
                                buttonValue="Upload document"
                                acceptFormats=".jpg, .jpeg, .png, .pdf"
                                stateProp={visaUpload}
                                uploadedFile={uploadedFiles.visaUpload}
                                onChangeHandler={this.onFileUpload}
                                onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                refFunc={(visaUpload) => (this.visaUpload = visaUpload)}
                                triggerInput={() => this.triggerInputFile('visaUpload')}
                                loading={removingLoading}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="col__upload-inputs-row">
                            <Datepicker
                                name="certificateDate"
                                label="Enhanced DBS Certificate"
                                type="text"
                                placeholder="Certificate Date"
                                fluid={false}
                                value={certificateDate}
                                error={!!errors.certificateDate}
                                helperText={errors.certificateDate}
                                onChange={this.onChangeHandler}
                                minDate={new Date()}
                            />
                            <UploadFileHandler
                                inputName="certificateUpload"
                                buttonValue="Upload document"
                                acceptFormats=".jpg, .jpeg, .png, .pdf"
                                stateProp={certificateUpload}
                                uploadedFile={uploadedFiles.certificateUpload}
                                onChangeHandler={this.onFileUpload}
                                onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                refFunc={(certificateUpload) => (this.certificateUpload = certificateUpload)}
                                triggerInput={() => this.triggerInputFile('certificateUpload')}
                                loading={removingLoading}
                            />
                        </div>
                        <div className="col__upload-inputs-row">
                            <TextInput
                                name="nationalInsuranceNumber"
                                label="National Insurance Number"
                                type="text"
                                placeholder="NI Number"
                                fluid={false}
                                value={nationalInsuranceNumber}
                                error={!!errors.nationalInsuranceNumber}
                                helperText={errors.nationalInsuranceNumber}
                                onChange={this.onChangeHandler}
                            />
                            <UploadFileHandler
                                inputName="nationalInsuranceUpload"
                                buttonValue="Upload document"
                                acceptFormats=".jpg, .jpeg, .png, .pdf"
                                stateProp={nationalInsuranceUpload}
                                uploadedFile={uploadedFiles.nationalInsuranceUpload}
                                onChangeHandler={this.onFileUpload}
                                onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                refFunc={(nationalInsuranceUpload) =>
                                    (this.nationalInsuranceUpload = nationalInsuranceUpload)
                                }
                                triggerInput={() => this.triggerInputFile('nationalInsuranceUpload')}
                                loading={removingLoading}
                            />
                        </div>
                        <div className="col__upload-inputs-row">
                            <TextInput
                                name="dbsAnnual"
                                label="DBS Annual Update"
                                type="text"
                                placeholder="Number"
                                fluid={false}
                                value={dbsAnnual}
                                error={!!errors.dbsAnnual}
                                helperText={errors.dbsAnnual}
                                onChange={this.onChangeHandler}
                            />
                            <UploadFileHandler
                                inputName="DBSAnnualUpdateDoc"
                                buttonValue="Upload document"
                                acceptFormats=".jpg, .jpeg, .png, .pdf"
                                stateProp={DBSAnnualUpdateDoc}
                                uploadedFile={uploadedFiles.DBSAnnualUpdateDoc}
                                onChangeHandler={this.onFileUpload}
                                onRemoveFile={(name) => this.onFileRemoveHandler(name)}
                                refFunc={(DBSAnnualUpdateDoc) => (this.DBSAnnualUpdateDoc = DBSAnnualUpdateDoc)}
                                triggerInput={() => this.triggerInputFile('DBSAnnualUpdateDoc')}
                                loading={removingLoading}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="col__upload-inputs-row">
                            <TextInput
                                name="qualifications"
                                label="Qualifications"
                                type="text"
                                placeholder="MPharm"
                                fluid={false}
                                value={qualifications}
                                error={!!errors.qualifications}
                                helperText={errors.qualifications}
                                onChange={this.onChangeHandler}
                            />
                        </div>
                        <div className="col__upload-inputs-row">
                            <SelectComponent
                                name="yearOfQualification"
                                label="Year Qualified"
                                key={filledYearOfQualification}
                                defaultValue={
                                    filledYearOfQualification
                                        ? formatedListOfYears.find((el) => el.value === filledYearOfQualification).value
                                        : null
                                }
                                placeholder="Year"
                                fluid={false}
                                floating
                                options={formatedListOfYears}
                                error={!!errors.yearOfQualification}
                                helperText={errors.yearOfQualification}
                                onChange={this.onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="right-to-work-form__row button-row">
                    {heading}
                    <div className="buttons-holder">
                        <ButtonComponent
                            value={isEditProfile ? 'Cancel' : 'Back'}
                            type="transparent"
                            onClick={onGoBack}
                            size="medium"
                            disabled={loading}
                        />
                        <ButtonComponent
                            value="Save"
                            onClick={this.onSubmit}
                            size="medium"
                            disabled={Object.values(errors).some((error) => !!error)}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

RightToWorkForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    filledForm: PropTypes.object,
    onRemoveRigthToWorkFile: PropTypes.func.isRequired,
    isEditProfile: PropTypes.bool
};

export default RightToWorkForm;
