import { VerificationPage } from './VerificationPage';
import { connect } from 'react-redux';
import { resendActivationLinkRequest } from '../../../../store/actions';

export default connect(
    (state) => ({
        userEmail: state.auth.user.email
    }),
    {
        resendActivationLinkRequest
    }
)(VerificationPage);
