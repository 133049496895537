export const CREATE_MILEAGE_RULE_REQUEST = 'CREATE_MILEAGE_RULE_REQUEST';
export const CREATE_MILEAGE_RULE_SUCCESS = 'CREATE_MILEAGE_RULE_SUCCESS';
export const CREATE_MILEAGE_RULE_FAILED = 'CREATE_MILEAGE_RULE_FAILED';

export const CREATE_TRANSPORT_RULE_REQUEST = 'CREATE_TRANSPORT_RULE_REQUEST';
export const CREATE_TRANSPORT_RULE_SUCCESS = 'CREATE_TRANSPORT_RULE_SUCCESS';
export const CREATE_TRANSPORT_RULE_FAILED = 'CREATE_TRANSPORT_RULE_FAILED';

export const CREATE_PARKING_RULE_REQUEST = 'CREATE_PARKING_RULE_REQUEST';
export const CREATE_PARKING_RULE_SUCCESS = 'CREATE_PARKING_RULE_SUCCESS';
export const CREATE_PARKING_RULE_FAILED = 'CREATE_PARKING_RULE_FAILED';

export const CREATE_ACCOMMODATION_RULE_REQUEST = 'CREATE_ACCOMMODATION_RULE_REQUEST';
export const CREATE_ACCOMMODATION_RULE_SUCCESS = 'CREATE_ACCOMMODATION_RULE_SUCCESS';
export const CREATE_ACCOMMODATION_RULE_FAILED = 'CREATE_ACCOMMODATION_RULE_FAILED';

export const DELETE_MILEAGE_RULE_REQUEST = 'DELETE_MILEAGE_RULE_REQUEST';
export const DELETE_MILEAGE_RULE_SUCCESS = 'DELETE_MILEAGE_RULE_SUCCESS';
export const DELETE_MILEAGE_RULE_FAILED = 'DELETE_MILEAGE_RULE_FAILED';

export const DELETE_TRANSPORT_RULE_REQUEST = 'DELETE_TRANSPORT_RULE_REQUEST';
export const DELETE_TRANSPORT_RULE_SUCCESS = 'DELETE_TRANSPORT_RULE_SUCCESS';
export const DELETE_TRANSPORT_RULE_FAILED = 'DELETE_TRANSPORT_RULE_FAILED';

export const DELETE_PARKING_RULE_REQUEST = 'DELETE_PARKING_RULE_REQUEST';
export const DELETE_PARKING_RULE_SUCCESS = 'DELETE_PARKING_RULE_SUCCESS';
export const DELETE_PARKING_RULE_FAILED = 'DELETE_PARKING_RULE_FAILED';

export const DELETE_ACCOMMODATION_RULE_REQUEST = 'DELETE_ACCOMMODATION_RULE_REQUEST';
export const DELETE_ACCOMMODATION_RULE_SUCCESS = 'DELETE_ACCOMMODATION_RULE_SUCCESS';
export const DELETE_ACCOMMODATION_RULE_FAILED = 'DELETE_ACCOMMODATION_RULE_FAILED';

export const GET_ALL_MILEAGE_RULES_REQUEST = 'GET_ALL_MILEAGE_RULES_REQUEST';
export const GET_ALL_MILEAGE_RULES_SUCCESS = 'GET_ALL_MILEAGE_RULES_SUCCESS';
export const GET_ALL_MILEAGE_RULES_FAILED = 'GET_ALL_MILEAGE_RULES_FAILED';

export const GET_ALL_TRANSPORT_RULES_REQUEST = 'GET_ALL_TRANSPORT_RULES_REQUEST';
export const GET_ALL_TRANSPORT_RULES_SUCCESS = 'GET_ALL_TRANSPORT_RULES_SUCCESS';
export const GET_ALL_TRANSPORT_RULES_FAILED = 'GET_ALL_TRANSPORT_RULES_FAILED';

export const GET_ALL_PARKING_RULES_REQUEST = 'GET_ALL_PARKING_RULES_REQUEST';
export const GET_ALL_PARKING_RULES_SUCCESS = 'GET_ALL_PARKING_RULES_SUCCESS';
export const GET_ALL_PARKING_RULES_FAILED = 'GET_ALL_PARKING_RULES_FAILED';

export const GET_ALL_ACCOMMODATION_RULES_REQUEST = 'GET_ALL_ACCOMMODATION_RULES_REQUEST';
export const GET_ALL_ACCOMMODATION_RULES_SUCCESS = 'GET_ALL_ACCOMMODATION_RULES_SUCCESS';
export const GET_ALL_ACCOMMODATION_RULES_FAILED = 'GET_ALL_ACCOMMODATION_RULES_FAILED';

export const UPDATE_MASTER_DATE_REQUEST = 'UPDATE_MASTER_DATE_REQUEST';
export const UPDATE_MASTER_DATE_SUCCESS = 'UPDATE_MASTER_DATE_SUCCESS';
export const UPDATE_MASTER_DATE_FAILED = 'UPDATE_MASTER_DATE_FAILED';

export const GET_MASTER_DATE_REQUEST = 'GET_MASTER_DATE_REQUEST';
export const GET_MASTER_DATE_SUCCESS = 'GET_MASTER_DATE_SUCCESS';
export const GET_MASTER_DATE_FAILED = 'GET_MASTER_DATE_FAILED';
