import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TemplateButton = ({ text = '+ add shift', itemId, onClick }) => {
    return (
        <button onClick={onClick} className="template-button">
            {text}
        </button>
    );
};

TemplateButton.propTypes = {
    itemId: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string
};

export default TemplateButton;
