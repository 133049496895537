import React from 'react';
import LoginPage from '../pages/login-page';
import SignupPage from '../pages/signup-page';
import InvitePage from '../pages/invite-page';
import LocumCheckInPage from '../pages/locum-view/check-in-page';

import { Switch, Route, Redirect } from 'react-router-dom';

import { RouterConfig } from '../../routerConfig';

const Authentication = (props) => (
    <Switch>
        <Route path={RouterConfig.login} component={LoginPage} exact />
        <Route path={RouterConfig.signup} component={SignupPage} exact />
        <Route path={RouterConfig.invite} component={InvitePage} exact />
        <Route path={RouterConfig.locumCheckIn} component={LocumCheckInPage} exact />
        <Redirect to={RouterConfig.login} exact />
    </Switch>
);

export default Authentication;
