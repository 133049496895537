import {
    GET_PHARMACIES_LIST_SUCCESS,
    GET_PHARMACIES_LIST_FAILED,
    ON_PHARMACIES_FILTER_CHANGE,
    ON_PHARMACIES_FILTER_FALSE,
    CLEAR_PHARMACIES_FILTERS
} from '../../../actions/super-admin-view/pharmacies-page/action-types';

const initialState = {
    redirectPageTo: null,
    loading: false,
    data: null,
    totalData: 0,
    itemsPerPage: 10,
    isFiltering: false,
    isFilterChanged: false,
    filters: {
        role: '',
        pharmacy: '',
        status: ''
    }
};

const adminPharmaciesPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_PHARMACIES_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                isFilterChanged: false,
                redirectPageTo: null
            };
        case GET_PHARMACIES_LIST_FAILED:
            return state;
        case ON_PHARMACIES_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                },
                isFiltering: true,
                isFilterChanged: true
            };
        case ON_PHARMACIES_FILTER_FALSE:
            return {
                ...state,
                isFiltering: false,
                isFilterChanged: false,
                redirectPageTo: 1
            };
        case CLEAR_PHARMACIES_FILTERS:
            return {
                ...state,
                filters: {
                    role: '',
                    pharmacy: '',
                    status: ''
                },
                isFilterChanged: false
            };
        default:
            return state;
    }
};

export default adminPharmaciesPage;
