export const GET_BRANCH_TEMPLATE_REQUEST = 'GET_BRANCH_TEMPLATE_REQUEST';
export const GET_BRANCH_TEMPLATE_SUCCESS = 'GET_BRANCH_TEMPLATE_SUCCESS';
export const GET_BRANCH_TEMPLATE_FAILED = 'GET_BRANCH_TEMPLATE_FAILED';

export const POST_TEMPLATE_REQUEST = 'POST_TEMPLATE_REQUEST';
export const POST_TEMPLATE_SUCCESS = 'POST_TEMPLATE_SUCCESS';
export const POST_TEMPLATE_FAILED = 'POST_TEMPLATE_FAILED';

export const EDIT_TEMPLATE_REQUEST = 'EDIT_TEMPLATE_REQUEST';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_FAILED = 'EDIT_TEMPLATE_FAILED';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILED = 'DELETE_TEMPLATE_FAILED';

export const SCHEDULE_ACTION_REQUEST = 'SCHEDULE_ACTION_REQUEST';
export const SCHEDULE_ACTION_SUCCESS = 'SCHEDULE_ACTION_SUCCESS';
export const SCHEDULE_ACTION_FAILED = 'SCHEDULE_ACTION_FAILED';

export const TEMPLATE_PUBLISH_REQUEST = 'TEMPLATE_PUBLISH_REQUEST';
export const TEMPLATE_PUBLISH_SUCCESS = 'TEMPLATE_PUBLISH_SUCCESS';
export const TEMPLATE_PUBLISH_FAILED = 'TEMPLATE_PUBLISH_FAILED';
