export const GET_SCHEDULE_SHIFTS_REQUEST = 'GET_SCHEDULE_SHIFTS_REQUEST';
export const GET_SCHEDULE_SHIFTS_SUCCESS = 'GET_SCHEDULE_SHIFTS_SUCCESS';
export const GET_SCHEDULE_SHIFTS_FAILED = 'GET_SCHEDULE_SHIFTS_FAILED';

export const ON_SCHEDULE_FILTER_CHANGE = 'ON_SCHEDULE_FILTER_CHANGE';
export const ON_SCHEDULE_FILTER_FALSE = 'ON_SCHEDULE_FILTER_FALSE';
export const CLEAR_SCHEDULE_FILTERS = 'CLEAR_SCHEDULE_FILTERS';

export const ON_SCHEDULE_DATE_RANGE_CHANGE = 'ON_SCHEDULE_DATE_RANGE_CHANGE';

export const ON_SCHEDULE_POST_JOB_REQUEST = 'ON_SCHEDULE_POST_JOB_REQUEST';
export const ON_SCHEDULE_POST_JOB_SUCCESS = 'ON_SCHEDULE_POST_JOB_SUCCESS';
export const ON_SCHEDULE_POST_JOB_FAILED = 'ON_SCHEDULE_POST_JOB_FAILED';

export const ON_SCHEDULE_SUBMIT_SHIFT_REQUEST = 'ON_SCHEDULE_SUBMIT_SHIFT_REQUEST';
export const ON_SCHEDULE_SUBMIT_SHIFT_SUCCESS = 'ON_SCHEDULE_SUBMIT_SHIFT_SUCCESS';
export const ON_SCHEDULE_SUBMIT_SHIFT_FAILED = 'ON_SCHEDULE_SUBMIT_SHIFT_FAILED';
