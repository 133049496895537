import { SchedulePage } from './SchedulePage';
import { connect } from 'react-redux';
import {
    getCurrentDay,
    getScheduleShiftsRequest,
    onScheduleFilterChange,
    onScheduleDateRangeChange,
    clearScheduleFilters,
    getPostJobDraft,
    separateJob,
    longTermJob,
    onSchedulePostJobRequest,
    getBranchAvailableStaffRequest,
    onScheduleSubmitShiftRequest,
    getLocumByGphcRequest,
    clearLocumData,
    getJobLocumsByFilterRequest,
    clearGetJobLocumsByFilter,
    getAllMileageRulesRequest,
    getAllTransportRulesRequest,
    getAllParkingRulesRequest,
    getAllAccommodationRulesRequest,
    onSubmitBulkShiftRequest,
    onSubmitShiftDraftBulkRequest,
    getAllStaffRequest,
    onPostJobBulkRequest
} from '../../../../store/actions';

export default connect(
    (state) => ({
        currentDay: state.branchSchedule.currentDay,
        staffList: state.branchSchedule.staffList.data,
        allStaff: state.branchStaffPage.data,
        schedulePage: state.schedulePage,
        postJobDraft: state.branchSchedule.postJob.jobData,
        locumData: state.locumData,
        locums: state.postJobForm.data,
        mileageRules: state.pharmacySettingsPage.mileageRules,
        transportRules: state.pharmacySettingsPage.transportRules,
        parkingRules: state.pharmacySettingsPage.parkingRules,
        accommodationRules: state.pharmacySettingsPage.accommodationRules
    }),
    {
        getCurrentDay,
        getScheduleShiftsRequest,
        onScheduleFilterChange,
        onScheduleDateRangeChange,
        clearScheduleFilters,
        getPostJobDraft,
        separateJob,
        longTermJob,
        onSchedulePostJobRequest,
        getBranchAvailableStaffRequest,
        onScheduleSubmitShiftRequest,
        getLocumByGphcRequest,
        clearLocumData,
        getJobLocumsByFilterRequest,
        clearGetJobLocumsByFilter,
        getAllMileageRulesRequest,
        getAllTransportRulesRequest,
        getAllParkingRulesRequest,
        getAllAccommodationRulesRequest,
        onSubmitBulkShiftRequest,
        onSubmitShiftDraftBulkRequest,
        onPostJobBulkRequest,
        getAllStaffRequest
    }
)(SchedulePage);
