import React from 'react';
import PropTypes from 'prop-types';
import Button from './../buttons/button';

import './style.scss';

const UploadButton = ({
    name,
    inputValue,
    acceptFormats,
    buttonValue,
    size,
    label,
    onInputChange,
    buttonType,
    triggerInputFile,
    inputRef
}) => {
    return (
        <div className="upload-button">
            <label>{label}</label>
            <input type="hidden" value={inputValue || ''} name={name} onChange={onInputChange} readOnly />
            <input
                value={inputValue || ''}
                name={name}
                onChange={onInputChange}
                aria-hidden="true"
                className="upload-input"
                type="file"
                accept={acceptFormats}
                ref={inputRef}
            />
            <Button type={buttonType} value={buttonValue} size={size} onClick={triggerInputFile} />
        </div>
    );
};

UploadButton.propTypes = {
    triggerInputFile: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    inputValue: PropTypes.string.isRequired,
    buttonValue: PropTypes.string.isRequired,
    buttonType: PropTypes.string,
    acceptFormats: PropTypes.string
};

export default UploadButton;
