import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { userRoles } from '../../../../../../userConfig';

const RoleItemsList = ({ list, title, branchId }) => {
    const [loaded, setLoaded] = useState(false);
    const staff = list.map((item) => {
        const { id, name, surname, image, email } = item;
        return (
            <Link key={id} to={`/staff/details/${branchId}/${id}`} className="staff-item">
                <img
                    src={image}
                    style={loaded ? { opacity: '1' } : {}}
                    alt={image === undefined || image === null ? '' : 'staff'}
                    className={image === undefined || image === null ? 'error' : ''}
                    onLoad={() => setLoaded(true)}
                />
                <div className="staff-item__content">
                    <p className="title">{`${name} ${surname}`}</p>
                    <p className="desc">{email}</p>
                </div>
            </Link>
        );
    });

    let listTitle;

    switch (title) {
        case userRoles.RELIEF:
            listTitle = 'Relief Managers';
            break;
        case userRoles.PHARMACY_MANAGER:
            listTitle = 'Pharmacy Managers';
            break;
        case userRoles.TECHNICIAN:
            listTitle = 'Technicians';
            break;
        case userRoles.BRANCH_PHARMACIST:
            listTitle = 'Branch Pharmacist';
            break;
        default:
            listTitle = '';
    }

    return (
        <div className="col">
            <h3 className="col__title">{listTitle}</h3>
            {staff}
        </div>
    );
};

RoleItemsList.propTypes = {
    list: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    branchId: PropTypes.string.isRequired
};

export default RoleItemsList;
