import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import ButtonComponent from '../../../../../../components/UI/buttons/button';
import ConfirmModal from '../../../../../../components/modals/confirm-modal';

import { connect } from 'react-redux';
import { onPharmacyStatusChangeRequest } from '../../../../../../store/actions';
import { useModal } from '../../../../../../services/hooks';
import { userStatusesList } from '../../../../../../userConfig';

import './style.scss';

function PharmacyInformation({
    data: { name, surname, pharmacyName, userSubscription, status, branchesCount, staffCount, totalJobsCount },
    loading,
    onPharmacyStatusChangeRequest
}) {
    let { id } = useParams();
    const [pharmacyStatus, setPharmacyStatus] = useModal(false);
    const pharmacyAction = status === userStatusesList.ACTIVE ? 'deactivate' : 'activate';

    const onPharmacyAction = useCallback(
        () =>
            onPharmacyStatusChangeRequest({
                pharmacyId: id,
                action: pharmacyAction,
                toggleModal: () => setPharmacyStatus()
            }),
        [id, onPharmacyStatusChangeRequest, pharmacyAction, setPharmacyStatus]
    );

    const pharmacyActionButton = {
        value: 'Confirm',
        onClick: onPharmacyAction,
        size: 'big',
        width: 'big',
        loading
    };

    return (
        <div className="pharmacy-info-page">
            <h3 className="pharmacy-info-page__title">Information</h3>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">General information</h4>
                            <div className="info-box__item fluid">
                                <p className="name">Title:</p>
                                <div className="description">{pharmacyName}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Pharmacy Admin:</p>
                                <div className="description pharmacy-admin">{`${name} ${surname || ''}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">Stats</h4>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Branches:</p>
                                    <div className="description">{branchesCount}</div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">Staff:</p>
                                    <div className="description">{staffCount}</div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Jobs Total:</p>
                                    <div className="description">{totalJobsCount}</div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">Subscription:</p>
                                    <div className="description">{userSubscription || 'None'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-dashboard__row button-row">
                    <ButtonComponent
                        value={status === userStatusesList.ACTIVE ? 'Deactivate' : 'Activate'}
                        onClick={() => setPharmacyStatus()}
                        size="medium"
                        colorType={status === userStatusesList.ACTIVE ? 'danger' : 'green'}
                        disabled={loading}
                    />
                </div>
            </div>
            {pharmacyStatus && (
                <ConfirmModal
                    open={pharmacyStatus}
                    onClose={() => setPharmacyStatus()}
                    title={`${pharmacyAction} Pharmacy`}
                    text={`Are you sure you want to ${pharmacyAction} this pharmacy?`}
                    button={pharmacyActionButton}
                />
            )}
        </div>
    );
}

PharmacyInformation.propTypes = {
    data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    loading: state.adminPharmacyInfoPage.loading
});

const mapDispatchToProps = {
    onPharmacyStatusChangeRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyInformation);
