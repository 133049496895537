export const GET_PHARMACY_INVOICES_REQUEST = 'GET_PHARMACY_INVOICES_REQUEST';
export const GET_PHARMACY_INVOICES_SUCCESS = 'GET_PHARMACY_INVOICES_SUCCESS';
export const GET_PHARMACY_INVOICES_FAILED = 'GET_PHARMACY_INVOICES_FAILED';

export const GET_PHARMACY_ALL_BRANCHES_REQUEST = 'GET_PHARMACY_ALL_BRANCHES_REQUEST';
export const GET_PHARMACY_ALL_BRANCHES_SUCCESS = 'GET_PHARMACY_ALL_BRANCHES_SUCCESS';
export const GET_PHARMACY_ALL_BRANCHES_FAILED = 'GET_PHARMACY_ALL_BRANCHES_FAILED';

export const GET_PHARMACY_SUBSCRIPTION_REQUEST = 'GET_PHARMACY_SUBSCRIPTION_REQUEST';
export const GET_PHARMACY_SUBSCRIPTION_SUCCESS = 'GET_PHARMACY_SUBSCRIPTION_SUCCESS';
export const GET_PHARMACY_SUBSCRIPTION_FAILED = 'GET_PHARMACY_SUBSCRIPTION_FAILED';

export const GET_PHARMACY_BRANCH_DEACTIVATE_REQUEST = 'GET_PHARMACY_BRANCH_DEACTIVATE_REQUEST';
export const GET_PHARMACY_BRANCH_DEACTIVATE_SUCCESS = 'GET_PHARMACY_BRANCH_DEACTIVATE_SUCCESS';
export const GET_PHARMACY_BRANCH_DEACTIVATE_FAILED = 'GET_PHARMACY_BRANCH_DEACTIVATE_FAILED';

export const PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_REQUEST = 'PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_REQUEST';
export const PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_SUCCESS = 'PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_SUCCESS';
export const PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_FAILED = 'PUT_PHARMACY_UPDATE_SUBSCRIPTIONS_FAILED';

export const CLEAR_INVOICES_LIST = 'CLEAR_INVOICES_LIST';
