import {
    GET_INVITED_STAFF_INFO_REQUEST,
    GET_INVITED_STAFF_INFO_SUCCESS,
    GET_INVITED_STAFF_INFO_FAILED,
    ON_CONFIRM_IDENTITY_REQUEST,
    ON_CONFIRM_IDENTITY_SUCCESS,
    ON_CONFIRM_IDENTITY_FAILED,
    ON_INVITED_STAFF_LOGIN_REQUEST,
    ON_INVITED_STAFF_LOGIN_SUCCESS,
    ON_INVITED_STAFF_LOGIN_FAILED,
    ON_CANCEL_GPHC_VERIFICATION_REQUEST,
    ON_CANCEL_GPHC_VERIFICATION_SUCCESS,
    ON_CANCEL_GPHC_VERIFICATION_FAILED
} from './action-types';

export const getInvitedStaffInfoRequest = (id) => ({
    type: GET_INVITED_STAFF_INFO_REQUEST,
    id
});

export const getInvitedStaffInfoSuccess = (payload) => ({
    type: GET_INVITED_STAFF_INFO_SUCCESS,
    payload
});

export const getInvitedStaffInfoFailed = () => ({
    type: GET_INVITED_STAFF_INFO_FAILED
});

export const onConfirmIdentityRequest = (id, payload) => ({
    type: ON_CONFIRM_IDENTITY_REQUEST,
    id,
    payload
});

export const onConfirmIdentitySuccess = (payload) => ({
    type: ON_CONFIRM_IDENTITY_SUCCESS,
    payload
});

export const onConfirmIdentityFailed = () => ({
    type: ON_CONFIRM_IDENTITY_FAILED
});

export const onInvitedStaffLoginRequest = (payload) => ({
    type: ON_INVITED_STAFF_LOGIN_REQUEST,
    payload
});

export const onInvitedStaffLoginSuccess = () => ({
    type: ON_INVITED_STAFF_LOGIN_SUCCESS
});

export const onInvitedStaffLoginFailed = () => ({
    type: ON_INVITED_STAFF_LOGIN_FAILED
});

export const onCancelGphcVerificationRequest = (payload) => ({
    type: ON_CANCEL_GPHC_VERIFICATION_REQUEST,
    payload
});

export const onCancelGphcVerificationSuccess = () => ({
    type: ON_CANCEL_GPHC_VERIFICATION_SUCCESS
});

export const onCancelGphcVerificationFailed = () => ({
    type: ON_CANCEL_GPHC_VERIFICATION_FAILED
});
