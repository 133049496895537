import React, { useEffect } from 'react';

import { generatePath } from 'react-router';
import UserInformation from './information';

import { Switch, Redirect, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../../routerConfig';

import Sidebar from '../../../../sidebar';
import ContentContainer from '../../../../../components/content-container';
import ProfileCard from '../../../../../components/profile-card';
import BackButton from '../../../../../components/UI/buttons/back-button';

import { connect } from 'react-redux';
import { getStaffInfoRequest, showBackButton, hideBackButton } from '../../../../../store/actions';

function UserDetails({
    match: {
        params: { staffId }
    },
    history,
    userInfoPage: { data },
    getStaffInfoRequest,
    showBackButton,
    hideBackButton,
    detailsPageView: { backbuttonVisibility }
}) {
    useEffect(() => {
        showBackButton();
        getStaffInfoRequest(staffId);
        return () => hideBackButton();
    }, [getStaffInfoRequest, hideBackButton, showBackButton, staffId]);

    const sidebarLinks = [
        {
            text: 'Information',
            to: generatePath(RouterConfig.superAdminUserInformation, { staffId }),
            disabled: false,
            hidden: false
        },
        {
            text: 'Schedule',
            to: generatePath(RouterConfig.superAdminUserSchedule, { staffId }),
            disabled: true,
            hidden: true
        }
    ];

    if (!data) {
        return null;
    } else
        return (
            <div className="content-view-page details-page">
                <div className="main-wrapper">
                    <div className="back-button-holder">
                        <BackButton visible={backbuttonVisibility} onClick={() => history.goBack()} />
                    </div>
                    <div className="content-holder">
                        <Sidebar links={sidebarLinks} visible>
                            <ProfileCard
                                avatar={data.image}
                                name={data.name}
                                surname={data.surname}
                                role={data.role}
                                status={data.status}
                            />
                        </Sidebar>
                        <ContentContainer smallPaddings fluid={false}>
                            <Switch>
                                <Route
                                    path={RouterConfig.superAdminUserInformation}
                                    render={() => <UserInformation data={data} status={data.status} />}
                                />
                                <Redirect
                                    from={RouterConfig.superAdminUserRoot}
                                    to={RouterConfig.superAdminUserInformation}
                                />
                            </Switch>
                        </ContentContainer>
                    </div>
                </div>
            </div>
        );
}

const mapStateToProps = (state) => ({
    userInfoPage: state.staffInfoPage,
    detailsPageView: state.branchDetailsPageView
});

const mapDispatchToProps = {
    getStaffInfoRequest,
    showBackButton,
    hideBackButton
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
