import {
    GET_BRANCH_TEMPLATE_SUCCESS,
    GET_BRANCH_TEMPLATE_FAILED,
    POST_TEMPLATE_FAILED,
    DELETE_TEMPLATE_FAILED,
    SCHEDULE_ACTION_FAILED,
    SCHEDULE_ACTION_SUCCESS,
    TEMPLATE_PUBLISH_SUCCESS,
    TEMPLATE_PUBLISH_FAILED,
    TEMPLATE_PUBLISH_REQUEST
} from '../../../actions/admin-view/branch-template/action-types';

const initialState = {
    data: null,
    loading: false
};

const branchStaffPage = (state = initialState, action) => {
    switch (action.type) {
        case TEMPLATE_PUBLISH_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BRANCH_TEMPLATE_SUCCESS:
        case SCHEDULE_ACTION_SUCCESS:
        case TEMPLATE_PUBLISH_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case TEMPLATE_PUBLISH_FAILED:
        case POST_TEMPLATE_FAILED:
        case GET_BRANCH_TEMPLATE_FAILED:
        case DELETE_TEMPLATE_FAILED:
        case SCHEDULE_ACTION_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default branchStaffPage;
