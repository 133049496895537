import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonComponent from '../../../../../../components/UI/buttons/button';
import { weekDays } from '../../../../../../services/datesHelper';
import { Input } from 'semantic-ui-react';

const InfoDashboard = ({
    loading,
    onArchiveBranchClick,
    onEditInfoClick,
    onEditTimeClick,
    onUpdateBranchRateRequest,
    data: {
        id,
        isArchived,
        title,
        number,
        address1,
        address2,
        postalCode,
        city,
        regionName,
        description,
        operatingTime,
        phone,
        itemsPerMonth,
        mdsPerMonth,
        methadonePatientsPerDay,
        careHomesSupplied,
        staffSupportLevelId,
        branchServices,
        pharmacySystemId
    },
    getData
}) => {
    const [weekRates, setWeekRates] = useState([]);
    const [editableRate, setEditableRate] = useState(null);

    useEffect(() => {
        if (!operatingTime) return;
        setWeekRates(
            weekDays.map((day) => {
                const weekTime = operatingTime.find(({ dayOfWeek }) => dayOfWeek === day);

                return {
                    day,
                    operatingTime: weekTime,
                    rate: weekTime ? weekTime.locumRate : '',
                    disabled: !weekTime
                };
            })
        );
        // eslint-disable-next-line
    }, [setWeekRates, operatingTime, weekDays]);

    useEffect(() => {
        if (!editableRate || !id) return;
        const timeout = setTimeout(() => {
            onUpdateBranchRateRequest({
                branchId: id,
                value: editableRate.rate ? parseFloat(editableRate.rate).toFixed(2) : 0,
                dayOfWeek: editableRate.day
            });
            setEditableRate(null);
        }, 500);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [editableRate, id]);

    const getServices =
        getData && getData.branchServices && branchServices
            ? branchServices.flatMap((service) => [getData.branchServices.find((item) => item.id === service)])
            : [];

    const servicesItems = getServices.map((service) => service.title);

    const getSupportLevel =
        getData &&
        getData.staffSupportLevels &&
        getData.staffSupportLevels.find((ssl) => ssl.id === staffSupportLevelId);

    const getPharmacySystem =
        getData && getData.pharmacySystems && getData.pharmacySystems.find((ps) => ps.id === pharmacySystemId);

    const getWorkingDays = useMemo(() => {
        return weekRates.map((day, index) => {
            const workingTime = day.operatingTime && (
                <span>{`${day.operatingTime.startTime} - ${day.operatingTime.endTime}`}</span>
            );
            const lunchTime =
                day.operatingTime && day.operatingTime.lunchStartTime ? (
                    <span>{` Lunch ${day.operatingTime.lunchStartTime} - ${day.operatingTime.lunchEndTime}`}</span>
                ) : null;

            return (
                <div
                    className={classnames('info-box__item fluid inner-item', {
                        noMargin: index === 0
                    })}
                    key={day.day}
                >
                    <p className="name">{day.day}</p>
                    <div className="description">
                        {day.disabled ? (
                            'Branch closed'
                        ) : (
                            <>
                                {workingTime}
                                {lunchTime}
                            </>
                        )}
                    </div>
                </div>
            );
        });
    }, [weekRates]);

    const onRateChange = (e) => {
        setWeekRates(
            weekRates.map((wr) => {
                if (wr.day === e.target.name) {
                    wr.rate = parseFloat(e.target.value) || '';
                    setEditableRate(wr);
                }
                return wr;
            })
        );
    };

    const editInfoButton = isArchived ? null : (
        <span role="button" className="edit-button" onClick={onEditInfoClick}>
            Edit
        </span>
    );

    const editOperatingTimeButton = isArchived ? null : (
        <span role="button" className="edit-button" onClick={onEditTimeClick}>
            Edit
        </span>
    );

    if (!getData.branchServices || !branchServices) return null;

    return (
        <>
            <h3 className="branch-info-page__title">Information</h3>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">
                                General information
                                {editInfoButton}
                            </h4>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Branch Name:</p>
                                    <div className="description">{title}</div>
                                </div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Branch Number:</p>
                                <div className="description">{number}</div>
                            </div>
                        </div>
                        <div className="info-box__item fluid inner-item">
                            <p className="name">Address Line 1:</p>
                            <div className="description">{address1}</div>
                        </div>
                        <div className="info-box__item fluid inner-item">
                            <p className="name">Address Line 2:</p>
                            <div className="description">{address2}</div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item inner-item">
                                <p className="name">Town/City</p>
                                <div className="description">{city}</div>
                            </div>
                            <div className="info-box__item inner-item">
                                <p className="name">Postcode:</p>
                                <div className="description">{postalCode}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item inner-item">
                                <p className="name">Region</p>
                                <div className="description">{regionName}</div>
                            </div>
                            <div className="info-box__item inner-item">
                                <p className="name">Phone:</p>
                                <div className="description">{phone}</div>
                            </div>
                        </div>
                        <div className="info-box__item fluid inner-item">
                            <p className="name">About Branch:</p>
                            <div className="description untransformed">
                                {!description || description === 'null' ? '' : description}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box no-title ">
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Item per Month:</p>
                                <div className="description">{itemsPerMonth}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">MDS per month:</p>
                                <div className="description">{mdsPerMonth}</div>
                            </div>

                            <div className="info-box__item fluid inner-item">
                                <p className="name">Methadone patients per day:</p>
                                <div className="description">{methadonePatientsPerDay}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Care homes supplied:</p>
                                <div className="description">{careHomesSupplied}</div>
                            </div>

                            <div className="info-box__item fluid inner-item">
                                <p className="name">Support level:</p>
                                <div className="description">{`${getSupportLevel && getSupportLevel.title} Staff`}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">System Used:</p>
                                <div className="description">{getPharmacySystem && getPharmacySystem.title}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Services Provided:</p>
                                <div className="description">
                                    <div className="services">{servicesItems.join(', ')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">
                                Branch Hours and Rate
                                {editOperatingTimeButton}
                            </h4>
                            {getWorkingDays}
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box">
                            <h4 style={{ marginBottom: '3rem' }} className="info-box__title">
                                Locum Rate
                            </h4>
                            {weekRates.map((wr, index) => {
                                return (
                                    <div
                                        style={{ marginBottom: wr.disabled ? '1.5rem' : '3rem' }}
                                        key={wr.day}
                                        className={classnames('info-box__item fluid inner-item', {
                                            noMargin: index === 0
                                        })}
                                    >
                                        <p className="name"></p>
                                        <div className="description">
                                            <Input
                                                icon="pound"
                                                size="small"
                                                iconPosition="left"
                                                name={wr.day}
                                                type="number"
                                                step="0.01"
                                                inputMode="decimal"
                                                placeholder={'Type value'}
                                                value={wr.rate || ''}
                                                disabled={wr.disabled}
                                                autoComplete="off"
                                                onChange={onRateChange}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="info-dashboard__row button-row">
                    <ButtonComponent
                        value={isArchived ? 'Archived' : 'Archive Branch'}
                        onClick={onArchiveBranchClick}
                        size="medium"
                        loading={loading}
                        disabled={loading || isArchived}
                    />
                </div>
            </div>
        </>
    );
};

InfoDashboard.propTypes = {
    loading: PropTypes.bool,
    onArchiveBranchClick: PropTypes.func.isRequired,
    onEditInfoClick: PropTypes.func.isRequired,
    onEditTimeClick: PropTypes.func.isRequired
};

export default InfoDashboard;
