import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-big-calendar';

import './style.scss';

const ScheduleCalendar = ({
    events,
    components,
    formats,
    localizer,
    onEventClick,
    onEventDoubleClick,
    onNavigate,
    selectable = false,
    onSelectSlot,
    views = ['month'],
    view,
    onView,
    date
}) => {
    return (
        <Calendar
            className="schedule-calendar"
            localizer={localizer}
            events={events}
            formats={formats}
            components={components}
            onNavigate={onNavigate}
            selectable={selectable}
            views={views}
            view={view}
            date={date}
            onView={onView}
            defaultDate={new Date()}
            defaultView="month"
            startAccessor="startDate"
            onSelectSlot={onSelectSlot}
            tooltipAccessor={() => null}
            endAccessor="endDate"
            onSelectEvent={onEventClick}
            onDoubleClickEvent={onEventDoubleClick}
            popup
        />
    );
};

ScheduleCalendar.propTypes = {
    events: PropTypes.array,
    onEventClick: PropTypes.func,
    selectable: PropTypes.bool,
    views: PropTypes.array,
    defaultView: PropTypes.string,
    view: PropTypes.string,
    defaultDate: PropTypes.object,
    onView: PropTypes.func,
    onSelectSlot: PropTypes.func
};

export default ScheduleCalendar;
