import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageNav from '../../../../../components/page-nav';
import TableComponent from '../../../../../components/table';

import { usePage, usePrevious } from '../../../../../services/hooks';
import { onFilter } from '../../../../../services/fetchDataHelper';

import './style.scss';

export function PharmaciesListPage({
    pharmaciesPage: {
        data,
        itemsPerPage,
        totalData,
        filters: { pharmacy, status, id, pharmacyAdmin },
        loading,
        isFilterChanged
    },
    getPharmaciesListRequest,
    onPharmaciesFilterChange,
    clearPharmaciesFilters
}) {
    const { pathname } = useLocation();
    const history = useHistory();
    const page = usePage();
    const prevPage = usePrevious(page);
    const prevRoute = usePrevious(pathname);
    // const watchFilterChange = useTablePaginationReset(
    //   isFilterChanged,
    //   RouterConfig.superAdminPharmacies
    // );

    useEffect(() => {
        const unlisten = history.listen(({ pathname }) => {
            if (pathname !== prevRoute) clearPharmaciesFilters();
        });
        return () => {
            unlisten();
        };
    }, [history, clearPharmaciesFilters, prevRoute]);

    useEffect(() => {
        if (!page || page === prevPage) return;

        getPharmaciesListRequest({ page });
    }, [getPharmaciesListRequest, page, prevPage]);

    if (!data) {
        return null;
    } else {
        const tableCols = [
            {
                name: 'id',
                title: 'pharmacy id',
                render: (item) => item.id,
                type: 'disabled'
            },
            {
                name: 'pharmacyName',
                title: 'pharmacy title',
                render: (item) => item.pharmacyName,
                type: 'disabled'
            },
            {
                name: 'branchesCount',
                title: 'branches',
                render: (item) => item.branchesCount,
                type: 'disabled'
            },
            {
                name: 'staffCount',
                title: 'employees',
                render: (item) => item.staffCount,
                type: 'disabled'
            },
            {
                name: 'userSubscription',
                title: 'subscription',
                width: 100,
                render: (item) => {
                    return (
                        <div>
                            <div>{`Numark(${item.numarkCount})`}</div>
                            <div>{`Standard(${item.standardCount})`}</div>
                        </div>
                    );
                },
                type: 'disabled'
            },
            {
                name: 'currentJobsCount',
                title: 'current jobs',
                render: (item) => item.currentJobsCount,
                type: 'disabled'
            },
            {
                name: 'doneJobsCount',
                title: 'done jobs',
                render: (item) => item.doneJobsCount,
                type: 'disabled'
            },
            {
                name: 'name',
                title: 'pharmacy admin',
                render: (item) => `${item.name} ${item.surname}`,
                type: 'disabled'
            },
            {
                name: 'status',
                title: 'pharmacy status',
                render: (item) => (item.status === 'active' ? 'activated' : item.status),
                type: 'disabled'
            }
        ];

        const searchInputs = [
            {
                name: 'pharmacy',
                type: 'text',
                value: pharmacy,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onPharmaciesFilterChange, page),
                placeholder: 'Search by title',
                fluid: false,
                loading,
                disabled: loading,
                componentType: 'input'
            },
            {
                name: 'status',
                type: 'text',
                value: status,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onPharmaciesFilterChange, page),
                placeholder: 'Search by status',
                fluid: false,
                loading,
                disabled: loading,
                componentType: 'input'
            },
            {
                name: 'pharmacyAdmin',
                type: 'text',
                value: pharmacyAdmin,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onPharmaciesFilterChange, page),
                placeholder: 'Search by admin',
                fluid: false,
                loading,
                disabled: loading,
                componentType: 'input'
            },
            {
                name: 'id',
                type: 'text',
                value: id,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onPharmaciesFilterChange, page),
                placeholder: 'Search by id',
                fluid: false,
                loading,
                disabled: loading,
                componentType: 'input'
            }
        ];

        return (
            <div className="admin-pharmacies-page">
                <div className="main-container">
                    <PageNav title="Pharmacies" inputs={searchInputs} />
                    <TableComponent
                        cols={tableCols}
                        data={data.rows}
                        totalItems={totalData}
                        itemsPerPage={itemsPerPage}
                        page={Number(page)}
                        openInfo="/pharmacies/details"
                    />
                </div>
            </div>
        );
    }
}
