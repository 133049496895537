import { LocumSchedule } from './LocumSchedule';
import { connect } from 'react-redux';
import {
    getStaffUserEventsRequest,
    onStaffUserEventsDateRangeChange,
    onLocumActionRequest,
    getJobOffersRequest,
    getLocumJobDetailsRequest,
    onLocumJobActionRequest
} from '../../../../store/actions';

export default connect(
    (state) => ({
        schedule: state.staffUserSchedule,
        jobOffers: state.locumJobOffersPage,
        jobApplyResponseData: state.jobPreviewPage.jobApplyResponseData,
        locumJobData: state.locumJobDetails
    }),
    {
        getStaffUserEventsRequest,
        onStaffUserEventsDateRangeChange,
        getJobOffersRequest,
        onLocumActionRequest,
        getLocumJobDetailsRequest,
        onLocumJobActionRequest
    }
)(LocumSchedule);
