import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getInvitedStaffInfoSuccess,
    getInvitedStaffInfoFailed,
    onConfirmIdentitySuccess,
    onConfirmIdentityFailed,
    onInvitedStaffLoginSuccess,
    onInvitedStaffLoginFailed,
    authSuccess,
    authFailed,
    onCancelGphcVerificationSuccess,
    onCancelGphcVerificationFailed
} from '../../actions';
import * as staffInvitePageActionTypes from '../../actions/staff-invite-page/action-types';
import api from '../../../services/api';

function* onInvitedStaffInfoRequest({ id }) {
    try {
        const { data } = yield call(api.request, {
            url: `/invites/basic-data/${id}`,
            method: 'get'
        });
        yield put(getInvitedStaffInfoSuccess(data));
    } catch (err) {
        yield put(getInvitedStaffInfoFailed());
    }
}

function* onCancelGphcVerificationRequest({ payload: { id } }) {
    try {
        yield call(api.request, {
            url: `/invites/${id}/cancel-gphc`,
            method: 'post'
        });
        yield put(onCancelGphcVerificationSuccess());
    } catch (err) {
        yield put(onCancelGphcVerificationFailed());
    }
}

function* confirmIdentityRequest({ id, payload: { gphcNumber, toggleModal } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/invites/validate-gphc/${id}`,
            method: 'post',
            payload: { gphcNumber: +gphcNumber }
        });
        yield put(onConfirmIdentitySuccess(data));
        toggleModal();
    } catch (err) {
        yield put(onConfirmIdentityFailed());
    }
}

function* onInvitedStaffLoginRequest({ payload: { id, recievedData, redirect } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/invites/${id}/sign-up`,
            method: 'post',
            payload: recievedData
        });
        yield put(onInvitedStaffLoginSuccess());
        api.setAccessToken(data.access);
        api.setRefreshToken(data.refresh);
        yield put(authSuccess(data));
        redirect();
    } catch (err) {
        yield put(onInvitedStaffLoginFailed());
        yield put(authFailed());
    }
}

// watchers
function* watchInvitedStaffInfoRequest() {
    yield takeEvery(staffInvitePageActionTypes.GET_INVITED_STAFF_INFO_REQUEST, onInvitedStaffInfoRequest);
}

function* watchConfirmIdentityRequest() {
    yield takeEvery(staffInvitePageActionTypes.ON_CONFIRM_IDENTITY_REQUEST, confirmIdentityRequest);
}

function* watchInvitedStaffLoginRequest() {
    yield takeEvery(staffInvitePageActionTypes.ON_INVITED_STAFF_LOGIN_REQUEST, onInvitedStaffLoginRequest);
}
function* watchOnCancelGphcVerificationRequest() {
    yield takeEvery(staffInvitePageActionTypes.ON_CANCEL_GPHC_VERIFICATION_REQUEST, onCancelGphcVerificationRequest);
}

const staffInvitePageSaga = [
    fork(watchInvitedStaffInfoRequest),
    fork(watchConfirmIdentityRequest),
    fork(watchInvitedStaffLoginRequest),
    fork(watchOnCancelGphcVerificationRequest)
];

export default staffInvitePageSaga;
