import React, { useEffect, useContext } from 'react';

import { AdminView, ManagerView, LocumView, SuperAdminView, PageNotFound } from '../pages';
import { AbilityContext, updateAbility } from '../../services/ability';
import { connect } from 'react-redux';

import { userRoles } from '../../userConfig';

const Root = ({ userRole, user, roles }) => {
    const ability = useContext(AbilityContext);

    useEffect(() => {
        updateAbility(ability, userRole);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let appView;

    switch (userRole) {
        case userRoles.ADMIN:
        case userRoles.MODERATOR:
            appView = <SuperAdminView roles={roles} role={userRole} user={user} />;
            break;
        case userRoles.BRANCH_ADMIN:
            appView = <AdminView roles={roles} role={userRole} user={user} />;
            break;
        case userRoles.PHARMACY_MANAGER:
        case userRoles.RELIEF:
        case userRoles.BRANCH_PHARMACIST:
            appView = <ManagerView roles={roles} user={user} />;
            break;
        case userRoles.LOCUM:
            appView = <LocumView roles={roles} user={user} />;
            break;
        case userRoles.TECHNICIAN:
        case userRoles.DISPENSER:
        case userRoles.MEDICINE_COUNTER:
            appView = <PageNotFound />;
            break;
        default:
            appView = <PageNotFound />;
    }

    return <AbilityContext.Provider value={ability}>{appView}</AbilityContext.Provider>;
};

const mapStateToProps = (state) => ({
    userRole: state.auth.user.role,
    user: state.auth.user,
    roles: state.auth.roles
});

export default connect(mapStateToProps)(Root);
