import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './style.scss';
import { Form } from 'semantic-ui-react';
import Datepicker from '../../../../../components/UI/datepicker';
import TextInput from '../../../../../components/UI/inputs/text-input';
import ButtonModal from '../../../../../components/UI/buttons/button-modal';
import { useLocation, useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

const initialForm = {
    startDate: '',
    endDate: '',
    minHourlyRate: '',
    tab: '',
    distance: 20,
    page: 0
};

const options = [
    { key: 1, text: '1 mile', value: 1 },
    { key: 2, text: '5 miles', value: 5 },
    { key: 3, text: '10 miles', value: 10 },
    { key: 4, text: '20 miles', value: 20 },
    { key: 5, text: '50 miles', value: 50 },
    { key: 6, text: '100 miles', value: 100 },
    { key: 7, text: '150 miles', value: 150 },
    { key: 8, text: '200 miles', value: 200 },
    { key: 9, text: 'Nationwide', value: 'nationwide' }
];

export function JobFilterPanel() {
    const [filterForm, setFilterForm] = useState(initialForm);
    const history = useHistory();
    const { pathname, search } = useLocation();

    useEffect(() => {
        let searchParams = new URLSearchParams(search);
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        const minHourlyRate = searchParams.get('minHourlyRate');
        const tab = searchParams.get('tab');
        const distance = searchParams.get('distance');
        const page = searchParams.get('page');
        setFilterForm({
            startDate: startDate && new Date(moment(startDate).format('YYYY-MM-DD')),
            endDate: endDate && new Date(moment(endDate).format('YYYY-MM-DD')),
            minHourlyRate: minHourlyRate || '',
            tab: tab,
            distance: distance === 'nationwide' ? 'nationwide' : Number(distance) || 20,
            page: page || 0
        });
    }, [search]);

    const handleApply = () => {
        const { startDate, endDate, minHourlyRate, distance } = filterForm;
        let searchParams = new URLSearchParams(search);
        const page = searchParams.get('page');
        const tab = searchParams.get('tab');
        const viewMode = searchParams.get('viewMode');
        searchParams = new URLSearchParams([
            ['page', page],
            ['tab', tab || ''],
            ['viewMode', viewMode || '']
        ]);

        if (startDate) {
            const momentStart = moment(startDate).format('YYYY-MM-DD');
            searchParams.append('startDate', momentStart);
        }
        if (endDate) {
            const momentEnd = moment(endDate).format('YYYY-MM-DD');
            searchParams.append('endDate', momentEnd);
        }

        searchParams.append('minHourlyRate', minHourlyRate);
        searchParams.append('distance', distance);
        history.replace({ pathname, search: searchParams.toString() });
    };

    const onRadiusChange = (_, { value }) => {
        setFilterForm({
            ...filterForm,
            distance: value
        });
    };

    const onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        if (name === 'minHourlyRate') {
            if (isNaN(Number(value))) return;
            if (Number(value) < 0) return;
        }

        setFilterForm({
            ...filterForm,
            [name]: value
        });
    };

    const onFilterClear = () => {
        setFilterForm({
            ...initialForm
        });
        let searchParams = new URLSearchParams(search);
        const page = searchParams.get('page');
        const tab = searchParams.get('tab') || filterForm.tab;
        searchParams = new URLSearchParams([
            ['page', page],
            ['tab', tab]
        ]);
        history.replace({ pathname, search: searchParams.toString() });
    };

    return (
        <Form className={''} autoComplete="nope">
            <div className="locum-job-offers-page__row">
                <div className="col">
                    <h3 className="col__title">Filter</h3>
                    <div style={{ marginBottom: '1rem' }} className="input-row">
                        <div className="input-group fluid">
                            <label>Preferred travel distance (radius)</label>
                            <Dropdown
                                onChange={onRadiusChange}
                                options={options}
                                selection
                                name="distance"
                                value={filterForm.distance}
                            />
                        </div>
                    </div>

                    <div style={{ marginBottom: '1rem' }}>
                        <div>
                            <Datepicker
                                name="startDate"
                                value={filterForm.startDate || ''}
                                label="Start Date"
                                minDate={new Date()}
                                onChange={onChangeHandler}
                                format="DD/MM/YY"
                                fluid={false}
                                clearable={false}
                            />
                            <span style={{ marginLeft: '1rem' }}>
                                <Datepicker
                                    name="endDate"
                                    value={filterForm.endDate || ''}
                                    label="End Date"
                                    onChange={onChangeHandler}
                                    minDate={filterForm.startDate || null}
                                    format="DD/MM/YY"
                                    fluid={false}
                                    clearable={false}
                                />
                            </span>
                        </div>
                    </div>

                    <div style={{ marginBottom: '1rem' }} className="input-row">
                        <div className="input-group hourly-rate">
                            <label>Minimum hourly rate</label>
                            <TextInput
                                name="minHourlyRate"
                                type="text"
                                value={String(filterForm.minHourlyRate)}
                                fluid={false}
                                icon={'pound'}
                                onChange={onChangeHandler}
                                iconPosition="left"
                            />
                        </div>
                    </div>
                    <div className="buttons-row double">
                        <ButtonModal value="Clear" colorType="red" onClick={() => onFilterClear()} />

                        <ButtonModal onClick={handleApply} value="Apply" colorType="blue" type="submit" />
                    </div>
                </div>
            </div>
        </Form>
    );
}
