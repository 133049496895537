import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from './../../UI/inputs/text-input';
import ButtonComponent from './../../UI/buttons/button';

import { emailError, passError } from './../../../services/validate';

import './style.scss';

class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        loading: false,
        validate: {
            email: false,
            password: false
        },
        errors: {
            email: null,
            password: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeHandler = ({ target: { name, value } }) => {
        const { errors, validate } = this.state;
        return this.setState(
            {
                [name]: value
            },
            () => {
                if (errors[name] || validate[name]) this.validate(name);
            }
        );
    };

    validate = async (name) => {
        const value = this.state[name];
        let error = null;

        switch (name) {
            case 'email':
                error = emailError(value);
                break;
            case 'password':
                error = passError(value);
                break;
            default:
                return;
        }
        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onSubmitHandler = async (e) => {
        e.preventDefault();
        await this.validate('email');

        const { errors, password, email } = this.state;
        const { onSuccess } = this.props;
        if (errors.password || errors.email) return;

        onSuccess({ password, email });
    };

    onResetPassClick = async (e) => {
        e.preventDefault();
        await this.validate('email');

        const { onResetPassSuccess } = this.props;
        const { errors, email } = this.state;

        if (errors.email) return;

        onResetPassSuccess(email);
    };

    onGhangeView = (callback) => {
        const { errors } = this.state;
        let resetErrors = {};

        // clean up errors
        for (let key in errors) {
            errors[key] = null;
            resetErrors[key] = errors[key];
        }

        this.setState({ errors: resetErrors });
        callback();
    };

    render() {
        const { onProceedToSignup, loading, formView, onForgotPass, onBackToLogin } = this.props;
        const { errors, email, password } = this.state;

        let displayForm =
            formView === 'login' ? (
                <>
                    <form onSubmit={this.onSubmitHandler}>
                        <h2 className="login-form__title">Login</h2>
                        <TextInput
                            name="email"
                            label="Email"
                            type="email"
                            value={email}
                            error={!!errors.email}
                            helperText={errors.email}
                            onChange={this.onChangeHandler}
                        />
                        <TextInput
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            error={!!errors.password}
                            helperText={errors.password}
                            onChange={this.onChangeHandler}
                        />
                        <p
                            className="login-form__forgot-pass-button"
                            onClick={() => this.onGhangeView(onForgotPass)}
                            role="button"
                        >
                            Forgot Password?
                        </p>
                        <ButtonComponent
                            value="Login"
                            onClick={this.onSubmitHandler}
                            loading={loading}
                            disabled={!!errors.email || !!errors.password}
                        />
                    </form>
                    <ButtonComponent value="Sign Up" type="bordered" onClick={onProceedToSignup} />
                </>
            ) : (
                <>
                    <h2 className="login-form__title forgot-title">Forgot Password</h2>
                    <p className="login-form__subtitle">Please enter your email address below to reset your password</p>
                    <TextInput
                        name="email"
                        label="Email"
                        type="email"
                        value={email}
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={this.onChangeHandler}
                    />
                    <ButtonComponent
                        value="Reset"
                        onClick={this.onResetPassClick}
                        loading={loading}
                        disabled={!!errors.email}
                    />
                    <ButtonComponent value="Cancel" type="bordered" onClick={() => this.onGhangeView(onBackToLogin)} />
                </>
            );

        return <div className="login-form">{displayForm}</div>;
    }
}

LoginForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onProceedToSignup: PropTypes.func.isRequired,
    onForgotPass: PropTypes.func.isRequired,
    onBackToLogin: PropTypes.func.isRequired,
    onResetPassSuccess: PropTypes.func.isRequired,
    formView: PropTypes.string.isRequired,
    loading: PropTypes.bool
};

export default LoginForm;
