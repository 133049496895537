import {
    GET_JOB_OFFERS_REQUEST,
    GET_JOB_OFFERS_SUCCESS,
    GET_JOB_OFFERS_FAILED,
    ON_JOB_OFFERS_FILTER_CHANGE,
    ON_JOB_OFFERS_FILTER_FALSE,
    CLEAR_JOB_OFFERS_FILTERS
} from './action-types';

export const getJobOffersRequest = (payload) => ({
    type: GET_JOB_OFFERS_REQUEST,
    payload
});

export const getJobOffersSuccess = (payload) => ({
    type: GET_JOB_OFFERS_SUCCESS,
    payload
});

export const getJobOffersFailed = () => ({
    type: GET_JOB_OFFERS_FAILED
});

export const onJobOffersFilterChange = (payload, page) => ({
    type: ON_JOB_OFFERS_FILTER_CHANGE,
    payload,
    page
});

export const onJobOffersFilterFalse = () => ({
    type: ON_JOB_OFFERS_FILTER_FALSE
});

export const clearJobOffersFilters = () => ({
    type: CLEAR_JOB_OFFERS_FILTERS
});
