import React from 'react';
import BranchesList from './branches-list';
import BranchDetails from './branch-details';

import { Switch, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../routerConfig';

const BranchesPage = (props) => (
    <Switch>
        <Route path={RouterConfig.branchAdminBranches} component={BranchesList} exact />
        <Route path={RouterConfig.branchAdminBranchRoot} component={BranchDetails} />
    </Switch>
);

export default BranchesPage;
