import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getStaffInfoSuccess,
    getStaffInfoFailed,
    toggleAddReleaseLocumBankSuccess,
    toggleAddReleaseLocumBankFailed
} from '../../../actions';
import * as staffInfoActionTypes from '../../../actions/admin-view/staff-info/action-types';
import api from '../../../../services/api';

function* onStaffInfoRequest({ staffId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/staff/${staffId}/profile`,
            method: 'get'
        });
        yield put(getStaffInfoSuccess(data));
    } catch (err) {
        yield put(getStaffInfoFailed());
    }
}

function* toggleAddReleaseLocumBankRequest({ userId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/locum-banks/${userId}/toggle-add-release`,
            method: 'POST'
        });
        yield put(toggleAddReleaseLocumBankSuccess(data));
    } catch (err) {
        yield put(toggleAddReleaseLocumBankFailed());
    }
}

function* watchToggleLocumBankRequest() {
    yield takeEvery(staffInfoActionTypes.TOGGLE_ADD_RELSEASE_LOCUM_BANK_REQUEST, toggleAddReleaseLocumBankRequest);
}

function* watchStaffInfoRequest() {
    yield takeEvery(staffInfoActionTypes.GET_STAFF_INFO_REQUEST, onStaffInfoRequest);
}

const staffInfoPageSaga = [fork(watchStaffInfoRequest), fork(watchToggleLocumBankRequest)];

export default staffInfoPageSaga;
