import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import classnames from 'classnames';

import './style.scss';

const phoneMask = ['+', '4', '4', /[2-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const PhoneMaskInput = ({
    name,
    value,
    label,
    loading,
    fluid = true,
    onChange,
    error,
    placeholder,
    autoComplete = 'nope',
    helperText,
    disabled,
    hidden,
    mask = phoneMask
}) => (
    <div className={classnames('input-group', { fluid, hidden })}>
        <label>{label}</label>
        <MaskedInput
            className={error ? 'error' : ''}
            name={name}
            onChange={onChange}
            error={error}
            value={value}
            autoComplete={autoComplete}
            loading={loading}
            disabled={disabled}
            placeholder={placeholder}
            type="tel"
            mask={mask}
            placeholderChar={'\u2000'}
            showMask
            guide={false}
        />
        <p className="helper-text">{helperText}</p>
    </div>
);

PhoneMaskInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    autoComplete: PropTypes.string,
    helperText: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    hidden: PropTypes.bool,
    mask: PropTypes.array,
    placeholder: PropTypes.string
};

export default PhoneMaskInput;
