import axios from 'axios';
import cookies from 'browser-cookies';
import { store as notificationStore } from 'react-notifications-component';
import notificationSettings from './notificationSettings';
import { apiUrl } from '../config';
import store from '../store/store.js';
import { setLoaderOn, setLoaderOff, logout } from '../store/actions/';

class Api {
    constructor(baseURL) {
        this.adapter = axios.create({
            baseURL
        });
    }

    request = async ({ url, method = 'get', payload, loading = false, headers = {} }) => {
        try {
            const accessToken = cookies.get('accessToken');
            if (!loading) store.dispatch(setLoaderOn());

            const request = {
                url,
                method,
                data: payload,
                headers: {
                    ...headers
                }
            };

            if (accessToken) request.headers.Authorization = accessToken;

            const response = await this.adapter
                .request(request)
                .catch(({ response }) => this.handleError(response, request));

            if (store.getState().loader > 0) store.dispatch(setLoaderOff());

            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    setAccessToken = (token) => {
        cookies.set('accessToken', token, 365);
    };

    setRefreshToken = (token) => {
        // set expiration date to 30 days
        let date = new Date();
        var minutes = 60;
        date.setTime(date.getTime() + minutes * 24 * (59400 * 30));

        cookies.set('refreshToken', token, { expires: date });
    };

    handleError = async (err, request) => {
        const oldAccess = cookies.get('accessToken');
        const oldRefresh = cookies.get('refreshToken');

        if (store.getState().loader > 0) store.dispatch(setLoaderOff());

        if (err.status === 401 && err.data.code === 'user_deactivated' && !request.url.includes('login')) {
            store.dispatch(logout());
        }

        if (err.status === 401 && err.data.code !== 'user_deactivated' && err.data.code !== 'unknown_credentials') {
            return await this.adapter
                .request({
                    url: '/auth/refresh',
                    method: 'post',
                    data: { token: oldAccess, refreshToken: oldRefresh }
                })
                .then((res) => {
                    const {
                        data: { access, refresh }
                    } = res;

                    this.setAccessToken(access);
                    this.setRefreshToken(refresh);
                    return res.data;
                })
                .then((data) =>
                    this.adapter.request({
                        ...request,
                        headers: {
                            ...request.headers,
                            Authorization: `Bearer ${data.access}`
                        }
                    })
                );
        } else {
            notificationStore.addNotification({
                ...notificationSettings.settings,
                title: 'Error',
                message: err.data.message || err.data.errors[0].message,
                type: 'danger'
            });
            return Promise.reject(err);
        }
    };
}

export default new Api(apiUrl);
