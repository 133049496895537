import { SET_LOADER_ON, SET_LOADER_OFF } from '../../actions/loader/action-types';

export default (state = 0, action) => {
    switch (action.type) {
        case SET_LOADER_ON:
            return state + 1;
        case SET_LOADER_OFF:
            return state - 1;
        default:
            return state;
    }
};
