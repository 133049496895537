import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { RouterConfig } from '../../../../../../routerConfig';
import SelectComponent from '../../../../../../components/UI/select';
import ButtonComponent from '../../../../../../components/UI/buttons/button';
import AppliedLocumCard from '../../../../../../components/UI/applied-locum-card';
import CustomPagination from '../../../../../../components/custom-pagination';
import JobAdjustments from '../../../../../../components/job-adjustments-table';

import { paginate, getTotalRate } from '../../../../../../services/helpers';
import { JOB } from '../../../../../../services/constants';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-icon.svg';
import PopupComponent from '../../../../../../components/popup';
import { useDispatch } from 'react-redux';
import { onJobMileageValueUpdateRequest } from '../../../../../../store/actions';
import { useEffect } from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';
import { getDuration } from '../../../../../../services/datesHelper';

const RuleType = {
    Mileage: 'MILEAGE',
    Parking: 'PARKING',
    Transport: 'TRANSPORT',
    Accommodation: 'ACCOMMODATIONANDFOODS'
};

const expenceDetails = [
    {
        type: RuleType.Mileage,
        title: 'Mileage Expenses',
        name: 'mileage',
        cols: ['Rate per Mile, pence', 'Pay Mileage after', 'Maximum Mileage'],
        value: null,
        data: []
    },
    {
        type: RuleType.Transport,
        title: 'Pay Transport Expenses',
        name: 'transport',
        cols: ['Name', 'Pay over', 'Up to'],
        value: null,
        data: []
    },
    {
        type: RuleType.Accommodation,
        title: 'Pay Accommodation Expenses',
        name: 'accommodation',
        cols: ['Name', 'Food', 'Pay over', 'Up to'],
        value: null,
        data: []
    },
    {
        type: RuleType.Parking,
        title: 'Pay Parking Expenses',
        name: 'parking',
        cols: ['Name', 'Up to'],
        value: null,
        data: []
    }
];

const expencesInitState = {
    mileage: {
        value: '0',
        editable: false,
        originalValue: '0'
    },
    transport: {
        value: '0',
        editable: false,
        originalValue: '0'
    },
    parking: {
        value: '0',
        editable: false,
        originalValue: '0'
    },
    accommodation: {
        value: '0',
        editable: false,
        originalValue: '0'
    }
};

const JobPreviewDashboard = ({
    onEditJobClick,
    onCancelJobClick,
    onDenyLocum,
    onApproveLocum,
    onCancelLocum,
    onInviteLocum,
    jobId,
    branchId,
    history,
    workedLocums,
    data: {
        branchTitle,
        startDate,
        endDate,
        startTime,
        endTime,
        hourlyRate,
        hasExternalLocum,
        jobAdjustments,
        paidLunch,
        description,
        status,
        staff = [],
        mileageRule,
        transportRule,
        parkingRule,
        accommodationAndFoodRule,
        mileageValue,
        accommodationValue,
        transportValue,
        parkingValue,
        lunchStartTime,
        lunchEndTime,
        id
    },
    loading,
    ...props
}) => {
    const [activePage, setActivePage] = useState(1);
    const [selectedLocum, setSelectedLocum] = useState(null);
    const [expences, setExpences] = useState(expencesInitState);
    const locumsCards = useMemo(() => paginate(staff, 5, activePage), [staff, activePage]);
    const dispatch = useDispatch();

    useEffect(() => {
        setExpences({
            mileage: {
                ...expences.mileage,
                value: mileageValue.toString() || '0',
                originalValue: mileageValue.toString() || '0',
                total: parseFloat(calculateMileageExpenses(mileageValue.toString() || '0', mileageRule)).toFixed(2)
            },
            transport: {
                ...expences.transport,
                value: transportValue.toString() || '0',
                originalValue: transportValue.toString() || '0',
                total: parseFloat(transportValue).toFixed(2) || '0'
            },
            accommodation: {
                ...expences.accommodation,
                value: accommodationValue.toString() || '0',
                originalValue: accommodationValue.toString() || '0',
                total: parseFloat(accommodationValue).toFixed(2) || '0'
            },
            parking: {
                ...expences.parking,
                value: parkingValue.toString() || 0,
                originalValue: parkingValue.toString() || '0',
                total: parseFloat(parkingValue).toFixed(2) || '0'
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mileageValue, accommodationValue, transportValue, parkingValue, mileageRule]);

    const setPageNum = (event, { activePage }) => setActivePage(activePage);

    const onDropdownChange = (e, { value }) => {
        if (value) {
            onInviteLocum(value);
            setSelectedLocum(value);
        } else {
            setSelectedLocum(null);
            return false;
        }
    };

    const jobPeriod = moment(endDate).isSame(startDate) ? (
        <>
            <p className="name">Date</p>
            <div className="description">{moment(startDate).format('D MMMM YYYY')}</div>
        </>
    ) : (
        <>
            <p className="name">Dates</p>
            <div className="description">
                {`${moment(startDate).format('D MMMM YYYY')} - ${moment(endDate).format('D MMMM YYYY')}`}
            </div>
        </>
    );

    const canEdit =
        status === 'cancelled' ? (
            <span className="job-status">Cancelled</span>
        ) : status === 'approved' ? null : (
            <span role="button" className="edit-button" onClick={onEditJobClick}>
                Edit
            </span>
        );

    const filteredLocumOptions = workedLocums.map((option) => {
        const { id, name, surname, image, role, gphcNumber } = option;
        const locumName = name && surname ? `${name} ${surname}` : gphcNumber;
        return {
            key: id,
            value: id,
            text: (
                <div className="option-inner">
                    <span className="option-inner__name bold">{locumName}</span>
                    <span className="option-inner__role blue">{role.replace(/_/g, ' ')}</span>
                </div>
            ),
            image: { avatar: !!image, src: image }
        };
    });

    const inviteLocumDropdown =
        status === JOB.UNFILLED || status === JOB.PENDING ? (
            <div className="info-box__item">
                <p className="name">Invite</p>
                <SelectComponent
                    name="inviteLocum"
                    icon="caret down"
                    value={selectedLocum}
                    floating
                    largeOptions
                    onChange={onDropdownChange}
                    options={filteredLocumOptions}
                />
            </div>
        ) : null;

    const calculateMileageExpenses = (value, rule) => {
        if (!rule) return value;
        const { maximumMileage, payMileageAfter, ratePerMile } = rule;

        let calculatedValue;
        const editableMileageValue = parseInt(value) || 0;
        if (editableMileageValue - payMileageAfter > maximumMileage) {
            calculatedValue = ((maximumMileage - payMileageAfter) * ratePerMile) / 100;
        } else {
            calculatedValue = ((editableMileageValue - payMileageAfter) * ratePerMile) / 100;
        }
        return calculatedValue > 0 ? calculatedValue : 0;
    };

    const calculateTotal = () => {
        const duration = getDuration(startTime, endTime) || 0;
        const lunchDuration = getDuration(lunchStartTime, lunchEndTime) || 0;
        const getTotalAmount = getTotalRate(
            startDate,
            endDate,
            hourlyRate,
            paidLunch ? duration : duration - lunchDuration
        );

        const sum =
            parseFloat(getTotalAmount) +
            parseFloat(expences.mileage.total) +
            parseFloat(expences.transport.total) +
            parseFloat(expences.parking.total) +
            parseFloat(expences.accommodation.total);
        return (sum || 0).toFixed(2);
    };

    const onMileageUpdate = () => {
        dispatch(
            onJobMileageValueUpdateRequest({
                jobId: id,
                mileageValue: parseInt(expences.mileage.value) || 0,
                transportValue: parseInt(expences.transport.value) || 0,
                parkingValue: parseInt(expences.parking.value) || 0,
                accommodationValue: parseInt(expences.accommodation.value) || 0
            })
        );
        setExpences({
            mileage: {
                ...expences.mileage,
                originalValue: expences.mileage.value,
                editable: false
            },
            parking: {
                ...expences.parking,
                originalValue: expences.parking.value,
                editable: false
            },
            transport: {
                ...expences.transport,
                originalValue: expences.transport.value,
                editable: false
            },
            accommodation: {
                ...expences.accommodation,
                originalValue: expences.accommodation.value,
                editable: false
            }
        });
    };

    const toggleExpenceEditable = (expence) => () => {
        setExpences({
            ...expences,
            [expence.name]: {
                ...expences[expence.name],
                value: expences[expence.name].originalValue || '0',
                editable: !expences[expence.name].editable,
                total:
                    expence.type === RuleType.Mileage
                        ? parseFloat(
                              calculateMileageExpenses(expences[expence.name].originalValue, mileageRule)
                          ).toFixed(2)
                        : expences[expence.name].originalValue
                        ? parseFloat(expences[expence.name].originalValue).toFixed(2)
                        : 0
            }
        });
    };

    const onExpenceValueChange = (expence) => (e) => {
        setExpences({
            ...expences,
            [expence.name]: {
                ...expences[expence.name],
                value: e.target.value,
                total:
                    expence.type === RuleType.Mileage
                        ? parseFloat(calculateMileageExpenses(e.target.value, mileageRule) || '0').toFixed(2)
                        : parseFloat(e.target.value || '0').toFixed(2) || '0'
            }
        });
    };

    const expenceDetailsView = useMemo(() => {
        return expenceDetails.map((expence) => {
            switch (expence.type) {
                case RuleType.Mileage:
                    expence.data = mileageRule
                        ? [mileageRule.ratePerMile, mileageRule.payMileageAfter, mileageRule.maximumMileage]
                        : [];
                    expence.value = expences.mileage.value;
                    break;
                case RuleType.Transport:
                    expence.data = transportRule
                        ? [transportRule.name, `£${transportRule.payOver}`, `£${transportRule.upTo}`]
                        : [];
                    expence.value = expences.transport.value;
                    break;
                case RuleType.Accommodation:
                    expence.data = accommodationAndFoodRule
                        ? [
                              accommodationAndFoodRule.name,
                              accommodationAndFoodRule.foodCoverage ? 'Yes' : 'No',
                              `£${accommodationAndFoodRule.payOver}`,
                              `£${accommodationAndFoodRule.upTo}`
                          ]
                        : [];
                    expence.value = expences.accommodation.value;
                    break;
                case RuleType.Parking:
                    expence.data = parkingRule ? [parkingRule.name, `£${parkingRule.upTo}`] : [];
                    expence.value = expences.parking.value;
                    break;

                default:
                    break;
            }
            return (
                <div key={expence.type} className="item-row">
                    <div className="info-box__item">
                        <p className="name">
                            {expence.title}
                            <span className="ml-1">
                                <PopupComponent triggerComponent={<InfoIcon />} smallPaddings position="right center">
                                    <div className="job-preview-page">
                                        <div className="popup">
                                            {expence.data.length
                                                ? expence.cols.map((col, index) => {
                                                      return (
                                                          <div key={col + index} className="popup__row">
                                                              <div className="mr-2">{col}</div>
                                                              <div>{expence.data[index]}</div>
                                                          </div>
                                                      );
                                                  })
                                                : 'No data'}
                                        </div>
                                    </div>
                                </PopupComponent>
                            </span>
                        </p>
                        <div className="description">
                            {expences[expence.name].editable ? (
                                <Input
                                    size="large"
                                    name={expence.name}
                                    type="number"
                                    placeholder={'Type value'}
                                    value={expence.value || ''}
                                    autoComplete="off"
                                    onChange={onExpenceValueChange(expence)}
                                />
                            ) : (
                                '£' + expences[expence.name].total
                            )}

                            {expences[expence.name].editable ? (
                                <>
                                    <Button
                                        className="button-mileage-update mt-1"
                                        onClick={onMileageUpdate}
                                        size="small"
                                        // disabled={mileageValue === editableMileageValue}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        className="button-mileage-update mt-1"
                                        onClick={toggleExpenceEditable(expence)}
                                        size="small"
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <Icon
                                    onClick={toggleExpenceEditable(expence)}
                                    name={'pencil'}
                                    className={`header-icon pencil`}
                                />
                            )}
                        </div>
                    </div>
                </div>
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expenceDetails, expences, transportRule, mileageRule, parkingRule, accommodationAndFoodRule]);

    const locumsList = locumsCards.map((el) => {
        const { id, name, surname, roleTitle, image, email, phone, gphcNumber, rating } = el;

        return (
            <AppliedLocumCard
                key={id}
                name={name}
                surname={surname}
                id={id}
                role={roleTitle}
                image={image}
                rating={rating}
                status={el.status}
                loading={loading}
                email={email}
                phone={phone}
                gphcNumber={gphcNumber}
                inactive={status === 'approved' && el.status !== 'accepted'}
                isExternal={hasExternalLocum && ['pending', 'new'].includes(el.userStatus)}
                onLocumClick={() =>
                    history.push(
                        generatePath(RouterConfig.branchAdminStaffInformation, {
                            id: branchId,
                            staffId: id
                        })
                    )
                }
                onDenyButtonClick={onDenyLocum}
                onApproveButtonClick={onApproveLocum}
                onCancelButtonClick={() => {
                    setSelectedLocum(null);
                    onCancelLocum(id);
                }}
            />
        );
    });
    const locumsListPagination =
        staff.length > 5 ? (
            <CustomPagination items={locumsCards} onPageChange={setPageNum} totalPages={Math.ceil(staff.length / 5)} />
        ) : null;

    return (
        <div className="job-preview-page__board">
            <div className="row">
                <div className="col">
                    <h2 className="col__title">
                        Job Details
                        {canEdit}
                    </h2>
                    <p className="col__subtitle">{`#${jobId}`}</p>
                    <div className="info-box">
                        <div className="item-row">
                            <div className="info-box__item">{jobPeriod}</div>
                            <div className="info-box__item">
                                <p className="name">Operating Time</p>
                                <div className="description">{`${startTime} - ${endTime}`}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Hourly Rate</p>
                                <div className="description">{`£${hourlyRate}`}</div>
                            </div>
                            <div className="info-box__item">
                                <p className="name">Lunch</p>
                                <div className="description">{paidLunch ? 'Paid' : 'Unpaid'}</div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Job Description</p>
                                <div className="description">{description}</div>
                            </div>
                            <div className="info-box__item">
                                <p className="name">Branch title</p>
                                <div className="description">{branchTitle}</div>
                            </div>
                        </div>
                        {expenceDetailsView}
                        <div className="item-row">
                            <div className="info-box__item">
                                <p className="name">Total</p>
                                <div className="description">£{calculateTotal()}</div>
                            </div>
                        </div>
                        <div className="item-row">{inviteLocumDropdown}</div>
                    </div>

                    {jobAdjustments && jobAdjustments.length > 0 && <JobAdjustments jobAdjustments={jobAdjustments} />}
                </div>
                <div className="col">
                    <h2 className="col__title">Locums List</h2>
                    <p className="col__subtitle">Job Posted</p>
                    <div className="locums-list" key={locumsCards}>
                        {locumsList}
                    </div>
                    <div className="locums-list-pagination">{locumsListPagination}</div>
                </div>
            </div>
            {status !== 'completed' && (
                <div className="row button-row">
                    <ButtonComponent
                        colorType="danger"
                        value="Cancel Job"
                        onClick={onCancelJobClick}
                        size="medium"
                        loading={loading}
                        disabled={loading || status === 'cancelled'}
                    />
                </div>
            )}
        </div>
    );
};

JobPreviewDashboard.propTypes = {
    onEditJobClick: PropTypes.func,
    jobId: PropTypes.string,
    branchId: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    workedLocums: PropTypes.array,
    loading: PropTypes.bool,
    onCancelJobClick: PropTypes.func,
    onDenyLocum: PropTypes.func,
    onApproveLocum: PropTypes.func,
    onCancelLocum: PropTypes.func,
    onInviteLocum: PropTypes.func
};

export default withRouter(JobPreviewDashboard);
