import {
    GET_LOCUM_JOB_DETAILS_SUCCESS,
    GET_LOCUM_JOB_DETAILS_FAILED
} from '../../../actions/locum-view/job-preview/action-types';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case GET_LOCUM_JOB_DETAILS_SUCCESS:
            return (state = payload);
        case GET_LOCUM_JOB_DETAILS_FAILED:
            return state;
        default:
            return state;
    }
};
