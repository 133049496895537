import {
    GET_USERS_LIST_REQUEST,
    GET_USERS_LIST_SUCCESS,
    GET_USERS_LIST_FAILED,
    ON_USERS_LIST_FILTER_CHANGE,
    ON_USERS_LIST_FILTER_FALSE,
    CLEAR_USERS_LIST_FILTERS
} from './action-types';

export const getUsersListRequest = (payload, filters) => ({
    type: GET_USERS_LIST_REQUEST,
    payload,
    filters
});

export const getUsersListSuccess = (payload) => ({
    type: GET_USERS_LIST_SUCCESS,
    payload
});

export const getUsersListFailed = () => ({
    type: GET_USERS_LIST_FAILED
});

export const onUsersListFilterChange = (payload, page) => ({
    type: ON_USERS_LIST_FILTER_CHANGE,
    payload,
    page
});

export const onUsersListFilterFalse = () => ({
    type: ON_USERS_LIST_FILTER_FALSE
});

export const clearUsersListFilters = () => ({
    type: CLEAR_USERS_LIST_FILTERS
});
