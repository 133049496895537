import moment from 'moment';

import { CONTRACT_TYPE, CONTRACT_TYPE_TEXT, PERIOD_TYPE } from 'services/constants';

export const contractTypeOptions = [
    {
        value: CONTRACT_TYPE.FULL_TIME,
        key: CONTRACT_TYPE.FULL_TIME,
        text: CONTRACT_TYPE_TEXT[CONTRACT_TYPE.FULL_TIME]
    },
    {
        value: CONTRACT_TYPE.PART_TIME,
        key: CONTRACT_TYPE.PART_TIME,
        text: CONTRACT_TYPE_TEXT[CONTRACT_TYPE.PART_TIME]
    },
    {
        value: CONTRACT_TYPE.ZERO_HOUR,
        key: CONTRACT_TYPE.ZERO_HOUR,
        text: CONTRACT_TYPE_TEXT[CONTRACT_TYPE.ZERO_HOUR]
    },
    {
        value: CONTRACT_TYPE.OTHER,
        key: CONTRACT_TYPE.OTHER,
        text: CONTRACT_TYPE_TEXT[CONTRACT_TYPE.OTHER]
    }
];

export const workingHourPeriodOptions = [
    {
        value: PERIOD_TYPE.DAY,
        key: PERIOD_TYPE.DAY,
        text: PERIOD_TYPE.DAY
    },
    {
        value: PERIOD_TYPE.WEEK,
        key: PERIOD_TYPE.WEEK,
        text: PERIOD_TYPE.WEEK
    },
    {
        value: PERIOD_TYPE.MONTH,
        key: PERIOD_TYPE.MONTH,
        text: PERIOD_TYPE.MONTH
    }
];

export const grossSalaryPeriodOptions = [
    {
        value: PERIOD_TYPE.DAY,
        key: PERIOD_TYPE.DAY,
        text: PERIOD_TYPE.DAY
    },
    {
        value: PERIOD_TYPE.WEEK,
        key: PERIOD_TYPE.WEEK,
        text: PERIOD_TYPE.WEEK
    },
    {
        value: PERIOD_TYPE.MONTH,
        key: PERIOD_TYPE.MONTH,
        text: PERIOD_TYPE.MONTH
    },
    {
        value: PERIOD_TYPE.YEAR,
        key: PERIOD_TYPE.YEAR,
        text: PERIOD_TYPE.YEAR
    }
];

export const additionCompensationOptions = [
    {
        value: PERIOD_TYPE.MONTH,
        key: PERIOD_TYPE.MONTH,
        text: PERIOD_TYPE.MONTH
    },
    {
        value: PERIOD_TYPE.YEAR,
        key: PERIOD_TYPE.YEAR,
        text: PERIOD_TYPE.YEAR
    }
];

export const contractPages = {
    currentContract: 'current-contract',
    upcomingContract: 'upcoming-contracts',
    closedContract: 'closed-contracts'
};

export const jobTitleIdText = {
    1: 'Pharmacy Manager',
    6: 'Relief Pharmacist',
    8: 'Branch Pharmacist'
};
export const jobTitleOptions = [
    {
        value: 1,
        key: 1,
        text: jobTitleIdText[1]
    },
    {
        value: 6,
        key: 6,
        text: jobTitleIdText[6]
    },
    {
        value: 8,
        key: 8,
        text: jobTitleIdText[8]
    }
];

export const formatContractData = (contractData) => {
    return {
        ...contractData,
        endDate: moment(contractData.endDate).format('YYYY-MM-DD'),
        startDate: moment(contractData.startDate).format('YYYY-MM-DD')
    };
};
