import {
    GET_PHARMACY_INFO_REQUEST,
    GET_PHARMACY_INFO_SUCCESS,
    GET_PHARMACY_INFO_FAILED,
    ON_PHARMACY_STATUS_CHANGE_REQUEST,
    ON_PHARMACY_STATUS_CHANGE_SUCCESS,
    ON_PHARMACY_STATUS_CHANGE_FAILED
} from './action-types';

export const getPharmacyInfoRequest = (pharmacyId) => ({
    type: GET_PHARMACY_INFO_REQUEST,
    pharmacyId
});

export const getPharmacyInfoSuccess = (payload) => ({
    type: GET_PHARMACY_INFO_SUCCESS,
    payload
});

export const getPharmacyInfoFailed = () => ({
    type: GET_PHARMACY_INFO_FAILED
});

export const onPharmacyStatusChangeRequest = (payload) => ({
    type: ON_PHARMACY_STATUS_CHANGE_REQUEST,
    payload
});

export const onPharmacyStatusChangeSuccess = () => ({
    type: ON_PHARMACY_STATUS_CHANGE_SUCCESS
});

export const onPharmacyStatusChangeFailed = () => ({
    type: ON_PHARMACY_STATUS_CHANGE_FAILED
});
