import { LocumJobOffersList } from './LocumJobOffersList';
import { connect } from 'react-redux';
import { getJobOffersRequest, getLocumSettingsRequest, onLocumActionRequest } from '../../../../../store/actions';

export default connect(
    (state) => ({
        jobOffers: state.locumJobOffersPage,
        jobApplyResponseData: state.jobPreviewPage.jobApplyResponseData,
        settings: state.locumSettings,
        currentUser: state.auth.user
    }),
    {
        getJobOffersRequest,
        getLocumSettingsRequest,
        onLocumActionRequest
    }
)(LocumJobOffersList);
