import {
    GET_JOB_LOCUMS_BY_FILTER_REQUEST,
    GET_JOB_LOCUMS_BY_FILTER_SUCCESS,
    GET_JOB_LOCUMS_BY_FILTER_FAILED,
    CLEAR_JOB_LOCUMS_FILTERS
} from './action-types';

export const getJobLocumsByFilterRequest = (payload) => ({
    type: GET_JOB_LOCUMS_BY_FILTER_REQUEST,
    payload
});

export const getJobLocumsByFilterSuccess = (payload) => ({
    type: GET_JOB_LOCUMS_BY_FILTER_SUCCESS,
    payload
});

export const getJobLocumsByFilterFailed = () => ({
    type: GET_JOB_LOCUMS_BY_FILTER_FAILED
});

export const clearGetJobLocumsByFilter = () => ({
    type: CLEAR_JOB_LOCUMS_FILTERS
});
