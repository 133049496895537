import {
    LOG_IN_SUMBIT,
    LOG_IN_SUCCESS,
    LOG_IN_FAILED,
    RESET_PASS_SUBMIT,
    RESET_PASS_SUCCESS,
    RESET_PASS_FAILED,
    PROFILE_RESET_PASS_SUBMIT,
    PROFILE_RESET_PASS_SUCCESS,
    PROFILE_RESET_PASS_FAILED,
    FORGOT_PASS_SUBMIT,
    FORGOT_PASS_SUCCESS,
    FORGOT_PASS_FAILED
} from '../../actions/login-page/action-types';

const initialState = {
    loading: false,
    modals: {
        confirmModal: false,
        resetPassModal: false
    }
};

const loginPage = (state = initialState, action) => {
    switch (action.type) {
        case LOG_IN_SUMBIT:
        case RESET_PASS_SUBMIT:
        case PROFILE_RESET_PASS_SUBMIT:
        case FORGOT_PASS_SUBMIT:
            return {
                ...state,
                loading: true
            };
        case RESET_PASS_SUCCESS:
        case PROFILE_RESET_PASS_SUCCESS:
            return {
                ...state,
                loading: false,
                modals: {
                    ...state.modals,
                    resetPassModal: false
                }
            };
        case FORGOT_PASS_SUCCESS:
            return {
                ...state,
                loading: false,
                modals: {
                    ...state.modals,
                    confirmModal: false
                }
            };
        case LOG_IN_SUCCESS:
        case LOG_IN_FAILED:
        case RESET_PASS_FAILED:
        case FORGOT_PASS_FAILED:
        case PROFILE_RESET_PASS_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default loginPage;
