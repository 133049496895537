import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'semantic-ui-react';

import TextInput from '../../UI/inputs/text-input';
import TextAreaComponent from '../../UI/inputs/text-area';
import ButtonModal from '../../UI/buttons/button-modal';
import Timepicker from '../../UI/timepicker';
import CustomCheckbox from '../../UI/checkbox';
import Datepicker from '../../UI/datepicker';
import SelectComponent from '../../UI/select/SelectComponent';

import { digitsNumberError, emailError, phoneError, salaryError, textAreaError } from '../../../services/validate';
import { getDuration, getDurationFormat, parseTime, weekDays } from '../../../services/datesHelper';
import { JOB } from '../../../services/constants';

import './style.scss';
import ExpenceCoverageForm from './expence-coverage-form/ExpenceCoverageForm';
import CheckboxListContainer from '../../../containers/checkbox-list';

const TIME_FORMAT = 'HH:mm';

const FormType = {
    Job: 'JOB',
    Expence: 'EXPENCE'
};

// TODO remove locumData from parent components
class PostJobBulkForm extends Component {
    state = {
        startDate: !this.props.defaultDate ? null : new Date(this.props.defaultDate),
        endDate: !this.props.defaultDate ? null : new Date(this.props.defaultDate),
        selectBranch: '',
        paidLunch: false,
        fullDay: false,
        externalLocumBooking: false,
        gphcNumber: '',
        email: '',
        phone: '',
        hourlyRate: '',
        description: '',
        status: '',
        IR35: false,
        userSearchKeyword: '',
        selectedUser: null,
        mileageRuleId: null,
        hasExpences: false,
        formType: FormType.Job,
        workDays: [],
        expences: null,
        validate: {
            hourlyRate: false
        },
        errors: {
            hourlyRate: null
        }
    };

    timeout;

    componentDidMount() {
        this._isMounted = true;
        const { jobData, operatingTime, defaultDate, onLocumsInitialGet } = this.props;
        const weekDaysCheckboxes = weekDays.map((item) => {
            const weekTime = operatingTime.find((ot) => ot.dayOfWeek === item);
            const startTime =
                (jobData.startTime || weekTime) &&
                moment(parseTime(jobData.startTime ? jobData.startTime : weekTime.startTime));
            const endTime =
                (jobData.endTime || weekTime) &&
                moment(parseTime(jobData.endTime ? jobData.endTime : weekTime.endTime));
            let lunchStartTime = jobData.lunchStartTime
                ? jobData.lunchStartTime
                : weekTime
                ? weekTime.lunchStartTime
                : null;
            let lunchEndTime = jobData.lunchEndTime ? jobData.lunchEndTime : weekTime ? weekTime.lunchEndTime : null;
            lunchStartTime = lunchStartTime && moment(parseTime(lunchStartTime));
            lunchEndTime = lunchStartTime && moment(parseTime(lunchEndTime));

            return {
                title: item,
                checked: moment(defaultDate).format('dddd').toLowerCase() === item,
                startTime,
                endTime,
                lunchStartTime,
                lunchEndTime,
                hourlyRate: jobData.id ? jobData.hourlyRate : weekTime && weekTime.locumRate,
                disabled: !weekTime,
                duration: startTime && endTime && getDuration(startTime, endTime)
            };
        });

        const startDate = new Date(`${moment(defaultDate).startOf('day').format('YYYY-MM-DD')} 00:00:00`);
        const endDate = new Date(`${moment(defaultDate).startOf('day').format('YYYY-MM-DD')} 00:00:00`);

        const selectedDateDetails = weekDaysCheckboxes.find(
            (wd) => wd.title === moment(startDate).format('dddd').toLowerCase()
        );
        this.setState({
            workDays: weekDaysCheckboxes,
            hourlyRate: selectedDateDetails && selectedDateDetails.hourlyRate,
            startDate,
            endDate,
            description: jobData.description || ''
        });

        onLocumsInitialGet();
    }

    componentDidUpdate(_, prevState) {
        const { startDate, endDate } = this.state;
        if (
            startDate &&
            prevState.startDate &&
            new Date(startDate).getTime() !== new Date(prevState.startDate).getTime()
        ) {
            const newDay = moment(startDate).format('dddd').toLowerCase();
            const newWorkingDays = this.state.workDays.map((week) => {
                return {
                    ...week,
                    checked: week.title === newDay
                };
            });
            const singleChekedDay = newWorkingDays.find((wd) => wd.checked);
            this.setState({
                workDays: newWorkingDays,
                hourlyRate: singleChekedDay && singleChekedDay.hourlyRate,
                endDate: moment(startDate).isAfter(moment(endDate)) ? null : endDate
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearGetJobLocumsByFilter();
    }

    onStatTimeChange = (day, time, startTime, timeFinish, duration) => {
        time.set({ second: 0 });
        let setDuration = timeFinish ? getDuration(time, timeFinish) : '';
        this.setState({
            workDays: this.state.workDays.map((week) => {
                if (week === day) {
                    return {
                        ...week,
                        [startTime]: time,
                        [duration]: setDuration
                    };
                }
                return week;
            })
        });
    };

    onFinishTimeChange = (day, time, endTime, timeStart, duration) => {
        time.set({ second: 0 });
        let setDuration = timeStart ? getDuration(timeStart, time) : '';
        this.setState({
            workDays: this.state.workDays.map((week) => {
                if (week === day) {
                    return {
                        ...week,
                        [endTime]: time,
                        [duration]: setDuration
                    };
                }
                return week;
            })
        });
    };

    onCheckboxChangeHandler = (e, { name }) => {
        this.setState((prevState) => ({
            [name]: !prevState[name],
            gphcNumber: '',
            email: '',
            phone: ''
        }));
    };

    onChangeHandler = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;

        return this.setState(
            {
                [name]: value
            },
            () => {
                this.validate(name);
            }
        );
    };

    onLocumGet() {
        const { startDate, endDate, timeStart, timeFinish, userSearchKeyword } = this.state;
        this.props.onGphcChange({
            gphcNumber: isNaN(parseInt(userSearchKeyword)) ? null : userSearchKeyword,
            name: userSearchKeyword,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            startTime: timeStart ? timeStart.format('HH:mm') : null,
            endTime: timeFinish ? timeFinish.format('HH:mm') : null
        });
    }

    validate = async (name) => {
        const value = this.state[name];
        let error = null;
        switch (name) {
            case 'hourlyRate':
                error = salaryError(value);
                break;
            case 'description':
                error = value ? textAreaError(value) : null;
                break;
            case 'gphcNumber':
                error = value ? digitsNumberError(value) : null;
                break;
            case 'email':
                error = value ? emailError(value) : null;
                break;
            case 'phone':
                error = value ? phoneError(value) : null;
                break;

            default:
                return;
        }

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onSubmitClickHandler = (e) => {
        e.preventDefault();
        this.postJob();
    };

    postJob() {
        const {
            startDate,
            endDate,
            paidLunch,
            description,
            externalLocumBooking,
            selectedUser,
            email,
            phone,
            expences,
            hasExpences,
            workDays,
            formType
        } = this.state;

        if (hasExpences && formType === FormType.Job) {
            this.setState({
                formType: FormType.Expence
            });
            return;
        }
        const { onSubmitBulk, jobData, onSubmitUpdate } = this.props;
        let selectedDays = [];
        let dateFrom = moment(startDate).format('YYYY-MM-DD');
        while (moment(dateFrom).isSameOrBefore(moment(endDate).format('YYYY-MM-DD'))) {
            selectedDays.push(dateFrom);
            dateFrom = moment(dateFrom).add(1, 'day').format('YYYY-MM-DD');
        }

        selectedDays = selectedDays.filter((sd) =>
            workDays.find((wd) => wd.checked && wd.title === moment(sd).format('dddd').toLowerCase())
        );

        const data = selectedDays.map((sd) => {
            const weekData = workDays.find((wd) => wd.checked && wd.title === moment(sd).format('dddd').toLowerCase());
            const startTime = weekData && weekData.startTime ? weekData.startTime.format('HH:mm') : null;
            const endTime = weekData && weekData.endTime ? weekData.endTime.format('HH:mm') : null;
            const lunchEndTime = weekData.lunchEndTime ? weekData.lunchEndTime.format('HH:mm') : null;
            const lunchStartTime = weekData.lunchStartTime ? weekData.lunchStartTime.format('HH:mm') : null;
            const hourlyRate = weekData.hourlyRate;
            const total = this.calculateTotal(1, {
                startTime,
                endTime,
                lunchStartTime,
                lunchEndTime,
                hourlyRate,
                paidLunch
            });

            return {
                id: jobData ? jobData.id : undefined,
                startDate: sd,
                endDate: sd,
                hourlyRate,
                startTime,
                endTime,
                lunchEndTime,
                lunchStartTime,
                paidLunch,
                description,
                total,
                hasExternalLocum: externalLocumBooking,
                mileageRuleId: hasExpences ? parseInt(expences.mileageRuleId) || null : null,
                transportRuleId: hasExpences ? parseInt(expences.transportRuleId) || null : null,
                parkingRuleId: hasExpences ? parseInt(expences.parkingRuleId) || null : null,
                accommodationAndFoodRuleId: hasExpences ? parseInt(expences.accommodationAndFoodRuleId) || null : null,
                externalLocumBooking: externalLocumBooking
                    ? {
                          gphcNumber: selectedUser.gphcNumber,
                          email,
                          phone
                      }
                    : null
            };
        });

        if (jobData.id) {
            onSubmitUpdate(data[0]);
        } else {
            onSubmitBulk(data);
        }
    }

    onCancelClickHandler = (e) => {
        e.preventDefault();
        const { shiftDate, isTemplateShift } = this.state;
        const { onCancelClick, onCloseClick, isEdit } = this.props;
        const data = {
            isTemplateShift,
            date: moment(shiftDate).format('YYYY-MM-DD')
        };
        isEdit ? onCancelClick(data) : onCloseClick();
    };

    onLocumsSearch = (e) => {
        this.setState({
            userSearchKeyword: e.target.value
        });
    };

    getLocumName = (locum) => {
        let name = '';

        if (locum.name) {
            name = `${locum.name} ${locum.surname || ''}`;
        } else {
            name = `${locum.forenames || ''} ${locum.surname || ''}`;
        }

        return name;
    };

    onExpencesSubmit(expences) {
        this.setState(
            {
                expences
            },
            this.postJob
        );
    }

    onExpencesCancel = () => {
        this.setState({
            formType: FormType.Job,
            expences: this.props.isEdit ? this.state.expences : null
        });
    };

    calculateTotal = (dayCount, job) => {
        if (!job) return 0;
        const lunchDuration = (job.lunchStartTime && getDuration(job.lunchStartTime, job.lunchEndTime)) || 0;
        let duration = getDuration(job.startTime, job.endTime);
        if (!job.paidLunch) duration -= lunchDuration;
        const getHours = (duration / 60) * dayCount;
        return (job.hourlyRate * getHours).toFixed(2);
    };

    render() {
        const {
            startDate,
            endDate,
            paidLunch,
            description,
            externalLocumBooking,
            email,
            phone,
            fullDay,
            selectedUser,
            userSearchKeyword,
            hourlyRate,
            errors,
            status,
            hasExpences,
            workDays,
            formType
        } = this.state;
        const { loading, isEdit, calledFromShift, operatingTime } = this.props;
        const isMultiDay = moment(endDate).isAfter(startDate);
        const lunchExist = operatingTime.find((ot) => !!ot.lunchStartTime);

        const daysCheckboxes = workDays.map((item) => {
            return {
                id: item.title,
                disabled:
                    item.disabled || (!isMultiDay && item.title !== moment(startDate).format('dddd').toLowerCase()),
                label: item.title[0].toUpperCase() + item.title.substring(1, 3),
                name: item.title,
                checked: item.checked,
                onChange: () => {
                    this.setState({
                        workDays: workDays.map((wd) => {
                            if (wd === item) wd.checked = !wd.checked;
                            return wd;
                        })
                    });
                }
            };
        });

        const branchesDropdown = calledFromShift ? null : (
            <div className="content-row__item">
                <p className="title">Branches</p>
                <div className="inner">
                    <SelectComponent
                        name="selectBranch"
                        icon="caret down"
                        floating
                        onChange={this.onChangeHandler}
                        options={[]}
                    />
                </div>
            </div>
        );

        const isDisabled = status === JOB.PAID || status === JOB.PAYMENT_APPROVED;
        const canBeAdjusted = status === JOB.COMPLETED || status === JOB.ACTIVE;
        const isEditable = status === JOB.UNFILLED || status === JOB.PENDING;

        const isGphcNumberSearch = !isNaN(userSearchKeyword) && Number.isInteger(parseFloat(userSearchKeyword)); //Number.isFinite(userSearchKeyword);
        const typeOptions = this.props.locums.map((locum) => {
            const image = locum.userProfile && locum.userProfile.image ? locum.userProfile.image : null;
            const locumName = this.getLocumName(locum);
            const value = selectedUser || !isGphcNumberSearch ? locumName : locum.gphcNumber;
            return {
                value: value,
                key: locum.gphcNumber,
                text: value,
                content: (
                    <div className="option-inner">
                        <span className="option-inner__name">{locumName}</span>
                        <span className="option-inner__role">{locum.gphcNumber}</span>
                    </div>
                ),
                image: { avatar: !!image, src: image }
            };
        });

        if (formType === FormType.Expence) {
            const { mileageRules, transportRules, parkingRules, accommodationRules } = this.props;
            return (
                <ExpenceCoverageForm
                    onSubmit={this.onExpencesSubmit.bind(this)}
                    onCancel={this.onExpencesCancel}
                    mileageRules={mileageRules || []}
                    transportRules={transportRules || []}
                    parkingRules={parkingRules || []}
                    accommodationRules={accommodationRules || []}
                    submitLoading={loading}
                    savedExpences={this.state.expences}
                />
            );
        }

        const selectedDays = workDays.filter((wd) => wd.checked);

        let daysDiff = moment(endDate).diff(startDate, 'days') + 1;
        daysDiff = daysDiff >= 0 ? daysDiff : 0;

        return (
            <Form className="post-job-bulk-form" onSubmit={this.onSubmitClickHandler}>
                <div className="content-row">
                    {branchesDropdown}
                    <div className="content-row__item">
                        <div className="inner">
                            <Datepicker
                                name="startDate"
                                value={startDate || null}
                                label="Starting Date"
                                onChange={this.onChangeHandler}
                                format="DD/MM/YY"
                                minDate={
                                    !this.props.defaultDate
                                        ? new Date(Date.now() - 864e5)
                                        : new Date(this.props.defaultDate) || ''
                                } // 864e5 == 86400000 == 24*60*60*1000
                                disabled={loading || isDisabled || canBeAdjusted}
                                fluid={false}
                            />
                            <p className="duration">{daysDiff || 0}d</p>
                            <Datepicker
                                name="endDate"
                                value={endDate || null}
                                label="Finish Date"
                                onChange={this.onChangeHandler}
                                minDate={startDate || null}
                                format="DD/MM/YY"
                                disabled={loading || isDisabled || canBeAdjusted}
                                fluid={false}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <CustomCheckbox
                            name="fullDay"
                            label="Full Day"
                            checked={fullDay}
                            onChange={() => this.setState({ fullDay: !fullDay })}
                            disabled={isDisabled || !isMultiDay}
                        />
                    </div>
                    {!fullDay ? (
                        <>
                            <div className="content-row__item">
                                <div className="">
                                    <p className="title">Working Days</p>
                                    <CheckboxListContainer items={daysCheckboxes} layout="row" boxesSize="small" />
                                </div>
                            </div>
                            <div className={selectedDays.length && 'days-scroll-container'}>
                                {selectedDays.map((wd) => {
                                    const displayShiftDuration = getDurationFormat(wd.duration);
                                    const getDayTotalAmount = this.calculateTotal(1, {
                                        ...wd,
                                        paidLunch
                                    });

                                    return (
                                        <div key={wd.title} className="content-row__item">
                                            <p className="title">
                                                {wd.title[0].toUpperCase() + wd.title.slice(1)} Operating Hours
                                            </p>
                                            <div className="inner mr-1">
                                                <Timepicker
                                                    size="medium"
                                                    onChange={(time) =>
                                                        this.onStatTimeChange(
                                                            wd,
                                                            time,
                                                            'startTime',
                                                            wd.endTime,
                                                            'duration'
                                                        )
                                                    }
                                                    format={TIME_FORMAT}
                                                    minuteStep={15}
                                                    value={wd.startTime || moment()}
                                                    disabled={loading || isDisabled}
                                                />
                                                <p className="duration">{displayShiftDuration}</p>
                                                <Timepicker
                                                    size="medium"
                                                    onChange={(time) =>
                                                        this.onFinishTimeChange(
                                                            wd,
                                                            time,
                                                            'endTime',
                                                            wd.startTime,
                                                            'duration'
                                                        )
                                                    }
                                                    format={TIME_FORMAT}
                                                    minuteStep={15}
                                                    value={wd.endTime || moment()}
                                                    disabled={loading || isDisabled}
                                                />
                                            </div>
                                            <div className="content-row__item mt-1">
                                                <p className={`title ${isEdit ? 'disabled' : ''}`}>Hourly Rate</p>
                                                <div className="inner hourly-rate">
                                                    <div className="hourly-rate-box">
                                                        <TextInput
                                                            name="hourlyRate"
                                                            type="text"
                                                            value={wd.hourlyRate ? wd.hourlyRate + '' : ''}
                                                            fluid={false}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    workDays: workDays.map((result) => {
                                                                        if (wd.title === result.title) {
                                                                            return {
                                                                                ...result,
                                                                                hourlyRate: e.target.value
                                                                            };
                                                                        }
                                                                        return result;
                                                                    })
                                                                });
                                                            }}
                                                            disabled={loading || isDisabled || canBeAdjusted}
                                                        />
                                                    </div>
                                                    <p className="total-amount">{`Total: £${
                                                        isNaN(getDayTotalAmount) ? '' : getDayTotalAmount
                                                    }`}</p>
                                                    <div style={{ flex: '0 0 26%' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    ) : null}
                    <>
                        <div className="content-row__item">
                            <CustomCheckbox
                                name="paidLunch"
                                label="Paid Lunch"
                                checked={paidLunch}
                                onChange={this.onCheckboxChangeHandler}
                                disabled={isDisabled || !lunchExist}
                            />
                        </div>
                    </>
                    <div className="content-row__item">
                        <div className="inner hourly-rate">
                            <CustomCheckbox
                                name="expenses"
                                label="Cover expenses"
                                checked={hasExpences}
                                onChange={() => this.setState({ ...this.state, hasExpences: !hasExpences })}
                                disabled={['paid', 'payment_approved'].includes(status) || isDisabled || isEdit}
                            />
                        </div>
                    </div>

                    <div className="content-row__item">
                        <p className={`title ${isEdit ? 'disabled' : ''}`}>Description</p>
                        <div className="inner">
                            <TextAreaComponent
                                name="description"
                                value={description || ''}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={this.onChangeHandler}
                                disabled={loading || isDisabled || canBeAdjusted}
                            />
                        </div>
                    </div>
                    <div className="content-row__item">
                        <CustomCheckbox
                            name="externalLocumBooking"
                            label="Assign Locum"
                            checked={externalLocumBooking}
                            onChange={this.onCheckboxChangeHandler}
                            disabled={loading || isDisabled || canBeAdjusted}
                        />
                    </div>
                    {externalLocumBooking && (
                        <>
                            <div className="content-row__item">
                                <SelectComponent
                                    name="userSearchKeyword"
                                    label="GPhC/Name"
                                    search
                                    onSearchChange={(syntheticEvent, eventObj) => {
                                        this.setState(
                                            {
                                                userSearchKeyword: eventObj.searchQuery,
                                                selectedUser: null,
                                                email: '',
                                                phone: ''
                                            },
                                            () => {
                                                this.onLocumGet();
                                            }
                                        );
                                    }}
                                    placeholder="GPhC/Name"
                                    icon="caret down"
                                    floating
                                    value={selectedUser ? this.getLocumName(selectedUser) : 0}
                                    onChange={(_, eventObj) => {
                                        const matchUser = this.props.locums.find(
                                            (locum) =>
                                                locum.gphcNumber === eventObj.value ||
                                                this.getLocumName(locum) === eventObj.value
                                        );
                                        this.setState({
                                            selectedUser: matchUser,
                                            email: matchUser.email || '',
                                            phone: matchUser.userProfile ? matchUser.userProfile.phone || '' : ''
                                        });
                                    }}
                                    options={typeOptions}
                                />
                            </div>

                            <div className="content-row__item">
                                <div className="inner">
                                    <TextInput
                                        name="email"
                                        type="text"
                                        label="Email"
                                        value={email}
                                        fluid={false}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        onChange={this.onChangeHandler}
                                        disabled={loading || isDisabled || canBeAdjusted}
                                    />
                                    <p className="duration" />
                                    <TextInput
                                        name="phone"
                                        type="text"
                                        label="Phone"
                                        value={phone}
                                        fluid={false}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        onChange={this.onChangeHandler}
                                        disabled={loading || isDisabled || canBeAdjusted}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="buttons-row double">
                    <ButtonModal
                        value={isEdit ? 'Cancel' : 'Close'}
                        colorType="red"
                        // loading={removingLoading}
                        onClick={this.onCancelClickHandler}
                    />
                    <ButtonModal
                        value={hasExpences ? 'Next' : isEdit ? 'Edit' : 'Post'}
                        colorType="blue"
                        type="submit"
                        disabled={
                            !startDate ||
                            !endDate ||
                            (!isMultiDay && (!!errors.hourlyRate || !hourlyRate)) ||
                            !description ||
                            (isEditable && externalLocumBooking && (!selectedUser || !email || !phone))
                        }
                        loading={loading}
                    />
                </div>
            </Form>
        );
    }
}

PostJobBulkForm.propTypes = {
    defaultDate: PropTypes.object,
    staffList: PropTypes.array,
    calledFromShift: PropTypes.bool,
    jobData: PropTypes.object,
    isEdit: PropTypes.bool,
    loading: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    onSubmitClick: PropTypes.func.isRequired,
    onGphcChange: PropTypes.func,
    locums: PropTypes.array,
    operatingTime: PropTypes.array
};

PostJobBulkForm.defaultProps = {
    operatingTime: [],
    locums: []
};

export default PostJobBulkForm;
