import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react';
import ButtonComponent from '../../UI/buttons/button';
import TextInput from '../../UI/inputs/text-input';

import { digitsNumberError } from '../../../services/validate';

import './style.scss';

class LocumCheckInForm extends Component {
    state = {
        gphcNumber: '',
        validate: {
            gphcNumber: false
        },
        errors: {
            gphcNumber: null,
            form: ''
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validate = async (name) => {
        const value = this.state[name];
        let error = null;

        if (name === 'gphcNumber') error = digitsNumberError(value);

        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    errors: {
                        ...prevState.errors,
                        [name]: error
                    },
                    validate: {
                        ...prevState.validate,
                        [name]: true
                    }
                }),
                resolve
            );
        });
    };

    onChangeHandler = ({ target: { name, value } }) => {
        const { errors, validate } = this.state;

        return this.setState(
            {
                [name]: value
            },
            () => {
                if (errors[name] || validate[name]) this.validate(name);
            }
        );
    };

    onConfirmIdentityClick = async (e) => {
        e.preventDefault();
        const { onConfirmIdentity } = this.props;
        await this.validate('gphcNumber');

        const { gphcNumber, errors } = this.state;

        if (errors.gphcNumber) return;

        onConfirmIdentity(gphcNumber);
    };

    onCancelCheckInClick = (e) => {
        e.preventDefault();
        const { onCancelCheckIn } = this.props;
        onCancelCheckIn();
    };

    render() {
        const { gphcNumber, errors } = this.state;
        const { loading } = this.props;

        return (
            <Form className="check-in-form">
                <div className="check-in-form__step step-one">
                    <h2 className="check-in-form__title">Let’s Confirm your Identity</h2>
                    <p className="check-in-form__subtitle">
                        Enter your GPhC number in the box below so we can check your details from the GPhC register
                    </p>
                    <TextInput
                        name="gphcNumber"
                        type="text"
                        value={gphcNumber}
                        label="GPHC Number"
                        onChange={this.onChangeHandler}
                        error={!!errors.gphcNumber}
                        helperText={errors.gphcNumber}
                    />
                    <div className="buttons-row">
                        <ButtonComponent
                            value="Confirm"
                            onClick={this.onConfirmIdentityClick}
                            loading={loading}
                            size="big"
                            width="big"
                            disabled={!!errors.gphcNumber}
                        />
                        <ButtonComponent
                            value="Cancel"
                            type="transparent"
                            size="big"
                            width="big"
                            onClick={this.onCancelCheckInClick}
                            disabled={loading}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

LocumCheckInForm.propTypes = {
    loading: PropTypes.bool,
    onConfirmIdentity: PropTypes.func.isRequired,
    onCancelCheckIn: PropTypes.func.isRequired
};

export default LocumCheckInForm;
