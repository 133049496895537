import { call, put, fork, takeEvery, delay } from 'redux-saga/effects';
import {
    getLocumSettingsSuccess,
    getLocumSettingsFailed,
    onLocumSettingsUpdateSuccess,
    onLocumSettingsUpdateFailed
} from '../../../actions';
import * as locumSettingsTypes from '../../../actions/locum-view/settings/action-types';
import api from '../../../../services/api';

function* onGetLocumSettingsRequest() {
    try {
        const { data } = yield call(api.request, {
            url: `/settings/locum/`,
            method: 'GET'
        });
        yield put(getLocumSettingsSuccess(data));
    } catch (err) {
        yield put(getLocumSettingsFailed());
    }
}

function* onUpdateLocumSettingsRequest({ payload }) {
    try {
        yield delay(1000);
        const { data } = yield call(api.request, {
            url: `/settings/locum`,
            method: 'POST',
            payload
        });
        yield put(onLocumSettingsUpdateSuccess());
        yield put(getLocumSettingsSuccess(data));
    } catch (err) {
        yield put(onLocumSettingsUpdateFailed());
    }
}

// watchers
function* watchGetLocumJobRequest() {
    yield takeEvery(locumSettingsTypes.GET_LOCUM_SETTINGS_REQUEST, onGetLocumSettingsRequest);
}

function* watchLocumSettingsUpdateRequest() {
    yield takeEvery(locumSettingsTypes.LOCUM_SETTINGS_UPDATE_REQUEST, onUpdateLocumSettingsRequest);
}

const locumJobSettingsSaga = [fork(watchGetLocumJobRequest), fork(watchLocumSettingsUpdateRequest)];

export default locumJobSettingsSaga;
