import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

import './style.scss';

const Timepicker = ({ value, defaultOpenValue, format, onChange, disabled, size = 'small', helperText }) => {
    return (
        <div className="input-group">
            <TimePicker
                showSecond={false}
                defaultValue={moment().hour(0).minute(0)}
                onChange={onChange}
                format={format}
                value={value}
                defaultOpenValue={defaultOpenValue}
                minuteStep={15}
                inputReadOnly
                disabled={disabled}
                className={`timepicker ${size}`}
            />
            <p className="helper-text">{helperText}</p>
        </div>
    );
};

Timepicker.propTypes = {
    onChange: PropTypes.func,
    format: PropTypes.string,
    value: PropTypes.object,
    defaultOpenValue: PropTypes.object,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    helperText: PropTypes.string
};

export default Timepicker;
