import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ErrorImage } from '../../../../assets/img/404.svg';
import ButtonComponent from '../../../../components/UI/buttons/button';

function PageNotFound() {
    const history = useHistory();

    return (
        <div className="error-container">
            <div className="error-container__inner">
                <ErrorImage />
                <h1 className="error-title">Oooops....Page not found</h1>
                <ButtonComponent value="Return Back" onClick={() => history.goBack()} />
            </div>
        </div>
    );
}

export default PageNotFound;
