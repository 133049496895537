import React, { useEffect } from 'react';
import './styles.scss';

import Authentication from '../authentication';
import Root from '../root';
import GlobalLoader from '../../components/UI/global-loader';
import LocalLoader from '../../components/UI/local-loader';

export function App({ isAuth, registerProcess, authUserRequest, loader, ...props }) {
    useEffect(() => {
        authUserRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUserRequest]);

    return isAuth === undefined ? (
        <LocalLoader size="huge" color="blue" />
    ) : isAuth && !registerProcess ? (
        <>
            <GlobalLoader color="blue" loaderState={loader} />
            <Root />
        </>
    ) : (
        <Authentication />
    );
}
