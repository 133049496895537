import {
    GET_CURRENT_DAY,
    CLEAR_CURRENT_DAY,
    UPDATE_DAY_SHIFTS,
    GET_POST_JOB_DRAFT,
    ON_POST_JOB_REQUEST,
    ON_POST_JOB_SUCCESS,
    ON_POST_JOB_FAILED,
    SEPARATE_JOB,
    LONG_TERM_JOB,
    GET_BRANCH_SHIFTS_SUCCESS,
    GET_BRANCH_SHIFTS_FAILED,
    GET_BRANCH_AVAILABLE_STAFF_REQUEST,
    GET_BRANCH_AVAILABLE_STAFF_SUCCESS,
    GET_BRANCH_AVAILABLE_STAFF_FAILED,
    ON_SUBMIT_SHIFT_REQUEST,
    ON_SUBMIT_SHIFT_SUCCESS,
    ON_SUBMIT_SHIFT_FAILED,
    ON_SUBMIT_DRAFT_REQUEST,
    ON_SUBMIT_DRAFT_SUCCESS,
    ON_SUBMIT_DRAFT_FAILED,
    ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST,
    ON_GET_SCHEDULE_SHIFTS_BY_FILTER_SUCCESS,
    ON_GET_SCHEDULE_SHIFTS_BY_FILTER_FAILED
} from '../../../actions/admin-view/branch-schedule/action-types';

const initialState = {
    currentDay: {},
    postJob: {
        jobData: {},
        loading: false
    },
    shiftsList: {
        data: null,
        loading: false
    },
    staffList: {
        data: null,
        loading: false
    },
    shiftsByFilter: {
        data: null,
        loading: false,
        itemsPerPage: 10,
        totalData: null
    }
};

export const handleCurrentDay = (state, action) => {
    if (state === undefined) {
        return initialState.currentDay;
    }

    switch (action.type) {
        case GET_CURRENT_DAY:
            return (state = action.payload);
        case CLEAR_CURRENT_DAY:
            return (state = {});
        case UPDATE_DAY_SHIFTS:
            return {
                ...state.currentDay,
                shifts: action.payload
            };
        default:
            return state.currentDay;
    }
};

export const handleJobPosting = (state, action) => {
    if (state === undefined) {
        return initialState.postJob;
    }

    switch (action.type) {
        case GET_POST_JOB_DRAFT:
            return {
                ...state.postJob,
                jobData: action.payload
            };
        case SEPARATE_JOB:
            return {
                ...state.postJob,
                jobData: {
                    ...state.postJob.jobData,
                    split: true
                }
            };
        case LONG_TERM_JOB:
            return {
                ...state.postJob,
                jobData: {
                    ...state.postJob.jobData,
                    split: false
                }
            };
        case ON_POST_JOB_REQUEST:
            return {
                ...state.postJob,
                loading: true
            };
        case ON_POST_JOB_SUCCESS:
        case ON_POST_JOB_FAILED:
            return {
                ...state.postJob,
                loading: false
            };
        default:
            return state.postJob;
    }
};

export const handleBranchShifts = (state, action) => {
    if (state === undefined) {
        return initialState.shiftsList;
    }
    switch (action.type) {
        case GET_BRANCH_SHIFTS_SUCCESS:
            return {
                ...state.shiftsList,
                data: action.payload
            };
        case GET_BRANCH_SHIFTS_FAILED:
            return {
                ...state.shiftsList,
                loading: false
            };
        case ON_SUBMIT_SHIFT_REQUEST:
        case ON_SUBMIT_DRAFT_REQUEST:
            return {
                ...state.shiftsList,
                loading: true
            };
        case ON_SUBMIT_SHIFT_SUCCESS:
        case ON_SUBMIT_SHIFT_FAILED:
        case ON_SUBMIT_DRAFT_SUCCESS:
        case ON_SUBMIT_DRAFT_FAILED:
            return {
                ...state.shiftsList,
                loading: false
            };
        default:
            return state.shiftsList;
    }
};

export const handleAvailableStaff = (state, action) => {
    if (state === undefined) {
        return initialState.staffList;
    }
    switch (action.type) {
        case GET_BRANCH_AVAILABLE_STAFF_REQUEST:
            return {
                ...state.staffList,
                loading: true
            };
        case GET_BRANCH_AVAILABLE_STAFF_SUCCESS:
            return {
                ...state.staffList,
                data: action.payload,
                loading: false
            };
        case GET_BRANCH_AVAILABLE_STAFF_FAILED:
            return {
                ...state.staffList,
                loading: false
            };
        default:
            return state.staffList;
    }
};

export const handleShiftsByFilterGet = (state, action) => {
    if (state === undefined) {
        return initialState.shiftsByFilter;
    }

    switch (action.type) {
        case ON_GET_SCHEDULE_SHIFTS_BY_FILTER_REQUEST:
            return {
                ...state.shiftsByFilter,
                loading: true
            };
        case ON_GET_SCHEDULE_SHIFTS_BY_FILTER_SUCCESS:
            return {
                ...state.shiftsByFilter,
                data: action.payload.data.rows,
                totalData: action.payload.data.count
            };

        case ON_GET_SCHEDULE_SHIFTS_BY_FILTER_FAILED:
            return {
                ...state.shiftsByFilter,
                loading: false
            };
        default:
            return state.shiftsByFilter;
    }
};
