import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const ContentContainer = ({ transparent, smallPaddings, fluid, xSmallPaddings, autoHeight, ...props }) => {
    return (
        <main
            className={classnames('content-container', {
                transparent,
                smallPaddings,
                fluid,
                autoHeight,
                xSmallPaddings
            })}
        >
            {props.children}
        </main>
    );
};

ContentContainer.propTypes = {
    transparent: PropTypes.bool,
    smallPaddings: PropTypes.bool,
    fluid: PropTypes.bool,
    autoHeight: PropTypes.bool
};

export default ContentContainer;
