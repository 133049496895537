import React from 'react';
import PropTypes from 'prop-types';
import { Step } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';

import './style.scss';

const SingleStep = ({ avatar, title, onClick }) => (
    <Step onClick={onClick}>
        <Image src={avatar} avatar />
        <Step.Content>
            <Step.Title>{title}</Step.Title>
        </Step.Content>
    </Step>
);

SingleStep.propTypes = {
    avatar: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default SingleStep;
