import React from 'react';
import './style.scss';
import { PropTypes } from 'prop-types';

const DataTableSection = ({ sectionTitle, children }) => {
    return (
        <div className="data-table-section-wrapper">
            <div className="data-table-section-wrapper__title-col">
                <p className="data-table-section-wrapper__title-col-name">{sectionTitle}</p>
            </div>
            <div className="data-table-section-wrapper__item-col">{children}</div>
        </div>
    );
};

DataTableSection.propTypes = { sectionTitle: PropTypes.string, children: PropTypes.node };

export default DataTableSection;
