import React from 'react';

import UsersListPage from './users-list';
import UserDetails from './user-details';

import { Switch, Route } from 'react-router-dom';
import { RouterConfig } from './../../../../routerConfig';

const AdminUsersPage = (props) => {
    return (
        <Switch>
            <Route path={RouterConfig.superAdminUsers} component={UsersListPage} exact />
            <Route path={RouterConfig.superAdminUserRoot} component={UserDetails} />
        </Switch>
    );
};

export default AdminUsersPage;
