export const GET_POSTED_JOBS_REQUEST = 'GET_POSTED_JOBS_REQUEST';
export const GET_POSTED_JOBS_SUCCESS = 'GET_POSTED_JOBS_SUCCESS';
export const GET_POSTED_JOBS_FAILED = 'GET_POSTED_JOBS_FAILED';

export const GET_CSV_JOBS_DOWNLOAD_REQUEST = 'GET_CSV_JOBS_DOWNLOAD_REQUEST';
export const GET_CSV_JOBS_DOWNLOAD_SUCCESS = 'GET_CSV_JOBS_DOWNLOAD_SUCCESS';
export const GET_CSV_JOBS_DOWNLOAD_FAILED = 'GET_CSV_JOBS_DOWNLOAD_FAILED';

export const ON_JOBS_FILTER_CHANGE = 'ON_JOBS_FILTER_CHANGE';
export const ON_JOBS_FILTER_FALSE = 'ON_JOBS_FILTER_FALSE';
export const CLEAR_JOBS_FILTERS = 'CLEAR_JOBS_FILTERS';

export const SET_JOB_STATUS_REQUEST = 'SET_JOB_STATUS_REQUEST';
export const SET_JOB_STATUS_SUCCESS = 'SET_JOB_STATUS_SUCCESS';
export const SET_JOB_STATUS_FAILED = 'SET_JOB_STATUS_FAILED';

export const GET_MIN_AND_MAX_JOBS_REQUEST = 'GET_MIN_AND_MAX_JOBS_REQUEST';
export const GET_MIN_AND_MAX_JOBS_SUCCESS = 'GET_MIN_AND_MAX_JOBS_SUCCESS';
export const GET_MIN_AND_MAX_JOBS_FAILED = 'GET_MIN_AND_MAX_JOBS_FAILED';
