import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Checkbox, Dropdown } from 'semantic-ui-react';
import Datepicker from '../../../../../components/UI/datepicker';
import moment from 'moment';
import './style.scss';
import { Icon } from 'semantic-ui-react';

const JOB = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
    PENDING: 'pending',
    UNFILLED: 'unfilled',
    PAID: 'paid',
    PAYMENT_APPROVED: 'payment_approved',
    COMPLETED: 'completed'
};

const statuses = [
    {
        value: JOB.ACTIVE,
        text: 'Active'
    },
    {
        value: JOB.CANCELLED,
        text: 'Cancelled'
    },
    {
        value: JOB.PENDING,
        text: 'Pending'
    },
    {
        value: JOB.UNFILLED,
        text: 'Unfilled'
    },
    {
        value: JOB.PAID,
        text: 'Paid'
    },
    {
        value: JOB.PAYMENT_APPROVED,
        text: 'Payment Approved'
    },
    {
        value: JOB.COMPLETED,
        text: 'Completed'
    }
];

const cols = [
    [
        {
            value: 'branchName',
            text: 'Branch Name'
        },
        {
            value: 'branchNumber',
            text: 'Branch Number'
        },
        {
            value: 'branchAddress',
            text: 'Branch Address'
        }
    ],
    [
        {
            value: 'jobDate',
            text: 'Job Date'
        },
        {
            value: 'jobTime',
            text: 'Job Time'
        },
        {
            value: 'jobStatus',
            text: 'Job Status'
        },
        {
            value: 'hourlyRate',
            text: 'Hourly Rate'
        },
        {
            value: 'mileageExpense',
            text: 'Mileage Expense'
        },
        {
            value: 'accommodation',
            text: 'Accommodation & Food Expense'
        },
        {
            value: 'transport',
            text: 'Transport Expense'
        },
        {
            value: 'parking',
            text: 'Parking Expense'
        },
        {
            value: 'totalExpenseAmount',
            text: 'Total Expense Amount'
        },
        {
            value: 'totalAmount',
            text: 'Total Amount'
        }
    ],
    [
        {
            value: 'locumName',
            text: 'Locum Name'
        },
        {
            value: 'locumGPHCNumber',
            text: 'Locum GPHhC Number'
        },
        {
            value: 'locumPhoneNumber',
            text: 'Locum Phone Number'
        },
        {
            value: 'locumAddress',
            text: 'Locum Address'
        }
    ]
];

const colTitles = ['Branch details', 'Job details', 'Locum details'];

export default function LocumShiftsDownloadModal({ open, onClose, onDownload, startDate, endDate, tab }) {
    const [filters, setFilters] = useState({
        dateFrom: '',
        dateTo: '',
        statuses: [],
        columns: []
    });

    useEffect(() => {
        if (!open) return;
        const columns = [];
        cols.forEach((col) => {
            columns.push(...col.map((col) => col.value));
        });
        setFilters({
            ...filters,
            dateFrom: startDate ? new Date(startDate) : new Date(),
            dateTo: endDate ? new Date(endDate) : new Date(),
            statuses: statuses.map((status) => status.value),
            columns
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleFiltersChange = (syntheticEvent, eventObj) => {
        const { name, value } = eventObj === undefined ? syntheticEvent.target : eventObj;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const toggleCol = (col) => {
        const matchCol = filters.columns.includes(col.value);
        if (matchCol) {
            setFilters({
                ...filters,
                columns: filters.columns.filter((each) => each !== col.value)
            });
        } else {
            setFilters({
                ...filters,
                columns: [...filters.columns, col.value]
            });
        }
    };
    const handleSelectStatus = (_, eventObj) => {
        const { value } = eventObj;
        const matchStatus = filters.statuses.includes(value);
        if (matchStatus) {
            setFilters({
                ...filters,
                statuses: filters.statuses.filter((status) => status !== value)
            });
        } else {
            setFilters({
                ...filters,
                statuses: value
            });
        }
    };

    const handleDownload = () => {
        onDownload({
            ...filters,
            dateFrom: filters.dateFrom && moment(filters.dateFrom).format('YYYY-MM-DD'),
            dateTo: filters.dateTo && moment(filters.dateTo).format('YYYY-MM-DD')
        });
    };

    const onDateFilter = useCallback(() => {
        return (date) => {
            if (tab === 'upcoming') {
                return date >= new Date();
            }
            if (tab === 'past') {
                return date <= new Date();
            }
            return true;
        };
    }, [tab]);

    return (
        <Modal onClose={() => onClose()} open={open}>
            <Modal.Header
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                {`Download ${tab} shifts as CSV`}
                <Icon onClick={() => onClose()} style={{ cursor: 'pointer' }} name="close" />
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="locum-shifts-download-modal">
                        <p className="mb-1">Apply filters to the jobs</p>
                        <div className="mb-1">
                            <div className="d-flex mb-1">
                                <div className="d-flex align-center">
                                    <p>Date from</p>
                                    <div className="ml-1 flex-col">
                                        <Datepicker
                                            clearable={false}
                                            fluid={false}
                                            filterDate={onDateFilter()}
                                            name="dateFrom"
                                            value={filters.dateFrom || null}
                                            onChange={handleFiltersChange}
                                            format="DD/MM/YY"
                                        />
                                    </div>
                                </div>
                                <div style={{ flex: '0 0 50%' }} className="ml-5">
                                    <Dropdown
                                        placeholder="Statuses"
                                        fluid
                                        multiple
                                        clearable
                                        value={filters.statuses}
                                        selection
                                        onChange={handleSelectStatus}
                                        options={statuses.map((status) => {
                                            return {
                                                value: status.value,
                                                key: status.value,
                                                text: status.text
                                            };
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="mb-1">
                                <div className="d-flex mb-1">
                                    <div className="d-flex align-center">
                                        <p>Date to</p>
                                        <div style={{ marginLeft: '2.3rem' }} className="flex-col">
                                            <Datepicker
                                                clearable={false}
                                                fluid={false}
                                                name="dateTo"
                                                value={filters.dateTo || null}
                                                onChange={handleFiltersChange}
                                                filterDate={onDateFilter()}
                                                format="DD/MM/YY"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ flex: '0 0 50%' }} className="ml-5"></div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <p className="mb-1 mt-2">Select details of shifts to be present in the csv document</p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {cols.map((_, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <p style={{ fontWeight: 800, marginBottom: '1rem' }}>
                                                    {colTitles[index]}
                                                </p>
                                                {cols[index].map((col) => {
                                                    return (
                                                        <Checkbox
                                                            style={{
                                                                marginBottom: '1rem'
                                                            }}
                                                            key={col.value}
                                                            name={col.value}
                                                            label={col.text}
                                                            onChange={() => toggleCol(col)}
                                                            checked={filters.columns.includes(col.value)}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Download" color="blue" onClick={() => handleDownload()} />
            </Modal.Actions>
        </Modal>
    );
}
