import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import store from './store/store';
import ErrorBoundary from './containers/error-boundary';

import App from './containers/app';

const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false // enable logs
};

ReactPixel.init('1040328269695397', options);
ReactGA.initialize('UA-92955511-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <LastLocationProvider>
                <ReactNotification />
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </LastLocationProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);
