import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { generatePath } from 'react-router';
import query from 'query-string';

import PageNav from '../../../../../components/page-nav';
import TableComponent from '../../../../../components/table';
import BasicModal from '../../../../../components/modals/basic-modal';
import ConfirmModal from '../../../../../components/modals/confirm-modal';
import InviteStaffForm from '../../../../../components/forms/invite-staff-form';
import CreateBranchContainer from '../../../../create-branch';
import { onFilter } from '../../../../../services/fetchDataHelper';
import { RouterConfig } from '../../../../../routerConfig';

class BranchesList extends Component {
    state = {
        modals: {
            inviteStaff: false,
            inviteStaffSuccess: false,
            create: false
        },
        isSorting: false,
        paginationSorting: {},
        paginationKey: null,
        paginationValue: null,
        loading: false,
        currentItemId: null,
        error: null,
        info: null
    };

    componentDidMount() {
        this._isMounted = true;
        const {
            location: { search },
            getBranchesListRequest,
            getInvitesDataRequest
        } = this.props;

        getInvitesDataRequest();
        getBranchesListRequest({ page: query.parse(search).page });
    }

    componentWillUnmount() {
        this._isMounted = false;
        const { clearBranchesfilters } = this.props;
        clearBranchesfilters();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        const {
            location: { search },
            branchesPage: { isFiltering, filters },
            getBranchesListRequest
        } = this.props;
        const { isSorting } = this.state;
        const prevPage = query.parse(prevProps.location.search).page;
        let { page } = query.parse(search);

        if (page !== prevPage && prevPage === undefined) {
            return;
        } else if (page !== prevPage && !isSorting && !isFiltering) {
            getBranchesListRequest({ page: query.parse(search).page });
        } else if (page !== prevPage && !isSorting && isFiltering) {
            getBranchesListRequest({ page: query.parse(search).page }, filters);
        }
    };

    toggleModal = (name, itemId) => {
        const { modals } = this.state;
        const currentState = modals[name];

        return this.setState({
            modals: { ...modals, [name]: !currentState },
            currentItemId: itemId === undefined ? null : itemId
        });
    };

    onInviteStaff = (recievedData) => {
        const { modals } = this.state;
        const { onInviteStaffRequest } = this.props;
        onInviteStaffRequest({
            recievedData,
            toggleModal: () =>
                this.setState({
                    loading: false,
                    modals: {
                        ...modals,
                        inviteStaff: false,
                        inviteStaffSuccess: true
                    }
                })
        });
    };

    onCreateBranchSuccess = () => {
        const { history, createdBranchId } = this.props;
        history.push(generatePath(RouterConfig.branchAdminBranchRoot, { id: createdBranchId }));
    };

    render() {
        const { modals } = this.state;
        const {
            location: { search },
            branchesPage: {
                data,
                totalData,
                itemsPerPage,
                filters: { title, phone, region, address, number, active },
                redirectPageTo
            },
            inviteStaff: {
                invitesData: { roles, regions, branches }
            },
            onBranchesFilterChange
        } = this.props;
        const { page } = query.parse(search);

        const tableCols = [
            {
                name: 'number',
                title: 'Branch number',
                value: number,
                render: (item) => item.number,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onBranchesFilterChange, page),
                type: 'search'
            },
            {
                name: 'title',
                title: 'name',
                value: title,
                render: (item) => item.title,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onBranchesFilterChange, page),
                type: 'search'
            },
            {
                name: 'region',
                title: 'Region',
                value: region,
                render: (item) => item.regionName,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onBranchesFilterChange, page),
                type: 'search'
            },
            {
                name: 'address',
                title: 'address',
                value: address,
                render: (item) => item.address1,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onBranchesFilterChange, page),
                type: 'search'
            },
            {
                name: 'phone',
                title: 'phone',
                value: phone,
                render: (item) => item.phone,
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onBranchesFilterChange, page),
                type: 'search'
            },
            {
                name: 'active',
                title: 'status',
                value: active,
                render: (item) => (item.active ? 'Active' : 'Inactive'),
                onChange: (syntheticEvent, eventObj) =>
                    onFilter(syntheticEvent, eventObj, onBranchesFilterChange, page),
                type: 'select',
                options: [
                    { value: true, text: 'Active' },
                    { value: false, text: 'Inactive' }
                ],
                search: true,
                icon: 'search'
            }
        ];

        const headerButtons = [
            {
                componentType: 'button',
                value: 'Add staff',
                onClick: () => this.toggleModal('inviteStaff'),
                size: 'medium',
                width: 'medium'
            },
            {
                componentType: 'button',
                value: 'Create branch',
                onClick: () => this.toggleModal('create'),
                size: 'medium',
                width: 'medium'
            }
        ];

        const branchesTable =
            data.rows === undefined ? null : (
                <TableComponent
                    cols={tableCols}
                    data={data.rows}
                    totalItems={totalData}
                    itemsPerPage={itemsPerPage}
                    page={Number(page)}
                    openInfo="/branches/details"
                />
            );

        const inviteMoreButton = {
            value: 'Invite More',
            onClick: () =>
                this.setState({
                    modals: {
                        ...modals,
                        inviteStaff: true,
                        inviteStaffSuccess: false
                    }
                }),
            size: 'big',
            width: 'big'
        };

        return (
            <div className="branches-admin-page">
                {!page && <Redirect from="/branches" to="/branches?page=1" exact />}
                {/* if all filters was cleared return to first page */}
                {!!redirectPageTo && <Redirect from={`/branches?page=${page}`} to="/branches?page=1" exact />}
                <div className="main-container">
                    <PageNav title="Branches" inputs={headerButtons} />
                    {branchesTable}
                </div>
                {modals.inviteStaff && (
                    <BasicModal
                        open={modals.inviteStaff}
                        onClose={() => this.toggleModal('inviteStaff')}
                        title="Add Staff"
                    >
                        <InviteStaffForm
                            roles={roles}
                            regions={regions}
                            branches={branches}
                            loading={this.props.inviteStaff.loading}
                            onSuccess={this.onInviteStaff}
                            onCancelInvite={() => this.toggleModal('inviteStaff')}
                        />
                    </BasicModal>
                )}
                {modals.inviteStaffSuccess && (
                    <ConfirmModal
                        open={modals.inviteStaffSuccess}
                        onClose={() => this.toggleModal('inviteStaffSuccess')}
                        title="Invite sent"
                        text="Your invite has been sent"
                        button={inviteMoreButton}
                    />
                )}
                {modals.create && (
                    <BasicModal open={modals.create} onClose={() => this.toggleModal('create')} hiddenHeader>
                        <CreateBranchContainer
                            closeIcon={false}
                            onCancel={() => this.toggleModal('create')}
                            onCreateBranchSuccess={this.onCreateBranchSuccess}
                        />
                    </BasicModal>
                )}
            </div>
        );
    }
}

export default BranchesList;
