import {
    GET_CURRENT_SHIFT,
    GET_CURRENT_SHIFT_ID,
    GET_DAY_SHIFTS_SUCCESS,
    GET_DAY_SHIFTS_FAILED,
    ON_DAY_VIEW_POST_JOB_REQUEST,
    ON_DAY_VIEW_POST_JOB_SUCCESS,
    ON_DAY_VIEW_POST_JOB_FAILED,
    ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST,
    ON_DAY_VIEW_SUBMIT_DRAFT_SUCCESS,
    ON_DAY_VIEW_SUBMIT_DRAFT_FAILED,
    ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST,
    ON_DAY_VIEW_SUBMIT_SHIFT_SUCCESS,
    ON_DAY_VIEW_SUBMIT_SHIFT_FAILED,
    ON_REMOVE_SHIFT_REQUEST,
    ON_REMOVE_SHIFT_SUCCESS,
    ON_REMOVE_SHIFT_FAILED
} from '../../../actions/admin-view/branch-schedule-day-view/action-types';
import {
    GET_BRANCH_AVAILABLE_STAFF_REQUEST,
    GET_BRANCH_AVAILABLE_STAFF_SUCCESS,
    GET_BRANCH_AVAILABLE_STAFF_FAILED
} from '../../../actions/admin-view/branch-schedule/action-types';

const initialState = {
    currentShift: {},
    currentShiftId: null,
    data: null,
    loading: false,
    removingLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_SHIFT:
            return {
                ...state,
                currentShift: action.payload
            };
        case GET_CURRENT_SHIFT_ID:
            return {
                ...state,
                currentShiftId: action.payload
            };
        case GET_DAY_SHIFTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case GET_BRANCH_AVAILABLE_STAFF_REQUEST:
        case ON_DAY_VIEW_POST_JOB_REQUEST:
        case ON_DAY_VIEW_SUBMIT_DRAFT_REQUEST:
        case ON_DAY_VIEW_SUBMIT_SHIFT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_DAY_SHIFTS_FAILED:
        case GET_BRANCH_AVAILABLE_STAFF_SUCCESS:
        case GET_BRANCH_AVAILABLE_STAFF_FAILED:
        case ON_DAY_VIEW_POST_JOB_SUCCESS:
        case ON_DAY_VIEW_POST_JOB_FAILED:
        case ON_DAY_VIEW_SUBMIT_DRAFT_SUCCESS:
        case ON_DAY_VIEW_SUBMIT_DRAFT_FAILED:
        case ON_DAY_VIEW_SUBMIT_SHIFT_SUCCESS:
        case ON_DAY_VIEW_SUBMIT_SHIFT_FAILED:
            return {
                ...state,
                loading: false
            };
        case ON_REMOVE_SHIFT_REQUEST:
            return {
                ...state,
                removingLoading: true
            };
        case ON_REMOVE_SHIFT_SUCCESS:
        case ON_REMOVE_SHIFT_FAILED:
            return {
                ...state,
                removingLoading: false
            };
        default:
            return state;
    }
};
