export const ON_CREATE_BRANCH_REQUEST = 'ON_CREATE_BRANCH_REQUEST';
export const ON_CREATE_BRANCH_SUCCESS = 'ON_CREATE_BRANCH_SUCCESS';
export const ON_CREATE_BRANCH_FAILED = 'ON_CREATE_BRANCH_FAILED';

export const GET_BRANCHES_DATA_REQUEST = 'GET_BRANCHES_DATA_REQUEST';
export const GET_BRANCHES_DATA_SUCCESS = 'GET_BRANCHES_DATA_SUCCESS';
export const GET_BRANCHES_DATA_FAILED = 'GET_BRANCHES_DATA_FAILED';

export const UPDATE_BRANCH_RATE_REQUEST = 'UPDATE_BRANCH_RATE_REQUEST';
export const UPDATE_BRANCH_RATE_SUCCESS = 'UPDATE_BRANCH_RATE_SUCCESS';
export const UPDATE_BRANCH_RATE_FAILED = 'UPDATE_BRANCH_RATE_FAILED';

export const CLEAR_CREATED_BRANCH_INFO = 'CLEAR_CREATED_BRANCH_INFO';
