import { call, put, fork, takeLeading, takeLatest, select, delay } from 'redux-saga/effects';
import { getStaffListRequest, getStaffListSuccess, getStaffListFailed, onStaffFilterFalse } from '../../../actions';
import { selectItemsPerPage, selectFilters } from './selectors';
import * as staffListActionTypes from '../../../actions/admin-view/staff-page/action-types';
import api from '../../../../services/api';

function* onStaffListRequest({ payload }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const filters = yield select(selectFilters);
    const { page } = payload;

    try {
        const { data } = yield call(api.request, {
            url: '/staff/list',
            method: 'post',
            payload: {
                filters,
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getStaffListSuccess({
                data,
                totalData: data.count
            })
        );
        if (payload.toggleModal) payload.toggleModal();
    } catch (err) {
        yield put(getStaffListFailed());
    }
}

function* onStaffFilterChange({ payload: { value }, page }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field.length > 0);

    if (value.length > 2) {
        yield delay(1000);
        yield put(getStaffListRequest({ page }, filters));
    } else if (value.length === 0 && isFilled) {
        yield put(getStaffListRequest({ page }, filters));
    } else if (value.length === 0 && !isFilled && page !== 1) {
        yield put(onStaffFilterFalse());
    } else if (value.length === 0 && !isFilled && page === 1) {
        yield put(getStaffListRequest({ page }, filters));
        yield put(onStaffFilterFalse());
    }
}

// watchers
function* watchStaffListRequest() {
    yield takeLeading(staffListActionTypes.GET_STAFF_LIST_REQUEST, onStaffListRequest);
}

function* watchStaffFilterchange() {
    yield takeLatest(staffListActionTypes.ON_STAFF_FILTER_CHANGE, onStaffFilterChange);
}

const staffListSaga = [fork(watchStaffListRequest), fork(watchStaffFilterchange)];

export default staffListSaga;
