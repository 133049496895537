import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
    getBranchInfoRequest,
    getBranchInfoSuccess,
    getBranchInfoFailed,
    getBranchGeneralDataRequest,
    getBranchGeneralDataSuccess,
    getBranchGeneralDataFailed,
    onArchiveBranchSuccess,
    onArchiveBranchFailed,
    onEditBranchSuccess,
    onEditBranchFailed,
    onEditBranchScheduleSuccess,
    onEditBranchScheduleFailed,
    removeBranchLogoSuccess,
    removeBranchLogoFailed
} from '../../../actions';
import * as branchInfoActionTypes from '../../../actions/admin-view/branch-info/action-types';
import api from '../../../../services/api';

function* onBranchInfoRequest({ branchId }) {
    try {
        const { data } = yield call(api.request, {
            url: `/branches/${branchId}`,
            method: 'get'
        });
        yield put(getBranchInfoSuccess(data));
    } catch (err) {
        yield put(getBranchInfoFailed());
    }
}

function* onBranchGeneralDataRequest() {
    try {
        const { data } = yield call(api.request, {
            url: 'branches/get-data',
            method: 'get'
        });
        yield put(getBranchGeneralDataSuccess(data));
    } catch (err) {
        yield put(getBranchGeneralDataFailed());
    }
}

function* archiveBranchRequest({ payload: { branchId, toggleModal } }) {
    try {
        yield call(api.request, {
            url: `/branches/${branchId}/archive`,
            method: 'post'
        });
        yield put(onArchiveBranchSuccess());
        toggleModal();
        yield put(getBranchInfoRequest(branchId));
        yield put(getBranchGeneralDataRequest());
    } catch (err) {
        yield put(onArchiveBranchFailed());
    }
}

function* editBranchRequest({ payload: { branchId, formData, toggleModal } }) {
    try {
        yield call(api.request, {
            url: `/branches/${branchId}`,
            method: 'post',
            payload: formData
        });
        yield put(onEditBranchSuccess());
        toggleModal();
        yield put(getBranchInfoRequest(branchId));
        yield put(getBranchGeneralDataRequest());
    } catch (err) {
        yield put(onEditBranchFailed());
    }
}

function* editBranchScheduleRequest({ payload: { branchId, recievedData, toggleModal } }) {
    try {
        yield call(api.request, {
            url: `/branches/${branchId}/operating-time`,
            method: 'post',
            payload: recievedData
        });
        yield put(onEditBranchScheduleSuccess());
        toggleModal();
        yield put(getBranchInfoRequest(branchId));
        yield put(getBranchGeneralDataRequest());
    } catch (err) {
        yield put(onEditBranchScheduleFailed());
    }
}

function* removeBranchLogoRequest({ payload: { branchId } }) {
    try {
        yield call(api.request, {
            url: `/branches/${branchId}/logo`,
            method: 'DELETE'
        });
        yield put(removeBranchLogoSuccess());
        yield put(getBranchInfoRequest(branchId));
    } catch (err) {
        yield put(removeBranchLogoFailed());
    }
}

// watchers
function* watchBranchInfoRequest() {
    yield takeEvery(branchInfoActionTypes.GET_BRANCH_INFO_REQUEST, onBranchInfoRequest);
}

function* watchBranchGeneralDataRequest() {
    yield takeEvery(branchInfoActionTypes.GET_BRANCHES_GENERAL_DATA_REQUEST, onBranchGeneralDataRequest);
}

function* watchArchiveBranchRequest() {
    yield takeEvery(branchInfoActionTypes.ON_ARCHIVE_BRANCH_REQUEST, archiveBranchRequest);
}

function* watchEditBranchRequest() {
    yield takeEvery(branchInfoActionTypes.ON_EDIT_BRANCH_REQUEST, editBranchRequest);
}

function* watchEditBranchScheduleRequest() {
    yield takeEvery(branchInfoActionTypes.ON_EDIT_BRANCH_SCHEDULE_REQUEST, editBranchScheduleRequest);
}

function* watchRemoveBranchLogoRequest() {
    yield takeEvery(branchInfoActionTypes.REMOVE_BRANCH_LOGO_REQUEST, removeBranchLogoRequest);
}

const branchInfoSaga = [
    fork(watchBranchInfoRequest),
    fork(watchBranchGeneralDataRequest),
    fork(watchArchiveBranchRequest),
    fork(watchEditBranchRequest),
    fork(watchEditBranchScheduleRequest),
    fork(watchRemoveBranchLogoRequest)
];

export default branchInfoSaga;
