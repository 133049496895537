export const SCHEDULE = '/schedule';
export const REGIONS = '/regions';
export const BRANCHES = '/branches';
export const STAFF = '/staff';
export const POSTED_JOBS = '/locum-shifts';
export const PHARMACY_INFO = '/pharmacy-info';
export const LOCUM_BANK = '/locum-bank';
export const ABSENCE = '/absence';
export const CONTRACT = '/contract';

export const ONBOARDING = '/onboarding';
export const PERSONAL_INFO = '/personal-info';
export const SETTINGS = '/settings';
export const VERIFICATION = '/verification';

export const PHARMACIES = '/pharmacies';
export const USERS = '/users';
export const MODERATION = '/moderation';
export const JOB_OFFERS = '/job-offers';
export const BILLING = '/billing';
export const ADMIN_INFO = '/admin-info';
