import {
    GET_JOB_OFFERS_LIST_SUCCESS,
    GET_JOB_OFFERS_LIST_FAILED,
    ON_JOB_OFFERS_LIST_FILTER_CHANGE,
    ON_JOB_OFFERS_LIST_FILTER_FALSE,
    CLEAR_JOB_OFFERS_LIST_FILTERS
} from '../../../actions/super-admin-view/job-offers-page/action-types';

const initialState = {
    filters: {
        startDate: '',
        endDate: '',
        lunchStartTime: '',
        lunchEndTime: '',
        id: '',
        hourlyRate: '',
        status: '',
        branchTitle: '',
        locumName: '',
        pharmacyName: ''
    },
    isFiltering: false,
    redirectPageTo: null,
    loading: false,
    data: null,
    totalData: 0,
    itemsPerPage: 10
};

const adminJobOffersPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_JOB_OFFERS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case GET_JOB_OFFERS_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        case ON_JOB_OFFERS_LIST_FILTER_CHANGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                },
                isFiltering: true
            };
        case ON_JOB_OFFERS_LIST_FILTER_FALSE:
            return {
                ...state,
                isFiltering: false,
                redirectPageTo: 1
            };
        case CLEAR_JOB_OFFERS_LIST_FILTERS:
            return {
                ...state,
                filters: {
                    startDate: '',
                    endDate: '',
                    lunchStartTime: '',
                    lunchEndTime: '',
                    id: '',
                    hourlyRate: '',
                    status: '',
                    branchTitle: '',
                    locumName: '',
                    pharmacyName: ''
                },
                isFiltering: false
            };
        default:
            return state;
    }
};

export default adminJobOffersPage;
