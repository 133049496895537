import {
    GET_BRANCH_INFO_SUCCESS,
    GET_BRANCH_INFO_FAILED,
    GET_BRANCHES_GENERAL_DATA_SUCCESS,
    ON_ARCHIVE_BRANCH_REQUEST,
    ON_ARCHIVE_BRANCH_SUCCESS,
    ON_ARCHIVE_BRANCH_FAILED,
    ON_EDIT_BRANCH_REQUEST,
    ON_EDIT_BRANCH_SUCCESS,
    ON_EDIT_BRANCH_FAILED,
    ON_EDIT_BRANCH_SCHEDULE_REQUEST,
    ON_EDIT_BRANCH_SCHEDULE_SUCCESS,
    ON_EDIT_BRANCH_SCHEDULE_FAILED
} from '../../../actions/admin-view/branch-info/action-types';

const initialState = {
    branchInfo: {
        data: {},
        loading: false
    },
    generalInfo: {
        data: {}
    }
};

export const handleBranchInfo = (state, action) => {
    if (state === undefined) {
        return initialState.branchInfo;
    }

    switch (action.type) {
        case GET_BRANCH_INFO_SUCCESS:
            return {
                data: action.payload,
                loading: false
            };
        case ON_ARCHIVE_BRANCH_REQUEST:
        case ON_EDIT_BRANCH_REQUEST:
        case ON_EDIT_BRANCH_SCHEDULE_REQUEST:
            return {
                ...state.branchInfo,
                loading: true
            };
        case GET_BRANCH_INFO_FAILED:
        case ON_ARCHIVE_BRANCH_SUCCESS:
        case ON_ARCHIVE_BRANCH_FAILED:
        case ON_EDIT_BRANCH_SUCCESS:
        case ON_EDIT_BRANCH_FAILED:
        case ON_EDIT_BRANCH_SCHEDULE_SUCCESS:
        case ON_EDIT_BRANCH_SCHEDULE_FAILED:
            return {
                ...state.branchInfo,
                loading: false
            };
        default:
            return state.branchInfo;
    }
};

export const handleGeneralInfo = (state, action) => {
    if (state === undefined) {
        return initialState.generalInfo;
    }

    switch (action.type) {
        case GET_BRANCHES_GENERAL_DATA_SUCCESS:
            return {
                data: action.payload
            };
        default:
            return state.generalInfo;
    }
};
