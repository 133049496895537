import React from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../../../../../components/UI/buttons/button';

import moment from 'moment';

function ProfileInfoBoard({
    data: { name, surname, email, phone, gender, city, address1, dob, pharmacyName },
    onEditClick
}) {
    return (
        <>
            <div className="pharmacy-info-page__head">
                <h3 className="pharmacy-info-page__title">Profile Information</h3>
                <div className="edit-branch-form__row buttons-row">
                    <ButtonComponent value="Edit" onClick={onEditClick} size="medium" />
                </div>
            </div>
            <div className="info-dashboard">
                <div className="info-dashboard__row">
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">Bio</h4>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Name</p>
                                    <div className="description">{`${name} ${surname ? surname : ''}`}</div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">Gender</p>
                                    <div className="description">{!gender ? 'None' : gender}</div>
                                </div>
                            </div>
                            <div className="item-row">
                                <div className="info-box__item">
                                    <p className="name">Date of Birth</p>
                                    <div className="description">
                                        {dob ? moment(dob).format('MMMM Do, YYYY') : 'None'}
                                    </div>
                                </div>
                                <div className="info-box__item">
                                    <p className="name">Pharmacy</p>
                                    <div className="description">{pharmacyName || 'None'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="info-box">
                            <h4 className="info-box__title">contatcs</h4>
                            <div className="info-box__item fluid">
                                <p className="name">Address</p>
                                <div className="description">{`${address1}, ${city}` || 'None'}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Email</p>
                                <div className="description email">{!email ? 'None' : email}</div>
                            </div>
                            <div className="info-box__item fluid inner-item">
                                <p className="name">Phone</p>
                                <div className="description">{!phone ? 'None' : phone}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

ProfileInfoBoard.propTypes = {
    data: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired
};

export default ProfileInfoBoard;
