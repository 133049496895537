import { GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILED } from './action-types';

export const getUserProfileRequest = () => ({
    type: GET_USER_PROFILE_REQUEST
});

export const getUserProfileSuccess = (payload) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload
});

export const getUserProfileFailed = () => ({
    type: GET_USER_PROFILE_FAILED
});
