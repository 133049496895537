import EditPersonalInfoForm from './EditPersonalInfoForm';

import { profileResetPassSubmit } from '../../../store/actions';
import { connect } from 'react-redux';

export default connect(
    (state) => ({
        changePassLoading: state.auth.loading
    }),
    {
        profileResetPassSubmit
    }
)(EditPersonalInfoForm);
