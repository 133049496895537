import {
    GET_REGIONS_LIST_SUCCESS,
    GET_REGIONS_LIST_FAILED,
    ON_SUBMIT_REGION_REQUEST,
    ON_SUBMIT_REGION_SUCCESS,
    ON_SUBMIT_REGION_FAILED
} from '../../../actions/admin-view/regions-page/action-types';

const initialState = {
    redirectPageTo: null,
    loading: false,
    data: null,
    totalData: 0,
    itemsPerPage: 9
};

const regionsPage = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGIONS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                totalData: action.payload.totalData,
                redirectPageTo: null
            };
        case ON_SUBMIT_REGION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REGIONS_LIST_FAILED:
        case ON_SUBMIT_REGION_SUCCESS:
        case ON_SUBMIT_REGION_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default regionsPage;
