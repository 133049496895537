import React, { useState, useCallback, useEffect } from 'react';
import { useModal } from './../../../../../services/hooks';
import BasicModal from '../../../../../components/modals/basic-modal';
import EditSubscriptionModal from '../../../../../components/modals/edit-subscription-modal';
import ActivatedBranchesModal from '../../../../../components/modals/activated-branches-modal';
import PurchaseSuccessModal from '../../../../../components/modals/billing-purchase-success-modal';
import BillingDetailsForm from '../../../../../components/forms/billing-details-form';
import StripeCustomPagination from '../../../../../components/stripe-custom-pagination';
import SubscriptionNotification from './SubscriptionNotification';
import TransactionsTable from './TransactionsTable';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { stripeApiKey } from '../../../../../config';
import classnames from 'classnames';
import moment from 'moment';
import './style.scss';
import { useLocation, useHistory } from 'react-router';

const stripePromise = loadStripe(stripeApiKey);
const perPage = 10;

export function PharmacyBillingPage({
    updateBranchSubscriptionsRequest,
    getActiveBranchesListRequest,
    postPlanSubscriptionRequest,
    cancelPlanSubscriptionRequest,
    abortSubscriptionActionRequest,
    getTransactionsListRequest,
    clearTransactionsList,
    clearSubscriptionData,
    paymentMethod,
    subscriptionPlan,
    branches,
    personalInfo,
    updateProfileHostedPageRequest,
    subscriptionStandardPlanRequest,
    getProfileSubscriptionRequest,
    getPersonalInfoRequest,
    transactions: { data, hasMore, page },
    postDeactivateSubscriptionRequest,
    authUserRequest
}) {
    const [subscriptionModalOpen, setSubscriptionModalOpen] = useModal(false);
    const [activeBranchesModal, toggleActiveBranchesModal] = useModal(false);
    const [cancelSubscription, toggleCancelSubscription] = useModal(false);
    const [downgradeSubscription, toggleDowngradeSubscription] = useModal(false);
    const [purchaseSuccess, togglePurchaseSuccess] = useModal(false);
    const [activeListPage, setActivePage] = useState(1);

    const { search, pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        subscriptionStandardPlanRequest();
        getProfileSubscriptionRequest();
        getTransactionsListRequest({ page: 1, perPage });
    }, [subscriptionStandardPlanRequest, getProfileSubscriptionRequest, getTransactionsListRequest]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        const hostedPageId = params.get('hostedpage_id');
        if (hostedPageId) {
            history.replace({ pathname, search: '' });
            updateProfileHostedPageRequest({
                hostedPageId,
                callback: () => {
                    getProfileSubscriptionRequest();
                    getPersonalInfoRequest();
                    getActiveBranchesListRequest();
                    authUserRequest();
                    getTransactionsListRequest({ page: 1, perPage });
                }
            });
        }
        // eslint-disable-next-line
    }, [search, personalInfo]);

    useEffect(() => {
        getActiveBranchesListRequest();

        return () => {
            clearTransactionsList();
            clearSubscriptionData();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionPlan.redirectUrl]);

    const onPaginate = useCallback(
        (direction) => {
            let payload = { perPage, page };

            if (direction === 'next') {
                payload = { ...payload, page: page + 1 };
                setActivePage(activeListPage + 1);
            } else {
                payload = { ...payload, page: page - 1 };
                setActivePage(activeListPage - 1);
            }

            getTransactionsListRequest(payload);
        },
        [activeListPage, getTransactionsListRequest, page]
    );

    const onSubmitBranchSubscriptions = useCallback(
        (updatedBranches) =>
            updateBranchSubscriptionsRequest({
                branches: updatedBranches,
                toggleModal: (data) => {
                    getActiveBranchesListRequest();
                    getProfileSubscriptionRequest();
                    authUserRequest();
                    getTransactionsListRequest({ page: 1, perPage });
                    if (data.url) {
                        window.open(data.url);
                    }

                    setSubscriptionModalOpen(false);
                }
            }),
        // eslint-disable-next-line
        [personalInfo]
    );

    const onDeactivateBranch = (id) => {
        postDeactivateSubscriptionRequest({
            id,
            cb: () => {
                getActiveBranchesListRequest();
                authUserRequest();
            }
        });
    };

    const onSubscribePlan = useCallback(
        (downgrade = null) => {
            const {
                selectedPlan: { planId }
            } = subscriptionPlan;
            postPlanSubscriptionRequest(
                {
                    planId,
                    toggleModal: () => {
                        toggleActiveBranchesModal();
                        toggleDowngradeSubscription(false);
                    },
                    firstItemId: null,
                    lastItemId: null,
                    toggleSuccessModal: () => togglePurchaseSuccess()
                },
                downgrade
            );
        },
        [
            postPlanSubscriptionRequest,
            subscriptionPlan,
            toggleActiveBranchesModal,
            toggleDowngradeSubscription,
            togglePurchaseSuccess
        ]
    );

    const onCancelSubscription = useCallback(
        (immediately) =>
            cancelPlanSubscriptionRequest({
                immediately,
                toggleModal: () => {
                    setSubscriptionModalOpen();
                    toggleCancelSubscription();
                }
            }),
        [cancelPlanSubscriptionRequest, toggleCancelSubscription, setSubscriptionModalOpen]
    );

    const onDiscardAction = useCallback(
        (action) => abortSubscriptionActionRequest(action),
        [abortSubscriptionActionRequest]
    );

    const reloadPersonalInfo = useCallback(() => {
        getPersonalInfoRequest();
        getActiveBranchesListRequest();
    }, [getPersonalInfoRequest, getActiveBranchesListRequest]);

    const pagination =
        data && data.length >= 1 ? (
            <StripeCustomPagination
                prevDisabled={page === 1}
                nextDisabled={!hasMore}
                onPaginate={onPaginate}
                modalView
            />
        ) : null;

    let notification = null;

    if (
        subscriptionPlan.currentPlan &&
        !subscriptionPlan.currentPlan.nextPaymentDate &&
        subscriptionPlan.currentPlan.cancelAt
    ) {
        notification = (
            <SubscriptionNotification
                subscriptionEndDate={subscriptionPlan.currentPlan.cancelAt}
                onClick={onDiscardAction}
            />
        );
    } else if (subscriptionPlan.currentPlan && subscriptionPlan.currentPlan.isDowngraded) {
        notification = (
            <SubscriptionNotification
                subscriptionEndDate={subscriptionPlan.currentPlan.nextPaymentDate}
                onClick={onDiscardAction}
                isDowngrade
            />
        );
    }

    return (
        <Elements stripe={stripePromise}>
            <div className="pharmacy-info-page billing-page">
                <div className="pharmacy-info-page__head">
                    <h3 className="pharmacy-info-page__title">{'Subscription & Billing'}</h3>
                </div>
                <div className="info-dashboard">
                    <div className="info-dashboard__row">
                        <div className="col">
                            <div className="info-box">
                                <h4 className="info-box__title">
                                    Subscription Details
                                    <span
                                        role="button"
                                        className="edit-button"
                                        onClick={() => setSubscriptionModalOpen(!subscriptionModalOpen)}
                                    >
                                        Edit
                                    </span>
                                </h4>
                                <div className="item-row">
                                    <div className="info-box__item">
                                        <p className="name">Subscription Type</p>
                                        <div
                                            className={classnames('description', {
                                                notified:
                                                    subscriptionPlan.currentPlan &&
                                                    subscriptionPlan.currentPlan.cancelAt
                                            })}
                                        >
                                            Unlimited
                                        </div>
                                    </div>
                                    <div className="info-box__item">
                                        <p className="name">Price</p>
                                        <div className="description price">
                                            {subscriptionPlan.subscription && subscriptionPlan.subscription.data
                                                ? `£${subscriptionPlan.subscription.data.amount}`
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="item-row">
                                    <div className="info-box__item">
                                        <p className="name">Billing Period</p>
                                        <div className="description">Month</div>
                                    </div>
                                    <div className="info-box__item">
                                        <p className="name">Next Payment</p>
                                        <div className="description price">
                                            {subscriptionPlan.subscription && subscriptionPlan.subscription.data
                                                ? moment(subscriptionPlan.subscription.data.next_billing_at).format(
                                                      'DD/MM/YY'
                                                  )
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">{notification}</div>
                    </div>
                    <div className="info-dashboard__row">
                        <div className="col">
                            <div className="info-box">
                                <h4 className="info-box__title">List of Transactions</h4>
                                <TransactionsTable data={data} />
                                <div className="pagination-wrapper">{pagination}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {subscriptionModalOpen && (
                <BasicModal
                    open={subscriptionModalOpen}
                    onClose={() => setSubscriptionModalOpen()}
                    title="Subscription per branch"
                    size="small"
                >
                    <BillingDetailsForm
                        onCancelClick={() => setSubscriptionModalOpen()}
                        onSubmit={onSubmitBranchSubscriptions}
                        onDeactivateBranch={onDeactivateBranch}
                        loading={paymentMethod.loading}
                        subscriptionExist={
                            !!personalInfo.data.standardSubscriptionId || !!personalInfo.data.numarkSubscriptionId
                        }
                        branches={branches.data.rows}
                        reloadPersonalInfo={reloadPersonalInfo}
                    />
                </BasicModal>
            )}
            {cancelSubscription && (
                <EditSubscriptionModal
                    open={cancelSubscription}
                    onClose={() => toggleCancelSubscription()}
                    onSubmit={onCancelSubscription}
                    loading={subscriptionPlan.loading}
                    subscriptionEndDate={subscriptionPlan.currentPlan.nextPaymentDate || null}
                />
            )}
            {downgradeSubscription && (
                <EditSubscriptionModal
                    open={downgradeSubscription}
                    onClose={() => toggleDowngradeSubscription()}
                    onSubmit={(downgrade) => onSubscribePlan(downgrade)}
                    loading={subscriptionPlan.loading}
                    subscriptionEndDate={subscriptionPlan.currentPlan.nextPaymentDate || null}
                    isDowngrade
                />
            )}
            {activeBranchesModal && (
                <ActivatedBranchesModal
                    open={activeBranchesModal}
                    onClose={() => toggleActiveBranchesModal()}
                    onCancelClick={() => toggleActiveBranchesModal()}
                    onSubmit={() => {
                        if (
                            subscriptionPlan.currentPlan &&
                            +subscriptionPlan.currentPlan.price > +subscriptionPlan.selectedPlan.price
                        ) {
                            toggleDowngradeSubscription();
                        } else onSubscribePlan();
                    }}
                    loading={subscriptionPlan.loading}
                />
            )}
            {purchaseSuccess && <PurchaseSuccessModal open={purchaseSuccess} onClose={() => togglePurchaseSuccess()} />}
        </Elements>
    );
}
