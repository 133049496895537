import {
    GET_STAFF_INFO_REQUEST,
    GET_STAFF_INFO_SUCCESS,
    GET_STAFF_INFO_FAILED,
    TOGGLE_ADD_RELSEASE_LOCUM_BANK_REQUEST,
    TOGGLE_ADD_RELSEASE_LOCUM_BANK_SUCCESS,
    TOGGLE_ADD_RELSEASE_LOCUM_BANK_FAILED
} from './action-types';

export const getStaffInfoRequest = (staffId) => ({
    type: GET_STAFF_INFO_REQUEST,
    staffId
});

export const getStaffInfoSuccess = (payload) => ({
    type: GET_STAFF_INFO_SUCCESS,
    payload
});

export const getStaffInfoFailed = () => ({
    type: GET_STAFF_INFO_FAILED
});

export const toggleAddReleaseLocumBankRequest = (userId) => ({
    type: TOGGLE_ADD_RELSEASE_LOCUM_BANK_REQUEST,
    userId
});

export const toggleAddReleaseLocumBankSuccess = (payload) => ({
    type: TOGGLE_ADD_RELSEASE_LOCUM_BANK_SUCCESS,
    payload
});

export const toggleAddReleaseLocumBankFailed = () => ({
    type: TOGGLE_ADD_RELSEASE_LOCUM_BANK_FAILED
});
