import { call, put, fork, takeEvery, takeLatest, delay, select } from 'redux-saga/effects';
import {
    getPostedJobsRequest,
    getPostedJobsSuccess,
    getPostedJobsFailed,
    onJobsFilterFalse,
    setJobStatusFailed,
    setJobStatusSuccess,
    getCSVJobsDownloadSuccess,
    getCSVJobsDownloadFailed,
    getMinAndMaxJobFailed,
    getMinAndMaxJobSuccess
    //   getMinAndMaxJobRequest,
} from '../../../actions';
import { selectItemsPerPage, selectFilters } from './selectors';
import * as postedJobsListActionTypes from '../../../actions/admin-view/posted-jobs-page/action-types';
import api from '../../../../services/api';

function* onPostedJobsRequest({ payload, filters }) {
    const itemsPerPage = yield select(selectItemsPerPage);
    const { page } = payload;
    const searchFilters = filters ? filters : {};

    try {
        const { data } = yield call(api.request, {
            url: '/jobs/list',
            method: 'post',
            payload: {
                filters: searchFilters,
                pagination: {
                    page,
                    perPage: itemsPerPage
                }
            }
        });
        yield put(
            getPostedJobsSuccess({
                data,
                totalData: data.count
            })
        );
    } catch (err) {
        yield put(getPostedJobsFailed());
    }
}

function* onMinAndMaxJobsRequest({ payload }) {
    try {
        const { data } = yield call(api.request, {
            url: `/jobs/min-max-job?tab=${payload}`,
            method: 'get'
        });
        yield put(
            getMinAndMaxJobSuccess({
                data
            })
        );
    } catch (err) {
        yield put(getMinAndMaxJobFailed());
    }
}

function* onCSVJobsDownloadRequest({ filters }) {
    try {
        const { data } = yield call(api.request, {
            url: '/jobs/csv-download',
            method: 'post',
            payload: {
                filters
            }
        });
        yield put(getCSVJobsDownloadSuccess(data));
    } catch (err) {
        yield put(getCSVJobsDownloadFailed());
    }
}

function* onJobsFilterChange({ payload: { value }, page }) {
    const filters = yield select(selectFilters);
    let isFilled = Object.values(filters).some((field) => field && field.length > 0);

    if (!value || (value.length === 0 && !isFilled && page === 1)) {
        yield put(onJobsFilterFalse());
        yield put(getPostedJobsRequest({ page }, filters));
    } else if (value.length === 0 && isFilled) {
        yield put(getPostedJobsRequest({ page }, filters));
    } else if (value.length === 0 && !isFilled && page !== 1) {
        yield put(onJobsFilterFalse());
    } else {
        yield delay(1000);
        yield put(getPostedJobsRequest({ page }, filters));
    }
}

function* onSetJobStatusChange({ payload: { jobId, status, callback } }) {
    try {
        const { data } = yield call(api.request, {
            url: `/jobs/${jobId}/status`,
            method: 'post',
            payload: {
                status: status
            }
        });
        yield put(
            setJobStatusSuccess({
                data
            })
        );
        yield callback();
    } catch (err) {
        put(setJobStatusFailed());
    }
}

// watchers
function* watchPostedJobsRequest() {
    yield takeEvery(postedJobsListActionTypes.GET_POSTED_JOBS_REQUEST, onPostedJobsRequest);
}

function* watchJobsFilterChange() {
    yield takeLatest(postedJobsListActionTypes.ON_JOBS_FILTER_CHANGE, onJobsFilterChange);
}

function* watchJobStatusChange() {
    yield takeEvery(postedJobsListActionTypes.SET_JOB_STATUS_REQUEST, onSetJobStatusChange);
}
function* watchOnCSVJobsDownloadRequest() {
    yield takeEvery(postedJobsListActionTypes.GET_CSV_JOBS_DOWNLOAD_REQUEST, onCSVJobsDownloadRequest);
}
function* watchGetMinAndMaxJobsRequest() {
    yield takeEvery(postedJobsListActionTypes.GET_MIN_AND_MAX_JOBS_REQUEST, onMinAndMaxJobsRequest);
}

const postedJobsSaga = [
    fork(watchPostedJobsRequest),
    fork(watchJobsFilterChange),
    fork(watchJobStatusChange),
    fork(watchOnCSVJobsDownloadRequest),
    fork(watchGetMinAndMaxJobsRequest)
];

export default postedJobsSaga;
